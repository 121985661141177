import React, { useContext, useEffect, useState } from 'react'
import './RenderAllTasks.css';
import { Button, Checkbox, Collapse, Divider } from 'antd';
import { Col, Row } from 'reactstrap';
import AppContext from '../../Context/AppContext';
import AwsServerService from '../../common/util/AwsServerService';
import { TaskGroupCard } from './TaskGroupCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { invertColor } from '../HR/TimeClock/Util';
import { useHistory } from "react-router-dom";
import _ from 'lodash';
import { unstable_batchedUpdates } from 'react-dom';
import { CaretDownOutlined, CaretRightOutlined } from '@ant-design/icons';

const { Panel } = Collapse;
export const HeaderContent = ({ data, key }) => {
  // console.log(data[key]);
  return <Row sm={12}>
    <Col sm={6}>{data[key]}</Col>
    <Col sm={6}></Col>
  </Row>
}
export const RenderAllTasks = ({
  allPipelineData, viewTaskFunction,searchText,searchSettings,
  group, DealStatus, taskFilterSetting ,selectedTable,isSubTaskView, setIsSubTaskView, refreshData,setPageRefreshData
  ,allDependeniesData, dropdownTasksDataCache, allTasksAndSubtasks, selectedIds,setSelectedIds,
  dummyId,setDummyID,setFilteredDataForAllProjects,setDependencyRefresh
}) => {
  const AppData = useContext(AppContext);
  const { state } = AppData || {};
  const [arrayOfGroupKeys, setArrayOfGroupKeys] = useState([])
  const [activeKeys, setActiveKeys] = useState([])
  const [deals, setDeals] = useState([]);
  const [group1Data, setGrpoup1Data] = useState({});
  // const [dummyId, setDummyID] = useState({});
  const [cacheGroup1Data, setCacheGroup1Data] = useState({});
  const [allTimeRecords, setAllTimeRecords] = useState([]);
  const [groupFilter, setGroupFilter] = useState({
    group1: { name: "Status", value: "Stage", extraKey: "Account_Name" },
    group2: { name: "Pipeline", value: "PipelineROWID" },
  })
  const [taskHeadersData, setTaskHeadersData] = useState([]);
  const [hoveredRow, setHoveredRow] = useState(false);
  const history = useHistory();
  useEffect(e => {
    setGroupFilter(group)
  }, [group])

  useEffect(e=>{
    AwsServerService.getAllTimeRecords().then(r=>{
      setAllTimeRecords(r.data[0])
    }).catch(e=>{
      console.log(e);      
    })
  }, [])

  useEffect(e=>{
    if(selectedIds?.Task?.length == 0 ){
      setDummyID({})
    }
  },[selectedIds])
  
  const getColorOfAccountTile = (dealId) => {
    let deal = state.AllDeals?.find(e => e.Deal_Name == dealId);
    let st = {
      backgroundColor: "#5b6c97",
      color: "white"
    }
      let temp = state?.AllAccounts?.find(ds => ds.id == deal?.Client?.id);
      if (temp?.Color) {
        st["backgroundColor"] = temp?.Color;
        st["color"] = invertColor(temp?.Color, true)
    }
    return st;
  }
  

  const getColorOfDealStatus = (dealId) => {
    let st = {
      backgroundColor: "#5b6c97",
      color: "white"
    }
    if (groupFilter?.group1?.value == "Deal_Name") {
      let deal = deals?.find(d => d.Deal_Name == dealId);
      if (deal) {
        let temp = DealStatus?.find(ds => ds.display_value == deal.Stage);
        if (temp?.colour_code) {
          st["backgroundColor"] = temp.colour_code;
          st["color"] = invertColor(temp.colour_code, true)
        }
      }
    } else if (groupFilter?.group1?.value == "Stage") {
      let temp = DealStatus?.find(ds => ds.display_value == dealId);
      if (temp?.colour_code) {
        st["backgroundColor"] = temp.colour_code;
        st["color"] = invertColor(temp.colour_code, true)
      }
    } else if (groupFilter?.group1?.value == "Client") {
      let temp = state?.AllAccounts?.find(ds => ds.id == dealId);
      if (temp?.Color) {
        st["backgroundColor"] = temp?.Color;
        st["color"] = invertColor(temp?.Color, true)
      }
    }
    return st;
  }
  
  useEffect(e => {
    const keys = [];
    if (groupFilter && groupFilter.group1 && groupFilter.group2 && deals.length > 0 ) {
      if (groupFilter.group1 && groupFilter.group1.value) {
        let obj = {};
        deals?.forEach(data => {
          let val;
          if (groupFilter.group1.isNested) {
            val = groupFilter.group1.getValue(data);
          } else {
            val = data[groupFilter.group1.value];
          }
          if (obj[val]) {
            let allTasks = allPipelineData.filter(p => p.DealName == data.Deal_Name)?.flatMap(item => item.Tasks);
            obj[val] = [...obj[val], ...allTasks]
          } else {
            let allTasks = allPipelineData.filter(p => p.DealName == data.Deal_Name)?.flatMap(item => item.Tasks);
            obj[val] = [...allTasks];
            keys.push(`${val}`);
          }
        });
        if (groupFilter.group2 && groupFilter.group2.value) {
          Object.keys(obj).forEach(key => {
            let objtemp = {};
            obj[key].forEach(task => {
              if (objtemp[task[groupFilter.group2.value]]) {
                objtemp[task[groupFilter.group2.value]].push(task)
              } else {
                if(groupFilter.group2.value == "None"){
                  task["None"] = "None";
                }
                objtemp[task[groupFilter.group2.value]] = [task];
              }
            })
            obj[key] = objtemp;
          })
        }
        setGrpoup1Data(obj);
        setCacheGroup1Data(obj);
      }
    }
    setArrayOfGroupKeys(keys);
  }, [groupFilter, deals, deals.length, allPipelineData, refreshData])

  useEffect(e=>{
    if(cacheGroup1Data){
      if(searchText){
        let obj=_.cloneDeep(cacheGroup1Data);
        Object.keys(obj).forEach(group1 => {
          Object.keys(obj[group1]).forEach(group2=>{
            let temp=[...obj[group1][group2]];
            if(temp && temp.length>0 && searchSettings.TaskName){
              temp = temp.filter(task=>task.TaskName && _.capitalize(task.TaskName)?.includes(_.capitalize(searchText)));
            }
            if(temp && temp.length>0 && searchSettings.Description){
              temp = temp.filter(task=>task.TaskDescription && _.capitalize(task.TaskDescription)?.includes(_.capitalize(searchText)));
            }
            obj[group1][group2]=temp && temp.length>0 ?temp:[];
          })
        })
        setFilteredDataForAllProjects.current = obj;
        setGrpoup1Data(obj);
      }else{
        setFilteredDataForAllProjects.current = {...cacheGroup1Data};
        setGrpoup1Data({...cacheGroup1Data});
      }
    }
  },[searchText,searchSettings,cacheGroup1Data])

  useEffect(e => {
    if(state.AllDeals?.length > 0){
      setDeals(state.AllDeals)
    }
  }, [state.AllDeals])

  const getAccountTile = (key) => {
      let accountDeal = deals?.find(d => d?.Deal_Name == key);
      if (accountDeal?.Client?.name) {
        return `${accountDeal.Client.name}`;
      }
  }
  
  const getGroup1HeaderValue = (key, group) => {
      if (group == "Client") {
      let accountDeal = deals?.find(d => d?.Client?.id == key);
      if (accountDeal?.Client?.name) {
        return accountDeal.Client.name;
      }
    }
    return key
  }

  useEffect(e=>{
    if(taskFilterSetting?.ExpandDeals){
      setActiveKeys(arrayOfGroupKeys)
    }else{
      setActiveKeys([])
    }
  },[taskFilterSetting?.ExpandDeals, arrayOfGroupKeys])
  
  return (
    <div className='PiplinesList-Wrapper'>
      <Collapse onChange={(e) => {
        setActiveKeys(e);
        }} 
        expandIcon={({ isActive }) =>
          isActive ? <CaretDownOutlined /> : <CaretRightOutlined />
        }
        activeKey={activeKeys} 
        defaultActiveKey={arrayOfGroupKeys} 
        expandIconPosition="start" 
      >
        {group1Data && Object.keys(group1Data)?.length > 0 && Object.keys(group1Data)?.map((key, index) => {

            const allTasksSelected = Object.keys(group1Data[key]).length > 0 && Object.keys(group1Data[key]).every(key2 =>
              dummyId[`${key}_${key2}`]?.length === group1Data[key][key2]?.length
            );
            const anyTaskSelected = Object.keys(group1Data[key]).length > 0 && Object.keys(group1Data[key]).some(key2 =>
              dummyId[`${key}_${key2}`]?.length > 0
            );

          return <Panel className='Group-Panel-hover'
            header={<Row sm={12} >
              <Col style={{ display: "flex", flexDirection: "row" }} sm={6}>
              <Checkbox
                  checked={allTasksSelected}
                  indeterminate={anyTaskSelected && !allTasksSelected}
                  className='Project-Checkbox'
                  // style={{opacity: "0.7"}}
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent event bubbling
                    let isChecked = e.target.checked;
                    const updatedDummyId = { ...dummyId };
                    let updatedSelectedIds = [...selectedIds.Task]; // Keep the existing selected task IDs
                  
                    if (anyTaskSelected && !allTasksSelected) {
                      isChecked = false; // Force uncheck if indeterminate
                    }
                  
                    // Loop through group1Data to gather TaskROWIDs for the selected deal
                    for (const key2 in group1Data[key]) {
                      if (group1Data[key].hasOwnProperty(key2)) {
                        const taskIds = group1Data[key][key2]?.map(item => item?.TaskROWID) || [];
                      
                        // If the checkbox is checked, add task IDs
                        if (isChecked) {
                          updatedDummyId[`${key}_${key2}`] = taskIds;
                        
                          // Add task IDs only if they are not already in the selectedIds array (to avoid duplicates)
                          taskIds.forEach(taskId => {
                            if (!updatedSelectedIds.includes(taskId)) {
                              updatedSelectedIds.push(taskId);
                            }
                          });
                        } 
                        // If unchecked, remove task IDs from dummyId and selectedIds
                        else {
                          updatedDummyId[`${key}_${key2}`] = [];
                        
                          // Remove the task IDs from selectedIds if the checkbox is unchecked
                          updatedSelectedIds = updatedSelectedIds.filter(taskId => !taskIds.includes(taskId));
                        }
                      }
                    }
                  
                    // Batch the updates to dummyId and selectedIds
                    unstable_batchedUpdates(() => {
                      // Update the dummyId state
                      setDummyID(updatedDummyId);
                    
                      // Update the selectedIds state with unique task IDs
                      setSelectedIds(prev => ({
                        ...prev,
                        Task: updatedSelectedIds,  // Ensure no duplicates in selectedIds
                      }));
                    });
                  }}
                />
                {
                  groupFilter.group1.value == "Deal_Name" ? 
             <> <span style={getColorOfAccountTile(key)} className='Group1-Card' onClick={() => {
                let data = state.AllDeals?.find(e => e.Deal_Name == key);
                if (data) {
                  history.push({
                    pathname: "/d/Accounts/DetailView/" + data?.Client?.id,
                    state: {
                      data: data,
                      action: "DetailView",
                      module: "Accounts",
                    },
                  })
                }
              }}>{getAccountTile(key)}</span>&nbsp;&nbsp; <span style={{position:"relative", top:"2px", fontWeight: "700"}}>{"/"}</span>
              <span style={getColorOfDealStatus(key)} className='Group1-Card' onClick={() => {
                let data = state.AllDeals?.find(e => e.Deal_Name == key);
                if (data) {
                  history.push({
                    pathname: "/d/Deals/DetailView/" + data.id,
                    state: {
                      data: data,
                      action: "DetailView",
                      module: "Deals",
                    },
                  })
                } 
              }}>{getGroup1HeaderValue(key, groupFilter.group1.value)}</span></>
                  : 
              <span style={getColorOfDealStatus(key)} className='Group1-Card' onClick={() => {
                let data = state.AllDeals?.find(e => e.Deal_Name == key);
                if (data) {
                  history.push({
                    pathname: "/d/Deals/DetailView/" + data.id,
                    state: {
                      data: data,
                      action: "DetailView",
                      module: "Deals",
                    },
                  })
                }
              }}>{getGroup1HeaderValue(key, groupFilter.group1.value)}</span>
                }
              
              <Button className='Group-View-Btn' type='primary' icon={<FontAwesomeIcon icon={faEye} />} onClick={e => { }} ghost>View</Button></Col>
              <Col sm={6}></Col>
            </Row>}
            key={key} style={{ height: 'auto' }} >
            {(taskHeadersData.length > 0 || taskHeadersData.length == 0) && Object.keys(group1Data[key]).map(key2 => { 
              // console.log("group1Data[key][key2]", key2)
              return <div className='Category-Wrapper' style={{position: "relative"}}>
                 <div>
                    <Checkbox 
                    checked={dummyId[`${key}_${key2}`]?.length == group1Data[key][key2]?.length} 
                    style={{opacity: '0.98'}}
                    onClick={(e)=>{
                      let obj={...dummyId};
                      if(e.target.checked){
                        if(dummyId[`${key}_${key2}`] && dummyId[`${key}_${key2}`]?.length != group1Data[key][key2]?.length){
                          let ids=obj[`${key}_${key2}`];
                          delete obj[`${key}_${key2}`]
                          setDummyID(obj)
                          setSelectedIds((prevSelectedIds) => ({...prevSelectedIds,
                          Task: prevSelectedIds.Task.filter(taskId => !ids.includes(taskId))
                        }));
                          return
                        }
                        let arrayData = group1Data[key][key2]?.map(item => item?.TaskROWID);
                          obj[`${key}_${key2}`]=[...arrayData];
                        setDummyID(obj); 
                        setSelectedIds(prev=>({...prev, Task:[...prev.Task,...arrayData]}))
                      }else{
                        let ids=obj[`${key}_${key2}`];
                        delete obj[`${key}_${key2}`]
                        setDummyID(obj)
                        setSelectedIds((prevSelectedIds) => ({...prevSelectedIds,
                          Task: prevSelectedIds.Task.filter(taskId => !ids.includes(taskId))
                        }));
                      }
                  }}  
                  indeterminate={dummyId[`${key}_${key2}`]?.length> 0 && dummyId[`${key}_${key2}`]?.length != group1Data[key][key2]?.length}
                  className={`Category-CheckBox ${key?.replace(/\s+/g, '')}_${key2?.replace(/\s+/g, '')}`}
                  />
                </div>
               
               <TaskGroupCard
                selectedTable={selectedTable}
                isSubTaskView={isSubTaskView}
                setIsSubTaskView={setIsSubTaskView}
                taskFilterSetting={taskFilterSetting}
                viewTaskFunction={viewTaskFunction}
                groupFilter={groupFilter}
                allPipelineData={allPipelineData}
                data={group1Data[key][key2]}
                header={key2}
                key1={key}
                taskHeadersData={taskHeadersData}
                setTaskHeadersData={setTaskHeadersData}
                refreshData={refreshData}
                setPageRefreshData={setPageRefreshData}
                dropdownTasksDataCache={dropdownTasksDataCache}
                allTasksAndSubtasks={allTasksAndSubtasks}
                allDependeniesData={allDependeniesData}
                allTimeRecords={allTimeRecords}
                selectedIds={selectedIds}
                setSelectedIds={setSelectedIds}
                dummyId={dummyId}
                setDummyID={setDummyID}
                setDependencyRefresh={setDependencyRefresh}
                setHoveredRow={setHoveredRow}
              />
              </div>
            })}

          </Panel>
        })}

      </Collapse>
    </div>
  )
}
