import { faArrowAltCircleDown, faCaretDown, faCaretLeft, faCaretRight, faEyeSlash, faPlusCircle, faChevronDown, faChevronRight, faEllipsisH, faGripVertical, faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DropdownToggle, UncontrolledCollapse } from 'reactstrap';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { v4 as uuidv4 } from "uuid";
import './collapse_table.css';
import StatusDropdown from '../StatusDropdown/StatusDropdown';
import { ProgressBar } from "react-bootstrap";


const TableCollapse = (props) => {
    const [selectedRowId, setSelectedRowId] = useState('');
    const [click, setClick] = useState(false)
    const [data, setData] = useState([])
    const [Flag, setFlag] = useState(false);
    const [TaskCollapseIcon, setTaskCollapseIcon] = useState([]);
    const [SubTaskCollapseIcon, setSubTaskCollapseIcon] = useState([]);

    const [groupBy, setGroupBy] = useState("Pipeline");
    const [groupByField, setGroupByField] = useState([]);
    const [GroupIcons, setGroupIcons] = useState([]);
    const [firstData, setFirstData] = useState([])
    useEffect(() => {
        if (props.data) {
            let dataArr = [];
            props.data.forEach((obj) => {
                obj.Pipeline = obj.project.name;

            })
            TaskIcons(props.data);
            getGroupByFields(props.data, groupBy);
            //   dataArr.sort((a, b) => new Date(b.Milestone.DateTime) - new Date(a.Milestone.DateTime))
            //   let firstArr = []
            //   dataArr.forEach((one) => {
            //     if (firstArr.length == 0) {
            //       firstArr.push(one);
            //     } else {
            //       if (firstArr.filter(obj => obj.Pipeline == one.Pipeline).length == 0) {
            //         firstArr.push(one);
            //       }
            //     }
            //   })
            setFirstData(props.data)
            setData(props.data);
        }

    }, [props.data])

    useEffect(() => {
        //alert("useefffecg");
        data.map((tasks, index) => { });
        setFlag(false);
    }, [Flag]);
    const TaskIcons = (tasks) => {
        let t1 = [];
        let subt1 = [];
        tasks.forEach((e, i) => {
            t1.push({
                ID: e._id,
                taskArrow: faCaretDown,
            });
            // if (e.Subtask.length > 0) {
            //   e.Subtask.forEach((sub, j) => {
            //     subt1.push({
            //       ID: sub.SubtaskId,
            //       subtaskArrow: faCaretDown,
            //     });
            //   });
            // }
        });
        setTaskCollapseIcon(t1);

    };
    // const data = [
    //     { name: 'one', LatestStaus: 'test', LatestDate: 'Test', PrevStatus: [{ Status: 'Test', Date: 'Test' }, { Status: 'Test', Date: 'Test' }] },
    //     { name: 'two', LatestStaus: 'test', LatestDate: 'Test', PrevStatus: [{ Status: 'Test', Date: 'Test' }, { Status: 'Test', Date: 'Test' }] },

    // ]

    const getTime = (date) => {
        let Time = new Date(date);
        var hours = Time.getHours();
        var minutes = Time.getMinutes();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        var strTime = hours + ':' + minutes + ' ' + ampm;

        /// date
        // if (new Date().toLocaleDateString() !== new Date(date).toLocaleDateString()) {
        //     let dateTime = new Date(date);
        //     let str = '';
        //     str = str + dateTime.toLocaleString('en-us', { month: 'short' });
        //     str = str + " " + dateTime.getDate();
        //     /// year
        //     if (new Date().getFullYear() !== new Date(date).getFullYear()) {
        //         strTime = str + ' , ' + dateTime.getFullYear();
        //     }
        //     strTime = str + ' , ' + strTime;
        // }




        return strTime;

    };


    const toggleTaskIcons = (id) => {
        let t = TaskCollapseIcon;
        TaskCollapseIcon.forEach((e, i) => {
            if (e.ID == id) {
                setFlag(true);
                t[i] = {
                    ID: e.ID,
                    taskArrow: e.taskArrow == faCaretRight ? faCaretDown : faCaretRight,
                };
            }
        });
        setTaskCollapseIcon(t);
    };

    const GetFormattedDate = (date) => {
        let dateObj = new Date(date);
        let month = dateObj.getMonth() + 1; //months from 1-12
        let day = dateObj.getDate();
        let year = dateObj.getFullYear();
        day = day < 10 ? "0" + day : day;
        month = month < 10 ? "0" + month : month;

        return month + "/" + day + "/" + year;
    }

    const getGroupByFields = (tasks, groupBy) => {
        var unique = [];
        if (groupBy == "Priority" || groupBy == "Status") {
            if (groupBy == "Priority") {
                unique = [...new Set(tasks.map((task) => {
                    if (task[groupBy].name == '') {
                        return 'NO PRIORITY'
                    } else {
                        return task[groupBy].name
                    }
                }))];
            } else {
                unique = [...new Set(tasks.map((task) => task[groupBy].name))];
            }

        } else if (groupBy == 'Assignee') {
            unique = [...new Set(tasks.map((task) => {
                if (task[groupBy].length > 0) {
                    let assigness = task[groupBy];
                    let str = ''
                    assigness.forEach((assignee) => {
                        str = str + assignee.userName + 'EOU'
                    })
                    return str;
                } else {
                    let str = 'Unassignee';
                    return str;
                }

            }))]
        } else {
            unique = [...new Set(tasks.map((task) => task[groupBy]))];
        }
        setGroupByField(unique);


        //arrangeTaskDataOnGroupBy(unique,groupBy)

        //set group icons first time
        if (groupBy == 'Assignee') {
            let gicons = [];
            unique.forEach((e, i) => {
                if (e) {
                    gicons.push({
                        ID: e,
                        isOpen: true,
                    });
                }
            });
            setGroupIcons(gicons);
        } else {
            let gicons = [];
            unique.forEach((e, i) => {
                if (e) {
                    gicons.push({
                        ID: e.replace(/ /g, ""),
                        isOpen: true,
                    });
                }
            });
            setGroupIcons(gicons);
        }

    };
    const getGroupIcon = (gID) => {
        let obj = GroupIcons.find((e) => e.ID == gID);
        if (obj != undefined) {
            return obj.isOpen;
        } else {
            return true;
        }
    };

    const toggleGroupIcon = (gID) => {
        let gicons = [...GroupIcons];
        GroupIcons.forEach((e, i) => {
            if (e.ID == gID) {
                gicons[i] = {
                    ID: gID,
                    isOpen: !e.isOpen,
                };
            }
        });
        setGroupIcons(gicons);
    };

    const getStatusOfGroupBy = (group, task, groupValue) => {
        if (group == 'Assignee') {
            let str = ''
            if (task[group].length > 0) {
                task[group].forEach((one) => {
                    str = str + one.userName + 'EOU'
                })
            } else {
                str = 'Unassignee'
            }

            if (str == groupValue) {
                return true;
            } else {
                return false;
            }
        } else if (group == "Priority") {
            let name = (task[group].name == '') ? 'NO PRIORITY' : task[group].name;
            if (name == groupValue) {
                return true
            } else {
                return false;
            }
        } else {
            if ((group == "Status"
                ? task[group].name
                : task[group]) == groupValue) {
                return true;
            } else {
                return false;
            }
        }


    }

    const addColumns =

        [
            { name: 'PROGRESS', isVisible: true, isSearch: true },
          
            { name: 'START DATE', isVisible: true, isSearch: true },
            { name: 'END DATE', isVisible: true, isSearch: true },
            { name: 'STATUS', isVisible: true, isSearch: true },
            { name: 'COMPLETED DATE', isVisible: true, isSearch: true },
            // { name: 'TIME TRACKING', isVisible: true, isSearch: true },
            // { name: 'SUPERVISER', isVisible: true, isSearch: true },
            // { name: 'ASSIGNEE', isVisible: true, isSearch: true },
            // { name: 'DUE DATE', isVisible: true, isSearch: true },
            // { name: 'PRIORITY', isVisible: true, isSearch: true },
            // { name: 'TIME ESTIMATE', isVisible: true, isSearch: true },
            // 
            // { name: 'STATUS', isVisible: true, isSearch: true },
            // { name: 'PIPELINE', isVisible: true, isSearch: true },
            // { name: 'CATEGORY', isVisible: true, isSearch: true },
            // { name: 'MILESTONE', isVisible: true, isSearch: true },

        ]

    const getTaskTdAsperHeader = (data, headerName) => {
        if (headerName === "COMPLETED DATE") {
            if (data.status == 'completed') {
                return <td
                    // dragOver={defaultColumns[index] === dragOver}
                    className="subtask-cat">
                   {data.completed_date}
                </td>
            } else {
                return <td
                    // dragOver={defaultColumns[index] === dragOver}
                    className="subtask-cat">
                  -
                </td>
            }

        }
        if (headerName === "PROGRESS") {
            //return <td>Superviser</td>
            return <td
                // dragOver={defaultColumns[index] === dragOver}
                className="task-progress">
                <div className="progress-wrapper">
                    <ProgressBar now={75} />{" "}
                </div>
                <span>75%</span>
            </td>
        }
        if (headerName === "START DATE") {
            //return <td>Superviser</td>
            return <td
                // dragOver={defaultColumns[index] === dragOver}
                className="subtask-cat">{data.start_date}</td>
        }
        if (headerName === "END DATE") {
            //return <td>Superviser</td>
            return <td
                // dragOver={defaultColumns[index] === dragOver}
                className="subtask-cat">{data.end_date}</td>
        }

        if (headerName === "TIME TRACKING") {
            //return <td>Superviser</td>
            return <td
                // dragOver={defaultColumns[index] === dragOver}
                className="subtask-cat">Milestone</td>
        }
        if (headerName === "SUPERVISER") {
            //return <td>Superviser</td>
            return <td
                // dragOver={defaultColumns[index] === dragOver}
                className="subtask-cat">Milestone</td>

        }
        if (headerName === "ASSIGNEE") {
            //return <td>Superviser</td>
            return <td
                // dragOver={defaultColumns[index] === dragOver}
                className="subtask-cat">Milestone</td>
        }
        if (headerName === "DUE DATE") {
            //return <td>Superviser</td>
            return <td
                // dragOver={defaultColumns[index] === dragOver}
                className="subtask-cat">Milestone</td>
        }
        if (headerName === "PRIORITY") {
            //return <td>Superviser</td>
            return <td
                // dragOver={defaultColumns[index] === dragOver}
                className="subtask-cat">Milestone</td>
        }
        if (headerName === "TIME ESTIMATE") {
            //return <td>Superviser</td>
            return <td
                // dragOver={defaultColumns[index] === dragOver}
                className="subtask-cat">Milestone</td>
        }

        if (headerName === "STATUS") {
            return <td
                className="milestone-td-status status cursor-pointer">
                <StatusDropdown toggelButton={<button type="button" style={{
                    backgroundColor: ((data.status_det&&data.status_det.name=="Completed") ?'rgb(116, 203, 128)':(data.status_det&&data.status_det.name=="Active")?'rgb(245, 107, 98)':'gray'),
                }} >
                    <span className="visually-hidden">{data.status_det ? data.status_det.name : 'NA'} </span><FontAwesomeIcon icon={faCaretDown} />
                </button>} items={[{ name: 'Active', color: 'rgb(245, 107, 98)' }, { name: 'Completed', color: 'rgb(116, 203, 128)' }]} onSelect={(value) => {
                    // updateTask(data, "custom_status", value.id)
                }} />
                {/* <UncontrolledDropdown>
          <DropdownToggle
            style={{
              backgroundColor: (data.status ? data.status.color_code : 'gray'),
            }}
          >
            {data.status ? data.status.name : 'NA'}
          </DropdownToggle>
          <DropdownMenu style={props.id == 'tasklist' ? { maxHeight: '100px', overflowY: 'auto' } : {}}>
            {taskLayout.status_details && taskLayout.status_details.map((value, i) => {
              return (
                <DropdownItem
                  id={"status-click-id" + data.id + value.name}
                  style={{
                    backgroundColor: value.color,
                    color: "#fff",
                  }}
                  onClick={async (e) => {
                    updateTask(data, "custom_status", value.id)

                  }
                  }
                >
                  {value.name}
                </DropdownItem>
              );
            })}
          </DropdownMenu>
        </UncontrolledDropdown> */}
            </td>


        }

    }

    const getStyleForSubtask = (levels) => {
        return { paddingLeft: 6, minWidth: '215px !important' }
    }
    const getTableRenderRow = (tasks, levels, groupValue, parent, index) => {
        var a = uuidv4();
        var b = uuidv4();
        a = a + b;
        var subtaskuniqueID = a.replace(/[0-9]/g, "");
        let taskEstHr = "00";
        let taskEstMin = "00";

        {
            return (
                <>
                    {/* {getStatusOfGroupBy(groupBy, tasks, groupValue) ? */}{getStatusOfGroupBy(groupBy, tasks, groupValue) ?
                        <tr key={index}>

                            <td style={getStyleForSubtask(levels)} className="cursor-pointer ">

                                {/* <div className="subtask-parent-name">{((props.taskFilterSetting && props.taskFilterSetting.SubtaskParntNames) || (taskFilterSetting.SubtaskParntNames)) ? showSubtaskParentNames(tasks) : ""}</div> */}
                                <div className={props.taskLocationShow ? "cont-box" : "task-location-content "}>

                                    {tasks.subtasks && groupBy == 'Pipeline' ? (
                                        <span
                                       
                                            id={subtaskuniqueID}
                                            className="mainTaskArrow d-none"

                                        >
                                            <FontAwesomeIcon
                                                id={"collap" + tasks.id_string}
                                                icon={
                                                    tasks.isOpen || (tasks.Subtask && tasks.Subtask.length > 0) ?
                                                        faCaretDown :
                                                        faCaretRight
                                                }
                                            // onClick={() => {
                                            //   toggleTaskIcons(tasks.id_string);
                                            // }}
                                            />
                                        </span>
                                    ) : (
                                        <span
                                            id={subtaskuniqueID}
                                            className="mainTaskArrow "

                                        >
                                            {""}
                                        </span>
                                    )}


                                    <StatusDropdown toggelButton={<DropdownToggle
                                        className={'task-status-icon-left task-icon-bg'}
                                        style={{
                                            backgroundColor: ((tasks.status_det&&tasks.status_det.name=="Completed") ?'rgb(116, 203, 128)':(tasks.status_det&&tasks.status_det.name=="Active")?'rgb(245, 107, 98)':'gray'),
                                        }}
                                    >
                                        {""}
                                    </DropdownToggle>} items={[{ name: 'Active', color: 'rgb(245, 107, 98)' }, { name: 'Completed', color: 'rgb(116, 203, 128)' }]} onSelect={(value) => {
                                        // updateTask(tasks, "custom_status", value.id)
                                    }} />



                                    <span
                                        id={"OpenUrlModal" + tasks.id_string}
                                        onClick={() => { }
                                            //   openUpdateModal(tasks, "task")
                                        }
                                        className="task-name-highlight"
                                    // hidden={editNameTask && (tasks.id_string === editNameTask._id)}
                                    >
                                        {tasks.name}

                                    </span>
                                    {/* <input
                   
                    type="text"
                    name="TaskName"
                    className="Task-name-input"
                    // value={editNameTask ? editNameTask.name : ''}
                    id={tasks.id_string}
                    // onChange={(e) => handleTaskNameChange(e)}
                    onKeyUp={(e) => {
                      if (e.key == "Enter") {
                        // enterTaskName()
                      }
                    }}
                  /> */}
                                    {/* <span className="task-name-highlight cursor-pointer">
                  {tasks.dependency&&tasks.dependency.predecessor&&tasks.dependency.predecessor.length > 0 ?
                    <>
                      {isDependencyBothExist(tasks) ?
                        <img
                          className="minus-circle-v"
                          src={minusCircle}
                          onClick={() => {
                            // setShowDependencyList(tasks.DependentOn)
                            // setShowDependencyPopUp(true)
                          }}
                        />



                        : <FontAwesomeIcon
                          onClick={() => {
                           
                          }}
                          icon={faMinusCircle}
                          color={
                            tasks.DependentOn[0].type == "Blocking"
                              ? "#f40600"
                              : "#FFA500"
                          }
                        />}

                    

                    </>
                    : <></>
                  }
                </span> */}





                                    {/* <PlusSquareOutlined
                    style={{ fontSize: 11, marginLeft: 5 }}
                    onClick={() => {
                      setParentObject(tasks)
                      handleOpenTaskModel("subtask")
                    }
                    }
                    id={"create-subtask-tip" + tasks.id_string}
                    className="ms-2 openTask-popup"
                  /> */}
                                    {/* <UncontrolledTooltip
                    placement="top"
                    target={"create-subtask-tip" + tasks.id_string}

                  >
                    Create Subtask
                  </UncontrolledTooltip>

                  <span className="ms-2 testing-tesk-pen">
                    <FontAwesomeIcon
                      className="ms-2 openTask-popup"
                      style={{ fontSize: 10, marginLeft: 5 }}
                      icon={faPen}
                      id={"rename-tooltip" + tasks.id_string}
                      onClick={() => editableField(tasks)}
                    />
                    <UncontrolledTooltip
                      placement="top"
                      target={"rename-tooltip" + tasks.id_string}

                    >
                      Rename
                    </UncontrolledTooltip>
                  </span> */}
                                </div>
                                <span className="task-name-highlight cursor-pointer">
                                    {false ?
                                        <>
                                            {/* <AntdPopover trigger={'click'} placement='top' content={getDependencyContent(tasks.DependentOn)}> */}
                                            {false ?
                                                <img
                                                    className="minus-circle-v"
                                                    src={faMinusCircle}
                                                    onClick={() => {
                                                        // setShowDependencyList(tasks.DependentOn)
                                                        // setShowDependencyPopUp(true)
                                                    }}
                                                />



                                                : <FontAwesomeIcon
                                                    onClick={() => {
                                                        // setShowDependencyList(tasks.DependentOn)
                                                        // setShowDependencyPopUp(true)
                                                    }}
                                                    icon={faMinusCircle}
                                                    color={
                                                        tasks.DependentOn[0].type == "Blocking"
                                                            ? "#f40600"
                                                            : "#FFA500"
                                                    }
                                                />}
                                        </>
                                        : <></>
                                    }
                                </span>
                            </td>
                            {
                                addColumns.map(col => {
                                    if (col.isVisible) {
                                        return getTaskTdAsperHeader(tasks, col.name)
                                    }
                                })}
                            <td></td>
                        </tr>
                        : ''}
                    {/* {HelperFnGetRecursive(tasks, index)} */}
                </>
            );
        }
    }

    const getRowMaps = (one, groupValue) => {
        let returnArr = [];
        returnArr.push(getTableRenderRow(one, 1, groupValue, one))
        return returnArr;

    }

    const getGroupVal=(groupValue)=>{
        if(groupValue){
            groupValue=groupValue.replaceAll(props.removeString,'');
            groupValue=groupValue.replace('-','');
            return groupValue;
        }
       
    }
    return (
        <div style={{height:'calc(100vh - 285px)'}} className={'task-cards ml-0 mr-0'}>
            {groupByField ? (
                groupByField.map((groupValue, i) => {
                    if (!groupValue) {
                        return ''
                    }
                    return (
                        <>
                            {/* <div className="task-table-custom-main"> */}
                            <div className="fixed-contentleft-head">
                                <div style={{  justifyContent: 'left' }} className="task-card-title">
                                    <span id={"toggle-group" + groupValue.replace(/ /g, "")} className="task-toggle-down">
                                        <FontAwesomeIcon

                                            icon={
                                                getGroupIcon(groupValue.replace(/ /g, ""))
                                                    ? faChevronDown
                                                    : faChevronRight
                                            }
                                            onClick={() => {
                                                toggleGroupIcon(groupValue.replace(/ /g, ""))
                                            }
                                            }
                                        />
                                    </span>
                                    {<span style={{ fontSize: '14px' }} className="mx-2 text-truncate">{getGroupVal(groupValue)}</span>}


                                </div>
                            </div>

                            <div className="table-responsive ">

                                <UncontrolledCollapse
                                    // style={{ overflow: 'auto' }}
                                    toggler={
                                        "toggle-group" + groupValue.replace(/ /g, "")
                                    }

                                    isOpen={getGroupIcon(groupValue.replace(/ /g, ""))}
                                >
                                    <table className={"table task-table task-content-table table-hover milestone-table"} >
                                        <thead>
                                            <th
                                                // style={{minWidth:215}}
                                                className="min-width-250 task_table_header"

                                            >
                                            </th>
                                            {addColumns.map((column, index) => {
                                                if (column.isVisible) {
                                                    return <th
                                                    //   id={defaultColumns[index]}
                                                    //   key={defaultColumns[index]}
                                                    //   draggable
                                                    //   onDragStart={handleDragStart}
                                                    //   onDragOver={handleDragOver}
                                                    //   onDrop={handleOnDrop}
                                                    //   onDragEnter={handleDragEnter}
                                                    //   dragOver={defaultColumns[index] === dragOver}
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faGripVertical}
                                                            className="drag-table-icon"
                                                        />
                                                        {column.name}
                                                    </th>

                                                } else {
                                                    return ''
                                                }
                                            })}
                                            {/* <th className="add-column">

                          <span

                          // onClick={()=>setOpenAddColumnPopUpDealId(projectId)}
                            // className="float-r paddding-0 font-weight-bold cursor-point"
                            id={"firstplus-popup"+groupValue.replace(/ /g, "") + props.dealObj.id}
                          >
                            <FontAwesomeIcon style={{fontSize:'14px'}} icon={faPlusCircle} />

                          </span>
                        </th> */}
                                        </thead>
                                        <tbody>
                                            {firstData.map((one, index) => {
                                                if (groupBy == 'Pipeline') {
                                                    return getRowMaps(one, groupValue)
                                                } else {
                                                    return getTableRenderRow(one, 1, groupValue, one)
                                                }

                                            })}
                                            {/* {getRecursiveTable()} */}

                                            {/* {getTaskTreeTable()} */}

                                        </tbody>
                                    </table>
                                </UncontrolledCollapse>
                                {/* </Collapse> */}

                                <hr className="task-table-margin" />
                            </div>
                            {/* </div> */}
                        </>
                    );
                })
            ) : (
                <></>
            )}


        </div>

    );

}

export default TableCollapse;