import { DatePicker, Input, Modal, Button as Buttons } from 'antd';
import './Pipelines.css'
import React, { useContext, useEffect, useState } from 'react'
import Select from 'react-select';
import _ from 'lodash';
import { Button, Col, Container, Row } from 'reactstrap';
import AppContext from '../../../Context/AppContext';
import swal from 'sweetalert';
import AwsServerService from '../../../common/util/AwsServerService';

export default function CreatePipeline(props) { 
  // console.log("props", props);
  
  const userInfo=JSON.parse(localStorage.getItem("userinfo"));
  const initialData={PipelineName: ''}
  const [pipelineData,setPipelineData]=useState(initialData)    
  const [isModalOpen,setIsModalOpen]=useState(false)    
  const AppData=useContext(AppContext);
  const {dispatch,state}=AppData || {};
  const AntdbuttonStyles={cursor:'pointer',width:'5rem', fontSize:'medium',fontWeight:'500'} 

  useEffect(()=>{ 
    if(!_.isEmpty(props.data)){
      let data={...pipelineData}
   data.PipelineName=props.data.PipelineName
   data.Status={label:props.data.Status,value:props.data.Status};
  setPipelineData(data)  
  }else{
   setPipelineData(initialData);
  //  setPipelineStatuses([])
  }
  },[props.data])
  const handleSavePipeline= async () =>{
   let data={...pipelineData}; 
    if (data.PipelineName=='' || data.PipelineName==undefined) {
      swal('Alert','Please fill all the required fields!','warning');
      return;  
    }
    if (props.allPipelineData?.find(item => item?.PipelineName == data.PipelineName)) {
      swal('Alert','Pipeline with same name exists!','warning');
      return;  
    }
    // let statuses=[...pipelineStatuses];
    // data['Status']=JSON.stringify(statuses)  
    data['MODIFIEDBY']=userInfo?.email_id;    
    if(props.mode=='create'){
      data['CREATEDBY']=userInfo?.email_id;
      // console.log('sata',data);
      
    await AwsServerService.savePipelineInTemplate(data).then(async res=>{
        props.setIsOpen(false);
       await AwsServerService.getPipelineTemplateById(res[0]?.insertId).then(result=>{
          props.setViewPipelineData(result[0])
          props.setOpen(true);
          setPipelineData(initialData);
          props.setPageRefreshData(prev => !prev);
        }).catch((e)=>{

        })
         
      }).catch(err=>{
        swal('Failed','Some error occured','warning')
      });
    }else if(props.mode=='update'){
      data['ROWID']=props.data.ROWID;
      AwsServerService.updatePipelineInTemplate(data).then(res=>{
        // swal('Success','Pipeline updated successfully!','success')
        props.setPageRefreshData(prev => !prev);
        props.setIsOpen(false);
      }).catch(err=>{
        swal('Failed','Some error occured','warning')
      });
    }
  } 

  return (
    <div> 
      {/* {<ViewPipelines isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} from={'createPipeline'}/>} */}
     <Modal
     className={'createPipeline'}
     open={props.isOpen}
     style={{position:'relative',right:'7rem'}}
     title={'Create Pipeline'} 
     onCancel={()=>{props.setIsOpen(false); setPipelineData(initialData)}} 
     maskClosable={false}
     footer={<>
     <Buttons style={AntdbuttonStyles} onClick={()=>handleSavePipeline()} type="primary">Create</Buttons>
     <Buttons style={AntdbuttonStyles} onClick={()=>{props.setIsOpen(false); setPipelineData(initialData)}}>Cancel</Buttons>
     </>}
     >
     <Container>
      <Row className="align-items-center containerRow">
        <Col sm="2">
          <label htmlFor="pipeline-name">Name </label>
          <span style={{color:'red'}}>* </span>
        </Col>
        <Col sm='9'>
          <Input
             id='pipeline-name'
             placeholder='Enter Pipeline Name'
             value={pipelineData.PipelineName}
             onChange={(e)=>{
                e.target.value=e.target.value.trimStart();
                setPipelineData({...pipelineData,'PipelineName':e.target.value})
             }}
          />
        </Col>
      </Row>
    </Container> 
     </Modal>
    </div>
  )
}