import React, { useState, useEffect, useContext } from "react";
import "./subheader.css";
import {
  Col,
  FormGroup,
  PopoverBody,
  Row,
  UncontrolledPopover,
} from "reactstrap";
import {
  faAngleDown,
  faAngleLeft,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Config from "../../config.json";
import { Button, Dropdown, DropdownButton } from "react-bootstrap";
import { Dropdown as AntDropDown } from "antd";
import axios from "axios";
import httpService from "../../services/http.service";
import notes from "../../assets/images/svg/writing.svg";
import email from "../../assets/images/svg/email.svg";
import log from "../../assets/images/svg/log-file-format.svg";
import task from "../../assets/images/svg/list.svg";
import issue from "../../assets/images/svg/discuss-issue.svg";
import swal from "sweetalert";
import GetRecordById from "../util/getRecordById";
import PipelineService from "../util/PipelineService";
import ProjectService from "../util/ProjectService";
import ModuleService from "../util/ModuleService";
import refreshIcon from "../../assets/images/refresh-icon.png";
import Loader from "../loader";
import WorkdriveService from "../util/WorkdriveService";

import {
  SaveClickedForClient,
  ClientServices,
  CreateDealData,
  EditDealData,
  NycApiData,
  ActionsOfTimeClock,
  CustomeRefreshButton,
  addTimeSheetClicked,
  hrActiveKey,
  ClientFullServices,
  billingTerms,
  taskActiveKey
} from "../../Context/AppActions";
import AppContext from "../../Context/AppContext";
import HRTabs from "../../components/HR/TimeClock/HRTabs";
import UserService from "../util/UserService";
const SubHeader = (props) => {
  const history = props.history;
  let location = props.location;
  // useEffect(e=>{
  //   if(location){
  //     console.log("---- location in subheader",location);
  //   }
  // },[location])
  const AppData = useContext(AppContext);
  const { state, dispatch } = AppData || {};
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState({});
  const [moduleAllData, setModuleAllData] = useState([]);
  const [selectedTableforTask, setSelectedTableforTasks] = useState('tasks');
  // const [dropdownValue, setDropdownValue] = useState("All Deals");
  const [isModalOpen, setModalOpen] = useState(false);
  const [colorForIcon, setColorForIcon] = useState("#0069d9");
  const [colorForIcon2, setColorForIcon2] = useState("#0069d9");
  const editDealData = state.EditDealData || {};
  const [isEditDealClikced, setIsEditDealClikced] = useState(false);
  const [isSaveClicked, setIsSaveClicked] = useState(false);
  const [timeStamp, setTimestampt] = useState(undefined);
  const [layoutState, setLayoutState] = useState();
  const [selectedTable, setSelectedTable] = useState('timeSheet');
  const [selectedUser, setSelectedUser] = useState(null);
  const [userList, setUserList] = useState([])
  const [allusers, setAllUsers] = useState([]);

  const userInfo = JSON.parse(localStorage.getItem('userinfo'));
  const activeKey = state.hrActiveKey;

  // const [location,setLocation]=useState(props.location);
  //Hrushikesh new code starts
  const clickActionOfSaveAndCancelButton = (d) => {
    props.clickActionOfSaveAndCancelButton(d);
  };
  //hrushikesh new code ends

  useEffect(() => {
    if (location.state && location.state.module) {
      setData({});
      getJobData(location.state.module, location.state.id);
      if (location.state.action == "DetailView") {
        getModuleData(location.state.module);
      }
    }
  }, [location]);
  const ClientLayoutChanged = state.ClientLayoutChanged;
  const SelectedIdsForTimeClock = state.SelectedIdsForTimeClock;
  const isDeleteEnable = state.isDeleteEnable;
  useEffect(e => {
    // if(timeStamp!=undefined){
    dispatch(SaveClickedForClient(isSaveClicked))
    // }
  }, [isSaveClicked])

  useEffect(e => {
    dispatch(taskActiveKey({ tab: selectedTableforTask, view: "" }));
  }, [selectedTableforTask])

  const getAllUsers = () => {
    UserService.GetAllUsers()
      .then((result) => {
        result.data.users = result.data.users.filter(one => one.status == 'active');
        if (userInfo && (userInfo?.role_details.role_name == 'App User')) {
          let filterUser = result.data.users.filter(one => one.email == userInfo?.email_id);
          setAllUsers(filterUser);
          setSelectedUser(filterUser[0]);
        } else {
          setAllUsers(result.data.users);
          setSelectedUser(allusers[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      })
  }

  useEffect(() => {
    if (allusers) {
      let arrOfUsers = allusers.map((item, i) => {
        return { value: item.id, label: item.full_name };
      });
      if (userInfo && (userInfo?.role_details.role_name !== 'App User') && (activeKey && (activeKey?.view != "dayView"))) {
        arrOfUsers.unshift({ label: "All Users", value: "all" })
      }
      if (userInfo && (userInfo?.role_details.role_name == 'App User')) {
        let filterUser = allusers.filter(one => one.email == userInfo?.email_id);
        setSelectedUser(filterUser[0]);
      } else {
        setSelectedUser(allusers[0]);
      }
      setUserList(arrOfUsers)
    }

  }, [allusers, props.refreshEnable, selectedTable, activeKey]);

  useEffect(e => {
    getAllUsers();
  }, [])

  const onChangeUserFilter = (user) => {
    setSelectedUser(user);
  }

  useEffect(e => {
    setLayoutState(ClientLayoutChanged);
  }, [ClientLayoutChanged])

  useEffect(e => {
    setSelectedUser(selectedUser);
    dispatch(hrActiveKey({ tab: selectedTable, view: "" }));
  }, [selectedTable])

  const getModuleData = (module) => {
    if (module == "Deals") {
      const backendUrl = Config.backend_url + "getAllDealPages";
      var config = {
        token: localStorage.getItem("token"),
      };
      axios
        .post(backendUrl, { ...config })
        .then(function (res) {
          setModuleAllData(res.data.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      const backendUrl = Config.backend_url + "workdrive";
      var config = {
        Type: "Get",
        url: "https://www.zohoapis.com/crm/v2/" + module,
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      };
      axios
        .post(backendUrl, { config: config })
        .then(function (res) {
          setModuleAllData(res.data.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  const getJobData = (module, id) => {
    GetRecordById(module, id)
      .then((data) => {
        data = { ...data[0] };
        setData(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function camalize(str) {
    str = str.split("");
    str[0] = str[0].toUpperCase();
    return str.join("");
  }

  const DeleteDeal = async (moduleName) => {
    if (window.confirm(`Are You sure want to delete this ${moduleName}(s)?`)) {
      if (props.selectedIds?.length > 100) {
        alert("Can not delete more than 100 records at a time!");
        return;
      } else {
        await axios
          .post(
            `${Config.backend_url}deleteRecords`,
            {
              ids: props.selectedIds,
              module: moduleName,
              token: localStorage.getItem("token"),
            }
          )
          .then((res) => {
            props.setDeleteDealFlag("Deleted");
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  const EditDealFunction = (e) => {
    if (props.selectedIds?.length != 1) {
      e.preventDefault();
    } else {
      setIsEditDealClikced(!isEditDealClikced);
      history.push({
        pathname: "/dealupdate/Deals/Update/" + props.selectedIds,
        state: { module: "Deals", action: "Update" },
      });
      props.setSelectedIds([]);
    }
  };
  const EditAccountFunction = (e) => {
    if (props.selectedIds?.length != 1) {
      e.preventDefault();
    } else {
      setIsEditDealClikced(!isEditDealClikced);
      history.push({
        pathname: "/updateclient/Accounts/Update/" + props.selectedIds,
        state: { module: "Accounts", action: "Update" },
      });
      props.setSelectedIds([]);
    }
  };

  const getHeaderForSuplementry = () => {
    return (
      <FormGroup className="createdeal-headpadding">
        {getLoader()}
        <Row className="row-mar-0">
          <Col sm={3} className="heading-text-padding">
            <div className="jobheadmain-box">
              <span className="jobnumber-heding-text">
                <strong>
                  {
                    location.state.module == "Deals" ?
                      (location.state.action == "Update"
                        ?
                        editDealData.ProjectNumber &&
                        editDealData.ProjectNumber +
                        `(Edit ${location.state.module})`
                        :
                        `Create ${location.state.module}`)
                      :
                      (location.state.action == "Update" ?
                        `Edit ${location.state.module == "Accounts" ? "Client" : location.state.module}`
                        :
                        `Create ${location.state.module == "Accounts" ? "Client" : location.state.module}`)
                  }
                </strong>
              </span>
            </div>
          </Col>
          <Col sm={5}></Col>
          <Col sm={4}>
            <div className="save-cancelbox">
              <div className="create-dealbox2">
                <Button
                  className="top-botton-pad blue-background3D"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </div>
              <div className="create-dealbox3">
                <Button
                  className="top-botton-pad blue-background3D"
                  onClick={handleSave}
                >
                  {props.sidebarCardsStatus.Permitting &&
                    localStorage.getItem("currentsidebar") == "PVSystem"
                    ? "Save"
                    : location.state.module == "Accounts" && layoutState == "ServiceAdders" ? "Save"
                      : location.state.module == "Vendors" ? "Save"
                        : "Next"
                  }
                </Button>
              </div>
            </div>
            <div>
              {/* <Modal
          className={`createdeal-cancelmodal`}
          isOpen={isModalOpen}
          toggle={handleCancel}
        >
          <div className="modal-header-className">
            Confirmation Message
            <div className="note-header-icon">
              {" "}
              <FontAwesomeIcon
                // icon={faTimes}
                className="icon-time-color"
                onClick={() => setModalOpen(!isModalOpen)}
              ></FontAwesomeIcon>
            </div>
          </div>
          <ModalBody  style={{height : "25vh" , borderRadius : "10px" , border: "none"}}>
            <FormGroup className="marb-16 padtop-15 sureMessage">
              <Row>
                <Col sm={2}></Col>
                <Col sm={10}>
                  <span className="text-bold" for="l2code" style={{fontSize : "15px"}}>
                    Are you sure you want to cancel?
                  </span>
                </Col>

                <Col sm={1}></Col>
              </Row>
            </FormGroup>
            <FormGroup className="marb-16 padtop-15 messageButton">
              <Row>
                <Col sm={2}></Col>
                <Col sm={4}>
                  <Button
                    className="top-botton-pad blue-background3D"
                    onClick={() => {
                      props.setWarningFlag(false)
                      setModalOpen(false);
                      localStorage.removeItem("preliminayData");
                      localStorage.removeItem("nycData");
                      localStorage.removeItem("homeownerData");
                      localStorage.removeItem("permittingData");
                      localStorage.removeItem("pvSystemData");
                      localStorage.removeItem("street");
                      localStorage.removeItem("nycOption");
                      localStorage.removeItem("lng");
                      localStorage.removeItem("borough");
                      localStorage.removeItem("state");
                      localStorage.removeItem("houseNo");
                      localStorage.removeItem("projectAddress");
                      localStorage.removeItem("city");
                      localStorage.removeItem("lat");
                      localStorage.removeItem("zipcode");
    
                      if(location.state.action == 'Update'){
                        history.push('/d/Deals/DetailView/' + location.state.id);
                      }else{
                          history.push('/deallist/Deals/listview');
                      }
                      dispatch(EditDealData({}));
                      dispatch(CreateDealData({}));
                     
                    //  props.setEditDealDetails(0);
                    }}
                  >
                    Yes
                  </Button>{" "}
                </Col>
                <Col sm={4}>
                  <Button
                    className="top-botton-pad blue-background3D"
                    onClick={() => setModalOpen(false)}
                    style={{background: "#fff", color : "#0069d9"}}
                  >
                    No
                  </Button>
                </Col>
                <Col sm={3}></Col>
              </Row>
            </FormGroup>
          </ModalBody>
        </Modal> */}
            </div>
          </Col>
        </Row>
        <div></div>
      </FormGroup>
    );
  };

  const wwwViolationHeaders = [
    { value: "Name", label: "Violation Index", oldKey: "violationIndex" },
    {
      value: "Violation_Number",
      label: "Violation Number",
      oldKey: "violationNum",
    },
    {
      value: "Violation_Type",
      label: "Violation Type",
      oldKey: "violationType",
    },
    { value: "L2_Code", label: "L2 Code", oldKey: "ltwocode" },
    {
      value: "Civil_Penalty_Payment_Required",
      label: "Civil Penalty Payment Required",
      oldKey: "Civilpenalty",
    },
    {
      value: "Paid_Under_Job_Number",
      label: "Paid Under Job Number",
      oldKey: "jobNo",
    },
    { value: "Invoice_Number", label: "Invoice Number", oldKey: "invoiceNo" },
    // { value: "ISN_Number", label: "ISN Number" },
  ];

  const getAccountActions = () => {
    const items = [
      {
        label: <a
          style={
            props.selectedIds?.length == 0 || props.selectedIds?.length < 1
              ? { ...disableCss }
              : {}
          }
          onClick={(e) => {
            props.selectedIds?.length == 0 || props.selectedIds?.length < 1
              ? e.preventDefault()
              : DeleteDeal("Accounts");
          }}
        >Delete</a>,
        key: "0",
      },
      {
        label: <a
          style={props.selectedIds?.length == 1 ? {} : { ...disableCss }}
          onClick={(e) => {
            EditAccountFunction(e);
          }}
        >Edit Client</a>,
        key: "1",
      },
      {
        label: <a
          href="#/action-3"
          style={props.selectedIds?.length == 1 ? {} : { ...disableCss }}
          // onClick={(e) => {
          //   props.selectedIds?.length != 1 ? e.preventDefault() : <></>;
          // }}
          onClick={(e) => {
            e.preventDefault();
          }}
        >Email</a>,
        key: "2",
      },
      {
        label: <a
          style={props.selectedIds?.length > 0 ? {} : { ...disableCss }}
          onClick={(e) => {
            props.selectedIds?.length == 0
              ? e.preventDefault()
              : props.clientListTriggers({
                type: "downloadExcel",
                status: "download",
              });
          }}
        >Export</a>,
        key: "3",
      },
      {
        label: <a>Import</a>,
        key: "4",
      },
      {
        label: <a
          className="Bold-Highlited-Word"
          onClick={() => {
            props.clientListTriggers({ type: "EditColumns" });
          }}
        >Manage Columns</a>,
        key: "5",
      },
    ];
    return (
      <AntDropDown
        style={{ color: "none" }}
        className="Actions-btn-subheader action"
        overlayClassName="Actions-btn-subhdr"
        menu={{
          items,
        }}
        arrow={{
          pointAtCenter: true,
        }}
        trigger={["click"]}
      >
        <Button
          className="Action-Btn-Ant"
          variant="outline-primary"
          onClick={(e) => e.preventDefault()}
        >
          Actions
          <FontAwesomeIcon
            className="Action-Btn-Ant-Dropdown"
            icon={faAngleDown}
          />
        </Button>
      </AntDropDown>
    )
  }

  const getTimeClockActions = () => {
    const items = [
      {
        label: <a
          style={isDeleteEnable || (userInfo && (userInfo?.role_details.role_name == 'App User')) ? { ...disableCss } :
            ((SelectedIdsForTimeClock?.length == 0 || SelectedIdsForTimeClock?.length < 1)
              ? { ...disableCss }
              : {})
          }
          onClick={(e) => {
            if (SelectedIdsForTimeClock?.length == 0 || SelectedIdsForTimeClock?.length < 1 || (userInfo && (userInfo?.role_details.role_name == 'App User'))) {
              e.preventDefault()
            } else {
              dispatch(ActionsOfTimeClock("Delete"))
              // DeleteDeal("Accounts");
            }
          }}
        >Delete</a>,
        key: "0",
      },
      {
        label: <a
          style={SelectedIdsForTimeClock?.length > 0 ? {} : { ...disableCss }}
          onClick={(e) => {
            if (SelectedIdsForTimeClock?.length == 0) {
              e.preventDefault()
            } else {
              // props.clientListTriggers({
              //     type: "downloadExcel",
              //     status: "download",
              //   });
              dispatch(ActionsOfTimeClock("Export"))
            }
          }}
        >Export</a>,
        key: "1",
      },
      {
        label: <a>Import</a>,
        key: "2",
      },
      {
        label: <a
          className="Bold-Highlited-Word"
          onClick={() => {
            dispatch(ActionsOfTimeClock("EditColumns"))
          }}
        >Manage Columns</a>,
        key: "3",
      },
    ];
    return (
      <AntDropDown
        style={{ color: "none" }}
        className="Actions-btn-subheader action"
        overlayClassName="Actions-btn-subhdr"
        menu={{
          items,
        }}
        arrow={{
          pointAtCenter: true,
        }}
        trigger={["click"]}
      >
        <Button
          className="Action-Btn-Ant"
          variant="outline-primary"
          onClick={(e) => e.preventDefault()}
        >
          Actions
          <FontAwesomeIcon
            className="Action-Btn-Ant-Dropdown"
            icon={faAngleDown}
          />
        </Button>
      </AntDropDown>
    )
  }

  const getVendorActions = () => {
    const items = [
      {
        label: <a
          style={isDeleteEnable ? { ...disableCss } :
            ((SelectedIdsForTimeClock?.length == 0 || SelectedIdsForTimeClock?.length < 1)
              ? { ...disableCss }
              : {})
          }
          onClick={(e) => {
            if (SelectedIdsForTimeClock?.length == 0 || SelectedIdsForTimeClock?.length < 1) {
              e.preventDefault()
            } else {
              dispatch(ActionsOfTimeClock("Delete"))
              // DeleteDeal("Accounts");
            }
          }}
        >Delete</a>,
        key: "0",
      },
      {
        label: <a
          style={SelectedIdsForTimeClock?.length > 0 ? {} : { ...disableCss }}
          onClick={(e) => {
            if (SelectedIdsForTimeClock?.length == 0) {
              e.preventDefault()
            } else {
              // props.clientListTriggers({
              //     type: "downloadExcel",
              //     status: "download",
              //   });
              dispatch(ActionsOfTimeClock("Export"))
            }
          }}
        >Export</a>,
        key: "1",
      },
      {
        label: <a>Import</a>,
        key: "2",
      },
      {
        label: <a
          className="Bold-Highlited-Word"
          onClick={() => {
            dispatch(ActionsOfTimeClock("EditColumns"))
          }}
        >Manage Columns</a>,
        key: "3",
      },
    ];
    return (
      <AntDropDown
        style={{ color: "none" }}
        className="Actions-btn-subheader action"
        overlayClassName="Actions-btn-subhdr"
        menu={{
          items,
        }}
        arrow={{
          pointAtCenter: true,
        }}
        trigger={["click"]}
      >
        <Button
          className="Action-Btn-Ant"
          variant="outline-primary"
          onClick={(e) => e.preventDefault()}
        >
          Actions
          <FontAwesomeIcon
            className="Action-Btn-Ant-Dropdown"
            icon={faAngleDown}
          />
        </Button>
      </AntDropDown>
    )
  }
  const uploadViolations = async (
    folderId,
    violations,
    violationDocs,
    extensions,
    filing_folder_id,
    bin
  ) => {
    let isEditFlag = localStorage.getItem("isUpdateDeal");
    let result = filing_folder_id;
    let doArr = [];
    if (result.length > 0) {
      violations.forEach(async (one) => {
        let vDoc = violationDocs.violationDocs?.find(
          (e) => e.id.toUpperCase() == one.violationNum.toUpperCase()
        );
        let vsDoc = violationDocs.violationSummaryDocs?.find(
          (e) => e.id.toUpperCase() == one.violationNum.toUpperCase()
        );
        let fileName =
          extensions.fileExtention +
          "_" +
          one.violationType +
          " Violation Copy " +
          one.violationNum +
          ".pdf";
        let fileNameForViolationSum =
          extensions.fileExtention +
          "_" +
          one.violationType +
          " Violation Summary " +
          one.violationNum +
          ".pdf";
        try {
          let r = await uploadSingleFile(
            result[0].id,
            one.violationNum,
            fileName,
            vDoc?.violationDoc,
            isEditFlag
          );
          doArr.push(r);
        } catch (e) {
          if (vDoc?.violationDoc) {
            doArr.push(
              await WorkdriveService.handleWorkdriveUploadFile(
                fileName,
                vDoc?.violationDoc,
                result[0].id
              )
            );
          }
        }
        try {
          if (vsDoc?.violationSummaryDocs) {
            doArr.push(
              await WorkdriveService.handleWorkdriveUploadFile(
                fileNameForViolationSum,
                vsDoc?.violationSummaryDocs,
                result[0].id
              )
            );
          } else {
            let r = await handleClickDownloadViolationSummary(
              result[0].id,
              one.violationNum,
              fileNameForViolationSum,
              vsDoc?.violationSummaryDocs,
              isEditFlag,
              one,
              bin
            );
            doArr.push(r);
          }
        } catch (e) {
          console.log(e);
        }

        if (one.jobNo) {
          let doc = violationDocs.paidUnderJobDocs?.find(
            (e) => e.id.toUpperCase() == one.jobNo.toUpperCase()
          );
          let fileNameForJob =
            extensions.fileExtention +
            "_Violation Paid Application " +
            one.jobNo +
            ".pdf";
          if (doc?.JobDoc) {
            await WorkdriveService.handleWorkdriveUploadFile(
              fileNameForJob,
              doc.JobDoc,
              result[0].id
            );
          }
        }
        if (one.invoiceNo) {
          let doc = violationDocs.invoiceDocs?.find(
            (e) => e.id.toUpperCase() == one.invoiceNo.toUpperCase()
          );
          let fileNameForInvoice =
            extensions.fileExtention +
            "_Violation Paid Invoice " +
            one.invoiceNo +
            ".pdf";
          if (doc?.invoiceDoc) {
            await WorkdriveService.handleWorkdriveUploadFile(
              fileNameForInvoice,
              doc.invoiceDoc,
              result[0].id
            );
          }
        }
        // if(vsDoc && one.violationNum){
        //     let fileNameForViolationSum=extensions.fileExtention+'_'+one.violationType+' Violation Summary '+one.violationNum+extensions.todaysDateExtension+'.pdf'
        //     if(vsDoc?.violationSummaryDocs){
        //         await WorkdriveService.handleWorkdriveUploadFile(fileNameForViolationSum,vsDoc.violationSummaryDocs,result[0].id)
        //     }
        // }
      });
    }
    // return Promise.all(doArr)
  };

  const handleUploadFile = async (
    folderId,
    violations,
    violationDocs,
    extensions,
    filing_folder_id,
    bin
  ) => {
    if (filing_folder_id) {
      await uploadViolations(
        folderId,
        violations,
        violationDocs,
        extensions,
        [{ id: filing_folder_id }],
        bin
      );
      return true;
    } else {
      return false;
    }
    // return new Promise(async (resolve,reject)=>{
    //     await WorkdriveService.GetFiles(folderId)
    //     .then(async (result) => {
    //         result = result.data.filter((one) => one.attributes.name == 'Filing')
    //         await uploadViolations(folderId, violations,violationDocs,extensions,result)
    //     })
    //     .then((result)=>{
    //         resolve('Upload')
    //     })
    //     .catch((err) => {
    //         reject(err);
    //     })
    // })
  };

  const renameFileName = async (data, rename) => {
    if (rename) {
      if (data.fileId) {
        let name = rename;
        await axios.post(Config.backend_url + "archiveFile", {
          file_id: data.fileId,
          file_name: name,
          accessToken: localStorage.getItem("token"),
        });
      }
    }
  };
  // new methods
  const getTenDigitNumberWithPrefixZero = (number) => {
    const max = 10;
    const remain = max - number.length;
    let strPrefix = "";
    for (let i = 0; i < remain; i++) {
      strPrefix += "0";
    }
    return strPrefix + number;
  };
  let returnViolationDObLink = (isnNumber, number, bin) => {
    if (isnNumber) isnNumber = getTenDigitNumberWithPrefixZero(isnNumber);
    return `https://a810-bisweb.nyc.gov/bisweb/ActionViolationDisplayServlet?requestid=2&allbin=${bin}&allinquirytype=BXS3OCV4&allboroughname=&allstrt=&allnumbhous=&allisn=${isnNumber}&ppremise60=${number}`;
  };
  let returnViolationLink = (value) => {
    return `https://a810-bisweb.nyc.gov/bisweb/ECBQueryByNumberServlet?ecbin=${value}`;
  };

  const handleClickDownloadViolationSummary = async (
    folderId,
    value,
    fileName,
    file,
    isedit,
    info,
    bin
  ) => {
    let baseUrl = `https://catly-795086611.development.catalystserverless.com/server/apiservice/generatepdf`;
    let url =
      info.violationType == "ECB"
        ? returnViolationLink(info.violationNum)
        : returnViolationDObLink(info.isnNumber, info.violationNum, bin);
    let payLoad = {
      url: url,
    };
    await axios.post(baseUrl, payLoad).then(async (r) => {
      await WorkdriveService.handleWorkdriveUploadFile(
        fileName,
        r.data,
        folderId
      );
    });
  };

  const uploadSingleFile = async (folderId, value, fileName, file, isedit) => {
    return new Promise(async (resolve, reject) => {
      await WorkdriveService.DownloadECBViolationFile(value)
        .then(async (result) => {
          let bin = atob(result.replace("data:application/pdf;base64,", ""));
          let size = Math.round(bin.length / 1024);
          if (size > 2 && isedit == "false") {
            return await WorkdriveService.handleWorkdriveUploadFile(
              fileName,
              result,
              folderId
            );
          } else {
            if (file) {
              return await WorkdriveService.handleWorkdriveUploadFile(
                fileName,
                file,
                folderId
              );
            } else if (size <= 2 && isedit == "false") {
              return await WorkdriveService.handleWorkdriveUploadFile(
                fileName,
                result,
                folderId
              );
            }
          }
        })
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const getBase64 = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
    });
  };

  const isNotValid = (field, data) => {
    if (data == '' || data == undefined) {
      return true;
    }
    if (location.state.module == 'Accounts') {
      if (field == 'Shipping_House_Number' || field == "Billing_House_Number" || field == "Website") {
        return false;
      }
      if (field == "Phone") {
        var value1 = data.replace(/[-#*$+/?=%() ]/g, "");
        let onlyNo = value1.replace(/\D/g, '');
        if (onlyNo.length == 10) {
          return false;
        } else {
          return true;
        }
      } else if (field == "Billing_Code") {
        if (data.length == 5) {
          return false;
        } else {
          return true;
        }
      } else if (field == "Shipping_Code") {
        if (data.length == 5) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    } else {
      if (field == "Zip_Code") {
        if (data.length == 5) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    }
  }

  const clearFileFromZohoFiles = async (data, rename) => {
    if (rename) {
      if (data.fileId) {
        let name = rename;
        await axios.post(Config.backend_url + "archiveFile", {
          file_id: data.fileId,
          file_name: name,
          accessToken: localStorage.getItem("token"),
        });
      }
    } else if (data.fileId) {
      let name = data.filename.replace(".PDF", "") + "ARCHIVE.PDF";
      await axios.post(Config.backend_url + "archiveFile", {
        file_id: data.fileId,
        file_name: name,
        accessToken: localStorage.getItem("token"),
      });
    }
  };
  async function handleSaveClientService(data, id) {
    if (data && data.length > 0) {
      let config = {
        Type: "Create",
        Method: "Post",
        data: {
          Account_Id: id,
          services: data,
          fullService: state?.ClientFullServices ? state?.ClientFullServices : [],
          billingTerms: state?.billingTerms ? [state?.billingTerms] : [],
        }
      }
      await axios.post(Config.backend_url + "handleservice", { config: config }).then(e => {
        dispatch(ClientServices([]));
        dispatch(ClientFullServices([]));
        dispatch(billingTerms([]))
      }).catch(e => {
        console.log(e)
      })
    }
  }
  async function handleUpdateClientService(data, id) {
    if (data && data.length > 0) {
      let config = {
        Type: "Update",
        Method: "Post",
        data: {
          Account_Id: id,
          services: data,
          fullService: state?.ClientFullServices ? state?.ClientFullServices : [],
          billingTerms: state?.billingTerms ? [state?.billingTerms] : [],
        }
      }
      await axios.post(Config.backend_url + "handleservice", { config: config }).then(e => {
        dispatch(ClientServices([]));
        dispatch(ClientFullServices([]));
        dispatch(billingTerms([]));
      }).catch(e => {
        console.log(e)
      })
    }
  }
  const sections = [{
    "ClientInfo": {
      Account_Name: 'Account_Name',
      Parent_Account: 'Parent_Account',
      Color: 'Color',
      Project_Type: 'Project_Type',
      Phone: 'Phone',
      // Website: 'Website',
      // Account_Site: 'Account_Site',
    },
    "BillingDetails": {
      Billing_Address: "Billing_Address",
      Billing_Street: "Billing_Street",
      Billing_City: "Billing_City",
      Billing_State: "Billing_State",
      Billing_Code: "Billing_Code",
      // Billing_Country: "Billing_Country"
    },

    "ShippingDetails": {
      Shipping_Address: "Shipping_Address",
      Shipping_Street: 'Shipping_Street',
      Shipping_City: "Shipping_City",
      Shipping_State: "Shipping_State",
      Shipping_Code: "Shipping_Code",
      // Shipping_Country: "Shipping_Country",
    },
    "FullServices": {
      // Shipping_Address: "Shipping_Address",
      // Shipping_Street: 'Shipping_Street',
      // Shipping_City: "Shipping_City",
      // Shipping_State: "Shipping_State",
      // Shipping_Code: "Shipping_Code",
      // Shipping_Country: "Shipping_Country",
    },
    "BillingTerms": {
      Contract_Price: "Contract_Price",
      Tier_Number: "Tier_Number"
    },
    "ServiceAdders": null,
  }
  ]

  let Shipping_Arr = ["Shipping_Country", "Shipping_House_Number", "Shipping_Code", "Shipping_State", "Shipping_City", "Shipping_Street", "Shipping_Address", "Shipping_Address_Different_Than_Billing_Adderss"]
  let ignoreArray = ["Client_Nick_Name", "Billing_House_Number", "Website", "Shipping_House_Number", "Color", "Service_Adders", "Account_Site"]
  const handleSave = async (e) => {
    setTimestampt(e.timeStamp);
    setIsSaveClicked(!isSaveClicked)
    if (location.state.module == 'Accounts') {
      let flag = false;
      let data = JSON.parse(localStorage.getItem("data"));
      if (
        sections[0][layoutState] != null ||
        sections[0][layoutState] != undefined
      ) {

        let serviceFlag = 0;
        Object.keys(sections[0][layoutState]).forEach((one) => {
          if (isNotValid(one, data[one]) && one !== 'Color') {
            flag = true;
          }
        })
        if (serviceFlag == -1) {
          return;
        }
        if (flag) {
          return
        }
      }
      Object.keys(data).forEach((one) => {
        if (isNotValid(one, data[one]) && !ignoreArray.includes(one)) {
          if (!data.Shipping_Address_Different_Than_Billing_Adderss && Shipping_Arr.includes(one)) {

          } else {
            flag = true;
          }
          // flag = true;
        }
      })
      if (layoutState != "ServiceAdders" && flag == false) {
        return
      } else if (flag) {
        return
      }
    }
    if (location.state.module == 'Vendors') {
      let flag = false;
      let data = JSON.parse(localStorage.getItem("data"));
      Object.keys(data).forEach((one) => {
        if (isNotValid(one, data[one]) && one != "Active") {
          flag = true;
        }
      })
      if (flag) {
        swal("Alert!", "Please fill all required fields correctly.", "warning");
        return
      }
    }
    props.setWarningFlag(false);
    if (
      (props.sidebarCardsStatus.Permitting &&
        props.sidebarCardsStatus.NavActivetab == "PVSystem") ||
      !(location.state && location.state.module == "Deals")
    ) {
      if (location.state && location.state.module == "Pipeline") {
        if (location.state.action == "create") {
          PipelineService.CreatePipeline(
            JSON.parse(localStorage.getItem("data"))
          )
            .then((result) => {
              history.goBack();
              localStorage.removeItem("data");
              // console.table(result);
            })
            .catch((err) => {
              console.log(err);
            });
        }
        if (location.state.action == "Update") {
          PipelineService.UpdatePipeline(
            JSON.parse(localStorage.getItem("data"))
          )
            .then((result) => {
              history.goBack();
              localStorage.removeItem("data");
            })
            .catch((err) => {
              console.log(err);
            });
        }
      } else if (location.state && location.state.module == "Deals") {
        props.setWarningFlag(false);
        if (!props.sidebarCardsStatus.ShouldSave) {
          alert(
            "Please check all checkboxes of current Section.(It should be checked to save form.)"
          );
          return;
        }
        if (location.state) {
          let obj = JSON.parse(localStorage.getItem("data"));

          setLoader(true);
          //hrushikesh shelke code for get deal no end
          let resultDealId = localStorage.getItem("isDealCreated");
          let dealNoOfCreatedDeal = localStorage.getItem("DealNoOfCreatedDeal");
          if (location.state.action == "create") {
            if (
              dealNoOfCreatedDeal == null ||
              dealNoOfCreatedDeal == undefined
            ) {
              await axios
                .post(Config.backend_url + "getAllDealPages", {
                  token: localStorage.getItem("token"),
                })
                .then((r) => {
                  r.data = r.data.data[0].Deal_Name;
                  let arr = r.data.split("-");
                  let d = new Date();
                  let currentY = d.getYear() + 1900 - 2000;
                  let str = "";
                  let no = parseInt(arr[1]) + 1;
                  if (currentY != arr[0].replace("S", "")) {
                    str = "S" + currentY + "-";
                  } else {
                    str = arr[0] + "-";
                  }
                  if (no < 1000) {
                    str = str + "0" + no;
                  } else {
                    str = str + no;
                  }
                  obj = { ...obj, ProjectNumber: str, Deal_Name: str };
                  localStorage.setItem("DealNoOfCreatedDeal", str);
                });
            } else {
              obj = {
                ...obj,
                ProjectNumber: dealNoOfCreatedDeal,
                Deal_Name: dealNoOfCreatedDeal,
              };
            }
          }

          //hrushikesh shelke code for get deal no end

          if (obj.Service_Requested.length == 0) {
            setLoader(false);
            swal("Please select at least one service requested", {
              dangerMode: true,
            });
            return;
          }
          obj.Service_Requested_Name = obj.Service_Requested.map(
            (one) => one.name
          ).join(",");
          obj.Service_Requested_ID = obj.Service_Requested.map(
            (one) => one.id
          ).join(",");

          let config = {
            Type: "Create",
            method: "POST",
            url:
              "https://www.zohoapis.com/crm/v2/" +
              location.state.module +
              "/upsert",
            headers: { Authorization: localStorage.getItem("token") },
            data: [obj],
          };

          if (
            resultDealId == undefined ||
            resultDealId == 0 ||
            resultDealId == null
          ) {
            await httpService
              .post("workdrive", { config: config })
              .then(async (result) => {
                localStorage.setItem(
                  "isDealCreated",
                  result.data.data[0].details.id
                );
              })
              .catch((err) => {
                setLoader(false);
                localStorage.removeItem("isDealCreated");
                swal(`${err}`);
              });
          }
          if (props.sidebarCardsStatus.RenameFilesArray) {
            for (
              let t = 0;
              t < props.sidebarCardsStatus.RenameFilesArray.length;
              t++
            ) {
              await renameFileName(
                props.sidebarCardsStatus.RenameFilesArray[t],
                props.sidebarCardsStatus.RenameFilesArray[t].newName
              );
            }
          }

          // Web_scrape_monitoring_api

          if (obj.Is_NYC_Project == "Yes") {
            await axios
              .post(Config.backend_url + "postApiAndWebScrapingMonitoring", {
                ...props.sidebarCardsStatus.webScrapeMonitor,
                Deals_ID: obj.Deal_Name,
              })
              .then((res) => { })
              .catch((err) => {
                console.log(err);
              });
          }

          resultDealId = localStorage.getItem("isDealCreated");
          dealNoOfCreatedDeal = localStorage.getItem("DealNoOfCreatedDeal");
          if (resultDealId != undefined && resultDealId != null) {
            try {
              let res = await GetRecordById(
                "Deals",
                resultDealId?.replace(",", "")
              );

              let ZohoProjectflowUrl =
                "https://flow.zoho.com/730406134/flow/webhook/incoming?zapikey=1001.d7814330a955811b3acdc58229ba6d2b.268298341dece8fdf47902366c638d0c&isdebug=false";
              for (let i = 0; i < obj.Service_Requested.length; i++) {
                /// Zoho Project Create With Service Name Refered
                if (obj.Service_Requested[i].name.trim() == "Permitting") {
                  let template = await ProjectService.GetTemplateProjects(
                    "Permitting"
                  );
                  let project = await ProjectService.CreateProject({
                    name:
                      res[0].Deal_Name + "-" + obj.Service_Requested[i].name,
                    template_id: template.id_string,
                  });
                  let projectid = res[0].Project_ID
                    ? res[0].Project_ID + "," + project.id_string
                    : project.id_string;
                  await ProjectService.UpdateDeal({
                    id: res[0].id,
                    Project_ID: projectid,
                  });
                }
              }

              while (
                !res[0].zohoworkdriveforcrm__Workdrive_Folder_ID ||
                (!res[0].zohoworkdriveforcrm__Workdrive_Folder_ID &&
                  obj.wwpViolation.length > 0)
              ) {
                await delay(2000);
                res = await GetRecordById(
                  "Deals",
                  resultDealId?.replace(",", "")
                );
              }

              //archive old files
              if (!props.sidebarCardsStatus.duplicateDeal.dealName) {
                if (props.sidebarCardsStatus.archiveDocumentArray) {
                  props.sidebarCardsStatus.archiveDocumentArray.forEach(
                    async (e) => {
                      await clearFileFromZohoFiles(e);
                    }
                  );
                }
              }
              //
              // logic for deuplicate deal start
              if (props.sidebarCardsStatus.duplicateDeal.dealName) {
                let payload = {
                  parent_folder_id:
                    res[0].zohoworkdriveforcrm__Workdrive_Folder_ID,
                  target_folder_id:
                    props.sidebarCardsStatus.duplicateDeal.dealName
                      ?.dealFolderId,
                  accessToken: localStorage.getItem("token"),
                  dealName: res[0].Deal_Name,
                };
                await axios.post(
                  Config.backend_url + "duplicateWorkdrive",
                  payload
                );
                let data1 = await axios.post(
                  Config.backend_url + "getFolderIdByName",
                  payload
                );
                while (
                  data1.data.id == "" ||
                  data1.data.id == payload.parent_folder_id
                ) {
                  await delay(2000);
                  data1 = await axios.post(
                    Config.backend_url + "getFolderIdByName",
                    payload
                  );
                }

                if (
                  data1.data.id != payload.parent_folder_id &&
                  data1.data.id != ""
                ) {
                  let tempOb = {
                    id: res[0].id,
                    zohoworkdriveforcrm__Workdrive_Folder_ID: data1.data.id,
                  };
                  // obj['id']=res[0].id;
                  // obj["zohoworkdriveforcrm__Workdrive_Folder_ID"]=data1.data.id;
                  config = {
                    Type: "Create",
                    method: "POST",
                    url:
                      "https://www.zohoapis.com/crm/v2/" +
                      location.state.module +
                      "/upsert",
                    headers: { Authorization: localStorage.getItem("token") },
                    data: [tempOb],
                  };
                  await httpService
                    .post("workdrive", { config: config })
                    .then(async (result) => { })
                    .catch((err) => {
                      swal(`${err}`);
                    });
                  res = await GetRecordById(
                    "Deals",
                    resultDealId?.replace(",", "")
                  );
                  while (
                    !res[0].zohoworkdriveforcrm__Workdrive_Folder_ID ||
                    (!res[0].zohoworkdriveforcrm__Workdrive_Folder_ID &&
                      obj.wwpViolation.length > 0)
                  ) {
                    await delay(2000);
                    res = await GetRecordById(
                      "Deals",
                      resultDealId?.replace(",", "")
                    );
                  }
                }
              }

              // return;
              // logic for deuplicate deal end

              //upload the violation if already exist

              //Hrushikesh new code starts
              let fileExtention =
                res[0].House_Number + " " + res[0].Street_Name;

              let d = new Date();
              let todaysDateExtension =
                "(" +
                (d.getMonth() + 1 < 10
                  ? "0" + (d.getMonth() + 1)
                  : d.getMonth() + 1) +
                "-" +
                (d.getDate() < 10 ? "0" + d.getDate() : d.getDate()) +
                "-" +
                (d.getYear() + 1900) +
                (" " +
                  d.getHours() +
                  ":" +
                  d.getMinutes() +
                  ":" +
                  d.getSeconds()) +
                ")";

              // if(props.sidebarCardsStatus.FilesExtention!=null){
              //     fileExtention+=props.sidebarCardsStatus.FilesExtention.houseNo?props.sidebarCardsStatus.FilesExtention.houseNo+' ':'';
              //     fileExtention+=props.sidebarCardsStatus.FilesExtention.street?props.sidebarCardsStatus.FilesExtention.street:'';
              // }

              let filing_folder_id = await axios
                .post(Config.backend_url + "getFilingFolderID", {
                  id: res[0].zohoworkdriveforcrm__Workdrive_Folder_ID,
                  Authorization: localStorage.getItem("token"),
                })
                .then((r) => r.data.filing_folder_id);

              let flag = handleUploadFile(
                res[0].zohoworkdriveforcrm__Workdrive_Folder_ID,
                obj.wwpViolation,
                props.sidebarCardsStatus.EditDocuments,
                {
                  fileExtention: fileExtention,
                  todaysDateExtension: todaysDateExtension,
                },
                filing_folder_id,
                res[0].Bin_Number
              );

              // upload the AsbestosCertificate
              if (props.sidebarCardsStatus.AsbestosCertificate != null) {
                if (props.sidebarCardsStatus.AsbestosCertificate.file != null) {
                  await getBase64(
                    props.sidebarCardsStatus.AsbestosCertificate.file
                  ).then(async (f) => {
                    await WorkdriveService.handleWorkdriveUploadFile(
                      fileExtention + "_CERTIFICATE OF OCCUPANCY.pdf",
                      f,
                      filing_folder_id
                    );
                  });
                }
              }
              // upload the DHCRLetter
              if (props.sidebarCardsStatus.DHCRLetter != null) {
                if (props.sidebarCardsStatus.DHCRLetter.file != null) {
                  await getBase64(
                    props.sidebarCardsStatus.DHCRLetter.file
                  ).then(async (f) => {
                    await WorkdriveService.handleWorkdriveUploadFile(
                      fileExtention + "_DHCRLETTER.pdf",
                      f,
                      filing_folder_id
                    );
                  });
                }
              }
              // upload the DeedCertificate
              if (props.sidebarCardsStatus.DeedCertificate != null) {
                if (props.sidebarCardsStatus.DeedCertificate.file != null) {
                  await getBase64(
                    props.sidebarCardsStatus.DeedCertificate.file
                  ).then(async (f) => {
                    await WorkdriveService.handleWorkdriveUploadFile(
                      fileExtention + "_DEED.pdf",
                      f,
                      filing_folder_id
                    );
                  });
                }
              }

              // upload the ACP5
              if (props.sidebarCardsStatus.ACP5 != null) {
                if (props.sidebarCardsStatus.ACP5.file != null) {
                  await getBase64(props.sidebarCardsStatus.ACP5.file).then(
                    async (f) => {
                      await WorkdriveService.handleWorkdriveUploadFile(
                        fileExtention + "_ACP5.pdf",
                        f,
                        filing_folder_id
                      );
                    }
                  );
                }
              }

              // UPLOAD TIDAL IMAGE
              if (props.sidebarCardsStatus?.tidalImage != null) {
                if (props.sidebarCardsStatus.tidalImage.image != null) {
                  await WorkdriveService.handleWorkdriveUploadFile(
                    fileExtention + "_TIDALWETLAND IMAGE.JPEG",
                    props.sidebarCardsStatus.tidalImage.image,
                    filing_folder_id
                  );
                }
              }
              // UPLOAD Flood Maps
              if (props.sidebarCardsStatus?.floodMapDocs != null) {
                if (props.sidebarCardsStatus.floodMapDocs.effictive != null) {
                  await WorkdriveService.handleWorkdriveUploadFile(
                    fileExtention + "_EFFECTIVE FLOOD MAP.PDF",
                    props.sidebarCardsStatus.floodMapDocs.effictive,
                    filing_folder_id
                  );
                }
                if (props.sidebarCardsStatus?.floodMapDocs?.preliminary != null) {
                  await WorkdriveService.handleWorkdriveUploadFile(
                    fileExtention + "_PRELIMINARY FLOOD MAP.PDF",
                    props.sidebarCardsStatus.floodMapDocs.preliminary,
                    filing_folder_id
                  );
                }
              }

              // upload flood complete

              // upload Map Number start's

              if (props.sidebarCardsStatus.mapNumberPdf != null) {
                await WorkdriveService.handleWorkdriveUploadFile(
                  fileExtention + "_MAP Number.PDF",
                  props.sidebarCardsStatus.mapNumberPdf.MapNumber,
                  filing_folder_id
                );
              }
              // upload Map Number complete

              let dataa = res[0];
              handleSavePrevSolar(
                dataa.id,
                dataa.Deal_Name,
                obj.Previous_Solar_Info,
                "Previous_Solar_Info"
              );
              let arr = [];
              if (obj.wwpViolation) {
                obj.wwpViolation.forEach((one) => {
                  let obj = {};
                  if (one.id) {
                    obj = { id: one.id };
                  }
                  wwwViolationHeaders.forEach((header) => {
                    obj[header.value] = one[header.oldKey];
                  });
                  arr.push(obj);
                });
              }
              handleSavePrevSolar(
                dataa.id,
                dataa.Deal_Name,
                arr,
                "WWP_Violations"
              );
              dataa.Created_By = dataa.Created_By.name;
              dataa.Owner = dataa.Owner.name;
              dataa.Account_Name = dataa.Account_Name
                ? dataa.Account_Name.name
                : "";
              setLoader(false);
              history.push({
                pathname:
                  "/d/Deals/DetailView/" + resultDealId?.replace(",", ""),
                state: { data: dataa, action: "DetailView", module: "Deals" },
              });
              // history.goBack();
              localStorage.removeItem("isDealCreated");
              localStorage.removeItem("DealNoOfCreatedDeal");
              localStorage.removeItem("currentsidebar");
              localStorage.removeItem("data");
              localStorage.removeItem("allitemchecked");
            } catch (e) {
              // localStorage.removeItem('allitemchecked')
              // localStorage.removeItem('currentsidebar')
              setLoader(false);
              swal(`${e}`);
            }
          }
        }
      } else {
        let dataa = JSON.parse(localStorage.getItem("data"));
        if (location.state) {

          props.clientListTriggers({ type: 'ShouldSave', status: true })
          let config = {
            Type: "Create",
            method: "POST",
            url:
              "https://www.zohoapis.com/crm/v2/" +
              location.state.module +
              "/upsert",
            headers: { Authorization: localStorage.getItem("token") },
            data: [JSON.parse(localStorage.getItem("data"))],
          };
          httpService
            .post("workdrive", { config: config })
            .then(async (result) => {

              if (location.state.module == "Accounts" && result?.data?.data[0]?.details?.id) {
                if (state.ClientFullServices && location.state.action == "create") {
                  handleSaveClientService(state.ClientFullServices, result?.data?.data[0]?.details?.id)
                } else if (state.ClientFullServices && location.state.action == "Update") {
                  handleUpdateClientService(state.ClientFullServices, result?.data?.data[0]?.details?.id)
                }
              }
              history.push({
                pathname: `/c/${location.state.module}/DetailView/${result.data.data[0].details.id}`,
                state: { data: result.data, action: 'DetailView', module: location.state.module }
              });
              props.clientListTriggers({ type: 'ShouldSave', status: false })
              localStorage.removeItem("data");
            })
            .catch((err) => {
              swal(`${err}`);
            });
        }
      }
    } else {
      props.clickActionOfSaveAndCancelButton();
    }
  };

  const handleSavePrevSolar = (id, dealName, data, Module) => {
    const backendUrl = Config.backend_url;
    let dataArr = [];
    if (data) {
      data.forEach((one) => {
        one.Deal_Name = { module: "Deals", name: dealName, id: id };
        dataArr.push(one);
      });
    }
    let config = {
      Type: "Create",
      method: "POST",
      url: "https://www.zohoapis.com/crm/v2/" + Module + "/upsert",
      headers: { Authorization: localStorage.getItem("token") },
      data: dataArr,
    };
    if (dataArr) {
      axios
        .post(backendUrl + "workdrive", { config: config })
        .then(async (result) => { })
        .catch((err) => {
          if (dataArr.length !== 0) {
            swal(`${err}`);
          }
        });
    }
    handleDeleteIds(Module);
  };

  const handleDeleteIds = (Module) => {
    let ids = localStorage.getItem("deletedIds");
    if (ids) {
      ids = JSON.parse(ids);
      let doArr = [];
      ids.forEach((id) => {
        doArr.push(ModuleService.DeleteModuleData(Module, id));
      });
      Promise.all(doArr)
        .then((result) => {
          localStorage.removeItem("deletedIds");
        })
        .catch((err) => {
          localStorage.removeItem("deletedIds");
          console.log(err);
        });
    }
  };

  const handleCancel = () => {
    props.setShowExitPrompt(true);
    if (location.state && location.state.module == "Deals") {
      if (location.state.action == "create") {
        history.push("/deallist/Deals/listview");
      } else if (location.state.action === "Update") {
        if (!isEditDealClikced) {
          history.push({
            pathname: "/d/Deals/DetailView/" + location.state.id,
            state: { data: null, action: "DetailView", module: "Deals" },
          });
        } else {
          setIsEditDealClikced(!isEditDealClikced);
          history.push("/deallist/Deals/listview");
        }
      }
    } else if (location.state && location.state.module == "Accounts") {
      if (location.state.action == "create") {
        // if(window.confirm("Are you sure you want to leave without changes save?")){
        history.push("/clientlist/Accounts/listview");
        // }
      } else {
        // if(window.confirm("Are you sure you want to leave without changes save?")){
        history.push({
          pathname: "/c/Accounts/DetailView/" + location.state.id,
          state: { data: null, action: "DetailView", module: "Accounts" },
        });
        // }
      }
    } else {
      history.goBack();
    }
  };

  const getActionsForRequest = () => {
    const items = [
      {
        label: <a onClick={() => {
          dispatch(addTimeSheetClicked({ module: "request", button: "Entry Request" }))
        }}>Entry Request</a>,
        key: "0",
      },
      {
        label: <a onClick={() => {
          dispatch(addTimeSheetClicked({ module: "request", button: "Absence Request" }))
        }}>Absence Request</a>,
        key: "1",
      },
    ];
    return (
      <div className="job-action-box">
        <AntDropDown
          style={{ color: "none" }}
          className=" action"
          menu={{
            items,
          }}
          arrow={{
            pointAtCenter: false,
          }}
          trigger={["click"]}
        >
          <Button
            className="Action-Btn-Ant"
            variant="outline-primary"
            style={{ background: "#007bff", color: "white" }}
            onClick={(e) => e.preventDefault()}
          >
            Add Request
            {/* <FontAwesomeIcon
              className="Action-Btn-Ant-Dropdown"
              icon={faAngleDown}
            /> */}
          </Button>
        </AntDropDown>
      </div>
    );
  };

  const getCreateButtonByModule = () => {
    if (location.state && location.state.module == "Pipeline") {
      return (
        <Button
          className="top-botton-pad blue-background3D"
          onClick={() => {
            history.push({
              pathname: "/app/pipelines/create",
              state: { module: "Pipeline", action: "create" },
            });
          }}
        >
          Create Pipeline
        </Button>
      );
    } else if (location.state && location.state.module == "Deals") {
      return (
        <Button
          className="top-botton-pad blue-background3D"
          onClick={async (e) => {
            localStorage.removeItem("allitemchecked");
            localStorage.removeItem("isDealCreated");
            localStorage.removeItem("currentsidebar");
            await props.getToken();
            props.setWarningFlag(false);
            history.push({
              pathname: "/dealcreate/Deals/create",
              state: { module: "Deals", action: "create" },
            });
            dispatch(EditDealData({}));
            dispatch(CreateDealData({}));
            dispatch(NycApiData({}));
          }}
        >
          Create Deal
        </Button>
      );
    } else if (location.state && location.state.module == "Accounts") {
      return (
        <Button
          className="top-botton-pad blue-background3D"
          onClick={() => {
            dispatch(ClientFullServices([]));
            history.push({
              pathname: "/createclient/Accounts/create",
              state: { module: "Accounts", action: "create" },
            });
          }}
        >
          Create Client
        </Button>
      );
    } else if (location.state && location.state.module == "Vendors") {
      return (
        <Button
          className="top-botton-pad blue-background3D"
          onClick={() => {
            history.push({
              pathname: "/vendorcreate/Vendors/create",
              state: { module: "Vendors", action: "create" },
            });
          }}
        >
          Create Vendor
        </Button>
      );
    } else if (location.state && location.state.module == "Photovoltaic_Modules") {
      return (
        <Button
          className="top-botton-pad blue-background3D"
          onClick={() => {
            history.push({
              pathname: "/app/contacts/createVendor",
              state: { module: "Photovoltaic_Modules", action: "create" },
            });
          }}
        >
          Create Photovoltaic Module
        </Button>
      );
    }
    // else if (location.state && location.state.module == "TimeClock") {
    //   if(selectedTable == "timeSheet"){
    //     // return (
    //     //   <Button
    //     //     style={{fontSize: "15px !important"}}
    //     //     className="top-botton-pad-hr blue-background3D"
    //     //     onClick={() => {
    //     //      dispatch(addTimeSheetClicked({module: "timeSheet"}))
    //     //     }}
    //     //   >
    //     //     Add Timesheet
    //     //   </Button>
    //     // );
    //   }else if(selectedTable == "request"){
    //     return (
    //       <div className="req-btn-wrapper">
    //         {/* <div style={{marginRight : "10px"}}>
    //           <Button
    //             style={{fontSize: "12px !important"}}
    //             className="top-botton-pad-hr blue-background3D"
    //             onClick={() => {
    //               dispatch(addTimeSheetClicked({module: "request", button: "Entry Request"}))
    //             }}
    //           >
    //             Add Entry Request
    //           </Button>
    //         </div>
    //         <div>
    //           <Button
    //             style={{fontSize: "12px !important"}}
    //             className="top-botton-pad-hr blue-background3D"
    //             onClick={() => {
    //               dispatch(addTimeSheetClicked({module: "request" , button: "Absence Request"}))
    //             }}
    //           >
    //             Add Absence Request
    //           </Button>
    //         </div> */}
    //     {/* {getActionsForRequest()} */}
    //       </div>
    //     );
    //   }
    //   else if(selectedTable == "shiftMapping"){
    //     return (
    //       <Button
    //         style={{fontSize: "15px !important"}}
    //         className="top-botton-pad-hr blue-background3D"
    //         onClick={() => {
    //           dispatch(addTimeSheetClicked({module: "shiftMapping"}))
    //         }}
    //       >
    //         Assign Shift <FontAwesomeIcon icon={faPlus} />
    //       </Button>
    //     );
    //   }
    // }

    else {
      return <></>;
    }
  };

  // const handelNameChange = (e) => {
  //   setDropdownValue(e.currentTarget.textContent);
  // };

  const getSimpleView = () => {
    return (
      <FormGroup className="createdeal-headpadding">
        {getLoader()}
        <Row className="row-mar-0 pt-1">
          <Col sm={3} className="heading-text-padding">
            <div className="jobhead-box">
              {/* <FontAwesomeIcon className="job-back-icon" icon={faArrowLeft}
                            onClick={() => { window.history.go(-1) }}
                        /> */}

              <span className="jobnumber-heding-text">
                {" "}
                <strong>{location.state.module}</strong>
              </span>
            </div>
          </Col>
          <Col sm={5}></Col>
          <Col sm={4} className="d-flex align-items-center">
            <div className="create-dealbox3">
              <Dropdown style={{ color: "none" }} className=" action">
                <Dropdown.Toggle
                  id="dropdown-basic"
                  className="action-text pipeline-toggle action-arrow "
                >
                  Actions
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item>Delete</Dropdown.Item>
                  <Dropdown.Item href="#/action-2">Update</Dropdown.Item>
                  <Dropdown.Item href="#/action-3">Email</Dropdown.Item>
                  <Dropdown.Item>Export</Dropdown.Item>
                  <Dropdown.Item>Import</Dropdown.Item>
                  {/* <Dropdown.Item >Manage Columns</Dropdown.Item> */}
                  <DropdownButton>Manage Columns</DropdownButton>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Col>
        </Row>
        <div></div>
      </FormGroup>
    );
  };

  const getReplaceValue = (str) => {
    if (str) {
      str = str.replaceAll("sandeepext", " ");
      str = str.replaceAll("_", " ");
      str = str.trim();
      return str;
    }
    return "";
  };
  const disableCss = {
    "pointer-events": "none",
    color: "#afafaf",
    cursor: "default",
  };
  const items = [
    {
      label: (
        <a
          style={
            props.selectedIds?.length == 0 || props.selectedIds?.length < 1
              ? { ...disableCss }
              : {}
          }
          onClick={(e) => {
            props.selectedIds?.length == 0 || props.selectedIds?.length < 1
              ? e.preventDefault()
              : DeleteDeal("Deals");
          }}
        >
          Delete
        </a>
      ),
      key: "0",
    },
    {
      label: (
        <a
          style={props.selectedIds?.length == 1 ? {} : { ...disableCss }}
          onClick={(e) => {
            EditDealFunction(e);
          }}
        >
          Edit Deal
        </a>
      ),
      key: "1",
    },
    {
      label: (
        <a
          href="#/action-3"
          style={props.selectedIds?.length == 1 ? {} : { ...disableCss }}
          // onClick={(e) => {
          //   props.selectedIds?.length != 1 ? e.preventDefault() : <></>;
          // }}
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          Email
        </a>
      ),
      key: "2",
    },
    {
      label: (
        <a
          style={props.selectedIds?.length > 0 ? {} : { ...disableCss }}
          onClick={(e) => {
            props.selectedIds?.length == 0
              ? e.preventDefault()
              : props.dealListTriggers({
                type: "downloadExcel",
                status: "download",
              });
          }}
        >
          Export
        </a>
      ),
      key: "3",
    },
    {
      label: <a>Import</a>,
      key: "4",
    },
    {
      label: (
        <a
          className="Bold-Highlited-Word"
          onClick={() => {
            props.dealListTriggers({ type: "EditColumns" });
          }}
        >
          Manage Columns
        </a>
      ),
      key: "7",
    },
  ];

  const getJobActions = () => {
    const items = [
      {
        label: <a>Duplicate</a>,
        key: "0",
      },
      {
        label: <a>Share</a>,
        key: "1",
      },
      {
        label: <a onClick={() => DeleteDeal("Deals")}>Delete</a>,
        key: "2",
      },
      {
        label: <a>Generate Paperwork</a>,
        key: "3",
      },
    ];
    return (
      <div className="job-action-box">
        <AntDropDown
          style={{ color: "none" }}
          className=" action"
          menu={{
            items,
          }}
          arrow={{
            pointAtCenter: true,
          }}
          trigger={["click"]}
        >
          <Button
            className="Action-Btn-Ant"
            variant="outline-primary"
            onClick={(e) => e.preventDefault()}
          >
            Actions
            <FontAwesomeIcon
              className="Action-Btn-Ant-Dropdown"
              icon={faAngleDown}
            />
          </Button>
        </AntDropDown>
      </div>
    );
  };

  const getAccountJobActions = () => {
    const items = [
      {
        label: <a>Duplicate</a>,
        key: "0",
      },
      {
        label: <a>Share</a>,
        key: "1",
      },
      {
        label: <a onClick={() => DeleteDeal("Accounts")}>Delete</a>,
        key: "2",
      },
      {
        label: <a>Generate Paperwork</a>,
        key: "3",
      },
    ];
    return (
      <div className="job-action-box">
        <AntDropDown
          style={{ color: "none" }}
          className=" action"
          menu={{
            items,
          }}
          arrow={{
            pointAtCenter: true,
          }}
          trigger={["click"]}
        >
          <Button
            className="Action-Btn-Ant"
            variant="outline-primary"
            onClick={(e) => e.preventDefault()}
          >
            Actions
            <FontAwesomeIcon
              className="Action-Btn-Ant-Dropdown"
              icon={faAngleDown}
            />
          </Button>
        </AntDropDown>
      </div>
    );
  };

  const handleAllDealChange = (e) => {
    if (e === "All_Deals") {
      props.setCheckType({ type: "All_Deals" });
    } else if (e === "NYC_Deals") {
      props.setCheckType({ type: "NYC_Deals" });
    } else {
      props.setCheckType({ type: "NON_NYC_Deals" });
    }
  };

  const getListHeader = (module) => {

    if (module == "Calendar") {
      return <></>;
    }
    if (module == "Deals") {
      {
        getLoader();
      }
      return (
        <FormGroup className="pad-top-5 border-bottom" style={{ display: (location && location?.pathname) === "/" ? "none" : "", margin: (location && location?.pathname) === "/setting/index" && "0px" }}>
          <Row className="row-mar-0">
            {/* <Col sm={5} className="pad-r0 pad-l20" style={{marginTop : "15px"}}>  */}
            {/* <div className="jobhead-box"> */}
            {/* <FontAwesomeIcon className="job-back-icon" icon={faArrowLeft}
                                onClick={() => { window.history.go(-1) }}
                            /> */}

            {/* <span className="jobnumber-heding-text">
                                <strong>
                                    {getReplaceValue(location.state.module)}
                                </strong>
                            </span>
                        </div> */}
            {/* </Col> */}
            <Col sm={8} style={{ marginTop: "4px", display: "flex" }}>
              <div className="pipeline-dealbox">
                <div className="create-dealbox2">

                </div>
              </div>
            </Col>
            <Col sm={4}>
              <div className="create-dealbox">
                <div className="create-dealbox2">
                  <img
                    className={state?.CustomeRefreshButton ? "rotate-refresh-icon" : ""}
                    onClick={(e) => {
                      // props.setRefreshEnable(true)
                      dispatch(CustomeRefreshButton(true));
                    }}
                    style={{
                      width: "25px",
                      marginTop: "5px",
                      cursor: "pointer",
                    }}
                    src={refreshIcon}
                  />
                </div>
                <div className="create-dealbox2">
                  {getCreateButtonByModule()}
                </div>
                <div className="create-dealbox3">
                  <AntDropDown
                    style={{ color: "none" }}
                    className=" action"
                    overlayClassName="Actions-btn-subhdr"
                    menu={{
                      items,
                    }}
                    arrow={{
                      pointAtCenter: true,
                    }}
                    trigger={["click"]}
                  >
                    <Button
                      className="Action-Btn-Ant Actions-btn-subheader"
                      variant="outline-primary"
                      onClick={(e) => e.preventDefault()}
                    >
                      Actions
                      <FontAwesomeIcon
                        className="Action-Btn-Ant-Dropdown"
                        icon={faAngleDown}
                      />
                    </Button>
                  </AntDropDown>
                </div>
              </div>
            </Col>
          </Row>
        </FormGroup>
      );
    } else {
      {
        getLoader();
      }
      return (
        <FormGroup className={`pad-top-5 border-bottom ${location?.pathname?.includes("/project/Tasks/") ? "Task-Subheader" : ""}`} style={{
          display: (location && location?.pathname) === "/" || (location && location?.pathname) === "/setting/index" ? "none" : "",
          marginBottom: ( location?.pathname?.includes("/project/Tasks/") || location?.pathname?.includes("/setting/pipeline")? "0px" : "")
        }}>
          <Row className="row-mar-0">
            <Col sm={5} className="pad-r0 pad-l20">
              <div className="hr-tabs-section">
                {/* <FontAwesomeIcon className="job-back-icon" icon={faArrowLeft}
                                onClick={() => { window.history.go(-1) }}
                            /> */}

                {/* <span className="jobnumber-heding-text"> */}
                {/* <strong>{getReplaceValue(location.state.module)}</strong> */}
                {location.pathname == "/setting/taskflows" ?
                  <HRTabs
                    module={"TaskBlueprint"}
                    selectedTable={"taskblueprint"}
                    setSelectedTable={() => { }}
                  /> : location?.pathname?.includes("/setting/pipeline") ?
                    <HRTabs
                      module={"PIPELINE"}
                      selectedTable={"pipelines"}
                      loadInitialTab={true}
                      setSelectedTable={setSelectedTableforTasks}
                      loadDefaultTable={'pipelines'}
                    /> : location.state.module == "TimeClock" ?
                      <HRTabs
                        module={"HR"}
                        selectedTable={selectedTable}
                        setSelectedTable={setSelectedTable}
                      /> : location?.pathname?.includes("/project/Tasks/") ?
                        <HRTabs
                          module={"TASK"}
                          selectedTable={selectedTableforTask}
                          setSelectedTable={setSelectedTableforTasks}
                        /> : <></>}
                {/* {location.state.module == "TimeClock" ?
                  <HRTabs
                    module={"HR"}
                    selectedTable={selectedTable}
                    setSelectedTable={setSelectedTable}
                  /> : <></>} */}
                {/* {location.state.module == "Tasks" ?
                  <HRTabs
                    module={"TASK"}
                    selectedTable={selectedTableforTask}
                    setSelectedTable={setSelectedTableforTasks}
                  /> : <></>} */}

                {/* </span> */}
              </div>
            </Col>
            <Col sm={2}></Col>
            <Col sm={5} style={{ paddingRight: (location.state.module == "Tasks" ? "15px" : "") }}>
              <div className="create-dealbox">
                <div className="create-dealbox2">
                  <img
                    className={props.refreshEnable ? "rotate-refresh-icon" : ""}
                    onClick={(e) => {
                      props.setRefreshEnable(true)
                      // dispatch(CustomeRefreshButton(true));
                    }}
                    style={{
                      width: "25px",
                      marginTop: "5px",
                      cursor: "pointer",
                    }}
                    src={refreshIcon}
                  />
                </div>
                <div className="create-dealbox2">
                  {getCreateButtonByModule()}
                </div>
                {location.state.module == "TimeClock" ? (
                  <div className="create-dealbox3">
                    {getTimeClockActions()}
                  </div>
                ) : location.state.module == "Vendors" ?
                  <div className="create-dealbox3">
                    {getVendorActions()}
                  </div>
                  : (
                    <div className="create-dealbox3">
                      {getAccountActions()}
                    </div>
                  )}
              </div>
            </Col>
          </Row>
        </FormGroup>
      );
    }
  };

  const getBodySubHeader = () => {
    if (location.state && location.state.module == "Deals") {
      return (
        <div className="job-subheader-box">
          {getLoader()}
          <Row className="row-mar-0">
            <Col sm={3} className="pad-12">
              <div className="jobhead-box">
                {/* <FontAwesomeIcon className="job-back-icon" icon={faArrowLeft}
                                onClick={() => { history.go(-1) }}
                            /> */}

                <span className="jobnumber-heding-text">
                  {" "}
                  <strong>{data.Deal_Name}</strong>
                </span>
                <svg
                  onClick={() => {
                    if (data.zohoworkdriveforcrm__Workdrive_Folder_ID) {
                      window.open(
                        "https://workdrive.zoho.com/folder/" +
                        data.zohoworkdriveforcrm__Workdrive_Folder_ID
                      );
                    }
                  }}
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="far"
                  data-icon="folder-open"
                  className="svg-inline--fa fa-folder-open fa-w-18 jobheader-folder-icon"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 576 512"
                >
                  <path
                    fill="currentColor"
                    d="M527.9 224H480v-48c0-26.5-21.5-48-48-48H272l-64-64H48C21.5 64 0 85.5 0 112v288c0 26.5 21.5 48 48 48h400c16.5 0 31.9-8.5 40.7-22.6l79.9-128c20-31.9-3-73.4-40.7-73.4zM48 118c0-3.3 2.7-6 6-6h134.1l64 64H426c3.3 0 6 2.7 6 6v42H152c-16.8 0-32.4 8.8-41.1 23.2L48 351.4zm400 282H72l77.2-128H528z"
                  ></path>
                </svg>
              </div>
            </Col>
            <Col sm={4} className="job-icons-box">
              <Row>
                <Col sm={2}></Col>
                <Col sm={2}>
                  <img
                    className="job-top-logo"
                    src={notes}
                  // onClick={() => props.setModal(true)}
                  />
                  <div className="job-top-text">
                    <strong> Notes</strong>
                  </div>
                </Col>
                <Col
                  //   onClick={()=>setCreateMailPopUp(true)}

                  sm={2}
                >
                  <img className="job-top-logo" src={email} />

                  <div id="create-mail-popup" className="job-top-text">
                    <strong> Email</strong>
                  </div>
                </Col>
                <Col sm={2}>
                  <img id="addlog-popup" className="job-top-logo" src={log} />
                  <div className="job-top-text">
                    <strong> Log</strong>
                  </div>
                  <UncontrolledPopover
                    trigger="legacy"
                    placement="bottom"
                    target="addlog-popup"
                  >
                    <PopoverBody></PopoverBody>
                  </UncontrolledPopover>
                </Col>
                <Col sm={2}>
                  {" "}
                  <img className="job-top-logo" src={task} />
                  <div className="job-top-text">
                    <strong> Task</strong>
                  </div>
                </Col>
                <Col sm={2}>
                  {" "}
                  <img className="job-top-logo" src={issue} />
                  <div className="job-top-text">
                    <strong> Issue</strong>
                  </div>{" "}
                </Col>
              </Row>
            </Col>
            <Col sm={1}></Col>
            <Col sm={4} className="padl-32 pad-t-b-15">
              <div className="edit-dealbox-button">
                <img
                  className={state?.CustomeRefreshButton ? "rotate-refresh-icon" : ""}
                  onClick={(e) => {
                    // props.setRefreshEnable(true)
                    dispatch(CustomeRefreshButton(true));
                  }}
                  style={{ width: "25px", marginTop: "5px", cursor: "pointer" }}
                  src={refreshIcon}
                />
              </div>
              <div className="edit-dealbox-button">
                <Button
                  className="top-botton-pad blue-background3D"
                  onClick={async () => {
                    await props.getToken();
                    localStorage.removeItem("isDealCreated");
                    history.push({
                      pathname: "/dealupdate/Deals/Update/" + location.state.id,
                      state: { module: "Deals", action: "Update" },
                    });
                  }}
                >
                  Edit Deal
                </Button>
              </div>

              {getJobActions()}
              <div className="job-rightHead-box">
                <FontAwesomeIcon
                  onClick={() => {
                    const list = [...moduleAllData];
                    if (list[list.length - 1].id == data.id) {
                      setColorForIcon2("grey");
                    } else {
                      setColorForIcon("#0069d9");
                      setColorForIcon2("#0069d9");
                    }
                    let index = list.findIndex((one) => one.id == data.id);
                    if (index > -1) {
                      let obj = list[index + 1];
                      if (obj) {
                        setData(obj);
                        history.push({
                          pathname: "/d/Deals/DetailView/" + obj.id,
                          state: {
                            data: obj,
                            action: "DetailView",
                            module: "Deals",
                          },
                        });
                      }
                    }
                  }}
                  className="cursor-point"
                  color={colorForIcon2}
                  icon={faAngleLeft}
                />

                <FontAwesomeIcon
                  onClick={() => {
                    const list = [...moduleAllData];
                    if (list[0].id == data.id) {
                      setColorForIcon("grey");
                    } else {
                      setColorForIcon2("#0069d9");
                      setColorForIcon("#0069d9");
                    }
                    let index = list.findIndex((one) => one.id == data.id);
                    if (index > -1) {
                      let obj = list[index - 1];
                      if (obj) {
                        setData(obj);
                        history.push({
                          pathname: "/d/Deals/DetailView/" + obj.id,
                          state: {
                            data: obj,
                            action: "DetailView",
                            module: "Deals",
                          },
                        });
                      }
                    }
                  }}
                  icon={faAngleRight}
                  color={colorForIcon}
                  className="mar-r-l-30 cursor-point"
                />
              </div>
            </Col>
          </Row>
        </div>
      );
    } else if (location.state && location.state.module == "Accounts") {
      return (
        <div className="job-subheader-box">
          {getLoader()}
          <Row className="row-mar-0">
            <Col sm={3} className="pad-12">
              <div className="jobhead-box">
                {/* <FontAwesomeIcon className="job-back-icon" icon={faArrowLeft}
                                onClick={() => { window.history.go(-1) }}
                            /> */}

                <span className="jobnumber-heding-text">
                  {" "}
                  <strong>{data.Account_Name}</strong>
                </span>
                <svg
                  onClick={() => {
                    if (data.zohoworkdriveforcrm__Workdrive_Folder_ID) {
                      window.open(
                        "https://workdrive.zoho.com/folder/" +
                        data.zohoworkdriveforcrm__Workdrive_Folder_ID
                      );
                    }
                  }}
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="far"
                  data-icon="folder-open"
                  className="svg-inline--fa fa-folder-open fa-w-18 jobheader-folder-icon"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 576 512"
                >
                  <path
                    fill="currentColor"
                    d="M527.9 224H480v-48c0-26.5-21.5-48-48-48H272l-64-64H48C21.5 64 0 85.5 0 112v288c0 26.5 21.5 48 48 48h400c16.5 0 31.9-8.5 40.7-22.6l79.9-128c20-31.9-3-73.4-40.7-73.4zM48 118c0-3.3 2.7-6 6-6h134.1l64 64H426c3.3 0 6 2.7 6 6v42H152c-16.8 0-32.4 8.8-41.1 23.2L48 351.4zm400 282H72l77.2-128H528z"
                  ></path>
                </svg>
              </div>
            </Col>
            <Col sm={4} className="job-icons-box">
              <Row>
                <Col sm={2}></Col>
                <Col sm={2}>
                  <img
                    className="job-top-logo"
                    src={notes}
                  // onClick={() => props.setModal(true)}
                  />
                  <div className="job-top-text">
                    <strong> Notes</strong>
                  </div>
                </Col>
                <Col
                  //   onClick={()=>setCreateMailPopUp(true)}

                  sm={2}
                >
                  <img className="job-top-logo" src={email} />

                  <div id="create-mail-popup" className="job-top-text">
                    <strong> Email</strong>
                  </div>
                </Col>
                <Col sm={2}>
                  <img id="addlog-popup" className="job-top-logo" src={log} />
                  <div className="job-top-text">
                    <strong> Log</strong>
                  </div>
                  <UncontrolledPopover
                    trigger="legacy"
                    placement="bottom"
                    target="addlog-popup"
                  >
                    <PopoverBody></PopoverBody>
                  </UncontrolledPopover>
                </Col>
                <Col sm={2}>
                  {" "}
                  <img className="job-top-logo" src={task} />
                  <div className="job-top-text">
                    <strong> Task</strong>
                  </div>
                </Col>
                <Col sm={2}>
                  {" "}
                  <img className="job-top-logo" src={issue} />
                  <div className="job-top-text">
                    <strong> Issue</strong>
                  </div>{" "}
                </Col>
              </Row>
            </Col>
            <Col sm={1}></Col>
            <Col sm={4} className="padl-32 pad-t-b-15">
              <div className="edit-dealbox-button">
                <img
                  className={state?.CustomeRefreshButton ? "rotate-refresh-icon" : ""}
                  onClick={(e) => {
                    // props.setRefreshEnable(true)
                    dispatch(CustomeRefreshButton(true));
                  }}
                  style={{ width: "25px", marginTop: "5px", cursor: "pointer" }}
                  src={refreshIcon}
                />
              </div>
              <div className="edit-dealbox-button">
                <Button
                  className="top-botton-pad blue-background3D"
                  onClick={() => {
                    history.push({
                      pathname:
                        "/updateclient/Accounts/Update/" + location.state.id,
                      state: {
                        action: "Update",
                        data: location.state.data,
                        module: "Accounts",
                      },
                    });
                  }}
                >
                  Edit Client
                </Button>
              </div>
              {/* <div className="job-action-box">
                <Dropdown className=" action">
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    className="action-text pipeline-toggle action-arrow "
                  >
                    Actions
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">Duplicate</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">Share</Dropdown.Item>
                    <Dropdown.Item>Delete</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">
                      Generate Paperwork
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div> */}
              {getAccountJobActions()}
              <div className="job-rightHead-box">
                <FontAwesomeIcon
                  onClick={() => {
                    const list = [...moduleAllData];
                    let index = list.findIndex((one) => one.id == data.id);
                    if (index > -1) {
                      let obj = list[index + 1];
                      if (obj) {
                        setData(obj);
                        history.push({
                          pathname: "/c/Accounts/DetailView/" + obj.id,
                          state: {
                            data: obj,
                            action: "DetailView",
                            module: "Accounts",
                          },
                        });
                      }
                    }
                  }}
                  className="cursor-point"
                  color="#0069d9"
                  icon={faAngleLeft}
                />
                <FontAwesomeIcon
                  onClick={() => {
                    const list = [...moduleAllData];
                    let index = list.findIndex((one) => one.id == data.id);
                    if (index > -1) {
                      let obj = list[index - 1];
                      if (obj) {
                        setData(obj);
                        history.push({
                          pathname: "/c/Accounts/DetailView/" + obj.id,
                          state: {
                            data: obj,
                            action: "DetailView",
                            module: "Accounts",
                          },
                        });
                      }
                    }
                  }}
                  icon={faAngleRight}
                  color="#0069d9"
                  className="mar-r-l-30 cursor-point"
                />
              </div>
            </Col>
          </Row>
        </div>
      );
    } else {
      return <></>;
    }
  };

  const getLoader = () => (loader ? <Loader /> : <></>);
  //  
  if (
    location.state &&
    (location.state.action == "create" || location.state.action === "Update")
  ) {
    return getHeaderForSuplementry();
  } else if (location.state && location.state.action == "DetailView") {
    return getBodySubHeader();
  } else if (location.state && location.state.action == "SimpleView") {
    return getSimpleView();
  } else if (location.state && (location.state.action == "listview" || location.state.action == "defaultView" 
    || location.pathname == "/setting/taskflows" || location.pathname == "/setting/pipeline")) {
    return getListHeader(location.state.module);
  } else {
    return <></>;
  }
};

export default SubHeader;