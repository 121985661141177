import React, { useEffect, useRef, useState } from 'react'
import { Col, Row } from 'reactstrap'
import { invertColor } from '../../../components/HR/TimeClock/Util';
import { Button, Popover } from 'antd';
import './TaskFlowStatus.css'
import { ButtonOverflow } from './ButtonOverflow';
import { useEdges } from 'reactflow';
export const TaskFlowStatus = ({
    statusOptions,
    onChangeStatus,
    taskData,
    type,
    transitions,
}) => {
    const [status,setStatus] = useState("Not Started");
    const containerRef = useRef(null);
    const [Options1,setOptions1] = useState([]);
    const [Options,setOptions] = useState([]);
    useEffect(e=>{
        let s=taskData?.TaskFlowObject?.Nodes?.find(k=>k.id==taskData.TaskStatus);
        if(s){
            setStatus(s?.data?.label);
        }else{
            s=taskData?.TaskFlowObject?.Nodes?.find(k=>k.id=="1");
            setStatus(s?.data?.label);
        }
    },[taskData])
    useEffect(e=>{
        setOptions(transitions)
    },[transitions])
    useEffect(e=>{
        setOptions1(statusOptions)
    },[statusOptions])
    const getStyleObj = (status) => {
        let temp = statusOptions?.find(k => k.label == status);
        let sty = {
            backgroundColor: "white",
            color: "black",
            border: "1px solid white"
        }
        if (temp && temp.color) {
            sty["backgroundColor"] = temp.color;
            sty["border"] = `1px solid ${temp.color}`;
            sty["color"] = invertColor(temp.color, true);
        }
        return sty;
    }
    const getStatusDropdown = (data, listtype) => {

        // let arr=[];
        // if(listtype=="reject"){
        //   arr=statusOptions?.filter(one=>one.color=="red")
        // }else{
        //   arr=statusOptions?.filter(one=>one.color!="red")
        // }
        const content = (
            <div className="Task-Status-Div">
                {Options1?.map((one) => {
                    return (
                        <p
                            style={getStyleObj(one.label)}
                            className="Task-Status-P"
                            onClick={() => {
                                // onSelect(one);
                                // onChangeTransion(one.label)
                                onChangeStatus(one);
                                // setSelected(!selected);
                            }}
                        // style={{ backgroundColor: one.color, color: one.color ? invertColor(one.color, true) : "#fff", fontWeight:"600" }}
                        >
                            {one.label}
                        </p>
                    );
                })}
            </div>
        );
        if (Options1?.length == 0) {
            return (<Button
                type="primary"
                disabled={true}
                style={{
                    opacity: 0.5,
                    backgroundColor: "#1677ff",
                    borderColor: "#1677ff",
                    color: "white",
                }}
                // icon={<CheckCircleOutlined size={24} style={{ height: "1rem" }} />}
                size="middle"
            >No Task Flow</Button>)
        }
        return (
            <Popover
                style={{ zIndex: 9999 }}
                // open={true}
                onOpenChange={(e) => {
                    // setIsOpen(!isOpen);
                }}
                trigger="click"
                // overlayClassName={"Prject-Status-Container" }
                placement="bottomLeft"
                content={content}
                arrow={false}
            >
                <Button
                    type="primary"
                    style={getStyleObj(status)}
                    // icon={<CheckCircleOutlined size={24} style={{ height: "1rem" }} />}
                    size="middle"
                >{status}</Button>
            </Popover>
        )

    }
    
    return (
        <div className='Status-Wrapper'>
            <div className={`${type != "Status" || type=="Both"?'Main-Status-Button':"Main-Status-Button2"}`} style={{ padding: "0px",display:"flex" ,width:"max-content" }}>
                {type != "Status" && type!="Both"?'TRANSITIONS':getStatusDropdown()}
            </div>
            { (type != "Status" || type=="Both") && <Col className='Button-Wrapper' style={{ padding: "0px" }} sm={11}>
                <ButtonOverflow
                    buttons={Options}
                    onClickButton={(data)=>{onChangeStatus(data)}}
                />
            </Col>}
        </div>
    )
}