import { QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Popconfirm } from "antd";
import React, { useState } from "react";
import { OtsideClickClosingWrapper } from "../../../common/EmojiReactionPicker/OtsideClickClosingWrapper";

const SingleTransition = ({ button, getStyleObj, onClickButton , onCloseCallback}) => {
  const [popConfirmVisible, setPopConfirmVisible] = useState(false);
  const handleCancel = () => {
    setPopConfirmVisible(false);
  };
  const handleConfirm = () => {
    onClickButton(button);
    setPopConfirmVisible(false);
    onCloseCallback();
  };
  return (
    <OtsideClickClosingWrapper
        close={e=>{
            setPopConfirmVisible(false);
            onCloseCallback()
        }}
    >
      <Popconfirm
        // disabled={inputField.Type != "PICKLIST"}
        key={button.id}
        className="Do-Not-Close"
        overlayClassName="Do-Not-Close"
        open={popConfirmVisible}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
        okText="Yes"
        cancelText="No"
        title="Transition Confirmation"
        description="Are you sure you want to complete this transition?"
        icon={
          <QuestionCircleOutlined
            style={{
              color: "red",
            }}
          />
        }
      >
        <Button
          // key={button.id}
          style={{ marginRight: "8px", ...getStyleObj(button) }}
          onClick={() => {
            setPopConfirmVisible(true);
            // onClickButton(button)
          }}
        >
          {button.label}
        </Button>
      </Popconfirm>
    </OtsideClickClosingWrapper>
  );
};

export default SingleTransition;
