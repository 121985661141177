import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Input } from 'reactstrap'

export const GlobalSearch = ({ onSearchFilterTexts ,text}) => {
    return (
        <div className="input-search-wrapper" style={{position:"relative"}}>
            <div className="search-icon-wrapper">
                <FontAwesomeIcon
                    className="mr-sm-2 search-ico"
                    icon={faSearch}
                />
            </div>
            <Input
                type="text"
                value={text}
                onChange={(e) => {
                    e.target.value=e.target.value.trimStart() 
                    onSearchFilterTexts(e.target.value)
                }
                }
                placeholder="Search"
                className="mr-sm-2 pad-fix search-filter search-deal"
                style={{ width: "17rem", borderColor: "#018EE0" }}
            />
        </div>
    )
}
