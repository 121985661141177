import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  Children,
  useContext,
  useMemo,
} from "react";
import {
  Popover as AntdPopover,
  DatePicker,
  Button,
  Modal,
  Card,
  Space,
  Input as SearchInput
} from "antd";
import { CardBody, CardHeader, Col, FormGroup, Row } from "reactstrap";
import "antd/dist/reset.css";
import { Input, UncontrolledTooltip, UncontrolledCollapse } from "reactstrap";
import { useParams } from "react-router-dom";
import {
  Calendar as DnDCalendar,
  dateFnsLocalizer,
  Event,
  momentLocalizer,
} from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import HttpService from "../../services/http.service";
import dayjs, { isDayjs } from "dayjs";
import {CirclePicker, CompactPicker} from 'react-color'
import { OtsideClickClosingWrapper } from "../../common/EmojiReactionPicker/OtsideClickClosingWrapper";
import "./Calendar.css";
import {
  faBell,
  faUser,
  faVideo,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faClock,
  faPen,
  faSearch,
  faSearchMinus,
  faSearchPlus,
  faTimes,
  faTrash,
  faEye,
  faFilter,
  faPaperclip,
  faFile,
  faFileImage,
  faFilePdf,
  faFileExcel,
  faBars,
  faDownload,
  faLocationArrow,
  faSearchLocation,
  faCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { v4 as uuid } from 'uuid';
import Geocode from "react-geocode";
import AwsServerService from "../../common/util/AwsServerService";
import { calendarEventType, convertZohoTimeToLocal, getSystemTimezoe, HexColorRenges } from "../HR/TimeClock/Util";
import swal from "sweetalert";
import _ from "lodash";
import { faCalendarAlt,faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import CalendarAPI from "../../common/util/CalendarAPI";
import validation from "../../common/util/validation";
import UserService from "../../common/util/UserService";
import { HexColorPicker } from "react-colorful";
import { toIsoString } from "../../common/DateTime";
import dateTimeObject from "../../common/util/date";
import Reccuring from "../Reccuring/reccuring";
import refreshIcon from "../../assets/images/refresh-icon.png";
import Loader from "../../common/loader";
import moment from "moment";
import Select from "react-select";
import AppContext from "../../Context/AppContext";
import { Socket, forwardObject, refershModule } from "../../Context/AppActions";
import Draggable from "react-draggable";
import { ToolBox } from "../../common/ShortcutsToolbox/ToolBox";
import CreatableSelect from "react-select/creatable";
import { Promise } from "es6-promise";
import JoditEditor from "jodit-react";
import { DocumentPreview } from "../EmailSection/DocumentPreview/DocumentPreview";
import WarningSvg from '../../assets/images/svg/warning.svg'
import locationSvg from '../../assets/images/svg/location.svg'
import { EventSearch } from "./EventSearch";
import "../../common/custom_multiselect/custom_multiselect.css"
import MultiselectCheckbox from "./MultiselectCheckbox";
import CustomTimePicker from "./CustomTimePicker";
const {Search} = SearchInput;

const FullCalendar = ({
  refreshEnable,
  setRefreshEnable,
  isAccess,
  selectedRole,
  from,
  selectedEmployee,
  location,
  history,
  getParams,
  invertColor
}) => {
  const AppData = useContext(AppContext);
  const { dispatch, state } = AppData || {};
  const ConferenceOptions = [{ label: "Zoho Meeting", value: "zmeeting" }];
  const allReminders=state.cacheReminderData;
  const [eventPopupState, setEventPopupState] = useState("create");
  const [eventSearchLoading,setEventSearchLoading] = useState(false);
  const [allDay, setAllDay] = useState(true);
  const [inputValue, setInputValue] = useState("");
  const [openAttachmentPopUp,setOpenAttachmentPopUp] = useState(false);
  const [isOpenFlag, setIsOpenFlag] = useState({ state: false, object: null });
  const [note,setNote] = useState(''); 
  const [eventReminders, setEventReminders] = useState([]);
  const [refreshRecurringData, setRefreshRecurringData] = useState(0);
  const [allEventsForSearch,setAllEventsForSearch] = useState([]);
  const [compactColorPicker, setCompactColorPicker] = useState(false);
  const [arrow, setArrow] = useState('Show');
  const addressInputRef = useRef(null);
  const [eventDataOptions, setEventDataOptions] = useState([{
    NotificationBy: "email",
    NotificationOn: "before",
    NotificationTime: "5",
    isChecked:true
  },{
    NotificationBy: "popup",
    NotificationOn: "before",
    NotificationTime: "5",
    isChecked:false
  },{
    NotificationBy: "notification",
    NotificationOn: "before",
    NotificationTime: "5",
    isChecked:false
  }])
  const components = {
    DropdownIndicator: null,
  };
  const params = useParams();

  const mergedArrow = useMemo(() => {
    if (arrow === 'Hide') {
      return false;
    }
    if (arrow === 'Show') {
      return true;
    }
    return {
      pointAtCenter: true,
    };
  }, [arrow]);
 
  useEffect(() => {
    if (getParams) {
      getParams(params);
    }
  }, [location]);
  const CalendarOptions = [
    { label: "Month", value: "month" },
    { label: "Week", value: "week" },
    { label: "Day", value: "day" },
  ];
  const [myCalendar, setMyCalendar] = useState(null);
  const [selectedEmailIds, setSelectedEmailIds] = useState([]);


  const sidebarEmailContext = [
    {
      label: "Open",
      icon: () => {
        return <FontAwesomeIcon color="007bff" icon={faEye} />;
      },
      module: ["Event"],
      action: (e, data, refrence) => {
        if (refrence) {
          onClickEvent(refrence);
        }
      },
    },
    {
      label: "Edit Event",
      icon: () => {
        return <FontAwesomeIcon color="007bff" icon={faPen} />;
      },
      module: ["Event"],
      action: async (e, data, refrence) => {
        if (refrence) {
          onClickEvent(refrence);
          let obj = { ...eventData };
          let calendar = { ...myCalendar };
          let res = await CalendarAPI.GetEventDetail(
            calendar.uid,
            refrence.uid
          );
          if((res[0]?.attach)?.length > 0){
            setAttachments(res[0]?.attach)
          }
          let actions = convertNotifiactionDescToOrignal(res[0]);
          setEventReminders(res[0]?.reminders);
          obj.action = actions;
          obj.description = res[0]?.description;
          obj.attendees = res[0]?.attendees;
          if (res[0]?.conference_data?.meetingdata) {
            setConference({ label: "Zoho Meeting", value: "zmeeting" });
          }
          setNote(res[0]?.description);
          if(res[0].isallday){
            setTillDate(moment(res[0].dateandtime.start).format("YYYY-MM-DD"));
          }else{
            setFromTime(moment(res[0].dateandtime.start).format("HH:mm"));
            setTillDate(moment(res[0].dateandtime.end).format("YYYY-MM-DD"));
            setTillTime(moment(res[0].dateandtime.end).format("HH:mm"));
          }
          setFromDate(moment(res[0].dateandtime.start).format("YYYY-MM-DD"));
          if (obj?.description?.includes("srsolarreminder")) {
            let arr = [];
            if (obj?.description?.includes("notification")) {
              arr.push("notification");
            }
            if (obj?.description?.includes("email")) {
              arr.push("email");
            }
            if (obj?.description?.includes("popup")) {
              arr.push("popup");
            }
            obj.action = arr;
            setFromDate(
              moment(
                reminderEventBackToOriginalTime(res[0].dateandtime.start)
              ).format("YYYY-MM-DD")
            );
            setFromTime(
              moment(
                reminderEventBackToOriginalTime(res[0].dateandtime.start)
              ).format("HH:mm")
            );
          }
          setAction("edit");
          setEventData(obj);
        }
      },
    },
    {
      label: "View",
      icon: () => {
        return <FontAwesomeIcon color="007bff" icon={faEye} />;
      },
      module: ["Attachments"],
      action: async (e, data, refrence) => {
        getchFiletDataUrl(refrence)
      }
      
    },
    {
      label: "Download",
      icon: () => {
        return <FontAwesomeIcon color="007bff" icon={faDownload} />;
      },
      module: ["Attachments"],
      action: async(e, data, refrence) => {
        if (refrence) {
          await CalendarAPI.AttachmentDownload(eventData.caluid, eventData.uid, refrence.fileId, 'download')
          .then(response => {
            downloadFile(response,refrence.fileName)
          })
          .catch(error => {
            console.error('Error downloading the image:', error);
          });
        }
      },
    },
  ];
  const [toolboxposition, setToolboxPosition] = useState({ x: 0, y: 0 });
  const [isOpen, setIsOpen] = useState({ state: false, object: null });
  const [modalPosition, setModalPosition] = useState({ x: 0, y: 35 });
  const [key, setKey] = useState("timeSheet");
  const [selectedTable, setSelectedTable] = useState("timeSheet");
  const [allUserTask, setAllUserTask] = useState([]);
  const [taskContinue, setTaskContinue] = useState(false);
  const [deleteClick, setDeleteClick] = useState(false);
  const [timeEntryPopUp, setTimeEntryPopUp] = useState(false);
  const [editEvent, setEditEvent] = useState(null);
  const [eventEntryPopUpState, setEventEntryPopUpState] = useState(false);
  const [action, setAction] = useState("create");
  const [taskEstimatePopUpWarn, setTaskEstimatePopupWarn] = useState(false);
  const [calendreView, setCalenderView] = useState({
    label: "Month",
    value: "month",
  });
  const [conference, setConference] = useState(null);
  const [chartTooltipTableData, setChartTooltipTableData] = useState([]);
  const localizer = momentLocalizer(moment);
  const [editOccurence, setEditOccurence] = useState("");
  const weekdays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  let repeatEventObject = {
    OnDay: new Date().getDate(),
    OnMonth: "OnDay",
    EventDate: new Date(Date.now()),
    MonthStep: "1",
    MonthDay: weekdays[new Date().getDay()],
    DailyIntervalCount: "1",
    WhichMonth: `${new Date().getMonth() + 1}`,
    EndBy: "Never",
    Repeat: "Daily",
    DailyInterval: "Every",
    WeeklyDay: `${dayjs().format("dddd")}`,
  }

  const [repeatObj, setRepeatObj] = useState({...repeatEventObject});
  const [filteredCalenderEvents, setFilteredCalenderEvents] = useState({
    status: false,
    data: [],
  });
  const [calenderEvents, setCalendarEvents] = useState([]);
  const [originalEvents, setOriginalEvents] = useState([]);
  const [timeSlot, setTimeSlot] = useState(60);
  const [selectedUser, setSelectedUser] = useState("All Users");
  const [allusers, setAllUsers] = useState([]);
  const [allCacheUser, setAllCacheUser] = useState([]);
  const [allDeal, setAllDeal] = useState([]);
  const [chartHover, setChartHover] = useState(false);
  const [hoverDateCell, setHoverDateCell] = useState(null);
  const [selectedSlot, setSelectedSlot] = useState({});
  const [eventData, setEventData] = useState({
    color: "#8cbf40",
    action: [
      {
        NotificationBy: ["email"],
        NotificationOn: "before",
        NotificationTime: "5",
      },
    ],
  });
  const [fromDate, setFromDate] = useState(null);
  const [tillDate, setTillDate] = useState(null);
  const [fromTime, setFromTime] = useState(null);
  const [tillTime, setTillTime] = useState(null);
  const [isRepeatEvent, setIsRepeatEvent] = useState(false);
  const [attachments,setAttachments] = useState([]);
  // console.log("attachments : ", attachments);
  const [calenderId, setCalenderId] = useState(
    "d8140b3f7b9a49d39872dc98ba68b1af"
  );
  const [deafulCalenderViewDate, setDefaultCalenderViewDate] = useState(
    new Date(Date.now())
  );

  useEffect(e=>{
    setRefreshRecurringData(refreshRecurringData + 1)
  },[action])

  const config = useMemo(() => ({
		toolbar: true,
		buttons: [
		  'bold',
		  'italic',
		  'underline',
		  'strikethrough',		 
		  '|',
		  'ul',
		  'ol',
		],
		removeButtons: [
		  'video',
		  'superscript',
		  'subscript',
		  'file',
		  'indent',
		  'outdent',
		  'formatPainter',
		  'link',
		  'unlink',
		  'source',
		  'code',
		  'symbol',
		  'selectall',
		  'cutformat',
		  'copy',
		  'cut',
		  'paste',
		  'copyhtml',
		  'cuthtml',
		  'insertImage',
		  'filebrowser',
		  'about',
		  "breakline",
      'font',
		  'fontsize',
		  'brush',
		  'copyformat',
		  'eraser',
		  'hr',
		  'print',
      'table',
		  'speechRecognize',
		  'spellcheck',
		  'Align',
      'undo',
		  'redo',
		  'paragraph',
      'dots',
      'lineHeight',
      'fullsize',
      'align', // Trying to remove align buttons
      'left',   // Specifically targeting align buttons
      'center', // Specifically targeting align buttons
      'right',  // Specifically targeting align buttons
      'justify',// Specifically targeting align buttons
		],
	  }), []);

  const monthName = [
    {
      id: "1",
      name: "January",
    },
    {
      id: "2",
      name: "February",
    },
    {
      id: "3",
      name: "March",
    },
    {
      id: "4",
      name: "April",
    },
    {
      id: "5",
      name: "May",
    },
    {
      id: "6",
      name: "June",
    },
    {
      id: "7",
      name: "July",
    },
    {
      id: "8",
      name: "August",
    },
    {
      id: "9",
      name: "September",
    },
    {
      id: "10",
      name: "October",
    },
    {
      id: "11",
      name: "November",
    },
    {
      id: "12",
      name: "December",
    },
  ];
  const [years, setYears] = useState({ start: 2020, end: 2030 });
  const [searchEventText, setSearchEventText] = useState("");
  const [searchEvetns, setSearchEvents] = useState([]);
  const [errors, setErrors] = useState({});
  const handleContextMenu = (e, one, module) => { 
    e.preventDefault(); // Prevent default context menu
    setIsOpenFlag({ state: true, object: one }); 
    setToolboxPosition({ x: e.clientX, y: e.clientY });
  };
  const initPlaceAPI = () => {
    let autocomplete = new window.google.maps.places.Autocomplete(addressInputRef.current);
  };
  const calculateTimeForNotification=(remTime,remType,dateandtime)=>{
    let eventTime= moment(dateandtime.start).format('DD/MM/YYYY hh:mm')
    let reminderTime= moment(remTime).format('DD/MM/YYYY hh:mm') 
    let diff=dayjs(eventTime, "DD/MM/YYYY HH:mm").diff(dayjs(reminderTime,"DD/MM/YYYY HH:mm"),"minutes")
    return remType=='before'? `-${diff}`:`+${Math.abs(diff)}`;
  }
  const deleteAttachment = async(att) => {
    setLoading(true)
    if(!att.size){
      let data = attachments.filter(e=> e.fileId != att.fileId);
      setAttachments(data);
      setLoading(false);
      return
    }

    let data = {
      "uid" : eventData.uid,
      "dateandtime" : {
        "timezone" : eventData?.dateandtime?.timezone,
        "start": eventData?.dateandtime?.start,
        "end": eventData?.dateandtime?.end,
      },
      "title" : eventData?.title,
      "attach" : {
      "rmAttachId" : att?.fileId,
      },
      "etag" : eventData?.etag
    }
    await CalendarAPI.DeletedAttachment(eventData.caluid, eventData.uid, data)
    .then(res=>{
      console.log(res);
      let obj = {...eventData};
      obj.etag = res[0]?.etag;
      setEventData(obj)
      setAttachments(res[0]?.attach || [])
      setLoading(false);
    }).catch(e =>{
      console.log(e);
      setLoading(false);
    })
  }
  const filtersForCalenderEvents = {
    "All Day": {
      label: "All Day",
      id: 1,
      checked: false,
    },
    Recurring: {
      label: "Recurring",
      id: 2,
      checked: false,
    },
    Meetings: {
      label: "Meetings",
      id: 3,
      checked: false,
    },
    Task: {
      label: "Task",
      id: 5,
      checked: false,
    },
    // Attendees: {
    //   label: "Attendees",
    //   id: 4,
    //   checked: false,
    //   email: [],
    // },
  };
  const [calFilterOptions, setCalFilterOptions] = useState({
    ...filtersForCalenderEvents,
  });
  const [filterPopup, setFilterPopup] = useState(false);

  async function fetchMyCalendar() {
    await CalendarAPI.GetAllCalendars().then((res) => {
      setMyCalendar(res);
    });
  }
  const goToView = (view) => {
    setCalenderView(view);
  };
  const setConferenceType = (view, e) => {
    let obj = { ...eventData };
    obj[e.name] = view.value;
    setConference(view);
    setEventData(obj);
  };
  const handleDrag = (e, ui) => {
    if (e.target && e.target.closest(".ant-picker-dropdown")) {
      e.stopPropagation();
      return;
    }
    const { x, y } = ui;
    setModalPosition({ x, y });
  };
  useEffect((e) => {
    fetchMyCalendar();
  }, []);
  useEffect(e=>{
    if(state?.forwardObject?.module=="Calendar" && state?.forwardObject?.state=="create"){
      setAction(state?.forwardObject?.state);
      setEventPopupState(state?.forwardObject?.state);
      setEventEntryPopUpState(true)
      setIsRepeatEvent(false);
      setFromDate(moment().format("YYYY-MM-DD"));
      setFromTime(moment().format("HH:mm"));
      setTillDate(moment().format("YYYY-MM-DD"));
      let till = new Date(Date.now());
      till.setMinutes(till.getMinutes() + 15);
      setTillTime(moment(till).format("HH:mm"));
      dispatch(forwardObject({module:"",data:"",state:""}))
    }else if(state?.forwardObject?.module=="Calendar" && state?.forwardObject?.state=="view" && state?.forwardObject?.data){
      let data=filteredCalenderEvents?.data?.find(k=>k.uid==state?.forwardObject?.data?.messageId);
      if(data){
        onClickEvent(data);
        dispatch(forwardObject({module:"",data:"",state:""}))
      }
    }
  },[state?.forwardObject,filteredCalenderEvents])
  const getDateRange = () => { 
    let obj = {};
    if (calendreView?.value == "month") {
      obj = dateTimeObject.GetDateArr(
        deafulCalenderViewDate,
        deafulCalenderViewDate,
        "This Month"
      );
    }
    if (calendreView?.value == "week") {
      obj = dateTimeObject.GetDateArr(
        deafulCalenderViewDate,
        deafulCalenderViewDate,
        "This Week"
      );
    }
    if (calendreView?.value == "day") {
      obj = dateTimeObject.GetDateArr(
        deafulCalenderViewDate,
        deafulCalenderViewDate,
        "Today"
      );
    }
    obj = { start: obj[0], end: obj[1] };
    obj.start = moment(new Date(obj.start)).format("yyyyMMDD");
    obj.end = moment(new Date(obj.end)).format("yyyyMMDD");
    return obj;
  };
  const customstyle = {
    control: (provided, state) => ({
      ...provided,
      width: "9.5rem",
      background: "#fff",
      borderColor: "#ccc",
      minHeight: "1.6rem",
      height: "2.2rem",
      boxShadow: state.isFocused ? 0 : 0,
      "&:hover": {},
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      padding: "0px 0.6rem",
    }),

    input: (provided, state) => ({
      ...provided,
      margin: "0px",
      padding: "0px",
      height: "20px",
      fontSize: "13px",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "2rem",
    }),
    placeholder: (provided, state) => ({
      ...provided,
      margin: "0px",
      padding: "0px",
      height: "2rem",
      fontSize: "1rem",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontSize: "15px",
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: "13px",
    }),
    menu: (provided, state) => ({
      ...provided,
      width: "100%",
    }),
  };

  const conferenceStyles = {
    control: (provided, state) => ({
      ...provided,
      width: "14.1rem",
      background: "#fff",
      borderColor: "#ccc",
      minHeight: "1.6rem",
      height: "2.2rem",
      boxShadow: state.isFocused ? 0 : 0,
      "&:hover": {},
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      padding: "0px 0.6rem",
    }),

    input: (provided, state) => ({
      ...provided,
      margin: "0px",
      padding: "0px",
      height: "20px",
      fontSize: "13px",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "2rem",
    }),
    placeholder: (provided, state) => ({
      ...provided,
      margin: "0px",
      padding: "0px",
      height: "1.5rem",
      fontSize: "1rem",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontSize: "15px",
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: "13px",
    }),
    menu: (provided, state) => ({
      ...provided,
      width: "100%",
    }),
  };

  const participantStylesForFilter = {
    control: (provided, state) => ({
      ...provided,
      width: "auto",
      background: "#fff",
      borderColor: "#ccc",
      minHeight: "2.2rem",
      height: "auto",
      boxShadow: state.isFocused ? 0 : 0,
      "&:hover": {},
    }),

    multiValue: (provided, state) => ({
      ...provided,
      background: "#2196f3",
    }),

    multiValueLabel: (provided, state) => ({
      ...provided,
      color: "hsl(0deg 0% 100%)",
    }),

    multiValueRemove: (provided, state) => ({
      ...provided,
      color: "hsl(0deg 0% 100%)",
      ":hover": {
        backgroundColor: "#2196f3",
        color: "#d01a1a",
      },
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      padding: "0px 0.6rem",
    }),

    input: (provided, state) => ({
      ...provided,
      margin: "0px",
      padding: "0px",
      height: "20px",
      fontSize: "13px",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "2rem",
    }),
    placeholder: (provided, state) => ({
      ...provided,
      margin: "0px",
      padding: "0px",
      height: "1.5rem",
      fontSize: "1rem",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontSize: "15px",
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: "13px",
    }),
    menu: (provided, state) => ({
      ...provided,
      width: "100%",
    }),
  };

  const participantStyles = {
    control: (provided, state) => ({
      ...provided,
      width: "17.6rem",
      background: "#fff",
      borderColor: "#ccc",
      minHeight: "2.2rem",
      height: "auto",
      boxShadow: state.isFocused ? 0 : 0,
      "&:hover": {},
    }),

    multiValue: (provided, state) => ({
      ...provided,
      background: "#2196f3",
    }),

    multiValueLabel: (provided, state) => ({
      ...provided,
      color: "hsl(0deg 0% 100%)",
    }),

    multiValueRemove: (provided, state) => ({
      ...provided,
      color: "hsl(0deg 0% 100%)",
      ":hover": {
        backgroundColor: "#2196f3",
        color: "#d01a1a",
      },
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      padding: "0px 0.6rem",
    }),

    input: (provided, state) => ({
      ...provided,
      margin: "0px",
      padding: "0px",
      height: "20px",
      fontSize: "13px",
    }),
    indicatorSeparator: (state) => ({
      display: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "2rem",
    }),
    placeholder: (provided, state) => ({
      ...provided,
      margin: "0px",
      padding: "0px",
      height: "1.5rem",
      fontSize: "1rem",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontSize: "15px",
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: "13px",
    }),
    menu: (provided, state) => ({
      ...provided,
      width: "100%",
    }),
  };


  /// File preview in calendar

  const [filePrvData, setFilePrvData] = useState({
    "DataUrl": "",
    "FileName": "",
  });
  const [showPriview, setShowPriview] = useState(false);
  const closePreview = () => {
    setShowPriview(!showPriview);
    //setShowDealTableChart(true)
  }
  const getPDFPrevModal = () => {
    return (
      <Modal
        open={showPriview}
        title={
          filePrvData?.FileName
        }
        width={900}
        style={{ top: 170, borderRadius: 50 }}
        onCancel={closePreview}
        footer={null}
      >
        <DocumentPreview base64String={filePrvData?.DataUrl} />
      </Modal>
    )
  }



  const getchFiletDataUrl = async (refrence) => {

    // setLoader(true);
    try {
      await CalendarAPI.AttachmentDownload(eventData.caluid, eventData.uid, refrence.fileId, 'download')
      .then(response => {
          const urlMatch = response.match(/window\.location\.href='(.*?)'/);
            if (urlMatch && urlMatch[1]) {
              const url = urlMatch[1];
              console.log('Extracted URL:', url);
              // Open the URL in a new tab
              window.open(url, '_blank');
            }else{
              setFilePrvData({
                "DataUrl": response,
                "FileName": refrence?.fileName?refrence?.fileName:"File",
              })
              setShowPriview(true);
            }
      })
      .catch(error => {
        console.error('Error downloading the image:', error);
      });
      
    } catch (error) {
      // setLoader(false)
      console.log(error)
    }
    //setShowDealTableChart(false)
    // setShowPriview(true);
    // setLoader(false);
  }

  const downloadFile = (base64String,fileName) => {
     

    // Fix the base64 string
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    // Create a Blob from the binary data
    const blob = new Blob([byteArray], { type: 'application/octet-stream' });

    // Create a URL for the Blob
    const url = URL.createObjectURL(blob);

    // Create a link element and trigger the download
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    // Revoke the URL
    URL.revokeObjectURL(url);
  };

  ////

  useEffect(() => {
    // getCalendarEventList(getDateRange());
    getAllUser();
  }, []);

  let userInfo = JSON.parse(localStorage.getItem("userinfo"));

  const getConferenceDropdown = () => {
    return (
      <div>
        <Select
          options={ConferenceOptions}
          closeMenuOnSelect={true}
          onChange={(values, e) => setConferenceType(values, e)}
          value={
            conference
              ? { label: conference?.label, value: conference?.value }
              : ""
          }
          menuPlacement="auto"
          name="conference"
          placeholder="Add conference"
          styles={conferenceStyles}
        />
      </div>
    );
  };

  useEffect((e) => {
      if (action == "create") {
        setSelectedEmailIds([
          { label: userInfo?.email_id, value: userInfo?.email_id },
        ]);
      } else if (action == "edit" || action == "editview") {
        if (eventData?.attendees) {
          let data = eventData?.attendees.map((item) => {
            return { label: item.email, value: item.email };
          });
          setSelectedEmailIds([...data]);
        }
      }
    },
    [eventEntryPopUpState, action]
  );

  useEffect(() => {
    if (refreshEnable || state?.refershModule?.module == "Calendar") {
      getCalendarEventList(getDateRange());
      dispatch(refershModule({ module: "", data: "" }));
    }
  }, [refreshEnable, state?.refershModule?.module]);

  useEffect(() => {
    getCalendarEventList(getDateRange());
  }, [deafulCalenderViewDate, calendreView?.value]);

  const getAllUser = () => {
    UserService.GetAllUsers()
      .then((result) => {
        let loginUser = result.data.users.filter(
          (one) => one.zuid == localStorage.getItem("ZUID")
        );
        if (loginUser.length > 0) {
          // loginUser = loginUser[0];
          setAllUsers(loginUser);
          // if (loginUser.role.name == "CEO") {
          //     setAllUsers(result.data.users);
          // } else {

          // }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const openSerachResult = (e, one) => {
    console.log("one", one);
    onClickEvent(one,true);
  };

  const createOption = (label) => ({
    label,
    value: label,
  });

  const handleKeyDown = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case "Enter": 
        if(selectedEmailIds.find(e => e.label == inputValue)){
          swal("alert","Attendee Already Exists!")
        }
      case "Tab":
        if(!selectedEmailIds.find(e=> e.label == inputValue)){
          setSelectedEmailIds((prev) => [...prev, createOption(inputValue)]);
          setInputValue("");
        }
        event.preventDefault();
    }
  };

  const handleKeyDownForFilter = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        let obj = { ...calFilterOptions };
        obj["Attendees"].email = [
          ...obj["Attendees"].email,
          createOption(inputValue),
        ];
        setCalFilterOptions(obj);
        setInputValue("");
        event.preventDefault();
    }
  };


  const UploadAttachments=async(files)=>{
    return new Promise(async(resolve,reject)=>{
      const results = await Promise.all(files.map(file => CalendarAPI.UploadCalendarAttachement(file.file)));
      const allData = results.reduce((acc, result) => acc.concat(result.attachments), []);
      console.log("allData", allData);
      resolve(allData);
    })
  }

  const handleFileAttachments = async (fileList) => {
    let tempFileCollection = [...attachments];
    let v = fileList.length;
    let index = 0;
    while (index < v) {
      let fileName = fileList[index].name;
      tempFileCollection.push({
        fileId:uuid(),
        fileName:fileName,
        isManual: true,
        file: fileList[index],
      });
      index = index + 1;
    }
    setAttachments(tempFileCollection);
  };

  const handleUploadFiles = () => {
    let link = document.createElement('input');
    link.setAttribute('type', 'file');
    link.setAttribute('name', 'uploadFiles');
    link.setAttribute('multiple', "multiple");
    document.body.appendChild(link);
    link.click();
    link.onchange = function (e) {
      handleFileAttachments(e.currentTarget.files);
    }
    document.body.removeChild(link);
  }

  const getIconByType=(name)=>{
    if(name?.includes("pdf")){
      return <FontAwesomeIcon color='#007bff' icon={faFilePdf} />
    }else if(name?.includes("xlx")){
      return <FontAwesomeIcon icon={faFileExcel} />
    }else if(name?.includes("png") || name?.includes("jpeg") || name?.includes("jpg")){
      return <FontAwesomeIcon color='#007bff' icon={faFileImage} />
    }else{
      return <FontAwesomeIcon color='#007bff' icon={faFile} />
    }
  }

  const AttachmentPopUp = () => {
    return <Modal 
    title="Attachments" 
    className='Attachment-PopUp'
    open={openAttachmentPopUp}
    onOk={e=>setOpenAttachmentPopUp(!openAttachmentPopUp)}
    onCancel={e=>setOpenAttachmentPopUp(!openAttachmentPopUp)}
    >
    {attachments?.map(att=>{
      return (<>
      <Row sm={12}>
        <Col sm={2}>{getIconByType(att?.fileName)}</Col>
        <Col sm={8}>{att?.fileName}</Col>
        <Col sm={2} className='Attach-Delete-Icon'><FontAwesomeIcon onClick={e=>{
          let arr=attachments.filter(a=>a.fileId!=att.fileId);
          setAttachments(arr);
          if(arr.length == 0){
            setOpenAttachmentPopUp(false)
          }
        }} icon={faTrashAlt}/></Col>
      </Row>
      </>) 
    })}
  </Modal>
  }
  const isEventOnSameDay = (fromDt) =>{ 
    if(fromDt){ 
      let time= dayjs(fromDt);
      let sameDay= time.isSame(dayjs(new Date()).format("YYYY-MM-DD")) 
        return sameDay  
    }
    return false
  }
  const handleOnChange = (e) => {
    const obj = { ...eventData };
    const error = { ...errors };
    if (e.currentTarget.name == "isallday") {
      setConference(null)
      let all_day=isEventOnSameDay(fromDate) && e.currentTarget.value; 
      all_day=="true"?setAllDay(false):setAllDay(true);
      obj[e.currentTarget.name] = e.currentTarget.value == "true" ? true : false;
    } else {
      obj[e.currentTarget.name] = e.currentTarget.value;
    }

    if (e.currentTarget.name == "title") {
      if (!obj.title) {
        error["title"] = "Please enter title";
        setErrors(error);
      } else {
        setErrors({});
      }
    }
    if (e?.target?.name == "NotificationType") {
      if (obj.action) {
        if (e.currentTarget.checked) {
          obj.action.push(e.currentTarget.value);
        } else {
          obj.action = obj.action.filter(
            (one) => one !== e.currentTarget.value
          );
        }
      } else {
        if (e.currentTarget.checked) {
          obj.action = [e.currentTarget.value];
        }
      }
    }
    setEventData(obj);
  };

  const handleOnChangeNotificationAction = (idx, e) => {
    const obj = { ...eventData };
    if (obj.action) {
      obj.action[idx][e.currentTarget.name] = e.currentTarget.value;
      setEventData(obj);
    }
  };

  const setDateIncrement = (date) => {
    let dateTillArr = date.split("-");
    date = new Date(
      dateTillArr[0],
      parseInt(dateTillArr[1]) - 1,
      dateTillArr[2],
      0,
      0,
      0
    );
    date.setDate(date.getDate() + 1);
    date = dayjs(date).format("YYYY-MM-DD");
    return date;
  };

  const getNotificationArray = (array) => {
    let arr = [];
    array?.forEach((one) => {
      if (one.NotificationOn == "before" && one.NotificationBy.toLowerCase()) {
        arr.push({
          action: one.NotificationBy.toLowerCase(),
          minutes: `-${one.NotificationTime}`,
        });
      }
      if (one.NotificationOn == "after") {
        arr.push({
          action: one.NotificationBy.toLowerCase(),
          minutes: `+${one.NotificationTime}`,
        });
      }
    });
    return arr;
  };

  const addReminders =async (data) => {    
    let reminders = [];
    if (data.reminders && data.reminders.length > 0) {
      data.reminders.forEach(d => {
        let payload = {
          // "type": data.reminders.action,
          "module": "Calendar",
          "messageId": data.uid,
          "reminderHeading": data.title,
          "userEmail": userInfo.email_id,
          "sendAs": d.action,
        };
        if (parseInt(d.minutes) < 0 ) {
          payload.reminderType = "before";
        } else{
          payload.reminderType = "after"
        }
        payload.reminderTime = convertZohoTimeToLocal(data.dateandtime.start,"US",parseInt(d.minutes));
        payload.attendees = data?.attendees?.map(user => user.email).join(',')+`,${userInfo.email_id}`;
        reminders.push(payload);
      })
      await Promise.all(reminders.map(d=>AwsServerService.AddReminders(d))).then(res=>{
        
      })
    }
  }
  const handleReminder = async (event, flag) => {
    
    if (flag == "create" && !isEventInPast()) {
      addReminders(event)
    } else if (flag == "update") {
      //if(state.cacheReminderData.find(k=>k.messageId==event.uid)){
        await AwsServerService.DeleteReminders([event.uid]).then(res => {
          console.log(res);
          if(!isEventInPast()){
            addReminders(event)
          }
        }).catch(e => {
          console.log(e);
        })
      //}
    }
  }

  const handleSave = async (rule) => {
      
    try {
      const newArray=[] 
        eventData.action?.forEach((object,i )=> {
          object.NotificationBy?.forEach(e=>{
            newArray.push({
              NotificationBy:e,
              NotificationOn:object.NotificationOn,
              NotificationTime:object.NotificationTime
            })
          }) 
      });
    setLoading(true)
    let fromDt=fromDate;
    let tillDt=tillDate;
    if(isDayjs(fromDate)){
     fromDt= dayjs(fromDate).format('YYYY-MM-DD')
    }
    if(isDayjs(tillDate)){
      tillDt=dayjs(tillDate).format('YYYY-MM-DD')
    }
    if (isRepeatEvent) {
      rule = { ...repeatObj };
    }
    let errros = { ...errors };
    if (!eventData.title) {
      FocusIntoView("scroll-to-top")
      errros["title"] = "Please enter title";
      setErrors(errros);
      return;
    } else {
      setErrors({});
    }

    let dateFromArr = fromDt.split("-");
    let from = new Date(
      dateFromArr[0],
      parseInt(dateFromArr[1]) - 1,
      dateFromArr[2],
      0,
      0,
      0
    );
    if (fromTime) {
      let fromTime1 = fromTime.split(":");
      from.setHours(parseInt(fromTime1[0]), parseInt(fromTime1[1]));
    }
    let dateTillArr = tillDt.split("-");
    let till = new Date(
      dateTillArr[0],
      parseInt(dateTillArr[1]) - 1,
      dateTillArr[2],
      0,
      0,
      0
    );
    if (tillTime) {
      let tillTime1 = tillTime.split(":");
      till.setHours(parseInt(tillTime1[0]), parseInt(tillTime1[1]));
    }

    let obj = { title: eventData.title, color: eventData.color };
    if (rule && isRepeatEvent) {
      rule = handleConvertRule(rule);
      obj.rrule = rule;
    }
    if (eventData?.conference) {
      obj.conference = eventData?.conference ? eventData?.conference : "none";
    }

    obj.attendees = selectedEmailIds.map((e) => {
      return { email: e.label, permission: "1", attendance: "1" };
    });
    obj.notify_attendee = "2";
    if (eventData.isallday) {
      obj.dateandtime = {
        timezone: localStorage.getItem("TIMEZONE"),
        start: moment(new Date(from)).format("yyyyMMDD"),
        end: moment(new Date(till)).format("yyyyMMDD"),
      };
    } else {
      obj.dateandtime = {
        timezone: localStorage.getItem("TIMEZONE"),
        start: toIsoString(from),
        end: toIsoString(till),
      };
    }
    obj.isrep = isRepeatEvent;
    obj.isallday = eventData.isallday ? true : false
    obj.location = eventData.location
    obj.description = note
    obj.sendnotification = true;
    let res = { ...myCalendar };
    obj.etag = eventData.etag;
    if (editOccurence == "only_this_occurence"){
      obj.recurrenceid = eventData.recurrenceid;
      obj.recurrence_edittype = "only";
    } 
    if (editOccurence == "future_occurence") {
      obj.recurrenceid = eventData.recurrenceid;
      obj.recurrence_edittype = "following";
    }
    let filteredAttachments = attachments?.filter(e=> e.isManual);
    await UploadAttachments(filteredAttachments).then(res=>{
        if(res.length>0){
            let fileIdsArray = res.map(k => k.fileId);
            let fileIdsString = fileIdsArray.join(',');
            obj["attach"]= {"fileId" : fileIdsString};
        }
      }).catch(e=>{
        console.log(e);
      })
    
    if (action == "edit") {
      let rem = getNotificationArray(newArray);
      let backupreminders = rem;
      if (rem.length > 0) {
        obj.reminders = rem.filter(e=> e.action != "popup");
      }
      if(obj?.reminders?.length==0){
        delete obj.reminders;
      }
      await CalendarAPI.UpdateEvent(res.uid, eventData.uid, obj)
        .then((result) => {
          result[0].reminders = backupreminders;
          handleReminder(result[0],"update")
          dispatch(Socket({ trigger: "Refresh_Calendar", data: null }));
          setEventData({
            color: "#8cbf40",
            action: [
              {
                NotificationBy: ["email"],
                NotificationOn: "before",
                NotificationTime: "5",
              },
            ],
          });
          setFromDate(null);
          setFromTime(null);
          setTillDate(null);
          setTillTime(null);
          setConference(null);
          setSelectedEmailIds([]);
          setAttachments([]);
          setInputValue("");
          setNote('');
          setEventEntryPopUpState(false);
          dispatch(forwardObject({module:"",data:"",state:""}))
          getCalendarEventList(getDateRange());
          setAction("create");
          setRepeatObj({...repeatEventObject});
        })
        .catch((err) => {
          console.log(err);
          swal("Error!", "Something went wrong!", "error");
        });
    }
    if (action == "create") {
      let rem = getNotificationArray(newArray);;
      let backupreminders = rem;
      if (rem.length > 0) {
        obj.reminders = rem.filter(e=> e.action != "popup");
      }
      if(obj?.reminders?.length==0){
        delete obj.reminders;
      }
      await CalendarAPI.CreateEvent(res.uid, { eventdata: obj })
        .then((result) => {
          result[0].reminders = backupreminders;
          handleReminder(result[0],"create")
          dispatch(Socket({ trigger: "Refresh_Calendar", data: null }));
          setEventData({
            color: "#8cbf40",
            action: [
              {
                NotificationBy: ["email"],
                NotificationOn: "before",
                NotificationTime: "5",
              },
            ],
          });
          setFromDate(null);
          setFromTime(null);
          setTillDate(null);
          setTillTime(null);
          setSelectedEmailIds([]);
          setInputValue("");
          setAttachments([]);
          setNote('');
          setConference(null);
          setEventEntryPopUpState(false);
          dispatch(forwardObject({module:"",data:"",state:""}))
          getCalendarEventList(getDateRange());
          setAction("create");
          setRepeatObj({...repeatEventObject});
        })
        .catch((err) => {
          console.log(err);
          swal("Error!", "Something went wrong!", "error");
        });
    }
    setLoading(false);
    } catch (error) {
    setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  function FocusIntoView(id) {
    if (id) {
      let dom = document.getElementById(id);
      if (dom) {
        dom.scrollIntoView({ behavior: "smooth" });
      }
    }
  }

  const convertNotifiactionDescToOrignal = (event) => {
    event = { ...event };
    let arr = [];
    if (event?.reminders?.length > 0) {
        event?.reminders?.map((one, i)=>{
          return (
            arr.push({
                    NotificationBy: [one.action],
                    NotificationOn: one.minutes.includes("-") ? "before" : "after",
                    NotificationTime: one?.minutes?.replace("-",'').replace("+",''),
                  })
          )
        })
    } else {
      if (event.description && event.description.includes(`notification`)) {
        arr.push("notification");
      }
      if (event.description && event.description.includes(`email`)) {
        arr.push("email");
      }
      if (event.description && event.description.includes(`popup`)) {
        arr.push("popup");
      }
    }
    const groupedByKeys = {}; 
    arr.forEach(item => {
      const key = `${item.NotificationOn}_${item.NotificationTime}`;
      if (!groupedByKeys[key]) {
        groupedByKeys[key] = { ...item };
      } else {
        groupedByKeys[key].NotificationBy.push(...item.NotificationBy);
      }
    });
    return Object.values(groupedByKeys);
  };

  const [visible, setVisible] = useState(false);

  const handleVisibleChange = (newVisible) => {
    setVisible(newVisible);
  };

  const handleOptionClick = async (option) => {
    let calendar;
    let res;
    let obj;
    let actions;
    setVisible(false); // Close the popover
    switch (option) {
      case 'only_this_occurrence':
        setFromDate(moment(eventData.dateandtime.start).format('YYYY-MM-DD'));
        setFromTime(moment(eventData.dateandtime.start).format('HH:mm'));
        setTillDate(moment(eventData.dateandtime.end).format('YYYY-MM-DD'));
        setTillTime(moment(eventData.dateandtime.end).format('HH:mm'));
        setAction('edit');
        setIsRepeatEvent(false);
        setEditOccurence('only_this_occurence');
        obj = { ...eventData };
        calendar = { ...myCalendar };
        res = await CalendarAPI.GetEventDetail(
          calendar.uid,
          eventData.uid
        ); 
        let extraAction=allReminders?.filter(e=>e.messageId==eventData.uid && e.sendAs=='popup');
        let data=[]
        let recStartTime=[];
        if(eventData.recurrenceid){
          let tempArr=calenderEvents?.filter(k=>k.uid==eventData.uid)?.sort((a,b)=>a.start-b.start);
          recStartTime.push(tempArr[0])
        }
        extraAction.forEach((e)=>{
          let obj={
            action :e.sendAs,
            minutes: calculateTimeForNotification(e.reminderTime,e.reminderType,recStartTime[0].dateandtime)
          }
          data.push(obj);
        })
        res[0]?.reminders?.push(...data);
        actions = convertNotifiactionDescToOrignal(res[0]);
        obj.action = actions;
        setEventData(obj);
        break;
      case 'all_occurrences':
          
        calendar = { ...myCalendar };
        res = await CalendarAPI.GetEventDetail(calendar.uid, eventData.uid);
        if ((res[0]?.attach)?.length > 0) {
          setAttachments(res[0]?.attach);
        }
        obj = { ...eventData };
        obj.description = res[0].description;
        obj.attendees = res[0]?.attendees;
        if (res[0]?.conference_data?.meetingdata) {
          setConference({ label: 'Zoho Meeting', value: 'zmeeting' });
        }
        setNote(res[0]?.description);
        let extraAction2=allReminders?.filter(e=>e.messageId==eventData.uid && e.sendAs=='popup');
        let data2=[]
        extraAction2.forEach((e)=>{
          let obj={
            action :e.sendAs,
            minutes: calculateTimeForNotification(e.reminderTime,e.reminderType,eventData.dateandtime)
          }
          data2.push(obj);
        })
        res[0]?.reminders?.push(...data2);
        actions = convertNotifiactionDescToOrignal(res[0]);
        // actions = convertNotifiactionDescToOrignal(obj);
        obj.action = actions;
        setEventReminders(res[0]?.reminders);
        setFromDate(moment(res[0].dateandtime.start).format('YYYY-MM-DD'));
        setFromTime(moment(res[0].dateandtime.start).format('HH:mm'));
        setTillDate(moment(res[0].dateandtime.end).format('YYYY-MM-DD'));
        setTillTime(moment(res[0].dateandtime.end).format('HH:mm'));
        setAction('edit');
        setEditOccurence('all_occurence');
        setEventData(obj);
        break;
      case 'future_occurrences':
        calendar = { ...myCalendar };
        res = await CalendarAPI.GetEventDetail(calendar.uid, eventData.uid);
        setEventReminders(res[0]?.reminders);
        if ((res[0]?.attach)?.length > 0) {
          setAttachments(res[0]?.attach);
        }
        obj = { ...eventData };
        obj.description = res[0].description;
        obj.attendees = res[0]?.attendees;
        if (res[0]?.conference_data?.meetingdata) {
          setConference({ label: 'Zoho Meeting', value: 'zmeeting' });
        }
        setNote(res[0]?.description);
        let extraAction3=allReminders?.filter(e=>e.messageId==eventData.uid && e.sendAs=='popup');
        let data3=[]
        extraAction3.forEach((e)=>{
          let obj={
            action :e.sendAs,
            minutes: calculateTimeForNotification(e.reminderTime,e.reminderType,eventData.dateandtime)
          }
          data3.push(obj);
        })
        res[0]?.reminders?.push(...data3);
        actions = convertNotifiactionDescToOrignal(res[0]);
        obj.action = actions;
        setFromDate(moment(obj.dateandtime.start).format('YYYY-MM-DD'));
        setFromTime(moment(obj.dateandtime.start).format('HH:mm'));
        setTillDate(moment(obj.dateandtime.end).format('YYYY-MM-DD'));
        setTillTime(moment(obj.dateandtime.end).format('HH:mm'));
        setAction('edit');
        setEditOccurence('future_occurence');
        setEventData(obj);
        break;
      default:
        break;
    }
  };

  const contentforOccuerence = (
    <div>
      <div className="row task-filter-hovr">
        <div className="col-sm-12">
          <a onClick={() => handleOptionClick('only_this_occurrence')}>Only this occurrence</a>
        </div>
      </div>
      <hr className="hrcls" />
      <div className="row task-filter-hovr">
        <div className="col-sm-12">
          <a onClick={() => handleOptionClick('all_occurrences')}>All occurrences</a>
        </div>
      </div>
      <hr className="hrcls" />
      <div className="row task-filter-hovr">
        <div className="col-sm-12">
          <a onClick={() => handleOptionClick('future_occurrences')}>Future occurrences</a>
        </div>
      </div>
    </div>
  );


  const eventEntryPopUp = () => {
    return (
      <Modal
        Draggable
        closable={false}
        className="event_ctm_mod Event-Modal-Class Do-Not-Close"
        style={{ width: "780px" }}
        open={eventEntryPopUpState}
        title={
          <>
            <div
              className="d-flex justify-content-between pr-2"
              style={{ paddingTop: "2px" }}
            >
              <div>
                {eventData.color && eventData.color == "#F26F56"
                  ? "Remindar Event"
                  : "Calendar Event"}
              </div>
              <div className="event_edit_icon">
                {eventData.recurrenceid ? (
                  <AntdPopover
                    trigger={"click"}
                    overlayClassName="custom-popover"
                    content={contentforOccuerence}
                    open={visible}
                    onOpenChange={handleVisibleChange}
                  >
                    {action == "editview" && (
                      <FontAwesomeIcon className="mr-1" icon={faPen} />
                    )}
                  </AntdPopover>
                ) : (
                  action == "editview" && (
                    <FontAwesomeIcon
                      onClick={async () => {
                          
                        let obj = { ...eventData };
                        let calendar = { ...myCalendar };
                        let res = await CalendarAPI.GetEventDetail(
                          calendar.uid,
                          eventData.uid
                        );
                        if((res[0]?.attach)?.length > 0){
                          setAttachments(res[0]?.attach)
                        }
                        setEventReminders(res[0]?.reminders)
                        let extraAction=allReminders?.filter(e=>e.messageId==eventData.uid && e.sendAs=='popup');
                        let data=[]
                        extraAction.forEach((e)=>{
                          let obj={
                            action :e.sendAs,
                            minutes: calculateTimeForNotification(e.reminderTime,e.reminderType,eventData.dateandtime)
                          }
                          data.push(obj);
                        })
                          
                        res[0]?.reminders?.push(...data);
                        let actions = convertNotifiactionDescToOrignal(res[0]);
                        obj.action = actions;
                        obj.description = res[0].description;
                        obj.attendees = res[0]?.attendees;
                        setNote(res[0]?.description);
                        if (res[0]?.conference_data?.meetingdata) {
                          setConference({
                            label: "Zoho Meeting",
                            value: "zmeeting",
                          });
                        }
                        if(res[0].isallday){
                          setTillDate(moment(res[0].dateandtime.start).format("YYYY-MM-DD"));
                        }else{
                          setTillDate(moment(res[0].dateandtime.end).format("YYYY-MM-DD"));
                          setTillTime(
                            moment(res[0].dateandtime.end).format("HH:mm")
                          );
                          setFromTime(
                            moment(res[0].dateandtime.start).format("HH:mm")
                          );
                        }
                        setFromDate(
                          moment(res[0].dateandtime.start).format("YYYY-MM-DD")
                        );
                        setEditOccurence('')
                        setAction("edit");
                        setEventData(obj);
                      }}
                      className="mr-1"
                      icon={faPen}
                    />
                  )
                )}

                {eventData.recurrenceid ? (
                  <AntdPopover
                    trigger={"click"}
                    overlayClassName="custom-popover"
                    content={
                      <div>
                        <div className="row task-filter-hovr">
                          <div className="col-sm-12">
                            <a
                              onClick={async () => {
                                swal({
                                  title:
                                    "Are you sure? want to delete this event",
                                  text: "",
                                  icon: "warning",
                                  buttons: ["No", "Yes"],
                                  dangerMode: true,
                                }).then(async function (isConfirm) {
                                  if (isConfirm) {
                                    let res = { ...myCalendar };
                                    CalendarAPI.DeleteEvent(
                                      res.uid,
                                      eventData.uid,
                                      eventData.etag,
                                      eventData.recurrenceid,
                                      "only"
                                    )
                                      .then((result) => {
                                        setEventEntryPopUpState(false);
                                        dispatch(forwardObject({module:"",data:"",state:""}))
                                        getCalendarEventList(getDateRange());
                                        setAction("create");
                                        setAttachments([]);
                                      })
                                      .catch((err) => {
                                        console.log(err);
                                      });
                                  }
                                });
                              }}
                            >
                              Only this occurence
                            </a>
                          </div>
                        </div>
                        <hr className="hrcls"></hr>
                        <div className="row task-filter-hovr">
                          <div className="col-sm-12">
                            <a
                              onClick={async () => {
                                swal({
                                  title:
                                    "Are you sure? want to delete this event",
                                  text: "",
                                  icon: "warning",
                                  buttons: ["No", "Yes"],
                                  dangerMode: true,
                                }).then(async function (isConfirm) {
                                  if (isConfirm) {
                                    let res = { ...myCalendar };
                                    CalendarAPI.DeleteEvent(
                                      res.uid,
                                      eventData.uid,
                                      eventData.etag,
                                      undefined
                                    )
                                      .then((result) => {
                                        setEventEntryPopUpState(false);
                                        dispatch(forwardObject({module:"",data:"",state:""}))
                                        getCalendarEventList(getDateRange());
                                        setAction("create");
                                        setAttachments([]);
                                      })
                                      .catch((err) => {
                                        console.log(err);
                                      });
                                  }
                                });
                              }}
                            >
                              All occurence
                            </a>
                          </div>
                        </div>
                        <hr className="hrcls"></hr>
                        <div className="row task-filter-hovr">
                          <div className="col-sm-12">
                            <a
                              onClick={() => {
                                swal({
                                  title:
                                    "Are you sure? want to delete this event",
                                  text: "",
                                  icon: "warning",
                                  buttons: ["No", "Yes"],
                                  dangerMode: true,
                                }).then(async function (isConfirm) {
                                  if (isConfirm) {
                                    let res = { ...myCalendar };
                                    CalendarAPI.DeleteEvent(
                                      res.uid,
                                      eventData.uid,
                                      eventData.etag,
                                      eventData.recurrenceid,
                                      "following"
                                    )
                                      .then((result) => {
                                        setEventEntryPopUpState(false);
                                        dispatch(forwardObject({module:"",data:"",state:""}))
                                        getCalendarEventList(getDateRange());
                                        setAction("create");
                                        setAttachments([]);
                                      })
                                      .catch((err) => {
                                        console.log(err);
                                      });
                                  }
                                });
                              }}
                            >
                              Future occurence
                            </a>
                          </div>
                        </div>
                      </div>
                    }
                  >
                    <FontAwesomeIcon className="ml-2 mr-1" icon={faTrash} />
                  </AntdPopover>
                ) : action != "create" ? (
                  <FontAwesomeIcon
                    onClick={async () => {
                      swal({
                        title: "Are you sure? want to delete this event",
                        text: "",
                        icon: "warning",
                        buttons: ["No", "Yes"],
                        dangerMode: true,
                      }).then(async function (isConfirm) {
                        if (isConfirm) {
                          let res = { ...myCalendar };
                          CalendarAPI.DeleteEvent(
                            res.uid,
                            eventData.uid,
                            eventData.etag,
                            eventData.reccurenceid
                          )
                            .then((result) => {
                              setEventEntryPopUpState(false);
                              dispatch(forwardObject({module:"",data:"",state:""}))
                              getCalendarEventList(getDateRange());
                              setAction("create");
                              setAttachments([]);
                            })
                            .catch((err) => {
                              console.log(err);
                            });
                        }
                      });
                    }}
                    className="ml-2 mr-1"
                    icon={faTrash}
                  />
                ) : (
                  <></>
                )}
                <FontAwesomeIcon
                  className="cal-event-close-icon"
                  onClick={() => {
                    setAction("create");
                    setEventEntryPopUpState(false);
                    dispatch(forwardObject({module:"",data:"",state:""}))
                    setConference(null);
                    setNote('');
                    setRepeatObj({...repeatEventObject});
                    setAttachments([]);
                    setIsRepeatEvent(false)
                    setModalPosition({ x: 0, y: 35 })
                    setEventData({
                      color: "#8cbf40",
                      action: [
                        {
                          NotificationBy: ["email"],
                          NotificationOn: "before",
                          NotificationTime: "5",
                        },
                      ],
                    });
                  }}
                  size="2x"
                  icon={faTimes}
                />
              </div>
            </div>
          </>
        }
        onOk={() => {
          setAction("create");
          setEventEntryPopUpState(false);
          dispatch(forwardObject({module:"",data:"",state:""}))
          setConference(null);
        }}
        // onCancel={() => { setAction('create'); setEventEntryPopUpState(false) }}
        modalRender={(modal) => (
          <Draggable
            disabled={true}
            defaultPosition={modalPosition}
            position={{ x: modalPosition.x, y: modalPosition.y }}
            onStop={handleDrag}
          >
            <div>{modal}</div>
          </Draggable>
        )}
        footer={
          action == "edit" || action == "create"
            ? // && (!isRepeatEvent) ?
              [
                <Button
                  key="submit"
                  type="primary"
                  className="footer-btns"
                  loading={loading}
                  onClick={() => {
                    if(!eventData.isallday){
                      if (fromDate && tillDate && tillTime && fromTime) {
                        handleSave();
                      } else {
                        swal("Please select from and till date time", {
                          dangerMode: true,
                        });
                      }
                    }else{
                      if (fromDate && tillDate) {
                        handleSave();
                      } else {
                        swal("Please select from and till date", {
                          dangerMode: true,
                        });
                      }
                    }
                    setModalPosition({ x: 0, y: 35 });
                  }}
                >
                  Save
                </Button>,
                <Button
                  onClick={() => {
                    setAction("create");
                    setEventEntryPopUpState(false);
                    dispatch(forwardObject({module:"",data:"",state:""}))
                    setConference(null);
                    setNote('');
                    setRepeatObj({...repeatEventObject});
                    setAttachments([]);                    
                    setModalPosition({ x: 0, y: 35 })
                    setEventData({
                      color: "#8cbf40",
                      action: [
                        {
                          NotificationBy: ["email"],
                          NotificationOn: "before",
                          NotificationTime: "5",
                        },
                      ],
                    });
                  }}
                  className="footer-btns"
                >
                  Cancel
                </Button>,
              ]
            : null
        }
      >
        <>
          {action  ? eventEditCalender() : editViewCalender()}
        </>
      </Modal>
    );
  };

  const convertWeeklyDayTOShortDay = (days) => {
    if (days) {
      days = days.split(",");
      days = days.filter((day) => day);
      days.forEach((day, idx) => {
        days[idx] = day.slice(0, 2).toUpperCase();
      });
      days = days.join(",");
    }
    return days;
  };

  const handleConvertRule = (rrule) => {
    let rruleStr = "";
    if (rrule.Repeat == "Daily") {
      rruleStr = `FREQ=DAILY;INTERVAL=${rrule.DailyIntervalCount};`;
      if (rrule.DailyInterval == "WeekDay") {
        rruleStr = rruleStr + `BYDAY=MO,TU,WE,TH,FR;`;
      }
      if (rrule.EndBy == "ByDate") {
        rruleStr =
          rruleStr + `UNTIL=${dayjs(rrule.EndByValue).format("YYYYMMDD")};`;
      }
      if (rrule.EndBy == "ByCount") {
        rruleStr = rruleStr + `COUNT=${rrule.EndByValue};`;
      }
    }
    if (rrule.Repeat == "Weekly") {
      rruleStr = `FREQ=WEEKLY;INTERVAL=${rrule.DailyIntervalCount};`;
      rruleStr =
        rruleStr + `BYDAY=${convertWeeklyDayTOShortDay(rrule.WeeklyDay)};`;
      if (rrule.EndBy == "ByDate") {
        rruleStr =
          rruleStr + `UNTIL=${dayjs(rrule.EndByValue).format("YYYYMMDD")};`;
      }
      if (rrule.EndBy == "ByCount") {
        rruleStr = rruleStr + `COUNT=${rrule.EndByValue};`;
      }
    }
    if (rrule.Repeat == "Monthly") {
      rruleStr = `FREQ=MONTHLY;INTERVAL=${rrule.DailyIntervalCount};`;
      // rruleStr = rruleStr + `BYDAY=${convertWeeklyDayTOShortDay(rrule.WeeklyDay)}`;
      if (rrule.OnMonth == "OnDay") {
        rruleStr = rruleStr + `BYMONTHDAY=${rrule.OnDay};`;
      }
      if (rrule.OnMonth == "OnThe") {
        rruleStr =
          rruleStr +
          `BYDAY=${convertWeeklyDayTOShortDay(rrule.MonthDay)};BYSETPOS=${
            rrule.MonthStep
          };`;
      }

      if (rrule.EndBy == "ByDate") {
        rruleStr =
          rruleStr + `UNTIL=${dayjs(rrule.EndByValue).format("YYYYMMDD")};`;
      }
      if (rrule.EndBy == "ByCount") {
        rruleStr = rruleStr + `COUNT=${rrule.EndByValue};`;
      }
    }
    if (rrule.Repeat == "Yearly") {
      rruleStr = `FREQ=YEARLY;INTERVAL=1;BYMONTH=${rrule.WhichMonth};`;

      if (rrule.OnMonth == "OnDay") {
        rruleStr = rruleStr + `BYMONTHDAY=${rrule.OnDay};`;
      }
      if (rrule.OnMonth == "OnThe") {
        rruleStr =
          rruleStr +
          `BYDAY=${convertWeeklyDayTOShortDay(rrule.MonthDay)};BYSETPOS=${
            rrule.MonthStep
          };`;
      }

      if (rrule.EndBy == "ByDate") {
        rruleStr =
          rruleStr + `UNTIL=${dayjs(rrule.EndByValue).format("YYYYMMDD")};`;
      }
      if (rrule.EndBy == "ByCount") {
        rruleStr = rruleStr + `COUNT=${rrule.EndByValue};`;
      }
    }
    return rruleStr;
  };

  // const handleSaveReminder = async (rrule) => {
  //     let dueDate = new Date();
  //     let userinfo = JSON.parse(localStorage.getItem('userinfo'));
  //     let obj = { rrule: rrule, title: eventData.title }
  //     dueDate.setMinutes(dueDate.getMinutes() + 5);
  //     if (dueDate >= new Date()) {
  //         obj.dateandtime = { timezone: userinfo.time_zone, start: toIsoString(dueDate), end: toIsoString(dueDate) };
  //         obj.isrep = true;
  //         obj.isallday = false;
  //         obj.sendnotification = true
  //         obj.reminders = [{ action: 'email', minutes: '-5' }]
  //         let calender = await CalendarAPI.GetAllCalendars();
  //         CalendarAPI.UpdateEvent(calender.uid, eventData.uid, obj)
  //             .then((result) => {
  //                 // console.log(result);
  //                 // onClose()
  //                 // setDueDateTime(new Date())
  //                 // CreateReminder()

  //             })
  //             .catch((err) => {
  //                 console.log(err);
  //             })
  //     } else {
  //         swal('Reminder can not be set in past time', { dangerMode: true });
  //     }

  // }

  function sumOfTotalHrs(preTimeTotalTime, newTime) {
    if (newTime == "N/A") {
      newTime = "00:00";
    }
    let hour = 0;
    let minute = 0;
    let splitTime1 = preTimeTotalTime.split(":");
    let splitTime2 = newTime.split(":");
    hour = parseInt(splitTime1[0]) + parseInt(splitTime2[0]);
    minute = parseInt(splitTime1[1]) + parseInt(splitTime2[1]);
    hour = hour + minute / 60;
    minute = minute % 60;
    hour = Math.trunc(hour);
    minute = Math.trunc(minute);
    hour = (hour < 10 ? "0" : "") + hour;
    minute = (minute < 10 ? "0" : "") + minute;
    let total = hour + ":" + minute; //+ ":" + second;
    console.log("total : " + total);
    return total;
  }
  
  const isEventInPast = () =>{
    if(fromDate && fromTime){
      let time1 = dayjs(fromDate+" "+fromTime);
      let time2 = dayjs(new Date()).format("YYYY-MM-DD HH:MM");
      let isBeforetime = time1.isBefore(dayjs(time2))
      return isBeforetime
    }
    return false
  }

  const eventEditCalender = () => {
    return (
      <>
        <div className="form-group" id="scroll-to-top">

        { isEventInPast() && allDay &&
          <div className="past-event row mb-1 mt-2 mb-0 ml-0 mr-0">
          <div className="col-1"></div>
          <div className="col-10">
            <Row style={{ background: "#fbdddd", padding: "3px"}}>
            <div className="col-1" style={{padding : "0px"}}>
              <img src={WarningSvg} alt="warning" style={{display: "flex", height: "36px",width: "auto"}}/>
          </div>
            <Col sm={11}> <span style={{fontSize: "15px", fontWeight: "bold"}}>The event you are creating is in the past</span> </Col>
            </Row>
          </div>
        </div>
  }

          <div className="Event_color_picker">
            {eventData.color == "#F26F56" ? (
              <div className="color-picker">
                <span
                  style={
                    eventData.color
                      ? { backgroundColor: eventData.color }
                      : { backgroundColor: "#8cbf40" }
                  }
                  className="circle"
                ></span>
              </div>
            ) : (<>
              <div
                style={{ position: 'relative' }}
                onClick={() => {
                  setCompactColorPicker(true)
                }}
              >
                {/* <HexColorPicker
                  color={eventData.color}
                  onChange={(value) => {
                    handleOnChange({
                      currentTarget: { name: "color", value: value },
                    });
                  }}
                /> */}

                <div className="color-picker">
                  <span
                    style={
                      eventData.color
                        ? { backgroundColor: eventData.color }
                        : { backgroundColor: "#8cbf40" }
                    }
                    className="circle iconPosition"
                  ></span>
                  <FontAwesomeIcon icon={faChevronDown} />
                </div>
              </div>
              <OtsideClickClosingWrapper
                forceClose={true}
                close={() => { setCompactColorPicker(false) }}
              >
                {compactColorPicker && action!="editview" && <div className="compactColorPicker">
                  <CompactPicker
                    color={eventData.color}
                    onChange={(value) => {
                      handleOnChange({
                        currentTarget: { name: "color", value: value.hex },
                      })
                      setCompactColorPicker(false)
                    }}
                    colors={HexColorRenges}
                  />
                </div>}
              </OtsideClickClosingWrapper>
            </>
            ) }

            <input
              name="title"
              value={eventData.title ? eventData.title : ""}
              disabled={action == "editview" ? true : false}
              onChange={(e)=>{
                e.target.value=e.target.value.trimStart();
                handleOnChange(e)
              }}
              placeholder="Event Title"
              type="text"
              id='Event Title'
              className="form-control form-control-sm event-title"
            />
          </div>
          {errors.title ? (
            <p
              style={{ paddingLeft: "4rem", paddingTop: "0.3rem" }}
              className="small text-danger"
            >
              {errors.title}
            </p>
          ) : (
            <></>
          )}
        </div>
        <div className="row mt-2">
          <div className="col-1">
            <FontAwesomeIcon
              icon={faCalendarAlt}
              className="crete-event-icons iconPosition"
            />
          </div>
          <div className="col-2">
            {/* <Input
              name="FromDate"
              value={fromDate ? fromDate : ""}
              onChange={(e) => {
                setTillDate(e.currentTarget.value);
                setFromDate(e.currentTarget.value);
              }}
              type="date"
              className="form-control form-control-sm height-control"
            /> */}
              <DatePicker
              value={dayjs(fromDate)}
              disabled={action == "editview" ? true : false}
              className="calender-date-picker2"
              format={'MM/DD/YYYY'}
              style={{ width: 135, height: 25 , color: "#495057"}}
              onChange={(date, dateString) => {
              if (date) {
                  setTillDate(date);
                  setFromDate(date); 
                // setDefaultCalenderViewDate(date.$d);
                // setCalenderView("day");
              }
             }}
            />
          </div>
          {!eventData.isallday ? (
            <div className="col-2">
              <Input
                name="FromTime"
                value={fromTime ? fromTime : ""}
                disabled={action == "editview" ? true : false}
                onChange={(e) => {
                  let till = sumOfTotalHrs(e.currentTarget.value, "00:15");
                  setTillTime(till);
                  setFromTime(e.currentTarget.value);
                }}
                type="time"
                style={{fontWeight:'700'}}
                className="form-control form-control-sm height-control"
              />
            </div>
          ) : (
            <></>
          )}
          <div
            style={eventData.color == "#F26F56" ? { display: "none" } : {}}
            className="col-sm-1"
          >
            TO
          </div>
          <div
            style={eventData.color == "#F26F56" ? { display: "none" } : {}}
            className="col-2"
          >
            {/* <input
              name="TillDate"
              value={tillDate ? tillDate : ""}
              onChange={(e) => setTillDate(e.currentTarget.value)}
              type="date"
              className="form-control form-control-sm height-control"
            /> */}
            <DatePicker
              value={dayjs(tillDate)}
              className="calender-date-picker2"
              disabled={action == "editview" ? true : false}
              format={'MM/DD/YYYY'}
              style={{ width: 135, height: 25 }}
              onChange={(date, dateString) => {
                 
              if (date) {
                setTillDate(date)
              
                if(isRepeatEvent && (repeatObj.EndByValue && dayjs(dateString).isAfter(dayjs(repeatObj.EndByValue)))){ 
                  let temp={...repeatObj};
                  temp.EndByValue=dateString;
                  setRepeatObj(temp);
                }
              // setDefaultCalenderViewDate(date.$d);
              // setCalenderView("day");
             }
             }}
            />
          </div>
          {!eventData.isallday ? (
            <div
              style={eventData.color == "#F26F56" ? { display: "none" } : {}}
              className="col-2"
            >
              <Input
                name="TillTime"
                value={tillTime ? tillTime : ""}
                disabled={action == "editview" ? true : false}
                onChange={(e) => setTillTime(e.currentTarget.value)}
                type="time"
                style={{fontWeight:'700'}}
                className="form-control form-control-sm height-control"
              />
            </div>
          ) : (
            <></>
          )}
          {/* <div className="col-2">
                    <input name="isallday" checked={eventData.isallday || (eventData.isallday == 'true') ? true : false} value={eventData.isallday ? false : true} onChange={handleOnChange} type="checkbox" /> All Day
                </div> */}
        </div>
        <div className="row mt-3 mb-0 ml-0 mr-0" style={{display : action == "editview" ? "none" : ""}}>
          <div className="col-1" style={{ marginLeft: "0.5rem" }}>
          </div>
          <div className="col-2">
            <Input
              name="isallday"
              disabled={eventData?.conference_data?.meetingdata ?true:false}
              checked={
                eventData.isallday || eventData.isallday == "true"
                  ? true
                  : false
              }
              value={eventData.isallday ? false : true}
              onChange={handleOnChange}
              type="checkbox"
            />{" "}
            All Day
          </div>
          {(action == "edit" || editOccurence == "all_occurence" ||
          editOccurence == "future_occurence") && editOccurence!="only_this_occurence" ||
          action == "create" ? (
            <div className="col-2">
              <Input
                onChange={(e) => {
                   
                  setIsRepeatEvent(e.currentTarget.checked); 
                  setConference(null);
                }}
                checked={isRepeatEvent}
                type="checkbox"
              />{" "}
              Recurring
            </div>
          ) : (
            <></>
          )}
        </div>
        {isRepeatEvent ? (
          <div className="row mt-3" style={{
            "padding": "7px 0px",
            "margin": "0px",
          }}>
            <div className="col-1">
            </div>
            <div className="col-10" style={{
              "padding": "7px 16px",
              "background-color": "aliceblue",
              "border-radius": "7px",
              "margin": "0px",
            }}>
              <Reccuring
                repeatObj={repeatObj}
                setRepeatObj={setRepeatObj}
                start={fromDate}
                tillDate={tillDate}
                action={action}
                obj={eventData}
                refreshRecurringData={refreshRecurringData}
                onSave={(rule) => handleSave(rule)}
                onClose={() => setEventEntryPopUpState(false)}
              />
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className="row mt-3 add-participants" style={{ display: "flex" }}>
          <div className="col-6 participants-wrapper">
            <span>
              <FontAwesomeIcon icon={faUser} className="crete-event-icons iconPosition" title="Invite participants"/>
            </span>
            <div style={{position: 'relative',right: '0.2em'}}>
              {/* <Input placeholder="Invite participants" className="Invite-participants"/> */}
              <CreatableSelect
                options={[]}
                components={components}
                inputValue={inputValue}
                isClearable={false}
                isMulti={true}
                onInputChange={(newValue) => setInputValue(newValue)}
                onKeyDown={handleKeyDown}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                onChange={(values) => {                 
                  if(values.find(one=>one.value==userInfo.email_id)){
                    setSelectedEmailIds(values);
                  }else{
                    return
                  }
                }}
                value={selectedEmailIds}
                menuPlacement="auto"
                menuIsOpen={false}
                styles={participantStyles}
                isDisabled={action == "editview" ? true : false}
              />
            </div>
          </div>
          {action != "editview" && !eventData.isallday && !isRepeatEvent &&
          (dayjs(fromDate).isAfter(dayjs().startOf("day")) ||
            dayjs(fromDate).isSame(dayjs().startOf("day"))) ? (
            <div className="col-6 conference-wrapper">
              <span>
                <FontAwesomeIcon icon={faVideo} className="crete-event-icons iconPosition" title="Schedule Meeting"/>
              </span>
              {action == "create"  ? (
                <div className="Meeting-link">
                  {getConferenceDropdown()}
                  <span>
                    <FontAwesomeIcon
                      icon={faTrashAlt}
                      style={{
                        visibility: conference != null ? "visible" : "hidden",
                      }}
                      color="red"
                      onClick={() => {
                        setConference(null);
                      }}
                    />
                  </span>
                </div>
              ) : conference != null ? (
                <div className="Meeting-link">
                  {getConferenceDropdown()}
                  <span>
                    <FontAwesomeIcon
                      icon={faTrashAlt}
                      color="red"
                      onClick={() => {
                        setConference(null);
                      }}
                    />
                  </span>
                </div>
              ) : (
                getConferenceDropdown()
              )}
            </div>
          ) : (
            <></>
          )}
        </div>

        <div className="row mt-3">
                <div className="col-1" style={{left: "-2px"}}>
                  <img src={locationSvg} alt="Location" className="iconPosition" style={{height: "23px", width : "23px"}} title="Location"/>
                </div>
                <div className="col-6">
                <input type="text"
                        disabled={action=="editview"}
                        className="address-text2 form-control"
                        ref={addressInputRef}
                        name="location"
                        placeholder="Location"
                        value={eventData?.location ? eventData?.location : ""} 
                        onBlur={(e)=>{ 
                          handleOnChange(e);
                        }}
                        onChange={(e) =>{ 
                          e.target.value=e.target.value.trimStart().replace(/[_!#$%^&*()<>?|}{~:]/,''); 
                          // onChangeCondoAdd(e)}}
                        handleOnChange(e);
                        initPlaceAPI()
                        }}
                      />
                </div>
        </div>

        {action == "editview" && eventData?.conference_data?.meetingdata ? (
          <div className="row mt-3 Meeting-link">
            <div className="col-1">
              <FontAwesomeIcon icon={faVideo} className="crete-event-icons iconPosition" />
            </div>
            <div className="col-6">
              <Input
                type="text"
                className="Meeting-link-input"
                disabled
                value="Zoho Metting - Video"
              />
              <a
                target="_blank"
                href={eventData?.conference_data?.meetingdata?.meeting_link}
              >
                <Button>Join Meeting</Button>
              </a>
            </div>
          </div>
        ) : (
          <></>
        )}

        <div className="row mt-3" style={{display : action == "editview" ? "none" : ""}}>
          <div className="col-1">
            <FontAwesomeIcon
              icon={faPaperclip}
              className="crete-event-icons iconPosition"
              title="Attachments"
            />
          </div>
            <div className="col-3 calendar-attachments">
                  <span 
                  onClick={() => {
                  handleUploadFiles();
                  }}
                  title="Add attachments"
                  style={{cursor : "pointer" , color : "#4096ff"}}
                  >
                      Add Attachments
                  </span>
            </div>
          </div>

          {attachments?.length > 0 && action != "editview" && <div className="row mt-3 mb-0 ml-0 mr-0">
            <div className="col-1"></div>
            <div className="col-11">
              <div className="row">

            {attachments?.map(att => {
                  return (<>
                    <div className="col-2 Attachment-Card" title={att?.fileName}
                      onContextMenu={e=>{
                        if(action!= "create"){
                          handleContextMenu(e, att, "Other");
                        }
                      }}
                    >
                      <span>{getIconByType(att?.fileName)}</span>
                      <span className='Name'>{att?.fileName}</span>
                      <span className='delete-icon'><FontAwesomeIcon icon={faTimes} onClick={(e)=> {deleteAttachment(att)}}/></span>
                    </div>
                    </>)
                })}
              </div>

            </div>

            </div>}

        {action != "editview" ?
          eventData.action && 
          eventData.action.map((one, idx) => {
            return (
              <div className="row mt-3">
                <div className="col-1">
                  <FontAwesomeIcon
                    icon={faBell}
                    className="crete-event-icons iconPosition"
                    title="Reminders"
                  />
                </div>
                <div className="col-4">
                  {/* <select
                    name="NotificationBy"
                    value={one.NotificationBy}
                    onChange={(e) => handleOnChangeNotificationAction(idx, e)}
                    className="form-control form-control-sm height-control"
                  >
                    <option value={"email"}>Email</option>
                    <option value={"notification"}>Notification</option>
                    <option value={"popup"}>Popup</option>
                  </select> */}
                  <MultiselectCheckbox 
                  idx={idx} 
                  setEventData={setEventData}
                  setEventDataOptions={setEventDataOptions}
                  eventDataOptions={eventDataOptions}
                  eventData={eventData}
                  flag={action}
                  />
                </div>
                <div className="col-2">
                  <select
                    name="NotificationOn"
                    value={eventData.action[idx]?.NotificationOn}
                    onChange={(e) =>{  
                     let data= eventData?.action.map((option,index)=> {
                      if(idx==index){
                        let obj=  {...option,
                          NotificationOn: e.currentTarget.value
                        }
                        return obj;
                      }
                      return option
                    });
                   
                    // setEventDataOptions(data); 
                    setEventData({...eventData,"action":data})
                      // handleOnChangeNotificationAction(1, e)
                    }}
                    className="form-control form-control-sm height-control"
                  >
                    <option value={"before"}>before</option>
                    <option value={"after"}>after</option>
                  </select>
                </div>
                <div className="col-4">
                  <input
                    name="NotificationTime"
                    value={`${eventData.action[idx]?.NotificationTime} mins`}
                    onChange={(e) =>{
                      // if(e.currentTarget.value=='PickCustom'){
                      //   setOpen(true);
                      // }else{
                      //   setOpen(false);
                      // }
                      let data= eventData?.action.map((option,index)=> {
                        if(idx==index){
                          let obj=  {...option,
                            NotificationTime: e.currentTarget.value
                          }
                          return obj;
                        }
                        return option
                      });
                      setEventData({...eventData,"action":data})
                      // setEventDataOptions(data);  
                      // handleOnChangeNotificationAction(1, e)
                    }}
                    onClick={()=>{ 
                      let obj={...eventData.action[idx],"customTime":true}
                     let data= eventData.action?.map((e,index)=>{
                        if(idx==index){
                          return obj
                        }
                        return e
                      })
                      setEventData({...eventData,action:data})
                    }}
                    className="form-control form-control-sm height-control"
                  />
                    {/* <option  value={`${one.NotificationTime}`}>{one.NotificationTime} mins</option>
                    <option value={"5"}>5 mins</option>
                    <option value={"10"}>10 mins</option>
                    <option value={"15"}>15 mins</option>
                    <option value={'PickCustom'}>custom</option> */}
                    {/* <option value={"20"}>20 mins</option>
                    <option value={"25"}>25 mins</option>
                    <option value={"30"}>30 mins</option> */}
                  {/* </select> */}
                <div> <CustomTimePicker eventData={eventData} 
                setEventData={setEventData} idx={idx} 
                /> </div>
                </div>
                <div
                  onClick={() => {
                    let obj = { ...eventData };
                    obj.action.splice(idx, 1);
                    setEventData(obj);
                  }}
                  className="col-1"
                  style={{
                    display: "flex",
                    "align-items": "center",
                    "justify-content": "flex-end",
                    "padding-right": "2rem",
                  }}
                >
                  <FontAwesomeIcon color="red" icon={faTrashAlt} />
                </div>
              </div>
            );
          })
          : 
          getReminderStatement()
        }
        {/* { */}
          <div className="row mt-2" style={{display : action == "editview" ? "none" : ""}}>
            <div className="col-1"></div>
            {!eventData.action || eventData.action.length < 2 ? (
              <div className="col-4">
                <a
                  onClick={() => {
                    const obj = { ...eventData };
                    if (obj.action) {
                      obj.action.push({
                        NotificationBy: ["email"],
                        NotificationOn: "before",
                        NotificationTime: "5",
                      });
                    } else {
                      obj.action = [
                        {
                          NotificationBy: ["email"],
                          NotificationOn: "before",
                          NotificationTime: "5",
                        },
                      ];
                    }
                    setEventData(obj);
                    setEventDataOptions([{
                      NotificationBy: "email",
                      NotificationOn: "before",
                      NotificationTime: "5",
                      isChecked:true
                    },{
                      NotificationBy: "popup",
                      NotificationOn: "before",
                      NotificationTime: "5",
                      isChecked:false
                    },{
                      NotificationBy: "notification",
                      NotificationOn: "before",
                      NotificationTime: "5",
                      isChecked:false
                    }])
                  }}
                  className="cursor-pointer"
                >
                  Add notification
                </a>
              </div>
            ) : (
              <></>
            )}
          </div>
        {/* } */}

          <div className="row mt-3" style={{display : action == "editview" ? "none" : ""}}>
          <div className="col-1">
          <FontAwesomeIcon icon={faBars} title="Add Note" style={{position:'relative',left: '1.2rem',bottom:'5rem'}} className="crete-event-icons" />
          </div>
        <div className="col-10"> 
             <JoditEditor
              // ref={editor}
              value={note}
              tabIndex={1} 
              config={config}
              onChange={(e)=>setNote(e)}

            />
          </div>
          <div className="col-1"></div>
          </div>

       
      </>
    );
  };

  const getReminderStatement = (event) => {
    let str = [];    
    if(event?.length > 0){
      event?.map((one, i)=> {
        str.push(<p className="p-0 m-0"> {one?.minutes?.replace("-",'')?.replace("+",'')} minute {one?.minutes?.includes("-") ? "before" : one?.minutes?.includes("+") ? "after" : ""} by {one.action}</p>);
      })
    }
    return str;
  };

  const editViewCalender = () => {
    return (
      <>
        <div className="form-group">
          <div className="Event_color_picker">
            {eventData.color == "#F26F56" ? (
              <div className="color-picker">
                <span
                  style={{ background: eventData.color ? eventData.color : "rgb(237, 237, 237)"}}
                  className="circle"
                ></span>
              </div>
            ) : (
              <AntdPopover
                trigger={"click"}
                content={
                  <div>
                    <HexColorPicker
                      onChange={(value) => {
                        handleOnChange({
                          currentTarget: { name: "color", value: value },
                        });
                      }}
                    />
                  </div>
                }
              >
                <div className="color-picker">
                  <span
                    style={{ background: eventData.color ? eventData.color : "rgb(237, 237, 237)" }}
                    className="circle"
                  ></span>
                  <FontAwesomeIcon icon={faChevronDown} />
                </div>
              </AntdPopover>
            )}

            <input
              disabled
              name="title"
              value={eventData.title}
              onChange={handleOnChange}
              placeholder="Event Title"
              type="text"
              className="form-control form-control-sm"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-1">
            <FontAwesomeIcon
              icon={faCalendarAlt}
              className="crete-event-icons"
            />
          </div>
          <div className="col-2">
            <input
              disabled
              name="FromDate"
              value={fromDate ? fromDate : ""}
              onChange={(e) => setFromDate(e.currentTarget.value)}
              type="date"
              className="form-control form-control-sm"
            />
          </div>
          {!eventData.isallday ? (
            <div className="col-2">
              <input
                disabled
                name="FromTime"
                value={fromTime ? fromTime : ""}
                onChange={(e) => setFromTime(e.currentTarget.value)}
                type="time"
                className="form-control form-control-sm"
              />
            </div>
          ) : (
            <></>
          )}
          <div
            style={eventData.color == "#F26F56" ? { display: "none" } : {}}
            className="col-sm-1"
          >
            TO
          </div>
          <div
            style={eventData.color == "#F26F56" ? { display: "none" } : {}}
            className="col-2"
          >
            <input
              disabled
              name="TillDate"
              value={tillDate ? tillDate : ""}
              onChange={(e) => {
                 
                setTillDate(e.currentTarget.value)
                let obj={...repeatObj}
                if(e.currentTarget.value){ 
                    obj.EndByValue = dayjs(e.currentTarget.value).add(1,"months").format('YYYY-MM-DD');
                }
                setRepeatObj(obj)
              }}
              type="date"
              className="form-control form-control-sm"
            />
          </div>
          {!eventData.isallday ? (
            <div
              style={eventData.color == "#F26F56" ? { display: "none" } : {}}
              className="col-2"
            >
              <input
                disabled
                name="TillTime"
                value={tillTime ? tillTime : ""}
                onChange={(e) => setTillTime(e.currentTarget.value)}
                type="time"
                className="form-control form-control-sm"
              />
            </div>
          ) : (
            <></>
          )}
          {/* <div className="col-2">
                        <input name="isallday" checked={eventData.isallday || (eventData.isallday == 'true') ? true : false} value={eventData.isallday ? false : true} onChange={handleOnChange} type="checkbox" /> All Day
                    </div> */}
        </div>

        {isRepeatEvent ? (
          <div className="row mt-3" style={{
            "padding": "7px 0px",
            "margin": "0px",
          }}>
            <div className="col-1">
            </div>
            <div className="col-10" style={{
              "padding": "7px 16px",
              "background-color": "aliceblue",
              "border-radius": "7px",
              "margin": "0px",
            }}>
              <Reccuring
                repeatObj={repeatObj}
                setRepeatObj={setRepeatObj}
                start={fromDate}
                tillDate={tillDate}
                action={action}
                obj={eventData}
                refreshRecurringData={refreshRecurringData}
                onSave={(rule) => handleSave(rule)}
                onClose={() => setEventEntryPopUpState(false)}
              />
            </div>
          </div>
        ) : (
          <></>
        )}
        
        {eventData?.conference_data?.meetingdata ? (
          <div className="row mt-3 Meeting-link">
            <div className="col-1">
              <FontAwesomeIcon icon={faVideo} className="crete-event-icons" />
            </div>
            <div className="col-6">
              <Input
                type="text"
                className="Meeting-link-input"
                disabled
                value="Zoho Metting - Video"
              />
              <a
                target="_blank"
                href={eventData?.conference_data?.meetingdata?.meeting_link}
              >
                <Button>Join Meeting</Button>
              </a>
            </div>
          </div>
        ) : (
          <></>
        )}

        {eventData.reminders && eventData.reminders.length > 0 ? (
          <div className="row mt-2">
            <div className="col-1">
              <FontAwesomeIcon icon={faClock} className="crete-event-icons" />
            </div>
            <div className="col-8">
              {
                getReminderStatement(eventReminders).map((str) => {
                  return str;
                })
              }
            </div>
          </div>
        ) : (
          <></>
        )}
      </>
    );
  };

  const handleSelectSlot = useCallback(
    ({ start, end }) => {
      setAllDay(true);
      setEventPopupState("create");
      setSelectedSlot({ start, end });
      setErrors({});
      setEventEntryPopUpState(true);
      setEventData({
        color: "#8cbf40",
        action: [
          {
            NotificationBy: ["email"],
            NotificationOn: "before",
            NotificationTime: "5",
          },
        ],
      });
      setIsRepeatEvent(false);
      setFromDate(moment(start).format("YYYY-MM-DD"));
      setFromTime(moment(start).format("HH:mm"));
      setTillDate(moment(start).format("YYYY-MM-DD"));
      let till = new Date(Date.now());
      till.setMinutes(till.getMinutes() + 15);
      setTillTime(moment(start).add(15, "minutes").format("HH:mm"));
    },
    [setCalendarEvents]
  );

  const convertDateTime = (dateTime) => {
    if (dateTime) {
      // dateTime=dateTime.split('-')
      // dateTime=dateTime.slice(0,dateTime.length-1);
      // dateTime=dateTime.join('')+'Z'
      dateTime = moment(dateTime);
      return dateTime._d;
    }
  };

  const reminderEventBackToOriginalTime = (dateTime) => {
    if (dateTime) {
      let date = convertDateTime(dateTime);
      date.setMinutes(date.getMinutes() - 5);
      return date;
    }
  };

  const getCalendarEventList = async (rangeObj) => {
    setLoading(true)
     
    await CalendarAPI.GetAllCalendars()
      .then(async (res) => {
        await CalendarAPI.GetAllEventsWithoutTime(res.uid).then(data=>{
          setAllEventsForSearch(data);
        }).catch(e=>{
          console.log(e);
        })
        return await CalendarAPI.GetAllEvents(res.uid, JSON.stringify(rangeObj));
      })
      .then((result) => {
        let arr = [];
        if(result[0].message && result[0].message.includes('No events found')){
          return
        }
        result = validation.RemoveNestedArray(result);
        setOriginalEvents(result);
        result?.forEach((obj1) => {
          if (obj1.color == "#F26F56") {
            let obj = {
              color: obj1?.color,
              dateandtime: obj1?.dateandtime,
              uid: obj1?.uid,
              start: reminderEventBackToOriginalTime(obj1?.dateandtime?.start),
              end: convertDateTime(obj1?.dateandtime?.end),
              title: obj1?.title,
            };
            arr.push(obj);
          } else {
            let obj = {
              color: obj1?.color,
              dateandtime: obj1?.dateandtime,
              uid: obj1?.uid,
              start: convertDateTime(obj1?.dateandtime?.start),
              end: convertDateTime(obj1?.dateandtime?.end),
              title: obj1?.title,
            };
            arr.push(obj);
          }
        });

        setCalendarEvents(arr);
        setFilteredCalenderEvents({ status: false, data: arr });
        if (setRefreshEnable) {
          setRefreshEnable(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false)
      });
      setLoading(false)
  };

  const getYears = () => {
    let year = { ...years };
    let years1 = [];
    while (year.start <= year.end) {
      let obj = { id: year.start, name: year.start };
      years1.push(obj);
      year.start = year.start + 1;
    }
    return years1;
  };

  const getMinDate = () => {
    let date = new Date();
    date.setHours(0, 0, 0, 0);
    return date;
  };
  const getMaxDate = () => {
    let date = new Date();
    date.setHours(23, 59, 59, 99);
    return date;
  };

  const [calenderMinDate, setCalenderMinDate] = useState(getMinDate());
  const [calenderMaxDate, setCalenderMaxDate] = useState(getMaxDate());
  const [scrollStartDate, setScrollStartDate] = useState(getMinDate());

  const data = [
    ["Task", "Hours per Day"],
    ["Work", 11],
    ["Eat", 2],
    ["Commute", 2],
    ["Watch TV", 2],
    ["Sleep", 7], // CSS-style declaration
  ];

  const options = {
    // title: "Working Hours",
    pieHole: 0.4,
    pieSliceText: "none",
    is3D: false,

    chartArea: {
      height: 35,
      width: 35,
    },
    tooltip: { isHtml: true, trigger: "none" },
    legend: {
      position: "none",
    },
    slices: { 0: { color: "#0069d9" }, 1: { color: "#696969" } },
  };
  const options1 = {
    // title: "Working Hours",
    pieHole: 0.4,
    pieSliceText: "none",
    is3D: false,
    pieResidueSliceColor: "yellow",

    // chartArea: {
    //   height: 20,
    //   width: 20
    // },
    tooltip: { isHtml: true, trigger: "none" },
    legend: {
      position: "none",
    },
    slices: { 0: { color: "#0069d9" }, 1: { color: "#696969" } },
  };

  // console.log("calnderview", calendreView);

  const getTime = (date) => {
    let Time = new Date(date);
    var hours = Time.getHours();
    var minutes = Time.getMinutes();
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  };
  const getTimeSmallAmPm = (date) => {
    let Time = new Date(date);
    var hours = Time.getHours();
    var minutes = Time.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  };
  const handleNextArrow = (event) => {
    let filterEvent = calenderEvents.filter((one) => one.id == event.event.id);
    let currentIndex = 0;
    filterEvent.forEach((e, index) => {
      if (
        e.start.getTime() == event.event.start.getTime() &&
        e.end.getTime() == event.event.end.getTime()
      ) {
        currentIndex = index;
      }
    });
    if (filterEvent.length > 0) {
      setScrollStartDate(filterEvent[currentIndex + 1].start);
      setDefaultCalenderViewDate(filterEvent[currentIndex + 1].start);
      setTaskContinue(true);
    } else {
      let date = new Date(deafulCalenderViewDate);
      date.setDate(date.getDate() + 1);
      setDefaultCalenderViewDate(date);
      setTaskContinue(true);
    }
  };
  const handleOnChangeEvent = (e) => {
    const event = { ...editEvent };
    event[e.target.name] = e.target.value;
    setEditEvent(event);
  };
  const renderEvent = (event) => {
    let eventDetails=originalEvents.find(e=>e.uid==event.event.uid);
    let eventColor=eventDetails?.isallday?(event?.event?.color?event?.event?.color:calendarEventType["All Day"].color): calendarEventType["Default"].color;
    let color=invertColor(eventColor,true)
    return (
      <>
        <div className="event-parent test-class2">
        <div style={{color: color }} 
                    className="d-flex align-items-center justify-content-start">
           
              {!eventDetails?.isallday && <FontAwesomeIcon
                className="mr-2 crete-event-icons"
                color={eventDetails?.color?eventDetails?.color:"green"}
                icon={faCircle}
              />}{" "}
            {/* ) : (
              <FontAwesomeIcon
                className="mr-2"
                color={color}
                icon={faCalendarAlt}
              />
            )} */}
            <span style={{
              "white-space": "nowrap",
              "overflow": "hidden",
              "text-overflow": "ellipsis",
            }}>
              <span style={{ fontWeight: "400" }}>
              {(!event?.event?.isallday? 
                (calendreView.label == "Month" ?
                  moment(event.event.start).format("hh:mm a")
                  : moment(event.event.start).format("hh:mm a") + "-" + moment(event.event.end).format("hh:mm a"))
                : "") + " "}
              </span>
              <span style={{ fontWeight: "500" }}>
                {event.event.title}
              </span>
            </span>  
          </div>
        </div>
      </>
    );
  };
  const getAssigneeImg = (assigneeId, i) => {
    let imgUrl = "";
    //  setPrevCheckList(CheckList[i])
    let obj = allusers.find((user) => user.UserId == assigneeId);
    if (obj != undefined) {
      return obj.ProfilePhoto;
    } else {
      // if(usersDetail.length>0) {
      //   usersDetail.forEach(user=> {
      //     if(user.UserId == assigneeId){    //|| user._id == assigneeId
      //       imgUrl = user.ProfilePhoto;
      //     }
      //   })
      // }
      return imgUrl;
    }
  };

  const addAssigneeImgTblImg = (assignee, taskId) => {
    if (getAssigneeImg(assignee.userId) != "") {
      return (
        <>
          <UncontrolledTooltip
            placement="top"
            target={"user-name-tooltip" + assignee.userId + taskId}
          >
            {assignee.userName}
          </UncontrolledTooltip>
          <div className="assignee-img">
            <img
              id={"user-name-tooltip" + assignee.userId + taskId}
              src={getAssigneeImg(assignee.userId)}
              className="rounded-circle selected-avtar"
            />
          </div>
        </>
      );
    } else {
      return (
        <>
          {" "}
          <UncontrolledTooltip
            placement="top"
            target={"user-name-tooltip" + assignee.userId + taskId}
          >
            {assignee.userName}
          </UncontrolledTooltip>
          <div className="assignee-img">
            <div
              id={"user-name-tooltip" + assignee.userId + taskId}
              className="no-image-user"
            >
              {assignee.userName.charAt(0)}
            </div>
          </div>
        </>
      );
    }
    // return (
    //   <img
    //     src={getAssigneeImg(assignee.userId)}
    //     className="rounded-circle selected-avtar"
    //   />
    // );
  };

  const getTaskId = (oprationType, task, sub, meta) => {
    if (oprationType === "TASK_DROPDOWN_MENU") {
      return task._id;
    } else if (oprationType === "SUBTASK_DROPDOWN_MENU") {
      return sub._id;
    } else {
      return meta._id;
    }
  };

  const getDropdownMenu = (
    tasks,
    fnName,
    assignee,
    oprationType,
    subTask,
    metaTask
  ) => {
    return (
      <>
        {assignee === null
          ? fnName("", getTaskId(oprationType, tasks, subTask, metaTask))
          : fnName(assignee, getTaskId(oprationType, tasks, subTask, metaTask))}
      </>
    );
  };
  const [selectEventPopUp, setSelectEventPopUp] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [dealObj, setDealObj] = useState({});
  const [selectedDealId, setSelectedDealId] = useState("");

  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    // if (openModal && (!taskContinue) && (!deleteClick) && (!timeEntryPopUp)) {
    //   handleSelectEvent(selectedEvent);
    //   setScrollStartDate(selectedEvent.start);
    //   setOpenModal(false);
    // } else {
    //   setOpenModal(false);
    //   setTaskContinue(false);
    //   setDeleteClick(false);
    //   //setTimeEntryPopUp(false);
    // }
  }, [openModal]);

  const handleSelectEvent = (event) => {
    let deals = [...allDeal];
    let alltask = [...allTask];
    deals = deals.filter((deal) => deal._id == event.dealId);
    alltask.forEach((task) => {
      if (task._id == event.id) {
        openUpdateModal(task, "task");
      } else {
        task.Subtask.forEach((sub) => {
          if (sub._id == event.id) {
            openUpdateModal(sub, "subtask", task);
          } else {
            sub.Subtask.forEach((meta) => {
              if (meta._id == event.id) {
                openUpdateModal(meta, "metatask", task, sub);
              }
            });
          }
        });
      }
    });
    setDealObj(deals[0]);
    setSelectedDealId(deals[0]._id);
    setSelectedEvent(event);
    setSelectEventPopUp(true);
  };

  const [subTaskData, setSubtaskData] = useState(null);
  const [metaTaskData, setMetaTaskData] = useState(null);
  const [subtaskNestedModal, setSubtaskNestedModal] = useState(false);
  const [metaNestedModal, setMetaTaskNestedModal] = useState(false);
  const [parentObj, setParentObject] = useState(null);
  const [taskAction, setTaskAction] = useState("");
  const [openmodalType, setOpenModalType] = useState("task");
  const [nestedModal, setNestedModal] = useState(false);
  const [DependencyDropdownData, setDependencyDropdownData] = useState([]);
  const handleCloseSubMetaTaskModal = (taskType, task) => {
    if (taskType === "subtask") {
      setSubtaskNestedModal(false);
      setOpenModalType("task");
      setParentObject(task);
      setNestedModal(true);
    }
    if (taskType === "metatask") {
      setMetaTaskNestedModal(false);
      setSubtaskNestedModal(true);
      setNestedModal(true);
      setSubtaskData(task);
      setOpenModalType("subtask");
    }
  };
  const handleCloseTaskModel = async (taskType, task) => {
    if (taskType == "task") {
      setNestedModal(false);
      setParentObject(null);
    }
    if (taskType == "subtask") {
      setOpenModalType("task");
      setTaskAction("Edit");
      setSubtaskNestedModal(false);
      // let ref = await HttpService.CreateUpdate('getTaskRelationDocByTaskId', { TaskId: task._id });
      // if (ref.data.length > 0) {
      //   let task = taskDocs.filter((one) => one._id == ref.data[0].ParentTaskId)
      //   if (task.length > 0) {
      //     setTaskAction('Edit')
      //     setParentObject({ ...task[0] });
      //   }
      // }
    }
    if (taskType == "metatask") {
      setMetaTaskNestedModal(false);
      setOpenModalType("subtask");
      setTaskAction("Edit");
      // let ref = await HttpService.CreateUpdate('getTaskRelationDocByTaskId', { TaskId: task._id });
      // if (ref.data.length > 0) {
      //   let task = taskDocs.filter((one) => one._id == ref.data[0].ParentTaskId)
      //   if (task.length > 0) {
      //     setTaskAction('Edit')
      //     setOpenModalType('subtask');
      //     setParentObject({ ...task[0] });
      //   }
      // }
    }
  };
  const [loading, setLoading] = useState(false);
  const openUpdateModal = async (data, type, parentdata, metaSubTask) => {
    if (type == "task") {
      setOpenModalType(type);
      setParentObject(data);
      setTaskAction("Edit");
      setNestedModal(true);
    }
    if (type == "subtask") {
      setOpenModalType(type);
      setSubtaskData(data);
      setTaskAction("Edit");
      setSubtaskNestedModal(true);
      setParentObject(parentdata);
    }
    if (type == "metatask") {
      setOpenModalType(type);
      setMetaTaskData(data);
      setTaskAction("Edit");
      setParentObject(metaSubTask);
      setMetaTaskNestedModal(true);
    }
    // let tList = [...allTaskList];
    // setDependencyDropdownData(tList);
  };
  const [scrollPositon, setScrollPosition] = useState(0);
  const getScrollPosition = () => {
    let elem = document.getElementsByClassName("rbc-time-content")[0];
    if (elem) {
      setScrollPosition(elem.scrollTop);
    }
  };
  const setScrollPositionFun = () => {
    let elem = document.getElementsByClassName("rbc-time-content")[0];
    if (elem) {
      elem.scrollTop = scrollPositon;
    }
  };
  const [allTask, setAllTask] = useState([]);
  const getallTasks = async () => {
    setLoading(true);
    HttpService.getByBoj("gettask", {})
      .then((result) => {
        // setLoading(false);
        // setAllTask(result.data);
        // getAllUserTask();
        // setScrollPositionFun()
      })
      .catch((err) => {
        setLoading(false);
        // swal(`${err}`)
      });
  };

  const getDateCellWrapper = (view, date) => {
    let data = [];
    if (allCacheUser.length > 0) {
      if (selectedUser == "All Users" || selectedUser == "all") {
        data = getChartDataForDateEventWrapper(
          allCacheUser,
          calenderEvents,
          view,
          date
        );
      } else {
        let filterUser = allCacheUser.filter(
          (user) => user.UserId == selectedUser
        );
        data = getChartDataForDateEventWrapper(
          filterUser,
          calenderEvents,
          view,
          date
        );
      }
    }
    return data;
  };

  const getChartDataForDateEventWrapper = (users, events, view, date) => {
    // let totalMinutes = 0;
    // let usedMinutes = 0;
    // users.forEach((user) => {
    //   totalMinutes = totalMinutes + getTotalWorkingHoursForUser(view, user, date)
    // })
    // if (view == 'month') {
    //   let monthDuration = dateTime.getMonthStartEnd(date)
    //   events = events.filter((event) => {
    //     if ((monthDuration.start < event.start) && (monthDuration.end > event.end)) {
    //       return event;
    //     }
    //   })
    // }
    // else if (view == 'week') {
    //   let monthDuration = dateTime.getWeekStartEnd(date)
    //   events = events.filter((event) => {
    //     if ((monthDuration.start < event.start) && (monthDuration.end > event.end)) {
    //       return event;
    //     }
    //   })
    // }
    // else if (view == 'day') {
    //   events = events.filter((event) => {
    //     if (((date.getDate() == event.start.getDate()) && (date.getMonth() == event.start.getMonth())) &&
    //       ((date.getDate() == event.end.getDate()) && (date.getMonth() == event.end.getMonth()))) {
    //       return event;
    //     }
    //   })
    // }
    // usedMinutes = usedMinutes + getUsedMinites(events);
    // let availableMinutes = totalMinutes - usedMinutes;
    // let chartSchema = [
    //   { type: "string", id: "Title" },
    //   { type: "number", id: "Hours per Day" },
    //   { type: "string", role: "tooltip", p: { html: true } },
    // ];
    // let html = `<p class='calender-tooltip' style="z-index:-999999"><table><tr><td >Available Hours</td><td>${getHours(availableMinutes)}</td></tr>
    // <tr><td>Used Hours</td><td>${getHours(usedMinutes)}</td></tr></table></p>`
    // const data = [
    //   chartSchema,
    //   ["Used Hours", getHours(usedMinutes), html],
    //   ["Available Hours", getHours(availableMinutes), html],
    // ];
    // return data;
  };
  const getHours = (minutes) => {
    let hour = Math.floor(minutes / 60);
    return hour;
  };
  const eventStyleGetter = (event, start, end, isSelected) => {
    // let eventDetails=originalEvents.find(e=>e.uid==event.event.uid);
    let eventDetails=originalEvents?.find(e=>e.uid==event.uid);
    let eventColor=eventDetails?.isallday?(event?.color?event?.color:calendarEventType["All Day"].color): calendarEventType["Default"].color;
    let color = "";
    let borderColor = "#fff";
    if (event.isBreak || event.isHoliday) {
      color = "#D3D3D3";
      borderColor = "#D3D3D3";
    } else {
      color = event.color;
    }
    var backgroundColor = color;
    var style = {
      backgroundColor: eventColor,
      borderRadius: "5px !important",
      marginTop: "0px",
      opacity: 0.8,
      color: "black",
      padding: calendreView.value !== "month" ? "5px 0" : "",
      // marginLeft: "3%",
      // marginRight: "3%",
      width: "94%",
      border: "none",
      borderBottom: "1px solid " + borderColor,
    };
    return {
      style: style,
    };
  };

  const getCalenderEvents1 = (holiday, taskEvents) => {
    let event = [...holiday, ...taskEvents];
    event = event.filter((one) => {
      if ((one.end.getTime() - one.start.getTime()) / (60 * 1000) >= 1) {
        return event;
      }
    });
    if (calendreView?.value == "month" || calendreView?.value == "week") {
      event = event.filter((e) => !e.isBreak);
    }

    if (calendreView?.value == "month") {
      event = _.unionBy(event, "id");
    }
    return event;
  };

  const getCalenderResorces = (users) => {
    users = [...users];
    let data = [];
    if (calendreView?.value == "day") {
      if (selectedUser == "All Users" || selectedUser == "all") {
        users.forEach((user) => {
          let obj = {
            id: user.UserId,
            title: user.FirstName + " " + user.LastName,
          };
          data.push(obj);
        });
      } else {
        let filterUser = users.filter((one) => one.UserId == selectedUser);
        if (filterUser.length > 0) {
          data.push({
            id: filterUser[0].UserId,
            title: filterUser[0].FirstName + " " + filterUser[0].LastName,
          });
        }
      }
      if (data.length == 0) {
        data = null;
      }
    } else {
      data = null;
    }
    return data;
  };

  // const DateCellWrapper = ({ value, children }) => {
  //   const width = "14.28571429%";
  //   const style = {
  //     WebkitFlexBasis: width,
  //     flexBasis: width,
  //     maxWidth: width,

  //   };

  //   return (
  //     <div style={style}>
  //       <div>click</div>
  //       {children}
  //       </div>
  //   );
  // }

  const dailyChartContent = (
    <table className="bordered table table-sm m-0">
      <thead>
        <tr>
          <th>Employee</th>
          <th>Used Hours</th>
          <th>Available Hours</th>
          <th>Total</th>
        </tr>
      </thead>
      <tbody>
        {chartTooltipTableData.map((one) => {
          return (
            <tr>
              <th>{one.Name}</th>
              <td className="text-center">{Math.floor(one.used / 60)}</td>
              <td className="text-center">{Math.floor(one.available / 60)}</td>
              <td className="text-center">{Math.floor(one.total / 60)}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );

  const isNotWorkesd = () => {};

  const ColoredDateCellWrapper = ({ children, value }) => {
    // return <div>Text</div>
    return React.cloneElement(Children.only(children), {
      style: {
        ...children.style,
        backgroundColor:
          value.toLocaleDateString() == new Date().toLocaleDateString()
            ? "rgb(239 245 255)"
            : "",
      },
    });
  };

  const DateCellWrapper = ({ value, children }) => {
    return <div>Test</div>;
  };
  const performDateChange=(click)=>{
    return new Promise((resolve,reject)=>{
       
      let date = new Date(deafulCalenderViewDate);
      if (calendreView?.value == "day") {
        if (click == "Back") {
          date.setDate(date.getDate() - 1);
        }
        if (click == "Today") {
          date = new Date();
        }
        if (click == "Next") {
          date.setDate(date.getDate() + 1);
        }
      }
      if (calendreView?.value == "week") {
        if (click == "Back") {
          date.setDate(date.getDate() - 7);
        }
        if (click == "Today") {
          date = new Date();
        }
        if (click == "Next") {
          date.setDate(date.getDate() + 7);
        }
      }
      if (calendreView?.value == "month") {
        if (click == "Back") {
          date.setMonth(date.getMonth() - 1);
        }
        if (click == "Today") {
          date = new Date();
        }
        if (click == "Next") {
          date.setMonth(date.getMonth() + 1);
        }
      }
      resolve(date);
    })
  }
  const goToDate = async (click) => {
    // setLoading(true);
    await performDateChange(click).then(date=>{
      setDefaultCalenderViewDate(date);
    }).catch(e=>{
      console.log(e);
    })
    // setLoading(false);
  };

  const handleChangeLabel = (e) => {
    let date = new Date(deafulCalenderViewDate);
    if (e.target.name == "Month") {
      date.setMonth(parseInt(e.target.value) - 1);
    } else {
      date.setFullYear(parseInt(e.target.value));
      // setDayLabelHeaderEdit(false);
    }
    setDefaultCalenderViewDate(date);
  };

  const handleClickZoomButtons = (type) => {
    const slot = timeSlot;
    if (type == "Plus") {
      if (slot >= 60) {
        setTimeSlot(slot + 60);
      }
      if (slot == 15 || slot == 30) {
        setTimeSlot(slot * 2);
      } else if (slot == 5) {
        setTimeSlot(15);
      } else if (slot == 1) {
        setTimeSlot(5);
      }
    } else {
      if (slot > 1) {
        if (slot > 60) {
          setTimeSlot(slot - 60);
        } else if (slot == 60 || slot == 30) {
          setTimeSlot(slot / 2);
        } else if (slot == 15) {
          setTimeSlot(5);
        } else if (slot == 5) {
          setTimeSlot(1);
        }
      }
    }
  };

  const getHeaderLabel = () => {
    // if (calendreView == 'day') {
    //   return `${days[deafulCalenderViewDate.getDay()]}, ${monthName.filter(m => m.id == `${deafulCalenderViewDate.getMonth() + 1}`)[0].name}   ${deafulCalenderViewDate.getDate()}`
    // } else if (calendreView == 'week') {
    //   let obj = dateTime.getWeekStartEnd(new Date(deafulCalenderViewDate));
    //   return `${monthName.filter(m => m.id == `${obj.start.getMonth() + 1}`)[0].name} ${obj.start.getDate()} - ${monthName.filter(m => m.id == `${obj.end.getMonth() + 1}`)[0].name} ${obj.end.getDate()}`
    // } else {
    //   return `${monthName.filter(m => m.id == `${deafulCalenderViewDate.getMonth() + 1}`)[0].name} , ${deafulCalenderViewDate.getFullYear()}`
    // }
  };

  const getScrollTime = (events1) => {
    events1 = [...events1];
    events1 = events1.sort((a, b) => new Date(a.start) - new Date(b.start));
    if (events1.length > 0) {
      return events1[0].start;
    } else {
      return new Date();
    }
  };

  const getTimeLowestTimeSlot = (events) => {
    events = [...events];
    if (calendreView?.value == "day") {
      events = events.filter(
        (one) =>
          new Date(one).toLocaleDateString() ==
          deafulCalenderViewDate.toLocaleDateString()
      );
    }
    let eventDiff = [];
    events.forEach((one) => {
      let diff = new Date(one.end).getTime() - new Date(one.start).getTime();
      diff = Math.floor(diff / (1000 * 60));
      eventDiff.push(diff);
    });
    eventDiff.sort(function (a, b) {
      return a - b;
    });

    if (eventDiff.length > 0) {
      return eventDiff[0] + 2;
    } else {
      return 30;
    }
  };
  const dateFormat = "DD MMMM YYYY";
  const weekFormat = "MM/DD";
  const monthFormat = "MMMM YYYY";
  const customDateCellLabel = ({ date, label }) => {
    const formattedDate = dayjs(date).format("D");
    return <div>{formattedDate}</div>;
  };
  const monthDateLabel = ({ date, label }) => {
    let nexMonthDate = new Date(deafulCalenderViewDate);
    nexMonthDate.setMonth(nexMonthDate.getMonth() + 1);
     

    if (date.toLocaleDateString() == new Date().toLocaleDateString()) {
      return <span style={{fontSize:"1rem"}}className="current-date-label">{label}</span>;
    } else if (
      (deafulCalenderViewDate.getMonth() !== new Date().getMonth() &&
        new Date(
          dayjs(deafulCalenderViewDate).startOf("month").$d
        ).toLocaleDateString() == date.toLocaleDateString()) ||
      date.toLocaleDateString() ==
        new Date(dayjs(nexMonthDate).startOf("month").$d).toLocaleDateString()
    ) {
      return (
        <span style={{ backgroundColor: "gray" ,fontSize:"1rem" }} className="today-date-label">
          {dayjs(date).format("MMM DD")}
        </span>
      );
    } else if (
      date.toLocaleDateString() ==
      new Date(dayjs(date).startOf("month").$d).toLocaleDateString()
    ) {
      return (
        <span style={{color:"white",backgroundColor:"#007bff",fontSize:"1rem"}} className="today-date-label">{dayjs(date).format("MMM DD")}</span>
      );
    } else {
      return <span style={{fontSize:"1rem"}}>{label}</span>;
    }
  };

  async function onClickEvent(event,searchFlag) {
     
    try {
      if (!event.isBreak && !event.isHoliday) {
        setEditOccurence("");
        setEventPopupState("create");
        setLoading(true);
        let events = originalEvents.filter(
          (one) =>
            one.uid == event.uid && moment(one.dateandtime.start).isSame(moment(event.dateandtime.start))
            //  && one.dateandtime.start == event.dateandtime.start
        );
        if(searchFlag){
          events[0]=event;
        }
        if (events.length > 0) {
          let calendar = { ...myCalendar };
          let res = await CalendarAPI.GetEventDetail(calendar.uid, event.uid);
          if((res[0]?.attach)?.length > 0){
            setAttachments(res[0]?.attach || [])
          }
          setEventReminders(res[0]?.reminders)
          setNote(res[0]?.description);
          let all_day=isEventOnSameDay(moment(events[0].dateandtime.start).format("YYYY-MM-DD")) && res[0].isallday;
          all_day==true?setAllDay(false):setAllDay(true);  
          events[0].reminders = res[0]?.reminders;
          events[0].description = res[0]?.description;
          events[0].attendees = res[0]?.attendees;
          if (events[0]?.conference_data?.meetingdata) {
            setConference({ label: "Zoho Meeting", value: "zmeeting" });
          }
          setAction("editview");
          setErrors({});
          setSelectedEvent(events[0]);
          setFromDate(moment(events[0].dateandtime.start).format("YYYY-MM-DD"));
          setFromDate(
            moment(events[0].dateandtime.start).format("YYYY-MM-DD")
          );
          if(!events[0].isallday){
          setFromTime(moment(events[0].dateandtime.start).format("HH:mm"));
          setTillTime(moment(events[0].dateandtime.end).format("HH:mm"));
          setTillDate(moment(events[0].dateandtime.end).format("YYYY-MM-DD"));
          }else if(events[0].isallday){
            setTillDate(moment(events[0].dateandtime.start).format("YYYY-MM-DD"));
          }

          if (events[0].recurrenceid || events[0].rrule) {
            setIsRepeatEvent(true);
          }
          if(events[0]?.attendees?.length > 0){
            let arr = [];
            events[0].attendees = events[0].attendees.filter(one=> {
              if(!arr.includes(one?.email)){
                arr.push(one?.email);
                return one
              }
            })
          }
          setEventData(events[0]);
          setEventEntryPopUpState(true);
        }
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }

  const MyCalendar = (props) => (
    <div className="pos-relative">
      <DnDCalendar
        
        // start time 8:00am
        popup
        min={calenderMinDate}
        // end time 5:00pm
        max={calenderMaxDate}
        scrollToTime={scrollStartDate}
        timeslots={timeSlot <= 30 ? timeSlot : 30}
        step={timeSlot >= 60 ? timeSlot / 30 : 1}
        eventPropGetter={eventStyleGetter}
        selectable={true}
        localizer={localizer}
        onEventDrop={moveEvent}
        //resizable={true}
        views={["month", "week", "day"]}
        view={calendreView?.value}
        // slotDuration={ days: 1 }
        dayLayoutAlgorithm="no-overlap"
        components={{
          eventWrapper: function (props) {
            let eventDetails=originalEvents.find(e=>e.uid==props.event.uid);
            let eventColor=eventDetails?.isallday?(props?.event?.color?props?.event?.color:calendarEventType["All Day"].color): calendarEventType["Default"].color;
              
            console.log(props.children)
            return (
              <div
                className="Test Class"
                // style={{backgroundColor:eventColor}}
                onContextMenu={(e) => {
                  e.preventDefault();
                  setToolboxPosition({ x: e.clientX, y: e.clientY });
                  setIsOpen({ state: true, object: props.event });
                }}
              >
                {props.children}
              </div>
            );
          },
          timeGutterWrapper: function (props) {
            return <div></div>;
          },
          // dayColumnWrapper
          // dayColumnWrapper:function(props){
          //     
          //   return <div className="test-rushi">{props.children}</div>
          // },
          resourceHeader: function (props) {
            return <p>{props.resource.title}</p>;
          },

          toolbar: function header(toolbar) {
            return <></>;
            return (
              <div className="rbc-toolbar">
                <span className="rbc-btn-group float-left">
                  <div className="d-flex">
                    <span
                      style={{ color: "rgb(1, 142, 224)" }}
                      onClick={() => goToDate("Today")}
                    >
                      {calendreView == "month"
                        ? "This month"
                        : calendreView == "week"
                        ? "This week"
                        : calendreView == "day"
                        ? "Today"
                        : ""}
                    </span>
                    <div className="mx-3">
                      <span className="mr-2" onClick={() => goToDate("Back")}>
                        <FontAwesomeIcon icon={faChevronLeft} />
                      </span>
                      <span className="ml-2" onClick={() => goToDate("Next")}>
                        <FontAwesomeIcon icon={faChevronRight} />
                      </span>
                    </div>
                    <span>
                      <Space className="mr-4" direction="vertical" size={12}>
                        {calendreView == "day" ? (
                          <DatePicker
                            value={dayjs(deafulCalenderViewDate)}
                            className="calender-date-picker"
                            format={dateFormat}
                            style={{ width: 135, height: 25 }}
                            onChange={(date, dateString) => {
                              if (date) {
                                setDefaultCalenderViewDate(date.$d);
                                setCalenderView("day");
                              }
                            }}
                          />
                        ) : (
                          <DatePicker
                            picker={calendreView}
                            value={dayjs(deafulCalenderViewDate)}
                            className="calender-date-picker"
                            format={dateFormat}
                            style={{ width: 135, height: 25 }}
                            onChange={(date, dateString) => {
                              if (date) {
                                setDefaultCalenderViewDate(date.$d);
                                setCalenderView("day");
                              }
                            }}
                          />
                        )}
                      </Space>
                    </span>
                  </div>
                  {/* <button type="button" >
                                    <span className="prev-icon" onClick={() => goToDate('Back')}>Back</span>
                                </button>
                                <button type="button" >
                                    <span className="next-icon" onClick={() => goToDate('Today')}>Today</span>
                                </button>
                                <button type="button" >
                                    <span className="next-icon" onClick={() => goToDate('Next')}>Next</span>
                                </button> */}
                </span>
                <span className="rbc-toolbar-label">
                  {calendreView == "month" ? (
                    <div className="row justify-content-center">
                      <div
                        className={
                          from == "popover"
                            ? "col-md-5 col-lg-5 px-1"
                            : "col-md-3 col-lg-2 px-1"
                        }
                      >
                        <select
                          onChange={handleChangeLabel}
                          name="Month"
                          value={`${deafulCalenderViewDate.getMonth() + 1}`}
                          className="form-control form-control-sm currentTime-dropdown"
                        >
                          {monthName.map((m) => {
                            return <option value={m.id}>{m.name}</option>;
                          })}
                        </select>
                      </div>

                      <div
                        className={
                          from == "popover"
                            ? "col-md-5 col-lg-5 px-1"
                            : "col-md-3 col-lg-2 px-1"
                        }
                      >
                        <select
                          onChange={handleChangeLabel}
                          name="Year"
                          value={deafulCalenderViewDate.getFullYear()}
                          className="form-control form-control-sm currentTime-dropdown"
                        >
                          {getYears().map((m) => {
                            return <option value={m.id}>{m.name}</option>;
                          })}
                        </select>
                      </div>
                    </div>
                  ) : (
                    getHeaderLabel()
                  )}
                </span>
                <span className="rbc-btn-group float-right"></span>
              </div>
            );
          },

          event: renderEvent,
          dateCellWrapper: ColoredDateCellWrapper,
          month: {
            dateHeader: monthDateLabel,
          },
          week: {
            dateHeader: monthDateLabel,
          },
        }}
       
        draggableAccessor={false}
        resizableAccessor={false}
        //onView={(view) => setCalenderView(view)}
        onSelectEvent={async (event) => {
          onClickEvent(event);
        }}
        date={deafulCalenderViewDate}
        //onEventResize={resizeEvent}
        dayPropGetter={calendarDayStyle}
        events={filteredCalenderEvents?.data}
        resources={getCalenderResorces(allCacheUser)}
        startAccessor="start"
        endAccessor="end"
        onSelectSlot={handleSelectSlot}
        style={{ height: "calc(100vh - 225px)" }}
      />
      {(calendreView?.value == "day"||calendreView?.value == "week")  ? (
        <div className="caledar-txtbox">
          <div className="caltime">
            Time
            <div
              className="w-10"
              style={{ fontSize: "15px", color: "rgb(1, 142, 224)" }}
            >
              <FontAwesomeIcon
                onClick={() => handleClickZoomButtons("Plus")}
                className="mr-1 cursor-pointer"
                icon={faSearchMinus}
              />
              <FontAwesomeIcon
                onClick={() => handleClickZoomButtons("Minus")}
                className="ml-1 cursor-pointer"
                icon={faSearchPlus}
              />
              {/* <button style={{ height: 20 }}></button>
                        <button style={{ height: 20 }} ></button> */}
            </div>
          </div>
          {/* <div className="caltask">Task</div> */}
        </div>
      ) : (
        <></>
      )}
    </div>
  );

  const calendarDayStyle = () => {
    return {
      style: {
        backgroundColor: "white", //this works
        color: "green", //but why doesn't this work?
      },
    };
  };

  const getDiffInHourAndMiniutes = (start, end) => {
    var diffMs = end - start; // milliseconds between now & Christmas
    var diffDays = Math.floor(diffMs / 86400000); // days
    var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    return { hours: diffHrs, minutes: diffMins };
  };

  const getTimeEstimatedValue = (task) => {
    let timeEstimate = task.TimeEstimateValue;
    if (task.Status.name == "COMPLETED" && task.TimeTrack.length > 0) {
      timeEstimate = getDiffInHourAndMiniutes(
        new Date(task.TimeTrack[task.TimeTrack.length - 1].StartTime),
        new Date(task.TimeTrack[task.TimeTrack.length - 1].StopTime)
      );
      timeEstimate = `${timeEstimate.hours}:${timeEstimate.minutes}`;
    }
    return timeEstimate;
  };
  const getCalenderEvents = (docs) => {
    let events = [];
    // docs.forEach((task) => {
    //   let deal = allDeal.filter((deal) => deal._id == task.DealsId);
    //   if (task.Assignee.length > 0) {
    //     let timeEstimateValue = getTimeEstimatedValue(task);
    //     getEventDuration(task.Assignee, task.AssignDatetime, timeEstimateValue).forEach((event, index, self) => {
    //       let obj = {}
    //       obj = {
    //         continue: (index < (self.length - 1) ? true : false),
    //         dealId: task.DealsId,
    //         Assignee: task.Assignee,
    //         id: task._id,
    //         title: deal[0].Client + ' , ' + deal[0].ProjectNumber + " , " + task.TaskName,
    //         // allDay: true,
    //         start: new Date(event.start),
    //         end: new Date(event.end),
    //         color: task.Status.color
    //       }

    //       events.push(obj);
    //     })

    //   }

    // })
    // setCalenderEvent(events);
    return events;
  };
  const getLatestAndPreviousTaskBasedOnAssignDateTime = (
    tasks,
    selectedTask
  ) => {
    let previousTask = [];
    let lastestTask = tasks.filter((one) => {
      if (one.Assignee.length == 0) {
        return one;
      } else {
        if (
          new Date(one.AssignDatetime) > new Date(selectedTask.AssignDatetime)
        ) {
          return one;
        } else {
          previousTask.push(one);
        }
      }
    });
    return {
      prev: previousTask,
      latest: lastestTask,
      prevEvent: getCalenderEvents(previousTask),
    };
  };

  const assignTaskByOneSyncForUpdateEvent = async (
    assignee,
    docs,
    selectedTask
  ) => {
    let tasks = [...docs];
    tasks.forEach((one) => {
      one.Assignee = [];
    });
    let returnresult = "Update";
    let events = getCalenderEvents(selectedTask); //calenderEvents.filter(one=>one.Assignee.filter(a=>a.userId!==assignee.userId).length>0);
    for (let i = 0; i < tasks.length; i++) {
      let dateTime1 = checkValidateTimeEstimateForTask(
        assignee,
        { ...tasks[i] },
        events
      );
      if (dateTime1) {
        tasks[i].AssignDatetime = dateTime1;
      } else {
        returnresult = null;
        swal(
          "No available time slot till the task due date. If you want to assign increase the due date"
        );
        break;
      }

      tasks[i].Assignee.push(assignee);
      // if ((tasks[i].Status.name == 'PENDING') && (tasks[i].Assignee.length > 0)) {
      //   tasks[i].Status = { name: "NOT STARTED", color: "#f8d800" }
      // }
      //   let res = await updateTask_Single(tasks[i]);
      // let taskDocs1=await getTaskDocs1();
      let arr = getCalenderEvents(tasks);
      events = [...arr, ...events];

      // let usersData = cacheUsersArr.filter(e => e.UserId != userId);
      // // setUsersArr(usersData);
      // setAllUsers(usersData)
    }

    return returnresult;
  };
  const assignTaskByOneSync2 = async (assignee, role, docs, selectedTask) => {
    let tasks = [...docs];
    let taskObj = getLatestAndPreviousTaskBasedOnAssignDateTime(
      tasks,
      selectedTask
    );
    tasks = taskObj.latest;
    tasks = tasks.sort(
      (a, b) => new Date(a.AssignDatetime) - new Date(b.AssignDatetime)
    );
    tasks.forEach((one) => {
      one.Assignee = [];
    });
    let returnresult = "Update";
    let events = []; //calenderEvents.filter(one=>one.Assignee.filter(a=>a.userId!==assignee.userId).length>0);
    events = taskObj.prevEvent;
    for (let i = 0; i < tasks.length; i++) {
      let dateTime1 = checkValidateTimeEstimateForTask(
        assignee,
        { ...tasks[i] },
        events
      );
      if (dateTime1) {
        tasks[i].AssignDatetime = dateTime1;
      } else {
        returnresult = null;
        swal(
          "No available time slot till the task due date. If you want to assign increase the due date"
        );
        break;
      }

      tasks[i].Assignee.push(assignee);
      // if ((tasks[i].Status.name == 'PENDING') && (tasks[i].Assignee.length > 0)) {
      //   tasks[i].Status = { name: "NOT STARTED", color: "#f8d800" }
      // }
      let res = await updateTask_Single(tasks[i]);
      // let taskDocs1=await getTaskDocs1();
      let arr = getCalenderEvents(tasks);
      events = [...arr, ...events];

      // let usersData = cacheUsersArr.filter(e => e.UserId != userId);
      // // setUsersArr(usersData);
      // setAllUsers(usersData)
    }

    return returnresult;
  };

  const updateTask_Single = async (data) => {
    // let formData = new FormData();
    // formData.append("data", JSON.stringify(data));
    // let result = await HttpService.getByBoj("updatetask", formData);
    // return result;
  };

  const taskReAssign = (assignee, task) => {
    return new Promise((resolve, reject) => {
      let tasks = [...allUserTask];
      tasks = tasks.filter((t) => {
        if (t.Assignee.filter((a) => a.userId == assignee.userId).length > 0) {
          return t;
        }
      });
      //tasks.unshift(task);
      tasks.forEach((one) => {
        if (one._id == task._id) {
          one.TimeEstimateValue = task.TimeEstimateValue;
        }
      });
      assignTaskByOneSync2(assignee, "role", tasks, task)
        .then(async (result) => {
          await getallTasks();
          resolve(result);
        })
        .catch((err) => {
          reject(`${err}`);
        });
    });
  };

  // const handleSetRepeatRule = (rrule) => {
  //     let rruleStr = ''
  //     if (rrule.Repeat == "Daily") {
  //         rruleStr = `FREQ=DAILY;INTERVAL=${rrule.DailyIntervalCount};`
  //         if (rrule.DailyInterval == 'WeekDay') {
  //             rruleStr = rruleStr + `BYDAY=MO,TU,WE,TH,FR;`;
  //         } else {
  //             if (rrule.EndBy == 'ByDate') {
  //                 rruleStr = rruleStr + `UNTIL=${dayjs(new Date(rrule.EndByValue)).format('YYYYMMDDTHHMMSS')};`
  //             }
  //             if (rrule.EndBy == 'ByCount') {
  //                 rruleStr = rruleStr + `COUNT=${rrule.EndByValue};`
  //             }
  //         }

  //     }
  //     if (rrule.Repeat == "Weekly") {
  //         rruleStr = `FREQ=WEEKLY;INTERVAL=${rrule.DailyIntervalCount};`
  //         rruleStr = rruleStr + `BYDAY=${convertWeeklyDayTOShortDay(rrule.WeeklyDay)};`;
  //         if (rrule.EndBy == 'ByDate') {
  //             rruleStr = rruleStr + `UNTIL=${dayjs(new Date(rrule.EndByValue)).format('YYYYMMDDTHHMMSS')};`
  //         }
  //         if (rrule.EndBy == 'ByCount') {
  //             rruleStr = rruleStr + `COUNT=${rrule.EndByValue};`
  //         }
  //     }
  //     if (rrule.Repeat == "Monthly") {
  //         rruleStr = `FREQ=MONTHLY;INTERVAL=${rrule.DailyIntervalCount};`
  //         // rruleStr = rruleStr + `BYDAY=${convertWeeklyDayTOShortDay(rrule.WeeklyDay)}`;
  //         if (rrule.OnMonth == 'OnDay') {
  //             rruleStr = rruleStr + `BYMONTHDAY=${rrule.OnDay};`
  //         }
  //         if (rrule.OnMonth == 'OnThe') {
  //             rruleStr = rruleStr + `BYDAY=${convertWeeklyDayTOShortDay(rrule.MonthDay)};BYSETPOS=${rrule.MonthStep};`;
  //         }

  //         if (rrule.EndBy == 'ByDate') {
  //             rruleStr = rruleStr + `UNTIL=${dayjs(new Date(rrule.EndByValue)).format('YYYYMMDDTHHMMSS')};`
  //         }
  //         if (rrule.EndBy == 'ByCount') {
  //             rruleStr = rruleStr + `COUNT=${rrule.EndByValue};`
  //         }
  //     }
  //     if (rrule.Repeat == "Yearly") {
  //         rruleStr = `FREQ=YEARLY;INTERVAL=1;BYMONTH=${rrule.WhichMonth};`

  //         if (rrule.OnMonth == 'OnDay') {
  //             rruleStr = rruleStr + `BYMONTHDAY=${rrule.OnDay};`
  //         }
  //         if (rrule.OnMonth == 'OnThe') {
  //             rruleStr = rruleStr + `BYDAY=${convertWeeklyDayTOShortDay(rrule.MonthDay)};BYSETPOS=${rrule.MonthStep};`;
  //         }

  //         if (rrule.EndBy == 'ByDate') {
  //             rruleStr = rruleStr + `UNTIL=${dayjs(new Date(rrule.EndByValue)).format('YYYYMMDDTHHMMSS')};`
  //         }
  //         if (rrule.EndBy == 'ByCount') {
  //             rruleStr = rruleStr + `COUNT=${rrule.EndByValue};`
  //         }
  //     }
  //     onSaveRRRuleReminder(rruleStr)
  //         .then((result) => {
  //             console.log(result);
  //         })
  //         .catch((err) => {
  //             console.log(err);
  //         })
  // }

  const moveEvent = ({ event, start, end }) => {
    if (isAccess && isAccess(selectedRole, "Employeee Calender", "Write")) {
      let tasks = [...allUserTask];
      tasks = tasks.filter((task) => task._id == event.id);
      if (tasks.length > 0) {
        tasks = tasks[0];
        tasks.AssignDatetime = start;
        // updateTask(tasks);
        // taskReAssign(tasks.Assignee[0], tasks)
      }
    } else {
      swal("you don't have sufficient privillages to perform this task!", {
        dangerMode: true,
      });
    }

    // const { events } = this.state;

    // const idx = events.indexOf(event);
    // const updatedEvent = { ...event, start, end };

    // const nextEvents = [...events];
    // nextEvents.splice(idx, 1, updatedEvent);

    // this.setState({
    //   events: nextEvents
    // });
  };

  const getEstimateDuration = (startDate, timeEstimate) => {
    let obj = { start: new Date(startDate), end: new Date(startDate) };
    //obj.start.setMilliseconds(obj.start.getMilliseconds()+1);
    timeEstimate = timeEstimate.split(":");
    obj.end.setHours(obj.end.getHours() + parseInt(timeEstimate[0]));
    obj.end.setMinutes(obj.end.getMinutes() + parseInt(timeEstimate[1]));
    return obj;
  };

  const getUserDayShift = (date, user) => {
    // date = new Date(date);
    // let workingDays = user.WorkingDays;
    // let dayShift = workingDays.filter((one) => one.Day == days[date.getDay()])
    // if (dayShift.length > 0) {
    //   return dayShift[0];
    // } else {
    //   return null
    // }
  };

  const checkValidateTimeEstimateForTask = (assignee, task, events) => {};

  const content = (
    // <div>
    //   <p>Content</p>
    //   <p>Content</p>
    //   <button onClick={() => alert('alert')}></button>
    // </div>
    <>hjdjkasdhsashdjashdkjsahdkjahsd</>
  );

  const filterColumns = [
    { name: "Title", type: "string" },
    { name: "Description", type: "string" },
    // { name: 'Project Address', type: 'string' },
    { name: "AllDay", type: "string" },
    { name: "Date of Event", type: "string" },
    { name: "From Date", type: "string" },
    { name: "To Date", type: "string" },
    // { name: "Bin Number", type: 'string' },
    // { name: "Lot Number", type: 'string' },
    // { name: "Block Number", type: 'string' },
    { name: "Organizer", type: "date" },
    { name: "Location of Event", type: "string" },
  ];

  function modifyEvents(data) {
    let arr = [];
    return new Promise((resolve, reject) => {
      data?.forEach((obj1) => {
        if (obj1.color == "#F26F56") {
          let obj = {
            color: obj1.color,
            dateandtime: obj1?.dateandtime,
            uid: obj1?.uid,
            start: reminderEventBackToOriginalTime(obj1?.dateandtime?.start),
            end: convertDateTime(obj1?.dateandtime?.end),
            title: obj1?.title,
          };
          arr.push(obj);
        } else {
          let obj = {
            color: obj1?.color,
            dateandtime: obj1?.dateandtime,
            uid: obj1?.uid,
            start: convertDateTime(obj1?.dateandtime?.start),
            end: convertDateTime(obj1?.dateandtime?.end),
            title: obj1?.title,
          };
          arr.push(obj);
        }
      });
      resolve(arr);
    });
  }
  useEffect(()=>{
    applyFilterOnCalendar({...calFilterOptions},'true')
  },[originalEvents]) 
  async function applyFilterOnCalendar(filters,keyy) {
    let allData = [...originalEvents];
    let obj = {};
    let filterState = false;
    Object.keys(filters).map((f) => {
      if (filters[f]?.checked && filters[f]?.label == "All Day") {
        allData = allData.filter((k) => k.isallday);
        filterState = true;
      }
      if (filters[f]?.checked && filters[f]?.label == "Recurring") {
        allData = allData.filter((k) => {
          if (k.recurrenceid) {
            return k;
          }
        });
        filterState = true;
      }
      if (filters[f]?.checked && filters[f]?.label == "Meetings") {
        allData = allData.filter((k) => {
          if (k.conference_data) {
            return k;
          }
        });
        filterState = true;
      }
      if (filters[f]?.checked && filters[f]?.label == "Task") {
        allData = allData.filter((k) => {
          if (k?.title?.includes("Task :") || k?.title?.includes("Task:")) {
            return k;
          }
        });
        filterState = true;
      }
      if (
        filters[f]?.checked &&
        filters[f]?.label == "Attendees" &&
        filters[f].email
      ) {
        allData = allData.filter((k) => {
          if (
            !filters[f]?.email.find((aa) =>
              k?.attendees?.find((kk) => aa.value != kk.email)
            )
          ) {
            return k;
          }
        });
        filterState = true;
      }
    });
    await modifyEvents(allData)
      .then((res) => {
        setFilteredCalenderEvents({ status: filterState, data: res });
        if(keyy=='true'){
        }else{
          setFilterPopup(!filterPopup);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }
  const getFilterSection = (
    <Col className="Calendar-Filter-Wrapper">
      <Row className="Calendar-Filter-Header">Filter</Row>
      {calFilterOptions &&
        Object.keys(calFilterOptions)?.map((key) => {
          return (
            <Row className="Calendar-Filter-Card">
              <Col className="column" sm={2}>
                <Input
                  type="checkbox"
                  className="Check-Box"
                  checked={calFilterOptions[key]?.checked}
                  onChange={(e) => {
                    let obj = { ...calFilterOptions };
                    obj[key].checked = e.currentTarget.checked;
                    setCalFilterOptions(obj);
                  }}
                />
              </Col>
              {/* <Col sm={1}></Col> */}
              <Col className="column" sm={7}>
                {key}
              </Col>
              {key == "Attendees" && (
                <Row className="">
                  <Col className="column" sm={2}></Col>
                  {/* <Col sm={1}></Col> */}
                  <Col className="column" sm={10}>
                    {/* <Input type="text" style={{
                            "padding": "2px 5px",
                            "height": "auto"
                        }}
                            value={calFilterOptions["Attendees"]?.email}
                            onChange={e => {
                                let obj = { ...calFilterOptions };
                                obj["Attendees"].email = e.currentTarget.value;
                                setCalFilterOptions(obj);
                            }}
                            disabled={!calFilterOptions["Attendees"].checked} /> */}
                    <CreatableSelect
                      options={[]}
                      isDisabled={!calFilterOptions["Attendees"].checked}
                      components={components}
                      inputValue={inputValue}
                      isClearable={false}
                      isMulti={true}
                      onInputChange={(newValue) => setInputValue(newValue)}
                      onKeyDown={handleKeyDownForFilter}
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      onChange={(values) => {
                        //setSelectedEmailIds(values)
                        ;
                        let obj = { ...calFilterOptions };
                        obj["Attendees"].email = values;
                        setCalFilterOptions(obj);
                      }}
                      value={calFilterOptions["Attendees"].email}
                      menuPlacement="auto"
                      menuIsOpen={false}
                      styles={participantStylesForFilter}
                    />
                  </Col>
                </Row>
              )}
            </Row>
          );
        })}
      <Row className="" style={{ marginTop: "10px" }}>
        <Col className="column" sm={6}>
          <Button
            style={{ width: "98%" }}
            onClick={(e) => {
              setCalFilterOptions({ ...filtersForCalenderEvents });
              setFilteredCalenderEvents({
                status: false,
                data: calenderEvents,
              });
              setFilterPopup(!filterPopup);
            }}
          >
            Reset
          </Button>
        </Col>
        <Col className="column" sm={6}>
          <Button
            style={{ width: "98%" }}
            className="btn-primary"
            onClick={(e) => applyFilterOnCalendar({ ...calFilterOptions })}
          >
            Apply
          </Button>
        </Col>
      </Row>
    </Col>
  );

  const getTaskFilter = () => {
    return (
      (!filteredCalenderEvents.status || filteredCalenderEvents.status) && (
        <AntdPopover
          //   open={true}
          overlayClassName={`Calendar-Filter Do-Not-Close`}
          style={{ zIndex: 9999, width: "max-content" }}
          trigger="click"
          open={filterPopup}
          onOpenChange={(e) => {
            setFilterPopup(!filterPopup);
          }}
          placement="bottom"
          content={getFilterSection}
          arrow={true}
        >
          <span className="Filter-Calender" style={{ position: "relative" }}>
            <FontAwesomeIcon color="Blue" icon={faFilter} />
            {filteredCalenderEvents.status && (
              <span
                className="Cancel-Icon"
                onClick={(e) => {
                  //e.stopPropagation();
                  e.preventDefault();
                  setCalFilterOptions({ ...filtersForCalenderEvents });
                  setFilteredCalenderEvents({
                    status: false,
                    data: calenderEvents,
                  });
                  setFilterPopup(!filterPopup);
                }}
              >
                <FontAwesomeIcon icon={faTimes} />
              </span>
            )}
          </span>
        </AntdPopover>
      )
    );
  };

  const customWeekStartEndFormat = (value) =>
    `${dayjs(value).startOf("week").format("DD MMMM")} - ${dayjs(value)
      .endOf("week")
      .format("DD MMMM YYYY")}`;

  // const getSearchContent = (search) => {
  //   if (search !== "") {
  //     return (
  //       <Col sm={12} className="settings-search-card search-card-list">
  //         <Card className="settings-dropdown">
  //           <CardHeader
  //             style={{ position: "sticky", top: "0px" }}
  //             className=" p-0 bg-white d-flex justify-content-between align-items-center"
  //           >
  //             <span className="search-card-header">Search result from calendar</span>
  //             <FontAwesomeIcon
  //               style={{ marginRight: "18px" }}
  //               onClick={(e) => {
  //                 setSearchEventText("");
  //               }}
  //               color="#747474"
  //               icon={faTimes}
  //             />
  //           </CardHeader>
  //           <CardBody className="">
  //             {/* {searchEvetns.map(one => { */}
  //             <table class="table">
  //               <thead>
  //                   <tr style={{ background: "#4b9de3", color: "#fff" }}>
  //                   <th scope="col" className="serach-tbl-header">Title</th>
  //                   <th scope="col" className="serach-tbl-header">Start Date Time</th>
  //                   <th scope="col" className="serach-tbl-header">End Date Time</th>
  //                   {/* <th scope="col">Is Allday</th>
  //                                       <th scope="col"></th>
  //                                       <th scope="col"></th> */}
  //                 </tr>
  //               </thead>
  //               {searchEvetns.map((one) => {
  //                 return (
  //                   <tbody>
  //                     <tr>
  //                       <th
  //                         // style={{ width: "50%" , cursor: "pointer"}}
  //                         className="serach-tbl-title"
  //                         onClick={(e) => {
  //                           openSerachResult(e, one);
  //                         }}
  //                       >
  //                         {one.title}
  //                       </th>
  //                       <td style={{ width: "25%" }}>
  //                         {moment(one.dateandtime.start).format(
  //                           "DD MMM YYYY HH:mm a"
  //                         )}
  //                       </td>
  //                       <td style={{ width: "25%" }}>
  //                         {moment(one.dateandtime.end).format(
  //                           "DD MMM YYYY HH:mm a"
  //                         )}
  //                       </td>
  //                       {/* <td style={{width : "10%"}}><input type="checkbox" checked={one.isallday} /></td>
  //                                       <td style={{width : "5%"}}><FontAwesomeIcon color="gray" icon={faTrash} /></td>
  //                                       <td style={{width : "5%"}}><FontAwesomeIcon color="gray" icon={faPen} /></td> */}
  //                     </tr>
  //                   </tbody>
  //                 );
  //               })}
  //             </table>
  //           </CardBody>
  //         </Card>
  //       </Col>
  //     );
  //   } else {
  //     return <></>;
  //   }
  // };

  const getCustumHeader = () => {
    return (
      <FormGroup
        className="pad-top-5 py-1 pb-0 header-wrapper"
        style={{ marginBottom: "0px" }}
      >
        <Row className="row-mar-0">
          <Col sm={8} className="pad-r0 d-flex align-items-center  pad-l20">
            
            <div
              className="d-flex mt-1 align-items-center"
              style={{ marginBottom: "0.25rem" }}
            >
              <span className="today-button" onClick={() => goToDate("Today")}>
                {calendreView?.value == "month"
                  ? "This month"
                  : calendreView?.value == "week"
                  ? "This week"
                  : calendreView?.value == "day"
                  ? "Today"
                  : ""}
              </span>
              <div className="mx-2">
                <span
                  className="mr-2 backward-btn"
                  onClick={() => goToDate("Back")}
                >
                  <FontAwesomeIcon icon={faChevronLeft} />
                </span>
                <span
                  className="ml-2 forward-btn"
                  onClick={() => goToDate("Next")}
                >
                  <FontAwesomeIcon icon={faChevronRight} />
                </span>
              </div>
              <span>
                <Space
                  className="mr-4 cursor-pointer"
                  direction="vertical"
                  size={12}
                >
                  {calendreView?.value == "day" ? (
                    <DatePicker
                      value={dayjs(deafulCalenderViewDate)}
                      className="calender-date-picker"
                      format={dateFormat}
                      style={{ width: 175, height: 31, cursor: "pointer" }}
                      onChange={(date, dateString) => {
                        if (date) {
                          setDefaultCalenderViewDate(date.$d);
                          setCalenderView({ label: "Day", value: "day" });
                        }
                      }}
                    />
                  ) : (
                    <DatePicker
                      picker={calendreView?.value}
                      value={
                        calendreView?.value == "week"
                          ? dayjs(deafulCalenderViewDate)
                          : dayjs(deafulCalenderViewDate)
                      }
                      className="calender-date-picker"
                      format={
                        calendreView?.value == "month"
                          ? monthFormat
                          : calendreView?.value == "week"
                          ? customWeekStartEndFormat
                          : ""
                      }
                      style={{ cursor: "pointer", width: 170, height: 31 }}
                      onChange={(date, dateString) => {
                        if (date) {
                          setDefaultCalenderViewDate(date.$d);
                          // setCalenderView('day');
                        }
                      }}
                    />
                  )}
                </Space>
              </span>
            </div>
          </Col>
          <Col
            sm={4}
            className="d-flex justify-content-end align-items-center pr-lg-3"
          >
            <div className="row w-100  calendar-feature-header">
              <div className="col p-0 d-flex align-content-center">
                <div className="create-dealbox2 mt-0 d-flex align-items-center">
                  <img
                    className={refreshEnable ? "rotate-refresh-icon" : ""}
                    onClick={() => setRefreshEnable(true)}
                    style={{
                      width: "25px",
                      marginTop: "5px",
                      cursor: "pointer",
                    }}
                    src={refreshIcon}
                  />
                </div>
                {getTaskFilter()}
               
                <Select
                  className="pr-1"
                  options={CalendarOptions}
                  closeMenuOnSelect={true}
                  onChange={(values) => goToView(values)}
                  value={
                    calendreView
                      ? {
                          label: calendreView?.label,
                          value: calendreView?.value,
                        }
                      : ""
                  }
                  menuPlacement="auto"
                  styles={customstyle}
                />
              </div>

              <div className="col pl-0 position-relative">
                <EventSearch allEventsForSearch={allEventsForSearch} setAllEventsForSearch={setAllEventsForSearch} openSerachResult={openSerachResult} events={searchEvetns} serchInput={searchEventText}>
                  <Search
                    style={{ padding: "5px 0px 5px 30px", fontSize: "14px" }}
                    placeholder="Search events..."
                    type="text"
                    loading={eventSearchLoading}
                    // addonBefore={<FontAwesomeIcon
                    //   icon={faSearch}
                    // />}
                    className="dropdown-text mr-2 calender-dropdown-height"
                    name="SearchEvent"
                    id="SearchEvent"
                    value={searchEventText}
                    onChange={async (e) => {
                      let text = e.target.value;
                      if (text && allEventsForSearch) {
                        setEventSearchLoading(true);
                        setSearchEventText(text);
                        // try {
                        //   setSearchEventText(text);
                        //   let res = { ...myCalendar };
                        //   let events = await CalendarAPI.GetSearchEvents(
                        //     res.uid,
                        //     text,
                        //     getDateRange()
                        //   );
                        //   setSearchEvents(events);
                        //   setEventSearchLoading(false);
                        // } catch (err) {
                        //   setEventSearchLoading(false);
                        //   console.log(err);
                        // }
                        let filteredEvents=allEventsForSearch?.filter(ee=>{
                          if(ee?.title?.toLowerCase()?.includes(text.toLowerCase())){
                            return ee;
                          }
                        })
                        setSearchEvents(filteredEvents);
                        setEventSearchLoading(false);
                      } else {
                        setSearchEventText("");
                        setEventSearchLoading(false);
                        setSearchEvents([]);
                      }

                      // goToView(e.currentTarget.value);
                    }}
                  />
                  
                </EventSearch>
                
              </div>
            </div>
          </Col>
        </Row>
      </FormGroup>
    );
  };

  const getToolBox = () => {
    return (
      <ToolBox
        module={"Event"}
        // selectedMails={selectedMails}
        setOpen={(status) => {
          setIsOpen(status);
        }}
        isOpen={isOpen}
        position={toolboxposition}
        content={sidebarEmailContext}
      />
    );
  };
  const getToolBoxForAttachment = () => {
    return (
      <ToolBox
        module={"Attachments"}
        setOpen={(status) => {
          setIsOpenFlag(status);
        }}
        isOpen={isOpenFlag}
        position={toolboxposition}
        content={sidebarEmailContext}
      />
    )
  }
  return (
    <div>
      {loading ? <Loader /> : <></>}
      {getCustumHeader()}
      {getToolBox()}
      {getToolBoxForAttachment()}
      {/* {getSearchContent(searchEventText)} */}
      {AttachmentPopUp()}
      {getPDFPrevModal()}

      <div className="row m-0">
        <div className="col-12" style={{ padding: "0px" }}>
          <div className="empCalendarWrapper">
            <MyCalendar />
            {eventEntryPopUp()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FullCalendar;
