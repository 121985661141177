import React, { useState, useEffect, useRef } from "react";
import { useDropzone } from "react-dropzone";
import { useHistory, useLocation } from "react-router-dom";
import { DateRangePicker, Calendar } from "react-date-range";
import { addDays } from "date-fns";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import TimePicker from "react-time-picker/dist/entry.nostyle";
import _, { now, reject, result } from 'lodash';
import { CirclePicker } from 'react-color';
import attachIcon from "../../assets/images/task-attachment-icon.png"
import Vector from "../../assets/images/vector.png";
import minusCircleV from '../../assets/images/svg/minus-circle-v.svg'
import minusCircle from '../../assets/images/svg/minus-circle.svg'
import Select from 'react-select';
import makeAnimated from "react-select/animated";
import { components } from "react-select";
import { Popover as AntdPopover, Switch } from 'antd';
import CustomDropdown from '../../common/custom_dropdown/CustomDropdown'
import 'antd/dist/reset.css';
import { v4 as uuidv4 } from "uuid";
import Config from '../../config.json'
import {PlusSquareOutlined} from '@ant-design/icons'

// import "./TimePicker.css";
// import '../jobbody.css';
import {
  Col,
  Container,
  Row,
  Button,
  Form,
  ModalFooter,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  UncontrolledDropdown,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Popover,
  PopoverBody,
  PopoverHeader,
  UncontrolledPopover,
  UncontrolledTooltip,
  UncontrolledCollapse,
  Tooltip,
  Card, CardTitle, CardText,
  Collapse
} from "reactstrap";
import { Tabs, Tab, ProgressBar, DropdownButton, Navbar, Nav } from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "ckeditor5-build-all";
import swal from 'sweetalert'
// import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFlag,
  faHourglass,
  faClock,
  faStopCircle,
  faPlayCircle,
  faEdit,
  faTrashAlt,
  faEye,
  faCalendar,
  faCalendarCheck,
  faCaretSquareDown,
  faCheckSquare,
  faFile,
  faDotCircle,
  faCircle,
} from "@fortawesome/free-regular-svg-icons";

import { faExclamationTriangle, faFlag as faFlagSolid, faUpload, faSquare, faAngleRight, faCodeBranch, faCalendarTimes, faMagic, faCogs, faGripVertical, faFilter, faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import pin from "../../assets/images/svg/redpin.png";
import assigntask from "../../assets/images/svg/assigntask.svg";
import avtar from "../../assets/images/avtar.jpg";
import duedate from "../../assets/images/svg/duedate.svg";
import tag from "../../assets/images/svg/tag.svg";
import priority from "../../assets/images/svg/priority.svg";
import estimate from "../../assets/images/svg/estimate.svg";
import filter from "../../assets/images/filter.png";
import {
  faSearch,
  faCaretDown,
  faCaretRight,
  faEllipsisH,
  faPen,
  faCog,
  faChevronDown,
  faChevronRight,
  faFlag as solidFlag,
  faTimes,
  faStop,
  faAlignLeft,
  faTrash,
  faSlidersH,
  faMinusCircle,
  faPlusCircle,
  faHashtag,
  faTags,
  faItalic,
  faTextHeight,
  faCaretUp,
} from "@fortawesome/free-solid-svg-icons";
import HttpService from "../../services/http.service";
import "../../tabs/Task/task.css";
// import { v4 as uuidv4 } from "uuid";
// import { uuid } from "uuidv4";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { NavigateBeforeSharp, SignalCellularNullOutlined } from "@material-ui/icons";
// import TaskModal from "./taskModal";
import Loader from "../../common/loader";
// import EmployeeCalender from "../../hr/employeeCalender/employeeCalender";
import dateTime, { getDiffInHourAndMiniutes } from '../../common/DateTime';
import TaskService from "../../common/util/TaskService";

import dayjs from "dayjs";
import UserService from "../../common/util/UserService";
import AddColumn from "../../common/AddColumn/add_column";
import StatusDropdown from "../../common/StatusDropdown/StatusDropdown";
import Task_Filters from "../../common/task_filters/task_filter";
import ProjectService from "../../common/util/ProjectService";
import validation from "../../common/util/validation";
import ModuleService from "../../common/util/ModuleService";
import axios from "axios";
// import { a } from "react-dom-factories";
// import { isTaskAuthorize } from "../../../../../common/utill/taskAuthorize";
// import AutomateModal from "../../../../../common/automation/automateModal";

let usersDetail = [];
let selectedTask = {};
let selectedSubTask = {};
let selectedMeataTask = {};
let timeTrackInitialTime = new Date();

let NOT_DEFINED_VALUE = null;
let taskArr = [];
String.prototype.toHHMMSS = function () {
  var sec_num = parseInt(this, 10); // don't forget the second param
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
  var seconds = sec_num - (hours * 3600) - (minutes * 60);

  if (hours < 10) { hours = "0" + hours; }
  if (minutes < 10) { minutes = "0" + minutes; }
  if (seconds < 10) { seconds = "0" + seconds; }
  return hours + ':' + minutes + ':' + seconds;
}

const TaskList = (props) => {
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const dropdownRef = useRef();
  const history = props.history;

  const [deleteWarnObj, setDeleteWarnObj] = useState({ id: 'assignee', header: 'Assignee Delete Warning', text: 'Are you sure? You want to remove Assignee.' })
  const [clockisActive, setClockIsActive] = useState(false);
  const [superviserOpenFlag, setSuperviserOpenFlag] = useState(false);
  const [assigneeOpenFlag, setAssigneeOpenFlag] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showDependencyPopUp, setShowDependencyPopUp] = useState(false);
  const [showDependencyList, setShowDependencyList] = useState({});
  const [SearchThisTask, setSearchThisTask] = useState(props.searchTask);
  const [showAutomate, setShowAutomate] = useState(false);
  const [allAutomations, setAllAutomations] = useState([])

  const [subtaskTooltipOpen, setSubtaskTooltipOpen] = useState(false);
  const [renameTooltip, setRenameTooltip] = useState(false);
  const [editableInputOpen, setEditableInputOpen] = useState(false);

  const [rename2Tooltip, setRename2Tooltip] = useState(false);

  const [rename3Tooltip, setRename3Tooltip] = useState(false);

  const [subtask2TooltipOpen, setSubtask2TooltipOpen] = useState(false);

  const [subtask3TooltipOpen, setSubtask3TooltipOpen] = useState(false);
  const [allMyTasks, setAllMyTasks] = useState([]);
  const [allTasks, setAllTasks] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [groupByField, setGroupByField] = useState([]);
  const [expandAllTaskState, setExpandAllTaskState] = useState(false);
  const [expandDealTask, setExpandDealTask] = useState("");
  const [selectedTaskId, setSelectedTaskId] = useState({});
  const [selectedSubTaskId, setselectedSubTaskId] = useState({});
  const [newTask, seNewTaskState] = useState(true);
  const [assigneDropState, setAssigneDropState] = useState([]);
  const [subtaskAssignee, setSubtaskAssignee] = useState([]);
  const [subTasks, setSubTasks] = useState([]);
  const [subTaskTimeHr, setSubTaskTimeHr] = useState("");
  const [dealObj, setDealObj] = useState({});

  const [allJobs, setAllJobs] = useState([]);

  const [DealsId, setDealsId] = useState("");
  const [openAddColumnPopUpDealId, setOpenAddColumnPopUpDealId] = useState("");

  const [projectId, setProjectId] = useState(null);
  const [loader, setLoader] = useState(false);


  const [allCategory, setAllCategory] = useState([]);

  const [taskFilterSetting, setTaskFilterSetting] = useState({});
 
  const [expandTaskGroup, setExpandTaskGroup] = useState('');

  const [dependencyList, setDependencyList] = useState([])
  const [allTaskCache, setAllTaskCache] = useState([])
  const [taskSearchValue, setTaskSearchValue] = useState("");


  useEffect(() => {
    if ((allTaskCache.length > 0)) {
      let all = [...allTaskCache];
      if (taskSearchValue) {
        all = all.filter((one) => one.name.toLowerCase().includes(taskSearchValue.toLowerCase()))
        setAllTasks(all);
      } else {
        setAllTasks(all);
      }
    }


  }, [taskSearchValue])




  useEffect(() => {
    if (props.id == 'task') {
      getTaskDependencyData()
    } else {
      if (props.dependencyList) {
        setDependencyList(props.dependencyList);
      }
    }
  }, [props.id, props.dependencyList])

  const getTaskDependencyData = () => {
    ModuleService.GetModuleListData("Task_Dependency")
      .then((result) => {
        setDependencyList(result)
      })
      .catch((err) => {
        console.log(err);
      })
  }

  useEffect(() => {
    if (projectId) {
      getTaskLayout(projectId)
    }
  }, [projectId])

  
 



  const [filterItems, setFilterItems] = useState(
    [{ name: 'Status', type: 'string' },
    { name: 'Task Name', type: 'string' },
    { name: 'Pipeline', type: 'string' },
    { name: 'Category', type: 'string' },
    { name: "Due Date", type: 'date' }, { name: "Priority", 'type': 'string' },
    { name: "Assignee", type: 'string' },
    { name: "Supervisor", type: 'string' },
    { name: "Created By", type: 'string' },
    { name: 'Start Date', type: 'date' },
    { name: 'Milestone', type: 'string' }])

 




 


  

  

  const [editNameTask, setEditNameTask] = useState(null);

  const editableField = (task) => {
    setEditableInputOpen(!editableInputOpen);
    setEditNameTask(task);
  }

  const handleTaskNameChange = (e) => {
    const taskData = { ...editNameTask };
    //let task = taskData.filter(one=>one._id == e.currentTarget.id)[0];
    // selectedTask[e.currentTarget.name]= e.currentTarget.value;
    taskData.TaskName = e.currentTarget.value;

    setEditNameTask(taskData)
    //setAllTasks((current)=>[...taskData, [...taskData, { TaskName: e.target.value }]])

  }





  useEffect(() => {
    if (props.dealObj) {
      if (props.dealObj.Project_ID) {
        setProjectId(props.dealObj.Project_ID);
      
      }
    }
  }, [props.dealObj])



  const getJobsByProjectId = (pid) => {
    TaskService.GetJobsByProjectId(pid)
      .then((result) => {
        if (result) {
          setAllJobs(result)
        } else {
          setAllJobs([])
        }

      })
      .catch((err) => {
        console.log(err);
      })
  }

  useEffect(() => {
    if (props.allJobs) {
      if (props.id == 'tasklist') {
        setAllJobs(props.allJobs)
      }
    }
  }, [props.allJobs])

  useEffect(() => {
    
    if ((allJobs.length > 0) && (props.id == 'tasklist')) {
      (async () => {
        // setLoader(true);
        // for (let i = 0; i < props.allTasks.length; i++) {
        //   // let timeTracksObj = await getAllTimeTrackLogs(getJobId(props.allTasks[i].id_string));
        //   // let milestone = await getTaskMilestones(props.allTasks[i].milestone_id);
        //   props.allTasks[i].TimeTrack = { TotalTime: 0 }//timeTracksObj//timeTracksObj;
        //   props.allTasks[i].Milestone = {}//milestone//milestone;
        //   props.allTasks[i].JobId = getJobId(props.allTasks[i].id_string);
        // }
        setAllTasks(props.allTasks);
        setAllTaskCache(props.allTasks);
        // setLoader(false);
      })()
    }
  }, [allJobs, props.allTasks])



 

 





  




  useEffect(() => {
    if ((allTasks.length > 0) && (props.id == 'tasklist')) {
      setAllUsers(props.allUsers)
      setGroupBy(props.groupBy);
      setAllMyTasks(props.myTasks);
      if (props.groupBy == 'Pipeline') {
        TaskIcons(allTasks);
        getGroupByFields(allTasks, props.groupBy);
      } else {
        setAllTasks(props.myTasks)
        TaskIcons(props.myTasks);
        getGroupByFields(props.myTasks, props.groupBy);
      }
    }
  }, [allTasks, props.groupBy])


  const getJobId = (taskId) => {
    let jobs = [...allJobs];
    let filterJob = jobs.filter(one => one.zProjectsJobRefId == taskId);
    if (filterJob.length > 0) {
      return filterJob[0].jobId
    } else {
      return '';
    }
  }

 
  let taskSubChildArray = [];

  const handleFindChildTask = (parentTasksList, projectId, taskId, parentId, parentTask) => {
    const myTaskList = [...allMyTasks]
    let result = myTaskList.filter(task => task.project && (task.project.id_string == projectId) && (task.parent_task_id == taskId))
    const tasks = [...allTasks]
    if (result.length > 0) {
      result.forEach((one) => {
        taskSubChildArray.push(one);
        handleFindChildTask(tasks, projectId, one.id_string, one.parent_task_id, parentTask);

      })
    }
  }

  const handleCollapseAndExapndTask = (parentTasksList, projectId, taskId, parentId, parentTask) => {
    const myTaskList = [...allMyTasks]
    let result = myTaskList.filter(task => task.project && (task.project.id_string == projectId) && (task.parent_task_id == taskId))
    for (let i = 0; i < result.length; i++) {
      let timeTracksObj = { TotalTime: 0 }//await getAllTimeTrackLogs(getJobId(result[i].id_string));
      let milestone = {}//await getTaskMilestones(result[i].milestone_id);
      result[i].Milestone = milestone;
      result[i].TimeTrack = timeTracksObj;
      result[i].JobId = getJobId(result[i].id_string);
      if (parentTask.custom_fields && parentTask.custom_fields.filter((one) => one.label_name == 'Pipeline').length > 0) {
        result[i].custom_fields = parentTask.custom_fields.filter((one) => one.label_name == 'Pipeline')
      }

    }
    const tasks = [...parentTasksList];
    if (parentId) {
      tasks.forEach((one) => {
        if (one.id_string == taskId) {
          one.Subtask = result
        } else {
          if (one.Subtask) {
            one.Subtask.forEach((two) => {
              if (two.id_string == taskId) {
                two.Subtask = result
              } else {
                if (two.Subtask) {
                  two.Subtask.forEach((three) => {
                    if (three.id_string == taskId) {
                      three.Subtask = result
                    } else {
                      if (three.Subtask) {
                        three.Subtask.forEach((four) => {
                          if (four.id_string == taskId) {
                            four.Subtask = result
                          } else {
                            if (four.Subtask) {
                              four.Subtask.forEach((five) => {
                                if (five.id_string == taskId) {
                                  five.Subtask = result
                                } else {
                                  if (five.Subtask) {
                                    five.Subtask.forEach((six) => {
                                      if (six.id_string == taskId) {
                                        six.Subtask = result
                                      } else {
                                        if (six.Subtask) {
                                          six.Subtask.forEach((seventh) => {
                                            if (seventh.id_string == taskId) {
                                              seventh.Subtask = result;
                                            } else {
                                              if (seventh.Subtask) {
                                                seventh.Subtask.forEach((two) => {

                                                })
                                              }
                                            }
                                          })
                                        }
                                      }
                                    })
                                  }
                                }
                              })
                            }
                          }
                        })
                      }
                    }
                  })
                }
              }
            })
          }
        }
      })
    } else {
      tasks.forEach((one) => {
        if (one.id_string == taskId) {
          one.Subtask = result;
        }
      })
    }
    if (result.length > 0) {
      result.forEach((one) => {
        handleCollapseAndExapndTask(tasks, projectId, one.id_string, one.parent_task_id, parentTask)
      })
    } else {
      setAllTaskCache(tasks);
      setAllTasks(tasks);
    }
  }

  const handleClickOpenSubtask = (projectId, taskId, parentId, parentTask) => {
    TaskService.GetAllSubTaskOfTask(projectId, taskId)
      .then(async (result) => {
        for (let i = 0; i < result.length; i++) {
          let timeTracksObj = await getAllTimeTrackLogs(getJobId(result[i].id_string));
          // let milestone = await getTaskMilestones(result[i].milestone_id);
          // result[i].Milestone = milestone;
          result[i].TimeTrack = timeTracksObj;
          result[i].JobId = getJobId(result[i].id_string);
          if (parentTask.custom_fields && parentTask.custom_fields.filter((one) => one.label_name == 'Pipeline').length > 0) {
            result[i].custom_fields = parentTask.custom_fields.filter((one) => one.label_name == 'Pipeline')
          }

        }
        const tasks = [...allTasks];
        if (parentId) {
          tasks.forEach((one) => {
            if (one.id_string == taskId) {
              one.Subtask = result
            } else {
              if (one.Subtask) {
                one.Subtask.forEach((two) => {
                  if (two.id_string == taskId) {
                    two.Subtask = result
                  } else {
                    if (two.Subtask) {
                      two.Subtask.forEach((three) => {
                        if (three.id_string == taskId) {
                          three.Subtask = result
                        } else {
                          if (three.Subtask) {
                            three.Subtask.forEach((four) => {
                              if (four.id_string == taskId) {
                                four.Subtask = result
                              } else {
                                if (four.Subtask) {
                                  four.Subtask.forEach((five) => {
                                    if (five.id_string == taskId) {
                                      five.Subtask = result
                                    } else {
                                      if (five.Subtask) {
                                        five.Subtask.forEach((six) => {
                                          if (six.id_string == taskId) {
                                            six.Subtask = result
                                          } else {
                                            if (six.Subtask) {
                                              six.Subtask.forEach((seventh) => {
                                                if (seventh.id_string == taskId) {
                                                  seventh.Subtask = result;
                                                } else {
                                                  if (seventh.Subtask) {
                                                    seventh.Subtask.forEach((two) => {

                                                    })
                                                  }
                                                }
                                              })
                                            }
                                          }
                                        })
                                      }
                                    }
                                  })
                                }
                              }
                            })
                          }
                        }
                      })
                    }
                  }
                })
              }
            }
          })
        } else {
          tasks.forEach((one) => {
            if (one.id_string == taskId) {
              one.Subtask = result;
            }
          })
        }

        setAllTasks(tasks);
        setAllTaskCache(tasks);
     
      })
      .catch((err) => {
        console.log(err);
      })
  }

  const setTaskAfterUpdate = (taskId, data) => {
    const tasks = [...allTasks]

    tasks.forEach((one, oneIdx) => {
      if (one.id_string == taskId) {
        data.Subtask = one.Subtask;
        tasks[oneIdx] = data
      } else {
        if (one.Subtask) {
          one.Subtask.forEach((two, twoIdx) => {
            if (two.id_string == taskId) {
              data.Subtask = two.Subtask;
              one.Subtask[twoIdx] = data
            } else {
              if (two.Subtask) {
                two.Subtask.forEach((three, threeIdx) => {
                  if (three.id_string == taskId) {
                    data.Subtask = three.Subtask;
                    two.Subtask[threeIdx] = data
                  } else {
                    if (three.Subtask) {
                      three.Subtask.forEach((four, fourIdx) => {
                        if (four.id_string == taskId) {
                          data.Subtask = four.Subtask;
                          three.Subtask[fourIdx] = data
                        } else {
                          if (four.Subtask) {
                            four.Subtask.forEach((five, fiveIdx) => {
                              if (five.id_string == taskId) {
                                data.Subtask = five.Subtask;
                                four.Subtask[fiveIdx] = data
                              } else {
                                if (five.Subtask) {
                                  five.Subtask.forEach((six, sixIdx) => {
                                    if (six.id_string == taskId) {
                                      data.Subtask = six.Subtask;
                                      five.Subtask[sixIdx] = data
                                    } else {
                                      if (six.Subtask) {
                                        six.Subtask.forEach((seventh, seventhIdx) => {
                                          if (seventh.id_string == taskId) {
                                            data.Subtask = seventh.Subtask;
                                            six.Subtask[seventhIdx] = data
                                          } else {
                                            if (seventh.Subtask) {
                                              seventh.Subtask.forEach((eighth, eighthIdx) => {

                                              })
                                            }
                                          }
                                        })
                                      }
                                    }
                                  })
                                }
                              }
                            })
                          }
                        }
                      })
                    }
                  }
                })
              }
            }
          })
        }
      }
    })
    setAllTasks(tasks);
    setAllTaskCache(tasks);
  }

  const enterTaskName = () => {
    let data = { ...editNameTask }
    let formData = new FormData();
    formData.append("data", JSON.stringify(data));

    HttpService.CreateUpdate(
      "updatetask",
      formData,
    ).then((res) => {
      if (res.data.Ack == 1) {
        setEditableInputOpen(!editableInputOpen);
        setEditNameTask(null);
        getallTasks();
      }
    })
      // .then((result)=>{

      // })
      .catch((err) => {
        swal(`${err}`, { dangerMode: true })
      });
  }

  const assigneeTaskType = ["ADD_TASK_BY_LIST", "ADD_SUBTASK_BY_LIST", "ADD_METASUBTASK_BY_LIST"];
  const priorityTaskType = ["UPDATE_TASK_PRIORITY", "UPDATE_SUBTASK_PRIORITY", "UPDATE_METASUBTASK_PRIORITY"];

  let prevStatus = {};
  let prevPriority = {};



 
  const [allDeals, setAllDeals] = useState([]);
  const [calenderEvents, setCalenderEvent] = useState([]);
  const [holidayList, setHolidayList] = useState([])

  const [urgentTaskWarningPopUp, setUrgentTaskWarningPopUp] = useState(false);
  const [stateDataForUrgent, setStateDataForUrgent] = useState({});
  const urgentTaskWarnPopUpToggle = () => setUrgentTaskWarningPopUp(!urgentTaskWarningPopUp);

  const [taskDeleteWarningToggle, setTaskDeleteWarningToggle] = useState(false);

  
 
 

  function isHolidayFestival(date1, holidayList) {
    let holiday = false;
    let date = new Date(date1);
    let list = [...holidayList];
    for (let h = 0; h < list.length; h++) {
      if (list[h].DateFormat == 'Day of the Week') {
        let month = date.getMonth();
        let day = date.getDay()
        let week = dateTime.getWeekOfMonth(date);

        if (((month + 1) == parseInt(list[h].Day.Month)) &&
          (day == parseInt(list[h].Day.Day)) &&
          (week == parseInt(list[h].Day.Week)) && (!list[h].isLast)) {
          if (list[h].Recurring) {
            holiday = true;
            break;
          } else {
            if (date >= new Date()) {
              holiday = true;
              break;
            }
          }
        } else {
          if (list[h].Day.isLast) {
            date.setHours(0, 0, 0, 0);
            let calDate = dateTime.getLastDayInMonth(date1.getFullYear(), parseInt(list[h].Day.Month), parseInt(list[h].Day.Day));
            if (date.getTime() == calDate.getTime()) {
              holiday = true;
              break;
            }
          }


        }
      }
      else if (list[h].DateFormat == 'Day of Month') {
        if (((date.getMonth() + 1) == parseInt(list[h].Day.Month)) &&
          (date.getDate() == parseInt(list[h].Day.Day))) {
          if (list[h].Recurring) {
            holiday = true;
            break;
          } else {
            if (date >= new Date()) {
              holiday = true;
              break;
            }
          }
        }
      }
    }
    return holiday;
  }

  const getEventDuration = (Assignee, AssignDate, TimeEstimate) => {
    let duration = []
    let filterUser = allUsers.filter((user) => user.UserId == Assignee[0].userId);
    let shiftStart = new Date(AssignDate);
    if (filterUser.length == 0) {
      return []
    }
    let dayShift = filterUser[0].WorkingDays.filter(obj => obj.Day == days[shiftStart.getDay()])
    if (dayShift.length > 0) {
      dayShift = dayShift[0]
    }
    let inTime = dayShift.InTime
    inTime = inTime.split(':');
    shiftStart.setHours(parseInt(inTime[0]), parseInt(inTime[1]), 0, 0);


    let shiftEnd = new Date(AssignDate);
    let outTime = dayShift.OutTime;
    outTime = outTime.split(':');
    shiftEnd.setHours(parseInt(outTime[0]), parseInt(outTime[1]), 0, 0);

    let breakStart = new Date(shiftStart);
    let breakEnd = new Date(shiftEnd);
    let lunchStart = dayShift.LunchStartTime.split(':');
    let lunchEnd = dayShift.LunchEndTime.split(':');
    breakStart.setHours(parseInt(lunchStart[0]), parseInt(lunchStart[1]), 0,);
    breakEnd.setHours(parseInt(lunchEnd[0]), parseInt(lunchEnd[1]), 0, 0);


    let endDate = new Date(AssignDate);
    let startDate = new Date(AssignDate);
    TimeEstimate = TimeEstimate.split(':');
    if (TimeEstimate !== ':') {
      endDate.setHours(endDate.getHours() + parseInt(TimeEstimate[0]));
      endDate.setMinutes(endDate.getMinutes() + parseInt(TimeEstimate[1]));
    }

    while (startDate < endDate) {
      if ((shiftStart <= startDate) && (endDate <= shiftEnd)) {
        if (isNotWorkesd(filterUser[0].WorkingDays, new Date(startDate)) || isHolidayFestival(startDate, holidayList)) {

          startDate.setDate(startDate.getDate() + 1);
          breakStart.setDate(breakStart.getDate() + 1);
          breakEnd.setDate(breakEnd.getDate() + 1);
          endDate.setDate(endDate.getDate() + 1);
          //shiftStart.setDate(shiftStart.getDate()+1);
          shiftEnd.setDate(shiftEnd.getDate() + 1);
        } else {

          let obj = { start: new Date(startDate), end: new Date(endDate) }
          if ((breakStart < obj.start) && (obj.start < breakEnd)) {


            let remainingTime5 = new Date(breakEnd).getTime() - new Date(obj.start).getTime();
            obj.start = new Date(breakEnd)
            obj.end = obj.end.setTime(obj.end.getTime() + remainingTime5);
            if (obj.end > shiftEnd) {
              let remainigTime10 = new Date(obj.end).getTime() - new Date(shiftEnd).getTime();
              obj.end = new Date(shiftEnd);
              duration.push(obj);

              shiftStart.setDate(shiftStart.getDate() + 1);
              let dayShift1 = filterUser[0].WorkingDays.filter(obj => obj.Day == days[shiftStart.getDay()])
              if (dayShift1.length > 0) {
                dayShift1 = dayShift1[0]
              }
              let inTime = dayShift1.InTime;
              inTime = inTime.split(':');

              let outTime = dayShift1.OutTime;
              outTime = outTime.split(':');

              let lunchStart = dayShift1.LunchStartTime.split(':');
              let lunchEnd = dayShift1.LunchEndTime.split(':');

              shiftStart.setHours(parseInt(inTime[0]), parseInt(inTime[1]), 0, 0);

              breakStart.setDate(breakStart.getDate() + 1);

              breakStart.setHours(parseInt(lunchStart[0]), parseInt(lunchStart[1]), 0, 0);

              startDate = shiftStart;

              shiftEnd.setDate(shiftEnd.getDate() + 1);
              shiftEnd.setHours(parseInt(outTime[0]), parseInt(outTime[1]), 0, 0);

              breakEnd.setDate(breakEnd.getDate() + 1);
              breakEnd.setHours(parseInt(lunchEnd[0]), parseInt(lunchEnd[1]), 0, 0);
              let end = new Date(startDate);

              end.setTime(end.getTime() + remainigTime10);
              endDate = end;

            } else {
              duration.push(obj);
              break;
            }

            // let breaks = { start: new Date(breakStart), isBreak: true, end: new Date(breakEnd) };
            // duration.push(breaks)





          }
          else if ((breakStart < obj.end) && (obj.end <= breakEnd)) {
            let remainingTime6 = new Date(obj.end).getTime() - new Date(breakStart).getTime();

            let newObj4 = { start: new Date(breakEnd), end: new Date(breakEnd) }
            obj.end = new Date(breakStart);
            newObj4.end.setTime(newObj4.end.getTime() + remainingTime6);
            // let breaks = { start: new Date(breakStart), isBreak: true, end: new Date(breakEnd) };
            // duration.push(breaks)
            duration.push(obj);
            duration.push(newObj4);
            break;
          }
          else if (((breakStart < obj.start) && (obj.start < breakEnd)) && ((breakStart < obj.end) && (obj.end < breakEnd))) {
            let remainingTime7 = new Date(obj.end).getTime() - new Date(obj.start).getTime();
            obj.start = new Date(breakEnd);
            obj.end = new Date(breakEnd);
            obj.end.setTime(obj.end.getTime() + remainingTime7);
            // let breaks = { start: new Date(breakStart), isBreak: true, end: new Date(breakEnd) };
            // duration.push(breaks)
            duration.push(obj);
            break;
          }
          else if (((breakStart > obj.start) && (obj.end > breakEnd))) {
            let breakDuration1 = new Date(breakEnd).getTime() - new Date(breakStart).getTime();
            obj.end = new Date(breakStart);
            let newObj1 = { start: new Date(breakEnd), end: new Date(endDate) }
            newObj1.end.setTime(newObj1.end.getTime() + breakDuration1);
            if (newObj1.end > shiftEnd) {
              let remainigTime11 = new Date(newObj1.end).getTime() - new Date(shiftEnd).getTime();
              duration.push(obj);
              newObj1.end = new Date(shiftEnd);
              duration.push(newObj1);


              shiftStart.setDate(shiftStart.getDate() + 1);
              let dayShift1 = filterUser[0].WorkingDays.filter(obj => obj.Day == days[shiftStart.getDay()])
              if (dayShift1.length > 0) {
                dayShift1 = dayShift1[0]
              }
              let inTime = dayShift1.InTime;
              inTime = inTime.split(':');

              let outTime = dayShift1.OutTime;
              outTime = outTime.split(':');

              let lunchStart = dayShift1.LunchStartTime.split(':');
              let lunchEnd = dayShift1.LunchEndTime.split(':');

              shiftStart.setHours(parseInt(inTime[0]), parseInt(inTime[1]), 0, 0);

              breakStart.setDate(breakStart.getDate() + 1);

              breakStart.setHours(parseInt(lunchStart[0]), parseInt(lunchStart[1]), 0, 0);

              startDate = shiftStart;

              shiftEnd.setDate(shiftEnd.getDate() + 1);
              shiftEnd.setHours(parseInt(outTime[0]), parseInt(outTime[1]), 0, 0);

              breakEnd.setDate(breakEnd.getDate() + 1);
              breakEnd.setHours(parseInt(lunchEnd[0]), parseInt(lunchEnd[1]), 0, 0);
              let end = new Date(startDate);

              end.setTime(end.getTime() + remainigTime11);
              endDate = end;


            } else {
              duration.push(obj);
              duration.push(newObj1);
              // let breaks = { start: new Date(breakStart), isBreak: true, end: new Date(breakEnd) };
              // duration.push(breaks)
              break;
            }

          } else {

            duration.push(obj)
            break;
          }

        }

      } else {
        let obj1 = { start: new Date(startDate), end: new Date(shiftEnd) };
        let breakDuration = 0
        //obj1.start.setMilliseconds(1)
        //duration.push(obj)
        if ((breakStart <= obj1.start) && (obj1.start <= breakEnd)) {
          breakDuration = new Date(breakEnd).getTime() - new Date(obj1.start).getTime();
          obj1.start = new Date(breakEnd);
          obj1.end = new Date(shiftEnd);
          // let breaks = { start: new Date(breakStart), isBreak: true, end: new Date(breakEnd) };
          // duration.push(breaks)
          duration.push(obj1);
          endDate.setTime(endDate.getTime() + breakDuration);
         
        }
        else if ((breakStart > obj1.start) && (obj1.start < breakEnd)) {
          breakDuration = new Date(breakEnd).getTime() - new Date(breakStart).getTime();
          obj1.end = new Date(breakStart);
          duration.push(obj1);
          // let breaks = { start: new Date(breakStart), isBreak: true, end: new Date(breakEnd) };
          // duration.push(breaks)
          let newObj1 = { start: new Date(breakEnd), end: new Date(shiftEnd) }
          duration.push(newObj1);
          endDate.setTime(endDate.getTime() + breakDuration);

        }
        else {

          duration.push(obj1)
        }
        shiftStart.setDate(shiftStart.getDate() + 1);
        let dayShift1 = filterUser[0].WorkingDays.filter(obj => obj.Day == days[shiftStart.getDay()])
        if (dayShift1.length > 0) {
          dayShift1 = dayShift1[0]
        }
        let inTime = dayShift1.InTime;
        inTime = inTime.split(':');

        let outTime = dayShift1.OutTime;
        outTime = outTime.split(':');

        let lunchStart = dayShift1.LunchStartTime.split(':');
        let lunchEnd = dayShift1.LunchEndTime.split(':');

        shiftStart.setHours(parseInt(inTime[0]), parseInt(inTime[1]), 0, 0);

        breakStart.setDate(breakStart.getDate() + 1);

        breakStart.setHours(parseInt(lunchStart[0]), parseInt(lunchStart[1]), 0, 0);

        startDate = shiftStart;
        let remainigTime4 = new Date(endDate).getTime() - new Date(shiftEnd).getTime();
        shiftEnd.setDate(shiftEnd.getDate() + 1);
        shiftEnd.setHours(parseInt(outTime[0]), parseInt(outTime[1]), 0, 0);

        breakEnd.setDate(breakEnd.getDate() + 1);
        breakEnd.setHours(parseInt(lunchEnd[0]), parseInt(lunchEnd[1]), 0, 0);
        let end = new Date(startDate);
        remainigTime4 = remainigTime4;
        end.setTime(end.getTime() + remainigTime4);
        endDate = end;


        // let obj1 = { start: new Date(startDate), end: new Date(shiftEnd) }


        // obj1.start = new Date(shiftStart)

        // obj1.end = new Date(shiftStart)
        // duration.push(obj);
        // duration.push(obj1)
      }
    }

    duration = duration.filter((one) => {
      if (((one.end.getTime() - one.start.getTime()) / (1000 * 60)) >= 1) {
        return one
      }
    })
    return duration;
  }
  const [taskDocs, setTaskDocs] = useState([])

  // useEffect(() => {
  //   if ((props.deals) && (props.deals.length > 0) && (taskDocs.length > 0) && (allUsers.length > 0)) {
  //     getAllUserTask(taskDocs)
  //   }
  // }, [props.deals, taskDocs, allUsers])

  const getTimeEstimatedValue = (task) => {
    let timeEstimate = task.TimeEstimateValue;
    if ((task.Status.name == 'COMPLETED') && (task.TimeTrack.length > 0)) {
      timeEstimate = getDiffInHourAndMiniutes(new Date(task.TimeTrack[task.TimeTrack.length - 1].StartTime), new Date(task.TimeTrack[task.TimeTrack.length - 1].StopTime));
      timeEstimate = `${timeEstimate.hours}:${timeEstimate.minutes}`
    }
    return timeEstimate;
  }

  const statusTaskType = ["UPDATE_TASK_STATUS", "UPDATE_SUBTASK_STATUS", "UPDATE_METASUBTASK_STATUS"];

  const getAllUserTask = (docs) => {

    let events = [];
    docs.forEach((task) => {
      let deal = props.deals.filter((deal) => deal._id == task.DealsId);
      if (task.Assignee.length > 0) {
        let timeEstimateValue = getTimeEstimatedValue(task);
        getEventDuration(task.Assignee, task.AssignDatetime, timeEstimateValue).forEach((event, index, self) => {
          let obj = {}
          obj = {
            continue: false,
            dealId: task.DealsId,
            Assignee: task.Assignee,
            id: task._id,
            title: deal[0].Client + ' , ' + deal[0].ProjectNumber + " , " + task.TaskName,
            // allDay: true,
            start: new Date(event.start),
            end: new Date(event.end),
            color: task.Status.color
          }



          events.push(obj);
        })


      }

    })
    setCalenderEvent(events);
    //return events;

  }

  const getCalenderEvents = (docs) => {

    let events = [];
    docs.forEach((task) => {
      let deal = props.deals.filter((deal) => deal._id == task.DealsId);
      if (task.Assignee.length > 0) {
        let timeEstimateValue = getTimeEstimatedValue(task);
        getEventDuration(task.Assignee, task.AssignDatetime, timeEstimateValue).forEach((event, index, self) => {
          let obj = {}
          obj = {
            continue: false,
            dealId: task.DealsId,
            Assignee: task.Assignee,
            id: task._id,
            title: deal[0].Client + ' , ' + deal[0].ProjectNumber + " , " + task.TaskName,
            // allDay: true,
            start: new Date(event.start),
            end: new Date(event.end),
            color: task.Status.color
          }



          events.push(obj);
        })


      }

    })
    // setCalenderEvent(events);
    return events;

  }


  // useEffect(() => {
  //   if (props.id == 'tasklist') {
  //     getallTasks();
  //   }
  // }, [props.searchTask]);

  // useEffect(() => {
  //   if (props.id == 'tasklist') {
  //     getallTasks();
  //   }
  // }, [SearchThisTask]);

  // useEffect(() => {
  //   if (props.id == 'tasklist') {
  //     getallTasksfromParent();
  //     getAllUsersfromParent();
  //   }
  // }, [DealsId, props.allTasks, props.groupBy, props.searchTask]);
  const getallTasksfromParent = () => {
    if (
      props.searchTask == "" ||
      props.searchTask == undefined ||
      props.searchTask == null
    ) {
      setAllTasks(props.allTasks);
      TaskIcons(props.allTasks);
      getGroupByFields(props.allTasks, props.groupBy);
    } else {
      let resData = [];
      props.allTasks.forEach((task, i) => {
        if (
          task.TaskName.toLowerCase().includes(props.searchTask.toLowerCase())
        ) {
          resData.push(task);
        }
      });
      setAllTasks(resData);
      TaskIcons(resData);
      getGroupByFields(resData, props.groupBy);
    }
  };
  const getAllUsersfromParent = () => {
    usersDetail = props.allUsers;
    setAllUsers(props.allUsers);
  };









  ///////////////////////////////////////////////////////End Code For Task List Here///////////////////////////////////////////////////////////////

  const optionsService = [
    { value: 'PreliminaryResearch', label: 'Preliminary Research' },
    { value: 'PreliminaryLayout', label: 'Preliminary Layout' },
    { value: 'SiteAssessment', label: 'Site Assessment' },
    { value: 'Design', label: 'Design' },
    { value: 'Asbestos', label: 'Asbestos' },
    { value: 'Expediting', label: 'Expediting' },
    { value: 'Permitting', label: 'Permitting' },
    { value: 'Certification', label: 'Certification' },
    { value: 'ElectricalDiagram', label: 'Electrica lDiagram' },
    { value: 'SpecialInspection', label: 'Sspecial Inspection' },
    { value: 'ProgressInspection', label: 'Progress Inspection' },
    { value: 'EnergyInspection', label: 'Energy Inspection' },
    { value: 'Interconnection', label: 'Interconnection' },
    { value: 'StructuralLetter', label: 'Structural Letter' },
    { value: 'PropertySurvey', label: 'Property Survey' },
    { value: 'NYSERDAFiling', label: 'NYSERDA Filing' },
    { value: 'PropertyTaxAbatement', label: 'Property Tax Abatement' }
  ];

  const getValues = () => {
    let serviceVar = [...serviceReq];
    let values = [];
    serviceVar.forEach((one) => {
      let objKey = Object.keys(one);
      if (one[objKey[0]] == 'Yes') {
        let obj = {};
        let filterLable = optionsService.filter((one) => one.value == objKey[0])
        obj.label = filterLable[0].label;
        obj.value = objKey[0];
        values.push(obj);
      }

    })
    return values;
  }
  const serviceReqVariable = [
    { "PreliminaryResearch": "No" },
    { "PreliminaryLayout": "No" },
    { "SiteAssessment": "No" },
    { "Design": "No" },
    { "Asbestos": "No" },
    { "Expediting": "No" },
    { "Permitting": "No" },
    { "Certification": "No" },
    { "ElectricalDiagram": "No" },
    { "SpecialInspection": "No" },
    { "ProgressInspection": "No" },
    { "EnergyInspection": "No" },
    { "Interconnection": "No" },
    { "StructuralLetter": "No" },
    { "PropertySurvey": "No" },
    { "NYSERDAFiling": "No" },
    { "PropertyTaxAbatement": "No" }
  ]

  const getServiceRequestedData = (job) => {
    let jobdata = { ...job };
    let serviceReArr = [...serviceReqVariable]
    serviceReArr.forEach((one) => {
      let objKey = Object.keys(one);
      one[objKey[0]] = jobdata[objKey[0]]
    })
    setServiceRequested(serviceReArr)
  }

  const [serviceReq, setServiceRequested] = useState(serviceReqVariable)


  const getGroupVal = (groupValue) => {
    if (groupValue) {

      groupValue = groupValue.split('-');
      groupValue = groupValue[groupValue.length - 1];
      return groupValue;
      // if (props.id == 'task') {
      //   groupValue = groupValue.replaceAll(props.dealObj.Deal_Name, '');
      //   groupValue = groupValue.replace('-', '');
      //   return groupValue;
      // } else {
      //   groupValue = groupValue.replaceAll(props.dealInfo.Deal_Name, '');
      //   groupValue = groupValue.replace('-', '');
      //   if (!groupValue)
      //     return "NO PIPELINE"
      //   return groupValue;
      // }

    }

  }
  const Option = props => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  const MultiValue = props => (
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  );

  const animatedComponents = makeAnimated();


  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const [AttachedFiles, setAttachedFiles] = useState([]);

  const saveFileClickHandler = (file) => {
    if (file.length > 0) {
      let attachFilesData = { ...prepareTaskData() };
      attachFilesData.AttachedFiles = file;
      addUpdateTaskApi(attachFilesData);
    }
  }

  const handleFileAttachments = async (fileList) => {
    let tempFileCollection = [...AttachedFiles];
    let v = fileList.length;
    let index = 0;
    while (index < v) {
      let fileName = fileList[index].name;
      let base64 = await convertToBase64(fileList[index]);
      tempFileCollection.push({
        fileName: fileName,
        file: base64,
      });
      index = index + 1;
    }
    saveFileClickHandler(tempFileCollection)
    addActivity("ADD", "Attachment", prepareTaskData()._id, prepareTaskData().TaskName, 0, tempFileCollection)
    setAttachedFiles(tempFileCollection);
    // attachFilesData.AttachedFiles = tempFileCollection;
    // addUpdateTaskApi(attachFilesData);
  };


  // useEffect(() => {
  //   if ((props.id == 'task') && props.dealsId) {
  //     setDealsId(props.dealsId);
  //   }
  // }, [props.dealsId]);

  // useEffect(() => {
  //   if (props.id == 'task') {
  //     getallTasks();
  //     getAllUsers();

  //   }
  // }, [props.dealsId]);

  // useEffect(() => {
  //   if (props.id == 'task') {
  //     getallTasks();
  //     getAllUsers();
  //     getallclients();

  //   }

  // }, [allTasks]);

  const [clientData, setClientData] = useState([]);

  const getallclients = async () => {
    let returnData = await HttpService.getByBoj('getallclients', {}).then(res => {
     
      setClientData(res.data)
    });


  };


  const filterAllClients = () => {
    let allClients = [...clientData];
    let clientsName = allClients.filter(e => e.clientsName);
    setClientData(clientsName);
  }

  const [dependencyDrop, setDependencyDrop] = useState(false);
  const dependencyToggle = () => setDependencyDrop((prevState) => !prevState);

  const [allTaskList, setallTaskList] = useState([]);
  const dependencyType = ["Waiting-On", "Blocking"];
  const [DependencyDropdownData, setDependencyDropdownData] = useState([]);
  const [taskDependency, setTaskDependency] = useState([]);
  const [DependencyType, setDependencyType] = useState("");
  const taskDependencyName = (id) => {
    let task = allTaskList.find((t) => t.id == id);
    if (task != undefined) {
      return task.name;
    } else {
      return "NONE";
    }
  };
  const opentasktoggle = () => setOpenTaskPopup(!openTaskPopup);
  const [openTaskPopup, setOpenTaskPopup] = useState(false);
  const addTasktoggle = () => setAddTaskPopup(!addTaskPopup);
  const [addTaskPopup, setAddTaskPopup] = useState(false);
  const filtertasktoggle = () => setFilterTaskPopup(!filterTaskPopup);
  const [filterTaskPopup, setFilterTaskPopup] = useState(false);
  const secondPlustoggle = () => setsecondPlusPopup(!secondPlusPopup);
  const [secondPlusPopup, setsecondPlusPopup] = useState(false);
  const firstPlustoggle = () => { setfirstPlusPopup(!firstPlusPopup); if (!firstPlusPopup) { setOpenAddColumnPopUpDealId('') } };
  const [firstPlusPopup, setfirstPlusPopup] = useState(false);
  const settingtasktoggle = () => setsettingtaskPopup(!settingTaskPopup);
  const [settingTaskPopup, setsettingtaskPopup] = useState(false);
  const [nestedModal1, setNestedModal1] = useState(false);
  const [nestedModal, setNestedModal] = useState(false);
  const [isOpen, setIsOpen] = useState("");
  const [taskArrow, setTaskArrow] = useState("");
  const [backdrop, setBackdrop] = useState("static");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [subtaskStatusDrop, setSubTaskStatusDrop] = useState(false);
  const [categoryDrop, setCategoryDrop] = useState(false);
  const [taskName, setTaskName] = useState("");
  const [timeEstimateValue, setTimeEstimateValue] = useState("");
  const [timeMinuteValue, setTimeMinuteValue] = useState("");
  const [subtaskName, setSubtaskName] = useState("");
  const [description, setDescription] = useState("");
  const [datePickerIsOpen, setdatePickerIsOpen] = useState(false);
  const [startDate, setstartDate] = useState(new Date());
  const [groupBy, setGroupBy] = useState("Pipeline");
  const [defaultDependencyStatus, setDefaultDependencyStatus] = useState([])
  const [taskLayout, setTaskLayout] = useState({
    status_details: [
      // {
      //   is_start: true,
      //   color: "#f56b62",
      //   name: "Pending",
      //   id: "1716273000002029049",
      //   is_default: false,
      //   type: "open",
      // },
      {
        is_start: false,
        color: "#a9b2c0",
        name: "Not Required",
        id: "1716273000002029043",
        is_default: false,
        type: "closed",
      },
      {
        is_start: false,
        color: "#a7bcee",
        name: "Not Started",
        id: "1716273000002029045",
        is_default: false,
        type: "open",
      },
      {
        is_start: false,
        color: "#fbc11e",
        name: "To Do",
        id: "1716273000002029041",
        is_default: false,
        type: "open",
      },
      {
        is_start: false,
        color: "#08aeea",
        name: "In Progress",
        id: "1716273000000031001",
        is_default: true,
        type: "open",
      },
      {
        is_start: false,
        color: "#98ca3c",
        name: "Completed",
        id: "1716273000002029047",
        is_default: false,
        type: "closed",
      },

    ]
  })

  const [projectDate, setProjectDate] = useState(
    Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }).format(new Date())
  );
  const [taskStatus, setTaskStatus] = useState({
    name: "PENDING",
    color: "#e50000",
  });
  const [subtaskStatus, setSubtaskStatus] = useState({
    name: "PENDING",
    color: "#e50000",
  });
  const [taskCategory, setTaskCategory] = useState("DESIGN");
  const categoryValues = ["DESIGN", "FILING", "MISCELLANEOUS"];
  const priorityValues = [
    { name: "None", color: "lightgray" },
    { name: "High", color: "#e50000" },
    { name: "Medium", color: "#f7c700" },
    { name: "Low", color: "blue" },
  ];
  const statusValues = [
    { name: "PENDING", color: "#e50000" },
    { name: "NOT STARTED", color: "#f8d800" },
    { name: "IN PROGRESS", color: "#2ecd6f" },
    { name: "COMPLETED", color: "#f900ea" },
    { name: "NOT REQUIRED", color: "#0231e8" },
  ];

  const statusForWarn = ["IN PROGRESS", "COMPLETED"];


  const taskExtraItems = [
    { name: 'Milestone', id: 'milestone', label1: 'Mark as Milestone', label2: 'Unmark as Milestone' },
    { name: 'Duplicate', id: 'duplicate', label1: 'Duplicate' },
    { name: 'Template', id: 'template', label1: 'Save As Template' },

  ];


  const [popoverOpen, setPopoverOpen] = useState(false);
  const addviewtoggle = () => setPopoverOpen(!popoverOpen);

  const updateDueDateToggle = () => {
    setSelectedTaskId("");
    if (openDueDatePopUp) {
      setOpenDueDatePopUp(false)
    }
  };

  const updateViewToggle = () => {
    setSelectedTaskId("");
    if (openTimeEstimatePopUp) {
      setOpenTimeEstimatePopUp(false)
    }
  };

  const updateSubViewToggle = () => {
    setSelectedTaskId("");
    if (openTimeEstimatePopUp) {
      setOpenTimeEstimatePopUp(false)
    }
  };

  const updateMetaToggle = () => {
    setSelectedTaskId("");
    if (openTimeEstimatePopUp) {
      setOpenTimeEstimatePopUp(false)
    }
  };

  // useEffect(() => {
  //   if (props.isAccess) {
  //     const columns = [...addColumns];
  //     columns.forEach((one) => {
  //       one.isVisible = isVisibleTableColumn(one.headerName);
  //     })
  //     setAddColumns(columns);
  //   }
  // }, [])


  const getTaskLayout = (projectId) => {
    // setLoader(true)
    TaskService.GetTaskLayout(projectId)
      .then((result) => {
        setLoader(false)
        setDefaultDependencyStatus(result.status_details)
        // setTaskLayout(result);
      })
      .catch((err) => {
        setLoader(false)
        console.log(err);
      })
  }

  const isVisibleTableColumn = (name) => {
    let isVisibleRight = false;

    if (name == 'TIME TRACKING') {
      isVisibleRight = (props.isView && props.isView(props.selectedRole, 'Time Tracked'));
    }
    if (name == 'SUPERVISER') {
      isVisibleRight = (props.isView && props.isView(props.selectedRole, 'Supervisers'));

    }
    if (name == 'ASSIGNEE') {
      isVisibleRight = (props.isView && props.isView(props.selectedRole, 'Assignee'));

    }
    if (name == 'PRIORITY') {
      isVisibleRight = (props.isView && props.isView(props.selectedRole, 'Priority'));

    }
    if (name == 'DUE DATE') {
      isVisibleRight = (props.isView && props.isView(props.selectedRole, 'Due Date'));

    }
    if (name == 'TIME ESTIMATE') {
      isVisibleRight = (props.isView && props.isView(props.selectedRole, 'Time Estimate'));

    }
    if (name == 'PROGRESS') {
      isVisibleRight = true

    }
    if (name == 'STATUS') {
      isVisibleRight = (props.isView && props.isView(props.selectedRole, 'Status'));

    }
    if (name == 'PIPELINE') {
      isVisibleRight = (props.isView && props.isView(props.selectedRole, 'Task Pipeline'));

    }
    if (name == 'CATEGORY') {
      isVisibleRight = (props.isView && props.isView(props.selectedRole, 'Task Category'));

    }
    if (name == 'MILESTONE') {
      isVisibleRight = (props.isView && props.isView(props.selectedRole, 'Mark Milestone'));

    }
    let filterColumn = addColumns.filter(one => one.headerName == name)
    if (filterColumn.length > 0) {
      return (filterColumn[0].isVisible && isVisibleRight);
    }
    return true;
  }

  const getDifferenceBetweenTimes = (time1, time2) => {
    let timeDiff = new Object();
    timeDiff.milliseconds = Math.abs((time1 - time2));
    timeDiff.seconds = Math.floor(timeDiff.milliseconds / 1000);
    timeDiff.minutes = Math.floor(timeDiff.seconds / 60);
    timeDiff.hours = Math.floor(timeDiff.minutes / 60);
    timeDiff.days = Math.floor(timeDiff.hours / 24);
    timeDiff.weeks = Math.floor(timeDiff.days / 7);

    return timeDiff;


  }

  const [checkAllColumns, setCheckAllColumns] = useState(true);
  const getTotalTimeDiff = (taskData) => {
    let data = { ...taskData }
    let totalTimeDiff = { hrs: 0, mins: 0, sec: 0 };
    let timeStr = ""
    if (data && data.TimeTrack) {
      data.TimeTrack.forEach((t) => {
        if (t.current) {
          let obj = getDifferenceBetweenTimes(new Date(t.StartTime), new Date(Date.now()));
          totalTimeDiff.sec = obj.seconds + totalTimeDiff.sec;
        } else {
          let obj = getDifferenceBetweenTimes(new Date(t.StartTime), new Date(t.StopTime));
          totalTimeDiff.sec = obj.seconds + totalTimeDiff.sec;
        }

      })

      if (totalTimeDiff.sec >= 60) {
        totalTimeDiff.mins = totalTimeDiff.mins + Math.floor(totalTimeDiff.sec / 60);
        totalTimeDiff.sec = totalTimeDiff.sec % 60;

      }
      if (totalTimeDiff.mins >= 60) {
        totalTimeDiff.hrs = totalTimeDiff.hrs + Math.floor(totalTimeDiff.mins / 60);
        totalTimeDiff.mins = totalTimeDiff.mins % 60;

      }
      timeStr = timeStr + (totalTimeDiff.hrs < 10 ? "0" : "") + totalTimeDiff.hrs;
      timeStr =
        timeStr + ":" + (totalTimeDiff.mins < 10 ? "0" : "") + totalTimeDiff.mins;
      timeStr =
        timeStr + ":" + (totalTimeDiff.sec < 10 ? "0" : "") + totalTimeDiff.sec;
      return timeStr + " Hrs";
    } else {
      return `00:00:00 Hrs`;
    }


  }

  const getTimeTrackedColumn = (taskData) => {
    // timerTimeTrack:props.timerTimeTrack,
    // timerObj:props.timerObj,
    if (props.timerObj && (taskData.JobId == props.timerObj.jobId) && props.timeTrackFlag) {
      taskData.TimeTrack.TotalTime = props.timerTimeTrack;
      allTasks.forEach((one) => {
        if (one.id_string == taskData.id_string) {
          one.TimeTrack.TotalTime = props.timerTimeTrack;
        }
      })

      // if(allTasks.filter((one)=>one.id_string==taskData.id_string).length>0){
      //   allTasks.filter((one)=>one.id_string==taskData.id_string)[0].TimeTrack.TotalTime=props.timerTimeTrack;
      // }

    }
    return <p className="fontsize-12 text-hover-blue m-0" id="timeTracked">
      <FontAwesomeIcon
        icon={props.timerObj && (taskData.JobId == props.timerObj.jobId) && props.timeTrackFlag ? faStopCircle : faPlayCircle}
        className="timeTrack-playIcon"
        style={
          props.timerObj && (taskData.JobId == props.timerObj.jobId) && props.timeTrackFlag
            ? { color: 'rgb(204, 45, 45)' }
            : { color: 'rgb(45, 204, 110)' }
        }
        onClick={() => {
          //startstopClock(taskData)
          if (props.startstopClockTimeTrack && props.timeTrackFlag) {
            props.startstopClockTimeTrack(props.timerObj);
          } else {
            props.setTimerTimeTrack(taskData.TimeTrack.TotalTime);
            props.startstopClockTimeTrack({ jobId: taskData.JobId, jobName: taskData.name });
          }

        }}
      />
      <span className="ml-1" >{props.timerObj && (taskData.JobId == props.timerObj.jobId) && props.timeTrackFlag ? `${props.timerTimeTrack}`.toHHMMSS() : taskData.TimeTrack && `${taskData.TimeTrack.TotalTime}`.toHHMMSS()}</span>
    </p>
    // return <p className="fontsize-12 text-hover-blue m-0" id="timeTracked">
    //   <FontAwesomeIcon
    //     icon={taskData.TimeTrack.IsActive ? faStopCircle : faPlayCircle}
    //     className="timeTrack-playIcon"
    //     style={
    //       taskData.TimeTrack.IsActive
    //         ? { color: 'rgb(204, 45, 45)' }
    //         : { color: 'rgb(45, 204, 110)' }
    //     }
    //     onClick={() => {
    //       startstopClock(taskData)


    //     }}
    //   />
    //   <span className="ml-1" >{taskData.TimeTrack && `${taskData.TimeTrack.TotalTime}`.toHHMMSS()}</span>
    // </p>

  }

  let [defaultColumns, setDefaultColumns] = useState(['TIME TRACKING', 'SUPERVISER', 'ASSIGNEE', 'DUE DATE', 'PRIORITY', 'TIME ESTIMATE', 'PROGRESS', 'STATUS', 'PIPELINE', 'CATEGORY', 'MILESTONE']);
  const [addColumns, setAddColumns] = useState([])

  const taskColumns =

    [
      { name: 'TIME TRACKING', isVisible: true, isSearch: true },
      { name: 'SUPERVISER', isVisible: true, isSearch: true },
      { name: 'ASSIGNEE', isVisible: true, isSearch: true },
      { name: 'DUE DATE', isVisible: true, isSearch: true },
      { name: 'PRIORITY', isVisible: true, isSearch: true },
      { name: 'TIME ESTIMATE', isVisible: true, isSearch: true },
      // { name: 'PROGRESS', isVisible: true, isSearch: true },
      { name: 'STATUS', isVisible: true, isSearch: true },
      { name: 'PIPELINE', isVisible: true, isSearch: true },
      { name: 'CATEGORY', isVisible: true, isSearch: true },
      { name: 'MILESTONE', isVisible: true, isSearch: true },

    ]

  useEffect(() => {
    if (props.id == 'tasklist') {
      setAddColumns(props.columns ? props.columns : []);
    } else {
      setAddColumns(taskColumns)
    }
  }, [props.id])

  const getAllTimeTrackLogs = (taskId) => {
    return new Promise((resolve, reject) => {
      let startDate = new Date();
      startDate.setDate(1);
      startDate = dayjs(startDate).format('YYYY-MM-DD')
      let endDate = new Date();
      endDate = new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0)
      // endDate.setDate(31);
      endDate = dayjs(endDate).format('YYYY-MM-DD')
      //userinfo.email_id
      TaskService.GetAllTimeTrack("david@srsolardesign.com", startDate, endDate, taskId)
        .then((result) => {
          result = result ? result : []
         
          let totalTime = 0;
          result.forEach((one) => {
            totalTime = totalTime + one.totaltime;
          })
          let obj = { TotalTime: totalTime, TimeTracks: result }
          resolve(obj);
        })
        .catch((err) => {
          reject(err);
        })
    })
  }

  const [dragOver, setDragOver] = useState("");

  const evaluateDepedncyAndSuccession = async (data, value) => {
    let dpendency = dependencyList.filter((one) => (one.Current_Task_ID == data.id_string) && (one.Data_For == "Dependency"));
    let updateTaskStatus = dependencyList.filter((one) => (one.Current_Task_ID == data.id_string) && (one.Data_For == "Succession"));
    let systemWarningTasks = []
    for (let i = 0; i < updateTaskStatus.length; i++) {
      let filterTask = allTasks.filter((one) => one.id_string == updateTaskStatus[i].Related_Task_ID)
      if (filterTask.length > 0) {
        let changeStatus = taskLayout.status_details.filter((one) => one.id == updateTaskStatus[i].Dependent_Task_Status)
        changeStatus = changeStatus.length > 0 ? changeStatus[0] : {}
        let changeTaskIdx = taskLayout.status_details.findIndex((x) => x.name === filterTask[0].status.name);
        let changeStatusIdx = taskLayout.status_details.findIndex((x) => x.name === changeStatus.name);
        if (updateTaskStatus[i].Current_Task_Status.includes(value.name)) {
          if (changeStatusIdx >= changeTaskIdx) {
            await updateTask(filterTask[0], "custom_status", updateTaskStatus[i].Dependent_Task_Status)
          } else {
            systemWarningTasks.push(filterTask[0])

          }
        }
      }

    }

    if (systemWarningTasks.length > 0) {
      let changeStatus = []
      updateTaskStatus.forEach((one) => {
        let status1 = taskLayout.status_details.filter(sta => sta.id == one.Dependent_Task_Status);
        if (status1.length > 0) {
          changeStatus.push(status1[0].name)
        }
      })
      changeStatus = _.uniq(changeStatus);
      changeStatus = changeStatus.join(' , ');
      let status = systemWarningTasks.map(one => one.status.name);
      status = _.uniq(status);
      status = status.join(' , ');
      setChildTask(systemWarningTasks);
      setSystemWarningText(`system can not change these task status from ${status} to ${changeStatus} you can change manually`);
      setSystemWarningState(true);
    }


    let status = await IsTaskDependent(data, value.name, dpendency)
    if (status) {
      //  setActivityObj(obj);
      //  // setParentToSubtaskStatusCompleteWar(true);
      setStatusForUpdate(value)
      setTaskCompleteWarning(true);
      //  data.Status = value
      //  setDataForUpdate(data);
      //  getallTasks();
    } else {
      let prevStatusIdx = taskLayout.status_details.findIndex((x) => x.name === data.status.name);
      let nextStatusIdx = taskLayout.status_details.findIndex((x) => x.name === value.name);
      if (prevStatusIdx <= nextStatusIdx) {
        await updateTask(data, "custom_status", value.id)
      } else {
        swal({
          title: "Are you sure?",
          text: `You want to change the status from  ${data.status.name}  to  ${value.name} `,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
          .then(async (willDelete) => {
            if (willDelete) {
              await updateTask(data, "custom_status", value.id)
            }
          });
      }
    }

  }

  const getCategoryNames = () => {
    let all = [...allCategory];
    let returnArr = [];
    all.forEach((one) => {
      returnArr.push({ name: one.CategoryName, id: one.CategoryName });
    })
    return returnArr;
  }

  const getTaskSupervisor = (task) => {
    let users = []
    let obj = { ...task };
    let all = [...allUsers]
    let superViser = obj.custom_fields.filter((one) => one.label_name == "Supervisor");
    if (superViser.length > 0) {
      users = superViser[0].users
      if ((users.length == 0) && superViser[0].value) {
        let filteruser = all.filter((one) => superViser[0].value == one.zuid);
        if (filteruser.length > 0) {
          filteruser = filteruser[0]
          users.push({ email: filteruser.email_id, id: filteruser.zuid, name: filteruser.first_name + " " + filteruser.last_name });
        }
      }

    }
    return users
  }

  const getSubtaskTDAsperHeader = (data, subData, headerName) => {
    let taskEstHr = "00";
    let taskEstMin = "00";
    if (subData.TimeEstimateValue != "") {
      // let time=tasks.TimeEstimateValue.split(":");
      // taskEstHr=time[0];
      // taskEstMin=time[1];
      let obj = getTimeEstimateValue(
        subData.TimeEstimateValue
      );
      taskEstHr = obj.hrs;
      taskEstMin = obj.mins;
    }
    if (headerName === "TIME TRACKING") {
      return <td
      // dragOver={defaultColumns[subIndex] === dragOver}
      >
        {getTimeTrackedColumn(subData)}</td>
    }
    if (headerName === "SUPERVISER") {
      return <td
        // dragOver={defaultColumns[subIndex] === dragOver}
        onClick={() => {
          setSelectedCalenderTask(subData);
          setAssigneeOpenFlag(false);
          setSuperviserOpenFlag(true);
        }} id={"employee-calender-popup" + subData._id}>
        {getSuperviserDropdownMenu(
          data,
          addSuperviserTblImgIcon,
          NOT_DEFINED_VALUE,
          "SUBTASK_DROPDOWN_MENU",
          subData,
          NOT_DEFINED_VALUE
        )}
        {subData.Superviser && subData.Superviser.length > 0
          ? subData.Superviser.map(
            (assignee, i) =>
              getSuperviserDropdownMenu(
                data,
                addSuperviserImgTblImg,
                assignee,
                "SUBTASK_DROPDOWN_MENU",
                subData,
                NOT_DEFINED_VALUE
              )
          )
          : null}
      </td>
    }
    if (headerName === "ASSIGNEE") {
      return <td
        // dragOver={defaultColumns[subIndex] === dragOver}
        onClick={() => {
          setSelectedCalenderTask(subData)
          setAssigneeOpenFlag(true);
          setSuperviserOpenFlag(false)
        }

        } id={"employee-calender-popup" + subData._id}>
        {getDropdownMenu(
          data,
          addAssigneeTblImgIcon,
          NOT_DEFINED_VALUE,
          "SUBTASK_DROPDOWN_MENU",
          subData,
          NOT_DEFINED_VALUE
        )}
        {subData.Assignee && subData.Assignee.length > 0
          ? subData.Assignee.map(
            (assignee, i) =>
              getDropdownMenu(
                data,
                addAssigneeImgTblImg,
                assignee,
                "SUBTASK_DROPDOWN_MENU",
                subData,
                NOT_DEFINED_VALUE
              )
          )
          : null}
      </td>
    }
    if (headerName === "DUE DATE") {
      return <td
        // dragOver={defaultColumns[subIndex] === dragOver}
        className="cursor-pointer">
        <DatePicker
          className={
            (new Date(subData.DueDate).getTime() == new Date().getTime())
              ? "taskdetail-datepicker-blue text-center" :
              (new Date(subData.DueDate) < new Date()) ?
                "taskdetail-datepicker-red text-center" : (new Date(subData.DueDate) > new Date()) ?
                  "taskdetail-datepicker-green text-center" : ''
            // Date.now() -
            //   Date.parse(e.DueDate) >
            //   0
            //   ? "taskdetail-datepicker-red text-center"
            //   : (Date.parse(e.DueDate) -
            //     Date.now()) /
            //     1000 <
            //     172800
            //     ? "taskdetail-datepicker-green text-center"
            //     : "taskdetail-datepicker-blue text-center"
          }
          selected={Date.parse(subData.DueDate)} //subtask
          onChange={(evnt) =>
            updateDate(
              evnt,
              data,
              subData,
              "",
              "UPDATE_SUBTASK_DATE"
            )
          }
          dateFormat="MM/dd/yyyy"
          popperPlacement="bottom"
          popperModifiers={{
            flip: {
              behavior: ['bottom'] // don't allow it to flip to be above
            },
            preventOverflow: {
              enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
            },
            hide: {
              enabled: false // turn off since needs preventOverflow to be enabled
            }
          }}
        />
        {/* {e.DueDate} */}
      </td>
    }
    if (headerName === "PRIORITY") {
      return <td
        // dragOver={defaultColumns[subIndex] === dragOver}
        className="cursor-pointer">
        <UncontrolledDropdown className="transparent-dropdown">
          <DropdownToggle>
            {subData.Priority.name == "" ? (
              <img
                src={tag}
                className="task-table-icon"
              />
            ) : (
              <FontAwesomeIcon
                style={{
                  color: subData.Priority.color,
                }}
                icon={solidFlag}
              />
            )}
          </DropdownToggle>
          <DropdownMenu>
            {priorityValues.map(
              (value, i) => {
                return (
                  <DropdownItem
                    id={'priority-click' + subData._id + value.name}
                    onClick={(evnt) =>
                      updatePriority(
                        value,
                        data,
                        subData,
                        null,
                        "UPDATE_SUBTASK_PRIORITY"
                      )
                    }
                  >
                    <FontAwesomeIcon
                      style={{
                        color:
                          value.color,
                        marginRight:
                          "5px",
                      }}
                      icon={solidFlag}
                    />{" "}
                    {value.name}
                  </DropdownItem>
                );
              }
            )}
            <DropdownItem
              onClick={(evnt) => {
                let value = {
                  name: "",
                  color: "#000",
                };
                updatePriority(
                  value,
                  data,
                  subData,
                  null,
                  "UPDATE_SUBTASK_PRIORITY"
                );
              }}
            >
              <FontAwesomeIcon
                icon={faTimes}
                style={{
                  color: "red",
                  marginRight: "10px",
                }}
              />
              CLEAR
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </td>
    }
    if (headerName === "TIME ESTIMATE") {
      return <td
        // dragOver={defaultColumns[subIndex] === dragOver}
        id={`updateSubTimeEstimate${subData._id}`}
        onClick={() => {
          if (!openTimeEstimatePopUp) {
            setOpenTimeEstimatePopUp(true);
            setSelectedTaskId(subData._id);

            let obj = getTimeEstimateValue(
              subData.TimeEstimateValue
            );
            setTimeEstimateValue(obj.hrs);
            setTimeMinuteValue(obj.mins);
          }
          // setTimeEstimateValue(e.TimeEstimateValue.split(":")[0]);
          // setTimeMinuteValue(e.TimeEstimateValue.split(":")[1]);
        }}
      >
        {/* {e.TimeEstimateValue==""?"00:00":taskEstHr+"h "+taskEstMin+" m" } */}
        {getTymDetails(
          subData.TimeEstimateValue,
          taskEstHr,
          taskEstMin
        )}

        <UncontrolledPopover
          isOpen={
            selectedTaskId === subData._id
          }
          className="time_est_popover"
          toggle={updateSubViewToggle}
          trigger="legacy"
          placement="bottom"
          target={`updateSubTimeEstimate${subData._id}`}
        >
          <PopoverBody className="m-2">
            <b className="taskstatus-heading-right">
              Time Estimate :
            </b>
            <div className="time_est_fields">
              <div className="time_fields">
                <Input
                  type="text"
                  placeholder="00"
                  value={timeEstimateValue.length == 1 ? '0' + timeEstimateValue : timeEstimateValue}
                  onChange={(e) => {
                    if (e.target.value.length >= 2) {
                      let strsub1 = e.target.value.replace(/^0+/, '');
                      setTimeEstimateValue(strsub1);
                    }
                    else {
                      setTimeEstimateValue(e.target.value)
                    }
                  }

                  }
                  onKeyDown={(event) =>
                    subTaskEnterTimeEstimate(
                      event,
                      "updateList",
                      data,
                      subData
                    )
                  }
                />
                <span className="time_unit">
                  hrs
                </span>
              </div>
              <div className="time_fields ml-2">
                <Input
                  type="text"
                  placeholder="00"
                  value={timeMinuteValue.length == 1 ? '0' + timeMinuteValue : timeMinuteValue}
                  onChange={(e) => {
                    if (e.target.value.length >= 2) {
                      let strsub2 = e.target.value.replace(/^0+/, '');
                      setTimeMinuteValue(strsub2)
                    }
                    else {
                      setTimeMinuteValue(
                        e.target.value
                      )
                    }
                  }
                  }
                  onKeyDown={(event) =>
                    subTaskEnterTimeEstimate(
                      event,
                      "updateList",
                      data,
                      subData
                    )
                  }
                />
                <span className="time_unit">
                  min
                </span>
              </div>
            </div>
          </PopoverBody>
        </UncontrolledPopover>
      </td>
    }
    if (headerName === "PROGRESS") {
      return <td
        // dragOver={defaultColumns[subIndex] === dragOver}
        className="task-progress">
        <div className="progress-wrapper">
          <ProgressBar now={75} />{" "}
        </div>
        <span>75%</span>
      </td>
    }
    if (headerName === "STATUS") {
      return <td
        // dragOver={defaultColumns[subIndex] === dragOver}
        className="status cursor-pointer">
        <UncontrolledDropdown>
          <DropdownToggle
            style={{
              backgroundColor:
                subData.Status.color,
            }}
          >
            {subData.Status.name}
          </DropdownToggle>
          <DropdownMenu>
            {statusValues.map(
              (value, i) => {
                return (
                  <DropdownItem
                    id={"status-click-id" + subData._id + value.name}
                    style={{
                      backgroundColor:
                        value.color,
                      color: "#fff",
                    }}
                    onClick={async () => {
                      if (await GetAndCheckTaskFieldMandtoryToComplete(subData._id) && (value.name == 'COMPLETED')) {
                        setTaskCompleteWarning(true);
                        let obj = { ...taskStatusUpdateObj };
                        obj.first = value
                        obj.second = data;
                        obj.third = subData;
                        obj.forth = null;
                        obj.fifth = "UPDATE_SUBTASK_STATUS"
                        setTaskStatusUpdateObj(obj);
                        setTaskStatusUpdateWhere('table');
                      } else {
                        updateStatus(
                          value,
                          data,
                          subData,
                          null,
                          "UPDATE_SUBTASK_STATUS"
                        )
                      }
                    }

                    }
                  >
                    {value.name}
                  </DropdownItem>
                );
              }
            )}
          </DropdownMenu>
        </UncontrolledDropdown>
      </td>
    }
    if (headerName === "PIPELINE") {
      return <td
        // dragOver={defaultColumns[subIndex] === dragOver}
        className="subtask-cat">{subData.Pipeline}</td>
    }
    if (headerName === "CATEGORY") {
      return <td
        // dragOver={defaultColumns[subIndex] === dragOver}
        className="subtask-cat">{subData.Category}</td>
    }
    if (headerName === "MILESTONE") {
      return <td
        // dragOver={defaultColumns[subIndex] === dragOver}
        className="subtask-cat">{subData.isMilestone ? subData.Milestone.Status : ''}</td>

    }
  }

  const getMetaTaskTDAsPerHeader = (data, subData, metaData, headerName) => {
    let taskEstHr = "00";
    let taskEstMin = "00";
    if (subData.TimeEstimateValue != "") {
      let obj =
        getTimeEstimateValue(
          metaData.TimeEstimateValue
        );
      taskEstHr = obj.hrs;
      taskEstMin = obj.mins;
    }
    if (headerName === "TIME TRACKING") {
      return <td
      // dragOver={defaultColumns[metaIndex] === dragOver}
      >{getTimeTrackedColumn(metaData)}</td>
    }
    if (headerName === "SUPERVISER") {
      return <td
        // dragOver={defaultColumns[metaIndex] === dragOver}
        onClick={() => {
          // setSelectedCalenderTask(metaData)
          // setAssigneeOpenFlag(true);
          // setSuperviserOpenFlag(false);
        }
        } id={"employee-calender-popup" + metaData._id}>
        {getDropdownMenu(
          data,
          addAssigneeTblImgIcon,
          NOT_DEFINED_VALUE,
          "METATASK_DROPDOWN_MENU",
          subData,
          metaData
        )}

        {typeof metaData.Assignee !==
          "undefined" && metaData.Assignee
          ? metaData.Assignee.map(
            (assignee, i) =>
              getDropdownMenu(
                data,
                addAssigneeImgTblImg,
                assignee,
                "METATASK_DROPDOWN_MENU",
                subData,
                metaData
              )
          )
          : null}
      </td>
      // return <td
      //   // dragOver={defaultColumns[metaIndex] === dragOver}
      //   onClick={() => {
      //     setSelectedCalenderTask(metaData);
      //     setAssigneeOpenFlag(false);
      //     setSuperviserOpenFlag(true);
      //   }
      //   } id={"employee-calender-popup" + metaData._id}>
      //   {getSuperviserDropdownMenu(
      //     data,
      //     addSuperviserTblImgIcon,
      //     NOT_DEFINED_VALUE,
      //     "METATASK_DROPDOWN_MENU",
      //     subData,
      //     metaData
      //   )}

      //   {typeof metaData.Superviser !==
      //     "undefined" && metaData.Superviser
      //     ? metaData.Superviser.map(
      //       (assignee, i) =>
      //         getSuperviserDropdownMenu(
      //           data,
      //           addSuperviserImgTblImg,
      //           assignee,
      //           "METATASK_DROPDOWN_MENU",
      //           subData,
      //           metaData
      //         )
      //     )
      //     : null}
      // </td>
    }
    if (headerName === "ASSIGNEE") {
      return <td
        // dragOver={defaultColumns[metaIndex] === dragOver}
        onClick={() => {
          setSelectedCalenderTask(metaData)
          setAssigneeOpenFlag(true);
          setSuperviserOpenFlag(false);
        }
        } id={"employee-calender-popup" + metaData._id}>
        {getDropdownMenu(
          data,
          addAssigneeTblImgIcon,
          NOT_DEFINED_VALUE,
          "METATASK_DROPDOWN_MENU",
          subData,
          metaData
        )}

        {typeof metaData.Assignee !==
          "undefined" && metaData.Assignee
          ? metaData.Assignee.map(
            (assignee, i) =>
              getDropdownMenu(
                data,
                addAssigneeImgTblImg,
                assignee,
                "METATASK_DROPDOWN_MENU",
                subData,
                metaData
              )
          )
          : null}
      </td>
    }
    if (headerName === "DUE DATE") {
      return <td
        // dragOver={defaultColumns[metaIndex] === dragOver}
        className="cursor-pointer">
        <DatePicker
          className={
            (new Date(metaData.DueDate).getTime() == new Date().getTime())
              ? "taskdetail-datepicker-blue text-center" :
              (new Date(metaData.DueDate) < new Date()) ?
                "taskdetail-datepicker-red text-center" : (new Date(metaData.DueDate) > new Date()) ?
                  "taskdetail-datepicker-green text-center" : ''
            // Date.now() -
            //   Date.parse(
            //     metaTask.DueDate
            //   ) >
            //   0
            //   ? "taskdetail-datepicker-red text-center"
            //   : (Date.parse(
            //     metaTask.DueDate
            //   ) -
            //     Date.now()) /
            //     1000 <
            //     172800
            //     ? "taskdetail-datepicker-green text-center"
            //     : "taskdetail-datepicker-blue text-center"
          }
          selected={Date.parse(
            metaData.DueDate
          )}
          onChange={(evnt) =>
            updateDate(
              evnt,
              data,
              subData,
              metaData,
              "UPDATE_METASUBTASK_DATE"
            )
          }
          dateFormat="MM/dd/yyyy"
          popperPlacement="bottom"
          popperModifiers={{
            flip: {
              behavior: ['bottom'] // don't allow it to flip to be above
            },
            preventOverflow: {
              enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
            },
            hide: {
              enabled: false // turn off since needs preventOverflow to be enabled
            }
          }}
        />
      </td>
    }
    if (headerName === "PRIORITY") {
      return <td
        // dragOver={defaultColumns[metaIndex] === dragOver}
        className="cursor-pointer">
        <UncontrolledDropdown className="transparent-dropdown">
          <DropdownToggle>
            {metaData.Priority
              .name == "" ? (
              <img
                src={tag}
                className="task-table-icon"
              />
            ) : (
              <FontAwesomeIcon
                style={{
                  color:
                    metaData
                      .Priority
                      .color,
                }}
                icon={solidFlag}
              />
            )}
          </DropdownToggle>
          <DropdownMenu>
            {priorityValues.map(
              (value, i) => {
                return (
                  <DropdownItem
                    id={'priority-click' + metaData._id + value.name}
                    onClick={(
                      evnt
                    ) =>
                      updatePriority(
                        value,
                        data,
                        subData,
                        metaData,
                        "UPDATE_METASUBTASK_PRIORITY"
                      )
                    }
                  >
                    <FontAwesomeIcon
                      style={{
                        color:
                          value.color,
                        marginRight:
                          "5px",
                      }}
                      icon={
                        solidFlag
                      }
                    />{" "}
                    {value.name}
                  </DropdownItem>
                );
              }
            )}
            <DropdownItem
              onClick={(evnt) => {
                let value = {
                  name: "",
                  color: "#000",
                };
                updatePriority(
                  value,
                  data,
                  subData,
                  metaData,
                  "UPDATE_METASUBTASK_PRIORITY"
                );
              }}
            >
              <FontAwesomeIcon
                icon={faTimes}
                style={{
                  color: "red",
                  marginRight:
                    "10px",
                }}
              />
              CLEAR
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </td>
    }
    if (headerName === "TIME ESTIMATE") {
      return <td
        // dragOver={defaultColumns[metaIndex] === dragOver}
        id={`updateMetaTimeEstimate${metaData._id}`}
        onClick={() => {
          if (!openTimeEstimatePopUp) {
            setOpenTimeEstimatePopUp(true);
            setSelectedTaskId(
              metaData._id
            );

            let obj =
              getTimeEstimateValue(
                metaData.TimeEstimateValue
              );
            setTimeEstimateValue(
              obj.hrs
            );
            setTimeMinuteValue(
              obj.mins
            );
          }
        }}
      >
        {getTymDetails(
          metaData.TimeEstimateValue,
          taskEstHr,
          taskEstMin
        )}
        <UncontrolledPopover
          isOpen={
            selectedTaskId ===
            metaData._id
          }
          className="time_est_popover"
          toggle={
            updateMetaToggle
          }
          trigger="legacy"
          placement="bottom"
          target={`updateMetaTimeEstimate${metaData._id}`}
        >
          <PopoverBody className="m-2">
            <b className="taskstatus-heading-right">
              Time Estimate :
            </b>
            <div className="time_est_fields">
              <div className="time_fields">
                <Input
                  type="text"
                  placeholder="00"
                  value={timeEstimateValue.length == 1 ? '0' + timeEstimateValue : timeEstimateValue}
                  onChange={(e) => {
                    if (e.target.value.length >= 2) {
                      let strmeta1 = e.target.value.replace(/^0+/, '');
                      setTimeEstimateValue(strmeta1);
                    }
                    else {
                      setTimeEstimateValue(
                        e.target
                          .value
                      )
                    }
                  }
                  }
                  onKeyDown={(
                    event
                  ) =>
                    metaEnterTimeEstimate(
                      event,
                      "updateList",
                      data,
                      subData,
                      metaData
                    )
                  }
                />
                <span className="time_unit">
                  hrs
                </span>
              </div>
              <div className="time_fields ml-2">
                <Input
                  type="text"
                  placeholder="00"
                  value={timeMinuteValue.length == 1 ? '0' + timeMinuteValue : timeMinuteValue}
                  onChange={(e) => {
                    if (e.target.value.length >= 2) {
                      let strmeta2 = e.target.value.replace(/^0+/, '');
                      setTimeMinuteValue(strmeta2)
                    }
                    else {
                      setTimeMinuteValue(e.target.value)
                    }
                  }

                  }
                  onKeyDown={(
                    event
                  ) =>
                    metaEnterTimeEstimate(
                      event,
                      "updateList",
                      data,
                      subData,
                      metaData
                    )
                  }
                />
                <span className="time_unit">
                  min
                </span>
              </div>
            </div>
            <div className="text-right mt-2"></div>
          </PopoverBody>
        </UncontrolledPopover>
      </td>
    }
    if (headerName === "PROGRESS") {
      return <td
        // dragOver={defaultColumns[metaIndex] === dragOver}
        className="task-progress">
        <div className="progress-wrapper">
          <ProgressBar now={75} />{" "}
        </div>
        <span>75%</span>
      </td>
    }
    if (headerName === "STATUS") {
      return <td
        // dragOver={defaultColumns[metaIndex] === dragOver}
        className="status cursor-pointer">
        <UncontrolledDropdown>
          <DropdownToggle
            style={{
              backgroundColor:
                metaData.Status
                  .color,
            }}
          >
            {metaData.Status.name}
          </DropdownToggle>
          <DropdownMenu>
            {statusValues.map(
              (value, i) => {
                return (
                  <DropdownItem
                    id={"status-click-id" + metaData._id + value.name}
                    style={{
                      backgroundColor:
                        value.color,
                      color:
                        "#fff",
                    }}
                    onClick={async () => {
                      if (await GetAndCheckTaskFieldMandtoryToComplete(metaData._id) && (value.name == 'COMPLETED')) {
                        setCheckStatusToComplete(true);
                        setTaskCompleteWarning(true);
                        let obj = { ...taskStatusUpdateObj };
                        obj.first = value
                        obj.second = data;
                        obj.third = subData;
                        obj.forth = metaData;
                        obj.fifth = "UPDATE_METASUBTASK_STATUS"
                        setTaskStatusUpdateObj(obj);
                        setTaskStatusUpdateWhere('table');
                      } else {
                        updateStatus(
                          value,
                          data,
                          subData,
                          metaData,
                          "UPDATE_METASUBTASK_STATUS"
                        )
                      }
                    }

                    }
                  >
                    {value.name}
                  </DropdownItem>
                );
              }
            )}
          </DropdownMenu>
        </UncontrolledDropdown>
      </td>
    }
    if (headerName === "PIPELINE") {
      return <td
        // dragOver={defaultColumns[metaIndex] === dragOver}
        className="subtask-cat">{metaData.Pipeline}</td>

    }
    if (headerName === "CATEGORY") {
      return <td
        // dragOver={defaultColumns[metaIndex] === dragOver}
        className="subtask-cat">{metaData.Category}</td>
    }
    if (headerName === "MILESTONE") {
      return <td
        // dragOver={defaultColumns[metaIndex] === dragOver}
        className="subtask-cat">{metaData.isMilestone ? metaData.Milestone.Status : ''}</td>
    }
  }

  const handleDragStart = (e) => {
    const colData = [...defaultColumns];
    const { id } = e.target;
    const idx = colData.indexOf(id);
    e.dataTransfer.setData("colIdx", idx);
  };

  const handleDragOver = (e) => e.preventDefault();
  const handleDragEnter = (e) => {
    const { id } = e.target;
    setDragOver(id);
  };

  const handleOnDrop = (e, value) => {
    const { id } = e.target;
    // const droppedColIdx = id;
    let arr = [];
    let defColData = [...defaultColumns]
    const droppedColIdx = defColData.indexOf(id);
    // const draggedColIdx =  defaultColumns.findIndex((x) => x.headerName === value.headerName);
    const draggedColIdx = e.dataTransfer.getData("colIdx");
    const dragIdx = parseInt(draggedColIdx);
    const tempCols = [...addColumns];

    tempCols[dragIdx].headerName = defColData[droppedColIdx];
    tempCols[droppedColIdx].headerName = defColData[dragIdx];
    tempCols.forEach(e =>
      arr.push(e.headerName)
    )


    // defColData[dragIdx] = tempCols[droppedColIdx].headerName;
    // defColData[droppedColIdx] = tempCols[dragIdx].headerName;

    setDefaultColumns(arr);
    setAddColumns(tempCols);
    setDragOver("");
  };

  const handleOnChangeAddColumn = (name, checked) => {
    const columns = [

      ...addColumns];
    if (name === "Select All") {
      setCheckAllColumns(checked)
      addColumns.forEach((col, i) => {
        columns[i].isVisible = checked;
      });
    }
    else {
      setCheckAllColumns(false);
      columns.filter(one => one.headerName == name)[0].isVisible = checked;

    }
    // columns.filter(one => one.headerName == name)[0].isVisible = checked;
    setAddColumns(columns);
  }
  // const handleOnChangeAddColumn = (name, checked) => {
  //   const columns = [...addColumns];
  //   columns.filter(one => one.headerName == name)[0].isVisible = checked;
  //   setAddColumns(columns);
  // }

  const [timetrackedPopover, settimetrackedPopover] = useState(false);
  const timetrackedtoggle = () => settimetrackedPopover(!timetrackedPopover);
  const [key, setKey] = useState("timer");

  const [priorityValue, setPriorityValue] = useState({
    name: "",
    color: "#000",
  });
  const [subtaskPriority, setSubtaskPriority] = useState({
    name: "",
    color: "#000",
  });
  const [subtaskContainer, toggleSubtaskContainer] = useState(false);
  const [updateSubtaskId, setUpdateSubtaskId] = useState("");
  const [openmodalType, setOpenModalType] = useState("task");

  const [selectionRange, setSelectionRange] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);

  const [DisplayStartDueDate, setDisplayStartDueDate] = useState("");

  const setDateDisplay = (selection) => {
    let mm = selection.startDate.getUTCMonth() + 1; //months from 1-12
    let dd = selection.startDate.getDate();
    let yy = selection.startDate.getUTCFullYear();

    dd = dd < 10 ? "0" + dd : dd;
    mm = mm < 10 ? "0" + mm : mm;

    let display = dd + "/" + mm + "/" + yy;

    mm = selection.endDate.getUTCMonth() + 1; //months from 1-12
    dd = selection.endDate.getDate();
    yy = selection.endDate.getUTCFullYear();

    dd = dd < 10 ? "0" + dd : dd;
    mm = mm < 10 ? "0" + mm : mm;

    display = display + " - " + dd + "/" + mm + "/" + yy;
    setDisplayStartDueDate(display);
    return display;
  };

  const changeDateRange = (item) => {

    let dueDateData = { ...prepareTaskData() };
    setSelectionRange([item.selection]);
    let dueDateRange = setDateDisplay(item.selection);
    dueDateData.StartDate = item.selection.startDate;
    dueDateData.DueDate = item.selection.endDate;
    addUpdateTaskApi(dueDateData);

    let obj = {};
    obj.PrevState = dueDateData.StartDate;
    // prepareTaskData()["DueDate"] = item.selection.endDate;
    obj.UpdatedState = dueDateData.DueDate;
    addActivity("ADD", "DueDate", dueDateData._id, dueDateData.TaskName, 0, obj)
    getAllActivityAsPerTask(dueDateData._id);
  };
  const [newTaskData, setNewTaskData] = useState(null)
  const [newSubTask, setNewSubTask] = useState(false);
  const [newMetaTask, setNewMetaTask] = useState(false);
  const [activityList, setActivityList] = useState([]);



  const getAllActivityAsPerTask = async (TaskId) => {

    HttpService.getByBoj('getActivityTime', { UserId: localStorage.getItem('UserId') })
      .then((result) => {
        let renderTasksActivity = result.data.filter(e => e.TaskId === TaskId);
        setActivityList(renderTasksActivity);

      })
      .catch((err) => {
        // swal(`${err}`);
      })

  }

  const sendNotificationToUser = async (userId, userName, type, role, taskId, selectedTask) => {
    const notificationObj = {
      DataType: 'Task',
      NotificationType: type,
      SenderId: localStorage.getItem("UserId"),
      SenderName: localStorage.getItem("UserName"),
      SenderRole: localStorage.getItem("Role"),
      TableId: taskId,
      ReceiverId: userId,
      ReceiverName: userName,
      ReceiverRole: role,
      RequestId: selectedTask,
      isSeen: false,
      isClick: false,
    }
    HttpService.getByBoj("addnotification", notificationObj)
      .then((result) => {
      
      })
      .catch((err) => {
        //swal(`${err}`);
      })
  }

  const getStatementForTask = (one) => {
    if (one.Action.DataType === "TaskField") {
      if (one.Action.ActionType === "ADD") {
        return <span>You added TaskField {one.Action.Data.UpdatedState.Name ? one.Action.Data.UpdatedState.Name : ""}</span>
      }
      else if (one.Action.ActionType === "Update") {
        return <span>You updated {one.Action.Data.PrevState.Name}{' '}
          {one.Action.Data.PrevState.Value ? one.Action.Data.PrevState.Value.toString() : " empty "} to
          {" "}{one.Action.Data.UpdatedState.Value ? one.Action.Data.UpdatedState.Value.Value.toString() : ""}
        </span>
      }
    }
    else if (one.Action.DataType === "Attachment") {
      return <span>You added {" "} {one.Action.DataType} {" "} {one.Action.Data.fileName}</span>
    }
    else if (one.Action.DataType === "Milestone") {
      return (
        <span><small>You</small> {one.Action.Data.UpdatedState ? 'mark' : 'unmark'} this task as a milestone</span>
      )
    }

    else if (one.Action.DataType === "TASK") {
      if (one.Action.ActionType == 'ADD') {
        return (
          <span><small>You</small> created {one.TaskName}</span>
        )
      } else {
        return (
          <span><small>You</small> {one.Action.ActionType.toLowerCase()} {one.TaskName}</span>
        )
      }

    }
    else if (one.Action.DataType === "Assignee") {
      return (
        <span><small>You</small> {one.Action.ActionType.toLowerCase() + 'ed'} {one.Action.DataType} {one.Action.Data.userName}</span>
      )
    }

    else if (one.Action.DataType === "TimeEstimate") {
      // if()
      return <span><small>You</small> added {one.Action.DataType} {" "} {one.Action.Data.PrevState}
        {/* {one.Action.Data.UpdatedState} */}
      </span>
    }

    else if (one.Action.DataType === "DueDate") {
      return (
        <span><small>You</small> added Start Date {getDate(one.Action.Data.PrevState)} to
          Due Date {getDate(one.Action.Data.UpdatedState)}</span>
      )
    }
    else if (one.Action.DataType === "Status") {
      return (
        <span><small>You</small> set {one.Action.DataType.toLowerCase()}
          <span className="name-tasklist-label" style={{ backgroundColor: one.Action.Data.PrevStatus.color, color: 'white', borderRadius: 5, padding: 3, marginLeft: 5, marginRight: 10 }}> {one.Action.Data.PrevStatus.name}
          </span>to
          <span className="name-tasklist-label" style={{ borderRadius: 5, padding: 3, backgroundColor: one.Action.Data.UpdatedState.color, color: 'white', marginLeft: 10 }}>{one.Action.Data.UpdatedState.name}
          </span>
        </span>
      )
    }
    else if (one.Action.DataType === "Priority") {

      if (one.Action.Data.PrevState.name) {
        return (
          <span><small>You</small> set {one.Action.DataType.toLowerCase()}  {one.Action.Data.PrevState.name ? one.Action.Data.PrevState.name : 'Unknown'}  {
            <span className="updatedPriorityIcon">
              <FontAwesomeIcon color={one.Action.Data.PrevState.color} icon={faFlagSolid} />
            </span>
          }    to    {one.Action.Data.UpdatedState.name}
            {
              <span className="updatedPriorityIcon">
                <FontAwesomeIcon color={one.Action.Data.UpdatedState.color} icon={faFlagSolid} />
              </span>
            } </span>
        )
      }


      else {
        return <span><small>You</small> set {one.Action.DataType.toLowerCase()}    to    {one.Action.Data.UpdatedState.name}  {<FontAwesomeIcon color={one.Action.Data.UpdatedState.color} icon={faFlagSolid} />} </span>
      }

    }
    else if (one.Action.DataType === "Category") {
      return (
        <span><small>You</small> set {one.Action.DataType.toLowerCase()}    {one.Action.Data.PrevState ? one.Action.Data.PrevState : 'Unknown'}        to    {one.Action.Data.UpdatedState}   </span>
      )
    }
    else if (one.Action.DataType === "CheckList") {
      if (one.Action.Data.PrevCheckList) {
        return (
          <span><small>You</small> {one.Action.ActionType.toLowerCase()}  {one.Action.DataType.toLowerCase()}    {one.Action.Data.PrevCheckList.Label ? one.Action.Data.PrevCheckList.Label : 'Unknown'}        to    {one.Action.Data.UpdatedCheckList.Label}   </span>
        )
      } else {
        return (
          <span><small>You</small> set {one.Action.DataType.toLowerCase()} to {one.Action.Data.UpdatedCheckList.Label}   </span>
        )
      }
    }
    else {
      return (
        <span><small>You</small> set to {one.Action.DataType}</span>
      )
    }
  }

  const saveUpdateSubTask = () => {
    var data = subTasks;
    const uniqueId = uuidv4();

    const subtask = {
      DealsId: DealsId,
      //SubtaskId: updateSubtaskId == "" ? uniqueId : updateSubtaskId,
      TaskName: subtaskName,
      Status: subtaskStatus,
      Priority: subtaskPriority,
      Assignee: subtaskAssignee,
      Description: "",
      StartDate: selectionRange[0].startDate,
      DueDate: selectionRange[0].endDate,
      TimeEstimateValue:
        updateSubtaskId == ""
          ? ':'
          : parseInt(timeEstimateValue) <= 0
            ? timeMinuteValue.toString()
            : timeEstimateValue + ":" + timeMinuteValue,
      TimeTrack: updateSubtaskId == "" ? [] : currentData,
      Checklist: updateSubtaskId == "" ? [] : currentCheckList,
      Subtask: [],
    };
    if (updateSubtaskId == "") {
      data.push(subtask);
    } else {
      data.forEach((e, i) => {
        if (e.SubtaskId == updateSubtaskId) {
          data[i] = subtask;
        }
      });
    }

    setNewSubTask(true);
    setNewTaskData(subtask)
    // addUpdateTaskApi(subtask);
    setOpenSubtaskPopup(false)
    saveUpdateTaskData(subtask);
    setSubTasks(data);
    resetSubtaskFields();
  };

  const getTymDetails = (estimateVal, taskEstHr, taskEstMin) => {
    if (estimateVal == ":") {
      return <span className="hourglass-border">
        <FontAwesomeIcon icon={faHourglass} />
      </span>
      //return "00h 00m";
    }
    if ((taskEstHr == "00" || taskEstHr == "0") && (taskEstMin == "00" || taskEstMin == "0")) {
      return <span className="hourglass-border">
        <FontAwesomeIcon icon={faHourglass} />
      </span>
    }
    if (taskEstHr == "00" || taskEstHr == "0") {
      return taskEstMin + "m";
    }
    if (taskEstMin == "00" || taskEstMin == "0") {
      return taskEstHr + "h";
    }

    // if (taskEstHr.length == 1) {
    //   taskEstHr = '0' + taskEstHr
    // }
    // if (taskEstMin.length == 1) {
    //   taskEstMin = '0' + taskEstMin
    // }

    return taskEstHr + "h " + taskEstMin + "m";
  };


  const getEstimateDuration = (startDate, timeEstimate) => {
    let obj = { start: new Date(startDate), end: new Date(startDate) }
    //obj.start.setMilliseconds(obj.start.getMilliseconds()+1);
    timeEstimate = timeEstimate.split(':');
    obj.end.setHours(obj.end.getHours() + parseInt(timeEstimate[0]));
    obj.end.setMinutes(obj.end.getMinutes() + parseInt(timeEstimate[1]));
    return obj;
  }

  const getUserDayShift = (date, user) => {
    date = new Date(date);
    let workingDays = user.WorkingDays;
    let dayShift = workingDays.filter((one) => one.Day == days[date.getDay()])
    if (dayShift.length > 0) {
      return dayShift[0];
    } else {
      return null
    }
  }

  const getTaskDocs1 = async () => {
    let res = await HttpService.getByBoj("gettaskdocs", { DealsId: props.dealsId });
    return res.data.Data;
  }

  const checkTaskIsWorkingSwitchToAnotherTask = (tasks) => {
    let previousTask = []
    let lastestTask = tasks.filter((one) => {
      if (one.Assignee.length == 0) {
        return one;
      } else {
        let obj = getEstimateDuration(new Date(one.AssignDatetime), one.TimeEstimateValue);
        if ((obj.start >= new Date()) && (obj.end >= new Date())) {
          return one
        } else {
          previousTask.push(one);
        }
      }

    })
    return { prev: previousTask, latest: lastestTask, prevEvent: getCalenderEvents(previousTask) }
  }

  const checkTaskIsWorkingSwitchToAnotherTaskWithInProgress = (tasks) => {
    let previousTask = []
    let lastestTask = tasks.filter((one) => {
      if (one.Assignee.length == 0) {
        return one;
      } else {
        let obj = getEstimateDuration(new Date(one.AssignDatetime), one.TimeEstimateValue);
        if ((obj.start >= new Date()) && (obj.end >= new Date())) {
          return one

        } else {
          if ((one.Status.name == 'IN PROGRESS')) {
            previousTask.push(one);
          } else {
            return one
          }

        }
      }

    })
    return { prev: previousTask, latest: lastestTask, prevEvent: getCalenderEvents(previousTask) }
  }

  const getLatestAndPreviousTaskBasedOnAssignDateTime = (tasks, selectedTask) => {
    let previousTask = []
    let lastestTask = tasks.filter((one) => {
      if (one.Assignee.length == 0) {
        return one;
      } else {
        if (new Date(one.AssignDatetime) > new Date(selectedTask.AssignDatetime)) {
          return one
        } else {
          previousTask.push(one);
        }
      }

    })
    return { prev: previousTask, latest: lastestTask, prevEvent: getCalenderEvents(previousTask) }
  }

  const assignTaskByOneSync = async (assignee, role, docs) => {
    let tasks = [...docs];
    let taskObj = checkTaskIsWorkingSwitchToAnotherTaskWithInProgress(tasks);
    tasks = taskObj.latest;
    tasks.forEach(one => { one.Assignee = [] });
    let returnresult = 'Update';
    let events = calenderEvents.filter(one => one.Assignee.filter(a => a.userId !== assignee.userId).length > 0);
    events = taskObj.prevEvent;;
    for (let i = 0; i < tasks.length; i++) {
      let dateTime1 = checkValidateTimeEstimateForTask(assignee, { ...tasks[i] }, events);
      if (dateTime1) {
        tasks[i].AssignDatetime = dateTime1;
      } else {
        returnresult = null
        swal('No available time slot till the task due date. If you want to assign increase the due date');
        break
      }

      tasks[i].Assignee.push(assignee);
      // if ((tasks[i].Status.name == 'PENDING') && (tasks[i].Assignee.length > 0)) {
      //   tasks[i].Status = { name: "NOT STARTED", color: "#f8d800" }
      // }
      let res = await updateTask_Single(tasks[i]);
      // let taskDocs1=await getTaskDocs1();
      let arr = getCalenderEvents(tasks);
      events = [...arr, ...events];

      // let usersData = cacheUsersArr.filter(e => e.UserId != userId);
      // // setUsersArr(usersData);
      // setAllUsers(usersData)



    }


    return returnresult


  }

  const taskReAssign = (assignee, task) => {
    return new Promise((resolve, reject) => {
      let tasks = [...taskDocs];
      tasks = tasks.filter((t) => { if (t.Assignee.filter(a => a.userId == assignee.userId).length > 0) { return t } })
      tasks.unshift(task);
      tasks.forEach((one) => {
        if (one._id == task._id) {
          one.TimeEstimateValue = task.TimeEstimateValue;
        }
      })
      assignTaskByOneSync2(assignee, 'role', tasks, task)
        .then(async (result) => {
          await getallTasks();
          resolve(result);
        })
        .catch((err) => {
          reject(`${err}`);
        })
    })

  }

  const assignTaskByOneSync1 = async (assignee, role, docs, eventsTask) => {
    let tasks = [...docs];
    let taskObj = checkTaskIsWorkingSwitchToAnotherTask(tasks);
    tasks = taskObj.latest;
    tasks.forEach(one => { one.Assignee = [] });
    let returnresult = 'Update';
    let events = getCalenderEvents([eventsTask])//calenderEvents.filter(one=>one.Assignee.filter(a=>a.userId!==assignee.userId).length>0);
    //events=taskObj.prevEvent;;
    for (let i = 0; i < tasks.length; i++) {
      let dateTime1 = checkValidateTimeEstimateForTask(assignee, { ...tasks[i] }, events);
      if (dateTime1) {
        tasks[i].AssignDatetime = dateTime1;
      } else {
        returnresult = null
        swal('No available time slot till the task due date. If you want to assign increase the due date');
        break
      }

      tasks[i].Assignee.push(assignee);
      // if ((tasks[i].Status.name == 'PENDING') && (tasks[i].Assignee.length > 0)) {
      //   tasks[i].Status = { name: "NOT STARTED", color: "#f8d800" }
      // }
      let res = await updateTask_Single(tasks[i]);
      // let taskDocs1=await getTaskDocs1();
      let arr = getCalenderEvents(tasks);
      events = [...arr, ...events];

      // let usersData = cacheUsersArr.filter(e => e.UserId != userId);
      // // setUsersArr(usersData);
      // setAllUsers(usersData)



    }


    return returnresult


  }


  const assignTaskByOneSync2 = async (assignee, role, docs, selectedTask) => {
    let tasks = [...docs];
    let taskObj = getLatestAndPreviousTaskBasedOnAssignDateTime(tasks, selectedTask);
    tasks = taskObj.latest;
    tasks = tasks.sort((a, b) => new Date(a.AssignDatetime) - new Date(b.AssignDatetime))
    tasks.forEach(one => { one.Assignee = [] });
    let returnresult = 'Update';
    let events = []//calenderEvents.filter(one=>one.Assignee.filter(a=>a.userId!==assignee.userId).length>0);
    events = taskObj.prevEvent;;
    for (let i = 0; i < tasks.length; i++) {
      let dateTime1 = checkValidateTimeEstimateForTask(assignee, { ...tasks[i] }, events);
      if (dateTime1) {
        tasks[i].AssignDatetime = dateTime1;
      } else {
        returnresult = null
        swal('No available time slot till the task due date. If you want to assign increase the due date');
        break
      }

      tasks[i].Assignee.push(assignee);
      // if ((tasks[i].Status.name == 'PENDING') && (tasks[i].Assignee.length > 0)) {
      //   tasks[i].Status = { name: "NOT STARTED", color: "#f8d800" }
      // }
      let res = await updateTask_Single(tasks[i]);
      // let taskDocs1=await getTaskDocs1();
      let arr = getCalenderEvents(tasks);
      events = [...arr, ...events];

      // let usersData = cacheUsersArr.filter(e => e.UserId != userId);
      // // setUsersArr(usersData);
      // setAllUsers(usersData)



    }


    return returnresult


  }

  const checkAndRescheduleTaskFromPopover = (assignee, role, rescheduleTask, task) => {
    return new Promise((resolve, reject) => {
      assignTaskByOneSync1(assignee, role, rescheduleTask, task)
        .then(async (result) => {
          await getallTasks();
          await addActivity('Update', "Assignee", task._id, task.TaskName, 0, assignee);
          await getAllActivityAsPerTask(task._id);
          await sendNotificationToUser(assignee.userId, assignee.userName, 'Assign', role, task._id, [{ TaskUniqueId: task.UniqueId, DealName: props.dealObj.ProjectNumber, DealId: props.dealObj.UniqueId, TaskId: task._id, TaskName: task.TaskName }]);
          resolve('Update');
        })
        .catch((err) => {
          reject(`${err}`);
        })
    })

  }

  const checkValidateTimeEstimateForTaskAndPriority = (assignee, role, task) => {
    return new Promise((resolve, reject) => {
      let tasks = [...taskDocs];
      tasks = tasks.filter((t) => { if (t.Assignee.filter(a => a.userId == assignee.userId).length > 0) { return t } })
      tasks = tasks.sort((a, b) => new Date(a.AssignDatetime) - new Date(b.AssignDatetime))
      tasks.unshift(task);
      assignTaskByOneSync(assignee, role, tasks)
        .then(async (result) => {
          await getallTasks();
          await addActivity('Update', "Assignee", task._id, task.TaskName, 0, assignee);
          await getAllActivityAsPerTask(task._id);
          await sendNotificationToUser(assignee.userId, assignee.userName, 'Assign', role, task._id, [{ TaskUniqueId: task.UniqueId, DealName: props.dealObj.ProjectNumber, DealId: props.dealObj.UniqueId, TaskId: task._id, TaskName: task.TaskName }]);
          resolve('Update');
        })
        .catch((err) => {
          reject(`${err}`);
        })
    })

  }

  const checkValidateTimeEstimateForTask = (assignee, task, events) => {
    const users = [...allUsers]
    let startDateTime = new Date(task.StartDate);
    let taskScanFrom = new Date();
    let taskScanTill = new Date(task.DueDate);
    let returnResult = null;
    if (false) {
      //return true;
    } else {
      //let assignee = task.Assignee[0];
      let filterUser = users.filter(user => user.UserId == assignee.userId);

      if (filterUser.length > 0) {
        let shiftStart = new Date(taskScanFrom);
        let dayShift = getUserDayShift(shiftStart, filterUser[0]);

        let inTime = dayShift.InTime
        inTime = inTime.split(':');
        shiftStart.setHours(parseInt(inTime[0]), parseInt(inTime[1]), 0, 0);


        let shiftEnd = new Date(taskScanFrom);
        let outTime = dayShift.OutTime;
        outTime = outTime.split(':');
        shiftEnd.setHours(parseInt(outTime[0]), parseInt(outTime[1]), 0, 0);



        if ((taskScanFrom >= shiftStart) && (taskScanFrom >= shiftEnd)) {
          taskScanFrom.setDate(taskScanFrom.getDate() + 1);
          shiftStart.setDate(shiftStart.getDate() + 1);
          shiftEnd.setDate(shiftEnd.getDate() + 1);

          let dayShift2 = getUserDayShift(taskScanFrom, filterUser[0]);

          let inTime2 = dayShift2.InTime
          inTime2 = inTime2.split(':');
          shiftStart.setHours(parseInt(inTime2[0]), parseInt(inTime2[1]), 0, 0);

          let outTime2 = dayShift2.OutTime;
          outTime2 = outTime2.split(':');
          shiftEnd.setHours(parseInt(outTime2[0]), parseInt(outTime2[1]), 0, 0);

          taskScanFrom.setHours(parseInt(inTime2[0]), parseInt(inTime2[1]), 0, 0);
        }


        if ((taskScanFrom <= shiftStart) && (taskScanFrom <= shiftEnd)) {
          taskScanFrom.setHours(parseInt(inTime[0]), parseInt(inTime[1]), 0, 0);
        }

        let breakStart = new Date(shiftStart);
        let breakEnd = new Date(shiftEnd);
        let lunchStart = dayShift.LunchStartTime.split(':');
        let lunchEnd = dayShift.LunchEndTime.split(':');
        breakStart.setHours(parseInt(lunchStart[0]), parseInt(lunchStart[1]), 0, 0);
        breakEnd.setHours(parseInt(lunchEnd[0]), parseInt(lunchEnd[1]), 0, 0);



        while (taskScanFrom < taskScanTill) {

          let timeEstimateFrameObj = getEstimateDuration(taskScanFrom, task.TimeEstimateValue)
          if ((shiftStart <= timeEstimateFrameObj.start) && (timeEstimateFrameObj.end <= shiftEnd)) {
            // same as task date
            let flagEvent = userSlotBookedOrNot(new Date(timeEstimateFrameObj.start), new Date(timeEstimateFrameObj.end), assignee.userId, events)
            if ((!flagEvent.flag)) {
              if (((breakStart < timeEstimateFrameObj.start) && (timeEstimateFrameObj.start < breakEnd))) {
                taskScanFrom = breakEnd;
              } else {
                if (flagEvent.holiday) {
                  taskScanFrom.setDate(taskScanFrom.getDate() + 1);

                  let dayShift1 = getUserDayShift(taskScanFrom, filterUser[0]);

                  let inTime1 = dayShift1.InTime
                  inTime1 = inTime1.split(':');

                  let outTime1 = dayShift1.OutTime
                  outTime1 = outTime1.split(':');

                  let lunchStart1 = dayShift.LunchStartTime.split(':');
                  let lunchEnd1 = dayShift.LunchEndTime.split(':');







                  shiftStart.setDate(shiftStart.getDate() + 1);
                  shiftStart.setHours(parseInt(inTime1[0]), parseInt(inTime1[1]), 0, 0);

                  shiftEnd.setDate(shiftEnd.getDate() + 1);
                  shiftEnd.setHours(parseInt(outTime1[0]), parseInt(outTime1[1]), 0, 0);

                  breakStart.setDate(breakStart.getDate() + 1);
                  breakStart.setHours(parseInt(lunchStart1[0]), parseInt(lunchStart1[1]), 0, 0);

                  breakEnd.setDate(breakEnd.getDate() + 1);
                  breakEnd.setHours(parseInt(lunchEnd1[0]), parseInt(lunchEnd1[1]), 0, 0);

                  taskScanFrom.setHours(parseInt(inTime1[0]), parseInt(inTime1[1]), 0, 0);
                } else {
                  returnResult = new Date(timeEstimateFrameObj.start)
                  break;
                }

              }

              //return new Date(task.StartDate)
            } else {
              taskScanFrom.setHours(flagEvent.event.end.getHours(), flagEvent.event.end.getMinutes(), flagEvent.event.end.getSeconds(), flagEvent.event.end.getMilliseconds() + 1)
              returnResult = null;
            }
          } else {
            let flagEvent1 = userSlotBookedOrNot(new Date(timeEstimateFrameObj.start), new Date(timeEstimateFrameObj.end), assignee.userId, events)
            if (!flagEvent1.flag) {
              if (((shiftEnd <= timeEstimateFrameObj.start) && ((shiftEnd <= timeEstimateFrameObj.end))) || flagEvent1.holiday) {
                taskScanFrom.setDate(taskScanFrom.getDate() + 1);

                let dayShift1 = getUserDayShift(taskScanFrom, filterUser[0]);

                let inTime1 = dayShift1.InTime
                inTime1 = inTime1.split(':');

                let outTime1 = dayShift1.OutTime
                outTime1 = outTime1.split(':');

                let lunchStart1 = dayShift.LunchStartTime.split(':');
                let lunchEnd1 = dayShift.LunchEndTime.split(':');







                shiftStart.setDate(shiftStart.getDate() + 1);
                shiftStart.setHours(parseInt(inTime1[0]), parseInt(inTime1[1]), 0, 0);

                shiftEnd.setDate(shiftEnd.getDate() + 1);
                shiftEnd.setHours(parseInt(outTime1[0]), parseInt(outTime1[1]), 0, 0);

                breakStart.setDate(breakStart.getDate() + 1);
                breakStart.setHours(parseInt(lunchStart1[0]), parseInt(lunchStart1[1]), 0, 0);

                breakEnd.setDate(breakEnd.getDate() + 1);
                breakEnd.setHours(parseInt(lunchEnd1[0]), parseInt(lunchEnd1[1]), 0, 0);

                taskScanFrom.setHours(parseInt(inTime1[0]), parseInt(inTime1[1]), 0, 0);
              } else {

                if ((breakStart < timeEstimateFrameObj.start) && (timeEstimateFrameObj.start < breakEnd)) {
                  taskScanFrom = breakEnd;
                } else {
                  returnResult = new Date(timeEstimateFrameObj.start)
                  break;
                }

              }


              //return new Date(task.StartDate)
            } else {
              taskScanFrom.setHours(flagEvent1.event.end.getHours(), flagEvent1.event.end.getMinutes(), flagEvent1.event.end.getSeconds(), (flagEvent1.event.end.getMilliseconds() + 1))
              returnResult = null;
            }

            // returnResult = new Date(timeEstimateFrameObj.start);
            // break;
            // taskScanFrom.setDate(taskScanFrom.getDate() + 1)
            // let timeEstimateFrameObj1 = getEstimateDuration(taskScanFrom, task.TimeEstimateValue)
            // let flagEvent=userSlotBookedOrNot(new Date(timeEstimateFrameObj1.start), new Date(timeEstimateFrameObj1.end), assignee.userId)
            // if (!flagEvent.flag) {
            //   returnResult = new Date(timeEstimateFrameObj1.start);
            //   break;
            // } else {
            //   returnResult = null;
            // }
            // task start date chnage to next date
          }
        }
      } else {
        returnResult = null;
      }
    }
    return returnResult;
  }

  const isNotWorkesd = (userSetting, date) => {
    userSetting = userSetting.filter(obj => obj.Day == days[new Date(date).getDay()]);
    if (userSetting.length > 0) {
      if (userSetting[0].isWorking) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }



  const userSlotBookedOrNot = (Start, End, UserId, caledarEventDoc) => {
    let returnFlag = { flag: false, event: null };
    let filterUser = allUsers.filter((obj) => obj.UserId == UserId);
    if (filterUser.length > 0) {
      if (isNotWorkesd(filterUser[0].WorkingDays, Start)) {
        returnFlag.holiday = true
        return returnFlag;
      }
    }
    if (isHolidayFestival(Start, holidayList)) {
      returnFlag.holiday = true
      return returnFlag;
    }

    let calenderEventList = [...caledarEventDoc];
    calenderEventList = calenderEventList.filter((task) => {
      if (task.Assignee.filter(assignee => assignee.userId == UserId).length > 0) {
        return task;
      }
    })
    if (calenderEventList.length == 0) {
      returnFlag.flag = false;
    } else {
      let filterEvent = calenderEventList.filter((event) => {
        if (((Start >= event.start) && (End <= event.end))) {
          return event;
        } else if (((Start < event.start) && (End >= event.end))) {
          return event;
        }
        else if (((Start <= event.start) && (End <= event.end && End >= event.start))) {
          return event;
        } else if ((((Start >= event.start) && (Start < event.end)) && (End >= event.end))) {
          return event;
        }
        // else if (((Start >= event.start)&&(End>=event.start)) ) {
        //   return event;
        // }

      })
      if (filterEvent.length > 0) {
        returnFlag.flag = true;
        returnFlag.event = filterEvent[0];
      } else {
        returnFlag.flag = false;
      }
    }
    return returnFlag;


  }

  const getTimeEstimateValue = (timeEStimateVal) => {
    let obj = { hrs: '00', mins: '00' };
    // if (
    //   typeof timeEStimateVal !== "string" ||
    //   !timeEStimateVal instanceof String
    // ) {
    //   timeEStimateVal = timeEStimateVal.toString();
    // }
    // if (timeEStimateVal.includes(":")) {
    //   let time = timeEStimateVal.split(":");
    //   obj.hrs = time[0];
    //   obj.mins = time[1];
    // } else {
    //   obj.hrs = "00";
    //   obj.mins = timeEStimateVal;
    // }

    return obj;
  };

  const setStateForUpdateSubtask = (e) => {
    setUpdateSubtaskId(e.SubtaskId);
    setSubtaskName(e.TaskName);
    setSubtaskStatus(e.Status);
    setSubtaskPriority(e.Priority);
    setSubtaskAssignee(e.Assignee);
    toggleSubtaskContainer(false);
  };
  const resetSubtaskFields = () => {
    setSubtaskName("");
    setSubtaskStatus({
      name: "PENDING",
      color: "#e50000",
    });
    setSubtaskPriority({
      name: "",
      color: "#000",
    });
    setSubtaskAssignee([]);
    setUpdateSubtaskId("");
  };



  const getTaskDocs = (taskName, taskId) => {
    let duplicate = {
      TaskName: '',
      Everything: true, Attachements: true, Checklist: true, Comments: true, InputFields: true,
      LookUpFields: true, Dependencies: true, DueDate: true, Assignee: true,
      TimeEstimate: true, Priority: true, Status: true, Subtask: true
    };
    HttpService.getByBoj("gettaskdocs", { DealsId: props.dealsId })
      .then((res) => {
        if (res.data.Data.length > 0) {
          let filterCopyTasks = res.data.Data.filter(one => one.TaskCopyOf == taskId);
          setCopyTasks(filterCopyTasks);
          setTaskDocs(res.data.Data);
          duplicate.TaskName = taskName + ' - Copy' + (filterCopyTasks.length + 1);
          setDuplicateValueObj(duplicate);
        }
      })
      .catch(err => {
        //swal(`${err}`);
      })
  }
  const getallTasks = async () => {
    setLoading(true)
    HttpService.getByBoj("gettask", { DealsId: props.dealsId })
      .then(
        async (res) => {
          if (res.data.length > 0 && res.data != "unsuccesfull") {
            //res.data.forEach((one)=>one.Subtask=[])
            setAllTasks(res.data);
            setAllTaskCache(res.data);
            // if(props.id=='tasklist'){
            //   props.setAllTasks(res.data);
            // }
            let taskDocs = []
            setLoading(true)
            if (parentObj) {
              let query = { DealsId: props.dealsId }
              if (props.id == 'tasklist') {
                query = {}
              }
              let res2 = await HttpService.getByBoj("gettaskdocs", query)
              if (res2.data.Data.length > 0) {
                taskDocs = res2.data.Data;
                let filterCopyTasks = taskDocs.filter(one => one.TaskCopyOf == parentObj._id);
                setCopyTasks(filterCopyTasks);
                setTaskDocs(res2.data.Data);
                let obj = res2.data.Data.filter((one) => one._id == parentObj._id);
                obj = { ...obj[0] }
                // setParentObject(obj);
              }
            } else {
              let query = { DealsId: props.dealsId }
              if (props.id == 'tasklist') {
                query = {}
              }
              let res1 = await HttpService.getByBoj("gettaskdocs", query);
              taskDocs = res1.data.Data;
              setTaskDocs(res1.data.Data);
            }
            TaskIcons(res.data);
            getGroupByFields(res.data, groupBy);

            let t = [];
            taskDocs.forEach((task, i) => {
              t.push({
                id: task._id,
                name: task.TaskName,
                status: task.Status,
              });
              // if (task.Subtask.length > 0) {
              //   //foreach//push
              //   task.Subtask.forEach((subTask, subIndx) => {
              //     t.push({
              //       id: subTask.SubtaskId,
              //       name: subTask.TaskName,
              //       status: subTask.Status,
              //     });
              //     if (subTask.Subtask.length > 0) {
              //       subTask.Subtask.forEach((metaTask, metaIndx) => {
              //         t.push({
              //           id: metaTask.SubtaskId,
              //           name: metaTask.TaskName,
              //           status: metaTask.Status,
              //         });
              //       });
              //     }
              //   });
              // }
            });
            setallTaskList(t);
            setLoading(false);
          }
          else {
            setLoading(false);
          }
        }
      )
      .catch((err) => {
        setLoading(false);
        //swal(`${err}`)
      });
  };

  const getAllUsers = async () => {
    let returnData = await HttpService.getByBoj("getallusers", {}).then(
      (res) => {
        if (res.data.length > 0 && res.data != null) {
          usersDetail = res.data;
          setAllUsers(res.data);
          setCacheUsersArr(res.data);

        }
      }
    );
  };

  // const getSelectedUsers=()=>{
  //   let usersArr = [...allUsers];
  //   usersArr.forEach(e=>e.isSelect=false);
  //   return usersArr;
  // }

  const onlyTypeNumber = (value) => {
    const re = /^[0-9\b]+$/;
    if (value === "" || re.test(value)) {
      setTimeEstimateValue(value);
    }
  };

  const onlyAcceptNumbr = (value) => {
    const re = /^[0-9\b]+$/;
    if (value === "" || re.test(value)) {
      setTimeMinuteValue(value);
    }
  };

  function min2hrConverstion(mins) {
    let num = parseInt(mins);
    let hours = num / 60;
    let rhours = Math.floor(hours);
    let minutes = (hours - rhours) * 60;
    let rminutes = Math.round(minutes);

    let obj = {
      hrs: rhours,
      mint: rminutes,
    };
    return obj;
  }

  const metaEnterTimeEstimate = (
    event,
    operationType,
    taskData,
    subTask,
    metaTask
  ) => {
    if (isRights('Time Estimate')) {
      if (event.key === "Enter") {
        //
        let obj = {};
        let defaultHrEstimate = 0;
        if ((timeMinuteValue !== "") || (timeEstimateValue !== "")) {
          if (timeMinuteValue == "") {
            obj = min2hrConverstion(0);
          } else {
            obj = min2hrConverstion(timeMinuteValue);
          }

          if (timeEstimateValue === "") {
            obj.hrs = parseInt(obj.hrs) + parseInt(defaultHrEstimate);
            setTimeEstimateValue(obj.hrs);
          } else {
            obj.hrs = parseInt(obj.hrs) + parseInt(timeEstimateValue);
            setTimeEstimateValue(obj.hrs);
          }
          setTimeMinuteValue(obj.mint);
        } else {
          setTimeEstimateValue(timeEstimateValue);
          setTimeMinuteValue(timeMinuteValue);
        }
        if (timeMinuteValue === "") {
          setTimeMinuteValue("00");
        }

        if (operationType === "updateList") {
          taskData.Subtask.forEach((updtd, i) => {
            if (updtd.SubtaskId === subTask.SubtaskId) {
              updtd.Subtask.forEach((meta) => {
                if (meta.SubtaskId === metaTask.SubtaskId) {
                  // meta.TimeEstimateValue = obj.hrs+":"+obj.mint;
                  if ((timeMinuteValue == "") && (timeEstimateValue == "")) {
                    meta.TimeEstimateValue = ":"
                  } else {
                    meta.TimeEstimateValue =
                      parseInt(obj.hrs) <= 0 ? obj.hrs + ":" + obj.mint : obj.hrs + ":" + obj.mint;
                  }

                }
              });
            }
          });
          setOpenTimeEstimatePopUp(false);

          updateTask(metaTask);
        }
        setSelectedTaskId("");
        setPopoverOpen(!popoverOpen);
      }
    } else {
      swal("you don't have sufficient privillages to perform this task!", { dangerMode: true });
    }

  };

  const subTaskEnterTimeEstimate = (
    event,
    operationType,
    taskData,
    subTask
  ) => {
    if (isRights('Time Estimate')) {
      if (event.key === "Enter") {
        let obj = {};
        let defaultHrEstimate = 0;
        if ((timeMinuteValue !== "") || (timeEstimateValue !== '')) {
          if (timeMinuteValue == "") {
            obj = min2hrConverstion(0);
          } else {
            obj = min2hrConverstion(timeMinuteValue);
          }

          if (timeEstimateValue === "") {
            obj.hrs = parseInt(obj.hrs) + parseInt(defaultHrEstimate);
            setTimeEstimateValue(obj.hrs);
          } else {
            obj.hrs = parseInt(obj.hrs) + parseInt(timeEstimateValue);
            setTimeEstimateValue(obj.hrs);
          }
          setTimeMinuteValue(obj.mint);
        } else {
          setTimeEstimateValue(timeEstimateValue);
          setTimeMinuteValue(timeMinuteValue);
        }
        if (timeMinuteValue === "") {
          setTimeMinuteValue("00");
        }

        if (operationType === "updateList") {
          taskData.Subtask.forEach((updtd) => {
            if (updtd.SubtaskId === subTask.SubtaskId) {
              if ((timeEstimateValue == "") && (timeMinuteValue == "")) {
                updtd.TimeEstimateValue = ":"
              } else {
                updtd.TimeEstimateValue =
                  parseInt(obj.hrs) <= 0 ? obj.hrs + ":" + obj.mint : obj.hrs + ":" + obj.mint;
              }

            }
          });

          updateTask(subTask);
          setOpenTimeEstimatePopUp(false);
        }

        setSelectedTaskId("");
        setPopoverOpen(!popoverOpen);
      }
    } else {
      swal("you don't have sufficient privillages to perform this task!", { dangerMode: true });
    }

  };

  function tymIsEmptyOrNull(timeEstimateValue, timeMinuteValue) {
    let flag = false;
    if (
      timeMinuteValue === "" &&
      (timeEstimateValue === "" || timeEstimateValue === ":")
    ) {
      flag = true;
    }
    // if( obj.mint !== undefined  || obj.hrs !== undefined){
    //   flag = true;
    // }
    return flag;
  }


  const enterTimeEstimate = (e, operationType, taskData) => {
    if (isRights('Time Estimate')) {
      if (e.key === "Enter") {
        let obj = {};
        let timeObj = {};
        timeObj.PrevState = prepareTaskData().TimeEstimateValue;
        prepareTaskData().TimeEstimateValue = timeEstimateValue + ':' + timeMinuteValue;
        timeObj.UpdatedState = timeEstimateValue + ":" + timeMinuteValue;

        let defaultHrEstimate = 0;
        if ((timeMinuteValue !== "") || (timeEstimateValue !== "")) {
          if (timeMinuteValue == "") {
            obj = min2hrConverstion(0);
          } else {
            obj = min2hrConverstion(timeMinuteValue);
          }

          if (timeEstimateValue === "") {
            obj.hrs = parseInt(obj.hrs) + parseInt(defaultHrEstimate);
            setTimeEstimateValue(obj.hrs);
          } else {
            obj.hrs = parseInt(obj.hrs) + parseInt(timeEstimateValue);
            setTimeEstimateValue(obj.hrs);

          }
          setTimeMinuteValue(obj.mint);
        } else {
          setTimeEstimateValue(timeEstimateValue);
          setTimeMinuteValue(timeMinuteValue);
        }
        if (timeMinuteValue === "") {
          setTimeMinuteValue("00");
        }
       
        if (operationType === "updateList") {
     
          if (!tymIsEmptyOrNull(timeEstimateValue, timeMinuteValue)) {
            taskData.TimeEstimateValue =
              parseInt(obj.hrs) <= 0 ? obj.hrs + ":" + obj.mint : obj.hrs + ":" + obj.mint;
      

            setOpenTimeEstimatePopUp(false);
            updateTask(taskData);
            addActivity("ADD", "TimeEstimate", prepareTaskData()._id, prepareTaskData().TaskName, 0, timeObj)

          } else {
            taskData.TimeEstimateValue = ':'
            setOpenTimeEstimatePopUp(false);
            updateTask(taskData);
            addActivity("ADD", "TimeEstimate", prepareTaskData()._id, prepareTaskData().TaskName, 0, timeObj)
          }
        }

        setSelectedTaskId("");
        setPopoverOpen(!popoverOpen);
      }
    } else {
      swal("you don't have sufficient privillages to perform this task!", { dangerMode: true });
    }


  };
  const [GroupIcons, setGroupIcons] = useState([]);
  const [expandAllGroups, setExpandAllGroups] = useState(false);




  useEffect(() => {
    if ((props.id == 'tasklist') && props.taskFilterSetting && (GroupIcons.length > 0)) {
      setExpandAllGroups(props.taskFilterSetting.ExpandGroups)
    }

  }, [props.taskFilterSetting, GroupIcons])

  useEffect(() => {
    if (expandAllGroups) {
      const arr = [...GroupIcons];
      arr.forEach((one) => {
        one.isOpen = true
      })
      setGroupIcons(arr)
    } else {
      const arr = [...GroupIcons];
      arr.forEach((one) => {
        one.isOpen = false
      })
      setGroupIcons(arr)
    }
  }, [expandAllGroups])

  useEffect(() => {
    if ((props.id == 'tasklist') && props.taskFilterSetting && (groupByField.length > 0)) {
      setExpandAllTaskState(props.taskFilterSetting.ExpandTasks)
      setExpandDealTask(props.taskFilterSetting.ExpandDealTask);
      // if((props.taskFilterSetting.ExpandDealTask!=="")&&(props.taskFilterSetting.ExpandDealTask!==undefined)&&(props.taskFilterSetting.ExpandDealTask!=="ALL")){
      //   let filterDeal=groupByField.filter(one=>one.includes(props.taskFilterSetting.ExpandDealTask))
      //   filterDeal.forEach((one) => {
      //     expandAllTasks(one)
      //   })
      // }
    }
  }, [props.taskFilterSetting, groupByField])


  useEffect(() => {
    if (expandAllTaskState) {
      groupByField.forEach((one) => {
        expandAllTasks(one)
      })
    } else {
      if ((expandDealTask !== "") && (expandDealTask !== undefined) && (expandDealTask !== "ALL")) {
        let filterDeal = groupByField.filter(one => one.includes(expandDealTask))
        filterDeal.forEach((one) => {
          expandAllTasks(one)
        })
      } else {
        groupByField.forEach((one) => {
          collapseAllTask(one)
        })
      }

    }
  }, [expandAllTaskState, expandDealTask])






  const getGroupByFields = (tasks, groupBy) => {
    var unique = [];
    if (groupBy == "Category") {
      unique = [...new Set(tasks.map((task) => {
        if (task && task.custom_fields && task.custom_fields.filter(one => one.label_name == 'Category').length > 0) {
          let field = task.custom_fields.filter(one => one.label_name == 'Category')[0]
          return field.value
        } else {
          return 'NO CATEGORY'
        }

      }))]

    }
    else if (groupBy == "None") {
      unique = [...new Set(tasks.map((task) => 'NONE'))];

    }
    else if (groupBy == "Priority" || groupBy == "Status") {
      if (groupBy == "Priority") {
        unique = [...new Set(tasks.map((task) => {
          if (task[groupBy.toLowerCase()] == '') {
            return 'NO PRIORITY'
          } else {
            return task[groupBy.toLowerCase()]
          }
        }))];
      } else {
        unique = [...new Set(tasks.map((task) => task[groupBy.toLowerCase()].name))];
      }

    }
    else if (groupBy == "Milestone") {
      unique = [...new Set(tasks.map((task) => {
        if (task.tasklist.name == '') {
          return 'NO MILESTONE'
        } else {
          return task.tasklist.name
        }
      }))];

    } else if (groupBy == 'Superviser') {
      unique = [...new Set(tasks.map((task) => {
        if (!(task[groupBy.toLowerCase()] == '') || task[groupBy.toLowerCase()] == '') {
          return 'NO SUPERVISER'
        } else {
          return task[groupBy.toLowerCase()]
        }
      }))];

    } else if (groupBy == 'Due Date') {
      unique = [...new Set(tasks.map((task) => {
        if ((!task.end_date) || task.end_date == '') {
          return 'N/A'
        } else {
          return task.end_date
        }
      }))];

    } else if (groupBy == 'Assignee') {
      unique = [...new Set(tasks.map((task) => {
        if ((task.details.owners.length > 0) && task.details.owners.filter(one => one.name == 'Unassigned').length == 0) {
          let assigness = task.details.owners;
          let str = ''
          assigness.forEach((assignee) => {
            let name = assignee.first_name + " " + assignee.last_name
            str = str + name + 'EOU'
          })
          return str;
        } else {
          let str = 'Unassignee';
          return str;
        }

      }))]
    }
    else if (groupBy == 'Pipeline') {

      unique = [...new Set(tasks.map((task) => {
        if (task && task.project_name) {
          return task.project_name
        } else {
          return 'NO PIPELINE'
        }
      }))]
      // unique = [...new Set(tasks.map((task) => {
      //   if (task && task.custom_fields && task.custom_fields.filter(one => one.label_name == 'Pipeline').length > 0) {
      //     let field = task.custom_fields.filter(one => one.label_name == 'Pipeline')[0]
      //     return field.value
      //   } else {
      //     return 'NO PIPELINE'
      //   }

      // }))]
    } else {
      unique = [...new Set(tasks.map((task) => task[groupBy.toLowerCase()]))];
    }
    setGroupByField(unique);


    //arrangeTaskDataOnGroupBy(unique,groupBy)

    //set group icons first time
    if (groupBy == 'Assignee') {
      let gicons = [];
      unique.forEach((e, i) => {
        if (e) {
          gicons.push({
            ID: e + props.dealObj.id,
            isOpen: true,
          });
        }
      });
      setGroupIcons(gicons);
    } else {
      let gicons = [];
      unique.forEach((e, i) => {
        if (e) {
          gicons.push({
            ID: e.replace(/ /g, "") + props.dealObj.id,
            isOpen: true,
          });
        }
      });

      if ((props.id == 'tasklist')) {
        let filterGroup = [...props.groupExpandFlag];
        filterGroup = filterGroup.filter(one => one.DealId == props.dealObj.Deal_Name);
        if (filterGroup.length > 0) {
          filterGroup = filterGroup[0]
          gicons.forEach((one) => {
            one.isOpen = filterGroup.isOpen
          })
        }

      }

      setGroupIcons(gicons);
    }

  };

  const arrangeTaskDataOnGroupBy = (unique, group) => {
    if (group == 'Status') {
      let tasksArr = []
      unique.forEach((status) => {
        const tasks = [...allTasks];
        tasks.forEach(task => {
          if (status == task.Status.name) {
            let taskClone = { ...task };
            taskClone.Subtask = [];
            task.Subtask.forEach(subtask => {
              let subClone = { ...subtask };
              subClone.Subtask = []
              if (status == subtask.Status.name) {
                subtask.Subtask.forEach((meta) => {
                  if (status == meta.Status.name) {
                    let metaClone = { ...meta };
                    subClone.Subtask.push(metaClone);
                  }
                })
                taskClone.Subtask.push(subClone);
              }
            })
            tasksArr.push(taskClone);
          } else {
            task.Subtask.forEach(subtask => {
              let subClone = { ...subtask };
              subClone.Subtask = []
              if (status == subtask.Status.name) {
                subtask.Subtask.forEach((meta) => {
                  if (status == meta.Status.name) {
                    let metaClone = { ...meta };
                    subClone.Subtask.push(metaClone);
                  }
                })
                tasksArr.push(subClone);
              }
            })
          }

        })
      })
      setAllTasks(tasksArr);

    }

  }

  useEffect(() => {
    if ((props.id == 'tasklist') && (GroupIcons.length > 0) && (props.dealObj.Project_ID == projectId)) {
      let gicons = [...GroupIcons];
      let filterGroup = [...props.groupExpandFlag];
      filterGroup = filterGroup.filter(one => one.DealId == props.dealObj.Deal_Name);
      if (filterGroup.length > 0) {
        filterGroup = filterGroup[0]
        gicons.forEach((one) => {
          one.isOpen = filterGroup.isOpen
        })
      }
      setGroupIcons(gicons);
    }
  }, [props.groupExpandFlag])

  const toggleGroupIcon = (gID) => {
    let gicons = [...GroupIcons];
    GroupIcons.forEach((e, i) => {
      if (e.ID == gID) {
        gicons[i] = {
          ID: gID,
          isOpen: !e.isOpen,
        };
      }
    });
    setGroupIcons(gicons);
  };

  const getDynamicFieldIcon = (type) => {
    switch (type) {
      case 'Dropdown':
        return <FontAwesomeIcon className="field-icon" icon={faCaretSquareDown} />
        break;
      case 'Date':
        return <FontAwesomeIcon className="field-icon" icon={faCalendar} />
        break;
      case 'File':
        return <FontAwesomeIcon className="field-icon" icon={faFile} />
        break;
      case 'Textarea':
        return <FontAwesomeIcon className="field-icon" icon={faTextHeight} />
        break;
      case 'Text':
        return <FontAwesomeIcon className="field-icon" icon={faItalic} />
        break;
      case 'Radio':
        return <FontAwesomeIcon className="field-icon" icon={faDotCircle} />
        break;
      case 'Label':
        return <FontAwesomeIcon className="field-icon" icon={faTags} />
        break;
      case 'Number':
        return <FontAwesomeIcon className="field-icon" icon={faHashtag} />
        break;

    }
  }

  const getGroupIcon = (gID) => {
    let obj = GroupIcons.find((e) => e.ID == gID);
    if (obj != undefined) {
      return obj.isOpen;
    } else {
      return true;
    }
  };

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const subtaskStatusToggle = () =>
    setSubTaskStatusDrop((prevState) => !prevState);
  const categoryToggle = () => setCategoryDrop((prevState) => !prevState);

  const nextStatusHandle = () => {

    statusValues.map((value, i) => {
      if (
        value.name === taskStatus.name &&
        taskStatus.name != "COMPLETED" &&
        taskStatus.name != "NOT REQUIRED"
      ) {
        if (isTaskCompleteteMandatory() && statusValues[i + 1].name == 'COMPLETED') {
          setTaskCompleteWarning(true)
        } else {
          setStartDateOnCompleted(statusValues[i + 1], prepareTaskData());
          setTaskStatus(statusValues[i + 1]);
          addActivity("Update", "Status", prepareTaskData()._id, prepareTaskData().TaskName, 0, { PrevStatus: taskStatus, UpdatedState: statusValues[i + 1] });
          saveUpdateTaskData();
        }

      }
    });
  };

  const [TaskCollapseIcon, setTaskCollapseIcon] = useState([]);
  const [SubTaskCollapseIcon, setSubTaskCollapseIcon] = useState([]);
  const [Flag, setFlag] = useState(false);

  const [dropOpen, setDropOpen] = useState(false);

  const [AttachdropOpen, setAttachDropOpen] = useState(false);

  const dropToggle = () => {
    setDropOpen(!dropOpen);
  }

  const AttachdropToggle = () => {
    setAttachDropOpen(!AttachdropOpen);
  }



  const TaskIcons = (tasks) => {
    let t1 = [];
    let subt1 = [];
    tasks.forEach((e, i) => {
      t1.push({
        ID: e.id_string,
        taskArrow: faCaretDown,
      });
      // if (e.Subtask&&(e.Subtask.length > 0)) {
      //   e.Subtask.forEach((sub, j) => {
      //     subt1.push({
      //       ID: sub.id_string,
      //       subtaskArrow: faCaretDown,
      //     });
      //   });
      // }
    });
    setTaskCollapseIcon(t1);
    setSubTaskCollapseIcon(subt1);
  };
  const subTaskIcon = (id) => {
    //
    let obj = SubTaskCollapseIcon.find((e) => e.ID == id);
    if (obj != undefined) {
      return obj.subtaskArrow;
    } else {
      let subT = SubTaskCollapseIcon;
      subT.push({
        ID: id,
        subtaskArrow: faCaretDown,
      });
      return faCaretDown;
    }
  };

  const toggleSubTaskIcons = (id) => {
    let t = SubTaskCollapseIcon;
    SubTaskCollapseIcon.forEach((e, i) => {
      if (e.ID == id) {
        setFlag(true);
        t[i] = {
          ID: e.ID,
          subtaskArrow:
            e.subtaskArrow == faCaretRight ? faCaretDown : faCaretRight,
        };
      }
    });
    setSubTaskCollapseIcon(t);
  };

  const openSubtaskPopupToggle = () => {
    setOpenSubtaskPopup(!openSubtaskPopup)
  }

  const toggleTaskIcons = (id) => {
    let t = TaskCollapseIcon;
    TaskCollapseIcon.forEach((e, i) => {
      if (e.ID == id) {
        setFlag(true);
        t[i] = {
          ID: e.ID,
          taskArrow: e.taskArrow == faCaretRight ? faCaretDown : faCaretRight,
        };
      }
    });
    setTaskCollapseIcon(t);
  };

  // useEffect(() => {
  //   //alert("useefffecg");
  //   setFlag(false);
  //   allTasks.map((tasks, index) => { });
  // }, [Flag]);
  // const [searchString, setSearchString] = useState(null);
  // useEffect(() => {
  //   if (props.searchTask && (props.searchString !== '') && (allTasks.length > 0)) {
  //     let searchString = props.searchTask.split('=');
  //     searchString = searchString[1];
  //     setSearchString(searchString)
  //   }
  // }, [allTasks])

  let intervalTask;

  // useEffect(() => {
  //   if (searchString) {
  //     setOpenModalDefault(searchString);
  //   }
  // }, [searchString])

  // useEffect(() => {
  //   if (location.state && location.state.detail && location.state.detail.action === "taskModalOpen") {
  //     setOpenModalDefault(location.state.detail.TaskUniqueId);
  //   }
  // }, [location])

  const setOpenModalDefault = (taskId) => {
    intervalTask = setInterval(() => {
      let element = document.getElementById('OpenUrlModal' + taskId);
      if (element) {
        element.click();
        clearInterval(intervalTask);
      }
    }, 100)

  }

  const [DesignCollapseIcon, setDesignCollapseIcon] = useState(false);
  const [FilingCollapseIcon, setFilingCollapseIcon] = useState(false);
  const [MiscCollapseIcon, setMiscCollapseIcon] = useState(false);
  // const toggleGroupIcon=(g)=>{
  //   if(g=="DESIGN"){
  //     setDesignCollapseIcon(!DesignCollapseIcon);
  //   }
  //   else if(g=="FILING"){
  //     setFilingCollapseIcon(!FilingCollapseIcon);
  //   }
  //   else{
  //     setMiscCollapseIcon(!MiscCollapseIcon);
  //   }
  // };

  const [secondTable, setsecondTable] = useState("");

  const toggletable2 = () => {
    if (secondTable == "") {
      setsecondTable("show");
    } else {
      setsecondTable("");
    }
  };

  const [thirdTable, setthirdTable] = useState("");

  const toggletable3 = () => {
    if (thirdTable == "") {
      setthirdTable("show");
    } else {
      setthirdTable("");
    }
  };

  const toggleNested1 = () => {
    setNestedModal1(!nestedModal1);
  };

  const openDatePicker = () => {
    setdatePickerIsOpen(!datePickerIsOpen);
  };
  const handleChange = (date) => {
    setstartDate(date);
    setdatePickerIsOpen(!datePickerIsOpen);
  };

  const changeDate = (date) => {
    setstartDate(date);
    const formattedDate = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }).format(date);
    setProjectDate(formattedDate);
  };

  const clearAllTblListAssgnee = (oprtnType) => {
    if (oprtnType === "TASK_DROPDOWN_MENU") {
      selectedTask.Assignee = [];
    } else if (oprtnType === "SUBTASK_DROPDOWN_MENU") {
      selectedSubTask.Assignee = [];
    } else if (oprtnType === "METATASK_DROPDOWN_MENU") {
      selectedMeataTask.Assignee = [];
    }
    updateTask(selectedTask);
  };

  const removeTblAssignee = (data, asgneeUserId) => {
    let flag = false;
    data.Assignee.forEach((asgn, i) => {
      if (asgn.userId === asgneeUserId) {
        data.Assignee.splice(i, 1);
        flag = true;
      }
    });

    return flag;
  };

  const [cacheUsersArr, setCacheUsersArr] = useState([]);

  const [taskEstimatePopUpWarn, setTaskEstimatePopupWarn] = useState(false);


  const taskEstimateFillWarningModal = () => {
    return (
      <Modal
        isOpen={taskEstimatePopUpWarn}
        backdrop={'static'}
        size='md'
        toggle={taskEstimatePopUpWarn}
        className="taskComplete-warning"
      >

        <ModalBody>
          <Row >
            <Col sm={12}>
              <FontAwesomeIcon icon={faExclamationTriangle} size='3x' className="text-warning" />
              <h5 className="my-2">Warning</h5>
              <p className="taskComplete-warning-subtitle">time estimate must be defined before adding assignee.</p>
            </Col>

          </Row>
          <Row >
            <Col sm={12}>


              <button onClick={() => {
                setTaskEstimatePopupWarn(!taskEstimatePopUpWarn)
              }} className='btn btn-secondary w-100 mt-2'>OK</button>
            </Col>

          </Row>
        </ModalBody>
      </Modal>
    )
  }

  const addSelectedTask = (taskType, data) => {
    if (taskType == 'task') {
      selectedTask = data
    }
    else if (taskType == 'subtask') {
      selectedSubTask = data
    } else {
      selectedMeataTask = data
    }
  }

  const addRemoveTblListAssignee = async (userName, userId, oprtnType, role) => {
    if (oprtnType === "ADD_TASK_BY_LIST") {
      if (!removeTblAssignee(selectedTask, userId)) {
        if (selectedTask.TimeEstimateValue == ':' || selectedTask.TimeEstimateValue == '0:0') {
          setTaskEstimatePopupWarn(true);
          return
        }


        if (selectedTask.Priority.name == 'URGENT') {
          setUrgentTaskWarningPopUp(true);
          setStateDataForUrgent({ assignee: { userName: userName, userId: userId }, role: role, task: selectedTask, events: calenderEvents })

          return
        } else {
          let taskAssignDate1 = checkValidateTimeEstimateForTask({ userName: userName, userId: userId }, selectedTask, calenderEvents);
          if (taskAssignDate1) {
            selectedTask.AssignDatetime = taskAssignDate1;
          } else {
            swal('No available time slot till the task due date. If you want to assign increase the due date');
            return
          }
        }



        selectedTask.Assignee.push({ userName: userName, userId: userId });
        // if ((selectedTask.Status.name == 'PENDING') && (selectedTask.Assignee.length > 0)) {
        //   selectedTask.Status = { name: "NOT STARTED", color: "#f8d800" }
        // }

        await updateTask(selectedTask);
        await checkAutomationAndExecute({ userName: userName, userId: userId }, selectedTask._id, 'Assignee');

        // let usersData = cacheUsersArr.filter(e => e.UserId != userId);
        // // setUsersArr(usersData);
        // setAllUsers(usersData)

        addActivity('Update', "Assignee", selectedTask._id, selectedTask.TaskName, 0, { userName: userName, userId: userId });
        getAllActivityAsPerTask(selectedTask._id);
        sendNotificationToUser(userId, userName, 'Assign', role, selectedTask._id, [{ TaskUniqueId: selectedTask.UniqueId, DealName: props.dealObj.ProjectNumber, DealId: props.dealObj.UniqueId, TaskId: selectedTask._id, TaskName: selectedTask.TaskName }]);

      }
    } else if (oprtnType === "ADD_SUBTASK_BY_LIST") {
      if (!removeTblAssignee(selectedSubTask, userId)) {
        if (selectedSubTask.TimeEstimateValue == ':') {
          setTaskEstimatePopupWarn(true);
          return
        }

        if (selectedSubTask.Priority.name == 'URGENT') {
          setUrgentTaskWarningPopUp(true);
          setStateDataForUrgent({ assignee: { userName: userName, userId: userId }, role: role, task: selectedTask, events: calenderEvents })

          return
        } else {
          let taskAssignDate2 = checkValidateTimeEstimateForTask({ userName: userName, userId: userId }, selectedSubTask, calenderEvents);
          if (taskAssignDate2) {
            selectedSubTask.AssignDatetime = taskAssignDate2;
          } else {
            swal('No available time slot till the task due date. If you want to assign increase the due date');
            return
          }
        }


        selectedSubTask.Assignee.push({ userName: userName, userId: userId });
        // if ((selectedSubTask.Status.name == 'PENDING') && (selectedSubTask.Assignee.length > 0)) {
        //   selectedSubTask.Status = { name: "NOT STARTED", color: "#f8d800" }
        // }

        await updateTask(selectedSubTask);
        await checkAutomationAndExecute({ userName: userName, userId: userId }, selectedSubTask._id, 'Assignee');
        addActivity('Update', "Assignee", selectedSubTask._id, selectedSubTask.TaskName, 0, { userName: userName, userId: userId });
        getAllActivityAsPerTask(selectedSubTask._id);
        sendNotificationToUser(userId, userName, 'Assign', role, selectedSubTask._id, [{ TaskUniqueId: selectedTask.UniqueId, DealName: props.dealObj.ProjectNumber, DealId: props.dealObj.UniqueId, TaskId: selectedSubTask._id, TaskName: selectedSubTask.TaskName }]);

      }
    } else if (oprtnType === "ADD_METASUBTASK_BY_LIST") {
      if (!removeTblAssignee(selectedMeataTask, userId)) {
        if (selectedMeataTask.TimeEstimateValue == ':') {
          setTaskEstimatePopupWarn(true);
          return
        }
        if (selectedMeataTask.Priority.name == 'URGENT') {
          setUrgentTaskWarningPopUp(true);
          setStateDataForUrgent({ assignee: { userName: userName, userId: userId }, role: role, task: selectedTask, events: calenderEvents })

          return
        } else {
          let taskAssignDate3 = checkValidateTimeEstimateForTask({ userName: userName, userId: userId }, selectedMeataTask, calenderEvents);
          if (taskAssignDate3) {
            selectedMeataTask.AssignDatetime = taskAssignDate3;
            // ifTImeSlotAvailableReAssignLatestTask({ userName: userName, userId: userId }, taskDocs, selectedMeataTask)
          } else {
            swal('No available time slot till the task due date. If you want to assign increase the due date');
            return
          }
        }
        selectedMeataTask.Assignee.push({ userName: userName, userId: userId });
        // if ((selectedMeataTask.Status.name == 'PENDING') && (selectedMeataTask.Assignee.length > 0)) {
        //   selectedMeataTask.Status = { name: "NOT STARTED", color: "#f8d800" }
        // }

        await updateTask(selectedMeataTask);
        await checkAutomationAndExecute({ userName: userName, userId: userId }, selectedMeataTask._id, 'Assignee');
        addActivity('Update', "Assignee", selectedMeataTask._id, selectedMeataTask.TaskName, 0, { userName: userName, userId: userId });
        getAllActivityAsPerTask(selectedMeataTask._id);
        sendNotificationToUser(userId, userName, 'Assign', role, selectedMeataTask._id, [{ TaskUniqueId: selectedTask.UniqueId, DealName: props.dealObj.ProjectNumber, DealId: props.dealObj.UniqueId, TaskId: selectedMeataTask._id, TaskName: selectedMeataTask.TaskName }]);

      }
    }

    //getAllUserTask()




  };

  const addRemoveTblListSuperviser = async (userName, userId, oprtnType, role) => {
    if (oprtnType === "ADD_TASK_BY_LIST") {
      if (selectedTask.Superviser.filter(o => o.userId == userId).length == 0) {
        selectedTask.Superviser.push({ userName: userName, userId: userId });
      }

      // if ((selectedTask.Status.name == 'PENDING') && (selectedTask.Assignee.length > 0)) {
      //   selectedTask.Status = { name: "NOT STARTED", color: "#f8d800" }
      // }
      updateTask(selectedTask);
    } else if (oprtnType === "ADD_SUBTASK_BY_LIST") {
      if (selectedSubTask.Superviser.filter(o => o.userId == userId).length == 0) {
        selectedSubTask.Superviser.push({ userName: userName, userId: userId });
      }
      // if ((selectedTask.Status.name == 'PENDING') && (selectedTask.Assignee.length > 0)) {
      //   selectedTask.Status = { name: "NOT STARTED", color: "#f8d800" }
      // }
      updateTask(selectedSubTask);
    } else if (oprtnType === "ADD_METASUBTASK_BY_LIST") {
      if (selectedMeataTask.Superviser.filter(o => o.userId == userId).length == 0) {
        selectedMeataTask.Superviser.push({ userName: userName, userId: userId });
      }
      // if ((selectedTask.Status.name == 'PENDING') && (selectedTask.Assignee.length > 0)) {
      //   selectedTask.Status = { name: "NOT STARTED", color: "#f8d800" }
      // }
      updateTask(selectedMeataTask);
    }

    //getAllUserTask()




  };

  const ifTImeSlotAvailableReAssignLatestTask = async (assignee, tasks, task) => {
    let doc = { ...task };
    tasks.filter((t) => t._id == task._id)[0].Assignee.push(assignee);
    tasks.filter((t) => t._id == task._id)[0].AssignDatetime = task.AssignDatetime;
    tasks = tasks.filter((one) => one.Assignee.length > 0);
    await assignTaskByOneSync2(assignee, 'role', tasks, doc)
  }



  const addActivity = async (actionType, dataType, taskId, taskName, taskType, data) => {
    let obj = { UserId: localStorage.getItem('UserId'), UserName: localStorage.getItem('UserName'), DealId: DealsId, TaskId: taskId, TaskName: taskName, Action: { ActionType: actionType, DataType: dataType, Data: data } };
    HttpService.getByBoj('addActivityTime', obj)
      .then((result) => {
        getAllActivityAsPerTask(taskId);
      })
      .catch((err) => {
        //swal(`${err}`);
      })
  }

  const handleOpenCreateField = (dataType) => {
    setCreateFieldPopUp(true)
    setElementType(dataType)
    setTaskFieldExist(false);
    if (taskFieldAction === "add") {
      setTaskFieldObj(null);
      setTaskFieldConfigObj(null);
    }
  }

  const saveTaskCategory = (value) => {
    let categoryData = { ...prepareTaskData() };
    setTaskCategory(value);
    categoryData.Category = value;
    addUpdateTaskApi(categoryData);
  }

  const addPriorityActivity = (value) => {
    let priorityData = { ...prepareTaskData() };
    setPriorityValue(value);
    priorityData.Priority = value;
    addUpdateTaskApi(priorityData);

    let obj = {};
    obj.PrevState = prepareTaskData()["Priority"]
    prepareTaskData()["Priority"] = value;
    obj.UpdatedState = value;
    addActivity("ADD", "Priority", priorityData._id, priorityData.TaskName, 0, obj)
    getAllActivityAsPerTask(priorityData._id);


  }

  const saveStatusOnClick = async (taskData, value) => {
    let statusData = { ...taskData };
    let dependencyStatus = statusData.DependentOn
    let obj = {};
    obj.PrevStatus = statusData["Status"];


    let status = await checkCheckTaskStatus(statusData, value)
    if (status) {
      setActivityObj(obj);
      setParentToSubtaskStatusCompleteWar(true);
      statusData.Status = value;
      setDataForUpdate(statusData);
      //getallTasks();
    }
    else {
      setStartDateOnCompleted(value, statusData)
      setTaskStatus(value);
      statusData.Status = value;
      obj.UpdatedState = value;
      addActivity("ADD", "Status", statusData._id, statusData.TaskName, 0, obj);

      // handleOnChangeTask({ target: { name: 'Status', value: value } });
    }


    return status;

  }

  const addRemoveAssignee = (userName, userId, oprtnType) => {
    let activityData = { ...prepareTaskData() };
    let assignee = assigneDropState;
    let found = false;
    if (assignee.length > 1) {
      assigneDropState.forEach((asgn, i) => {
        if (asgn.userId == userId) {
          assignee.splice(i, 1);
          found = true;
          saveUpdateTaskData();
          addActivity("DELETE", "Assignee", activityData._id, activityData.TaskName, 0, {
            userName: userName,
            userId: userId,
          });
          getAllActivityAsPerTask(activityData._id);
        }
      });
    }

    //|| assignee.length == 1
    if (found == false) {
      assignee.push({
        userName: userName,
        userId: userId,
      });
      saveUpdateTaskData();
      addActivity("ADD", "Assignee", activityData._id, activityData.TaskName, 0, {
        userName: userName,
        userId: userId,
      });
      getAllActivityAsPerTask(activityData._id);
      // assignee = addAssgnee(assignee, userName, userId, oprtnType);
    }

    setAssigneDropState([...assignee]);

  };

  const addAssgnee = (assignee, userName, userId, oprtnType) => {
    assignee.push({
      userName: userName,
      userId: userId,
    });

    // if (oprtnType === "ADD_TASK_BY_LIST") {
    //   if(!removeTblAssignee(selectedTask, userId)) {
    //     selectedTask.Assignee.push({
    //       userName: userName,
    //       userId: userId,
    //     });
    //   }

    // } else if(oprtnType === "ADD_SUBTASK_BY_LIST") {
    //   if(!removeTblAssignee(selectedSubTask, userId)) {
    //     selectedSubTask.Assignee.push({
    //       userName: userName,
    //       userId: userId,
    //     });
    //   }
    // }else if(oprtnType === "ADD_METASUBTASK_BY_LIST"){
    //   if(!removeTblAssignee(selectedMeataTask, userId)) {
    //     selectedMeataTask.Assignee.push({
    //       userName: userName,
    //       userId: userId,
    //     });
    //   }

    // }
    // updateTask(selectedTask);

    return assignee;
  };

  const [dependencyCollapse, setDependencyCollapse] = useState(true);

  //TIMETRACK
  const [interv, setInterv] = useState(0);
  const [timer, setTimer] = useState(0);
  const [timeTrackLogs, setTimeTrackLogs] = useState([])
  // const [clockisActive, setClockIsActive] = useState(false);
  const [StartTime, setStartTime] = useState("");
  const [StopTime, setStopTime] = useState("");
  const [TimeTrackedData, setTimeTrackedData] = useState([]);
  const [TotalTimeTracked, setTotalTimeTracked] = useState(0);
  let d = new Date().getHours() + ":" + new Date().getMinutes();
  const [FromRange, setFromRange] = useState(() => {
    let hrs = new Date().getHours();
    hrs = hrs < 10 ? "0" + hrs : hrs;
    let mins = new Date().getMinutes();
    mins = mins < 10 ? "0" + mins : mins;
    return hrs + ":" + mins;
  });
  const [ToRange, setToRange] = useState(() => {
    let hrs = new Date().getHours();
    hrs = hrs < 10 ? "0" + hrs : hrs;
    let mins = new Date().getMinutes();
    mins = mins < 10 ? "0" + mins : mins;
    return hrs + ":" + mins;
  });
  const SetTime = () => {
    let hrs = new Date().getHours();
    hrs = hrs < 10 ? "0" + hrs : hrs;
    let mins = new Date().getMinutes();
    mins = mins < 10 ? "0" + mins : mins;
    return hrs + ":" + mins;
  };
  const getDateMonthOnly = (dateSplit) => {
    dateSplit = dateSplit.split("/");
    let monthShortNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    //
    let Month = parseInt(dateSplit[1]);
    return monthShortNames[Month - 1] + " " + dateSplit[0];
  };
  function GetFormattedDate() {
    let dateObj = new Date();
    let month = dateObj.getUTCMonth() + 1; //months from 1-12
    let day = dateObj.getUTCDate();
    let year = dateObj.getUTCFullYear();
    day = day < 10 ? "0" + day : day;
    month = month < 10 ? "0" + month : month;
    return day + "/" + month + "/" + year;
  }
  const formatRangeTimer = (Time, Date) => {
    let d2 = Time.split(":");
    let yr = Date.split("/");
    if (d2[0] > 12) {
      d2[0] = d[0] % 12;
      d2[1] = d2[1] + " p.m.";
    } else {
      d2[1] = d2[1] + " a.m.";
    }
    return getDateMonthOnly(Date) + " " + yr[2] + " " + d2[0] + ":" + d2[1];
  };
  const formatStartStopTimer = (Time) => {
    let d1 = new Date(Time).toString();
    d1 = d1.split(" ");
    let d2 = d1[4].split(":");
    if (d2[0] > 12) {
      d2[0] = d[0] % 12;
      d2[2] = d2[2] + " p.m.";
    } else {
      d2[2] = d2[2] + " a.m.";
    }
    return (
      d1[1] +
      " " +
      d1[2] +
      " " +
      d1[3] +
      " " +
      d2[0] +
      ":" +
      d2[1] +
      ":" +
      d2[2]
    );
  };
  const formatTime = (timer) => {
    const getSeconds = `0${timer % 60}`.slice(-2);
    const minutes = `${Math.floor(timer / 60)}`;
    const getMinutes = `0${minutes % 60}`.slice(-2);
    const getHours = `0${Math.floor(timer / 3600)}`.slice(-2);
    return `${getHours}:${getMinutes}:${getSeconds} Hrs`;
  };
  const handleReset = () => {
    //
    clearInterval(interv);
    setClockIsActive(false);
    setInterv(0);
    setTimer(0);
  };
  const isAlreadyAssigneeTimerPlay = (taskData) => {
    let filterTask = taskDocs.filter(one => {
      let assignee = one.Assignee;
      let taskAssignee = taskData.Assignee;
      if ((assignee.filter(a => taskAssignee.filter(o => o.userId == a.userId).length > 0).length > 0) && one._id !== taskData._id) {
        return one;
      }
    })
    filterTask = filterTask.filter((task) => {
      if (task.TimeTrack.filter(t => t.current).length > 0) {
        return task;
      }
    })
    if ((filterTask.length > 0)) {
      return true
    } else {
      return false;
    }
  }

  
  // const startstopClock = async (taskData, isActive) => {
  //   if (taskData.Assignee.length > 0) {
  //     if (!isAlreadyAssigneeTimerPlay(taskData)) {
  //       const data = { ...taskData };
  //       if (isActive) {
  //         setClockIsActive(false);
  //         handleReset();
  //         clearInterval(interv);
  //         setInterv(0)
  //         setTimer(0);
  //         data.TimeTrack.forEach((one) => {
  //           if (one.current) {
  //             one.StopTime = new Date(Date.now());
  //             one.current = false;
  //           }
  //         })
  //         updateTask(data)
  //         // setTaskData(data);
  //         // setTimeTrackObj({})
  //       } else {
  //         let flag = false;
  //         if (data.Status.name !== "IN PROGRESS") {
  //           flag = await saveStatusOnClick(data, { name: "IN PROGRESS", color: "#2ecd6f" });
  //           //setOpenTimeTrackedPopUp(flag);
  //         }


  //         if (!flag) {
  //           const temp = {
  //             ID: uuidv4(),
  //             UserID: localStorage.getItem("UserId"),
  //             InputBy: "Timer",
  //             StartTime: new Date(Date.now()),
  //             current: true
  //             // TimeTracked: stop == "" ? 0 : Math.floor((stop - start) / 1000),
  //           };
  //           data.Status = { name: "IN PROGRESS", color: "#2ecd6f" };
  //           data.TimeTrack.push(temp);
  //           updateTask(data)

  //           setStartTime(Date.now());
  //           setClockIsActive(true);
  //           setInterv(
  //             setInterval(async () => {
  //               let dealId = props.dealsId
  //               let res2 = await HttpService.getByBoj("gettaskdocs", { DealsId: dealId })
  //               res2.data.Data.forEach((one) => {
  //                 one.TimeTrack.forEach((t) => {
  //                   if (t.current) {
  //                     t.StopTime = new Date(Date.now())
  //                   }
  //                 })
  //               })
  //               setTaskDocs(res2.data.Data)

  //             }, 1000));
  //         }
  //       }
  //     } else {
  //       swal('Assignee already working in another task', { dangerMode: true });
  //     }
  //   } else {
  //     swal('please add assignee to start time clock', { dangerMode: true });
  //   }



  // };
  const currentData = TimeTrackedData ? TimeTrackedData : [];

  const removeTimeTrack = (itemID) => {
    let tempArr = currentData.filter((e) => e.ID != itemID);
    currentData.splice(0, currentData.length);
    // let Arr=currentData;
    // Arr.forEach((e,i)=>{
    //
    //   currentData.shift();
    // })
    // //currentData.pop();
    let sum = 0;
    tempArr.forEach((e, i) => {
      sum = sum + e.TimeTracked;

      currentData.push(e);
    });
    setTotalTimeTracked(sum);
    setTimeTrackedData(currentData);
  };

  const onSaveTimer = (start, stop) => {
    const temp = {
      ID: uuidv4(),
      UserID: localStorage.getItem("UserId"),
      Date: GetFormattedDate(),
      InputBy: "Timer",
      StartTime: start,
      StopTime: stop,
      TimeTracked: stop == "" ? 0 : Math.floor((stop - start) / 1000),
    };
    let sum = 0;
    if (stop == "") {
      currentData.push(temp);
    } else {
      currentData.forEach((e, i) => {
        if (e.StopTime == "") {
          currentData[i] = temp;
        }
      });
    }
    currentData.forEach((e, i) => {
      sum = sum + e.TimeTracked;
    });
    setTotalTimeTracked(sum);
    setTimeTrackedData(currentData);
    openmodalType == "task"
      ? saveUpdateTaskData()
      : openmodalType == "subtask"
        ? saveUpdateSubTaskData()
        : saveUpdateMetaSubTaskData();
  };
  const onSaveTimeRange = (start, stop, itemID) => {
    let s1 = start.split(":");
    let s2 = stop.split(":");
    let diff = s2[0] * 3600 + s2[1] * 60 - (s1[0] * 3600 + s1[1] * 60);
    if (diff > 0) {
      const temp = {
        ID: itemID == "" ? uuidv4() : itemID,
        UserID: localStorage.getItem("UserId"),
        Date: GetFormattedDate(),
        InputBy: "TimeRange",
        StartTime: start,
        StopTime: stop,
        TimeTracked: diff,
      };
      if (itemID == "") {
        currentData.push(temp);
      } else {
        currentData.forEach((e, i) => {
          if (currentData[i].ID == itemID) {
            currentData[i] = temp;
          }
        });
      }
      let sum = 0;
      currentData.forEach((e, i) => {
        sum = sum + e.TimeTracked;
      });
      setTotalTimeTracked(sum);
      setTimeTrackedData(currentData);
      openmodalType == "task"
        ? saveUpdateTaskData()
        : openmodalType == "subtask"
          ? saveUpdateSubTaskData()
          : saveUpdateMetaSubTaskData();
    } else {
      setFromRange(() => {
        let hrs = new Date().getHours();
        hrs = hrs < 10 ? "0" + hrs : hrs;
        let mins = new Date().getMinutes();
        mins = mins < 10 ? "0" + mins : mins;
        return hrs + ":" + mins;
      });
      setToRange(() => {
        let hrs = new Date().getHours();
        hrs = hrs < 10 ? "0" + hrs : hrs;
        let mins = new Date().getMinutes();
        mins = mins < 10 ? "0" + mins : mins;
        return hrs + ":" + mins;
      });
      swal("Starting Time should be lesser");
    }
  };
  /// CHECKLIST CONST
  const [CheckItemAssigneeDrop, setCheckItemAssigneeDrop] = useState(false);
  const [CheckItemAssignee, setCheckItemAssignee] = useState([]);
  const [CheckList, setCheckList] = useState([]);
  const [CheckItemContainer, toggleCheckItemContainer] = useState(false);
  const [CheckItemLabel, setCheckItemLabel] = useState("");
  const [CheckItemforUpdate, setCheckItemforUpdate] = useState("");
  const currentCheckList = CheckList;

  const getUserProfilePhotobyID = (userId) => {
    let selectedUser = allUsers.find((e) => e.UserId == userId);
    if (selectedUser != undefined) {
      return selectedUser.ProfilePhoto;
    } else {
      return assigntask;
    }
  };

  const resetCheckItemFields = () => {
    setCheckItemLabel("");
    setCheckItemforUpdate("");
    setCheckItemAssignee([]);
  };
  const saveUpdateCheckList = () => {
    let newCheckItem = {
      ID: uuidv4(),
      UserID: localStorage.getItem("UserId"),
      Label: CheckItemLabel,
      Assignee: CheckItemAssignee[0] ? CheckItemAssignee[0] : [],
      IsChecked: false,
    };

    let obj = {}
    obj.PrevCheckList = prevCheckList;
    obj.UpdatedCheckList = newCheckItem;

    if (!newTask) {
      openmodalType == "task"
        ? addActivity('Update', 'CheckList', parentObj._id, parentObj.TaskName, 0, obj)
        : openmodalType == "subtask"
          ? addActivity('Update', 'CheckList', parentObj._id, parentObj.TaskName, 0, obj)
          : addActivity('Update', 'CheckList', parentObj._id, parentObj.TaskName, 0, obj)
    }


    currentCheckList.push(newCheckItem);
    setCheckList(currentCheckList);
    if (!newTask) {
      openmodalType == "task"
        ? saveUpdateTaskData()
        : openmodalType == "subtask"
          ? saveUpdateSubTaskData()
          : saveUpdateMetaSubTaskData();
    }
    resetCheckItemFields();
  };

  const [prevCheckList, setPrevCheckList] = useState(null);
  const changeCheckLabel = (e, Item, i) => {
    if (e.key == "Enter" && CheckItemLabel != "") {
      let filterCheckList = CheckList.filter(one => one.ID == Item.ID)
      if (filterCheckList.length > 0) {
        setPrevCheckList(filterCheckList[0])
      }

      let updatedCheckItem = {
        ID: Item.ID,
        UserID: Item.UserID,
        Assignee: Item.Assignee,
        Label: CheckItemLabel,
        IsChecked: Item.IsChecked,
      };
      let obj = {}
      obj.PrevCheckList = filterCheckList.length > 0 ? filterCheckList[0] : null;
      obj.UpdatedCheckList = updatedCheckItem;

      if (!newTask) {
        openmodalType == "task"
          ? addActivity('Update', 'CheckList', parentObj._id, parentObj.TaskName, 0, obj)
          : openmodalType == "subtask"
            ? addActivity('Update', 'CheckList', parentObj._id, parentObj.TaskName, 0, obj)
            : addActivity('Update', 'CheckList', parentObj._id, parentObj.TaskName, 0, obj)
      }
      currentCheckList.forEach((e, i) => {
        if (e.ID == Item.ID) {
          currentCheckList[i] = updatedCheckItem;
        }
      });
      setCheckList(currentCheckList);
      if (!newTask) {
        openmodalType == "task"
          ? saveUpdateTaskData()
          : openmodalType == "subtask"
            ? saveUpdateSubTaskData()
            : saveUpdateMetaSubTaskData();
      }
      resetCheckItemFields();
    }
  };


  const changeCheck = (Item, idx) => {
    let updatedCheckItem = {
      ID: Item.ID,
      UserID: Item.UserID,
      Assignee: Item.Assignee,
      Label: Item.Label,
      IsChecked: !Item.IsChecked,
    };
    let filterCheckList = CheckList.filter(one => one.ID == Item.ID)
    if (filterCheckList.length > 0) {
      setPrevCheckList(filterCheckList[0])
    }
    currentCheckList.forEach((e, i) => {
      if (e.ID == Item.ID) {
        currentCheckList[i] = updatedCheckItem;
      }
    });
    setCheckList(currentCheckList);
    if (!newTask) {
      openmodalType == "task"
        ? saveUpdateTaskData()
        : openmodalType == "subtask"
          ? saveUpdateSubTaskData()
          : saveUpdateMetaSubTaskData();
    }
    resetCheckItemFields();
  };
  //modify checklist in 3 columns
  const arrayChunk = (arr, n) => {
    const array = arr.slice();
    const chunks = [];
    while (array.length) chunks.push(array.splice(0, n));
    return chunks;
  };

  const [parentObj, setParentObject] = useState(null);


  const [parentTaskObj, setParentTaskObj] = useState(null);
  const [editTask, setEditTask] = useState(false);

  const [action, setAction] = useState();
  // const [taskId,,setTaskId]=useState('')

  // const openUpdateModal = async (data, type, parentdata, metaSubTask) => {
  //   // let duplicate = {
  //   //   TaskName: '',
  //   //   Everything: true, Attachements: true, Checklist: true, Comments: true, InputFields: true,
  //   //   LookUpFields: true, Dependencies: true, DueDate: true, Assignee: true
  //   // };
  //   // duplicate.TaskName=data.TaskName+' - Copy'+(copyTasks.length+1);
  //   // setDuplicateValueObj(duplicate);
  //   getallTasks();
  //   setTaskAction('Edit');
  //   getTaskDocs(data.TaskName, data._id)
  //   GetAllTaskCustumeFieldList(data._id)
  //   setParentObject(data);
  //   if (data.TimeEstimateValue != "") {
  //     let obj = getTimeEstimateValue(data.TimeEstimateValue);

  //     setTimeEstimateValue(obj.hrs);
  //     setTimeMinuteValue(obj.mins);
  //   }

  //   if (type == "subtask") {
  //     if (data.Subtask.length > 0) {
  //       setSubtaskCollapse(true);

  //     }
  //     else {
  //       setSubtaskCollapse(!subtaskCollapse)
  //     }
  //     getAllActivityAsPerTask(data._id)
  //     setSelectedTaskId({ task: data });
  //     updateComments(data._id);
  //     setTaskCategory(parentdata.Category);
  //     setselectedSubTaskId({ subtask: data, task: parentdata });
  //     setTaskName(data.TaskName);
  //     let TList = [...allTaskList];
  //     // TList = TList.filter((t) => t.id != data.SubtaskId);
  //     setDependencyDropdownData(TList);
  //     setTaskDependency([]);
  //     // setTaskDependency(data.DependentOn != null ? data.DependentOn : []);
  //     setDescription(data.Description); // setSelectedTaskId({subtask:data,task:parentdata});
  //     setSubTasks(data.Subtask);
  //     // setTaskId(data._id)
  //     setTaskStatus(data.Status);
  //     setPriorityValue(data.Priority);
  //     setAssigneDropState(data.Assignee);
  //     setSelectionRange([
  //       {
  //         startDate: data.StartDate ? data.StartDate : new Date(),
  //         endDate: data.DueDate ? data.DueDate : addDays(new Date(), 7),
  //         key: "selection",
  //       },
  //     ]);
  //     setstartDate(Date.parse(data.DueDate));
  //     setSubTasks(data.Subtask);
  //     if (data.TimeEstimateValue != "") {
  //       let obj = getTimeEstimateValue(data.TimeEstimateValue);

  //       setTimeEstimateValue(obj.hrs);
  //       setTimeMinuteValue(obj.mins);
  //     }
  //     // setTimeEstimateValue(data.TimeEstimateValue);

  //     setCheckList(data.Checklist);
  //     setTimeTrackedData(data.TimeTrack);

  //     let timeSum = 0;
  //     data.TimeTrack.forEach((e, i) => {
  //       if (e.UserID == localStorage.getItem("UserId")) {
  //         timeSum = timeSum + e.TimeTracked;
  //         if (e.StopTime == "") {
  //           setStartTime(e.StartTime);
  //           setClockIsActive(true);
  //           let startTimerFromHere = Math.floor(
  //             (Date.now() - e.StartTime) / 1000
  //           );
  //           setTimer(startTimerFromHere);
  //         }
  //         if (e.StopTime == "") {
  //           setInterv(
  //             setInterval(() => {
  //               setTimer((timer) => timer + 1);
  //             }, 1000)
  //           );
  //         }
  //       }
  //     });
  //     setTotalTimeTracked(timeSum);
  //   } else if (type == "metatask") {
  //     getAllActivityAsPerTask(data._id)
  //     setSelectedTaskId({ task: data })
  //     updateComments(data._id);
  //     setselectedSubTaskId({
  //       metatask: data,
  //       task: parentdata,
  //       subtask: metaSubTask,
  //     });
  //     //setTaskId(data._id)
  //     //setSelectedTaskId({subtask:data,task:parentdata});
  //     setTaskName(data.TaskName);
  //     let TList = [...allTaskList];
  //     // TList = TList.filter((t) => t.id != data.SubtaskId);
  //     setDependencyDropdownData(TList);
  //     setTaskDependency([]);
  //     // setTaskDependency(data.DependentOn != null ? data.DependentOn : []);
  //     setDescription(data.Description);
  //     setTaskStatus(data.Status);
  //     setPriorityValue(data.Priority);
  //     setAssigneDropState(data.Assignee);
  //     setSelectionRange([
  //       {
  //         startDate: data.StartDate ? data.StartDate : new Date(),
  //         endDate: data.DueDate ? data.DueDate : addDays(new Date(), 7),
  //         key: "selection",
  //       },
  //     ]);
  //     setstartDate(Date.parse(data.DueDate));
  //     setSubTasks(data.Subtask);
  //     if (data.TimeEstimateValue != "") {
  //       let obj = getTimeEstimateValue(data.TimeEstimateValue);
  //       setTimeEstimateValue(obj.hrs);
  //       setTimeMinuteValue(obj.mins);
  //     }
  //     // setTimeEstimateValue(data.TimeEstimateValue);
  //     setCheckList(data.Checklist);
  //     setTimeTrackedData(data.TimeTrack);

  //     let timeSum = 0;
  //     data.TimeTrack.forEach((e, i) => {
  //       if (e.UserID == localStorage.getItem("UserId")) {
  //         timeSum = timeSum + e.TimeTracked;
  //         if (e.StopTime == "") {
  //           setStartTime(e.StartTime);
  //           setClockIsActive(true);
  //           let startTimerFromHere = Math.floor(
  //             (Date.now() - e.StartTime) / 1000
  //           );
  //           setTimer(startTimerFromHere);
  //         }
  //         if (e.StopTime == "") {
  //           setInterv(
  //             setInterval(() => {
  //               setTimer((timer) => timer + 1);
  //             }, 1000)
  //           );
  //         }
  //       }
  //     });
  //     setTotalTimeTracked(timeSum);
  //   } else {

  //     getAllActivityAsPerTask(data._id)
  //     setSelectedTaskId({ task: data });
  //     setTaskName(data.TaskName);
  //     let TList = [...allTaskList];
  //     TList = TList.filter((t) => t.id != data._id);
  //     setDependencyDropdownData(TList);
  //     setTaskDependency(data.DependentOn != null ? data.DependentOn : []);
  //     setDescription(data.Description);
  //     setTaskStatus(data.Status);
  //     setTaskCategory(data.Category);

  //     let selection = {
  //       startDate: data.StartDate ? new Date(data.StartDate) : new Date(),
  //       endDate: data.DueDate ? new Date(data.DueDate) : addDays(new Date(), 7),
  //       key: "selection",
  //     };
  //     setSelectionRange([selection]);
  //     setDateDisplay(selection);

  //     setstartDate(Date.parse(data.DueDate));
  //     setPriorityValue(data.Priority);
  //     setAssigneDropState(data.Assignee);
  //     setSubTasks(data.Subtask);
  //     // setTimeEstimateValue(data.TimeEstimateValue);
  //     updateComments(data._id);

  //     setCheckList(data.Checklist);
  //     setTimeTrackedData(data.TimeTrack);

  //     let timeSum = 0;
  //     data.TimeTrack.forEach((e, i) => {
  //       if (e.UserID == localStorage.getItem("UserId")) {
  //         timeSum = timeSum + e.TimeTracked;
  //         if (e.StopTime == "") {
  //           setStartTime(e.StartTime);
  //           setClockIsActive(true);
  //           let startTimerFromHere = Math.floor(
  //             (Date.now() - e.StartTime) / 1000
  //           );
  //           setTimer(startTimerFromHere);
  //         }
  //         if (e.StopTime == "") {
  //           setInterv(
  //             setInterval(() => {
  //               setTimer((timer) => timer + 1);
  //             }, 1000)
  //           );
  //         }
  //       }
  //     });
  //     setTotalTimeTracked(timeSum);

  //     setAttachedFiles(data.AttachedFiles);
  //   }
  //   // if(type=='task'){
  //   //   setEditTask(true);
  //   //   setEditSubTask(false);
  //   //   setEditMetaTask(false);
  //   // }
  //   // if(type=='subtask'){
  //   //   setEditTask(false);
  //   //   setEditSubTask(true);
  //   //   setEditMetaTask(false);
  //   // }
  //   // if(type=='metatask'){
  //   //   setEditTask(false);
  //   //   setEditSubTask(false);
  //   //   setEditMetaTask(true);
  //   // }
  //   setEditTask(true);
  //   subtaskCollapseOpen()
  //   seNewTaskState(false);
  //   setNestedModal(true);
  //   toggleSubtaskContainer(false);
  //   setOpenModalType(type);
  // };


  const openUpdateModal = async (data, type, parentdata, metaSubTask) => {
    setOpenModalType(type)
    setParentObject(data);
    setTaskAction('Edit');
    setNestedModal(true);
    selectedTask = data
    // if (type == 'task') {
    //   setOpenModalType(type)
    //   setParentObject(data);
    //   setTaskAction('Edit');
    //   setNestedModal(true);
    //   selectedTask = data
    // }
    // if (type == 'subtask') {
    //   selectedSubTask = data
    //   setOpenModalType(type);
    //   setSubtaskData(data);
    //   setTaskAction('Edit');
    //   setSubtaskNestedModal(true);
    //   setParentObject(parentdata);
    // }
    // if (type == 'metatask') {
    //   selectedMeataTask = data
    //   setOpenModalType(type);
    //   setMetaTaskData(data);
    //   setTaskAction('Edit');
    //   setParentObject(metaSubTask);
    //   setMetaTaskNestedModal(true);
    // }
    let tList = [...allTaskList];
    setDependencyDropdownData(tList);
  };
  const isTaskCompleteteMandatory = () => {
    let result = false;
    let filterTaskMandat = taskCustumeFieldList.filter(one => {
      if (one.Config) {
        if (one.Config.Mandatory && ((!one.Value) || one.Value.Value == '')) {
          return one;
        }
      }
    })
    if (filterTaskMandat.length > 0) {
      result = true;
    } else {
      result = false;
    }
    return result;
  }

  const [taskMandatoryStatus, setTaskMandatoryStatus] = useState(false);

  const [browseTemplatePopUp, setBrowseTemplatePopup] = useState(false);
  const [taskTemplates, setTaskTemplates] = useState([]);
  const browseTemplatePopUpToggle = () => setBrowseTemplatePopup(!browseTemplatePopUp);

  const openBrowseTemplatePopUp = async () => {
    taskArr = [];
    try {
      taskArr = await TaskService.GetMyTasks()
      // await getAllTask(projectId)
      setSelectedTemplate(null);
      setBrowseTemplatePopup(true);
    }
    catch (err) {
      console.log(err);
    }

    // let filterTaskTemplate = result.data.Data.filter(one => one.isTemplate);

    // HttpService.getByBoj("gettaskdocs", { DealsId: props.dealsId })
    //   .then((result) => {
    //     setSelectedTemplate(null);
    //     setBrowseTemplatePopup(true);
    //     let filterTaskTemplate = result.data.Data.filter(one => one.isTemplate);
    //     setTaskTemplates(filterTaskTemplate);
    //   })
    //   .catch((err) => {
    //     // swal(`${err}`);
    //   })

  }


  const handleDeleteTaskTemplate = () => {
    if (selectedTemplate) {
      HttpService.getByBoj("deleteTaskById", { _id: [selectedTemplate._id] })
        .then((result) => {
          openBrowseTemplatePopUp()
        })
        .catch((err) => {
          // swal(`${err}`, { dangerMode: true })
        })
    } else {
      swal('please select task template', { dangerMode: true })
    }

  }

  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const [useTemplatePopUp, setUseTemplatePopUp] = useState(false);
  const setUseTemplateToggle = () => setUseTemplatePopUp(!useTemplatePopUp);
  const keyLableTemplate = {
    'Due Date': 'DueDate', 'Start Date': 'StartDate',
    Attachments: "Attachements", 'Look Up Fields': "LookUpFields", Comments: "Comments", Status: 'Status', Priority: 'Priority', Subtask: 'Subtask', Assignee: 'Assignee', 'Input Fields': 'InputFields',
  }
  const handleClickUseTemplate = () => {
    let obj = { ...duplicateValueObj }
    let tasks = { ...selectedTemplate }
    let taskCustomFields = tasks.custom_fields.filter(one => one.column_name == 'UDF_MULTI1')
    if (taskCustomFields.length > 0) {
      taskCustomFields = taskCustomFields[0];
      let fields = taskCustomFields.value.split(',');
      fields = fields.map(one => {
        if (one && one.trim()) {
          return keyLableTemplate[one]
        }
      });
      let keys = Object.keys(obj);
      keys.forEach((key) => {
        if (!fields.includes(key)) {
          obj[key] = false
          // delete obj[key]
        }
      })
    }
    obj['Everything'] = true;
    setDuplicateValueObj(obj);
    setUseTemplatePopUp(true);
  }

  const BrowseTaskTemplateModal = () => {
    return <Modal
      isOpen={browseTemplatePopUp}
      backdrop={backdrop}
      size='md'
      toggle={browseTemplatePopUpToggle}
      className=" duplicate-task-modal"
    >

      <ModalBody >
        <Row>
          <Col sm={6}>
            <span className='float-left'>
              <h5>Browse Template</h5>
            </span>
          </Col>
          <Col sm={6}>
            <span className='float-right'>
              <FontAwesomeIcon style={{ cursor: 'pointer' }} onClick={browseTemplatePopUpToggle} icon={faTimes} />
            </span>
          </Col>

        </Row>
        <Row className="my-3">

          {taskArr && taskArr.filter((one) => {
            if (one.custom_fields && (one.custom_fields.filter((field) => (field.label_name == 'IsTemplate') && (field.value == 'true')).length > 0)) {
              return one
            }
          }).map((one) => {
            return <Col sm={4}><Card
              style={selectedTemplate && selectedTemplate.id_string == one.id_string ? { borderColor: 'green' } : {}}
              onClick={() => {
                setSelectedTemplate(one)
                // let obj = { ...one.Template }
                // obj.Everything = true;
                // obj.TaskName = '';
                // delete one.Template;
                // setParentObject(one);
                // setDuplicateValueObj(obj);
              }}
              className="task-template-card"
            >


              <p>{one.name}</p>
            </Card>
            </Col>
          })}



        </Row>

        <Row >
          <Col sm={12} className="text-right">

            <button
              onClick={handleClickUseTemplate}
              className='btn btn-primary btn-sm'
            >Use Template</button>

            <button
              className='btn btn-danger btn-sm ml-3'
              onClick={handleDeleteTaskTemplate}
            >Delete Template</button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  }


  const openSaveSubTaskModal = () => {
    setAddTaskPopup(false)
    setDisplayStartDueDate("");
    setActivityList([])
    setTaskCustumFieldList([])
    seNewTaskState(true);
    setSelectedTaskId({});
    setTaskName("");
    setDependencyDropdownData(allTaskList);
    setTaskDependency([]);
    setDescription("");
    setTaskStatus({ name: "PENDING", color: "#e50000" });
    setTaskCategory("DESIGN");
    setPriorityValue({ name: "", color: "#000" });
    setAssigneDropState([]);
    setSubTasks([]);
    toggleSubtaskContainer(false);
    setUpdateSubtaskId("");
    setTimeEstimateValue("");
    setTimeMinuteValue("");
    setOpenModalType('task');
    setCommentsBody([]);
    setTotalTimeTracked(0);
    setCheckList([]);
    setTimeTrackedData([]);
    settimetrackedPopover(false);
    clearInterval(interv);
    setInterv(0);
    setTimer(0);
    setClockIsActive(false);
    setAttachedFiles([]);
    if (prepareTaskData()._id !== "") {
      seNewTaskState(false);
      setEditTask(true);
    }
    setSubtaskCollapse(false);
  };

  const openSaveTaskModal = (taskType) => {
    setTaskAction('Create');
    setNestedModal(true);
    setOpenModalType(taskType);
  };



  const updatePriority = async (value, task, subTask, metaTask, oprtnType) => {
    let obj = {}
    if (!isRights('Priority')) {
      swal("you don't have sufficient privillages to perform this task!", { dangerMode: true });
      return
    }
    if (oprtnType === "UPDATE_TASK_PRIORITY") {
      prevPriority = task.Priority;
      obj.PrevState = task["Priority"]
      task["Priority"] = value;
      obj.UpdatedState = value;

      await updateTask(task);
      await checkAutomationAndExecute(value, task._id, 'Priority');
      addActivity('Update', 'Priority', task._id, task.TaskName, 0, obj);

    } else if (oprtnType === "UPDATE_SUBTASK_PRIORITY") {
      prevPriority = subTask.Priority;
      obj.PrevState = subTask["Priority"]
      subTask["Priority"] = value;
      obj.UpdatedState = value;

      await updateTask(subTask);
      await checkAutomationAndExecute(value, subTask._id, 'Priority');
      addActivity('Update', 'Priority', subTask._id, subTask.TaskName, 0, obj);
    } else if (oprtnType === "UPDATE_METASUBTASK_PRIORITY") {
      prevPriority = metaTask.Priority;
      obj.PrevState = metaTask["Priority"]
      metaTask["Priority"] = value;
      obj.UpdatedState = value;
      await updateTask(metaTask);
      await checkAutomationAndExecute(value, metaTask._id, 'Priority');
      addActivity('Update', 'Priority', metaTask._id, metaTask.TaskName, 0, obj);
    }


    getallTasks();
  };



  const updateDate = (value, task, subTask, metaTask, oprtnType) => {
    if (isRights('Due Date')) {
      let data = {}
      data.PrevState = task["DueDate"] ? convertStringToDate(new Date(task["DueDate"])) : null;
      if (oprtnType === "UPDATE_TASK_DATE") {

        task["DueDate"] = convertStringToDate(value);
        setUpdateTaskData(task);
        updateTask(task);
        data.UpdatedState = convertStringToDate(value)

        addActivity('Update', 'DueDate', task._id, task.TaskName, 0, data);
      } else if (oprtnType === "UPDATE_SUBTASK_DATE") {

        subTask["DueDate"] = convertStringToDate(value);
        setUpdateTaskData(subTask);
        updateTask(subTask);
        data.UpdatedState = convertStringToDate(value)

        addActivity('Update', 'DueDate', subTask._id, subTask.TaskName, 0, data);
      } else if (oprtnType === "UPDATE_METASUBTASK_DATE") {

        metaTask["DueDate"] = convertStringToDate(value);
        setUpdateTaskData(metaTask);
        updateTask(metaTask);
        data.UpdatedState = convertStringToDate(value)
        addActivity('Update', 'DueDate', metaTask._id, metaTask.TaskName, 0, data);
      }



      getallTasks();
    } else {
      swal("you don't have sufficient privillages to perform this task!", { dangerMode: true });
    }

  };

  const convertStringToDate = (value) => {
    let Fieldvalue = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }).format(value);

    return Fieldvalue;
  };

  const updateCategory = (value, task) => {
    let obj = {}
    obj.PrevState = task["Category"];
    obj.UpdatedState = value
    task["Category"] = value;
    addActivity('Update', 'Category', task._id, task.TaskName, 0, obj);
    updateTask(task);
    getallTasks();
  };

  const setStartDateOnCompleted = (status, task) => {
    if (status.name == 'COMPLETED') {
      let filterDependentTask = taskDocs.filter((one) => {
        if (one.DependentOn && one.DependentOn.filter((dependency) => dependency.id == task._id).length > 0) {
          return one;
        }
      })
      if (filterDependentTask.length > 0) {
        let taskData = { ...filterDependentTask[0] }
        taskData.StartDate = new Date(Date.now());
        updateTask(taskData);
      }
    }
  }

  const addTimeTrackForStatusUpdate = (data, value, estimate1) => {
    if (value.name == 'COMPLETED') {
      if (data.TimeTrack.length == 0) {
        const timeTrackObj = {
          ID: uuidv4(),
          UserID: localStorage.getItem("UserId"),
          InputBy: "Timer",
          current: false,
          StartTime: estimate1.start,
          StopTime: new Date(Date.now()),
        };
        data.TimeTrack.push(timeTrackObj);
        data.Status = value;
      } else if (data.TimeTrack.filter((one) => one.current).length > 0) {
        let obj = data.TimeTrack.filter((one) => one.current)[0]
        obj.current = false;
        obj.StopTime = new Date(Date.now());
        data.TimeTrack.filter((one) => one.current)[0] = obj;
      } else {
        const timeTrackObj = {
          ID: uuidv4(),
          UserID: localStorage.getItem("UserId"),
          InputBy: "Timer",
          current: false,
          StartTime: estimate1.start,
          StopTime: new Date(Date.now()),
        };
        data.TimeTrack.push(timeTrackObj);
        data.Status = value;
      }

    } else {
      const timeTrackObj = {
        ID: uuidv4(),
        UserID: localStorage.getItem("UserId"),
        InputBy: "Timer",
        current: true,
        StartTime: estimate1.start,
        StopTime: new Date(Date.now()),
      };
      data.TimeTrack.push(timeTrackObj);
      data.Status = value;
    }
    return data;
  }

  const isMatchStatus = (name, statusArr) => {
    if (statusArr && statusArr.includes(name)) {
      return true;
    } else {
      return false;
    }
  }

  const isRights = (item) => {
    if (props.isAccess && props.isAccess(props.selectedRole, 'Task', 'Write') && props.isAccess(props.selectedRole, item, 'Write')) {
      return true
    } else {
      return false
    }
  }


  const checkConditionWhen = (obj, compareCurrent, comparePrev) => {
    let returnResult = false;
    if (obj.value == 'Assignee changes') {
      if ((obj.selectedValue.filter(one => one.userId == compareCurrent.userId).length > 0)) {
        returnResult = true;
      }
    }
    else if (obj.value == 'Status changes' || obj.value == 'Priority changes') {

      if (((!obj.to) || (obj.to.length == 0) || (obj.to.filter(one => one.value == compareCurrent.name).length > 0)) && ((!obj.from) || (obj.from.length == 0) || (obj.from.filter(one => one.value == comparePrev.name).length > 0))) {
        returnResult = true;
      }
    }
    return returnResult;
  }

  const setTaskToInVariable = (taskType, taskId) => {
    const allTask = [...taskDocs];
    let task = allTask.filter(one => one._id == taskId)[0];
    switch (taskType) {
      case 0:
        selectedTask = task;
        break;
      case 1:
        selectedSubTask = task;
        break;
      case 2:
        selectedMeataTask = task;
        break;
      default:
        break;

    }
  }

  const actionThen = async (obj, taskId) => {
    let returnResult = false;
    let tasks = [...taskDocs];

    if (obj.value == 'Assignee changes') {
      if (obj.selectedValue) {
        for (let i = 0; i < obj.selectedValue.length; i++) {
          let res = await HttpService.CreateUpdate('getTaskRelationDocByTaskId', { TaskId: taskId });
          res = res.data[0];
          let user = obj.selectedValue[i];
          setTaskToInVariable(res.TaskType, res.TaskId);
          addRemoveTblListAssignee(user.name, user.userId, assigneeTaskType[res.TaskType], user.role)
        }
      }
    }
    else if (obj.value == 'Status changes') {
      let status = statusValues.filter(one => one.name == obj.selectedValue)[0];
      let res = await HttpService.CreateUpdate('getTaskRelationDocByTaskId', { TaskId: taskId });
      tasks = tasks.filter(one => one._id == taskId)[0]
      res = res.data[0];
      if (res.TaskType == 0) {
        await updateStatus(status, tasks, null, null, statusTaskType[res.TaskType]);
      }
      if (res.TaskType == 1) {
        await updateStatus(status, null, tasks, null, statusTaskType[res.TaskType]);
      }
      if (res.TaskType == 2) {
        await updateStatus(status, null, null, tasks, statusTaskType[res.TaskType]);
      }

      // let element = document.getElementById('status-click-id' + taskId + obj.selectedValue);
      // element.click();
    }
    else if (obj.value == 'Priority changes') {
      let priority = priorityValues.filter(one => one.name == obj.selectedValue)[0];
      let res = await HttpService.CreateUpdate('getTaskRelationDocByTaskId', { TaskId: taskId });
      tasks = tasks.filter(one => one._id == taskId)[0]
      res = res.data[0];
      if (res.TaskType == 0) {
        await updatePriority(priority, tasks, null, null, priorityTaskType[res.TaskType]);
      }
      if (res.TaskType == 1) {
        await updatePriority(priority, null, tasks, null, priorityTaskType[res.TaskType]);
      }
      if (res.TaskType == 2) {
        await updatePriority(priority, null, null, tasks, priorityTaskType[res.TaskType]);
      }
      // let element = document.getElementById('priority-click' + taskId + obj.selectedValue);
      // element.click();
    }
    else if (obj.value == 'Send Email') {
      let users = obj.selectedValue ? obj.selectedValue : [];
      let content = "<div style='border:0.5px;background-color: #e7ede3e6;width: 600px; margin:auto; padding:15px'><p style='font-weight: 600'>" + obj.body + "</p> <br></br><br></br>  <p style='margin: 0;'>Thank you,</p> <br></br> <p style='margin: 5px 0 30px;'>SRS CRM Team</p> "
      let res = await HttpService.CreateUpdate('AutomationEmailSender', { users: users, subject: obj.subject, body: obj.body, content: content });
     
    }
    return returnResult;
  }

  const actionThenDeal = async (obj, taskId) => {
    let returnResult = false;
    let tasks = [...taskDocs];

    if (obj.Property == 'Assignee') {
      if (obj.selectedValue) {
        for (let i = 0; i < obj.selectedValue.length; i++) {
          let res = await HttpService.CreateUpdate('getTaskRelationDocByTaskId', { TaskId: taskId });
          res = res.data[0];
          let user = obj.selectedValue[i];
          setTaskToInVariable(res.TaskType, res.TaskId);
          addRemoveTblListAssignee(user.name, user.userId, assigneeTaskType[res.TaskType], user.role)
        }
      }
    }
    else if (obj.Property == 'Status') {
      let status = statusValues.filter(one => one.name == obj.selectedValue)[0];
      let res = await HttpService.CreateUpdate('getTaskRelationDocByTaskId', { TaskId: taskId });
      tasks = tasks.filter(one => one._id == taskId)[0]
      res = res.data[0];
      if (res.TaskType == 0) {
        await updateStatus(status, tasks, null, null, statusTaskType[res.TaskType]);
      }
      if (res.TaskType == 1) {
        await updateStatus(status, null, tasks, null, statusTaskType[res.TaskType]);
      }
      if (res.TaskType == 2) {
        await updateStatus(status, null, null, tasks, statusTaskType[res.TaskType]);
      }

      // let element = document.getElementById('status-click-id' + taskId + obj.selectedValue);
      // element.click();
    }
    else if (obj.Property == 'Priority') {
      let priority = priorityValues.filter(one => one.name == obj.selectedValue)[0];
      let res = await HttpService.CreateUpdate('getTaskRelationDocByTaskId', { TaskId: taskId });
      tasks = tasks.filter(one => one._id == taskId)[0]
      res = res.data[0];
      if (res.TaskType == 0) {
        await updatePriority(priority, tasks, null, null, priorityTaskType[res.TaskType]);
      }
      if (res.TaskType == 1) {
        await updatePriority(priority, null, tasks, null, priorityTaskType[res.TaskType]);
      }
      if (res.TaskType == 2) {
        await updatePriority(priority, null, null, tasks, priorityTaskType[res.TaskType]);
      }
      // let element = document.getElementById('priority-click' + taskId + obj.selectedValue);
      // element.click();
    }
    else if (obj.value == 'Send Email') {
      let users = obj.selectedValue ? obj.selectedValue : [];
      let content = "<div style='border:0.5px;background-color: #e7ede3e6;width: 600px; margin:auto; padding:15px'><p style='font-weight: 600'>" + obj.body + "</p> <br></br><br></br>  <p style='margin: 0;'>Thank you,</p> <br></br> <p style='margin: 5px 0 30px;'>SRS CRM Team</p> "
      let res = await HttpService.CreateUpdate('AutomationEmailSender', { users: users, subject: obj.subject, body: obj.body, content: content });
    

    }
    return returnResult;
  }


  const isActionRequired = (action, task) => {
    let result = false;
    if (action.Property == 'Status') {
      if (task.Status.name !== action.selectedValue) {
        result = true;
      }
    }
    if (action.Property == 'Priority') {
      if (task.Priority.name !== action.selectedValue) {
        result = true;
      }
    }
    if (action.Property == 'Assignee') {
      if (task.Assignee.name !== action.selectedValue) {
        result = true;
      }
    }
    if (action.Property == 'Pipeline') {
      if (task.Pipeline !== action.selectedValue) {
        result = true;
      }
    }
    return result;
  }


  const checkAutomationAndExecute = async (value, taskId, docType) => {
    let autoMation = [...allAutomations];
    try {
      for (let a = 0; a < autoMation.length; a++) {
        let one = { ...autoMation[a] };
        let returnResult = await HttpService.CreateUpdate("executeAutomation", one);
        returnResult = returnResult.data;
        for (let i = 0; i < one.Then.Action.length; i++) {
          if (returnResult) {
            let action = { ...one.Then.Action[i] }
            if (action.Module == 'Task') {
              let result = await HttpService.CreateUpdate("taskCondition", action.Condition);
              let tasks = result.data.Result;
              result = result.data.isCheck;
              if (result) {
                for (let task = 0; task < tasks.length; task++) {
                  if (isActionRequired(action, tasks[task])) {
                    await actionThenDeal(action, tasks[task]._id);
                  } else {
                    break;
                  }

                }
              }
            }
          }
        }
      }
    }
    catch (ex) {
      swal(`${ex}`);
    }
  }




  // const checkAutomationAndExecute = async (value, taskId, docType) => {
  //   let autoMation = [...allAutomations];
  //   // autoMation=autoMation.filter(one=>one.IsActive);
  //   let tasks = [...taskDocs];

  //   autoMation = autoMation.filter(one => (one.When.TaskId == taskId));

  //   if (autoMation.length > 0) {

  //     for (let a = 0; a < autoMation.length; a++) {
  //       let returnResult = true;
  //       let one = autoMation[a];
  //       let task = tasks.filter((task) => task._id == one.When.TaskId)[0];
  //       // one.When.Condition.sort(function (a,b){
  //       //   if(a.value < b.value){
  //       //       return -1;
  //       //   }
  //       //   if(a.value > b.value){
  //       //       return 0;
  //       //   }
  //       //  })
  //       for (let j = 0; j < one.When.Condition.length; j++) {
  //         let when = one.When.Condition[j];
  //         if (when.value == 'Status changes') {
  //           if (when.to && (!prevStatus.name) && when.to.filter(one => one.value == task.Status.name).length > 0) {
  //             returnResult = (returnResult && true);
  //           } else {
  //             returnResult = (returnResult && checkConditionWhen(when, value, prevStatus))
  //           }
  //         }
  //         if (when.value == 'Priority changes') {
  //           if (when.to && (prevPriority.name !== '') && when.to.filter(one => one.value == task.Priority.name).length > 0) {
  //             returnResult = (returnResult && true);
  //           } else {
  //             returnResult = (returnResult && checkConditionWhen(when, value, prevPriority))
  //           }
  //         }
  //         if (when.value == 'Assignee changes') {
  //           let assignee = when.selectedValue.filter(comparer(task.Assignee));
  //           if (when.selectedValue && assignee.length > 0) {
  //             returnResult = (returnResult && true);
  //           } else {
  //             returnResult = (returnResult && checkConditionWhen(when, value, task['Assignee']))
  //           }
  //         }
  //       }
  //       one.Then.Action.sort(function (a, b) {
  //         if (a.value < b.value) {
  //           return -1;
  //         }
  //         if (a.value > b.value) {
  //           return 0;
  //         }
  //       })
  //       for (let i = 0; i < one.Then.Action.length; i++) {
  //         if (returnResult) {
  //           await actionThen(one.Then.Action[i], one.Then.TaskId)
  //         }
  //       }
  //     }
  //   }
  // }

  function comparer(otherArray) {
    return function (current) {
      return otherArray.filter(function (other) {
        return other.userId == current.userId
      }).length > 0;
    }
  }




  const updateStatus = async (value, task, subTask, metaTask, oprtnType) => {
    let waitingOn = [];
    let data;
    let obj = {}

    if (!isRights('Status')) {

      swal("you don't have sufficient privillages to perform this task!", { dangerMode: true });
      return
    }

    if (oprtnType === "UPDATE_TASK_STATUS") {
      //Start Set Start Date Functionality

      prevStatus = task.Status;
      //End Start Date Functionality
      task.DependentOn.forEach((x, i) => {
        let ta = allTaskList.find((t) => t.id == x.id);
        if (isMatchStatus(ta.status.name, x.DependencyStatus) && x.type == "Waiting-On") {
          waitingOn.push(ta.name);
        }
      });
      let existMain = false;
      for (let i = 0; i < task.DependentOn.length; i++) {
        if ((task.DependentOn[i].type == 'Waiting-On') && (task.DependentOn[i].Status) && (task.DependentOn[i].Status.filter(s => s.value == value.name).length > 0)) {
          existMain = true;
          break;
        }
      }
      if ((waitingOn.length > 0) && existMain) {
        // swal("still waiting on" + JSON.stringify(waitingOn), { dangerMode: true });
        setTaskWaitingList(waitingOn);
        //  setTaskCompleteWarning(true);
      } else {
        obj.PrevStatus = task["Status"];
        task["Status"] = value;

        obj.UpdatedState = value;
      }
      data = { ...task };
    } else if (oprtnType === "UPDATE_SUBTASK_STATUS") {
      prevStatus = subTask.Status;
      subTask.DependentOn.forEach((x, i) => {
        let ta = allTaskList.find((t) => t.id == x.id);
        if (isMatchStatus(ta.status.name, x.DependencyStatus) && ((x.type == "Waiting-On"))) {
          waitingOn.push(ta.name);
        }
      });
      let existSub = false;
      for (let i = 0; i < subTask.DependentOn.length; i++) {
        if (((subTask.DependentOn[i].type == 'Waiting-On')) && (subTask.DependentOn[i].Status) && (subTask.DependentOn[i].Status.filter(s => s.value == value.name).length > 0)) {
          existSub = true;
          break;
        }
      }
      if ((waitingOn.length > 0) && existSub) {
        // alert("still waiting on" + JSON.stringify(waitingOn));
        setTaskWaitingList(waitingOn);
        // setTaskCompleteWarning(true);
      } else {
        obj.PrevStatus = subTask["Status"];
        subTask["Status"] = value;

        obj.UpdatedState = value;
      }
      data = { ...subTask };

      // obj.PrevStatus = subTask["Status"];
      // subTask["Status"] = value;
      // data = { ...subTask };
      // obj.UpdatedState = value;
      // subTask.DependentOn.forEach((x, i) => {
      //   let ta = allTaskList.find((t) => t.id == x.id);
      //   if (ta.status.name != "COMPLETED" && x.type == "Waiting-On") {
      //     waitingOn.push(ta.name);
      //   }
      // });
      // if (waitingOn.length > 0 && value.name == "COMPLETED") {
      //   alert("still waiting on" + JSON.stringify(waitingOn));
      // } else {
      //   subTask["Status"] = value;
      // }
    } else if (oprtnType === "UPDATE_METASUBTASK_STATUS") {
      prevStatus = metaTask.Status;
      metaTask.DependentOn.forEach((x, i) => {
        let ta = allTaskList.find((t) => t.id == x.id);
        if (isMatchStatus(ta.status.name, x.DependencyStatus) && x.type == "Waiting-On") {
          waitingOn.push(ta.name);
        }
      });
      let existMeta = false;
      for (let i = 0; i < metaTask.DependentOn.length; i++) {
        if ((metaTask.DependentOn[i].type == 'Waiting-On') && (metaTask.DependentOn[i].Status) && (metaTask.DependentOn[i].Status.filter(s => s.value == value.name).length > 0)) {
          existMeta = true;
          break;
        }
      }
      if ((waitingOn.length > 0) && existMeta) {
        // alert("still waiting on" + JSON.stringify(waitingOn));
        setTaskWaitingList(waitingOn);
        // setTaskCompleteWarning(true);
      } else {
        obj.PrevStatus = metaTask["Status"];
        metaTask["Status"] = value;
        obj.UpdatedState = value;
      }
      data = { ...metaTask };
      // obj.PrevStatus = metaTask["Status"];
      // metaTask["Status"] = value;
      // obj.UpdatedState = value;
      // data = { ...metaTask };
      // metaTask.DependentOn.forEach((x, i) => {
      //   let ta = allTaskList.find((t) => t.id == x.id);
      //   if (ta.status.name != "COMPLETED" && x.type == "Waiting-On") {
      //     waitingOn.push(ta.name);
      //   }
      // });
      // if (waitingOn.length > 0 && value.name == "COMPLETED") {
      //   alert("still waiting on" + JSON.stringify(waitingOn));
      // } else {
      //   metaTask["Status"] = value;
      // }
    }
    setStartDateOnCompleted(value, data)
    let dependencyStatus = data.DependentOn;
    let exist = false;
    for (let i = 0; i < dependencyStatus.length; i++) {
      if ((dependencyStatus[i].type == 'Waiting-On' || dependencyStatus[i].type == 'Blocking') && (dependencyStatus[i].Status) && (dependencyStatus[i].Status.filter(s => s.value == value.name).length > 0)) {
        exist = true;
        break;
      }
    }
    // if (value.name == 'COMPLETED') {
    //  if ((data.DependentOn.length > 0)) {
    let status = await checkCheckTaskStatus(data, value)
    if (status) {
      setActivityObj(obj);
      // setParentToSubtaskStatusCompleteWar(true);
      setStatusForUpdate(value)
      setTaskCompleteWarning(true);
      data.Status = value
      setDataForUpdate(data);
      getallTasks();
    } else {
      if (data.isMilestone) {
        if (data.Pipeline) {
          let res = await HttpService.CreateUpdate('getPipeline', { PipelineName: data.Pipeline })
          if (res.data.ServiceAssociated.length > 0) {
            data.Milestone.DateTime = new Date(Date.now())
          }
        }

      }

      if ((data.Assignee.length == 0) && (statusForWarn.includes(value.name))) {
        swal(`please add assignee to ${value.name.toLowerCase()} the task`, { dangerMode: true });
      }
      else {
        if (statusForWarn.includes(value.name)) {
          let estimate1 = getEstimateDuration(new Date(data.AssignDatetime), data.TimeEstimateValue);
          if ((estimate1.start <= new Date(Date.now())) && (estimate1.end >= new Date(Date.now()))) {

            let estimateDur = getDiffInHourAndMiniutes(estimate1.start, new Date(Date.now()));
            estimateDur = `${estimateDur.hours}:${estimateDur.minutes}`
            //data.TimeEstimateValue = estimateDur;
            data = addTimeTrackForStatusUpdate(data, value, estimate1)
            await updateTask(data);
            await checkAutomationAndExecute(value, data._id, 'Status')
            data = { ...data };
            data.TimeEstimateValue = estimateDur;
            await taskReAssign(data.Assignee[0], data)
          }
          else if ((estimate1.start > new Date(Date.now())) && statusForWarn.includes(value.name)) {
            swal('task not started ', { dangerMode: true });
          }
          else {
            let estimateDur = getDiffInHourAndMiniutes(estimate1.start, new Date(Date.now()));
            estimateDur = `${estimateDur.hours}:${estimateDur.minutes}`
            //data.TimeEstimateValue = estimateDur;
            data = addTimeTrackForStatusUpdate(data, value, estimate1)
            await updateTask(data);
            await checkAutomationAndExecute(value, data._id, 'Status')
            data = { ...data };
            data.TimeEstimateValue = estimateDur;
            await taskReAssign(data.Assignee[0], data)
            // swal(`you are delay to ${value.name.toLowerCase()} the task`, { dangerMode: true });
          }
        } else {
          data.Status = value;
          await updateTask(data);
          await checkAutomationAndExecute(value, data._id, 'Status')
          if (waitingOn.length == 0) {
            addActivity('Update', 'Status', data._id, data.TaskName, 0, obj);
          }
          getallTasks();
        }


      }


      getallTasks();
    }
    // }


    //else {
    // if ((data.Assignee.length == 0) && (statusForWarn.includes(value.name))) {
    //   swal(`please add assignee to ${value.name.toLowerCase()} the task`, { dangerMode: true });
    // } else {
    //   data.Status=value;
    //   updateTask(data);
    //   if (waitingOn.length == 0) {
    //     addActivity('Update', 'Status', data._id, data.TaskName, 0, obj);
    //   }
    //   getallTasks();
    // }

    // }


  };



  const [assigneeSearch, setAssigneeSearch] = useState("");
  const [searchResult, setSearchResult] = useState([]);

  const handleAssigneeSearch = (name) => {
    let users = [...cacheUsersArr];

    setAssigneeSearch(name);
    let AssignUsers = users.filter(one => {
      if (one.FirstName.toLowerCase().includes(name.toLowerCase())) {
        return one;
      }

    });
    setSearchResult(AssignUsers);
  }

  const [activityObj, setActivityObj] = useState()
  const [dataForUpdate, setDataForUpdate] = useState(null);
  const [childTaskForWarinig, setChildTask] = useState([]);
  const [systemWarningState, setSystemWarningState] = useState(false)
  const [systemWarningText, setSystemWarningText] = useState("")
  const systemWarningToogle = () => setSystemWarningState(!systemWarningState)
  const [parentToSubtaskStatusCompleteWarinig, setParentToSubtaskStatusCompleteWar] = useState(false)
  const parentToSubtaskStatusCompleteWarinigToogle = () => setParentToSubtaskStatusCompleteWar(!parentToSubtaskStatusCompleteWarinig)
  const warningTaskStatusCompleteModal = () => {
    return <Modal
      isOpen={parentToSubtaskStatusCompleteWarinig}
      backdrop={backdrop}
      size='md'
      toggle={parentToSubtaskStatusCompleteWarinigToogle}
      className="taskComplete-warning"
    >

      <ModalBody>
        <Row >
          <Col sm={12}>
            <FontAwesomeIcon icon={faExclamationTriangle} size='3x' className="text-warning" />
            <h5 className="my-2">Dependency Warning</h5>
            <p className="taskComplete-warning-subtitle">The status of following subtask  must be completed first</p>
          </Col>

        </Row>
        <Row >
          <Col sm={12}>
            <p>Task Name</p>
            <ul className="textField-list">
              {childTaskForWarinig.map((one) => {
                return <li>{one.TaskName}</li>
              })}
            </ul>
            <hr />
          </Col>

        </Row>
        <Row >
          <Col sm={12}>
            <button
              className='btn btn-success w-100'
              onClick={() => {
                swal({
                  title: "Are you sure?",
                  text: "forcely complete the status for all sub task for this",
                  icon: "warning",
                  buttons: true,
                  dangerMode: true,
                })
                  .then(async (willDelete) => {
                    if (willDelete) {
                      let arr = [];
                      let arr1 = []
                      arr.push(updateTask(dataForUpdate))
                      arr1.push(addActivity('Update', 'Status', dataForUpdate._id, dataForUpdate.TaskName, 0, activityObj))
                      childTaskForWarinig.forEach((one) => {
                        one = { ...one }
                        one.Status = { name: "COMPLETED", color: "#f900ea" }
                        arr.push(updateTask(one))
                        arr1.push(addActivity('Update', 'Status', one._id, one.TaskName, 0, activityObj))
                      })
                      let res1 = await Promise.all(arr1)
                      let res = await Promise.all(arr);
                      setParentToSubtaskStatusCompleteWar(false);
                      swal("Successfully complete this task and sub task", {
                        icon: "success",
                      });
                    } else {
                      swal("you cancel  for the update task");
                    }
                  });
              }}
            >Update</button>
            <button onClick={() => setParentToSubtaskStatusCompleteWar(false)} className='btn btn-secondary w-100 mt-2'>Cancel</button>
          </Col>

        </Row>
      </ModalBody>
    </Modal>
  }



  const warningStytemMsg = () => {
    return <Modal
      isOpen={systemWarningState}
      backdrop={backdrop}
      size='md'
      toggle={systemWarningToogle}
      className="taskComplete-warning"
    >

      <ModalBody>
        <Row >
          <Col sm={12}>
            <FontAwesomeIcon icon={faExclamationTriangle} size='3x' className="text-warning" />
            <h5 className="my-2">System Dependency Warning</h5>
            <p className="taskComplete-warning-subtitle">{systemWarningText}</p>
          </Col>

        </Row>
        <Row >
          <Col sm={12}>
            <p>Task Name</p>
            <ul className="textField-list">
              {childTaskForWarinig.map((one) => {
                return <li>{one.name}</li>
              })}
            </ul>
            <hr />
          </Col>

        </Row>
        <Row >
          <Col sm={12}>
            {/* <button
              className='btn btn-success w-100'
             
            >Update</button> */}
            <button onClick={() => setSystemWarningState(false)} className='btn btn-secondary w-100 mt-2'>OK</button>
          </Col>

        </Row>
      </ModalBody>
    </Modal>
  }


  // const checkCheckTaskStatus = async (task) => {
  //   let result = await HttpService.CreateUpdate('getTaskRelationDocByParentTaskId', { ParentTaskId: task._id })
  //   let childTask = []
  //   taskDocs.forEach((one) => {
  //     result.data.forEach(task => {
  //       if (one._id == task.TaskId) {
  //         childTask.push(one);
  //       }
  //     })
  //   })
  //   if (childTask.length > 0) {
  //     let filterStatus = childTask.filter(one => one.Status.name !== 'COMPLETED')
  //     if (filterStatus.length > 0) {
  //       setChildTask(filterStatus);
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   } else {
  //     return false;
  //   }
  // }
  const checkCheckTaskStatus = async (task, status) => {
    let childTask = []
    let blockingTask = []
    taskDocs.forEach((one) => {
      one.DependentOn.forEach(d => {
        if ((d.id == task._id) && (d.type == 'Blocking')) {
          if (isMatchStatus(status.name, d.Status) && isMatchStatus(one.Status.name, d.DependencyStatus)) {
            blockingTask.push(one);
          }
        }
      })
    })
    let waitingTask = []
    task.DependentOn.forEach((d) => {
      taskDocs.forEach(one => {
        if ((d.id == one._id) && (d.type == 'Waiting-On')) {
          if (!isMatchStatus(one.Status.name, d.Status)) {
            if (isMatchStatus(status.name, d.DependencyStatus)) {
              waitingTask.push(one);
            }
          }
        }
      })




    })
    //  taskDocs.forEach((one)=>{
    //   one.DependentOn.forEach(d=>{
    //      if((d.id==task._id)&&(d.type=='Waiting-On')){
    //        if(!isMatchStatus(status.name,d.Status)&&!isMatchStatus(one.Status.name,d.DependencyStatus)){
    //         waitingTask.push( one);
    //        }
    //      }
    //    })
    //  })
    childTask = [...blockingTask, ...waitingTask]
    childTask = _.unionBy(childTask, '_id')
    if (childTask.length > 0) {
      setChildTask(childTask);
      return true;
      // let filterStatus = childTask.filter(one => one.Status.name !== 'COMPLETED')
      // if (filterStatus.length > 0) {
      //   setChildTask(filterStatus);
      //   return true;
      // } else {
      //   return false;
      // }
    } else {
      return false;
    }
  }

  const checkCheckTaskStatus1 = async (task, status) => {
    let result = await HttpService.CreateUpdate('getTaskRelationDocByParentTaskId', { ParentTaskId: task._id })
    let childTask = []

    task.DependentOn.forEach((depe) => {
      if (depe.type == 'Waiting-On') {
        let taskData = taskDocs.filter(t => t._id == depe.id);
        if (taskData.length > 0) {
          let dependency = taskData[0].DependentOn.filter(d => d.id == task._id);
          if (dependency.length > 0) {
            if (dependency[0].Status && isMatchStatus(status.name, dependency[0].Status)) {
              let filterTask = taskDocs.filter(t => t._id == depe.id);
              if (filterTask.length > 0) {
                if (depe.Status && isMatchStatus(filterTask[0].Status.name, depe.DependencyStatus)) {
                  childTask.push(filterTask[0]);
                }
              }
            }
          }
        }
      } else {
        if (depe.DependencyStatus && isMatchStatus(status.name, depe.DependencyStatus)) {
          let filterTask = taskDocs.filter(t => t._id == depe.id);
          if (filterTask.length > 0) {
            if (depe.Status && isMatchStatus(filterTask[0].Status.name, depe.Status)) {
              childTask.push(filterTask[0]);
            }
          }
        }
      }



    })
    // props.taskDocs.forEach((one) => {
    //   result.data.forEach(task => {
    //     if (one._id == task.TaskId) {
    //       childTask.push(one);
    //     }
    //   })
    // })
    if (childTask.length > 0) {
      setChildTask(childTask);
      return true;
      // let filterStatus = childTask.filter(one => one.Status.name !== 'COMPLETED')
      // if (filterStatus.length > 0) {
      //   setChildTask(filterStatus);
      //   return true;
      // } else {
      //   return false;
      // }
    } else {
      return false;
    }
  }


  const updateMultipleTaskObj = (taskObjArr) => {
    let doArr = []
    taskObjArr.forEach((oneTask) => {
      let formData = new FormData();
      formData.append("data", JSON.stringify(oneTask));
      doArr.push(HttpService.getByBoj("updatetask", formData))
    })
    Promise.all(doArr)
      .then((result) => {
        setTaskUpdateWhere('')
        setUpdateTaskData(null);
        getallTasks();
      })
      .catch((err) => {
        // swal(`${err}`);
      })
  }

  const updateTaskWhenPipelineAdd = (taskType, pipeline) => {
    if (taskType == 'subtask') {
      const taskObj = { ...parentObj }
      taskObj.Pipeline = pipeline;
      setParentObject(taskObj)
      updateMultipleTaskObj([taskObj])
    }
    if (taskType == 'metatask') {
      const taskObj1 = { ...subTaskData }
      const taskObj2 = { ...parentObj }
      taskObj1.Pipeline = pipeline;
      taskObj2.Pipeline = pipeline;
      setParentObject(taskObj2)
      setSubtaskData(taskObj1)
      updateMultipleTaskObj([taskObj1, taskObj2])
    }
  }

  const updateTask_Single = async (data) => {
    let formData = new FormData();
    formData.append("data", JSON.stringify(data));

    let result = await HttpService.getByBoj("updatetask", formData);
    return result;
  };

  const updateTask = (tasks, dataKey, value) => {
 

  };

  const [openremoveAssigneeModal, setOpenRemoveAssigneeModal] = useState(false);
  const [assigneeID, setAssigneeID] = useState("");
  const [saveTaskID, setSaveTaskID] = useState("");

  const handleRemoveAssigne = (userId, taskId) => {
    let allUsersName = [...allUsers];
    let taskData = [...taskDocs];
    let filterTasks = taskData.filter(obj => obj._id === taskId)[0];


    if (deleteWarnObj.id == 'assignee') {
      let findAssignee = filterTasks.Assignee;

      let filterAssignee = findAssignee.filter(e => e.userId != userId);

      filterTasks.Assignee = filterAssignee;
      if (filterTasks.Assignee.length == 0) {
        filterTasks.Status = { name: "PENDING", color: "#e50000" }
      }
      updateTask(filterTasks);
    }
    if (deleteWarnObj.id == 'superviser') {
      let findSuperviser = filterTasks.Superviser;

      let filterSuperviser = findSuperviser.filter(e => e.userId != userId);

      filterTasks.Superviser = filterSuperviser;

      updateTask(filterTasks);
    }


  }


  const getDependencyContent = (dependencyArr) => {
    let waitingOnLength = dependencyArr.filter(one => one.type == 'Waiting-On').length;
    let blockingLength = dependencyArr.filter(one => one.type == 'Blocking').length;
    return <div className="show-dependency-modal">
      {waitingOnLength > 0 ? <div style={{ border: '0.5px solid gray' }}>
        <li className="dependency-list">

          <FontAwesomeIcon
            icon={faMinusCircle}
            color={
              "#f8d800"
            }
          />
          {" "}

          <span
            style={
              { fontSize: 12, color: "#f8d800", textTransform: 'uppercase' }
            }
          >
            Waiting On
          </span>
        </li>
        {dependencyArr.map((dependency) => {
          if (dependency.type == 'Blocking') {
            return <></>
          }
          return <li className="dependency-list">
            <span
              className="dependency-status-icon"
              color={
                dependency.type == "Blocking"
                  ? "#f40600"
                  : "#f8d800"
              }
            ></span>

            {" "}




            <span style={{ fontSize: 12, marginLeft: 5 }}>
              {dependency.TaskName}
            </span>



          </li>
        })}

      </div> : <></>}
      {blockingLength > 0 ? <div className="mt-1" style={{ border: '0.5px solid gray' }}>
        <li className="dependency-list">

          <FontAwesomeIcon
            icon={faMinusCircle}
            color={
              "#f40600"
            }
          />
          {" "}

          <span
            style={
              { fontSize: 12, color: "#f40600", textTransform: 'uppercase' }
            }
          >
            Bloking
          </span>
        </li>
        {dependencyArr.map((dependency) => {
          if (dependency.type == 'Waiting-On') {
            return <></>
          }
          return <li className="dependency-list">
            <span
              className="dependency-status-icon"
              color={
                dependency.type == "Blocking"
                  ? "#f40600"
                  : "#f8d800"
              }
            ></span>



            <span style={{ fontSize: 12, marginLeft: 5 }}>
              {dependency.TaskName}
            </span>
          </li>
        })}

      </div> : <></>}
    </div>
  }
  const openRemoveAssigneWarningModal = () => {
    return (
      <Modal
        isOpen={openremoveAssigneeModal}
        backdrop={'static'}
        size='md'
        toggle={openremoveAssigneeModal}
        className="taskComplete-warning"
      >

        <ModalBody>
          <Row >
            <Col sm={12}>
              <FontAwesomeIcon icon={faExclamationTriangle} size='3x' className="text-warning" />
              <h5 className="my-2">{deleteWarnObj.header}</h5>
              <p className="taskComplete-warning-subtitle">{deleteWarnObj.text}</p>
            </Col>

          </Row>
          <Row >
            <Col sm={12}>
              <button
                className='btn btn-primary w-100'
                onClick={() => {
                  setOpenRemoveAssigneeModal(!openremoveAssigneeModal)
                  handleRemoveAssigne(assigneeID, saveTaskID)
                }}
              >Yes</button>

              <button onClick={() => {
                setOpenRemoveAssigneeModal(!openremoveAssigneeModal)
              }} className='btn btn-secondary w-100 mt-2'>No</button>
            </Col>

          </Row>
        </ModalBody>
      </Modal>
    )
  }

  const addAssigneeImgTblImg = (assignee, taskId) => {
    let name = assignee.first_name + " " + assignee.last_name;
    if (assignee.name == 'Unassigned') {
      name = assignee.name;
    }
    if (assignee.id && (assignee.id != "")) {
      // if (assignee.id != "") {
      return <>
        <UncontrolledTooltip placement='top' target={'user-name-tooltip' + assignee.id + taskId}>
          {name}
        </UncontrolledTooltip>
        <div className="assignee-img">
          <img
            id={'user-name-tooltip' + assignee.id + taskId}
            src={UserService.GetUserPhotoUrl(assignee.id)}
            //src={getAssigneeImg(assignee.id)}
            onClick={() => {
              // setSelectedTaskDropdown(taskId)
              // setSaveTaskID(taskId)
              // setSelectedAssigneeDropdown(assignee.userId);


              // handleRemoveAssigne(assignee.userId, taskId)
            }}
            className="rounded-circle selected-avtar"
          />
          <FontAwesomeIcon

            icon={faTimes}
            className="remove-icon"
            onClick={() => {
              // setAssigneeID(assignee.id)
              // setSaveTaskID(taskId)
              // setOpenRemoveAssigneeModal(!openremoveAssigneeModal)
              // setDeleteWarnObj({ id: 'assignee', header: 'Assignee Delete Warning', text: 'Are you sure? You want to remove Assignee.' })
              // // handleRemoveAssigne(assignee.userId, taskId)
            }}
          />
        </div>

      </>
    }
    else {
      return <>  <UncontrolledTooltip placement='top' target={'user-name-tooltip' + assignee.id + taskId}>
        {name}
      </UncontrolledTooltip>
        <div className="assignee-img">
          <div onClick={() => {

            setSaveTaskID(taskId)

            // handleRemoveAssigne(assignee.userId, taskId)
          }} id={'user-name-tooltip' + assignee.id + taskId} className='no-image-user'>{name.charAt(0)}</div>
          <FontAwesomeIcon

            icon={faTimes}
            className="remove-icon"
            onClick={() => {
              setAssigneeID(assignee.id)
              setSaveTaskID(taskId)
              setOpenRemoveAssigneeModal(!openremoveAssigneeModal)
              // handleRemoveAssigne(assignee.userId, taskId)
            }}
          />
        </div>

      </>
    }

  };

  const addSuperviserImgTblImg = (assignee, taskId) => {
    if (getAssigneeImg(assignee.userId) != "") {
      return <>
        <UncontrolledTooltip placement='top' target={'user-name-tooltip' + assignee.userId + taskId}>
          {assignee.userName}
        </UncontrolledTooltip>
        <div className="assignee-img">
          <img
            id={'user-name-tooltip' + assignee.userId + taskId}
            src={getAssigneeImg(assignee.userId)}
            onClick={() => {
              setSelectedTaskDropdown(taskId)
              setSaveTaskID(taskId)
              setSelectedAssigneeDropdown(assignee.userId);


              // handleRemoveAssigne(assignee.userId, taskId)
            }}
            className="rounded-circle selected-avtar"
          />
          <FontAwesomeIcon

            icon={faTimes}
            className="remove-icon"
            onClick={() => {
              setAssigneeID(assignee.userId)
              setSaveTaskID(taskId)
              setOpenRemoveAssigneeModal(!openremoveAssigneeModal);
              setDeleteWarnObj({ id: 'superviser', header: 'Superviser Delete Warning', text: 'Are you sure? You want to remove superviser.' })

              // handleRemoveAssigne(assignee.userId, taskId)
            }}
          />
        </div>

      </>
    }
    else {
      return <>  <UncontrolledTooltip placement='top' target={'user-name-tooltip' + assignee.userId + taskId}>
        {assignee.userName}
      </UncontrolledTooltip>
        <div className="assignee-img">
          <div onClick={() => {

            setSaveTaskID(taskId)

            // handleRemoveAssigne(assignee.userId, taskId)
          }} id={'user-name-tooltip' + assignee.userId + taskId} className='no-image-user'>{assignee.userName.charAt(0)}</div>
          <FontAwesomeIcon

            icon={faTimes}
            className="remove-icon"
            onClick={() => {
              setAssigneeID(assignee.userId)
              setSaveTaskID(taskId)
              setOpenRemoveAssigneeModal(!openremoveAssigneeModal)
              // handleRemoveAssigne(assignee.userId, taskId)
            }}
          />
        </div>

      </>
    }
    // return (
    //   <img
    //     src={getAssigneeImg(assignee.userId)}
    //     className="rounded-circle selected-avtar"
    //   />
    // );
  };

  // const addAssigneeImgTblImg = (assignee) => {

  //   return (
  //     <img
  //       src={getAssigneeImg(assignee.userId)}
  //       className="rounded-circle selected-avtar"
  //     />
  //   );
  // };

  const addTaskDropdownMenuItem = (user, tasks) => {
    return addDropdownMenuItem(
      "ADD_TASK_BY_LIST",
      user,
      tasks,
      NOT_DEFINED_VALUE,
      NOT_DEFINED_VALUE
    );
  };

  const addSubTaskDropdownMenuItem = (user, tasks, subTask) => {
    return addDropdownMenuItem(
      "ADD_SUBTASK_BY_LIST",
      user,
      tasks,
      subTask,
      NOT_DEFINED_VALUE
    );
  };

  const addMetaTaskDropdownMenuItem = (user, tasks, subTask, metaTask) => {
    return addDropdownMenuItem(
      "ADD_METASUBTASK_BY_LIST",
      user,
      tasks,
      subTask,
      metaTask
    );
  };


  const addSuperviserTaskDropdownMenuItem = (user, tasks) => {
    return addSuperviserDropdownMenuItem(
      "ADD_TASK_BY_LIST",
      user,
      tasks,
      NOT_DEFINED_VALUE,
      NOT_DEFINED_VALUE
    );
  };

  const addSuperviserSubTaskDropdownMenuItem = (user, tasks, subTask) => {
    return addSuperviserDropdownMenuItem(
      "ADD_SUBTASK_BY_LIST",
      user,
      tasks,
      subTask,
      NOT_DEFINED_VALUE
    );
  };

  const addSuperviserMetaTaskDropdownMenuItem = (user, tasks, subTask, metaTask) => {
    return addSuperviserDropdownMenuItem(
      "ADD_METASUBTASK_BY_LIST",
      user,
      tasks,
      subTask,
      metaTask
    );
  };

  const getStatusOfGroupBy = (group, task, groupValue) => {
    if (group == 'Assignee') {
      let str = ''
      if ((task.details.owners.length > 0) && task.details.owners.filter(one => one.name == 'Unassigned').length == 0) {
        task.details.owners.forEach((one) => {
          let name = one.first_name + " " + one.last_name;
          str = str + name + 'EOU'
        })
      } else {
        str = 'Unassignee'
      }

      if (str == groupValue) {
        return true;
      } else {
        return false;
      }
    } else if (group == "Priority") {
      let name = (task[group.toLowerCase()] == '') ? 'NO PRIORITY' : task[group.toLowerCase()];
      if (name == groupValue) {
        return true
      } else {
        return false;
      }
    }
    else if (group == "Milestone") {
      let name = (task.tasklist.name == '') ? 'NO MILESTONE' : task.tasklist.name;
      if (name == groupValue) {
        return true
      } else {
        return false;
      }
    }
    else if (group == "Due Date") {
      let name = ((!task.end_date) || (task.end_date == '')) ? 'N/A' : task.end_date;
      if (name == groupValue) {
        return true
      } else {
        return false;
      }
    }
    else if (group == "Superviser") {
      if ('NO SUPERVISER' == groupValue) {
        return true
      } else {
        return false;
      }
    }
    else if (group == "None") {

      if ('NONE' == groupValue) {
        return true
      } else {
        return false;
      }
    }
    else if (group == "Category") {
      let value = task && task.custom_fields && task.custom_fields.filter(one => one.label_name == 'Category').length > 0
      if (value) {
        let field = task.custom_fields.filter(one => one.label_name == 'Category')[0];
        let name = (field.value == '') ? 'NO CATEGORY' : field.value;
        if (name == groupValue) {
          return true
        } else {
          return false;
        }
      } else {
        if (groupValue == 'NO CATEGORY') {
          return true
        } else {
          return false
        }
      }
    }
    else if (group == "Pipeline") {
      let name = (!task.project_name) ? 'NO PIPELINE' : task.project_name;
      if (name == groupValue) {
        return true
      } else {
        return false;
      }
    } else {
      if ((group == "Status"
        ? task[group.toLowerCase()].name
        : task[group.toLowerCase()]) == groupValue) {
        return true;
      } else {
        return false;
      }
    }


  }



  const [employeeCalenderPopUp, setEmployeeCalenderPopUp] = useState(false)
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedTaskType, setSelectedTaskType] = useState('')
  const [selectedCalenderTask, setSelectedCalenderTask] = useState(null);
  const employeeCalenderPopUpToogle = () => setEmployeeCalenderPopUp(!employeeCalenderPopUp);

  const employeeCalenderModal = () => {
    // return <Modal
    //   isOpen={employeeCalenderPopUp}
    //   size='md'
    //   backdrop={backdrop}
    //  // toggle={employeeCalenderPopUpToogle}
    //  // className="taskComplete-warning"
    // >

    //   <ModalBody>
    //     <Row >
    //       <Col sm={12}>
    //         <FontAwesomeIcon icon={faCalendar} size='2x' className="text-warning" />
    //         <h5 className="my-2">Employee Calender</h5>
    //         {/* <p className="taskComplete-warning-subtitle">This task is waiting on required fields or tasks to be completed </p> */}
    //       </Col>

    //     </Row>
    //     <Row>
    //       <Col sm={12}>
    //         <EmployeeCalender/>
    //       </Col>
    //     </Row>

    //   </ModalBody>
    // </Modal>
  }

  const modifiers = {
    preventOverflow: {
      enabled: false,
    },
    flip: {
      enabled: false,
    },
  };


  const content = (
    <></>
    // <div>
    //   <p>Content</p>
    //   <p>Content</p>
    //   {/* <button onClick={() => alert('alert')}></button> */}
    // </div>
    // <EmployeeCalender from='popover' taskDocs={taskDocs}
    //   checkAndRescheduleTaskFromPopover={checkAndRescheduleTaskFromPopover}
    //   selectedTaskType={selectedTaskType} getTasks={getallTasks}
    //   task={selectedCalenderTask} selectedEmployee={selectedEmployee}
    //   taskReAssign={taskReAssign} />
  );
  const [selectedEmployeePopover, setSelectedEmployeePopover] = useState(false);

  const handleChnageVisible = (event) => {
    setSelectedEmployeePopover(event)
  }
  const addDropdownMenuItem = (oprtnType, user, tasks, subtask, metaTask) => {
    let userName = user.full_name;
    if (userName == 'All') {
      return <></>
    }
    return (
      <>
        <div className="empCalendar-popover" >
          <div className='row'>
            <div className='col-sm-8'>
              <DropdownItem
                className="position-relative"
                onMouseEnter={() => {
                  setSelectedTaskType(oprtnType);
                  setSelectedEmployee(user);
                  setEmployeeCalenderPopUp(true)
                }}
                onMouseLeave={() => {
                  setSelectedTaskType('');
                  setEmployeeCalenderPopUp(false)
                }}
                value={userName}
                onClick={(e) => {
                  // setSelectedEmployee(user);
                  //    setEmployeeCalenderPopUp(true);
                  // return 
                  if (isRights('Assignee')) {
                    selectedTask = tasks;
                    selectedSubTask = subtask;
                    selectedMeataTask = metaTask;
                    setSelectedTaskId({ task: tasks });
                    addRemoveTblListAssignee(userName, user.UserId, oprtnType, user.Role);
                  } else {
                    swal("you don't have sufficient privillages to perform this task!", { dangerMode: true });
                  }

                }}

              >
                {user.zuid && user.zuid !== '' ?
                  <img
                    src={UserService.GetUserPhotoUrl(user.zuid)}
                    //alt={avtar}
                    className="mr-1 rounded-circle width25"
                  />
                  : <div className='no-image-user'>{userName.charAt(0)}</div>}
                {userName}








              </DropdownItem>
            </div>
            {/* <div className='col-sm-2 mt-2'>
              <AntdPopover
                onVisibleChange={(visible) => handleChnageVisible(visible)}
                //visible={selectedEmployee&&selectedEmployee.UserId==user.UserId}
                // onMouseEnter={()=>setEmployeeCalenderPopUp(true)}

                placement="left" trigger="click" className="empCalendar-popover" content={content} title={selectedEmployee ? selectedEmployee.FirstName + " " + selectedEmployee.LastName + " Calender" : 'Employee Calender'}
              >
                <FontAwesomeIcon onClick={() => {
                  setSelectedTaskType(oprtnType);
                  setSelectedEmployee(user);
                  setEmployeeCalenderPopUp(true)
                }} className="ml-2" color="blue" icon={faCalendar} />
              </AntdPopover>
            </div> */}

          </div>






        </div>

      </>
    );
  };


  const addUserDropdwonItem = (user) => {
    let userName = user.full_name;
    if (userName == 'All') {
      return <></>
    }
    return (
      <>
        <div className="empCalendar-popover" >
          <div className='row'>
            <div className='col-sm-8'>
              <DropdownItem
                className="position-relative"
                onClick={(e) => {
                }}
              >
                {user.zuid && user.zuid !== '' ?
                  <img
                    src={UserService.GetUserPhotoUrl(user.zuid)}
                    //alt={avtar}
                    className="mr-1 rounded-circle width25"
                  />
                  : <div className='no-image-user'>{userName.charAt(0)}</div>}
                {userName}
              </DropdownItem>
            </div>
          </div>
        </div>
      </>
    );

  }

  const addSuperviserDropdownMenuItem = (oprtnType, user, tasks, subtask, metaTask) => {
    let userName = user.FirstName + " " + user.LastName;
    return (
      <>
        <div className="empCalendar-popover" >

          <DropdownItem
            className="position-relative"
            onMouseEnter={() => {
              setSelectedTaskType(oprtnType);
              setSelectedEmployee(user);
              setEmployeeCalenderPopUp(true)
            }}
            onMouseLeave={() => {
              setSelectedTaskType('');
              setEmployeeCalenderPopUp(false)
            }}
            value={userName}
            onClick={(e) => {
              // setSelectedEmployee(user);
              //    setEmployeeCalenderPopUp(true);
              // return 
              if (isRights('Supervisers')) {
                selectedTask = tasks;
                selectedSubTask = subtask;
                selectedMeataTask = metaTask;
                setSelectedTaskId({ task: tasks });
                addRemoveTblListSuperviser(userName, user.UserId, oprtnType, user.Role);
              } else {
                swal("you don't have sufficient privillages to perform this task!");
              }

            }}
          >
            {user.ProfilePhoto && user.ProfilePhoto !== '' ?
              <img
                src={user.ProfilePhoto}
                //alt={avtar}
                className="mr-1 rounded-circle width25"
              />
              : <div className='no-image-user'>{userName.charAt(0)}</div>}
            {userName}


          </DropdownItem>

        </div>

      </>
    );
  };

  const getTaskId = (oprationType, task, sub, meta) => {
    if (oprationType === "TASK_DROPDOWN_MENU") {
      return task._id
    }
    else if (oprationType === "SUBTASK_DROPDOWN_MENU") {
      return sub._id;
    } else {
      return meta._id;
    }
  }

  const [selectedTaskDropdown, setSelectedTaskDropdown] = useState(null);
  const [selectedAssigneeDropdown, setSelectedAssigneeDropdown] = useState(null)

  const toggleDropdwon = (e) => {
    if (selectedTaskDropdown && (!selectedEmployeePopover)) {
      setSelectedTaskDropdown(null)
    }
  }

  const getSupervisor = (task, fetchType) => {
    const users = [...allUsers];
    let data = { ...task }
    let clientid = props.dealObj.ClientId
    let category = data.Category;
    let filterUser = users.filter((one => {
      if (((one.Category.length > 0) && (one.Category[0].label == category)) && (one.ClientUnderSupervision.filter((client) => client.id == clientid).length > 0)) {
        return one;
      }
    }))
    if (fetchType !== 'full') {
      filterUser.forEach((one, index, self) => {
        self[index] = { userId: one.UserId, UserName: one.FirstName + " " + one.LastName };
      })
    }

    return filterUser;
  }



  const getSuperviserDropdownMenu = (
    tasks,
    fnName,
    assignee,
    oprationType,
    subTask,
    metaTask
  ) => {
    let recentTask = (oprationType === "TASK_DROPDOWN_MENU" ? tasks : oprationType === "SUBTASK_DROPDOWN_MENU" ? subTask : metaTask)
    return (
      <Dropdown ref={dropdownRef}
        toggle={toggleDropdwon}

        isOpen={superviserOpenFlag && selectedTaskDropdown && (selectedTaskDropdown == getTaskId(oprationType, tasks, subTask, metaTask)) &&
          ((selectedAssigneeDropdown == assignee) || (selectedAssigneeDropdown && selectedAssigneeDropdown.userId == assignee.userId))} className="transparent-dropdown assignee-dropdown">
        <DropdownToggle>
          {assignee === null ? fnName('', getTaskId(oprationType, tasks, subTask, metaTask)) : fnName(assignee, getTaskId(oprationType, tasks, subTask, metaTask))}
        </DropdownToggle>

        <DropdownMenu>
          <div className="dropdownHeader-search">
            <input
              type="text"
              placeholder="Search..."
              value={assigneeSearch}
              className='form-control form-control-sm'
              onChange={(e) => handleAssigneeSearch(e.target.value)}
            />
          </div>

          {assigneeSearch === "" ? getSupervisor(recentTask, 'full').map((user, i) =>
            oprationType === "TASK_DROPDOWN_MENU"
              ? addSuperviserTaskDropdownMenuItem(user, tasks)
              : oprationType === "SUBTASK_DROPDOWN_MENU"
                ? addSuperviserSubTaskDropdownMenuItem(user, tasks, subTask)
                : addSuperviserMetaTaskDropdownMenuItem(user, tasks, subTask, metaTask)
          ) : searchResult.map((user, i) =>
            oprationType === "TASK_DROPDOWN_MENU"
              ? addSuperviserTaskDropdownMenuItem(user, tasks)
              : oprationType === "SUBTASK_DROPDOWN_MENU"
                ? addSuperviserSubTaskDropdownMenuItem(user, tasks, subTask)
                : addSuperviserMetaTaskDropdownMenuItem(user, tasks, subTask, metaTask)
          )}
          {/* {clearAssigneeTag(oprationType, tasks, subTask, metaTask)} */}
        </DropdownMenu>


      </Dropdown>
    );
  };

  const getDropdownMenu = (
    tasks,
    fnName,
    assignee,
    oprationType,
    subTask,
    metaTask
  ) => {
    return (
      <Dropdown ref={dropdownRef}
        toggle={toggleDropdwon}

        isOpen={assigneeOpenFlag && selectedTaskDropdown && (selectedTaskDropdown == getTaskId(oprationType, tasks, subTask, metaTask)) &&
          ((selectedAssigneeDropdown == assignee) || (selectedAssigneeDropdown && selectedAssigneeDropdown.userId == assignee.userId))} className="transparent-dropdown assignee-dropdown">
        <DropdownToggle>
          {assignee === null ? fnName('', getTaskId(oprationType, tasks, subTask, metaTask)) : fnName(assignee, getTaskId(oprationType, tasks, subTask, metaTask))}
        </DropdownToggle>

        <DropdownMenu style={{ maxHeight: 150, overflowY: 'auto' }}>
          <div className="dropdownHeader-search">
            <input
              type="text"
              placeholder="Search..."
              value={assigneeSearch}
              className='form-control form-control-sm'
              onChange={(e) => handleAssigneeSearch(e.target.value)}
            />
          </div>

          {assigneeSearch === "" ? allUsers.map((user, i) =>
            oprationType === "TASK_DROPDOWN_MENU"
              ? addTaskDropdownMenuItem(user, tasks)
              : oprationType === "SUBTASK_DROPDOWN_MENU"
                ? addSubTaskDropdownMenuItem(user, tasks, subTask)
                : addMetaTaskDropdownMenuItem(user, tasks, subTask, metaTask)
          ) : searchResult.map((user, i) =>
            oprationType === "TASK_DROPDOWN_MENU"
              ? addTaskDropdownMenuItem(user, tasks)
              : oprationType === "SUBTASK_DROPDOWN_MENU"
                ? addSubTaskDropdownMenuItem(user, tasks, subTask)
                : addMetaTaskDropdownMenuItem(user, tasks, subTask, metaTask)
          )}
          {/* {clearAssigneeTag(oprationType, tasks, subTask, metaTask)} */}
        </DropdownMenu>


      </Dropdown>
    );
  };

  const clearAssigneeTag = (oprationType, tasks, subTask, metaTask) => {
    return (
      <DropdownItem
        value=""
        onClick={() => {
          selectedTask = tasks;
          selectedSubTask = subTask;
          selectedMeataTask = metaTask;
          clearAllTblListAssgnee(oprationType);
        }}
      >
        {" "}
        <FontAwesomeIcon
          icon={faTimes}
          style={{
            color: "red",
            marginRight: "10px",
          }}
        />
        CLEAR
      </DropdownItem>
    );
  };
  const changeGroupByTaskList = (e) => {
    setGroupBy(e);
    getGroupByFields(allTasks, e);
    // e.preventDefault();
  };
  

  const getProjectIds = (Ids) => {
    if (Ids) {
      Ids = Ids.split(',');
      Ids = Ids.filter(id => id);
      return Ids;
    } else {
      return [];
    }

  }

 

  // function to save form data
  // const saveUpdateTaskData = async () => {
  //   //
  //   // if()
  //   var id="";
  //   if(selectedTaskId.task._id!="" && selectedTaskId.task._id!= undefined){
  //     id=selectedTaskId.task._id;
  //   }
  //   const taskData = {
  //     _id: selectedTaskId.task._id,
  //     Status: taskStatus,
  //     TaskName: taskName,
  //     Description: description,
  //     Assignee: assigneDropState,
  //     Category: taskCategory,
  //     DueDate: projectDate,
  //     Priority: priorityValue,
  //     Subtask: subTasks,
  //     TimeEstimateValue:timeEstimateValue,
  //         };
  //   const status = await HttpService.CreateUpdate(
  //     newTask ? "addtask" : "updatetask",
  //     taskData
  //   ).then((res) => {
  //     if (res.data.Ack == 1) {
  //       getallTasks();
  //     }
  //   });
  // };

  // const saveUpdateTaskData = async () => {
  //   var id=""

  //   // if()
  //   if(selectedTaskId.task !=undefined){
  //          id=selectedTaskId.task._id;
  //        }
  //   const taskData = {
  //     _id: id,
  //     Status: taskStatus,
  //     TaskName: taskName,
  //     Description: description,
  //     Assignee: assigneDropState,
  //     Category: taskCategory,
  //     DueDate: projectDate,
  //     Priority: priorityValue,
  //     Subtask: subTasks,
  //     TimeEstimateValue: timeEstimateValue+":"+timeMinuteValue,
  //         };
  //   const status = await HttpService.CreateUpdate(
  //     newTask ? "addtask" : "updatetask",
  //     taskData
  //   ).then((res) => {

  //     if (res.data.Ack == 1) {
  //       getallTasks();
  //     }
  //   });
  // };

  const generateAssignee = () => {
    assigneDropState.forEach((asgnData) => {
      if (asgnData.userId === "") {
        asgnData.userId = localStorage.getItem("UserId");
        asgnData.userName = localStorage.getItem("UserName");
      }
    });
    return assigneDropState;
  };

  const isAllMandatoriesValid = (operationType) => {

    let flag = true;
    if (operationType !== 'updateList') {
      if (taskName.length === 0 || description.length === 0) {
        flag = false;
      }
    }
    return flag;
  };

  const prepareTaskData = () => {
    var id = "";
    if (selectedTaskId.task != undefined) {
      id = selectedTaskId.task._id;
    }
    let taskData = {
      _id: id,
      DealsId: DealsId,
      Status: taskStatus,
      TaskName: taskName,
      DependentOn: taskDependency,
      Description: description,
      //Assignee: generateAssignee(),
      Assignee: assigneDropState,
      Category: taskCategory,
      StartDate: selectionRange[0].startDate,
      DueDate: selectionRange[0].endDate,
      Priority: priorityValue,
      Subtask: subTasks,
      TimeEstimateValue:
        parseInt(timeEstimateValue) <= 0
          ? timeEstimateValue + ':' + timeMinuteValue.toString()
          : timeEstimateValue + ":" + timeMinuteValue,
      TimeTrack: currentData,
      Checklist: newTask ? CheckList : currentCheckList,
      AttachedFiles: AttachedFiles,
    };
    return taskData;
  };

  const [taskUpdateWhere, setTaskUpdateWhere] = useState('')

  const saveUpdateTaskData = async (operationType) => {
    // const taskData = {
    //   _id: id,
    //   DealsId: DealsId,
    //   Status: taskStatus,
    //   TaskName: taskName,
    //   Description: description,
    //   //Assignee: generateAssignee(),
    //   Assignee: assigneDropState,
    //   Category: taskCategory,
    //   StartDate: selectionRange[0].startDate,
    //   DueDate: selectionRange[0].endDate,
    //   Priority: priorityValue,
    //   Subtask: subTasks,
    //   TimeEstimateValue:
    //     parseInt(timeEstimateValue) <= 0
    //       ? timeMinuteValue.toString()
    //       : timeEstimateValue + ":" + timeMinuteValue,
    //   TimeTrack: currentData,
    //   Checklist: newTask ? CheckList : currentCheckList,
    //   AttachedFiles: AttachedFiles,
    // };
    let isvalidData = isAllMandatoriesValid(operationType);
    if (isvalidData && openmodalType === "task") {
      let data = prepareTaskData();
      setUpdateTaskData(data)
      addUpdateTaskApi(data, data);
      // if(taskActionType=='subtask'){

      // }else{
      //   addUpdateTaskApi(data1?data1:data, data);
      // }

    } else if (openmodalType == "subtask") {
      let data = prepareTaskData();
      setUpdateTaskData(data)

      addUpdateTaskApi(data, data);
      // alert("Mandatory fields missing ");
    }
    else if (openmodalType == "metatask") {
      let data = prepareTaskData();
      setUpdateTaskData(data)

      addUpdateTaskApi(data, data);
    }
    else {
      swal("Mandatory fields missing ", { dangerMode: true });
    }
    // addUpdateTaskApi(taskData);
  };

  const saveTaskNameAndDescription = () => {

    let data = { ...prepareTaskData() };
    if (taskName.length > 0 && description.length > 0) {

      // saveUpdateTaskData();
      addUpdateTaskApi(data);
    }

    // else if(taskName.length !== 0 || description > 0){
    //   alert("Please fill mandatory fields");
    // }
  }




  const addUpdateTaskApi = async (taskData, updateData) => {
    if (newTask) {
      if (isTaskAlreadyExist(taskData.TaskName)) {
        swal('task name already exist in this deal ', { dangerMode: true });
        return
      }
      let formData = new FormData();
      formData.append("data", JSON.stringify(taskData));
      try {
        let status = await HttpService.CreateUpdate(
          "addtask",
          formData,
          {}
        ).then(async (res) => {
          if (res.data.Ack == 1) {
            let obj = {}
            setSelectedTaskId({ task: res.data.Data })
            setParentObject(res.data.Data)
            obj.TaskId = res.data.Data._id;
            obj.TaskName = res.data.Data.TaskName;
            obj.DealId = res.data.Data.DealsId;
            obj.TaskType = 0;
            obj.ParentTaskId = null;
            await addActivity('ADD', 'TASK', res.data.Data._id, res.data.Data.TaskName, obj.TaskType)
            return HttpService.CreateUpdate('addTaskRelation', obj)

          }
        })
          .then((result) => {
            let doArr = []
            taskData.Subtask.forEach((one) => {
              if (one._id) {
                doArr.push(updateSubTask(one));
              } else {
                doArr.push(addSubTask(one, 1, result.data.Data.TaskId));
              }

            })
            return Promise.all(doArr);
          })
          .then((result) => {
            seNewTaskState(false);
            setEditTask(true);

            getallTasks();
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      if (editTask) {
        updateSubTask(taskData)
          .then((result) => {
            let doArr = []
            taskData.Subtask.forEach((one) => {
              if (one._id) {
                doArr.push(updateSubTask(one));
                one.Subtask.forEach((meta) => {
                  if (meta._id) {
                    doArr.push(updateSubTask(meta));
                  } else {
                    doArr.push(addSubTask(meta, 2));
                  }
                })
              } else {
                doArr.push(addSubTask(one, 1));
                one.Subtask.forEach((meta) => {
                  if (meta._id) {
                    doArr.push(updateSubTask(meta));
                  } else {
                    doArr.push(addSubTask(meta, 2));
                  }
                })
              }

            })
            return Promise.all(doArr);
          })
          // .then((result)=>{
          //   let doArr=[]
          //   taskData.Subtask.forEach((one)=>{
          //     if(one._id){
          //       doArr.push(updateSubTask(one));
          //     }else{
          //       doArr.push(addSubTask(one));
          //     }

          //   })
          //   return Promise.all(doArr);
          // })
          .catch((err) => {
            //swal(`${err}`)
          })
      } else {
        if (!newTaskData) {
          let data = { ...taskData }
          delete data.SubtaskId
          let formData = new FormData();
          formData.append("data", JSON.stringify(data));
          HttpService.CreateUpdate(
            "addtask",
            formData,
          ).then((res) => {
            if (res.data.Ack == 1) {
              let obj = {}
              obj.TaskId = res.data.Data._id;
              obj.TaskName = res.data.Data.TaskName;
              obj.DealId = res.data.Data.DealsId;
              obj.TaskType = 1;
              obj.ParentTaskId = parentObj._id;
              return HttpService.CreateUpdate('addTaskRelation', obj)
            }
          })
            .then((result) => {
              getallTasks();
            })
            .catch((err) => {
              //swal(`${err}`, { dangerMode: true })
            });
        } else {
          let data = { ...newTaskData }
          delete data.SubtaskId
          let formData = new FormData();
          formData.append("data", JSON.stringify(data));
          HttpService.CreateUpdate(
            "addtask",
            formData,
          ).then((res) => {
            if (res.data.Ack == 1) {
              let obj = {}
              obj.TaskId = res.data.Data._id;
              obj.TaskName = res.data.Data.TaskName;
              obj.DealId = res.data.Data.DealsId;
              obj.TaskType = 1;
              obj.ParentTaskId = parentObj._id;
              return HttpService.CreateUpdate('addTaskRelation', obj)
            }
          })
            .then((result) => {
              getallTasks();
            })
            .catch((err) => {
              //swal(`${err}`, { dangerMode: true })
            });
        }

      }

      // let status = await HttpService.CreateUpdate(
      //   "updatetask",
      //   formData,
      //   {}
      // ).then((res) => {
      //   if (res.data.Ack == 1) {
      //     getallTasks();
      //   }
      // });
    }
  };

  const updateSubTask = async (data) => {
    data = { ...data }
    data.Subtask = []
    let formData = new FormData();
    formData.append("data", JSON.stringify(data));

    HttpService.CreateUpdate(
      "updatetask",
      formData,
    ).then((res) => {
      if (res.data.Ack == 1) {
        // swal(res.data.Message)
        setTaskUpdateWhere('');
        getallTasks();
        setUpdateTaskData(null);
        // let obj = {}
        // obj.TaskId = res.data.Data._id;
        // obj.TaskName = res.data.Data.TaskName;
        // obj.DealId = res.data.Data.DealsId;
        // obj.TaskType = 1;
        // obj.ParentTaskId = parentObj._id;
        // return HttpService.CreateUpdate('addTaskRelation', obj)
      }
    })
      // .then((result)=>{

      // })
      .catch((err) => {
        //swal(`${err}`, { dangerMode: true })
      });
  }

  const getTime = (date) => {
    let Time = new Date(date);
    var hours = Time.getHours();
    var minutes = Time.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;

  };

  // const getDate = (date) => {
  //   date = new Date(date);
  //   let str = '';
  //   str = str + date.toLocaleString('en-us', { month: 'short' });
  //   str = str + " " + date.getDate();

  //   return str;
  // }

  function getDate(date) {
    let dateObj = new Date(date);
    let d = dateObj.toString().split(" ");
    let month = d[1]; //months from 1-12
    let day = dateObj.getDate();
    let year = dateObj.getFullYear();
    day = day < 10 ? "0" + day : day;
    month = month < 10 ? "0" + month : month;
    //return day + "/" + month + "/" + year;
    // if (year === now.years()) {
    //   return month + " " + day;
    // } else {

    return month + " " + day;
    // }
  }

  const addSubTask = async (data, taskType, id) => {
    data = { ...data };
    delete data.SubtaskId
    // if(isTaskAlreadyExist(data.TaskName)){
    //   swal('task name already exist in this deal');
    //   return
    // }
    if (!isTaskAlreadyExist(data.TaskName)) {
      let formData = new FormData();
      formData.append("data", JSON.stringify(data));
      HttpService.CreateUpdate(
        "addtask",
        formData,
      ).then(async (res) => {
        if (res.data.Ack == 1) {
          let obj = {}
          obj.TaskId = res.data.Data._id;
          obj.TaskName = res.data.Data.TaskName;
          obj.DealId = res.data.Data.DealsId;
          obj.TaskType = taskType;
          obj.ParentTaskId = id ? id : parentObj._id;
          setSubTasks([res.data.Data]);
          await addActivity('ADD', 'TASK', res.data.Data._id, res.data.Data.TaskName, taskType);
          return HttpService.CreateUpdate('addTaskRelation', obj)
        }
      })
        .then((result) => {
          getallTasks();
        })
        .catch((err) => {
          //swal(`${err}`, { dangerMode: true })
        });
    } else {
      swal('task name already exist in this deal ', { dangerMode: true });
    }

  }

  const saveUpdateSubTaskData = async () => {
    // var subtask = selectedTaskId.subtask;
    var subtask = selectedSubTaskId.subtask;
    subtask.Status = taskStatus;
    subtask.TaskName = taskName;
    subtask.DependentOn = taskDependency;
    subtask.Description = description;
    subtask.Assignee = assigneDropState;
    // subtask.Category: taskCategory,
    subtask.StartDate = selectionRange[0].startDate;
    subtask.DueDate = selectionRange[0].endDate;
    subtask.Priority = priorityValue;
    subtask.Subtask = subTasks;
    subtask.TimeEstimateValue =
      parseInt(timeEstimateValue) <= 0
        ? timeMinuteValue.toString()
        : timeEstimateValue + ":" + timeMinuteValue;
    subtask.TimeTrack = currentData;
    subtask.Checklist = currentCheckList;
    var task = selectedSubTaskId.task;
    selectedSubTaskId.task.Subtask.map((st, i) => {
      if (st.SubtaskId == subtask.SubtaskId) {
        task.Subtask[i] = subtask;
      }
    });

    setUpdateTaskData(subtask);
    addUpdateTaskApi(task, subtask);
  };


  // const [updateTask,setUpdateTask]=useState(null);
  const [updateTaskData, setUpdateTaskData] = useState(null);

  const saveUpdateMetaSubTaskData = async () => {
    var task = selectedSubTaskId.task;
    var subtask = selectedSubTaskId.subtask;
    var metatask = selectedSubTaskId.metatask;
    metatask.Status = taskStatus;
    metatask.TaskName = taskName;
    metatask.DependentOn = taskDependency;
    metatask.Description = description;
    metatask.Assignee = assigneDropState;
    metatask.StartDate = selectionRange[0].startDate;
    metatask.DueDate = selectionRange[0].endDate;
    metatask.Priority = priorityValue;
    metatask.TimeEstimateValue =
      parseInt(timeEstimateValue) <= 0
        ? timeMinuteValue.toString()
        : timeEstimateValue + ":" + timeMinuteValue;
    metatask.TimeTrack = currentData;
    metatask.Checklist = currentCheckList;
    task.Subtask.forEach((sub, i) => {
      if (sub.SubtaskId === subtask.SubtaskId) {
        sub.Subtask.forEach((meta) => {
          if (meta.SubtaskId === metatask.SubtaskId) {
            meta = metatask;
          }
        });
      }
    });

    //   selectedSubTaskId.task.Subtask.map((st,i)=>{
    //  if(st.SubtaskId==subtask.SubtaskId){
    //   st.Subtask.map((mt,j)=>{
    //     if(mt.SubtaskId==metatask.SubtaskId){
    //       task.Subtask[i].Subtask[j]=metatask;
    //     }
    //   })a
    //  }
    //   })
    setUpdateTaskData(metatask)
    addUpdateTaskApi(task, metatask);
  };

  const getDropDownStatusForNewFiels = (className, status) => {
    return <UncontrolledDropdown>
      <DropdownToggle
        className={'badge ' + className}
      >
        {status}
      </DropdownToggle>
      <DropdownMenu>
        {statusValues.map((value, i) => {
          return (
            <DropdownItem
              style={{
                backgroundColor: value.color,
                color: "#fff",
              }}
            // onClick={(e) =>
            //   updateStatus(
            //     value,
            //     tasks,
            //     null,
            //     null,
            //     "UPDATE_TASK_STATUS"
            //   )
            // }
            >
              {value.name}
            </DropdownItem>
          );
        })}
      </DropdownMenu>
    </UncontrolledDropdown>
  }

  const getSpaceBetweenCamelString = (string) => {
    string = string.replace(/([A-Z])/g, ' $1').trim();
    return string;
  }

  const [addEditField, setAddEditFiels] = useState(false);

  const addEditFieldToggle = () => setAddEditFiels(!addEditField);
  const [openExistModal, setOpenExistModal] = useState(false);

  const existModalToggle = () => {

    setOpenExistModal(!openExistModal)

  }

  const getTotalCustomeTaskEmphtyField = () => {
    let i = 0;
    taskCustumeFieldList.forEach((one) => {
      if (!one.Value) {
        i = i + 1;
      }
    })
    return i;
  }

  const [taskCustumeFieldList, setTaskCustumFieldList] = useState([])

  const GetAndCheckTaskFieldMandtoryToComplete = async (taskId) => {
    const backendUrl = Config.backend_url;
    let res = await axios.post(backendUrl+'getTaskFields', { TaskId: taskId });
    let result = false;
    let filterTaskMandat = res.data.Data.filter(one => {
      if (one.Config) {
        if (one.Config.Mandatory && ((!one.Value) || one.Value.Value == '')) {
          return one;
        }
      }
    })
    if (filterTaskMandat.length > 0) {
      result = true;
      setTaskCustumFieldList(res.data.Data);
    } else {
      result = false;
    }
    return result;

  }

  const GetAllTaskCustumeFieldList = (taskId) => {
    const backendUrl = Config.backend_url;
    // HttpService.CreateUpdate('getTaskFields', { TaskId: taskId })
    axios.post(backendUrl+'getTaskFields', { TaskId: taskId })
      .then((result) => {
        setTaskCustumFieldList(result.data.Data);
        let columns = [...addColumns];
        result.data.Data.forEach((one) => {
          let filterColumn = columns.filter(obj => obj.headerName == one.Name);
          if (filterColumn.length == 0) {
            let obj = { headerName: one.Name, isVisible: false };
            columns.push(obj)
          }

        })
        setAddColumns(columns);
      })
      .catch((err) => {
        //swal(`${err}`, { dangerMode: true });
      })
  }



  const [openCustomFields, setOpenCustomFields] = useState(false);

  const customFieldsToggle = () => {
    setOpenCustomFields(!openCustomFields);
    setTaskFieldExist(false);
  }
  // const [addEditField,setAddEditFiels]=useState(false);

  // const addEditFieldToggle=()=>setAddEditFiels(!addEditField);
  const [delTooltipOpen, setDelTooltipOpen] = useState(false);

  const delToggle = () => setDelTooltipOpen(!delTooltipOpen);

  const [editTooltipOpen, setEditTooltipOpen] = useState(false);

  const editToggle = () => setEditTooltipOpen(!editTooltipOpen);

  const handleTaskFieldDelete = (taskFieldObj) => {
    let bacUrl = 'https://crm.srsolardesign.com:3006/api/v1/'

    // HttpService.deleteByID('deleteTaskField', taskFieldObj._id)
    axios.post(bacUrl+'deleteTaskField', taskFieldObj._id)
      .then((result) => {
        GetAllTaskCustumeFieldList(taskFieldObj.TaskId);
      })
      .catch((err) => {
        //swal(`${err}`, { dangerMode: true });
      })
  }

  const [addLookFieldsPopUp, setAddLookupFieldsPopUp] = useState(false);
  const addLookUpFieldsToggle = () => setAddLookupFieldsPopUp(!addLookFieldsPopUp);
  const handleClickAddLookUpFields = () => {
    setAddLookupFieldsPopUp(true);
  }

  const [lookUpSearch, setLookUpSearch] = useState(null);

  const [openTimeEstimatePopUp, setOpenTimeEstimatePopUp] = useState(false);
  const [openDueDatePopUp, setOpenDueDatePopUp] = useState(false);

  const handleOnChangeSearchLookUpFields = (e) => {
    if (e.target.value == '') {
      setLookUpSearch(null);
    } else {
      setLookUpSearch(e.target.value.toLowerCase());
    }

  }

  const addLookUpFields = (name, value) => {
    let bacurl = Config.backend_url;
    const obj = { ...taskFieldObj };
    obj.Type = 'LookUp';
    obj.Name = name;
    obj.Label = name;
    obj.Value = { Value: value };
    obj.TaskId = parentObj._id;
    obj.TaskName = parentObj.TaskName;
    obj.DealId = parentObj.DealsId;
    obj.UserName = localStorage.getItem('UserName');
    obj.UserId = localStorage.getItem('UserId');
    obj.Config = taskFieldConfigObj;
    axios.post(bacurl + 'createTaskField', obj)
      .then((result) => {

        GetAllTaskCustumeFieldList(parentObj._id)
        setTaskFieldConfigObj(null);
        setTaskFieldObj(null)
        // addLookUpFieldsToggle();
        setAddLookupFieldsPopUp(true);
        //swal('Create Success');
      })
      .catch((err) => {
        swal(`${err}`, { dangerMode: true });
      })
  }

  const renderAddLookUpFieldsModal = () => {
    return <Modal
      isOpen={addLookFieldsPopUp}
      backdrop={backdrop}
      toggle={addLookUpFieldsToggle} size='sm'
      className="customFieldModal"
      id="manageFieldModal"
    >
      <ModalHeader className="text-center" toggle={addLookUpFieldsToggle}>LOOKUP FIELDS</ModalHeader>
      <ModalBody>
        {/* <h6 className="addCustomField-title">LOOKUP FIELDS</h6> */}
        <div className="fieldSearch w-100">
          <input name='LookUpSearch' onChange={handleOnChangeSearchLookUpFields} className="form-control" type="text" placeholder="Search..." />
          <FontAwesomeIcon icon={faSearch} />
        </div>
        <table className='table table-sm table-hover addCustomField-table addLookupFieldTbl'>
          {Object.keys(props.dealObj).map(key => {
            if (key == 'Positions' || key == 'Attachments' || key == 'pvSystemInfo' || key == 'ContactRoles' || key == 'wwpViolation' || key == 'zoningList' || key == 'commercialList' || key == 'specialDistList' || key == 'UniqueId' || key == '_id' || key == '__v' || key == 'createdAt' || key == 'updatedAt' || key == 'ClientId' || taskCustumeFieldList.filter((one) => one.Name == key).length > 0) {
              return;
            }
            if (lookUpSearch) {
              if (key.toLowerCase().includes(lookUpSearch)) {
                return <tr>
                  <td>
                    <FontAwesomeIcon icon={faEye} className="field-icon" />
                    {getSpaceBetweenCamelString(key)}
                  </td>
                  <td>
                    {props.dealObj[key]}
                  </td>
                  <td onClick={() => addLookUpFields(key, props.dealObj[key])} style={{ cursor: 'pointer' }} className="labels">
                    <span  ><FontAwesomeIcon icon={faPlusCircle} color='red' /> Add</span>
                  </td>
                </tr>
              }
            }
            if (!lookUpSearch) {
              return <tr>
                <td>
                  <FontAwesomeIcon icon={faEye} className="field-icon" />
                  {getSpaceBetweenCamelString(key)}
                </td>
                <td>
                  {props.dealObj[key]}
                </td>
                <td style={{ cursor: 'pointer' }} onClick={() => addLookUpFields(key, props.dealObj[key])} className="labels">
                  <span><FontAwesomeIcon icon={faPlusCircle} color='red' /> Add</span>
                </td>
              </tr>
            }

          })}


        </table>


      </ModalBody>
    </Modal>
  }


  const handleEditInputFields = (obj) => {
    if (obj.Type !== 'LookUp') {
      const optConfig = [...options];
      optConfig.splice(0, optConfig.length)
      obj = { ...obj };
      let keys = []
      if (obj.Config) {
        keys = Object.keys(obj.Config);
      }

      let index = 0;
      keys.forEach((key) => {
        if ((!key.includes('Mandatory')) && (!key.includes('Color')) && (!key.includes('IsLooKUpField')) && (!key.includes('IsInputField'))) {
          index++
          optConfig.push(index);

        }
      })
      setTaskFieldAction('edit');
      setOptions(optConfig);
      setElementType(obj.Type);
      createFieldToggle();
      setTaskFieldObj(obj);
      setTaskFieldConfigObj(obj.Config);
    }
  }

  const handleOnClickRequired = (obj) => {
    obj = { ...obj }
    let config = { ...obj.Config };
    config.Mandatory = true;
    obj.Config = config;
    HttpService.CreateUpdate('updateTaskField', obj)
      .then((result) => {

        // customFieldsToggle();
        GetAllTaskCustumeFieldList(parentObj._id)

        // swal('Create Success');
      })
      .catch((err) => {
        swal(`${err}`, { dangerMode: true });
      })
  }

  const handleClickNotrequired = (obj) => {
    obj = { ...obj };
    let config = { ...obj.Config };
    config.Mandatory = false;
    obj.Config = config;
    HttpService.CreateUpdate('updateTaskField', obj)
      .then((result) => {

        // customFieldsToggle();
        GetAllTaskCustumeFieldList(parentObj._id)

        // swal('Create Success');
      })
      .catch((err) => {
        swal(`${err}`, { dangerMode: true });
      })
  }

  const isDependencyBothExist = (tasks) => {
    const all = [...allTasks];
    let dependencyArr = []
    // if(tasks.project){
    //   tasks=await TaskService.GetTaskDetailById(tasks.project.id_string,tasks.id_string)
    //   tasks.dependency && tasks.dependency.predecessor && tasks.dependency.predecessor.forEach((dependency, ind) => {
    //     let type = tasks.dependency.dependencyDetails[dependency].DEPENDENCY_TYPE;
    //     if (type == 'FS') {
    //       dependencyArr.push('Waiting-On')
    //     }
    //   })
    // }else{
    //   all=all.filter((t)=>t.id_string==tasks.root_task_id);
    //   if(all.length>0){
    //     all=all[0]
    //     if(all.project){
    //       tasks=await TaskService.GetTaskDetailById(all.project.id_string,tasks.id_string)
    //       tasks.dependency && tasks.dependency.predecessor && tasks.dependency.predecessor.forEach((dependency, ind) => {
    //         let type = tasks.dependency.dependencyDetails[dependency].DEPENDENCY_TYPE;
    //         if (type == 'FS') {
    //           dependencyArr.push('Waiting-On')
    //         }
    //       })
    //     }
    //   }

    // }

    return dependencyArr;

  }

  const AddOrEditFields = () => {
    return <Modal
      isOpen={addEditField}
      backdrop={backdrop}
      toggle={addEditFieldToggle} size='lg'
      className="customFieldModal"
      id="manageFieldModal"
    >
      <ModalHeader className="centered-modal-heading" toggle={addEditFieldToggle}>Manage {showLookupItems ? "Lookup" : "Input"} Fields For {props.dealObj.ProjectNumber}</ModalHeader>

      <ModalBody>
        {showLookupItems ? (
          <>
            <h6 className="addCustomField-title">LOOKUP FIELDS</h6>
            <table className='table table-sm table-hover addCustomField-table'>
              {taskCustumeFieldList.map((one) => {
                if (one.Type == 'LookUp' || (one.Config && one.Config.IsLooKUpField)) {
                  return <tr>
                    <td>
                      <FontAwesomeIcon icon={faEye} className="field-icon" />
                      {getSpaceBetweenCamelString(one.Name)}
                    </td>
                    <td className="actionBtns">
                      {/* <FontAwesomeIcon icon={faPen} id="editoolTip" />
                <Tooltip placement="top" isOpen={editTooltipOpen} target="editoolTip" toggle={editToggle}>
                  Edit
                </Tooltip> */}
                      <FontAwesomeIcon onClick={() => handleTaskFieldDelete(one)} icon={faTrashAlt} id="trashTip" className="lookupFieldDel" />
                      <Tooltip placement="top" isOpen={delTooltipOpen} target="trashTip" toggle={delToggle}>
                        Delete
                      </Tooltip>
                    </td>
                  </tr>
                }
              })}
            </table>

            <div>
              <button onClick={handleClickAddLookUpFields} className='btn btn-sm btn-primary'>Add Lookup Fields</button>
            </div>
          </>
        ) :
          (<>
            <h6 className="addCustomField-title">INPUT FIELDS</h6>
            <table className='table table-sm table-hover addCustomField-table'>
              {taskCustumeFieldList.map((one) => {
                if (one.Type == 'LookUp') {
                  return;
                }
                return <tr>
                  <td>
                    {getDynamicFieldIcon(one.Type)}
                    {one.Label}
                  </td>
                  <td className="actionBtns">
                    <FontAwesomeIcon onClick={() => handleEditInputFields(one)} icon={faPen} id="editoolTip" />
                    <Tooltip placement="top" isOpen={editTooltipOpen} target="editoolTip" toggle={editToggle}>
                      Edit
                    </Tooltip>
                    <FontAwesomeIcon onClick={() => handleTaskFieldDelete(one)} icon={faTrashAlt} color='red' id="trashTip" />
                    <Tooltip placement="top" isOpen={delTooltipOpen} target="trashTip" toggle={delToggle}>
                      Delete
                    </Tooltip>
                  </td>
                  {one.Config && one.Config.Mandatory ? <td
                    className="labels label-required"
                  >
                    <span
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleClickNotrequired(one)}
                    >{one.Config.Mandatory ? "UnRequired" : "Required"}
                    </span>
                  </td> :
                    <td
                      className="labels"
                    >

                      <span style={{ cursor: 'pointer' }} onClick={() => handleOnClickRequired(one)}>Required</span>
                    </td>

                  }

                  {/* <td className="labels">
                {taskFieldConfigObj &&taskFieldConfigObj.Mandatory ? (<span style={{color:"red"}}>Required</span>) : (<span>Required</span>)}
                
              </td> */}
                </tr>
              })}



            </table>
            <div className="manageField-btns">
              <button
                className="btn btn-sm btn-primary"
                onClick={() => { setOpenCustomFields(!openCustomFields); setTaskFieldAction('add') }
                }
              >New Input Field</button>
              {/* <p className="mb-0 mx-2">or</p>
          <span onClick={() => setOpenExistModal(true)}>Use an existing field</span> */}
            </div>
          </>)
        }



      </ModalBody>
      {/* <ModalFooter>
      <Button color="primary" onClick={addEditFieldToggle}>Save</Button>{' '}
      <Button color="secondary" onClick={addEditFieldToggle}>Cancel</Button>
    </ModalFooter> */}
    </Modal>
  }


  const [showInputItems, setShowInputItems] = useState(false);
  const [showLookupItems, setShowLookupItems] = useState(false);

  const onClickAddOrEditCustomeFields = (type) => {
    setAddEditFiels(true);
    if (type === "inputFields") {
      setShowInputItems(true);
      setShowLookupItems(false);
    }
    else {
      setShowLookupItems(true);
      setShowInputItems(false);
    }

  }

  const [createFieldPopUp, setCreateFieldPopUp] = useState(false);
  const [taskFieldAction, setTaskFieldAction] = useState('');

  const createFieldToggle = () => {
    if (taskFieldAction == 'add') {
      setOptions([1])
    }
    setCreateFieldPopUp(!createFieldPopUp)
  };

  const [inputType, setInputType] = useState('');
  const [inputName, setInputName] = useState('');
  const [elementType, setElementType] = useState('')
  const [options, setOptions] = useState([1]);
  const handleAddOption = () => {
    const opt = [...options]
    let value = opt[opt.length - 1];
    value = parseInt(value) + 1
    opt.push(value);
    setOptions(opt);
  };
  const [taskFieldObj, setTaskFieldObj] = useState(null);


  const handleOnChangeFieldName = (e) => {
    const { name, value } = e.currentTarget;
    const obj = { ...taskFieldObj };
    obj['Label'] = value;
    obj['Type'] = name;
    obj['Name'] = value;
    setTaskFieldObj(obj);
  }

  const [taskFieldConfigObj, setTaskFieldConfigObj] = useState(null);

  const randomColors = ['red', 'blue', 'green', 'yellow', '#f900ea']
  const getRandomColorHex = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    // let objKeys=Object.keys(api.JobColour[Math.floor(Math.random() * (max - min + 1)) + min])
    return randomColors[Math.floor(Math.random() * (max - min + 1)) + min];

  }

  const handleOnChangeConfig = (e) => {
    if (e.target.id = "Dropdown") {
      if (e.target.name == 'Mandatory') {
        let obj = { ...taskFieldConfigObj };
        obj[e.target.name] = e.target.value;
        setTaskFieldConfigObj(obj);
      } else {
        if (e.target.name.includes('Option')) {
          let obj = { ...taskFieldConfigObj };
          obj[e.target.name] = e.target.value;
          // obj[e.target.name + 'Color'] = getRandomColorHex(1, 5);
          setTaskFieldConfigObj(obj);
        } else {
          let obj = { ...taskFieldConfigObj };
          obj[e.target.name] = e.target.value;
          setTaskFieldConfigObj(obj);
        }
      }

    } else {
      let obj = { ...taskFieldConfigObj };
      obj[e.target.id] = e.currentTarget.value;
      setTaskFieldConfigObj(obj);
    }
  }
  const getTitleForPopup = () => {
    if (elementType == "Dropdown") {
      if (taskFieldAction === "edit") {
        return "Update Dropdown Field";
      }
      else {
        return "Create Dropdown Field";
      }
    }
    if (elementType == "Number") {
      if (taskFieldAction === "edit") {
        return "Update Number Fields";
      }
      else {
        return "Create Number Fields";
      }

    }
    if (elementType == "Label") {
      if (taskFieldAction === "edit") {
        return "Update Label Fields";
      }
      else {
        return "Create Label Fields";
      }

    }
    if (elementType == "Text") {
      if (taskFieldAction === "edit") {
        return "Update Text Fields";
      }
      else {
        return "Create Text Fields";
      }

    }
    if (elementType == "Date") {
      if (taskFieldAction === "edit") {
        return "Update Date Fields";
      }
      else {
        return "Create Date Fields";
      }

    }
    if (elementType == "Checkbox") {
      if (taskFieldAction === "edit") {
        return "Update Radio Fields";
      }
      else {
        return "Create Radio Fields";
      }

    }
    if (elementType == "Files") {
      if (taskFieldAction === "edit") {
        return "Update Files Fields";
      }
      else {
        return "Create Files Fields";
      }

    }
    if (elementType == "Textarea") {
      if (taskFieldAction === "edit") {
        return "Update TextArea Fields";
      }
      else {
        return "Create TextArea Fields";
      }

    }
  }
  const getInputFields = () => {
    if (elementType == 'Number') {
      return <div>
        <div className='form-group'>
          <label className='labelBold'>Field Name</label>
          <input type='text' style={isTaskFieldExist ? { borderColor: 'red' } : {}} defaultValue={taskFieldObj ? taskFieldObj.Name : ''} onChange={handleOnChangeFieldName} className="form-control " placeholder='Enter name..' name={elementType} />
          {isTaskFieldExist ? <p className='small text-danger'>field name already exist</p> : <></>}
        </div>
        <div className='form-group'>
          <label className='labelBold'>Significant Digit</label>
          <input type='number' defaultValue={taskFieldConfigObj ? taskFieldConfigObj.Significant : false} onChange={handleOnChangeConfig} className="form-control" placeholder='Enter significant digit' id={elementType} name={'Significant'} />
        </div>
        <div >
          <input type='checkbox' defaultChecked={taskFieldConfigObj ? taskFieldConfigObj.Mandatory : false} onChange={(e) => handleOnChangeConfig({ target: { name: 'Mandatory', value: e.target.checked } })} placeholder='Enter name..' id='Mandatory' name={elementType} />
          &nbsp;&nbsp;&nbsp;<label >Required</label>
        </div>
        {/* <div >
          <input type='checkbox' defaultChecked={taskFieldConfigObj ? taskFieldConfigObj.IsLooKUpField : false} onChange={(e) => handleOnChangeConfig({ target: { name: 'IsLooKUpField', value: e.target.checked } })} placeholder='Enter name..' id='IsLooKUpField' name={elementType} />
          &nbsp;&nbsp;&nbsp;<label >Look Up Field</label>
        </div>
        <div >
          <input type='checkbox' defaultChecked={taskFieldConfigObj ? taskFieldConfigObj.IsInputField : false} onChange={(e) => handleOnChangeConfig({ target: { name: 'IsInputField', value: e.target.checked } })} placeholder='Enter name..' id='IsInputField' name={elementType} />
          &nbsp;&nbsp;&nbsp;<label >Input Field</label>
        </div> */}


      </div>
    }
    else if (elementType == 'Dropdown') {
      return <div>
        <div className='form-group'>
          <label className='labelBold'>Field Name</label>
          <input type='text' defaultValue={taskFieldObj ? taskFieldObj.Name : ''} onChange={handleOnChangeFieldName} className="form-control" style={isTaskFieldExist ? { borderColor: 'red' } : {}} placeholder='Enter name..' name={elementType} />
          {isTaskFieldExist ? <p className='small text-danger'>field name already exist</p> : <></>}
        </div>
        {options.map((option) => {
          return <div className='form-group position-relative'>
            <label className='labelBold'>Option {option}</label>
            <input type='text' value={taskFieldConfigObj ? taskFieldConfigObj['Option' + option] : ''} onChange={handleOnChangeConfig} id={elementType} className="form-control" placeholder='Enter options' name={'Option' + option} />
            {/* <input type='color' onChange={handleOnChangeConfig} id={elementType} value={taskFieldConfigObj ? taskFieldConfigObj['Option' + option + 'Color'] : ''} name={'Option' + option + 'Color'} className="newFieldDropColor" /> */}
            {/* <select className="newFieldDropColor">
              {colors.map((color, i) => {
               return  <option value={color} style={{backgroundColor:color}}>{color}</option>
              })}

            </select> */}
            {/* <div  className="newFieldDropColor">
            <CirclePicker  />
            </div> */}

          </div>
        })}
        <div style={{ cursor: 'pointer' }}>
          <FontAwesomeIcon icon={faPlusCircle} color='blue' />
          <span onClick={handleAddOption} style={{ color: 'blue', padding: 5 }}>Add new option</span>
        </div>
        <div >
          <input defaultChecked={taskFieldConfigObj ? taskFieldConfigObj.Mandatory : false} type='checkbox' onChange={(e) => handleOnChangeConfig({ target: { name: 'Mandatory', value: e.target.checked } })} placeholder='Enter name..' id='Mandatory' name={elementType} />
          &nbsp;&nbsp;&nbsp;<label >Required</label>
        </div>
        {/* <div >
          <input defaultChecked={taskFieldConfigObj ? taskFieldConfigObj.IsLooKUpField : false} type='checkbox' onChange={(e) => handleOnChangeConfig({ target: { name: 'IsLooKUpField', value: e.target.checked } })} placeholder='Enter name..' id='IsLooKUpField' name={elementType} />
          &nbsp;&nbsp;&nbsp;<label >Look Up Field</label>
        </div>
        <div >
          <input defaultChecked={taskFieldConfigObj ? taskFieldObj.IsInputField : false} type='checkbox' onChange={(e) => handleOnChangeConfig({ target: { name: 'IsInputField', value: e.target.checked } })} placeholder='Enter name..' id='IsInputField' name={elementType} />
          &nbsp;&nbsp;&nbsp;<label >Input Field</label>
        </div> */}


      </div>
    }

    else {
      return <><div className='form-group'>
        <label className='labelBold'>Field Name</label>
        <input style={isTaskFieldExist ? { borderColor: 'red' } : {}} defaultValue={taskFieldObj ? taskFieldObj.Name : ''} type='text' onChange={handleOnChangeFieldName} className="form-control" placeholder='Enter name..' name={elementType} />
        {isTaskFieldExist ? <p className='small text-danger'>field name already exist</p> : <></>}
      </div>
        <div >
          <input type='checkbox' defaultChecked={taskFieldConfigObj ? taskFieldConfigObj.Mandatory : false} onChange={(e) => handleOnChangeConfig({ target: { name: 'Mandatory', value: e.target.checked } })} placeholder='Enter name..' id='Mandatory' name={elementType} />
          &nbsp;&nbsp;&nbsp;<label >Required</label>
        </div>
        {/* <div >
          <input defaultChecked={taskFieldConfigObj ? taskFieldConfigObj.IsLooKUpField : false} type='checkbox' onChange={(e) => handleOnChangeConfig({ target: { name: 'IsLooKUpField', value: e.target.checked } })} placeholder='Enter name..' id='IsLooKUpField' name={elementType} />
          &nbsp;&nbsp;&nbsp;<label >Look Up Field</label>
        </div>
        <div >
          <input defaultChecked={taskFieldConfigObj ? taskFieldConfigObj.IsInputField : false} type='checkbox' onChange={(e) => handleOnChangeConfig({ target: { name: 'IsInputField', value: e.target.checked } })} placeholder='Enter name..' id='IsInputField' name={elementType} />
          &nbsp;&nbsp;&nbsp;<label >Input Field</label>
        </div> */}
      </>
    }




  }

  const colors = ['red', 'green', 'yellow', 'blue', '#f900ea']

  const isTaskCustomeFieldExist = async (fieldName, id) => {
    const backendUrl = Config.backend_url;
    let result = false;
    let keys = Object.keys(props.dealObj);
    result = keys.includes(fieldName);
    if (!result) {
      let taskFieldList = await axios.post(backendUrl+'getTaskFields', { DealId: id })
      taskFieldList = taskFieldList.data.Data;
      if (taskFieldList.filter(one => one.Name.toLowerCase() == fieldName.toLowerCase()).length > 0) {
        result = true;
      } else {
        result = false;
      }
    }
    return result;
  }

  const [isTaskFieldExist, setTaskFieldExist] = useState(false);

  const handleCreateField = async () => {
    let bacurl = Config.backend_url;
    if (taskFieldAction == 'add') {
      if (!taskFieldObj || Object.keys(taskFieldObj).length == 0) {
        swal('Task Field Name Required', { dangerMode: true });
        return
      }

      const obj = { ...taskFieldObj };

      if (await isTaskCustomeFieldExist(obj.Name, parentObj.DealsId)) {
        setTaskFieldExist(true);
        return
      } else {
        setTaskFieldExist(false);
      }
      obj.TaskId = parentObj._id;
      obj.TaskName = parentObj.TaskName;
      obj.DealId = parentObj.DealsId;
      obj.UserName = localStorage.getItem('UserName');
      obj.UserId = localStorage.getItem('UserId');
      obj.Config = taskFieldConfigObj;

      // HttpService.CreateUpdate('createTaskField', obj)
      axios.post(bacurl + 'createTaskField', obj)

        .then((result) => {
          createFieldToggle();
          customFieldsToggle();
          GetAllTaskCustumeFieldList(parentObj._id)
          setTaskFieldConfigObj(null);
          setTaskFieldObj(null)
          setTaskFieldAction('')

          addActivity("ADD", "TaskField", obj.TaskId, obj.TaskName, 0, {
            PrevState: { Value: null, Name: "" },
            UpdatedState: { Value: null, Name: obj.Name }
          });
          // swal('Create Success');
        })
        .catch((err) => {
          //swal(`${err}`, { dangerMode: true });
        })
    }
    if (taskFieldAction == 'edit') {
      // if (!taskFieldObj || Object.keys(taskFieldObj).length == 0) {
      //   swal('Task Field Name Required');
      //   return
      // }
      const obj = { ...taskFieldObj };
      obj.Config = taskFieldConfigObj;
      HttpService.CreateUpdate('updateTaskField', obj)
        .then((result) => {
          createFieldToggle();
          // customFieldsToggle();
          GetAllTaskCustumeFieldList(parentObj._id)
          setTaskFieldConfigObj(null);
          setTaskFieldObj(null)
          setTaskFieldAction('')
          addActivity("Update", "InputFields", obj.TaskId, obj.TaskName, 0, obj);
          // swal('Create Success');
        })
        .catch((err) => {
          swal(`${err}`, { dangerMode: true });
        })
    }
  }

  const createFieldModal = () => {
    return <Modal
      isOpen={createFieldPopUp}
      toggle={createFieldToggle}
      size='lg'
      backdrop='static'
      className="createFieldModal"
    >

      <div>
        <h5 className="text-center mt-3"
        // toggle={addEditFieldToggle}
        >{getTitleForPopup()}
          <FontAwesomeIcon
            icon={faTimes}
            onClick={() => createFieldToggle()}
            className="close"
          />
        </h5>
      </div>

      <ModalBody>
        {getInputFields()}

        <div style={{ float: 'right' }}>

          <Button color="primary" onClick={handleCreateField}>{taskFieldAction == "edit" ? "Update" : "Create"}</Button>{'      '}
          <Button onClick={createFieldToggle} color="secondary" >Cancel</Button>
        </div>
      </ModalBody>

    </Modal>
  }

  const [showMore, setShowMore] = useState(false)
  const [showMoreFields, setShowMoreFields] = useState(false);
  //const [noOfItemsToShowMoreOrLess, setNoOfItemsToShowMoreOrLess] = useState(false)

  const handleClickShowMoreFields = () => {
    setShowMore(!showMore);
  }

  const openLookupFields = () => {
    setShowMoreFields(!showMoreFields);
  }
  // useEffect(() => { })

  const handleOnChangeTaskCustomField = (event, data) => {
    let oldValue = data.Value;
    if (data.Type == 'File') {
      event.preventDefault();
      let self = this;
      let fr = new FileReader();

      //if (event.currentTarget.files[0].size < 51200 && e.currentTarget.files[0].size > 2000) {
      fr.readAsDataURL(event.currentTarget.files[0]);
      fr.fileName = event.currentTarget.files[0].name
      fr.onloadend = function (e) {
        let obj = { Value: e.target.result, FileName: e.target.fileName };
        data = { ...data };
        data["Value"] = obj;
        HttpService.CreateUpdate('updateTaskField', data)
          .then((result) => {
            if (result.data.Ack == 1) {
              let list = _.cloneDeep(taskCustumeFieldList);
              let arr = [...list];
              arr.forEach((one, i) => {
                if (one._id == data._id) {
                  arr[i].Value = event.target.value
                }
              })
              setTaskCustumFieldList(arr);
           
            } 
          })
          .catch((err) => {
            console.log(err);
          })
      };



      // }
      // else {
      //     swal('File Size Should Be Between 2KB to 50KB');
      // }



    } else {
      if (data.Type == 'Number') {

        data = { ...data };
        data['Value'] = event.target.value

      } else {
        data = { ...data };
        data['Value'] = event.target.value

      }
      if (!oldValue) {
        addActivity('Update', 'TaskField', data.TaskId, data.TaskName, 0, { PrevState: { Value: null, Name: data.Name }, UpdatedState: { Name: data.Name, Value: data.Value } });
      }
      else {
        addActivity('Update', 'TaskField', data.TaskId, data.TaskName, 0, { PrevState: { Value: oldValue.Value, Name: data.Name }, UpdatedState: { Name: data.Name, Value: data.Value } });
      }
      HttpService.CreateUpdate('updateTaskField', data)
        .then((result) => {
          if (result.data.Ack == 1) {
            let list = _.cloneDeep(taskCustumeFieldList);
            let arr = [...list];
            arr.forEach((one, i) => {
              if (one._id == data._id) {
                arr[i].Value = event.target.value
              }
            })
            // addActivity("UPDATE", "InputFields", prepareTaskData()._id, prepareTaskData().TaskName, obj);
            setTaskCustumFieldList(arr);
          } 
        })
        .catch((err) => {
          console.log('Unsuccess')
        })
    }

  }

  const GetSignificantValue = (value, signDigitObj) => {
    let num;
    if (signDigitObj) {
      num = parseFloat(value).toFixed(parseInt(signDigitObj.Significant));
    } else {
      num = parseFloat(value)
    }
    return num;
  }

  const getNumber = (number, signDigitObj) => {
    if ((number !== '') && signDigitObj) {
      let arrNumber = number.split('.');
      if ((arrNumber.length == 2) && arrNumber[1].length <= parseInt(signDigitObj.Significant)) {
        return number;
      } else {
        return GetSignificantValue(number, signDigitObj)
      }
    } else {
      return number;
    }

  }

  const getBorderStyles = (obj) => {
    if (taskMandatoryStatus && ((!obj.Value) || obj.Value.Value == '')) {
      return { borderColor: 'red' }
    } else {
      return {};
    }
  }
  const myRef = useRef(null)

  const [checklistCollapse, setChecklistCollapse] = useState(false);
  const [subtaskCollapse, setSubtaskCollapse] = useState(false);

  const [collapseOpen, setCollapseOpen] = useState(false);

  const [openSubtaskPopup, setOpenSubtaskPopup] = useState(false);
  const checklistCollapseOpen = () => {
    setChecklistCollapse(!checklistCollapse);

  }

  const subtaskCollapseOpen = () => {

    if (prepareTaskData().Subtask.length > 0 || (prepareTaskData().Subtask[0] && prepareTaskData().Subtask[0].Subtask.length > 0)) {
      setSubtaskCollapse(true);

    }
    else {
      setSubtaskCollapse(!subtaskCollapse);
    }

  }
  const testFunction = () => {
    swal('shdfb\bh')
  }
  const GetInputHtmlFromTaskFields = (obj) => {
    if (obj.Type == 'Number') {
      return <tr>
        <td>{obj.Label}{obj.Config ? obj.Config.Mandatory ? <span style={{ color: 'red' }}>*</span> : '' : ''}</td>
        <td
        // className="hoverDelete"
        >
          <input type='number' ref={myRef} defaultValue={obj.Value ? getNumber(obj.Value ? obj.Value.Value.toString() : myRef.current.value, obj.Config) : ''} onChange={(e) => handleOnChangeTaskCustomField({ target: { value: { Value: GetSignificantValue(e.target.value, obj.Config) } } }, obj)}
            placeholder="...." className='form-control' name={obj.Name} />
          {/* {getDropDownStatusForNewFiels('badge-info', 'Complete')} */}
          {/* <FontAwesomeIcon className="removeField-icon" style={{ backgroundColor: 'blue', color: 'white' }} icon={faTimes} /> */}
        </td>
      </tr>
    }

    if (obj.Type == 'Dropdown') {
      return <tr>
        <td>{obj.Label}{obj.Config ? obj.Config.Mandatory ? <span style={{ color: 'red' }}>*</span> : '' : ''}</td>
        <td
        // className="hoverDelete"
        >
          <select className='form-control'
            onChange={(e) => {
            
              handleOnChangeTaskCustomField({ target: { value: { Option: obj.Config[key] } } }, obj)
            }}
          >
            <option>Please Select</option>
            {Object.keys(obj.Config).filter(one => {
              if (
                // !one.includes('Color') &&
                !one.includes('Mandatory') && !one.includes('IsLooKUpField') && !one.includes('IsInputField')) {
                return one;
              }
            }).map((key, i) => {
              return (
                <option>{obj.Config[key]}</option>
              );
            })}

          </select>
          {/* <UncontrolledDropdown>
            <DropdownToggle
              className='badge'
              // style={{
              //   backgroundColor: obj.Value ? obj.Value.Color : 'gray',
              //   color: "#fff",
              // }}
            >
              {obj.Value ? obj.Value.Option : obj.Type}
            </DropdownToggle>
            <DropdownMenu>
              {Object.keys(obj.Config).filter(one => {
                if (
                  // !one.includes('Color') &&
                 !one.includes('Mandatory') && !one.includes('IsLooKUpField') && !one.includes('IsInputField')) {
                  return one;
                }
              }).map((key, i) => {
                return (
                  <DropdownItem
                    // style={{
                    //   backgroundColor: obj.Config[key + 'Color'],
                    //   color: "#fff",
                    // }}
                    onClick={() => handleOnChangeTaskCustomField({ target: { value: { Option: obj.Config[key], Color: obj.Config[key + 'Color'] } } }, obj)}
                  >
                    {obj.Config[key]}
                  </DropdownItem>
                );
              })}
            </DropdownMenu>
          </UncontrolledDropdown> */}

        </td>
      </tr>
    }
    if (obj.Type == 'File') {
      return <tr>
        <td>{obj.Label}{obj.Config ? obj.Config.Mandatory ? <span style={{ color: 'red' }}>*</span> : '' : ''}</td>
        <td
        // className="hoverDelete"       
        >
          <input type='file' onChange={(e) => handleOnChangeTaskCustomField(e, obj)} placeholder={obj.Type} className='form-control' name={obj.Label} />
          {/* <label>{obj.Value ? obj.Value.FileName : ''}</label> */}
        </td>
      </tr>
    }
    if (obj.Type == 'Radio') {
      return <tr>
        <td>{obj.Label}{obj.Config ? obj.Config.Mandatory ? <span style={{ color: 'red' }}>*</span> : '' : ''}</td>
        <td
        //  className="hoverDelete"
        >
          <input type='radio' defaultChecked={obj.Value ? obj.Value.Value == true : ''} onChange={(e) => handleOnChangeTaskCustomField({ target: { value: { Value: true } } }, obj)} name={obj.Label} />&nbsp;&nbsp;&nbsp;Yes&nbsp;&nbsp;&nbsp;
          <input type='radio' defaultChecked={obj.Value ? obj.Value.Value == false : ''} onChange={(e) => handleOnChangeTaskCustomField({ target: { value: { Value: false } } }, obj)} name={obj.Label} />&nbsp;&nbsp;&nbsp;No
        </td>
      </tr>
    }
    if (obj.Type == 'Date') {
      return <tr>
        <td>{obj.Label}{obj.Config ? obj.Config.Mandatory ? <span style={{ color: 'red' }}>*</span> : '' : ''}</td>
        <td
        //  className="hoverDelete"
        >
          <input type='date' placeholder="mm/dd/yy" defaultValue={obj.Value ? obj.Value.Value : ''} onChange={(e) => handleOnChangeTaskCustomField({ target: { value: { Value: e.target.value } } }, obj)} className='form-control' name={obj.Label} />
        </td>
      </tr>
    }
    if (obj.Type == 'Textarea') {
      return <tr>
        <td>{obj.Label}{obj.Config ? obj.Config.Mandatory ? <span style={{ color: 'red' }}>*</span> : '' : ''}</td>
        <td
        // className="hoverDelete"
        >
          <textarea type='text' placeholder='...' rows={3} cols={10} defaultValue={obj.Value ? obj.Value.Value : ''} onChange={(e) => handleOnChangeTaskCustomField({ target: { value: { Value: e.target.value } } }, obj)} className='form-control' name={obj.Label} />
        </td>
      </tr>
    }
    if (obj.Type == 'Label') {
      return <tr>
        <td>{obj.Label}{obj.Config ? obj.Config.Mandatory ? <span style={{ color: 'red' }}>*</span> : '' : ''}</td>
        <td
        // className="hoverDelete"
        >
          <input type='text' defaultValue={obj.Value ? obj.Value.Value : ''} onChange={(e) => handleOnChangeTaskCustomField({ target: { value: { Value: e.target.value } } }, obj)} placeholder="...." className='form-control' name={obj.Label} />
        </td>
      </tr>
    }
    if (obj.Type == 'Text') {
      return <tr>
        <td>{obj.Label}{obj.Config ? obj.Config.Mandatory ? <span style={{ color: 'red' }}>*</span> : '' : ''}</td>
        <td
          className="hoverDelete"
        >
          <input type='text' defaultValue={obj.Value ? obj.Value.Value : ''} onChange={(e) => handleOnChangeTaskCustomField({ target: { value: { Value: e.target.value } } }, obj)} placeholder="...." className='form-control' name={obj.Label} />
        </td>
      </tr>
    }
    // if (obj.Type == 'LookUp') {
    //   return <tr>
    //     <td>{getSpaceBetweenCamelString(obj.Label)}{obj.Config ? obj.Config.Mandatory ? <span style={{ color: 'red' }}>*</span> : '' : ''}</td>
    //     <td className="hoverDelete">
    //       <input type='text' className='form-control' disabled defaultValue={obj.Value ? obj.Value.Value : ''} name={obj.Label} />
    //     </td>
    //   </tr>
    // }

  }

  const getInputHtmlForAddField = (obj) => {
    if (obj.Type == 'LookUp') {
      return <tr>
        <td>{getSpaceBetweenCamelString(obj.Label)}{obj.Config ?
          obj.Config.Mandatory ?
            <span style={{ color: 'red' }}>*</span> : '' : ''}
        </td>
        <td
        //  className="hoverDelete"
        >
          <input
            type='text'
            className='form-control'
            disabled defaultValue={obj.Value ? obj.Value.Value : ''}
            name={obj.Label}
          />
        </td>
      </tr>
    }

  }

  const countLookupFields = (obj) => {
    let totalCount = 0;
    let lookupObj = obj.filter(e => e.Type == "LookUp");
    totalCount = lookupObj.length;
    return totalCount;
  }

  const emptyFieldsLength = (obj) => {

    let emptyCount = 0;
    let valueObj = obj.filter(e => !e.Value);
    emptyCount = valueObj.length;

    return emptyCount;

  }

  const countInputFields = (obj) => {
    let totalCount = 0;
    let inputObj = obj.filter(e => e.Type !== "LookUp");
    totalCount = inputObj.length;
    return totalCount;
  }

  const getTableForAddNewField = () => {
    return <div className="newField-box">

      <table className="table table-sm table-hover newField-table">
        <tbody>
          {taskCustumeFieldList.map((one, i) => {
            if (showMore) {
              return GetInputHtmlFromTaskFields(one)
            } else {
              return;
            }
            // if (one.Value) {
            //   return GetInputHtmlFromTaskFields(one)
            // } else {
            //   if (showMore) {
            //     return GetInputHtmlFromTaskFields(one)
            //   }
            // }
            // if (showMore) {

            // } else {
            //   if (i == 0) {
            //     return GetInputHtmlFromTaskFields(one)
            //   }
            // }

          })}
          {/* <tr>
            <td>Mark</td>
            <td className="hoverDelete">
              {getDropDownStatusForNewFiels('badge-warning', 'Pending')}
              <FontAwesomeIcon className="removeField-icon" style={{ backgroundColor: 'blue', color: 'white' }} icon={faTimes} />
            </td>
          </tr>
          <tr>
            <td>Mark</td>
            <td className="hoverDelete">
              {getDropDownStatusForNewFiels('badge-danger', 'In Progress')}
              <FontAwesomeIcon className="removeField-icon" style={{ backgroundColor: 'blue', color: 'white' }} icon={faTimes} />
            </td>
          </tr> */}
        </tbody>
      </table>
      <div className="newField-tableOptions">
        {/* {!showMore ? getTotalCustomeTaskEmphtyField() : 0} empty */}
        {/* {taskCustumeFieldList.map(one=> ( */}

        <p onClick={handleClickShowMoreFields}><FontAwesomeIcon className="mr-2" icon={showMore ? faCaretUp : faCaretDown} />{showMore ? "Show less Input" : "Show more Input"}   fields
          {" "}
          ( {countInputFields(taskCustumeFieldList)} total fields {" "} {" "}
          {emptyFieldsLength(taskCustumeFieldList)} empty fields )
        </p>
        {/* ) )} */}


        {/* {!showMore ? taskCustumeFieldList.length  : taskCustumeFieldList.length-1} */}
        <a style={{ cursor: 'pointer' }} onClick={() => onClickAddOrEditCustomeFields("inputFields")}>
          + Add or edit fields
        </a>
      </div>

    </div>
  }

  const getTableForLookupFields = () => {
    return <div className="newField-box">

      <table className="table table-sm table-hover newField-table">
        <tbody>
          {taskCustumeFieldList.map((one, i) => {
            if (showMoreFields) {
              return getInputHtmlForAddField(one)
            }
            else {
              return;
            }

          })}

        </tbody>
      </table>
      <div className="newField-tableOptions">
        {/* {!showMore ? getTotalCustomeTaskEmphtyField() : 0} empty */}
        <p onClick={openLookupFields}>
          <FontAwesomeIcon
            className="mr-2"
            icon={showMoreFields ? faCaretUp : faCaretDown}
          />{showMoreFields ? "Show less Lookup" : "Show more Lookup"}    fields {" "}
          ({countLookupFields(taskCustumeFieldList)} total fields)
        </p>
        {/* {!showMore ? taskCustumeFieldList.length  : taskCustumeFieldList.length-1} */}
        <a style={{ cursor: 'pointer' }} onClick={() => onClickAddOrEditCustomeFields("LookupFields")}>+ Add fields</a>
      </div>
    </div>
  }

  const [warningTaskCompletePopup, setTaskCompleteWarning] = useState(false);
  const [statusForUpdate, setStatusForUpdate] = useState(null);
  const warningTaskCompleteAlertToogle = () => setTaskCompleteWarning(!warningTaskCompletePopup)

  const [taskWaitingOnList, setTaskWaitingList] = useState([]);

  const warningTaskCompleteAlertModal = () => {
    return <Modal
      isOpen={warningTaskCompletePopup}
      backdrop={backdrop}
      size='md'
      toggle={warningTaskCompleteAlertToogle}
      className="taskComplete-warning"
    >

      <ModalBody>
        <Row >
          <Col sm={12}>
            <FontAwesomeIcon icon={faExclamationTriangle} size='3x' className="text-warning" />
            <h5 className="my-2">Dependency Warning</h5>
            <p className="taskComplete-warning-subtitle">This task is waiting on required fields or tasks dependency for the following tasks of fields </p>
          </Col>

        </Row>
        <Row >
          <Col sm={12}>
            {taskCustumeFieldList.length > 0 ?
              <div>
                <p>Field Name</p>
                <ul className="textField-list">
                  {taskCustumeFieldList.map((one) => {
                    if (one.Config && one.Config.Mandatory && (!one.Value || one.Value.Value === "")) {
                      return <li>{one.Name}</li>
                    }
                  })}
                </ul>
                <hr />
              </div>
              : <div>
                <p>Task Name</p>
                <ul className="textField-list">
                  {childTaskForWarinig.map((one) => {
                    return <li>{one.name}</li>
                  })}
                </ul>
              </div>}

            {/* <p>Task Name</p>
            <ul className="textField-list">
              {taskWaitingOnList.map((one) => {
                return <li>{one}</li>
              })}
            </ul> */}
          </Col>

        </Row>
        <Row >
          <Col sm={12}>
            <button
              className='btn btn-success w-100'
              onClick={() => {
                // swal({
                //   title: "Are you sure?",
                //   text: "forcely complete the status for all sub task for this",
                //   icon: "warning",
                //   buttons: true,
                //   dangerMode: true,
                // })
                //   .then(async (willDelete) => {
                //     if (willDelete) {
                //       let arr = [];
                //       let arr1 = []
                //       arr.push(updateTask(dataForUpdate))
                //       // arr1.push(addActivity('Update', 'Status', dataForUpdate._id, dataForUpdate.TaskName, 0, activityObj))
                //       // childTaskForWarinig.forEach((one) => {
                //       //   one = { ...one }
                //       //   one.Status = statusForUpdate?statusForUpdate: { name: "COMPLETED", color: "#f900ea" }
                //       //   arr.push(updateTask(one))
                //       //   arr1.push(addActivity('Update', 'Status', one._id, one.TaskName, 0, activityObj))
                //       // })
                //       let res1 = await Promise.all(arr1)
                //       let res = await Promise.all(arr);
                //       setTaskCompleteWarning(false);
                //       // setParentToSubtaskStatusCompleteWar(false);
                //       swal("Success", {
                //         icon: "success",
                //       });
                //     } else {
                //       swal("you cancel  for the update task");
                //     }
                //   });
              }}
            // onClick={() => {
            //   if (taskStatusUpdateWhere == 'table' && taskStatusUpdateObj) {
            //     updateStatus(taskStatusUpdateObj.first, taskStatusUpdateObj.second, taskStatusUpdateObj.third, taskStatusUpdateObj.forth, taskStatusUpdateObj.fifth);
            //     setTaskCompleteWarning(false)
            //     setTaskStatusUpdateWhere('');
            //     setTaskStatusUpdateObj(null);
            //     setTaskWaitingList([])
            //   } else {
            //     setTaskStatus({ name: "COMPLETED", color: "#f900ea" })
            //     setTaskCompleteWarning(false);
            //     setTaskWaitingList([])
            //   }

            // }}
            >Ignore</button>
            <button onClick={() => setTaskCompleteWarning(false)} className='btn btn-secondary w-100 mt-2'>Cancel</button>
          </Col>

        </Row>
      </ModalBody>
    </Modal>
  }


  const getStatusContent = (dependent) => {
    let currentStatus = dependent.Current_Task_Status ? dependent.Current_Task_Status : [];
    let relatedStatus = dependent.Related_Task_Status ? dependent.Related_Task_Status : [];
    return <div className="row">
      <div className="col-sm-12">
        <strong>Current Task Dependency Status : </strong>
        {currentStatus && currentStatus.map((one) => {
          return <p >{one}</p>
        })}
        <br></br><strong>Related Task Dependency Status : </strong>
        {relatedStatus && relatedStatus.map((one) => {
          return <p >{one}</p>;
        })}
      </div>
    </div>
  }

  const checkDepedencyWarning = (status, depend) => {
    let statusDetail = [...taskLayout.status_details];
    let waitingOn = []
    depend.forEach((x, i) => {
      let ta = allTasks.find((t) => t.id_string == x.Related_Task_ID);
      if (isMatchStatus(ta.status.name, x.Current_Task_Status) && x.Type == "Waiting-On") {
        waitingOn.push(ta.name);
      }
    });

  }


  const IsTaskDependent = async (task, status, dependency) => {
    let childTask = []
    let blockingTask = []
    // allTasks.forEach((one) => {
    //   let dependency1=dependencyList.filter((dep)=>dep.Current_Task_ID==one.id_string)
    //   dependency1.forEach(d => {
    //     if ((d.Related_Task_ID == task.id_string) && (d.Type == 'Blocking')) {
    //       if (isMatchStatus(status, d.Related_Task_Status) && isMatchStatus(one.status.name, d.Current_Task_Status)) {
    //         blockingTask.push(one);
    //       }
    //     }
    //   })
    // })
    let waitingTask = []
    dependency.forEach((d) => {
      allMyTasks.forEach(one => {
        if ((d.Related_Task_ID == one.id_string) && (d.Type == 'Waiting-On')) {
          if (!isMatchStatus(one.status.name, d.Related_Task_Status)) {
            if (isMatchStatus(status, d.Current_Task_Status)) {
              waitingTask.push(one);
            }
          }
        }
      })




    })
    //  taskDocs.forEach((one)=>{
    //   one.DependentOn.forEach(d=>{
    //      if((d.id==task._id)&&(d.type=='Waiting-On')){
    //        if(!isMatchStatus(status.name,d.Status)&&!isMatchStatus(one.Status.name,d.DependencyStatus)){
    //         waitingTask.push( one);
    //        }
    //      }
    //    })
    //  })
    childTask = [...blockingTask, ...waitingTask]
    childTask = _.unionBy(childTask, 'id_string')
    if (childTask.length > 0) {
      setChildTask(childTask);
      return true;
      // let filterStatus = childTask.filter(one => one.Status.name !== 'COMPLETED')
      // if (filterStatus.length > 0) {
      //   setChildTask(filterStatus);
      //   return true;
      // } else {
      //   return false;
      // }
    } else {
      return false;
    }
  }

  const showDependencyModal = () => {
    let waitingOnLength = getTaskDependency(showDependencyList.id_string).filter((one) => one.Type == "Waiting-On").length;
    let blockingLength = getTaskDependency(showDependencyList.id_string).filter((one) => one.Type == "Blocking").length;
    return <Modal

      isOpen={showDependencyPopUp}
      backdrop={backdrop}
      size='md'
      toggle={showDependencyPopUp}
      className="taskDependency-modal"
    >

      <ModalBody>
        <div className="d-flex align-items-center justify-content-between mb-3">
          <h5 className="mb-0">Dependencies </h5>
          <FontAwesomeIcon onClick={() => setShowDependencyPopUp(false)} icon={faTimes} />
        </div>

        {waitingOnLength > 0 ? <div className="dependency-box" style={{ borderColor: "#FFA500" }}>
          <div className="dependency-status" style={{ color: "#FFA500" }}>
            <FontAwesomeIcon
              icon={faMinusCircle}
            />
            {" "}

            <span>Waiting On</span>
          </div>

          <ul className="dependency-task-list list-unstyled">

            {getTaskDependency(showDependencyList.id_string).filter((one) => one.Type == "Waiting-On").map((depend) => {

              return <li>
                <div className="d-flex align-items-center">
                  <span
                    className="dependency-status-icon"
                    color={
                      "#f8d800"
                    }
                  ></span>{" "}
                  <p className="m-0">{depend.Related_Task_Name}</p>
                </div>
                <AntdPopover trigger={'hover'} content={getStatusContent(depend)}>
                  <FontAwesomeIcon icon={faEye} />
                </AntdPopover>

              </li>
            })}

          </ul>
        </div> : <></>}
        {blockingLength > 0 ? <div className="dependency-box" style={{ borderColor: "#f40600" }}>
          <div className="dependency-status" style={{ color: "#f40600" }}>
            <FontAwesomeIcon
              icon={faMinusCircle}
            />
            {" "}

            <span>Blocking</span>
          </div>

          <ul className="dependency-task-list list-unstyled">

            {getTaskDependency(showDependencyList.id_string).filter((one) => one.Type == "Blocking").map((depend) => {
              return <li>
                <div className="d-flex align-items-center">
                  <span
                    className="dependency-status-icon"
                    color={
                      "#f8d800"
                    }
                  ></span>{" "}
                  <p className="m-0">{depend.Related_Task_Name}</p>
                </div>
                <AntdPopover trigger={'hover'} content={getStatusContent(depend)}>
                  <FontAwesomeIcon icon={faEye} />
                </AntdPopover>

              </li>
            })}

          </ul>
        </div> : <></>}



      </ModalBody>
    </Modal>
  }

  const [taskStatusUpdateWhere, setTaskStatusUpdateWhere] = useState('');
  const [taskStatusUpdateObj, setTaskStatusUpdateObj] = useState(null)

  const [askMilestonePopup, setAskMileStonePopup] = useState(false);
  const [askMilestoneId, setAskMileStoneId] = useState(null);
  const [askMilestoneObj, setAskMileStoneObj] = useState(null);
  const askMilestonePopupToogle = () => setAskMileStonePopup(!askMilestonePopup);

  const handleOnChangeAskMilestone = (e) => {
    const obj = { ...askMilestoneObj };
    obj[e.currentTarget.name] = e.currentTarget.value;
    setAskMileStoneObj(obj);
  }

  const askModalToMakeMilestone = () => {
    return <Modal
      isOpen={askMilestonePopup}
      backdrop={backdrop}
      size='md'
      toggle={askMilestonePopupToogle}
      className="taskComplete-warning"
    >

      <ModalBody>
        <Row >
          <Col sm={12}>
            {/* <FontAwesomeIcon icon={faExclamationTriangle} size='3x' className="text-warning" /> */}
            <h5 className="my-2">Milestone</h5>
            <p className="taskComplete-warning-subtitle">This task is make milestone required fields to be completed </p>
          </Col>

        </Row>
        <Row >
          <Col sm={12}>
            <div className='form-group'>
              <label>Statuses associated with</label>
              <select name='Category' onChange={handleOnChangeAskMilestone} className='form-control form-control-sm'>
                <option>--Select Category--</option>
                <option>Design</option>
                <option>Filing</option>
                <option>Miscellaneous</option>
              </select>
            </div>
          </Col>
          <Col sm={12}>
            <div className='form-group'>
              <label>Statuses description</label>
              <input placeholder='description...' onChange={handleOnChangeAskMilestone} name='Status' type='text' className='form-control form-control-sm' />
            </div>
          </Col>

        </Row>
        <Row >
          <Col sm={12}>
            <button
              className='btn btn-success w-100'
              onClick={() => handleTaskExtraOps(askMilestoneId)}

            >Yes </button>
            <button onClick={() => setAskMileStonePopup(false)} className='btn btn-secondary w-100 mt-2'>Cancel</button>
          </Col>

        </Row>
      </ModalBody>
    </Modal>
  }



  const handleTaskExtraOps = (value) => {
    if (value && value.id == 'milestone') {
      let obj = { ...parentObj };
      obj.isMilestone = !obj.isMilestone;
      if (obj.isMilestone) {
        obj.Milestone = askMilestoneObj;
      } else {
        obj.Milestone = null;
      }
      updateSubTask(obj);
      addActivity('Update', 'Milestone', obj._id, obj.TaskName, 0, { PrevState: parentObj.isMilestone ? true : false, UpdatedState: obj.isMilestone });
      setAskMileStonePopup(false);
    }
  }

  const handleUploadFiles = () => {
    let link = document.createElement('input');
    link.setAttribute('type', 'file');
    link.setAttribute('name', 'uploadFiles');
    document.body.appendChild(link);
    link.click();
    link.onchange = function (e) {
      handleFileAttachments(e.currentTarget.files);
    }
    document.body.removeChild(link);
  }



  const handleTaskExtraOpsDropdownClick = (value) => {
    const parent = { ...parentObj };
    const duplicate = { ...duplicateValueObj };
    if (value && value.id == 'milestone') {
      if (parentObj && (!parentObj.isMilestone)) {
        setAskMileStonePopup(true);
        setAskMileStoneId(value);
      } else {
        handleTaskExtraOps(value)
      }
    }
    if (value && value.id == 'duplicate') {
      setDuplicatePopUp(true);
    }
    if (value && value.id == 'template') {
      setTemplatePopUp(true)
      setServiceRequested(serviceReqVariable);
      duplicate.TaskName = parent.TaskName + ' - Template';
      setDuplicateValueObj(duplicate);
    }
  }

  const [copyTasks, setCopyTasks] = useState([])

  const [popUpTemplate, setTemplatePopUp] = useState(false);
  const popUpTemplateToogle = () => setTemplatePopUp(!popUpTemplate);

  const [popUpDuplicate, setDuplicatePopUp] = useState(false);
  const popUpDuplicateToogle = () => setDuplicatePopUp(!popUpDuplicate);

  const [duplicateValueObj, setDuplicateValueObj] = useState({
    Pipeline: [],
    TaskName: '',
    Everything: true, Attachements: true, Checklist: true, Comments: true, InputFields: true,
    LookUpFields: true, Dependencies: true, DueDate: true, Assignee: true,
    TimeEstimate: true, Priority: true, Status: true, Subtask: true,
  });

  const handleOnChangeDupicateCheckBox = (e) => {
    const obj = { ...duplicateValueObj };
    if (e.currentTarget.name == 'TaskName') {
      obj[e.currentTarget.name] = e.currentTarget.value;
    } else {
      if (e.currentTarget.checked) {
        if (e.currentTarget.name == 'Everything') {
          let keys = Object.keys(obj);
          keys.forEach((key) => {
            if (key !== 'TaskName') {
              obj[key] = true;
            }
          })
        } else {
          obj[e.currentTarget.name] = e.currentTarget.checked;
          obj['Everything'] = false;
        }

      } else {
        if (e.currentTarget.name == 'Everything') {
          let keys = Object.keys(obj);
          keys.forEach((key) => {
            if (key !== 'TaskName') {
              obj[key] = false;
            }
          })
        } else {
          obj[e.currentTarget.name] = e.currentTarget.checked;
          obj['Everything'] = false;
        }
      }

    }
    setDuplicateValueObj(obj);
  }

  const addDuplicateTaskComm_InputFields_LookUpFields = (oldTaskId, newTaskId, newTaskName) => {
    const duplicateValue = { ...duplicateValueObj };
    if (duplicateValue.Comments) {
      HttpService.CreateUpdate("gettaskcomms", { TaskID: oldTaskId })
        .then((result) => {
          let arr = []
          result.data.forEach((one) => {
            let obj = { ...one }
            delete obj._id;
            obj.TaskID = newTaskId
            arr.push(HttpService.CreateUpdate("addtaskcomms", obj))
          })
          return Promise.all(arr)
        })
        .then((result) => {
          
        })
        .catch((err) => {
          // swal(`${err}`, { dangerMode: true });
        })
    }
    if (duplicateValue.InputFields) {
      let bacurl = Config.backend_url;
      // HttpService.CreateUpdate('getTaskFields', { TaskId: oldTaskId })
      axios.post(bacurl+'getTaskFields', { TaskId: oldTaskId })
        .then((result) => {
          let arr = []
          result.data.Data.forEach((one) => {
            if (one.Type !== 'LookUp') {
              let obj = { ...one }
              delete obj._id;
              obj.TaskId = newTaskId;
              obj.TaskName = newTaskName;
              // arr.push(HttpService.CreateUpdate('createTaskField', obj))
              arr.push(axios.post(bacurl + 'createTaskField', obj))
            }
          })
          return Promise.all(arr)
        })
        .then((result) => {
         
        })
        .catch((err) => {
          //swal(`${err}`, { dangerMode: true });
        })
    }
    if (duplicateValue.LookUpFields) {
      let bacurl = Config.backend_url;
      // HttpService.CreateUpdate('getTaskFields', { TaskId: oldTaskId })
      axios.post(bacurl+'getTaskFields', { TaskId: oldTaskId })
        .then((result) => {
          let arr = []
          result.data.Data.forEach((one) => {
            if (one.Type == 'LookUp') {
              let obj = { ...one }
              delete obj._id;
              obj.TaskId = newTaskId;
              obj.TaskName = newTaskName;
              // arr.push(HttpService.CreateUpdate('createTaskField', obj))
              arr.push(axios.post(bacurl + 'createTaskField', obj))
            }
          })
          return Promise.all(arr)
        })
        .then((result) => {
       
        })
        .catch((err) => {
          // swal(`${err}`, { dangerMode: true });
        })
    }



  }


  const handleGetDuplicateTaskObj = () => {
    const taskObj = { ...parentObj };
    const duplicateObj = { ...duplicateValueObj };

    if (!duplicateObj.Assignee) {
      taskObj.Assignee.splice(0, taskObj.Assignee.length)
    }
    if (!duplicateObj.Milestone) {
      delete taskObj.isMilestone;
      delete taskObj.Milestone
    }
    if (!duplicateObj.Checklist) {
      taskObj.Checklist.splice(0, taskObj.Checklist.length)
    }
    if (!duplicateObj.Attachements) {
      taskObj.AttachedFiles.splice(0, taskObj.AttachedFiles.length)
    }
    if (!duplicateObj.Dependencies) {
      taskObj.DependentOn.splice(0, taskObj.DependentOn.length)
    }
    if (!duplicateObj.DueDate) {
      delete taskObj.DueDate;
    }
    if (!duplicateObj.TimeEstimate) {
      delete taskObj.TimeEstimateValue;
    }
    if (!duplicateObj.Status) {
      delete taskObj.Status;
    }
    if (!duplicateObj.Priority) {
      delete taskObj.Priority;
    }
    return taskObj


  }

  const handleServiceChange = (values) => {
    let services = [...serviceReq];
    services.forEach((one, index) => {
      let objKeys = Object.keys(one);
      let filterValue = values.filter(value => objKeys[0] == value.value)
      if (filterValue.length > 0) {
        services[index][objKeys[0]] = "Yes"
      } else {
        services[index][objKeys[0]] = "No"
      }

    })
    setServiceRequested(services);
  }





  const renderUseTemplateModal = () => {
    return <Modal
      isOpen={useTemplatePopUp}
      backdrop={backdrop}
      size='md'
      toggle={setUseTemplateToggle}
      className="taskComplete-warning duplicate-task-modal"
    >

      <ModalBody >
        <Row >
          <Col sm={6}>
            <span className='float-left'>
              <h4>Use Template</h4>
            </span>
          </Col>
          <Col sm={6}>
            <span className='float-right'>
              <FontAwesomeIcon style={{ cursor: 'pointer' }} onClick={setUseTemplateToggle} icon={faTimes} />
            </span>
          </Col>

        </Row>
        <Row >
          <Col sm={12}>
            <p className='small text-left'>New Task Name</p>
          </Col>
        </Row>
        <Row >
          <Col sm={12}>
            <div className='form-group'>
              <input onChange={handleOnChangeDupicateCheckBox} name='TaskName' type='text' placeholder='task name' className='form-control form-control-sm duplicate-taskname' />
            </div>
          </Col>
        </Row>
        <Row >
          <Col sm={12}>
            <p className='small text-left'>Pipeline Associated With :</p>
          </Col>
        </Row>
        <Row >
          <Col sm={12}>
            <p className="mr-2 px-1">
              <Select
                options={groupByField.map(one => { return { label: getGroupVal(one), value: one } })}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{ Option, MultiValue, animatedComponents }}
                onChange={(values) => {
                  let obj = { ...duplicateValueObj };
                  obj.Pipeline = values;
                  setDuplicateValueObj(obj)
                }}
                allowSelectAll={true}
                value={duplicateValueObj.Pipeline}
                menuPlacement="auto"
              // onChange={handleServiceChange}
              // value={getValues()}
              // isMulti={true}
              // options={options}
              // isClearable={false}
              />
            </p>
          </Col>
        </Row>
        <Row className='duplicate-task-box' >

          <Col sm={6}>
            <Row>
              <p style={{ fontSize: 16 }} className='small text-left'>What do you want to copy?</p>
            </Row>
            <Row>
              <Col sm={1}>
                <div className='float-left'>
                  <input onChange={(e) => {
                    let tasks = { ...selectedTemplate }
                    let obj = { ...duplicateValueObj }
                    if (tasks.custom_fields) {
                      let taskCustomFields = tasks.custom_fields.filter(one => one.column_name == 'UDF_MULTI1')
                      if (taskCustomFields.length > 0) {
                        taskCustomFields = taskCustomFields[0];
                        let fields = taskCustomFields.value.split(',');
                        fields = fields.map(one => {
                          if (one && one.trim()) {
                            return keyLableTemplate[one]
                          }
                        });
                        fields.forEach((key) => {
                          obj[key] = e.currentTarget.checked;
                        })
                        obj[e.currentTarget.name] = e.currentTarget.checked
                        setDuplicateValueObj(obj);
                      }
                    }

                  }} checked={duplicateValueObj.Everything} name='Everything' type='checkbox' />
                </div>
              </Col>
              <Col sm={8}>
                <p className='text-left small'> Import Everything</p>
              </Col>
            </Row>

            <hr className='m-0 mb-2' />

            {<>
              {Object.keys(duplicateValueObj).map(key => {
                let tasks = { ...selectedTemplate }
                if (tasks.custom_fields) {
                  let taskCustomFields = tasks.custom_fields.filter(one => one.column_name == 'UDF_MULTI1')
                  if (taskCustomFields.length > 0) {
                    taskCustomFields = taskCustomFields[0];
                    let fields = taskCustomFields.value.split(',');
                    fields = fields.map(one => {
                      if (one && one.trim()) {
                        return keyLableTemplate[one]
                      }
                    });
                    if (fields.includes(key)) {
                      return <Row>
                        <Col sm={1}>
                          <div className='float-left'>
                            <input onChange={handleOnChangeDupicateCheckBox} checked={duplicateValueObj[key]} name={key} type='checkbox' />
                          </div>
                        </Col>
                        <Col sm={8}>
                          <p className='text-left small'> {key}</p>
                        </Col>
                      </Row>
                    }

                  }
                }


              })}

              {/* {duplicateValueObj.Checklist ? <Row>
                <Col sm={1}>
                  <div className='float-left'>
                    <input onChange={handleOnChangeDupicateCheckBox} checked={duplicateValueObj.Checklist} name='Checklist' type='checkbox' />
                  </div>
                </Col>
                <Col sm={8}>
                  <p className='text-left small'> Checklist</p>
                </Col>
              </Row> : <></>} */}
              {/* <Row>
                <Col sm={1}>
                  <div className='float-left'>
                    <input onChange={handleOnChangeDupicateCheckBox} checked={duplicateValueObj.Comments} name='Comments' type='checkbox' />
                  </div>
                </Col>
                <Col sm={8}>
                  <p className='text-left small'> Comments</p>
                </Col>
              </Row>
              <Row>
                <Col sm={1}>
                  <div className='float-left'>
                    <input onChange={handleOnChangeDupicateCheckBox} checked={duplicateValueObj.InputFields} name='InputFields' type='checkbox' />
                  </div>
                </Col>
                <Col sm={8}>
                  <p className='text-left small'> Input Fields</p>
                </Col>
              </Row>
              <Row>
                <Col sm={1}>
                  <div className='float-left'>
                    <input onChange={handleOnChangeDupicateCheckBox} checked={duplicateValueObj.LookUpFields} name='LookUpFields' type='checkbox' />
                  </div>
                </Col>
                <Col sm={8}>
                  <p className='text-left small'> LookUp Fields</p>
                </Col>
              </Row>
              <Row>
                <Col sm={1}>
                  <div >
                    <input onChange={handleOnChangeDupicateCheckBox} checked={duplicateValueObj.Dependencies} name='Dependencies' type='checkbox' />
                  </div>
                </Col>
                <Col sm={8}>
                  <p className='text-left small'> Dependencies</p>
                </Col>
              </Row>
              <Row>
                <Col sm={1}>
                  <div >
                    <input name='DueDate' onChange={handleOnChangeDupicateCheckBox} checked={duplicateValueObj.DueDate} type='checkbox' />
                  </div>
                </Col>
                <Col sm={8}>
                  <p className='text-left small'> Due Date</p>
                </Col>
              </Row>
              <Row>
                <Col sm={1}>
                  <div >
                    <input onChange={handleOnChangeDupicateCheckBox} name='Assignee' checked={duplicateValueObj.Assignee} type='checkbox' />
                  </div>
                </Col>
                <Col sm={8}>
                  <p className=' text-left small'> Assignee</p>
                </Col>
              </Row>
              <Row>
                <Col sm={1}>
                  <div >
                    <input onChange={handleOnChangeDupicateCheckBox} name='TimeEstimate' checked={duplicateValueObj.TimeEstimate} type='checkbox' />
                  </div>
                </Col>
                <Col sm={8}>
                  <p className='text-left small'> Time Estimate</p>
                </Col>
              </Row>
              <Row>
                <Col sm={1}>
                  <div >
                    <input onChange={handleOnChangeDupicateCheckBox} name='Priority' checked={duplicateValueObj.Priority} type='checkbox' />
                  </div>
                </Col>
                <Col sm={8}>
                  <p className='text-left small'> Priority</p>
                </Col>
              </Row>
              <Row>
                <Col sm={1}>
                  <div >
                    <input onChange={handleOnChangeDupicateCheckBox} name='Status' checked={duplicateValueObj.Status} type='checkbox' />
                  </div>
                </Col>
                <Col sm={8}>
                  <p className='text-left small'> Status</p>
                </Col>
              </Row>
              <Row>
                <Col sm={1}>
                  <div >
                    <input onChange={handleOnChangeDupicateCheckBox} name='Subtask' checked={duplicateValueObj.Subtask} type='checkbox' />
                  </div>
                </Col>
                <Col sm={8}>
                  <p className='text-left small'> Subtask</p>
                </Col>
              </Row> */}
            </>}


          </Col>
          {/* <Col sm={6}>
            <Row>
              <Col sm={1}>
                <div >
                  <input name='Customize' onChange={(e) => {
                    let obj = { ...duplicateValueObj }
                    let keys = Object.keys(obj);
                    keys.forEach(key => {
                      if (key !== 'TaskName') {
                        obj[key] = !e.currentTarget.checked;
                      }
                    })

                    setDuplicateValueObj(obj);

                  }} type='checkbox' />
                </div>
              </Col>
              <Col sm={8}>
                <p className='text-left small'> Customize</p>
              </Col>
            </Row>
          </Col> */}

        </Row>
        <Row >
          <Col sm={12}>
            <button
              onClick={() => handleCreateDuplicateTask(duplicateValueObj, selectedTemplate)}
              className='btn btn-primary w-50 float-right'
            >Use Template </button>
          </Col>

        </Row>
      </ModalBody>
    </Modal>
  }





  const handleCreateDuplicateSubTask = async (oldTaskId, newTaskId, isTemplate, services) => {
    let subtaskRelation = await HttpService.CreateUpdate("getTaskRelationDocByParentTaskId", { ParentTaskId: oldTaskId });
    if (subtaskRelation.data.length > 0) {
      const duplicateObj = { ...duplicateValueObj };
      subtaskRelation = subtaskRelation.data[0];
      let subtask = taskDocs.filter((one) => one._id == subtaskRelation.TaskId);
      subtask = { ...subtask[0] };
      let oldTaskId1 = subtask._id;
      delete subtask._id;
      subtask.isTemplate = isTemplate ? true : false;
      if (isTemplate) {
        subtask.Template = duplicateObj;
      } else {
        subtask.TaskCopyOf = oldTaskId1;
      }
      let formData = new FormData();
      formData.append("data", JSON.stringify(subtask));
      HttpService.CreateUpdate("addtask", formData, {})
        .then(async (res) => {
          let obj = subtaskRelation
          delete obj._id;
          obj.ParentTaskId = newTaskId;
          obj.TaskId = res.data.Data._id;
          obj.TaskName = res.data.Data.TaskName;
          addDuplicateTaskComm_InputFields_LookUpFields(oldTaskId1, res.data.Data._id, res.data.Data.TaskName)
          await addActivity('ADD', 'TASK', res.data.Data._id, res.data.Data.TaskName, obj.TaskType)
          return HttpService.CreateUpdate('addTaskRelation', obj)
        })
        .then((result) => {
          
        })
        .catch((err) => {
          // swal(`${err}`, { dangerMode: true });
        })
    }
  }

  const isTaskAlreadyExist = (taskName) => {
    if (taskDocs.filter((one) => (one.TaskName.toLowerCase() == taskName.toLowerCase()) && (!one.isTemplate)).length > 0) {
      return true;
    }
    return false;

  }

  const isTemplateAlreadyExist = (taskName) => {
    if (taskDocs.filter((one) => ((one.TaskName.toLowerCase() == taskName.toLowerCase()) && (one.isTemplate))).length > 0) {
      return true;
    }
    return false;

  }



  const handleCopyComments = (pid, duplicateTaskObj, copyTaskId) => {
    TaskService.GetTaskComment(duplicateTaskObj.project.id_string, duplicateTaskObj.id_string)
      .then((notes) => {
        if (notes) {
          let doArr = []
          notes.forEach((note) => {
            doArr.push(TaskService.AddTaskComment(pid, copyTaskId, 'content', note.content))
          })
          return Promise.all(doArr)
        }
      })
      .then((result) => {
      
      })
      .catch(error => {
        console.log(error)
      });
  }


  const handleCopyAttachements = (pid, tid, copyTaskId) => {
    TaskService.GetTaskComment(pid, tid)
      .then((notes) => {
        if (notes) {
          // setCommentsBody(notes);
          // setAllData(notes)
        }

      })
      .catch(error => {
        console.log(error)
      });
  }




  const handleCreateDuplicateTask = async (duplicateFields, duplicateTask) => {
    let duplicateObj = { ...duplicateFields };
    let duplicateTaskObj = { ...duplicateTask };
    let data = {}
    data.person_responsible = ''
    data.name = duplicateFields.TaskName;
    if (duplicateObj.Priority) {
      data.priority = duplicateTaskObj.priority
    }
    if (duplicateObj.Assignee) {
      duplicateTaskObj.details.owners.forEach((one) => {
        if (one.name !== 'Unassigned') {
          data.person_responsible = data.person_responsible + one.id + ','
        }
      })
    }
    if (duplicateObj.DueDate) {
      data.end_date = duplicateTaskObj.end_date
    }
    let doArr = []
    let projects = await TaskService.GetAllProjects();
    projects = projects.data.projects;
    let filterProject = projects.filter(p => duplicateObj.Pipeline.some(pipe => p.name == pipe.value));
    if (filterProject.length > 0) {
      filterProject.forEach((project) => {
        doArr.push(createTemplateTask(project.id_string, data, duplicateObj, duplicateTaskObj))
      })
      Promise.all(doArr)
        .then((result) => {
        
          setBrowseTemplatePopup(false);
          setUseTemplatePopUp(false);
          // alert('task clone successfully');
        })
        .catch((err) => {
          console.log(err);
        })
    }

  }

  const createTemplateTask = (projectId, data, duplicateObj, duplicateTaskObj) => {
    return new Promise((resolve, reject) => {
      TaskService.CreateTask(projectId, data)
        .then((result) => {
          if (result) {
            result.project = { id_string: projectId };
            result.project_id = projectId;
            let obj = {}
            duplicateTaskObj.custom_fields.forEach((one) => {
              if (one.column_name == 'UDF_CHAR1' || one.column_name == 'UDF_CHAR3') {
                obj[one.column_name] = one.value
              }
            })
            updateTask(result, 'custom_fields', JSON.stringify(obj));
            if (duplicateObj.Comments) {
              handleCopyComments(projectId, duplicateTaskObj, result.id_string)
            }
            if (duplicateObj.Status) {
              let id = taskLayout.status_details.filter(one => one.name == duplicateTaskObj.status.name)
              if (id.length > 0) {
                updateTask(result, 'custom_status', id[0].id)
              }


            }

            if (duplicateObj.Subtask) {
              handleRecursiveCopySubtask(duplicateTaskObj, result.id_string)
            }

            if (duplicateObj.LookUpFields) {

            }
            if (duplicateObj.InputFields) {

            }
            if (duplicateObj.Attachements) {

            }

          }

          resolve('Create')




        })
        .catch((err) => {
          reject(err);
        })
    })
  }

  const getLabel = (value) => {
    if (value && value.id == 'milestone') {
      return parentObj && parentObj.isMilestone ? value.label2 : value.label1
    }
    if (value && value.id == 'duplicate') {
      return value.label1
    }
    if (value && value.id == 'template') {
      return value.label1
    }
  }
  const [priorityTooltipOpen, setPriorityTooltipOpen] = useState(false);

  const [mainTaskId, setMainTaskId] = useState('');


  const priorityToggle = () => {
    setPriorityTooltipOpen(!priorityTooltipOpen);
  }
  const [taskAction, setTaskAction] = useState('');

 

 
  const handleOpenTaskModel = (taskType, parent) => {
    if (parent) {
      setParentObject({});
      setParentTaskObj(parent);
      setTaskAction('Create');
      setNestedModal(true);
      setOpenModalType(taskType);
    } else {
      setParentTaskObj({});
      setParentObject({});
      setTaskAction('Create');
      setNestedModal(true);
      setOpenModalType(taskType);
    }

    // if (taskType == 'task') {
    //   setParentObject(null);
    //   setTaskAction('Create');
    //   setNestedModal(true);
    //   setOpenModalType(taskType);
    // }
    // if (taskType == 'subtask') {
    //   setTaskAction('Create');
    //   setSubtaskNestedModal(true);
    //   setOpenModalType(taskType);
    // }
    // if (taskType == 'metatask') {
    //   setTaskAction('Create');
    //   setMetaTaskNestedModal(true);
    //   setOpenModalType(taskType);
    // }

    // let TList = [...allTaskList];
    // setDependencyDropdownData(TList);

  }
 
  const [subTaskData, setSubtaskData] = useState(null);

 
 






  // const getAssigneeImg = (assigneeId, i) => {
  //   let imgUrl = assigntask;
  //   //  setPrevCheckList(CheckList[i])
  //   let obj = usersDetail.find((user) => user.UserId == assigneeId);
  //   if (obj != undefined)  {
  //     if(obj.ProfilePhoto!="" || obj.ProfilePhoto!=undefined){
  //       return obj.ProfilePhoto;
  //     }
  //     else{
  //       return  obj.FirstName.charAt(0);
  //     }

  //   } else {
  //     // if(usersDetail.length>0) {
  //     //   usersDetail.forEach(user=> {
  //     //     if(user.UserId == assigneeId){    //|| user._id == assigneeId
  //     //       imgUrl = user.ProfilePhoto;
  //     //     }
  //     //   })
  //     // }
  //     return imgUrl;
  //   }
  // };

  const navigateToDeal = (deal) => {
    deal = { ...deal }
    deal.Created_By = deal.Created_By.name;
    deal.Owner = deal.Owner.name;
    deal.Account_Name = deal.Account_Name ? deal.Account_Name.name : "";
    history.push({
      pathname: '/d/Deals/DetailView/' + deal.id,
      state: { data: deal, action: 'DetailView', module: 'Deals' }
    });
  }

  const showTaskLocationNames = (tasks) => {
    let taskData = [...allTasks];
    let jobData = [...props.Deals];
    let filterClient = {};
    let filterJob;
    let colorObj = { background: "#5b6c97", color: '#fff' }
    let clientColorObj = { background: "#5b6c97", color: '#fff' }
    taskData = taskData.filter((one) => one.id_string == tasks.root_task_id)[0]
    if (tasks && tasks.project) {
      filterJob = jobData.filter(one => {
        if (one.Project_ID) {
          let ids = one.Project_ID.split(',');
          ids = ids.filter((id) => id);
          return ids.includes(tasks.project.id_string);
        }

      })[0]
    
    } else {
      if (taskData && taskData.project) {
        filterJob = jobData.filter(one => {
          if (one.Project_ID) {
            let ids = one.Project_ID.split(',');
            ids = ids.filter((id) => id);
            return ids.includes(taskData.project.id_string);
          }
        })[0]
       
      }
    }

    if (filterJob.Project_Status_ID && props.dealStatus) {
      let color = props.dealStatus.filter((one) => one.status_id == filterJob.Project_Status_ID);
      if (color.length > 0) {
        color = color[0];
        colorObj = { background: color.status_color_hexcode, color: '#fff' }
      }

      if (props.AllClients.length > 0) {
        filterClient = props.AllClients.filter((one) => filterJob.Account_Name && (one.id == filterJob.Account_Name.id));
        if (filterClient.length > 0) {
          filterClient = filterClient[0];
          if (filterClient.Color) {
            clientColorObj.background = filterClient.Color;
          }
        }
      }
    }


    return (
      <>
        <span
          className="cont-box2"
        >
          <span
            onClick={() => {
              history.push({
                pathname: '/c/Accounts/DetailView/' + filterClient.id,
                state: { data: filterClient, action: 'Update', module: 'Accounts' }
              });
            }}
            style={clientColorObj}
            className="cont-box3"
          >{filterJob.Account_Name.name}

          </span>
          <span className="custom-slash">{"/"}</span>
          <span style={colorObj} onClick={() => navigateToDeal(filterJob)} className="cont-box4 cursor-pointer">
            {filterJob.Deal_Name}
          </span>
          {/* <span className="cont-box5">
            {filterJob.Project_Address}
          </span> */}
        </span>


      </>
    )
  }

  const showSubtaskParentNames = (tasks) => {
    let data = [...allMyTasks];
    let parentName
    let filterTask = data.filter(one => one.id_string == tasks.parent_task_id)[0];
   
    if (filterTask && filterTask.status) {
      return (
        <span onClick={() => openUpdateModal(filterTask, "task")} style={{
          background: filterTask.status.color_code,
          color: '#fff'
        }}>{filterTask ? filterTask.name : ""}</span>
      )
    }


  }
  const getAssigneeImg = (assigneeId, i) => {
    let imgUrl = assigntask;
    //  setPrevCheckList(CheckList[i])
    let obj = usersDetail.find((user) => user.UserId == assigneeId);
    if (obj != undefined) {
      return obj.ProfilePhoto;
    } else {
      // if(usersDetail.length>0) {
      //   usersDetail.forEach(user=> {
      //     if(user.UserId == assigneeId){    //|| user._id == assigneeId
      //       imgUrl = user.ProfilePhoto;
      //     }
      //   })
      // }
      //return imgUrl;
      return ''
    }
  };

  /**
   *
   * @param {*} clzName  - css className name
   * @returns addAssigneeImgIcon
   */
  const addAssigneeTblImgIcon = (clzName, taskId) => {
    return (
      <>
        <UncontrolledTooltip placement='top' target={'user-name-tooltip-default' + taskId}>
          No User
        </UncontrolledTooltip>
        <img
          onClick={() => {
            setSelectedTaskDropdown(taskId)
            setSaveTaskID(taskId);
            setSelectedAssigneeDropdown(null);

            // handleRemoveAssigne(assignee.userId, taskId)
          }}
          id={'user-name-tooltip-default' + taskId}
          src={assigntask}
          className="task-table-icon add-assignee-table cursor-pointer"
        />

      </>

    );
  };


  const addSuperviserTblImgIcon = (clzName, taskId) => {
    return (
      <>
        <UncontrolledTooltip placement='top' target={'user-name-tooltip-default' + taskId}>
          No User
        </UncontrolledTooltip>
        <img
          onClick={() => {
            setSelectedTaskDropdown(taskId)
            setSaveTaskID(taskId);
            setSelectedAssigneeDropdown(null);

            // handleRemoveAssigne(assignee.userId, taskId)
          }}
          id={'user-name-tooltip-default' + taskId}
          src={getAssigneeImg("")}
          className="task-table-icon add-assignee-table cursor-pointer"
        />

      </>

    );
  };

  const [assigneeTooltipOpen, setAssigneeTooltipOpen] = useState(false);

  const assigneeToggle = () => {
    setAssigneeTooltipOpen(!assigneeTooltipOpen);
  }

  const emptyAssigneeImg = () => {
    let assignee = {};
    assignee.username = "";
    return getModalAssigneeDropdown(assignee, false);
  };

  const getModalAssigneeDropdown = (assignee, hasAssignee) => {
    //  //; //
    var a = uuidv4();
    var b = uuidv4();
    a = a + b;

    var uniqueID = a.replace(/[0-9]/g, "");
    uniqueID = uniqueID.replace(/-/g, "");
    return (
      <UncontrolledDropdown className="task-header-dropdown modal-assignee-wrapper">
        <DropdownToggle
          className={hasAssignee ? "has-assignee" : "no-assignee"}
        // id={uniqueID}
        >
          {assignee.username == "" ? (
            //task-table-icon add-assignee-table cursor-pointer
            <>
              <img src={getAssigneeImg("")} className="add-assignee" id="assigneeTooltip" />
              <Tooltip
                placement="top"
                isOpen={assigneeTooltipOpen}
                target="assigneeTooltip"
                toggle={assigneeToggle}
              >
                Assignee
              </Tooltip>
            </>
          ) : (
            <>
              <img
                src={getAssigneeImg(assignee.userId)}
                className="rounded-circle selected-avtar"
              />{" "}
              {/* <img src={avtar} className="rounded-circle selected-avtar" />{" "} */}
              {/* <UncontrolledTooltip
          placement="top"
          target={uniqueID}e
        >
          {assignee.userName}
        </UncontrolledTooltip> */}
            </>
          )}
        </DropdownToggle>
        <DropdownMenu>
          {allUsers.map((user, i) => {
            var userName = user.FirstName + " " + user.LastName;
            // //;
            return (
              <DropdownItem
                onClick={(e) => addRemoveAssignee(userName, user.UserId, null)}
              >
                <img
                  src={user.ProfilePhoto}
                  className="mr-1 rounded-circle width25"
                />
                {userName}
              </DropdownItem>
            );
          })}
          <DropdownItem onClick={() => setAssigneDropState([])}>
            {" "}
            <FontAwesomeIcon
              icon={faTimes}
              style={{
                color: "red",
                marginRight: "10px",
              }}
            />
            CLEAR
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  };

  const [checkStatusToComplete, setCheckStatusToComplete] = useState(false);

  const [Comms, setComms] = useState("");
  const [CommentsBody, setCommentsBody] = useState([]);

  const handleKeyPress = async (e) => {
    // add task

    if (e.key === "Enter" && Comms != "") {
      const CommstoDB = {
        TaskID: selectedTaskId.task._id,
        UserName: localStorage.getItem("UserName"),
        UserID: localStorage.getItem("UserId"),
        Comments: Comms,
      };

      let returnData = await HttpService.CreateUpdate(
        "addtaskcomms",
        CommstoDB
      ).then((res) => {
        if (res.data.Ack > 0 && res.data.Message != "unsuccesfull") {
          setComms("");
          updateComments(CommstoDB.TaskID);
        }
      });
    }
  };

  const getAssigneeImgeForTaskCardTitle = (users) => {
    if (users == 'Unassignee') {
      return <span className="mx-2">{users}</span>
    }
    let assignee = users.split('EOU');
    assignee = assignee.filter(one => one !== '');
    let arr = []
    assignee.forEach((one) => {
      let obj = {}
      let filterUser = allUsers.filter((user) => {
        let name = user.full_name;
        if (name.startsWith(one)) {
          return user
        }
      })
      if (filterUser.length > 0) {
        obj.userName = filterUser[0].full_name;
        obj.userId = filterUser[0].id;
        filterUser[0].id = filterUser[0].zuid;
        arr.push(filterUser[0]);
      }

    })
    return <>{arr.map((assignee) => {
      return <div className="transparent-dropdown assignee-dropdown">
        <button className="btn btn-secondary">
          {addAssigneeImgTblImg(assignee)}
        </button>
      </div>

    })}
    </>



  }

  const updateComments = async (id) => {
    //fetch task comment
    const TaskID = { TaskID: id };
    let returnData = await HttpService.CreateUpdate(
      "gettaskcomms",
      TaskID
    ).then((res) => {
      setCommentsBody(res.data);
    });
  };

  const getPriorityIconForGroupBy = (groupValue) => {

    if (groupValue == 'NO PRIORITY') {
      return <><FontAwesomeIcon
        style={{
          color: 'black',
        }}
        icon={solidFlag}
      /><span className="mx-2">{groupValue}</span></>
      // return <><img
      //   src={tag}
      //   className="task-table-icon"
      //   color="white"
      // /><span className="mx-2">{groupValue}</span></>
    } else {
      let value = priorityValues.filter(one => one.name == groupValue);
      if (value.length > 0) {
        return <><FontAwesomeIcon
          style={{

            color: value[0].color,
          }}
          icon={solidFlag}
        /><span className="mx-2">{groupValue}</span></>
      } else {
        return <></>
      }

    }

  }

  const getStatusStyleGroupBy = (groupValue) => {
    let obj = {}
    let value = taskLayout.status_details.filter(one => one.name == groupValue);
    if (value.length > 0) {
      obj = { backgroundColor: value[0].color }
    } else {
      obj = {}
    }

    if (addColumns.filter(one => one.isVisible).length == 0) {
      obj.top = '-17px'
    }
    return obj

  }

  const showCalenderPopOver = (taskId) => {
    return <UncontrolledPopover
      className="empCalendar-popover"
      isOpen={employeeCalenderPopUp}
      trigger="legacy"
      placement="auto"
      // modifiers={modifiers}
      target={"employee-calender-popup" + taskId}
    //toggle={employeeCalenderPopUpToogle}
    // className='reminderUserDropdown'
    >
      <PopoverHeader>
        Employee Calender
      </PopoverHeader>
      <PopoverBody
      // className='activity-filters'
      >
        {/* <EmployeeCalender /> */}
        Employeee Calender
      </PopoverBody>
    </UncontrolledPopover>
  }


  const getTaskTreeTable = () => {
    return allTasks.map((tasks, index) => {
      var a = uuidv4();
      var b = uuidv4();
      a = a + b;
      var subtaskuniqueID = a.replace(/[0-9]/g, "");
      let taskEstHr = "00";
      let taskEstMin = "00";
      // if (tasks.TimeEstimateValue != "") {
      //   // let time=tasks.TimeEstimateValue.split(":");
      //   // taskEstHr=time[0];
      //   // taskEstMin=time[1];
      //   let obj = getTimeEstimateValue(
      //     tasks.TimeEstimateValue
      //   );
      //   taskEstHr = obj.hrs;
      //   taskEstMin = obj.mins;
      // }
      // var metauniqueID = c.replace(/[0-9]/g, "");
      // if (
      //   (groupBy == "Priority" || groupBy == "Status"
      //     ? tasks[groupBy].name
      //     : tasks[groupBy]) == groupValue
      // ) 
      {
        return (
          <>
            {true ?
              <tr key={index}>

                <td className="cursor-pointer ">
                  {tasks.subtasks ? (
                    <span
                      id={subtaskuniqueID}
                      className="mainTaskArrow"
                      onClick={() => {

                        if (tasks.project) {
                          handleClickOpenSubtask(tasks.project.id_string, tasks.id_string, tasks.parent_task_id, tasks.isparent)
                        }
                      }}
                    >
                      <FontAwesomeIcon
                        id={"collap" + tasks.id_string}
                        icon={
                          TaskCollapseIcon[index] != undefined
                            ? TaskCollapseIcon[index]
                              .taskArrow
                            : faCaretRight
                        }
                        onClick={() => {
                          toggleTaskIcons(tasks.id_string);
                        }}
                      />
                    </span>
                  ) : (
                    <span
                      id={subtaskuniqueID}
                    // className="padl-25"
                    >
                      {""}
                    </span>
                  )}
                  <UncontrolledDropdown
                    className="task-status-icon"
                  // id={subtaskuniqueID}
                  >
                    <span
                      style={{
                        borderColor: tasks.status.color,
                      }}
                    ></span>
                    <DropdownToggle
                      className={tasks.isMilestone ? "task-icon-bg " : 'task-icon-bg'}
                      style={{
                        backgroundColor: tasks.status.color,
                      }}
                    >
                      {""}
                    </DropdownToggle>
                    <DropdownMenu>
                      {statusValues.map((value, i) => {
                        return (
                          <DropdownItem
                            className="task-status-sq"
                            style={
                              {
                                // backgroundColor: value.color,
                                // color: "#fff",
                              }
                            }
                            onClick={async (e) => {

                            }
                            }
                          >
                            <span
                              style={{
                                backgroundColor: value.color,
                              }}
                            ></span>
                            {value.name}
                          </DropdownItem>
                        );
                      })}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <span
                    id={"OpenUrlModal" + tasks.id_string}
                    onClick={() =>
                      openUpdateModal(tasks, "task")
                    }
                    className="task-name-highlight"
                    hidden={editNameTask && (tasks.id_string === editNameTask.id_string)}
                  >
                    {tasks.name}

                  </span>
                  <input
                    hidden={!(editNameTask && (tasks.id_string == editNameTask.id_string))}
                    // hidden={!editableInputOpen}
                    type="text"
                    name="TaskName"
                    className="Task-name-input"
                    value={editNameTask ? editNameTask.name : ''}
                    id={tasks.id_string}
                    onChange={(e) => handleTaskNameChange(e)}
                    onKeyUp={(e) => {
                      if (e.key == "Enter") {
                        enterTaskName()
                      }
                    }}
                  />
                  <PlusSquareOutlined
                    style={{ fontSize: 11 }}
                    onClick={() => {
                      setParentObject(tasks)
                      handleOpenTaskModel("subtask")
                    }
                    }
                    id={"create-subtask-tip" + tasks.id_string}
                    className="ms-2 openTask-popup"
                  />
                  <UncontrolledTooltip
                    placement="top"
                    target={"create-subtask-tip" + tasks.id_string}

                  >
                    Create Subtask
                  </UncontrolledTooltip>

                  <span className="ms-2 testing-tesk-pen">
                    <FontAwesomeIcon
                      className="ms-2 openTask-popup"
                      style={{ fontSize: 10 }}
                      icon={faPen}
                      id={"rename-tooltip" + tasks.id_string}
                      onClick={() => editableField(tasks)}
                    />
                    <UncontrolledTooltip
                      placement="top"
                      target={"rename-tooltip" + tasks.id_string}

                    >
                      Rename
                    </UncontrolledTooltip>
                  </span>



                  <span className="task-name-highlight cursor-pointer">
                    {/* <AntdPopover trigger={'click'} placement='top' content={getDependencyContent(tasks.DependentOn)}> */}
                    {tasks.dependency && (tasks.dependency.predecessor.length > 0) && (tasks.dependency.successor.length > 0) ?
                      <img
                        className="minus-circle-v"
                        src={minusCircle}
                        onClick={() => {
                          setShowDependencyList(tasks);
                          setShowDependencyPopUp(true)
                        }}
                      />



                      : <FontAwesomeIcon
                        onClick={() => {
                          setShowDependencyList(tasks);
                          setShowDependencyPopUp(true)
                        }}
                        icon={faMinusCircle}
                        color={
                          tasks.dependency && (tasks.dependency.successor.length > 0)
                            ? "#f40600"
                            : "#FFA500"
                        }
                      />}
                  </span>
                </td>
                {/* {
                  addColumns.map(col => isVisibleTableColumn(col.headerName) ?
                    getTaskTdAsperHeader(tasks, col.headerName)
                    : '')} */}

                {/* {addColumns.map(col => getTaskTdAsperHeader(tasks, col.name))} */}


                <td></td>
              </tr>
              : ''}

            {tasks.subtasks ? (
              (tasks.Subtask && tasks.Subtask.length > 0) ? (
                tasks.Subtask.map((e, subIndex) => {
                  var a = uuidv4();
                  var b = uuidv4();
                  a = a + b;
                  let metauniqueID = a.replace(
                    /[0-9]/g,
                    ""
                  );
                  let taskEstHr = "00";
                  let taskEstMin = "00";
                  // if (e.TimeEstimateValue != "") {
                  //   let obj = getTimeEstimateValue(
                  //     e.TimeEstimateValue
                  //   );
                  //   taskEstHr = obj.hrs;
                  //   taskEstMin = obj.mins;
                  // }
                  // if(e.TimeEstimateValue!=""){
                  //   var time = e.TimeEstimateValue.split(":");
                  //   taskEstHr=time[0];
                  //   taskEstMin=time[1];
                  // }

                  return (
                    <>
                      {/* getStatusOfGroupBy(groupBy, e, groupValue) */}
                      {true ?



                        <tr
                          key={subIndex}
                          className={
                            TaskCollapseIcon[index] !=
                              undefined
                              ? TaskCollapseIcon[index]
                                .taskArrow == faCaretDown
                                ? "collapse show"
                                : "collapse"
                              : "collapse show"
                          }
                        >
                          <td
                            className={
                              subIndex ==
                                tasks.Subtask.length - 1
                                ? e.subtasks
                                  ? "is-subtask has-subtask last-subtask"
                                  : "is-subtask last-subtask"
                                : e.subtasks
                                  ? "is-subtask has-subtask"
                                  : "is-subtask"
                            }
                          >
                            <span className="mainTaskArrow">
                              {e.subtasks ? (
                                <FontAwesomeIcon
                                  icon={subTaskIcon(
                                    e.id_string
                                  )}
                                  onClick={() => {
                                    toggleSubTaskIcons(
                                      e.id_string
                                    )
                                    handleClickOpenSubtask(projectId, e.id_string, e.parent_task_id, e.isparent)
                                  }

                                  }
                                  id={metauniqueID}
                                />
                              ) : (
                                <p
                                  id={metauniqueID}
                                  className="padl-25"
                                >
                                  {""}
                                </p>
                              )}
                            </span>

                            <UncontrolledDropdown className="task-status-icon"
                            >
                              <span
                                style={{
                                  borderColor: e.status.color,
                                }}
                              ></span>
                              <DropdownToggle
                                className={e.isMilestone ? "task-icon-bg rotate-diamod" : 'task-icon-bg'}
                                style={{
                                  backgroundColor:
                                    e.status.color,
                                }}
                              >
                                {""}
                              </DropdownToggle>
                              <DropdownMenu>
                                {statusValues.map(
                                  (value, i) => {
                                    return (
                                      <DropdownItem

                                        className="task-status-sq"
                                        style={
                                          {
                                            // backgroundColor: value.color,
                                            // color: "#fff",
                                          }
                                        }
                                        onClick={async () => {

                                        }

                                        }
                                      >
                                        <span
                                          style={{
                                            backgroundColor:
                                              value.color,
                                          }}
                                        ></span>
                                        {value.name}
                                      </DropdownItem>
                                    );
                                  }
                                )}
                              </DropdownMenu>
                            </UncontrolledDropdown>
                            <span
                              className="task-name-highlight cursor-pointer"
                              id={"OpenUrlModal" + e.id_string}
                              onClick={
                                () =>
                                  openUpdateModal(
                                    e,
                                    "subtask",
                                    tasks
                                  ) //sub
                              }
                              hidden={editNameTask && (e.id_string === editNameTask.id_string)}
                            >
                              {e.name}

                            </span>
                            <input
                              hidden={!(editNameTask && (e.id_string == editNameTask.id_string))}
                              type="text"
                              name="TaskName"
                              className="Task-name-input"
                              value={editNameTask ? editNameTask.name : ''}
                              id={e.id_string}
                              onChange={(event) => handleTaskNameChange(event)}
                              onKeyUp={(event) => {
                                if (event.key == "Enter") {
                                  enterTaskName()
                                }
                              }}

                            />
                            <PlusSquareOutlined
                              style={{ fontSize: 11 }}
                              onClick={() => {
                                setParentObject(tasks);
                                setSubtaskData(e);
                                handleOpenTaskModel('metatask');
                              }
                              }
                              id={"create-subtask2-tip" + e.id_string}
                              className="ms-2 openTask-popup"
                            />
                            <UncontrolledTooltip
                              placement="top"
                              target={"create-subtask2-tip" + e.id_string}
                            >
                              Create Meta task
                            </UncontrolledTooltip>

                            <span className="ms-2 testing-tesk-pen">
                              <FontAwesomeIcon
                                className="ms-2 openTask-popup"
                                style={{ fontSize: 10 }}
                                icon={faPen}
                                id={"rename-subtooltip" + e.id_string}
                                onClick={() => editableField(e)}
                              />
                              <UncontrolledTooltip
                                placement="top"
                                target={"rename-subtooltip" + e.id_string}
                              >
                                Rename
                              </UncontrolledTooltip>
                            </span>
                            <span className="task-name-highlight cursor-pointer">
                              {e.DependentOn && e.DependentOn.length > 0 ?
                                <>
                                  {/* <AntdPopover trigger={'click'} placement='top' content={getDependencyContent(e.DependentOn)}> */}
                                  {isDependencyBothExist(e.DependentOn) ?
                                    <img
                                      className="minus-circle-v"
                                      src={minusCircle}
                                      onClick={() => {
                                        setShowDependencyList(e.DependentOn)
                                        setShowDependencyPopUp(true)
                                      }}
                                    />
                                    : <FontAwesomeIcon
                                      onClick={() => {
                                        setShowDependencyList(e.DependentOn)
                                        setShowDependencyPopUp(true)
                                      }}
                                      icon={faMinusCircle}
                                      color={
                                        e.DependentOn[0].type == "Blocking"
                                          ? "#f40600"
                                          : "#FFA500"
                                      }
                                    />
                                  }

                                  {/* </AntdPopover> */}
                                </>
                                : <></>
                              }
                            </span>
                          </td>
                          {addColumns.map(col => isVisibleTableColumn(col.name) ? getSubtaskTDAsperHeader(tasks, e, col.name) : "")}
                          <td></td>
                        </tr>
                        : ''}

                      {e.subtasks ? (
                        e.Subtask && e.Subtask.map(
                          (metaTask, metaIndex) => {
                            let taskEstHr = "00";
                            let taskEstMin = "00";
                            // if (e.TimeEstimateValue != "") {
                            //   let obj =
                            //     getTimeEstimateValue(
                            //       metaTask.TimeEstimateValue
                            //     );
                            //   taskEstHr = obj.hrs;
                            //   taskEstMin = obj.mins;
                            // }
                            // getStatusOfGroupBy(groupBy, metaTask, groupValue)
                            if (true) {
                              return (

                                <>  <tr
                                  key={metaIndex}
                                  className={
                                    TaskCollapseIcon[index] !=
                                      undefined
                                      ? TaskCollapseIcon[
                                        index
                                      ].taskArrow ==
                                        faCaretDown
                                        ? subTaskIcon(
                                          e.id_string
                                        ) == faCaretDown
                                          ? "collapse show"
                                          : "collapse"
                                        : "collapse"
                                      : subTaskIcon(
                                        e.id_string
                                      ) == faCaretDown
                                        ? "collapse show"
                                        : "collapse"
                                  }
                                >
                                  <td

                                    className={
                                      subIndex ==
                                        tasks.Subtask.length - 1
                                        ? metaIndex ==
                                          e.Subtask.length - 1
                                          ? "last-meta is-metatask metas-of-last-subtask"
                                          : "is-metatask metas-of-last-subtask"
                                        : metaIndex ==
                                          e.Subtask.length - 1
                                          ? "last-meta is-metatask"
                                          : "is-metatask"
                                    }
                                  >

                                    <span className="mainTaskArrow">
                                      {metaTask.subtasks ? (
                                        <FontAwesomeIcon
                                          icon={faCaretDown}
                                          onClick={() => {
                                            toggleSubTaskIcons(
                                              metaTask.id_string
                                            )
                                            handleClickOpenSubtask(projectId, metaTask.id_string, metaTask.parent_task_id, metaTask.isparent)
                                          }

                                          }
                                          id={metaTask.id_string}
                                        />
                                      ) : (
                                        <p
                                          id={metaTask.id_string}
                                          className="padl-25"
                                        >
                                          {""}
                                        </p>
                                      )}
                                    </span>
                                    <UncontrolledDropdown className="task-status-icon">
                                      <span
                                        style={{
                                          borderColor:
                                            metaTask.status
                                              .color,
                                        }}
                                      ></span>
                                      <DropdownToggle
                                        className={metaTask.isMilestone ? "task-icon-bg rotate-diamod" : 'task-icon-bg'}
                                        style={{
                                          backgroundColor:
                                            metaTask.status
                                              .color,
                                        }}
                                      >
                                        {""}
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        {statusValues.map(
                                          (value, i) => {
                                            return (
                                              <DropdownItem
                                                className="task-status-sq"
                                                style={
                                                  {
                                                    // backgroundColor: value.color,
                                                    // color: "#fff",
                                                  }
                                                }
                                                onClick={async () => {

                                                }

                                                }
                                              >
                                                <span
                                                  style={{
                                                    backgroundColor:
                                                      value.color,
                                                  }}
                                                ></span>
                                                {value.name}
                                              </DropdownItem>
                                            );
                                          }
                                        )}
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                    <span
                                      className="task-name-highlight cursor-pointer"
                                      id={"OpenUrlModal" + e.id_string}
                                      onClick={
                                        () =>
                                          openUpdateModal(
                                            "subtask",
                                            tasks,
                                            e,
                                            metaTask
                                          ) //sub
                                      }
                                      hidden={editNameTask && (metaTask.id_string === editNameTask.id_string)}
                                    >
                                      {metaTask.name}

                                    </span>
                                    <input
                                      hidden={!(editNameTask && (metaTask.id_string == editNameTask.id_string))}
                                      type="text"
                                      name="TaskName"
                                      className="Task-name-input"
                                      value={editNameTask ? editNameTask.name : ''}
                                      id={metaTask.id_string}
                                      onChange={(event) => handleTaskNameChange(event)}
                                      onKeyUp={(event) => {
                                        if (event.key == "Enter") {
                                          enterTaskName()
                                        }
                                      }}
                                    />



                                    <span className="ms-2 testing-tesk-pen">
                                      <FontAwesomeIcon
                                        style={{ fontSize: 10 }}
                                        className="ms-2 openTask-popup"
                                        icon={faPen}
                                        id={"rename-metatooltip" + metaTask.id_string}
                                        onClick={() => editableField(metaTask)}
                                      />
                                      <UncontrolledTooltip
                                        placement="top"
                                        target={"rename-metatooltip" + metaTask.id_string}
                                      >
                                        Rename
                                      </UncontrolledTooltip>
                                    </span>
                                    <span className="task-name-highlight cursor-pointer">
                                      {metaTask.DependentOn && metaTask.DependentOn.length > 0 ?
                                        <>
                                          {/* <AntdPopover trigger={'click'} placement='top' content={getDependencyContent(metaTask.DependentOn)}> */}
                                          {isDependencyBothExist(metaTask.DependentOn) ?
                                            <img
                                              className="minus-circle-v"
                                              src={minusCircle}
                                              onClick={() => {
                                                setShowDependencyList(metaTask.DependentOn)
                                                setShowDependencyPopUp(true)
                                              }}
                                            />

                                            : <FontAwesomeIcon
                                              onClick={() => {
                                                setShowDependencyList(metaTask.DependentOn)
                                                setShowDependencyPopUp(true)
                                              }}
                                              icon={faMinusCircle}
                                              color={
                                                metaTask.DependentOn[0].type == "Blocking"
                                                  ? "#f40600"
                                                  : "#FFA500"
                                              }
                                            />}

                                          {/* </AntdPopover> */}
                                        </>
                                        : <></>
                                      }
                                    </span>
                                  </td>

                                  {addColumns.map(col =>
                                    isVisibleTableColumn(col.headerName) ? getMetaTaskTDAsPerHeader(tasks, e, metaTask, col.name) : ""
                                  )}


                                  <td>

                                  </td>
                                </tr>
                                  {metaTask.subtasks ? (
                                    metaTask.Subtask && metaTask.Subtask.map(
                                      (fourth, fourthIndex) => {
                                        let taskEstHr = "00";
                                        let taskEstMin = "00";
                                        // if (e.TimeEstimateValue != "") {
                                        //   let obj =
                                        //     getTimeEstimateValue(
                                        //       metaTask.TimeEstimateValue
                                        //     );
                                        //   taskEstHr = obj.hrs;
                                        //   taskEstMin = obj.mins;
                                        // }
                                        // getStatusOfGroupBy(groupBy, metaTask, groupValue)
                                        if (true) {
                                          return (

                                            <>  <tr
                                              key={fourthIndex}
                                              className={
                                                TaskCollapseIcon[index] !=
                                                  undefined
                                                  ? TaskCollapseIcon[
                                                    index
                                                  ].taskArrow ==
                                                    faCaretDown
                                                    ? subTaskIcon(
                                                      metaTask.id_string
                                                    ) == faCaretDown
                                                      ? "collapse show"
                                                      : "collapse"
                                                    : "collapse"
                                                  : subTaskIcon(
                                                    metaTask.id_string
                                                  ) == faCaretDown
                                                    ? "collapse show"
                                                    : "collapse"
                                              }
                                            >
                                              <td

                                                className={
                                                  subIndex ==
                                                    e.Subtask.length - 1
                                                    ? metaIndex ==
                                                      metaTask.Subtask.length - 1
                                                      ? "last-meta is-metatask metas-of-last-subtask"
                                                      : "is-metatask metas-of-last-subtask"
                                                    : fourthIndex ==
                                                      metaTask.Subtask.length - 1
                                                      ? "last-meta is-metatask"
                                                      : "is-metatask"
                                                }
                                              >

                                                <span className="mainTaskArrow">
                                                  {fourth.subtasks ? (
                                                    <FontAwesomeIcon
                                                      icon={subTaskIcon(
                                                        fourth.id_string
                                                      )}
                                                      onClick={() => {
                                                        toggleSubTaskIcons(
                                                          fourth.id_string
                                                        )
                                                        handleClickOpenSubtask(projectId, fourth.id_string, fourth.parent_task_id, fourth.isparent)
                                                      }

                                                      }
                                                      id={metauniqueID}
                                                    />
                                                  ) : (
                                                    <p
                                                      id={metauniqueID}
                                                      className="padl-25"
                                                    >
                                                      {""}
                                                    </p>
                                                  )}
                                                </span>
                                                <UncontrolledDropdown className="task-status-icon">
                                                  <span
                                                    style={{
                                                      borderColor:
                                                        fourth.status
                                                          .color,
                                                    }}
                                                  ></span>
                                                  <DropdownToggle
                                                    className={fourth.isMilestone ? "task-icon-bg rotate-diamod" : 'task-icon-bg'}
                                                    style={{
                                                      backgroundColor:
                                                        fourth.status
                                                          .color,
                                                    }}
                                                  >
                                                    {""}
                                                  </DropdownToggle>
                                                  <DropdownMenu>
                                                    {statusValues.map(
                                                      (value, i) => {
                                                        return (
                                                          <DropdownItem
                                                            className="task-status-sq"
                                                            style={
                                                              {
                                                                // backgroundColor: value.color,
                                                                // color: "#fff",
                                                              }
                                                            }
                                                            onClick={async () => {

                                                            }

                                                            }
                                                          >
                                                            <span
                                                              style={{
                                                                backgroundColor:
                                                                  value.color,
                                                              }}
                                                            ></span>
                                                            {value.name}
                                                          </DropdownItem>
                                                        );
                                                      }
                                                    )}
                                                  </DropdownMenu>
                                                </UncontrolledDropdown>
                                                <span
                                                  className="task-name-highlight cursor-pointer"
                                                  id={"OpenUrlModal" + fourth.id_string}
                                                  onClick={
                                                    () =>
                                                      openUpdateModal(
                                                        "subtask",
                                                        tasks,
                                                        e,
                                                        metaTask
                                                      ) //sub
                                                  }
                                                  hidden={editNameTask && (fourth.id_string === editNameTask.id_string)}
                                                >
                                                  {fourth.name}

                                                </span>
                                                <input
                                                  hidden={!(editNameTask && (fourth.id_string == editNameTask.id_string))}
                                                  type="text"
                                                  name="TaskName"
                                                  className="Task-name-input"
                                                  value={editNameTask ? editNameTask.name : ''}
                                                  id={metaTask.id_string}
                                                  onChange={(event) => handleTaskNameChange(event)}
                                                  onKeyUp={(event) => {
                                                    if (event.key == "Enter") {
                                                      enterTaskName()
                                                    }
                                                  }}
                                                />



                                                <span className="ms-2 testing-tesk-pen">
                                                  <FontAwesomeIcon
                                                    style={{ fontSize: 10 }}
                                                    className="ms-2 openTask-popup"
                                                    icon={faPen}
                                                    id={"rename-metatooltip" + fourth.id_string}
                                                    onClick={() => editableField(fourth)}
                                                  />
                                                  <UncontrolledTooltip
                                                    placement="top"
                                                    target={"rename-metatooltip" + fourth.id_string}
                                                  >
                                                    Rename
                                                  </UncontrolledTooltip>
                                                </span>
                                                <span className="task-name-highlight cursor-pointer">
                                                  {fourth.DependentOn && fourth.DependentOn.length > 0 ?
                                                    <>
                                                      {/* <AntdPopover trigger={'click'} placement='top' content={getDependencyContent(metaTask.DependentOn)}> */}
                                                      {isDependencyBothExist(metaTask.DependentOn) ?
                                                        <img
                                                          className="minus-circle-v"
                                                          src={minusCircle}
                                                          onClick={() => {
                                                            setShowDependencyList(fourth.DependentOn)
                                                            setShowDependencyPopUp(true)
                                                          }}
                                                        />

                                                        : <FontAwesomeIcon
                                                          onClick={() => {
                                                            setShowDependencyList(fourth.DependentOn)
                                                            setShowDependencyPopUp(true)
                                                          }}
                                                          icon={faMinusCircle}
                                                          color={
                                                            metaTask.DependentOn[0].type == "Blocking"
                                                              ? "#f40600"
                                                              : "#FFA500"
                                                          }
                                                        />}

                                                      {/* </AntdPopover> */}
                                                    </>
                                                    : <></>
                                                  }
                                                </span>
                                              </td>

                                              {addColumns.map(col =>
                                                isVisibleTableColumn(col.headerName) ? getMetaTaskTDAsPerHeader(tasks, e, metaTask, col.name) : ""
                                              )}


                                              <td></td>
                                            </tr>
                                              {fourth.subtasks ? (
                                                fourth.Subtask && fourth.Subtask.map(
                                                  (fifth, fifthIndex) => {
                                                    let taskEstHr = "00";
                                                    let taskEstMin = "00";
                                                    // if (e.TimeEstimateValue != "") {
                                                    //   let obj =
                                                    //     getTimeEstimateValue(
                                                    //       metaTask.TimeEstimateValue
                                                    //     );
                                                    //   taskEstHr = obj.hrs;
                                                    //   taskEstMin = obj.mins;
                                                    // }
                                                    // getStatusOfGroupBy(groupBy, metaTask, groupValue)
                                                    if (true) {
                                                      return (

                                                        <>  <tr
                                                          key={fifthIndex}
                                                          className={
                                                            TaskCollapseIcon[index] !=
                                                              undefined
                                                              ? TaskCollapseIcon[
                                                                index
                                                              ].taskArrow ==
                                                                faCaretDown
                                                                ? subTaskIcon(
                                                                  fourth.id_string
                                                                ) == faCaretDown
                                                                  ? "collapse show"
                                                                  : "collapse"
                                                                : "collapse"
                                                              : subTaskIcon(
                                                                fourth.id_string
                                                              ) == faCaretDown
                                                                ? "collapse show"
                                                                : "collapse"
                                                          }
                                                        >
                                                          <td

                                                            className={
                                                              subIndex ==
                                                                metaTask.Subtask.length - 1
                                                                ? metaIndex ==
                                                                  fourth.Subtask.length - 1
                                                                  ? "last-meta is-metatask metas-of-last-subtask"
                                                                  : "is-metatask metas-of-last-subtask"
                                                                : fifthIndex ==
                                                                  fourth.Subtask.length - 1
                                                                  ? "last-meta is-metatask"
                                                                  : "is-metatask"
                                                            }
                                                          >
                                                            <span className="mainTaskArrow">
                                                              {fifth.subtasks ? (
                                                                <FontAwesomeIcon
                                                                  icon={subTaskIcon(
                                                                    fifth.id_string
                                                                  )}
                                                                  onClick={() => {
                                                                    toggleSubTaskIcons(
                                                                      fifth.id_string
                                                                    )
                                                                    handleClickOpenSubtask(projectId, fifth.id_string, fifth.parent_task_id, fifth.isparent)
                                                                  }

                                                                  }
                                                                  id={metauniqueID}
                                                                />
                                                              ) : (
                                                                <p
                                                                  id={metauniqueID}
                                                                  className="padl-25"
                                                                >
                                                                  {""}
                                                                </p>
                                                              )}
                                                            </span>
                                                            <UncontrolledDropdown className="task-status-icon">
                                                              <span
                                                                style={{
                                                                  borderColor:
                                                                    fifth.status
                                                                      .color,
                                                                }}
                                                              ></span>
                                                              <DropdownToggle
                                                                className={fourth.isMilestone ? "task-icon-bg rotate-diamod" : 'task-icon-bg'}
                                                                style={{
                                                                  backgroundColor:
                                                                    fifth.status
                                                                      .color,
                                                                }}
                                                              >
                                                                {""}
                                                              </DropdownToggle>
                                                              <DropdownMenu>
                                                                {statusValues.map(
                                                                  (value, i) => {
                                                                    return (
                                                                      <DropdownItem
                                                                        className="task-status-sq"
                                                                        style={
                                                                          {
                                                                            // backgroundColor: value.color,
                                                                            // color: "#fff",
                                                                          }
                                                                        }
                                                                        onClick={async () => {

                                                                        }

                                                                        }
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            backgroundColor:
                                                                              value.color,
                                                                          }}
                                                                        ></span>
                                                                        {value.name}
                                                                      </DropdownItem>
                                                                    );
                                                                  }
                                                                )}
                                                              </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                            <span
                                                              className="task-name-highlight cursor-pointer"
                                                              id={"OpenUrlModal" + fifth.id_string}
                                                              onClick={
                                                                () =>
                                                                  openUpdateModal(
                                                                    "subtask",
                                                                    tasks,
                                                                    e,
                                                                    metaTask
                                                                  ) //sub
                                                              }
                                                              hidden={editNameTask && (fifth.id_string === editNameTask.id_string)}
                                                            >
                                                              {fifth.name}

                                                            </span>
                                                            <input
                                                              hidden={!(editNameTask && (fifth.id_string == editNameTask.id_string))}
                                                              type="text"
                                                              name="TaskName"
                                                              className="Task-name-input"
                                                              value={editNameTask ? editNameTask.name : ''}
                                                              id={fifth.id_string}
                                                              onChange={(event) => handleTaskNameChange(event)}
                                                              onKeyUp={(event) => {
                                                                if (event.key == "Enter") {
                                                                  enterTaskName()
                                                                }
                                                              }}
                                                            />



                                                            <span className="ms-2 testing-tesk-pen">
                                                              <FontAwesomeIcon
                                                                style={{ fontSize: 10 }}
                                                                className="ms-2 openTask-popup"
                                                                icon={faPen}
                                                                id={"rename-metatooltip" + fourth.id_string}
                                                                onClick={() => editableField(fourth)}
                                                              />
                                                              <UncontrolledTooltip
                                                                placement="top"
                                                                target={"rename-metatooltip" + fourth.id_string}
                                                              >
                                                                Rename
                                                              </UncontrolledTooltip>
                                                            </span>
                                                            <span className="task-name-highlight cursor-pointer">
                                                              {fifth.DependentOn && fifth.DependentOn.length > 0 ?
                                                                <>
                                                                  {/* <AntdPopover trigger={'click'} placement='top' content={getDependencyContent(metaTask.DependentOn)}> */}
                                                                  {isDependencyBothExist(fifth.DependentOn) ?
                                                                    <img
                                                                      className="minus-circle-v"
                                                                      src={minusCircle}
                                                                      onClick={() => {
                                                                        setShowDependencyList(fifth.DependentOn)
                                                                        setShowDependencyPopUp(true)
                                                                      }}
                                                                    />

                                                                    : <FontAwesomeIcon
                                                                      onClick={() => {
                                                                        setShowDependencyList(fifth.DependentOn)
                                                                        setShowDependencyPopUp(true)
                                                                      }}
                                                                      icon={faMinusCircle}
                                                                      color={
                                                                        fifth.DependentOn[0].type == "Blocking"
                                                                          ? "#f40600"
                                                                          : "#FFA500"
                                                                      }
                                                                    />}

                                                                  {/* </AntdPopover> */}
                                                                </>
                                                                : <></>
                                                              }
                                                            </span>
                                                          </td>

                                                          {addColumns.map(col =>
                                                            isVisibleTableColumn(col.headerName) ? getMetaTaskTDAsPerHeader(tasks, e, metaTask, col.name) : ""
                                                          )}


                                                          <td></td>
                                                        </tr>
                                                          {fifth.subtasks ? (
                                                            fifth.Subtask && fifth.Subtask.map(
                                                              (sixth, sixthIndex) => {
                                                                let taskEstHr = "00";
                                                                let taskEstMin = "00";
                                                                // if (e.TimeEstimateValue != "") {
                                                                //   let obj =
                                                                //     getTimeEstimateValue(
                                                                //       metaTask.TimeEstimateValue
                                                                //     );
                                                                //   taskEstHr = obj.hrs;
                                                                //   taskEstMin = obj.mins;
                                                                // }
                                                                // getStatusOfGroupBy(groupBy, metaTask, groupValue)
                                                                if (true) {
                                                                  return (

                                                                    <tr
                                                                      key={sixthIndex}
                                                                      className={
                                                                        TaskCollapseIcon[index] !=
                                                                          undefined
                                                                          ? TaskCollapseIcon[
                                                                            index
                                                                          ].taskArrow ==
                                                                            faCaretDown
                                                                            ? subTaskIcon(
                                                                              fifth.id_string
                                                                            ) == faCaretDown
                                                                              ? "collapse show"
                                                                              : "collapse"
                                                                            : "collapse"
                                                                          : subTaskIcon(
                                                                            fifth.id_string
                                                                          ) == faCaretDown
                                                                            ? "collapse show"
                                                                            : "collapse"
                                                                      }
                                                                    >
                                                                      <td

                                                                        className={
                                                                          subIndex ==
                                                                            fourth.Subtask.length - 1
                                                                            ? fourthIndex ==
                                                                              sixth.Subtask.length - 1
                                                                              ? "last-meta is-metatask metas-of-last-subtask"
                                                                              : "is-metatask metas-of-last-subtask"
                                                                            : sixthIndex ==
                                                                              sixth.Subtask.length - 1
                                                                              ? "last-meta is-metatask"
                                                                              : "is-metatask"
                                                                        }
                                                                      >
                                                                        <span className="mainTaskArrow">
                                                                          {sixth.subtasks ? (
                                                                            <FontAwesomeIcon
                                                                              icon={subTaskIcon(
                                                                                sixth.id_string
                                                                              )}
                                                                              onClick={() => {
                                                                                toggleSubTaskIcons(
                                                                                  sixth.id_string
                                                                                )
                                                                                handleClickOpenSubtask(projectId, sixth.id_string, sixth.parent_task_id, sixth.isparent)
                                                                              }

                                                                              }
                                                                              id={metauniqueID}
                                                                            />
                                                                          ) : (
                                                                            <p
                                                                              id={metauniqueID}
                                                                              className="padl-25"
                                                                            >
                                                                              {""}
                                                                            </p>
                                                                          )}
                                                                        </span>
                                                                        <UncontrolledDropdown className="task-status-icon">
                                                                          <span
                                                                            style={{
                                                                              borderColor:
                                                                                sixth.status
                                                                                  .color,
                                                                            }}
                                                                          ></span>
                                                                          <DropdownToggle
                                                                            className={sixth.isMilestone ? "task-icon-bg rotate-diamod" : 'task-icon-bg'}
                                                                            style={{
                                                                              backgroundColor:
                                                                                sixth.status
                                                                                  .color,
                                                                            }}
                                                                          >
                                                                            {""}
                                                                          </DropdownToggle>
                                                                          <DropdownMenu>
                                                                            {statusValues.map(
                                                                              (value, i) => {
                                                                                return (
                                                                                  <DropdownItem
                                                                                    className="task-status-sq"
                                                                                    style={
                                                                                      {
                                                                                        // backgroundColor: value.color,
                                                                                        // color: "#fff",
                                                                                      }
                                                                                    }
                                                                                    onClick={async () => {

                                                                                    }

                                                                                    }
                                                                                  >
                                                                                    <span
                                                                                      style={{
                                                                                        backgroundColor:
                                                                                          value.color,
                                                                                      }}
                                                                                    ></span>
                                                                                    {value.name}
                                                                                  </DropdownItem>
                                                                                );
                                                                              }
                                                                            )}
                                                                          </DropdownMenu>
                                                                        </UncontrolledDropdown>
                                                                        <span
                                                                          className="task-name-highlight cursor-pointer"
                                                                          id={"OpenUrlModal" + sixth.id_string}
                                                                          onClick={
                                                                            () =>
                                                                              openUpdateModal(
                                                                                "subtask",
                                                                                tasks,
                                                                                e,
                                                                                metaTask
                                                                              ) //sub
                                                                          }
                                                                          hidden={editNameTask && (sixth.id_string === editNameTask.id_string)}
                                                                        >
                                                                          {sixth.name}

                                                                        </span>
                                                                        <input
                                                                          hidden={!(editNameTask && (sixth.id_string == editNameTask.id_string))}
                                                                          type="text"
                                                                          name="TaskName"
                                                                          className="Task-name-input"
                                                                          value={editNameTask ? editNameTask.name : ''}
                                                                          id={sixth.id_string}
                                                                          onChange={(event) => handleTaskNameChange(event)}
                                                                          onKeyUp={(event) => {
                                                                            if (event.key == "Enter") {
                                                                              enterTaskName()
                                                                            }
                                                                          }}
                                                                        />



                                                                        <span className="ms-2 testing-tesk-pen">
                                                                          <FontAwesomeIcon
                                                                            style={{ fontSize: 10 }}
                                                                            className="ms-2 openTask-popup"
                                                                            icon={faPen}
                                                                            id={"rename-metatooltip" + sixth.id_string}
                                                                            onClick={() => editableField(fourth)}
                                                                          />
                                                                          <UncontrolledTooltip
                                                                            placement="top"
                                                                            target={"rename-metatooltip" + sixth.id_string}
                                                                          >
                                                                            Rename
                                                                          </UncontrolledTooltip>
                                                                        </span>
                                                                        <span className="task-name-highlight cursor-pointer">
                                                                          {fifth.DependentOn && fifth.DependentOn.length > 0 ?
                                                                            <>
                                                                              {/* <AntdPopover trigger={'click'} placement='top' content={getDependencyContent(metaTask.DependentOn)}> */}
                                                                              {isDependencyBothExist(sixth.DependentOn) ?
                                                                                <img
                                                                                  className="minus-circle-v"
                                                                                  src={minusCircle}
                                                                                  onClick={() => {
                                                                                    setShowDependencyList(sixth.DependentOn)
                                                                                    setShowDependencyPopUp(true)
                                                                                  }}
                                                                                />

                                                                                : <FontAwesomeIcon
                                                                                  onClick={() => {
                                                                                    setShowDependencyList(sixth.DependentOn)
                                                                                    setShowDependencyPopUp(true)
                                                                                  }}
                                                                                  icon={faMinusCircle}
                                                                                  color={
                                                                                    sixth.DependentOn[0].type == "Blocking"
                                                                                      ? "#f40600"
                                                                                      : "#FFA500"
                                                                                  }
                                                                                />}

                                                                              {/* </AntdPopover> */}
                                                                            </>
                                                                            : <></>
                                                                          }
                                                                        </span>
                                                                      </td>

                                                                      {addColumns.map(col =>
                                                                        isVisibleTableColumn(col.headerName) ? getMetaTaskTDAsPerHeader(tasks, e, metaTask, col.name) : ""
                                                                      )}


                                                                      <td></td>
                                                                    </tr>
                                                                  );
                                                                }
                                                              }
                                                            )
                                                          ) : (
                                                            <></>
                                                          )}

                                                        </>
                                                      );
                                                    }
                                                  }
                                                )
                                              ) : (
                                                <></>
                                              )}


                                            </>
                                          );
                                        }
                                      }
                                    )
                                  ) : (
                                    <></>
                                  )}


                                </>

                              );
                            }
                          }
                        )
                      ) : (
                        <></>
                      )}
                    </>
                  );
                })
              ) : (
                <></>
              )
            ) : (
              <></>
            )}
          </>
        );
      }
    })
  }

  const HelperFnGetRecursive = (tasks, index) => {
    return tasks.subtasks ? (
      tasks.subtasks ? (
        tasks.Subtask && tasks.Subtask.map((e, subIndex) => {
          var a = uuidv4();
          var b = uuidv4();
          a = a + b;
          let metauniqueID = a.replace(
            /[0-9]/g,
            ""
          );
          let taskEstHr = "00";
          let taskEstMin = "00";
          // if (e.TimeEstimateValue != "") {
          //   let obj = getTimeEstimateValue(
          //     e.TimeEstimateValue
          //   );
          //   taskEstHr = obj.hrs;
          //   taskEstMin = obj.mins;
          // }
          // if(e.TimeEstimateValue!=""){
          //   var time = e.TimeEstimateValue.split(":");
          //   taskEstHr=time[0];
          //   taskEstMin=time[1];
          // }

          return (
            <>
              {true ?



                <tr
                  key={subIndex}
                  className={
                    TaskCollapseIcon[index] !=
                      undefined
                      ? TaskCollapseIcon[index]
                        .taskArrow == faCaretDown
                        ? "collapse show"
                        : "collapse"
                      : "collapse show"
                  }
                >
                  <td
                    className={
                      subIndex ==
                        tasks.Subtask.length - 1
                        ? e.subtasks > 0
                          ? "is-subtask has-subtask last-subtask"
                          : "is-subtask last-subtask"
                        : e.subtask > 0
                          ? "is-subtask has-subtask"
                          : "is-subtask"
                    }
                  >
                    <span className="mainTaskArrow">
                      {e.subtasks ? (
                        <FontAwesomeIcon
                          icon={subTaskIcon(
                            e.id_string
                          )}
                          onClick={() => {
                            toggleSubTaskIcons(
                              e.id_string
                            )
                            handleClickOpenSubtask(projectId, e.id_string, e.parent_task_id, e)
                          }
                          }
                          id={metauniqueID}
                        />
                      ) : (
                        <p
                          id={metauniqueID}
                          className="padl-25"
                        >
                          {""}
                        </p>
                      )}
                    </span>

                    <UncontrolledDropdown className="task-status-icon"
                    >
                      <span
                        style={{
                          borderColor: e.status.color_code,
                        }}
                      ></span>
                      <DropdownToggle
                        className={e.isMilestone ? "task-icon-bg rotate-diamod" : 'task-icon-bg'}
                        style={{
                          backgroundColor:
                            e.status.color_code,
                        }}
                      >
                        {""}
                      </DropdownToggle>
                      <DropdownMenu>
                        {statusValues.map(
                          (value, i) => {
                            return (
                              <DropdownItem

                                className="task-status-sq"
                                style={
                                  {
                                    // backgroundColor: value.color,
                                    // color: "#fff",
                                  }
                                }
                                onClick={async () => {

                                }

                                }
                              >
                                <span
                                  style={{
                                    backgroundColor:
                                      value.color,
                                  }}
                                ></span>
                                {value.name}
                              </DropdownItem>
                            );
                          }
                        )}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    <span
                      className="task-name-highlight cursor-pointer"
                      id={"OpenUrlModal" + e.id_string}
                      onClick={
                        () =>
                          openUpdateModal(
                            e,
                            "task",
                            //tasks
                          ) //sub
                      }
                      hidden={editNameTask && (e.id_string === editNameTask._id)}
                    >
                      {e.name}

                    </span>
                    <input
                      hidden={!(editNameTask && (e.id_string == editNameTask._id))}
                      type="text"
                      name="TaskName"
                      className="Task-name-input"
                      value={editNameTask ? editNameTask.name : ''}
                      id={e._id}
                      onChange={(event) => handleTaskNameChange(event)}
                      onKeyUp={(event) => {
                        if (event.key == "Enter") {
                          enterTaskName()
                        }
                      }}

                    />
                    <PlusSquareOutlined
                      style={{ fontSize: 11 }}
                      onClick={() => {
                        setParentObject(tasks);
                        setSubtaskData(e);
                        handleOpenTaskModel('metatask');
                      }
                      }
                      id={"create-subtask2-tip" + e.id_string}
                      className="ms-2 openTask-popup"
                    />
                    <UncontrolledTooltip
                      placement="top"
                      target={"create-subtask2-tip" + e.id_string}
                    >
                      Create Meta task
                    </UncontrolledTooltip>

                    <span className="ms-2 testing-tesk-pen">
                      <FontAwesomeIcon
                        className="ms-2 openTask-popup"
                        style={{ fontSize: 10 }}
                        icon={faPen}
                        id={"rename-subtooltip" + e.id_string}
                        onClick={() => editableField(e)}
                      />
                      <UncontrolledTooltip
                        placement="top"
                        target={"rename-subtooltip" + e.id_string}
                      >
                        Rename
                      </UncontrolledTooltip>
                    </span>
                    <span className="task-name-highlight cursor-pointer">
                      {/* {e.DependentOn.length > 0 ? */}{false ?
                        <>
                          {/* <AntdPopover trigger={'click'} placement='top' content={getDependencyContent(e.DependentOn)}> */}
                          {isDependencyBothExist(e.DependentOn) ?
                            <img
                              className="minus-circle-v"
                              src={minusCircle}
                              onClick={() => {
                                setShowDependencyList(e.DependentOn)
                                setShowDependencyPopUp(true)
                              }}
                            />
                            : <FontAwesomeIcon
                              onClick={() => {
                                setShowDependencyList(e.DependentOn)
                                setShowDependencyPopUp(true)
                              }}
                              icon={faMinusCircle}
                              color={
                                e.DependentOn[0].type == "Blocking"
                                  ? "#f40600"
                                  : "#FFA500"
                              }
                            />
                          }

                          {/* </AntdPopover> */}
                        </>
                        : <></>
                      }
                    </span>
                  </td>
                  {addColumns.map(col => isVisibleTableColumn(col.headerName) ? getSubtaskTDAsperHeader(tasks, e, col.name) : "")}
                  <td></td>
                </tr>
                : ''}

              {HelperFnGetRecursive(e, subIndex)}
            </>
          );
        })
      ) : (
        <></>
      )
    ) : (
      <></>
    )
  }

  const copySubtask = async (taskId, subtask) => {
    subtask = { ...subtask }
    let data = {}
    data.person_responsible = ''
    data.name = subtask.name;
    data.priority = subtask.priority
    subtask.details.owners.forEach((one) => {
      if (one.id) {
        data.person_responsible = data.person_responsible + one.id + ','
      }
    })
    if (subtask.start_date) {
      data.start_date = subtask.start_date;
    }
    if (subtask.end_date) {
      data.end_date = subtask.end_date;
    }
    data.description = subtask.description;
    TaskService.CreateSubtask(props.dealObj.Project_ID, taskId, data)
      .then(async (result) => {
        if (result) {
          let obj = {}
          subtask.custom_fields.forEach((one) => {
            if (one.column_name == 'UDF_CHAR1' || one.column_name == 'UDF_CHAR3') {
              obj[one.column_name] = one.value
            }
          })
          updateTask(result.id_string, 'custom_fields', JSON.stringify(obj));
          handleCopyComments(projectId, subtask.id_string, result.id_string)
          let id = taskLayout.status_details.filter(one => one.name == subtask.status.name)
          if (id.length > 0) {
            updateTask(result.id_string, 'custom_status', id[0].id)
          }
          await handleRecursiveCopySubtask(subtask.id_string, result.id_string);
        }
      })
      .catch((err) => {
        console.log(err);
      })
  }

  const handleRecursiveCopySubtask = async (duplicateTaskObj, copyTaskId) => {
    try {
      let res = await TaskService.GetAllSubTaskOfTask(duplicateTaskObj.project.id_string, duplicateTaskObj.id_string);
      if (res) {
        for (let i = 0; i < res.length; i++) {
          await copySubtask(copyTaskId, res[i])
        }
      }
    }
    catch (err) {
      console.log(err);
    }
  }


  // const handleCopySubtask = async (taskId) => {
  //   let subtask = await TaskService.GetAllSubTaskOfTask(projectId, taskId);
  //   for (let i = 0; i < subtask.length; i++) {
  //     await handleRecursiveCopySubtask(subtask[i].id_string);
  //   }
  // }


  const getRecursiveTask = async (taskId) => {
    let res = await TaskService.GetAllSubTaskOfTask(projectId, taskId);
    if (res) {
      taskArr = [...taskArr, ...res];
      for (let i = 0; i < res.length; i++) {
        if (res[i].subtasks) {
          await getRecursiveTask(res[i].id_string);
        }
      }
    }
  }

  const getAllTask = async () => {
    let mainTasks = await TaskService.GetAllTaskOfProject(projectId);
    taskArr = [...taskArr, ...mainTasks];
    for (let i = 0; i < mainTasks.length; i++) {
      await getRecursiveTask(mainTasks[i].id_string);
    }
  }


  const getStyleForSubtask = (levels) => {
    if (levels == 1) {
      return { paddingLeft: ((levels * 9) * 1.5), minWidth: (levels * 2) + 200 }
    } else if (levels == 2) {
      return { paddingLeft: (((levels * 12) * 1.5) + (7.5 * 2)), minWidth: (levels * 10) + 200 }
    }
    else if (levels == 3) {
      return { paddingLeft: 87, minWidth: (levels * 10) + 200 }
    }
    else if (levels == 4) {
      return { paddingLeft: 124, minWidth: (levels * 10) + 200 }
    }
    else if (levels == 5) {
      return { paddingLeft: 162, minWidth: (levels * 10) + 200 }
    } else if (levels == 6) {
      return { paddingLeft: 199, minWidth: (levels * 10) + 200 }
    }
    else if (levels == 7) {
      return { paddingLeft: 238, minWidth: (levels * 10) + 200 }
    } else {
      return { paddingLeft: (((levels * 12) * 1.5) + (7.5 * 2)), minWidth: (levels * 10) + 200 }
    }

  }

  const getTaskDependency = (taskId) => {
    let list = [...dependencyList];
    list = list.filter((one) => (one.Current_Task_ID == taskId) && (one.Data_For.trim() == "Dependency"));
    return list
  }

  const isCheckDeleteCheck = (task) => {
    let isCheck = false;
    let all = [...allMyTasks];
    let filterTask = all.filter((one) => one.id_string == task.id_string);
    if (filterTask.length > 0) {
      if (filterTask[0].selected) {
        isCheck = true
      }
    }
    return isCheck
  }

  const getClassNameForConnectingLins = (levels, index) => {
    let str = "";
    if (levels == 2 && index == 0) {
      if (levels >= 2) {
        str = "task-first-child connecting-child-task cursor-pointer " + " connecting-task-" + levels;
      } else {
        str = "connecting-child-task cursor-pointer " + " connecting-task-" + levels;
      }

    } else {
      if (levels >= 2) {
        str = "connecting-child-task cursor-pointer " + " connecting-task-" + levels;
      } else {
        str = "cursor-pointer " + " connecting-task-" + levels;
      }
    }


    if ((levels - 1) == index) {
      str = str + " is-subtask-last"
    }
    return str;
  }

  const getTableRenderRow = (tasks, levels, groupValue, parent, index) => {
    var a = uuidv4();
    var b = uuidv4();
    a = a + b;
    var subtaskuniqueID = a.replace(/[0-9]/g, "");
    let taskEstHr = "00";
    let taskEstMin = "00";

    {
      return (
        <>

          {/* {getStatusOfGroupBy(groupBy, tasks, groupValue) ? */}{getStatusOfGroupBy(groupBy, tasks, groupValue) ?
            <tr key={index}>


              <td style={getStyleForSubtask(levels)} className={getClassNameForConnectingLins(levels, index)}>

                <div onClick={(e) => {
                  if (props.id == 'tasklist') {
                    props.onChangeRadio(tasks);
                  } else {
                    const all = [...allMyTasks];
                    let idx = all.findIndex(one => (one.id_string == tasks.id_string));
                    if (idx > -1) {
                      all[idx].selected = !all[idx].selected;
                      setAllMyTasks(all)
                    }
                  }

                }} style={isCheckDeleteCheck(tasks) ? { display: 'block' } : {}} className="radio-check-task ">

                
                </div>

                {

                  props.taskFilterSetting && props.taskFilterSetting.TaskLocation ? showTaskLocationNames(tasks) : ""


                }
                <div className="subtask-parent-name">{((props.taskFilterSetting && props.taskFilterSetting.SubtaskParntNames) || (taskFilterSetting.SubtaskParntNames)) ? showSubtaskParentNames(tasks) : ""}</div>
                <div className={props.taskLocationShow ? "cont-box" : "task-location-content"}>

                  {tasks.subtasks && groupBy == 'Pipeline' ? (
                    <span
                      id={subtaskuniqueID}
                      className="mainTaskArrow"
                      onClick={() => {
                        let alltasks = [...allTasks];
                        if (tasks.Subtask && tasks.Subtask.length > 0 && tasks.isOpen) {
                          tasks.Subtask = []
                        }

                        if (!tasks.isOpen) {
                          if (tasks.project) {
                            handleClickOpenSubtask(tasks.project_id, tasks.id_string, tasks.parent_task_id, parent)
                          } else {
                            let parent = alltasks.filter((one) => one.id_string == tasks.root_task_id);
                            if (parent.length > 0) {
                              parent = parent[0];
                              if (parent.project) {
                                handleClickOpenSubtask(parent.project_id, tasks.id_string, tasks.parent_task_id, parent)
                              }
                            }
                          }

                        }
                        tasks.isOpen = !tasks.isOpen
                        setTaskAfterUpdate(tasks.id_string, tasks)
                      }}
                    >
                      <FontAwesomeIcon
                        id={"collap" + tasks.id_string}
                        icon={
                          tasks.isOpen || (tasks.Subtask && tasks.Subtask.length > 0) ?
                            faCaretDown :
                            faCaretRight
                        }
                      
                      />
                    </span>
                  ) : (
                    <span
                      id={subtaskuniqueID}
                      className="mainTaskArrow"

                    >
                      {""}
                    </span>
                  )}


                  <DropdownToggle
                    className={tasks.custom_fields && (tasks.custom_fields.filter((one) => (one.label_name == 'IsMilestone') && one.value).length > 0) ? "task-status-icon-left task-icon-bg " : 'task-status-icon-left task-icon-bg'}
                    style={{
                      backgroundColor: (tasks.status ? tasks.status.color_code : 'gray'),
                    }}
                  >
                    {""}
                  </DropdownToggle>


                 
                  <UncontrolledTooltip target={"OpenUrlModal" + tasks.id_string}>
                    {tasks.name}
                  </UncontrolledTooltip>
                  <span
                    id={"OpenUrlModal" + tasks.id_string}
                    onClick={() =>{
                      if(props.onSelect){
                        props.onSelect(tasks)
                      }
                    }
                      // openUpdateModal(tasks, "task")
                    }
                    className="task-name-highlight text-truncate"
                    hidden={editNameTask && (tasks.id_string === editNameTask._id)}
                  >
                    {tasks.name}

                  </span>
                
                  


                  <span className="task-name-highlight cursor-pointer">
                    {/* <AntdPopover trigger={'click'} placement='top' content={getDependencyContent(tasks.DependentOn)}> */}
                    {(getTaskDependency(tasks.id_string).filter(one => one.Type == 'Waiting-On').length > 0) && (getTaskDependency(tasks.id_string).filter(one => one.Type == 'Blocking').length > 0) ?
                      <img
                        className="minus-circle-v"
                        src={minusCircle}
                      />



                      : getTaskDependency(tasks.id_string).length > 0 ?
                        <FontAwesomeIcon
                          icon={faMinusCircle}
                          color={
                            getTaskDependency(tasks.id_string).filter(one => one.Type == 'Blocking').length > 0
                              ? "#f40600"
                              : "#FFA500"
                          }
                        /> : <></>}
                  </span>


                  {getTaskChildGroupByStatus(tasks).map((one) => {
                    if (one.Tasks && (one.Tasks.length > 0)) {
                      return <>
                        <UncontrolledTooltip
                          placement="top"
                          target={"status-badge-tooltip" + one.id}
                        >
                          {one.name}
                        </UncontrolledTooltip>
                        <span id={"status-badge-tooltip" + one.id} style={{ backgroundColor: one.color }} className="badge ml-1">{one.Tasks ? one.Tasks.length : 0} </span>

                      </>
                    }
                  })}

                  <span className="dependency" >

                    {isDependencyBothExist(tasks).map((depe) => {
                      return <FontAwesomeIcon
                        onClick={() => {

                        }}
                        icon={faMinusCircle}
                        color={
                          depe == "Blocking"
                            ? "#f40600"
                            : "#FFA500"
                        }
                      />
                    })
                    }
                  </span>

                 
                </div>
                <span className="task-name-highlight cursor-pointer">
                  {false ?
                    <>
                      {/* <AntdPopover trigger={'click'} placement='top' content={getDependencyContent(tasks.DependentOn)}> */}
                      {isDependencyBothExist(tasks.DependentOn) ?
                        <img
                          className="minus-circle-v"
                          src={minusCircle}
                         
                        />



                        : <FontAwesomeIcon
                         
                          icon={faMinusCircle}
                          color={
                            tasks.DependentOn[0].type == "Blocking"
                              ? "#f40600"
                              : "#FFA500"
                          }
                        />}
                    </>
                    : <></>
                  }
                </span>
              </td>
              {/* {
                addColumns.map(col => {
                  if (col.isVisible) {
                    return getTaskTdAsperHeader(tasks, col.name)
                  }
                })} */}
              <td></td>
            </tr>
            : ''}
          {/* {HelperFnGetRecursive(tasks, index)} */}
        </>
      );
    }
  }



  const getRecursiveTable = () => {
    return allTasks ? (
      allTasks.map((tasks, index) => {
        var a = uuidv4();
        var b = uuidv4();
        a = a + b;
        var subtaskuniqueID = a.replace(/[0-9]/g, "");
        let taskEstHr = "00";
        let taskEstMin = "00";
        // if (tasks.TimeEstimateValue != "") {
        //   // let time=tasks.TimeEstimateValue.split(":");
        //   // taskEstHr=time[0];
        //   // taskEstMin=time[1];
        //   let obj = getTimeEstimateValue(
        //     tasks.TimeEstimateValue
        //   );
        //   taskEstHr = obj.hrs;
        //   taskEstMin = obj.mins;
        // }
        // var metauniqueID = c.replace(/[0-9]/g, "");
        // if (
        //   (groupBy == "Priority" || groupBy == "Status"
        //     ? tasks[groupBy].name
        //     : tasks[groupBy]) == groupValue
        // ) 
        {
          return (
            <>
              {/* {getStatusOfGroupBy(groupBy, tasks, groupValue) ? */}{true ?
                <tr key={index}>

                  <td style={{ paddingLeft: (index * 30), minWidth: (index * 30) + 200 }} className="cursor-pointer ">
                    {tasks.subtasks ? (
                      <span
                        id={subtaskuniqueID}
                        className="mainTaskArrow"
                        onClick={() => {
                          handleClickOpenSubtask(projectId, tasks.id_string, tasks.parent_task_id, tasks)
                        }}
                      >
                        <FontAwesomeIcon
                          id={"collap" + tasks.id_string}
                          icon={
                            TaskCollapseIcon[index] != undefined
                              ? TaskCollapseIcon[index]
                                .taskArrow
                              : faCaretRight
                          }
                          onClick={() => {
                            toggleTaskIcons(tasks.id_string);
                          }}
                        />
                      </span>
                    ) : (
                      <span
                        id={subtaskuniqueID}
                      // className="padl-25"
                      >
                        {""}
                      </span>
                    )}
                    <UncontrolledDropdown
                      className="task-status-icon"
                      style={tasks.subtasks ? {} : { marginLeft: '-3px' }}
                    // id={subtaskuniqueID}
                    >
                      <span
                        style={{
                          borderColor: tasks.status.color_code,
                        }}
                      ></span>
                      <DropdownToggle
                        className={tasks.isMilestone ? "task-icon-bg rotate-diamod" : 'task-icon-bg'}
                        style={{
                          backgroundColor: tasks.status.color_code,
                        }}
                      >
                        {""}
                      </DropdownToggle>
                      <DropdownMenu>
                        {statusValues.map((value, i) => {
                          return (
                            <DropdownItem
                              className="task-status-sq"
                              style={
                                {
                                  // backgroundColor: value.color,
                                  // color: "#fff",
                                }
                              }
                              onClick={async (e) => { }

                              }
                            >
                              <span
                                style={{
                                  backgroundColor: value.color,
                                }}
                              ></span>
                              {value.name}
                            </DropdownItem>
                          );
                        })}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    <span
                      id={"OpenUrlModal" + tasks.id_string}
                      onClick={() =>
                        openUpdateModal(tasks, "task")
                      }
                      className="task-name-highlight"
                      hidden={editNameTask && (tasks.id_string === editNameTask._id)}
                    >
                      {tasks.name}

                    </span>
                    <input
                      hidden={!(editNameTask && (tasks.id_string == editNameTask._id))}
                      // hidden={!editableInputOpen}
                      type="text"
                      name="TaskName"
                      className="Task-name-input"
                      value={editNameTask ? editNameTask.name : ''}
                      id={tasks.id_string}
                      onChange={(e) => handleTaskNameChange(e)}
                      onKeyUp={(e) => {
                        if (e.key == "Enter") {
                          enterTaskName()
                        }
                      }}
                    />
                    <PlusSquareOutlined
                      style={{ fontSize: 11 }}
                      onClick={() => {
                        setParentObject(tasks)
                        handleOpenTaskModel("subtask")
                      }
                      }
                      id={"create-subtask-tip" + tasks.id_string}
                      className="ms-2 openTask-popup"
                    />
                    <UncontrolledTooltip
                      placement="top"
                      target={"create-subtask-tip" + tasks.id_string}

                    >
                      Create Subtask
                    </UncontrolledTooltip>

                    <span className="ms-2 testing-tesk-pen">
                      <FontAwesomeIcon
                        className="ms-2 openTask-popup"
                        style={{ fontSize: 10 }}
                        icon={faPen}
                        id={"rename-tooltip" + tasks.id_string}
                        onClick={() => editableField(tasks)}
                      />
                      <UncontrolledTooltip
                        placement="top"
                        target={"rename-tooltip" + tasks.id_string}

                      >
                        Rename
                      </UncontrolledTooltip>
                    </span>

                    <span className="task-name-highlight cursor-pointer">
                      {false ?
                        <>
                          {/* <AntdPopover trigger={'click'} placement='top' content={getDependencyContent(tasks.DependentOn)}> */}
                          {isDependencyBothExist(tasks.DependentOn) ?
                            <img
                              className="minus-circle-v"
                              src={minusCircle}
                              onClick={() => {
                                setShowDependencyList(tasks.DependentOn)
                                setShowDependencyPopUp(true)
                              }}
                            />



                            : <FontAwesomeIcon
                              onClick={() => {
                                setShowDependencyList(tasks.DependentOn)
                                setShowDependencyPopUp(true)
                              }}
                              icon={faMinusCircle}
                              color={
                                tasks.DependentOn[0].type == "Blocking"
                                  ? "#f40600"
                                  : "#FFA500"
                              }
                            />}
                        </>
                        : <></>
                      }
                    </span>
                  </td>
                  {/* {
                    addColumns.map(col => isVisibleTableColumn(col.name) ?
                      getTaskTdAsperHeader(tasks, col.name)
                      : '')} */}
                  <td></td>
                </tr>
                : ''}
              {HelperFnGetRecursive(tasks, index)}
            </>
          );
        }
      })
    ) : (
      <></>
    )
  }


  const getRowMaps = (one, groupValue, index) => {
    let returnArr = [];
    if (one.subtasks && one.Subtask && one.Subtask.length > 0) {
      returnArr.push(getTableRenderRow(one, 1, groupValue, one, index))
      one.Subtask.forEach((two, idx2) => {
        if (two.subtasks && two.Subtask && two.Subtask.length > 0) {
          returnArr.push(getTableRenderRow(two, 2, groupValue, one, idx2))
          two.Subtask.forEach((three, idx3) => {
            if (three.subtasks && three.Subtask && three.Subtask.length > 0) {
              returnArr.push(getTableRenderRow(three, 3, groupValue, one, idx3))
              three.Subtask.forEach((fourth, idx4) => {
                if (fourth.subtasks && fourth.Subtask && fourth.Subtask.length > 0) {
                  returnArr.push(getTableRenderRow(fourth, 4, groupValue, one, idx4))
                  fourth.Subtask.forEach((fifth, idx5) => {
                    if (fifth.subtasks && fifth.Subtask && fifth.Subtask.length > 0) {
                      returnArr.push(getTableRenderRow(fifth, 5, groupValue, one, idx5))
                      fifth.Subtask.forEach((sixth, idx6) => {
                        if (sixth.subtasks && sixth.Subtask && sixth.Subtask.length > 0) {
                          returnArr.push(getTableRenderRow(sixth, 6, groupValue, one, idx6))
                          sixth.Subtask.forEach((seventh, idx7) => {
                            if (seventh.subtasks && seventh.Subtask && seventh.Subtask.length > 0) {
                              /// returnArr.push(sixth)
                              <>more</>
                            } else {
                              returnArr.push(getTableRenderRow(seventh, 7, groupValue, one, idx7))
                            }
                          })
                        } else {
                          returnArr.push(getTableRenderRow(sixth, 6, groupValue, one, idx6))
                        }
                      })
                    } else {
                      returnArr.push(getTableRenderRow(fifth, 5, groupValue, one, idx5))
                    }
                  })
                } else {
                  returnArr.push(getTableRenderRow(fourth, 4, groupValue, one, idx4))
                }
              })
            } else {
              returnArr.push(getTableRenderRow(three, 3, groupValue, one, idx3))
            }
          })
        } else {
          returnArr.push(getTableRenderRow(two, 2, groupValue, one, idx2))
        }
      })
    } else {
      returnArr.push(getTableRenderRow(one, 1, groupValue, one, index))
    }
    return returnArr;

  }


  const getTaskLengthSubchild = (tasks) => {
    // let allParentTask = [...allTasks]
    let tasks1 = [...allTasks];
    tasks1 = tasks1.filter((one) => one.id_string == tasks.root_task_id)
    taskSubChildArray = []
    if (tasks && tasks.project) {
      handleFindChildTask(tasks, tasks.project_id, tasks.id_string, tasks.parent_task_id, tasks)
    } else {
      if (tasks1.length > 0) {
        tasks1 = tasks1[0]
        if (tasks1 && tasks1.project) {
          handleFindChildTask(tasks, tasks1.project_id, tasks.id_string, tasks.parent_task_id, tasks)
        }
      }
    }
    // allParentTask = allParentTask.filter((one) => {
    //   if (one.id_string == taskId) {
    //     return one;
    //   }
    // })
    // allParentTask.forEach((parent) => {


    // })
    return taskSubChildArray;
  }

  const getTaskChildGroupByStatus = (tasks) => {
    tasks = getTaskLengthSubchild(tasks);
    let status = [...taskLayout.status_details];
    status.forEach((one) => {
      one.Tasks = tasks.filter(a => a.status && (a.status.name == one.name))
    })
    return status;
  }

  const expandAllTasks = (groupValue) => {
    let allParentTask = [...allTasks]
    let tasks = [...allTasks];
    allParentTask = allParentTask.filter((one) => {
      if (one.custom_fields && one.custom_fields.filter(field => field.label_name == 'Pipeline').length > 0) {
        let obj = one.custom_fields.filter(field => field.label_name == 'Pipeline')[0]
        if (obj.value == groupValue) {
          return one
        }
      } else {
        if (groupValue == 'NO PIPELINE') {
          return one
        }
      }
    })
    allParentTask.forEach((parent) => {
      if (props.id == 'task') {
        handleCollapseAndExapndTask(tasks, parent.project_id, parent.id_string, parent.parent_task_id, parent)

      } else {
        handleCollapseAndExapndTask(tasks, parent.project.id_string, parent.id_string, parent.parent_task_id, parent)

      }
    })
  }

  const collapseAllTask = (groupValue) => {
    let allParentTask = [...allTasks]
    let tasks = [...allTasks];
    allParentTask = allParentTask.filter((one) => {
      if (one.custom_fields && one.custom_fields.filter(field => field.label_name == 'Pipeline').length > 0) {
        let obj = one.custom_fields.filter(field => field.label_name == 'Pipeline')[0]
        if (obj.value == groupValue) {
          return one
        }
      } else {
        if (groupValue == 'NO PIPELINE') {
          return one
        }
      }
    })
    tasks.forEach((one) => {
      allParentTask.forEach((parent) => {
        if (parent.id_string == one.id_string) {
          one.Subtask = [];
          one.isOpen = false;
        }
      })
    })

    setAllTaskCache(tasks)
    setAllTasks(tasks)
  }

  const [dueDateAction, setDueDateAction] = useState(false);
  const [assigneeAction, setAssigneAction] = useState(false);

  const updateMultipleTask = (tasks, key, data) => {
    let doArr = [];
    if (tasks) {
      tasks.forEach((one) => {
        doArr.push(updateTask(one, key, data))
      })
      Promise.all(doArr)
        .then((result) => {
         
        })
        .catch((err) => {
          console.log(err);
        })
    }

  }

  const getSelectedTaskAction = () => {
    return <>
      <hr className="hrcls"></hr>
      <div className="row task-filter-hovr">

        <div className="col-sm-9">
          <a onClick={() => {

            setTaskDeleteWarningToggle(true)
          }}
          >Delete
          </a>
        </div>
      </div>
      <hr className="hrcls"></hr>
      <div className="row task-filter-hovr">

        <StatusDropdown toggelButton={<>
          <div className="col-sm-9">
            <a onClick={() => {
            }}
            >Set Status
            </a>
          </div>

        </>} items={taskLayout.status_details} onSelect={(value) => {
          let all = [...allTasks];
          all = all?.filter((one) => one.selected);
          updateMultipleTask(all, "custom_status", value.id)
        }} />

      </div>

      <hr className="hrcls"></hr>
      <div className="row task-filter-hovr">

        <UncontrolledDropdown >
          <div className="col-sm-9 three-dots-priority">
            <a onClick={() => {
            }}
            ><DropdownToggle>
                Set Priority
              </DropdownToggle>
            </a>
          </div>
          <DropdownMenu style={props.id == 'tasklist' ? { maxHeight: '100px', overflowY: 'auto' } : {}}>
            {priorityValues.map((value, i) => {
              return (
                <DropdownItem
                  id={'priority-click' + value.id + value.name}
                  onClick={(e) => {
                    let all = [...allTasks];
                    all = all.filter((one) => one.selected);
                    updateMultipleTask(all, "priority", value.name)
                  }

                    // updateTask(data, 'priority', value.name)
                  }
                >
                  <FontAwesomeIcon
                    style={{
                      color: value.color,
                      marginRight: "5px",
                    }}
                    icon={solidFlag}
                  />{" "}
                  {value.name}
                </DropdownItem>
              );
            })}
            <DropdownItem
              onClick={(e) => {
                // updateTask(data.id_string, 'priority', 'None')
              }}
            >
              <FontAwesomeIcon
                icon={faTimes}
                style={{
                  color: "red",
                  marginRight: "10px",
                }}
              />
              CLEAR
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>



      </div>


      <hr className="hrcls"></hr>
      <div className="row task-filter-hovr ">

        <div className="col-sm-9 datepicker-action">
          <a onClick={() => setDueDateAction(!dueDateAction)}>Set Due Date
          </a>
          <DatePicker
            onChange={(evnt) => {
              let all = [...allTasks];
              all = all.filter((one) => one.selected);
              let formattedDate = dayjs(new Date(evnt)).format("MM-DD-YYYY");
              updateMultipleTask(all, "end_date", formattedDate);
            }
            }
            open={dueDateAction}
            popperPlacement="bottom"
            popperModifiers={{
              flip: {
                behavior: ['bottom'] // don't allow it to flip to be above
              },
              preventOverflow: {
                enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
              },
              hide: {
                enabled: false // turn off since needs preventOverflow to be enabled
              }
            }}
          />
        </div>
      </div>
      {/* <hr className="hrcls"></hr>
      <div className="row task-filter-hovr">

        <CustomDropdown isUser={true} toggelButton={<>
          <div className="col-sm-9">
            <a onClick={() => {
            }}
            >Set Assignee
            </a>
          </div>

        </>} items={allUsers} onSelect={(value) => {
          //  let all = [...allTasks];
          //  all = all.filter((one) => one.selected);
          //  let formattedDate = dayjs(new Date(evnt)).format("MM-DD-YYYY");
          //  updateMultipleTask(all, "end_date", formattedDate);
          // updateTask(data, "custom_status", value.id)
          // updateTask(data, 'custom_fields', JSON.stringify({ UDF_CHAR6: value.CategoryName }))
        }} />
      </div> */}

      <hr className="hrcls"></hr>
      <div className="row task-filter-hovr">
        <CustomDropdown toggelButton={<>
          <div className="col-sm-9">
            <a onClick={() => {
            }}
            >Set Category
            </a>
          </div>

        </>} items={getCategoryNames()} onSelect={(value) => {
          let all = [...allTasks];
          all = all.filter((one) => one.selected);
          updateMultipleTask(all, "custom_fields", JSON.stringify({ UDF_CHAR6: value.name }));
          // updateTask(data, "custom_status", value.id)
          // updateTask(data, 'custom_fields', JSON.stringify({ UDF_CHAR6: value.CategoryName }))
        }} />
      </div>
      {/* <hr className="hrcls"></hr>
      <div className="row task-filter-hovr">

        <CustomDropdown toggelButton={<>
          <div className="col-sm-9">
            <a onClick={() => {
            }}
            >Set Milestone
            </a>
          </div>

        </>} items={tasklist} onSelect={(value) => {
           let all = [...allTasks];
           all = all.filter((one) => one.selected);
           updateMultipleTask(all, "tasklist_id",value.id );
        }} />
      </div> */}
      {/* <hr className="hrcls"></hr>
      <div className="row task-filter-hovr">

        <div id="task_action_time_estimate" className="col-sm-9">
          <a>Set Time Estimate
          </a>
          <UncontrolledPopover
            className="time_est_popover"
            trigger="legacy"
            placement="bottom"
            target="task_action_time_estimate"
          >
            <PopoverBody className="m-2">
              <b className="taskstatus-heading-right">
                Time Estimate :
              </b>
              <div className="time_est_fields">
                <div className="time_fields">
                  <Input
                    type="text"
                    placeholder="00"
                    value={timeEstimateValue.length == 1 ? '0' + timeEstimateValue : timeEstimateValue}
                    onChange={(e) => {
                      if (e.target.value.length >= 2) {
                        let str1 = e.target.value.replace(/^0+/, '');
                        setTimeEstimateValue(str1);
                      }
                      else {
                        setTimeEstimateValue(e.target.value)
                      }

                    }}
                    onKeyDown={(e) => {

                    }

                    }
                  />
                  <span className="time_unit">
                    hrs
                  </span>
                </div>
                <div className="time_fields ml-2">
                  <Input
                    type="text"
                    placeholder="00"
                    value={timeMinuteValue.length == 1 ? '0' + timeMinuteValue : timeMinuteValue}
                    onChange={(e) => {

                    }
                    }
                    onKeyDown={(e) => { }
                    }
                  />
                  <span className="time_unit">
                    min
                  </span>
                </div>
              </div>
              <div className="text-right mt-2">
              </div>
            </PopoverBody>
          </UncontrolledPopover>
        </div>
      </div> */}

    </>
  }

  const isAllSelected = (groupByValue) => {
    let all = [...allMyTasks];
    let selectedTask = [];
    all.forEach((one) => {
      let status = getStatusOfGroupBy(groupBy, one, groupByValue);
      if (status) {
        selectedTask.push(one);
      }
    })
    if (selectedTask.filter(one => one.selected).length == selectedTask.length) {
      return true;
    } else {
      return false;
    }
  }



  return (
    <div className={props.id == 'tasklist' ? "task-cards h-auto" : 'task-cards ml-0'}>
    {groupByField ? (
      groupByField.map((groupValue, i) => {
        if (!groupValue) {
          return ''
        }
        return (
          <>
            {/* <div className="task-table-custom-main"> */}
            <div style={addColumns.filter(one => one.isVisible).length == 0 ? { marginTop: '20px' } : {}} className="fixed-contentleft-head">
              <div style={groupBy == 'Status' ? getStatusStyleGroupBy(groupValue) : addColumns.filter(one => one.isVisible).length == 0 ? { top: '-17px' } : {}} className="task-card-title">
                <span id={"toggle-group" + groupValue.replace(/ /g, "") + props.dealObj.id} className="task-toggle-down">
                  <FontAwesomeIcon

                    icon={
                      getGroupIcon(groupValue.replace(/ /g, "") + props.dealObj.id)
                        ? faChevronDown
                        : faChevronRight
                    }
                    onClick={() => {
                      toggleGroupIcon(groupValue.replace(/ /g, "") + props.dealObj.id)
                    }
                    }
                  />
                </span>
                {groupBy == 'Assignee' ?
                  <div className="groupByAssignees">
                    {getAssigneeImgeForTaskCardTitle(groupValue)}
                  </div>

                  : groupBy == 'Priority' ? getPriorityIconForGroupBy(groupValue) : <span style={{ fontSize: '14px' }} className="mx-2">{getGroupVal(groupValue)}</span>}
                {/* <FontAwesomeIcon
                  className="task-pen-icon ml-3"
                  icon={faPen}
                /> */}
               {props.taskFor!=="Search"? <AntdPopover
                  placement="right"
                  trigger="click"
                  // isOpen={employeeNotePopup}
                  content={<div>

                    <div className="row task-filter-hovr">
                      <div className="col-sm-9">
                        <a onClick={() => {
                        }}
                        >Expand All Tasks
                        </a>
                      </div>
                      <div className="col-sm-3">
                        <Switch
                          // checked={taskFilterSetting.ExpandTasks||(groupValue==expandTaskGroup)}
                          onChange={(checked, event) => {

                            if (checked) {
                              setExpandTaskGroup(groupValue)
                              expandAllTasks(groupValue);
                            } else {
                              setExpandTaskGroup("")
                              collapseAllTask(groupValue);
                            }

                          }}
                          size="small"

                        />
                      </div>
                    </div>
                    <hr className="hrcls"></hr>
                    <div className="row task-filter-hovr">
                      <div className="col-sm-9">
                        <a onClick={() => {
                        }}
                        >Select All Tasks
                        </a>
                      </div>
                      <div className="col-sm-3">
                        <Switch
                          checked={isAllSelected(groupValue)}
                          onChange={(checked, event) => {
                            const all = [...allMyTasks];
                            all.forEach((task) => {
                              let flag = getStatusOfGroupBy(groupBy, task, groupValue);
                              if (flag) {
                                task.selected = checked;
                              }
                            })
                            if (props.id == 'tasklist') {
                              props.setAllTasks(all);
                              setAllMyTasks(all);
                            } else {
                              setAllMyTasks(all);
                            }



                          }}
                          size="small"

                        />
                      </div>
                    </div>

                   

                  </div>
                  }
                >
                  <FontAwesomeIcon
                    id="tasksetting_popup"
                    className="task-settings-icon rotate-ellipses"
                    icon={faEllipsisH}
                  />
                </AntdPopover>:<></>}
              </div>
            </div>

            <div className="table-responsive ">

              {/* <table className={props.taskLocationShow || props.showParentNames ? "table task-table task-content-table1 table-hover" : "table task-table task-content-table table-hover"} ></table> */}
              <UncontrolledCollapse
                toggler={
                  "toggle-group" + groupValue.replace(/ /g, "") + props.dealObj.id
                }

                isOpen={getGroupIcon(groupValue.replace(/ /g, "") + props.dealObj.id)}
              >
                <table className={props.taskLocationShow || props.showParentNames ? "table task-table task-content-table1 table-hover" : "table task-table task-content-table table-hover"} >
                  <thead>
                    <th
                      className="min-width-250 task_table_header"

                    >
                    </th>
                    {addColumns.map((column, index) => {
                      if (column.isVisible) {
                        return <th
                          id={defaultColumns[index]}
                          key={defaultColumns[index]}
                          draggable
                          onDragStart={handleDragStart}
                          onDragOver={handleDragOver}
                          onDrop={handleOnDrop}
                          onDragEnter={handleDragEnter}
                          dragOver={defaultColumns[index] === dragOver}
                        >
                          <FontAwesomeIcon
                            icon={faGripVertical}
                            className="drag-table-icon"
                          />
                          {column.name}
                        </th>

                      } else {
                        return ''
                      }
                    })}
                    {/* <th className="add-column">

                      <span

                      // onClick={()=>setOpenAddColumnPopUpDealId(projectId)}
                        // className="float-r paddding-0 font-weight-bold cursor-point"
                        id={"firstplus-popup"+groupValue.replace(/ /g, "") + props.dealObj.id}
                      >
                        <FontAwesomeIcon style={{fontSize:'14px'}} icon={faPlusCircle} />

                      </span>
                    </th> */}
                  </thead>
                  <tbody>
                    {allTasks.map((one, index) => {
                      if (groupBy == 'Pipeline') {
                        return getRowMaps(one, groupValue, index)
                      } else {
                        return getTableRenderRow(one, 1, groupValue, one, index)
                      }

                    })}
                    {/* {getRecursiveTable()} */}

                    {/* {getTaskTreeTable()} */}

                  </tbody>
                </table>
              </UncontrolledCollapse>
              {/* </Collapse> */}

              <hr className="task-table-margin" />
            </div>
            {/* </div> */}
          </>
        );
      })
    ) : (
      <></>
    )}

   
  </div>

  );
};

export default TaskList;
