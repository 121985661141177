import React, { useState, useEffect, useRef, useContext } from "react";
import { Nav, Tabs, Tab, Form } from "react-bootstrap";
import {SelectedIdsForTimeClock } from '../../../Context/AppActions';
import AppContext from "../../../Context/AppContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faProjectDiagram } from "@fortawesome/free-solid-svg-icons";
import { Col } from "reactstrap";
const tabs={
  HR: [
    {
      id: "timeSheet",
      name: "Timesheet",
    },
    {
      id: "request",
      name: "Request",
    },
    {
      id: "where",
      name: "Where",
    },
    {
      id: "whoIsIn",
      name: "Who Is In",
      userCheck : true
    },
    {
      id: "shiftMapping",
      name: "Shift Mapping",
    },
  ],
  TASK:[
    {
      id: "tasks",
      name: "Task",
    },
  ],
  PIPELINE:[
    {
      id: "pipelines",
      name: "Pipelines",
    },
  ],
  TaskBlueprint:[
    {
      id: "taskblueprint",
      name: "Blueprint",
      render:()=>{
        return <><FontAwesomeIcon className='logo' icon={faProjectDiagram} /><span style={{fontSize:"1rem",fontWeight:"600", marginLeft:"10px"}}>Task Flows</span></>
      }
    },
  ]
}

function HRTabs(props) {
  const [allTabsName, setAllTabsName] = useState([]);
  const [key, setKey] = useState("timeSheet");
  const userInfo = JSON.parse(localStorage.getItem('userinfo'));
  const AppData = useContext(AppContext);
  const { state, dispatch } = AppData || {};
  useEffect(e=>{
    if(props.module){
      let arr=tabs[props.module];
      setAllTabsName(tabs[props.module]);
      if(arr[0]){
        setKey(arr[0].id);
        props.setSelectedTable(arr[0].id);
      }
    }
  },[props.module])

  useEffect(e=>{
    if(props.loadDefaultTable){ 
        props.setSelectedTable(props.loadDefaultTable); 
    }
  },[props.loadDefaultTable])

  return (
    <div>
      <Tabs
        className={"hrAllTabs text-colr"}
        id="controlled-tab-example"
        activeKey={props.selectedTable}
        onSelect={(k) => {
          setKey(k);
          // props.setRowData([]);
          // props.setActionId("");
          props.setSelectedTable(k);
          dispatch(SelectedIdsForTimeClock([]))
        }}
      >
        {allTabsName.map((e) => {
          if(e.render){
            return <Tab eventKey={e.id} title={e.render()}></Tab>;
          }
          if(e.userCheck && userInfo && userInfo.role_details.role_name != "App User"){
            return <Tab eventKey={e.id} title={e.name}></Tab>;
          }else if(!e.userCheck){
            return <Tab eventKey={e.id} title={e.name}></Tab>;
          }
        })}
      </Tabs>
    </div>
  );
}

export default HRTabs;
