import { Popover as AntdPopover } from 'antd';
import TaskSearchComponent from './TaskSearchComponent';
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import './TaskGlobalSearch.css';

const TaskGlobalSearch = ({ nodeElement, onSelect}) => {
    const [searchtext, setSearchText] = useState('');
    const [searchBy, setSearchBy] = useState("Client");
    const handleOnChange = (e) => { 
        setSearchText(e.currentTarget.value);
    }

    const searchContent = () => {
        return <div style={{ height: '380px',width:'600px', overflowY: 'auto' }} className='task_global_search'>

            <div className="col-12 p-0 " style={{display:'flex',borderBottom:'1px solid #eaeaea', marginLeft: "10px"}}>
                <FontAwesomeIcon className='global-search-icon' icon={faSearch}/>
                <input style={{border:'0px'}} onChange={handleOnChange} placeholder='Search for Account Name, Deal Name, or Task Name' type="text" className="form-control form-control-sm" aria-label="Small" aria-describedby="inputGroup-sizing-sm" />
            </div>

            <div className='row'>
                <TaskSearchComponent onSelect={onSelect} searchText={searchtext} searchBy={searchBy} />
            </div>
        </div>
    }
    return (

        <AntdPopover   placement='top' trigger={"click"} content={searchContent}>
            {nodeElement}
        </AntdPopover>
    );
}

export default TaskGlobalSearch;