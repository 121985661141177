import React, { useEffect, useState } from "react";
import { Select as AntSelect, Button, Input, Popover, Select, Tooltip } from "antd";
import "./DependencyModal.css";
import { faEye, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row } from "reactstrap";
import { CaretRightOutlined } from "@ant-design/icons";
import _ from "lodash";
import assigntask from "../../../assets/images/svg/assigntask.svg";
import { invertColor } from "../../../components/HR/TimeClock/Util";

const {Search} = Input;

export default function DependencyModal({
  userOptions,
  handleSaveDependencies,
  dropdownTasksDataCache,
  setDropdownCount,
  openTaskFromDependencies,
  getOptionsForDropdown,
  selectedDependenciesCache,
  handleDeleteDependency,
  allTasksAndSubtasks,
  selectedDependencies,
  dropdowncount,
  filteredDropdownTasksData,
  openTaskData
}) {
  const [collapsed, setCollapsed] = useState({
    WaitingOn: true,
    Blocking: true,
  });
  const [waitingOndata, setWaitingOndata] = useState({
    WaitingOn: [],
    Blocking: [],
  });
  
  const [dropdownOpen, setDropdownOpen] = useState({Blocking : false, Waiting : false});
  const [openPopoverId, setOpenPopoverId] = useState(null);
  const [innerPopoverOpen, setInnerPopoverOpen] = useState(false); // for nested popover control
  const [dropDownData, setDropDownData] = useState([...dropdownTasksDataCache]);
  const [searchText, setSearchText] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [currentTask, setCurrentTask] = useState(openTaskData || {});
  let currentObject = currentTask?.TaskFlowObject?.Nodes?.find(e=>e.id==currentTask.TaskStatus);
  const [sourceTaskSelect, setSourceTaskSelect] = useState(currentObject); // Default label

  useEffect(e=>{
    if(openTaskData){
      setCurrentTask((prev) => ({ ...prev, ...openTaskData }))
    }
  }, [openTaskData])
  
  const toggleCollapse = (section) => {
    setCollapsed((prev) => ({ ...prev, [section]: !prev[section] }));
    setDropdownCount({ ...dropdowncount, [section]: 0 });
  };

  useEffect(() => {
    let depsObject = { WaitingOn: [], Blocking: [] };
    selectedDependencies.WaitingOn.map((e, index) => {
      let type = e.value.split("_");
      let taskData = allTasksAndSubtasks[type[0]].get(Number(type[1]));
      depsObject.WaitingOn.push({
        taskName: taskData.TaskName,
        assignee: taskData.Assignee,
        priority: taskData.Priority,
        status: taskData?.TaskFlowObject?.Nodes?.find(k=>k.id==taskData.TaskStatus)?.data?.label,
        TaskStatus: taskData.TaskStatus,
        TargetDependencyStatus: e.TargetDependencyStatus,
        SourceDependencyStatus: e.SourceDependencyStatus,
        data: taskData,
        obj: e,
      });
    });
    selectedDependencies.Blocking.map((e, index) => {
      let type = e.value.split("_");
      let taskData = allTasksAndSubtasks[type[0]].get(Number(type[1]));
      depsObject.Blocking.push({
        taskName: taskData.TaskName,
        assignee: taskData.Assignee,
        priority: taskData.Priority,
        status: taskData?.TaskFlowObject?.Nodes?.find(k=>k.id==taskData.TaskStatus)?.data?.label,
        TaskStatus: taskData.TaskStatus,
        TargetDependencyStatus: e.TargetDependencyStatus,
        SourceDependencyStatus: e.SourceDependencyStatus,
        obj: e,
        data: taskData,
      });
    });
    setWaitingOndata(depsObject);
  }, [selectedDependencies]);
  console.log("currentTask", currentTask)
  const getCurrentDependencyStatusOptions = () => {
    let renderOptions = [];
    if(currentTask.TaskFlowObject){
      renderOptions = currentTask.TaskFlowObject.Nodes.filter(e=> !e?.data?.visited)
    }
    const content = (
      <div className="Task-Status-Div">
          {renderOptions && renderOptions.map((one, idx) => (
              <p
                  key={idx}
                  className="Task-Status-P"
                  style={{
                    background: one.data.color === "grey" || !one.data.color ? "#808080" : one.data.color,
                    color: invertColor(one.data.color === "grey" || !one.data.color ? "#808080" : one.data.color, true)
                    }}
                  onClick={() => {
                    setSourceTaskSelect(one);
                  }}
              >
                  {one.data.label}
              </p>
          ))}
      </div>
  );
    return (
      <Popover
        style={{ zIndex: 9999 }}
        onOpenChange={(e) => {
        }}
        trigger="click"
        placement="bottomLeft"
        content={content}
      >
        <Button
          size="small"
          style={{
            maxWidth: "150px", // Set max width for ellipsis to take effect
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            background: sourceTaskSelect.data.color === "grey" || !sourceTaskSelect.data.color ? "#808080" : sourceTaskSelect.data.color,
            color: invertColor(sourceTaskSelect.data.color === "grey" || !sourceTaskSelect.data.color ? "#808080" : sourceTaskSelect.data.color, true)
          }}
          className="Elipsis-Class Status-Change-Dependency"
        >
          {sourceTaskSelect.data.label}
        </Button>
      </Popover>
    );
  };

  const getDependencyStatusOptions = (option, type, index) => {
    const renderOptions = option.taskData?.TaskFlowObject?.Nodes.filter(e => !e?.data?.visited) || [];

    const content = (
      <div className="Task-Status-Div">
          {renderOptions.map((one, idx) => (
              <p
                  key={idx}
                  className="Task-Status-P"
                  style={{
                    background: one.data.color || "#808080",
                    color: invertColor(one.data.color || "#808080", true),
                  }}
                  onClick={() => {
                      const updatedDropDownData = dropDownData.map(item => 
                          item.value === option.value
                              ? {
                                  ...item,
                                  targetTaskSelect: {
                                      label: one.data.label,
                                      color: one.data.color,
                                      id:one.id
                                  }
                              }
                              : item
                      );
                      setDropDownData(updatedDropDownData);
                  }}
              >
                  {one.data.label}
              </p>
          ))}
      </div>
    );

    // Retrieve the specific target selection for the current option
    const currentTargetSelect = dropDownData.find(item => item.value === option.value)?.targetTaskSelect;

    return (
      <Popover
        style={{ zIndex: 9999 }}
        trigger="click"
        placement="bottomLeft"
        content={content}
      >
        <Button
          type="primary"
          size="small"
          style={{
            maxWidth: "150px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            background: currentTargetSelect?.color || "#808080",
            color: invertColor(currentTargetSelect?.color || "#808080", true),
          }}
          className="Elipsis-Class Status-Change-Dependency"
        >
          {currentTargetSelect?.label || "Select Status"}
        </Button>
      </Popover>
    );
};

  useEffect(() => {
    const filteredOptions = getOptionsForDropdown(filteredDropdownTasksData, selectedDependencies)
        .filter(option =>
            option.label.toLowerCase().includes(searchText.toLowerCase())
        );

    setFilteredOptions(filteredOptions);

    const targetSelectOptions = filteredOptions
        .filter(option => option.taskData?.TaskFlowObject?.Nodes.some(e => e.data.state === "Close"))
        .map(option => {
            const closeNode = option.taskData.TaskFlowObject.Nodes.find(e => e.data.state === "Close");
            return {
                ...option,
                label: closeNode ? closeNode.data.label : option.label,
                color: closeNode ? closeNode.data.color : option.color,
            };
        });

    // Initialize target selection for each option in dropDownData
    const updatedDropDownData = dropDownData.map(item => {
        const matchingOption = targetSelectOptions.find(opt => opt.value === item.value);
        return {
            ...item,
            targetTaskSelect: matchingOption || item.targetTaskSelect // Set default or existing targetTaskSelect
        };
    });
    setDropDownData(updatedDropDownData);
}, [filteredDropdownTasksData, selectedDependencies, searchText]);


  useEffect(e=>{
    if(!innerPopoverOpen){
      setSourceTaskSelect(currentObject)
    }
  },[innerPopoverOpen]);

  const handleOptionSelect = (option, type) => {
    let data = [...dropDownData];
    let record = data?.find((t) => t.value === option.value);
    record["SourceDependencyStatus"] = sourceTaskSelect.id
    record["TargetDependencyStatus"] = record.targetTaskSelect.id
    
    handleSaveDependencies(record, type);

    setInnerPopoverOpen(false);
    setOpenPopoverId(null)
    setTimeout(() => setDropdownOpen({Blocking : false, Waiting : false}), 300); 
    setSourceTaskSelect(currentObject);
  };
  const getMapDependencyPopover = (option, type, index) => {
    const content = (
      <div>
          <span style={{ display: "flex", gap: "50px", margin: "10px 0px" }}>
              Source Task Status: {getCurrentDependencyStatusOptions()}
          </span>
          <span style={{ display: "flex", gap: "50px", margin: "10px 0px" }}>
              Target Task Status: {getDependencyStatusOptions(option, type, index)}
          </span>
          <p style={{display: "flex", justifyContent: "end", marginRight: "15px"}}>
            <Button 
              onClick={()=>{handleOptionSelect(option, type)}}
            > 
              Save {type} Dependency
            </Button>
          </p>
      </div>
    );

    return (
      <Popover
        content={content}
        trigger="click"
        open={innerPopoverOpen && openPopoverId?.value === option.value}
        onOpenChange={(visible) => {
          setInnerPopoverOpen(visible);
          setOpenPopoverId(visible ? option : null);
        }}
        placement="top"
        overlayClassName="Mapping-Dependency-popover"
      >
        <Button
          className="Add-Lookup-Icon"
          color="link"
          onClick={(e) => {
            e.stopPropagation();
            setInnerPopoverOpen(true);
            setOpenPopoverId(option);
          }}
        >
          <FontAwesomeIcon icon={faPlus} style={{ marginRight: "7px" }} />
        </Button>
      </Popover>
    );
};

  const getWaitingOnPopover = (type) => {
    const content = (
      <div className="dependncy-list-dropdowns">
        <Search
          size="small"
          placeholder="Search"
          value={searchText}
          onChange={(e) => {
            e.target.value = e.target.value.trimStart();
            setSearchText(e.target.value);
          }}
          style={{ marginBottom: ".8em", width: '99%' }}
          className="dependency-search-input"
        />
  
        {/* Header Row for Columns */}
        <Row className="custom-option-header" style={{ padding: "4px 8px", fontWeight: "bold", backgroundColor: "#f0f0f0", borderBottom: "1px solid #ccc" }}>
          <Col sm={6}>Task Name</Col>
          <Col sm={3} style={{ display: "flex", justifyContent: "center" }}>Status</Col>
          <Col sm={3} style={{ display: "flex", justifyContent: "center" }}>Actions</Col>
        </Row>
  
        <div style={{ maxHeight: "200px", overflowY: "auto", width: "100%" }}>
          {filteredOptions.map((option, index) => (
            <Row
              key={option.value}
              className="custom-option"
              style={{
                cursor: "pointer",
              }}
            >
              {/* Task Name Column */}
              <Col sm={6}>
                <Tooltip title={option.label}>
                  <span className="option-task-name Elipsis-Class">{option.label}</span>
                </Tooltip>
              </Col>
  
              {/* Status Column */}
              <Col sm={3} style={{ display: "flex", justifyContent: "center" }}>
                {option?.status && <Tooltip title={option.status}>
                  <span
                    className="option-status Elipsis-Class"
                    style={{
                      background: option.statusColor === "grey" || !option.statusColor ? "#808080" : option.statusColor,
                      color: invertColor(option.statusColor === "grey" || !option.statusColor ? "#808080" : option.statusColor, true),
                      padding: "2px 6px",
                      borderRadius: "3px",
                    }}
                  >
                    {option.status}
                  </span>
                </Tooltip>}
              </Col>
  
              {/* Actions Column */}
              <Col sm={3} style={{ display: "flex", justifyContent: "center" }}>
                {getMapDependencyPopover(option, type, index)}
              </Col>
            </Row>
          ))}
        </div>
      </div>
    );
  
    return (
      <div className="dropdownForWaiting" style={{ width: "50%", marginTop: ".8em", position: "absolute" }}>
        <Popover
          content={content}
          trigger="click"
          open={dropdownOpen[type]}
          onOpenChange={(visible) => {
            if (!visible) setInnerPopoverOpen(false); // Close inner popover when outer is closed
            setDropdownOpen((prev) => ({ ...prev, [type]: visible }));
        }}
          placement="rightTop"
          overlayClassName="Waiting-on-popover"
        >
          <Button icon={<FontAwesomeIcon icon={faPlus}/>}>
            Add Dependency 
          </Button>
        </Popover>
      </div>
    );
  }

  function viewDataForDep(data) {
    const formatDate = (date) => {
      if (!date) return "N/A";
      const options = { year: "numeric", month: "2-digit", day: "2-digit" };
      return new Date(date).toLocaleDateString(undefined, options);
    };
    const taskType = data.SubTaskROWID ? "SubTask" : "Task";
    return (
      <div className="popup">
        <div className="popup-content">
          <span>
            <strong>Task Type:</strong> {taskType}
          </span>
          <span>
            <strong>Task Name:</strong> {data.TaskName}
          </span>
          <span>
            <strong>Task Description:</strong> {data.TaskDescription || ""}
          </span>
          <span>
            <strong>Created By:</strong> {data.CREATEDBY}
          </span>
          <span>
            <strong>Created Date:</strong> {formatDate(data.CREATEDTIME)}
          </span>
          <span>
            <strong>Modified Date:</strong> {formatDate(data.MODIFIEDTIME)}
          </span>
          <span>
            <strong>Assignee:</strong>{" "}
            {userOptions?.filter((e) => e.value == data.Assignee)[0]?.label}
          </span>
          <span>
            <strong>Supervisor:</strong>{" "}
            {userOptions?.filter((e) => e.value == data.Supervisor)[0]?.label}
          </span>
          <span>
            <strong>Priority:</strong> {data.Priority}
          </span>
          <span>
            <strong>Start Date:</strong> {formatDate(data.StartDate)}
          </span>
          <span>
            <strong>Due Date:</strong> {formatDate(data.DueDate)}
          </span>
        </div>
      </div>
    );
  }
  return (
    <div className="dependencyClassDiv">
      <div
        className="subDivForDependencyClass"
        style={collapsed.WaitingOn ? { marginBottom: "4em" } : {}}
      >
        <Row
          className="Collapse-Header-Dependancies"
          onClick={() => toggleCollapse("WaitingOn")}
        >
          <label htmlFor="status" className="labelStatus">
            <CaretRightOutlined rotate={collapsed.WaitingOn ? 90 : 0} />
            <strong>
              Waiting on {selectedDependenciesCache.WaitingOn.length}
            </strong>
          </label>
        </Row>
        {
          collapsed.WaitingOn && (
            <>
              <table>
                <thead>
                  <tr>
                    <th style={{width: "11.9rem"}}>Task Name</th>
                    <th>Assignee</th>
                    <th>Priority</th>
                    <th>Status</th>
                    <th>Target Dependency Status</th>
                    <th>Source Dependency Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {waitingOndata.WaitingOn?.map((task, index) => (
                    <tr key={index}>
                      <td
                        title={task.taskName}
                        style={{ cursor: "pointer" }}
                        className="task-name"
                        onClick={() => openTaskFromDependencies(task.obj)}
                      >
                        {task.taskName}
                      </td>
                      <td className="task-name">
                        <Tooltip
                          color="rgb(54 54 54)"
                          title={`${
                            userOptions?.filter(
                              (e) => e.value == task.assignee
                            )[0]?.label
                          }`}
                        >
                          {userOptions?.filter(
                            (e) => e.value == task.assignee
                          )[0]?.label ? (
                            <img
                              style={{
                                cursor: "pointer",
                                height: "30px",
                                width: "30px",
                                borderRadius: "50%",
                              }}
                              className="Icon"
                              src={
                                "https://contacts.zoho.com/file?ID=" +
                                task.assignee +
                                "&fs=thumb"
                              }
                            />
                          ) : (
                            <img
                              style={{
                                cursor: "pointer",
                                height: "30px",
                                width: "30px",
                              }}
                              src={assigntask}
                            />
                          )}
                        </Tooltip>
                      </td>
                      <td>{task.priority}</td>
                      <td className="task-name">{task.status}</td>
                      <td className="task-name">{task.TargetDependencyStatus}</td>
                      <td className="task-name">{task.SourceDependencyStatus}</td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-evenly",
                          }}
                        >
                          <Tooltip title={viewDataForDep(task.data)}>
                            <span>
                              <FontAwesomeIcon
                                icon={faEye}
                                style={{ opacity: "0.7", color: "gray" }}
                              />
                            </span>
                          </Tooltip>
                          <span>
                            <FontAwesomeIcon
                              icon={faTrash}
                              style={{ opacity: "0.5", color: "#e84445" }}
                              onClick={() => {
                                handleDeleteDependency(task.obj, "waiting");
                              }}
                            />
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {getWaitingOnPopover("WaitingOn")}
            </>
          )
        }
      </div>
      <div
        className="subDivForDependencyClass"
        style={collapsed.Blocking ? { marginBottom: "4em" } : {}}
      >
        <Row
          className={"Collapse-Header-Dependancies"}
          onClick={() => toggleCollapse("Blocking")}
        >
          <label htmlFor="status" className="labelStatus">
            <CaretRightOutlined rotate={collapsed.Blocking ? 90 : 0} />
            <strong>
              Blocking {selectedDependenciesCache.Blocking.length}
            </strong>
          </label>
        </Row>
        {
          collapsed.Blocking && (
            <>
              <table>
                <thead>
                  <tr>
                    <th style={{width: "11.9rem"}}>Task Name</th>
                    <th>Assignee</th>
                    <th>Priority</th>
                    <th>Status</th>
                    <th>Target Dependency Status</th>
                    <th>Source Dependency Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {waitingOndata.Blocking?.map((task, index) => (
                    <tr key={index}>
                      <td
                        title={task.taskName}
                        style={{ cursor: "pointer" }}
                        className="task-name"
                        onClick={() => openTaskFromDependencies(task.obj)}
                      >
                        {task.taskName}
                      </td>
                      <td>
                        <Tooltip
                          color="rgb(54 54 54)"
                          title={`${
                            userOptions?.filter(
                              (e) => e.value == task.assignee
                            )[0]?.label
                          }`}
                        >
                          {userOptions?.filter(
                            (e) => e.value == task.assignee
                          )[0]?.label ? (
                            <img
                              style={{
                                cursor: "pointer",
                                height: "30px",
                                width: "30px",
                                borderRadius: "50%",
                              }}
                              className="Icon"
                              src={
                                "https://contacts.zoho.com/file?ID=" +
                                task.assignee +
                                "&fs=thumb"
                              }
                            />
                          ) : (
                            <img
                              style={{
                                cursor: "pointer",
                                height: "30px",
                                width: "30px",
                              }}
                              src={assigntask}
                            />
                          )}
                        </Tooltip>
                      </td>
                      <td>{task.priority}</td>
                      <td className="task-name">{task.status}</td>
                      <td className="task-name">{task.TargetDependencyStatus}</td>
                      <td className="task-name">{task.SourceDependencyStatus}</td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-evenly",
                          }}
                        >
                          <Tooltip title={viewDataForDep(task.data)}>
                            <span>
                              <FontAwesomeIcon
                                icon={faEye}
                                style={{ opacity: "0.7", color: "gray" }}
                              />
                            </span>
                          </Tooltip>
                          <span>
                            <FontAwesomeIcon
                              icon={faTrash}
                              style={{ opacity: "0.5", color: "#e84445" }}
                              onClick={() => {
                                handleDeleteDependency(task.obj);
                              }}
                            />
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {getWaitingOnPopover("Blocking")}
            </>
          )
        }
      </div>
    </div>
  );
}
