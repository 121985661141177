import dayjs from "dayjs";
import AwsServerService from "../common/util/AwsServerService"
import _ from "lodash";
export const fetchCriteriaFields = () => {
  new Promise(async (resolve, reject) => {
    await AwsServerService.getCriteriaFields().then(res => {
      console.log("api data===", res.dada[0]);
      resolve(res.dada[0]);
    }).catch(err => {
      reject(err);
    })
  })
}
export const getCriteriaFieldsAndObjects = async (allDeals, allTasks) => {
  return new Promise((resolve, reject) => {
    let obj = {
      "Deal_Name": _.uniqBy(allDeals, 'Deal_Name')?.map(deal => ({ label: deal.Deal_Name, value: deal.Deal_Name })),
      "City": _.uniqBy(allDeals, 'City')?.map(deal => ({ label: deal.City, value: deal.City })),
      "State": _.uniqBy(allDeals, 'State')?.map(deal => ({ label: deal.State, value: deal.State })),
      "TaskName": _.uniqBy(allTasks, 'TaskName')?.map(task => ({ label: task.TaskName, value: task.TaskName })),
      "Assignee": _.uniqBy(allTasks, 'Assignee')?.map(task => ({ label: task.Assignee, value: task.Assignee })),
      "Supervisor": _.uniqBy(allTasks, 'Supervisor')?.map(task => ({ label: task.Supervisor, value: task.Supervisor })),
    };
    // console.log("------------criteria multi",obj);
    resolve(obj);
  })
}
export function evaluateEdgeVisibility(dataObject, edge ,allEdges) {
    
  if (!edge.Criteria || edge.Criteria.length === 0) {
    // If no criteria, the edge is visible by default
    return true;
  }

  // Initialize result based on first criterion (before applying conditions)
  let overallResult = true; // Start with true (for AND logic)

  // Loop through each criterion for the edge
  for (let i = 0; i < edge.Criteria.length; i++) {
    const criterion = edge.Criteria[i];
    const { group, initial, middel, last, condition, type} = criterion;

    // Extract the relevant data from the provided dataObject
    if (group == "Transition") {
      if (initial && middel && last) {
        const edge = allEdges?.find(ee=>ee.id==initial); 
        let criterionResult = false; 
        switch (middel) {
          case "Is":
            switch (last) {
              case "Required":
                criterionResult = edge.visited?true:false;
                break;
              case "Not Required":
                criterionResult = true;
                break;
              default:
                console.warn(`Unknown operator: ${last}`);
                criterionResult = false; 
            }
            break;
          case "Is Not":
            switch (last) {
              case "Required":
                criterionResult = true;
                break;
              case "Not Required":
                criterionResult = edge.visited?true:false;
                break;
              default:
                console.warn(`Unknown operator: ${last}`);
                criterionResult = false; 
            }
            break;
          default:
            console.warn(`Unknown operator: ${middel}`);
            criterionResult = false; // If the operator is unknown, treat it as false
        }

        // Apply the logical condition (AND/OR)
        if (i === 0) {
          // For the first criterion, no condition, just take the result
          overallResult = criterionResult;
        } else if (condition === "AND") {
          // Apply AND condition
          overallResult = overallResult && criterionResult;
        } else if (condition === "OR") {
          // Apply OR condition
          overallResult = overallResult || criterionResult;
        } else {
          console.warn(`Unknown condition: ${condition}`);
          return false; // Hide edge if the condition is unknown
        }

      }
    }else{
      let groupData;
      groupData = dataObject[group]; 
      if (initial && middel && last) {
        if (!groupData) {
          overallResult = false; 
        } else {
          let valueToCompare; 
          if(group=="Input Fields" && initial){
            valueToCompare = groupData?.find(k=>k.id==initial)?.value; 
          }else{
            valueToCompare = groupData[initial]; 
          }
          let criterionResult = false; // Store individual criterion result

          if(["TEXT","PHONE","DEFAULT"].includes(type)){
            // Evaluate based on the operator (middel)
            switch (middel) {
              case "Is":
                criterionResult = valueToCompare === last;
                break;
              case "Is Not":
                criterionResult = valueToCompare !== last;
                break;
              case "Contains":
                criterionResult = typeof valueToCompare === "string" && valueToCompare.includes(last);
                break;
              case "Not Contains":
                criterionResult = typeof valueToCompare === "string" && !valueToCompare.includes(last);
                break;
              case "Starts With":
                criterionResult = typeof valueToCompare === "string" && valueToCompare.toLowerCase().startsWith(last.toLowerCase());
                break;
              case "Ends With":
                criterionResult = typeof valueToCompare === "string" && valueToCompare.toLowerCase().endsWith(last.toLowerCase());
                break;
              case "Is Empty":
                criterionResult =  valueToCompare === "" || valueToCompare === undefined || valueToCompare === null;
                break;
              case "Is Not Empty":
                criterionResult =  valueToCompare !== "" || valueToCompare !== undefined || valueToCompare !== null;
                break;
              default:
                console.warn(`Unknown operator: ${middel}`);
                criterionResult = false; // If the operator is unknown, treat it as false
            }
          }else if(["NUMBER","CURRENCY"].includes(type)){
            switch (middel) {
              case "=":
                criterionResult = valueToCompare === last;
                break;
              case "!=":
                criterionResult = valueToCompare !== last;
                break;
              case "<":
                criterionResult = typeof valueToCompare === "number" && valueToCompare<last;
                break;
              case "<=":
                criterionResult = typeof valueToCompare === "number" && valueToCompare<=last;
                break;
              case ">":
                criterionResult = typeof valueToCompare === "number" && valueToCompare>last;
                break;
              case ">=":
                criterionResult = typeof valueToCompare === "number" && valueToCompare>=last;
                break;
              default:
                console.warn(`Unknown operator: ${middel}`);
                criterionResult = false; // If the operator is unknown, treat it as false
            }
          }else if(["DATE"].includes(type)){
            switch (middel) {
              case "Is":
                criterionResult = dayjs(valueToCompare).format("MM/DD/YYYY") === dayjs(last).format("MM/DD/YYYY");
                break;
              case "Is Not":
                criterionResult = dayjs(valueToCompare).format("MM/DD/YYYY") !== dayjs(last).format("MM/DD/YYYY");
                break;
              case "Before":
                criterionResult = dayjs(valueToCompare).isBefore(last);
                break;
              case "After":
                criterionResult = dayjs(valueToCompare).isAfter(last);
                break;
              default:
                console.warn(`Unknown operator: ${middel}`);
                criterionResult = false; // If the operator is unknown, treat it as false
            }
          }else if(["PICKLIST"].includes(type)){
            switch (middel) {
              case "Is":
                criterionResult = valueToCompare === last;
                break;
              case "Is Not":
                criterionResult = valueToCompare !== last;
                break;
              default:
                console.warn(`Unknown operator: ${middel}`);
                criterionResult = false; // If the operator is unknown, treat it as false
            }
          }else if(["MULTI-PICKLIST"].includes(type)){
            switch (middel) {
              case "Is":
                criterionResult = Array.isArray(valueToCompare) && valueToCompare?.some(v1=>last.includes(v1));
                break;
              case "Is Not":
                criterionResult = !(Array.isArray(valueToCompare) && valueToCompare?.some(v1=>last.includes(v1)));
                break;
              default:
                console.warn(`Unknown operator: ${middel}`);
                criterionResult = false; // If the operator is unknown, treat it as false
            }
          }else if(["CHECKBOX"].includes(type)){
            switch (middel) {
              case "Is":
                criterionResult = valueToCompare==last;
                break;
              case "Is Not":
                criterionResult = valueToCompare!=last;
                break;
              default:
                console.warn(`Unknown operator: ${middel}`);
                criterionResult = false; // If the operator is unknown, treat it as false
            }
          }else if(["USER"].includes(type)){
            switch (middel) {
              case "Is":
                criterionResult = !last?.some(k=>!valueToCompare?.includes(k));
                break;
              case "Is Not":
                criterionResult = !last?.some(k=>valueToCompare?.includes(k));
                break;
              default:
                console.warn(`Unknown operator: ${middel}`);
                criterionResult = false; // If the operator is unknown, treat it as false
            }
          }

          // Apply the logical condition (AND/OR)
          if (i === 0) {
            // For the first criterion, no condition, just take the result
            overallResult = criterionResult;
          } else if (condition === "AND") {
            // Apply AND condition
            overallResult = overallResult && criterionResult;
          } else if (condition === "OR") {
            // Apply OR condition
            overallResult = overallResult || criterionResult;
          } else {
            console.warn(`Unknown condition: ${condition}`);
            return false; // Hide edge if the condition is unknown
          }
        }
      }
    }
  }

  return overallResult; // Return the final visibility result
}
export function filterEdges(edges, edge) {
  // Extract the source of the provided edge
  const edgeSource = edge.source;
  const edgeTarget = edge.target;

  // Filter the edges array
  const filteredEdges = edges.filter(e => {
    // Check if the edge's destination matches the provided edge's source
    const isMatchingDestination = e.target === edgeSource;

    // Exclude circular connections where source and target are reversed
    const isCircularConnection = e.source === edgeTarget && e.target === edgeSource;

    // Return only edges that match the destination and are not circular
    return isMatchingDestination && !isCircularConnection;
  });

  return filteredEdges;
}
