import React, { useEffect, useState } from "react";
import { Modal as AntModal, Input, Tabs, Button, Badge } from "antd";
import "./viewPipeline.css";
import { Row } from "reactstrap";
import { Col } from "react-bootstrap";
import { CloseOutlined } from "@ant-design/icons";
import TaskListTab from "../ViewPipelinesTabs/TaskListTab";
import ActivityStreamTab from "../ViewPipelinesTabs/ActivityStreamTab";
import AwsServerService from "../../../common/util/AwsServerService";
import swal from "sweetalert";
// import StatusTimeline from "../ViewPipelinesTabs/StatusTimeline";

const ViewPipelines = ({
  open,
  setOpen,
  viewPipelineData,
  viewTaskFunction,
  setViewPipelineData,
  refreshData,
  setPageRefreshData,
  openTaskModal,
  setOpenTaskModal,
  allPipelineData,
  selectedTable,
  history,
  allBlueprintsData
}) => {
  const userInfo = JSON.parse(localStorage.getItem("userinfo"));
  const initialPipelineData = {};
  const [pipelineData, setPipelineData] = useState(initialPipelineData);  

  useEffect(e=>{
    if(allPipelineData){
      let pipelineId = pipelineData.PipelineROWID || pipelineData.ROWID;
      let pipeline = allPipelineData.find(e=>e.PipelineROWID==pipelineId);
      if(pipeline){
        setPipelineData(pipeline);
      }
    }
  },[allPipelineData])

  useEffect(
    (e) => {
      if (viewPipelineData && viewPipelineData?.length > 0) {
        setPipelineData(viewPipelineData[0]);
      }
    },
    [viewPipelineData, refreshData]
  );

  // Save Pipeline Api Call //

  const handleSavePipeline = async () => {
    let data = { ...pipelineData };
    if (data.PipelineName == "" || data.PipelineName == undefined) {
      swal("Alert", "Please fill all the required fields!", "warning");
      return;
    }
    data["MODIFIEDBY"] = userInfo?.email_id;
    data["ROWID"] = pipelineData?.PipelineROWID
      ? pipelineData?.PipelineROWID
      : pipelineData?.ROWID;
    delete data?.PipelineROWID;
    delete data?.Tasks;
    delete data?.PipelineId
    AwsServerService.updatePipelineInTemplate(data)
      .then(async (res) => {
        // swal('Success','Pipeline updated successfully!','success')
        if (pipelineData?.Tasks?.length > 0) {
          await Promise.all(
            pipelineData?.Tasks.map((item) => {
              if (item.TaskROWID || !item?.TaskName) {
                return;
              }
              delete item["id"];
              delete item["flag"];
              item["PipelineROWID"] = pipelineData?.PipelineROWID || pipelineData?.ROWID;
              return AwsServerService.saveTaskInTemplate(item).then((e) => {
              });
            })
          ).then((allRes) => {
            setPageRefreshData((prev) => !prev);
          });
        }else{
          setPageRefreshData((prev) => !prev);
        }
        setOpen(!open);
      })
      .catch((err) => {
        swal("Failed", "Some error occured", "warning");
      });
    //  }
  };

  return (
    <div>
      <AntModal
        open={open}
        wrapClassName={"View-Pipeline-Modal-Wrapper"}
        rootClassName="View-Pipeline-Modal-Root-Wrapper"
        draggable
        title=""
        maskClosable={false}
        width={300}
        className=""
        style={{ top: 170, borderRadius: 50, right: 50 }}
        onCancel={() => {
          setPipelineData(initialPipelineData);
          history.replace('/setting/pipeline')
          setViewPipelineData([]);
          setOpen(false);
        }}
        footer={[<></>]}
      >
        <div className="View-Pipeline-Wrapper">
          <div className="Task-Header">
            <div>
              <h3 style={{ marginBottom: "0px", opacity: "0.85" }}>
                Update Pipeline
              </h3>
            </div>
            <div style={{ display: "flex", gap: "10px" }}>
              <Button
                type="primary"
                // icon={<CloseOutlined />}
                onClick={() => {
                  handleSavePipeline();
                }}
              >
                {"Update Pipeline"}
              </Button>
              <Button
                icon={<CloseOutlined />}
                onClick={() => {
                  setPipelineData(initialPipelineData);
                  setViewPipelineData([]);
                  history.replace('/setting/pipeline')
                  setOpen(false);
                }}
              ></Button>
            </div>
          </div>
          <div className="View-Pipeline-Modal-Body-Wrapper">
            <div className="View-Pipeline-Modal-Body-Child1">
              <Row className="View-Pipeline-Modal-Body" sm={12}>
                <Col className="View-Pipeline-Info-Wrapper" sm={12}>
                  {/* <Row className="Pipeline-Primary-Data-wrapper"> */}
                  <Row
                    className="PipelineNameWrapper"
                    style={{ marginBottom: "10px" }}
                  >
                    <Input
                      className="Pipeline-Name"
                      size="large"
                      placeholder="Pipeline Name"
                      variant="borderless"
                      value={pipelineData?.PipelineName}
                      onChange={(e) => {
                        e.target.value = e.target.value.trimStart();
                        setPipelineData({
                          ...pipelineData,
                          PipelineName: e.target.value,
                        });
                      }}
                    />
                  </Row>
                  <Row>
                    <Tabs
                      defaultActiveKey="1"
                      items={[
                        {
                          label: (
                            <Badge
                              color="blue"
                              count={pipelineData?.Tasks?.length}
                              offset={[10, 0]}
                            >
                              <span>Task List</span>
                            </Badge>
                          ),
                          key: "1",
                          children: (
                            <TaskListTab
                              groupFilter={""}
                              viewTaskFunction={viewTaskFunction}
                              viewPipelineData={viewPipelineData}
                              openTaskModal={openTaskModal}
                              setOpenTaskModal={setOpenTaskModal}
                              pipelineData={pipelineData}
                              setPipelineData={setPipelineData}
                              refreshData={refreshData}
                              setPageRefreshData={setPageRefreshData}
                              allPipelineData={allPipelineData}
                              selectedTable={selectedTable}
                              allBlueprintsData={allBlueprintsData}
                            />
                          ),
                        },
                        {
                          label: (
                            <Badge color="blue" count={0} offset={[10, 0]}>
                              <span>Status Timeline</span>
                            </Badge>
                          ),
                          key: "5",
                            children: <></>,
                        },
                        {
                          label: (
                            <Badge color="blue" count={0} offset={[10, 0]}>
                              <span>Activity Stream</span>
                            </Badge>
                          ),
                          key: "6",
                          children: (
                            <ActivityStreamTab
                              pipelineId={viewPipelineData[0]?.PipelineROWID}
                              module="PIPELINETEMPLATE"
                              refreshData={refreshData}
                            />
                          ),
                        },
                      ]}
                    />
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </AntModal>
    </div>
  );
};

export default ViewPipelines;
