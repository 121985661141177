import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
} from "reactstrap";
import { Tab, Tabs } from "react-bootstrap";
import Workdrive from "../../components/Workdrive/workdrive";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faFile } from "@fortawesome/free-regular-svg-icons";
import attachIcon from "../../assets/images/task-attachment-icon.png";

const AttachmentModal = (props) => {
  const [key, setKey] = useState("desktop");

  const getIcon = (ext, one) => {
    ext = ext.toLowerCase();
    if (ext == "txt") {
      return <FontAwesomeIcon size="5x" className="file-icon" icon={faFile} />;
    } else if (
      ext == "png" ||
      ext == "jpg" ||
      ext == "svg" ||
      ext == "JPEG" ||
      ext == "jpeg"
    ) {
      return <embed src={one.file} height="100" width="100" />;
    } else if (ext == "pdf") {
      return <embed src={one.file} height="100" width="100" />;
    } else {
      return <img className="file-icon" src={attachIcon} />;
    }
  };

  return (
    <div>
      <Modal
        isOpen={props.attachmentModal}
        backdrop={"static"}
        size="lg"
        className="taskdetail-modal-subtask"
        modalClassName="Create-Task-Attachment-Modal"
        wrapClassName="Create-Task-Attachment-Modal-Wrap"
        zIndex={9999}
      >
        <ModalBody>
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="job-parent-tabs ml-0"
          >
            <Tab eventKey="desktop" title="Desktop">
              <div className="drop-file-box my-3 d-flex justify-content-center align-items-center ">
                <ul
                  className="d-flex flex-wrap p-0 "
                  style={{ overflowY: "auto", maxHeight: "437px" }}
                >
                  {props.fileNames.map((one) => {
                    let ext = one.fileName.split(".");
                    let extLength = ext.length - 1;
                    console.log(ext);
                    return (
                      <>
                        <div className="card mx-1 mt-2" style={{ width: "14rem" }}>
                          <div className="card-body">
                            <h5>
                              {getIcon(ext[extLength], one)}
                              <p
                                onClick={() =>
                                  props.handleAttachementRemove(one)
                                }
                                className="cursor-pointer float-right"
                              >
                                <FontAwesomeIcon
                                  className="task-attachment-modal-remove-icon"
                                  icon={faTimes}
                                />
                              </p>
                            </h5>
                            <h6 className="card-title">{one.fileName}</h6>
                          </div>
                          <div className="card-footer">
                            {
                              //    one.Status=="Success" ? <a
                              //         className="card-link"
                              //         // onClick={()=>props.uploadFiles(one)}
                              //     >Delete
                              //     </a> :
                              <a
                                className="card-link float-right"
                                onClick={() => props.uploadFiles([one])}
                              >
                                Upload
                              </a>
                            }
                          </div>
                        </div>
                      </>
                    );
                  })}
                </ul>
                {props.fileNames.length > 0 ? (
                  ""
                ) : (
                  <Button onClick={(e) => props.handleUploadFiles(e)}>
                    Click here to upload
                  </Button>
                )}
              </div>
            </Tab>
            <Tab eventKey="workdrive" title="Workdrive">
              <Workdrive data={props.data} />
            </Tab>
          </Tabs>
          {props.fileNames.length > 0 ? (
            <button
              className="btn bt-sm float-right ml-3 btn-secondary"
              onClick={(e) => props.handleUploadFiles(e)}
            >
              Add More Files
            </button>
          ) : (
            ""
          )}
          {props.fileNames.length > 0 ? (
            <button
              className="btn bt-sm float-right ml-3 btn-secondary"
              onClick={(e) => props.uploadFiles(props.fileNames)}
            >
              Upload All
            </button>
          ) : (
            ""
          )}
          <button
            onClick={() => {
              props.setAttachmentModal(false);
              props.setFileNames([]);
            }}
            className="btn bt-sm float-right btn-secondary"
          >
            Cancel
          </button>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default AttachmentModal;
