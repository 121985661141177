import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from 'react-router-dom';
import { Card, CardText, CardBody, CardTitle, Col, Row } from "reactstrap";
import "./Setting.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import AppContext from "../../Context/AppContext";
import { taskActiveKey } from "../../Context/AppActions";

const Setting = (props) => {
    const Arr = [
        { name: 'General', child: [{ parent: 'General', name: 'Personal Setting', route: '/settings/profile' }] },
        { name: 'Users and Controls', child: [{ name: 'Users', parent: 'Users and Controls' }, { parent: 'Users and Controls', name: 'User Role', route: "/usersroles/list" }] },
        { name: 'Task', child: [{ parent: 'Task', name: 'Pipeline', route: "/pipeline/list",state:{action:'listview',module:'Pipeline'} }, { parent: 'Task', name: 'Services', route: "/services/list" }, { parent: 'Task', name: 'Category', route: "/category/list" }] },
        {
            name: 'Deals', child: [{ parent: 'Deals', name: 'Input Fields', route: "/inputFields/list" },
        { parent: 'Layout', name: 'Layout', route: "/layout/list" }
            ]
        },
        {
            name: 'Utility Data', child: [
                { parent: 'Utility Data', name: 'Holidays', state:{action:'listview',module:'Holidays'}, route: "/holidays/list" },
                { parent: 'Utility Data',state: { action: 'listview', module: 'Photovoltaic_Modules' }, name: 'Photovoltaic Module', route: "/pvmodule/list" },
            { parent: 'Utility Data', name: 'Photovoltaic Manufacturer', state:{action:'listview',module:'Photovoltaic_Manufacturer'}, route: "/pvmanufacturer/list" },
            { parent: 'Utility Data', name: 'String Inverter', route: "/stringInverter/list" },
            { parent: 'Utility Data', name: 'Micro Inverter', route: "/microInverter/list" },
            { parent: 'Utility Data', name: 'General Contractors', route: '/generalContractor/list' }, { name: 'Liscensed Electrician', route: '/licensedElectrician/list' }
            ]
        },
        { name: 'Automation', 
            child: [{ parent: 'Automation', name: 'Automation', route: "/automations" },
            { parent: 'Automation', name: 'Task Flows', route: "/setting/taskflows", state:{action:"TaskBlueprint",module:"TaskBlueprint"} },
            { parent: 'Automation', name: 'Pipeline', route: "/setting/pipeline", state:{action:"Pipeline",module:"Pipeline"} }
        ] },

    ]
    const [cardsArr, setCardArr] = useState(Arr);
    const [search, setSearch] = useState('');
    const history = useHistory();
    const location = props.location;
    const params = useParams();
    const AppData = useContext(AppContext);
    const { dispatch, state } = AppData || {};

    useEffect(() => {
        if (props.getParams) {
          props.getParams(params);
        }
      }, [location]);

    const handleChangeRoutes = (name, route,state) => {  
        if (name == 'Users' || name == 'Personal Setting' || name == 'Company Detail') {
            history.push({ pathname: '/setting/users/4695950000000296001/'+name, state: { tab: name } });
        } else {
            if(name == "Pipeline"){
                dispatch(taskActiveKey({tab : "pipeline", view : ""}))
            }
            history.push({ pathname: route, state: {...state} });
        }
    }

    const handleSearch = (e) => {
        setSearch(e.target.value);
    }

    const getSearchContent = (search) => {
        if (search !== '') {
            let arr = []
            Arr.forEach(one => {
                arr = [...one.child, ...arr];
            })
            let filterArr = arr.filter(one => one.name.toLowerCase().includes(search.toLowerCase()))
            return <Col sm={12} className='settings-search-card'>
                <Card className="settings-dropdown">
                    <CardBody className="">
                        {filterArr.map(one => {
                            return <div onClick={() => handleChangeRoutes(one.name, one.route,one.state,one.module)} className="cursor-pointer settings-dropdown-menu">
                                <h5>{one.name}</h5>
                                <h6>{one.parent}</h6>
                            </div>
                        })}
                    </CardBody>
                </Card>
            </Col>
        } else {
            return <></>
        }
    }
    return (
        <div className="settings-wrapper">
            {getSearchContent(search)}
            <div>
                <div className="settings-search">
                    <h4 className="mb-0">SETTINGS</h4>
                    <div className="py-2 px-3 position-relative">
                        <input className="search-input" onChange={handleSearch} type="text" placeholder="Search here..." />
                        <FontAwesomeIcon icon={faSearch} className="searchbar-icon" />
                    </div>
                </div>
                <Row className="m-0 d-flex align-items-start setting-dashboard">
                    {cardsArr.map((one) => {
                        return <Col sm={3} className='my-2'>
                            <Card className="settings-card m-auto">
                                <CardBody className="">
                                    <CardTitle>{one.name}</CardTitle>
                                    <CardText>
                                        <ul>
                                            {one.child.map(children => {
                                                return <li  onClick={() => {
                                                    handleChangeRoutes(children.name, children.route,children.state)
                                                }} className="setting-child cursor-pointer">
                                                    {children.name}
                                                </li>
                                            })}
                                        </ul>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    })}
                </Row>
            </div>
        </div>
    )
}

export default Setting;
