import React, { useState, useEffect } from "react";
import { Popover, Button, Col } from "antd";
import { HourglassOutlined } from "@ant-design/icons";
import { Row } from "reactstrap";
import Creatable from 'react-select/creatable';

// Define options for Creatable
const generateOptions = (max) => Array.from({ length: max + 1 }, (_, i) => ({ value: i, label: i }));

const CustomTimeSelect = ({ rootClassName, className, placement, taskData, setTaskData }) => {
  const [visible, setVisible] = useState(false);
  const initialDuration = { days: 0, hours: 0, minutes: 0 };
  const [duration, setDuration] = useState(initialDuration);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      width: "80px",
      background: "#fff",
      borderColor: "#ccc",
      minHeight: "2.2rem",
      height: "auto",
      boxShadow: state.isFocused ? 0 : 0,
      "&:hover": {},
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "0px 0.6rem",
    }),
    input: (provided) => ({
      ...provided,
      margin: "0px",
      padding: "0px",
      height: "15px",
      fontSize: "13px",
    }),
    indicatorSeparator: () => ({ display: "none" }),
    indicatorsContainer: (provided) => ({ ...provided, display: "none" }),
    placeholder: (provided) => ({
      ...provided,
      margin: "0px",
      padding: "0px",
      height: "1rem",
      fontSize: "1rem",
    }),
    singleValue: (provided) => ({ ...provided, fontSize: "15px" }),
    option: (provided) => ({ ...provided, fontSize: "13px" }),
    menu: (provided) => ({ ...provided, width: "100%" }),
  };

  const handleVisibleChange = (visible) => {
    if (!visible) {
      setDuration({ days: 0, hours: 0, minutes: 0 });
    }
    setVisible(visible);
  };

  const handleSave = () => {
    const data = { ...taskData };
    data.TimeEstimate = showDuration(duration);
    setTaskData(data);
    setDuration(initialDuration);
    setVisible(false);
  };

  const clearDuration = () => {
    setDuration({ days: 0, hours: 0, minutes: 0 });
    const data = { ...taskData };
    data.TimeEstimate = '';
    setTaskData(data);
  };

  const handleChange = (field, value) => {
    const newValue = value ? value.value : 0;
    let updatedDuration = { ...duration, [field]: newValue };

    if (field === "minutes" && newValue >= 60) {
      const extraHours = Math.floor(newValue / 60);
      updatedDuration.minutes = newValue % 60;
      updatedDuration.hours += extraHours;
    }

    if (field === "hours" && updatedDuration.hours >= 24) {
      const extraDays = Math.floor(updatedDuration.hours / 24);
      updatedDuration.hours = updatedDuration.hours % 24;
      updatedDuration.days += extraDays;
    }

    setDuration(updatedDuration);
  };

  const showDuration = (duration) => {
    let showTime = '';
    if (duration.days > 0) showTime += `${duration.days}d `;
    if (duration.hours > 0) showTime += `${duration.hours}h `;
    if (duration.minutes > 0) showTime += `${duration.minutes}m`;
    return showTime.trim();
  };

  // Parse TimeEstimate and populate the dropdowns
  useEffect(() => {
    if (visible && taskData?.TimeEstimate) {
      const timeString = taskData.TimeEstimate;
      const daysMatch = timeString.match(/(\d+)d/);
      const hoursMatch = timeString.match(/(\d+)h/);
      const minutesMatch = timeString.match(/(\d+)m/);

      const parsedDuration = {
        days: daysMatch ? parseInt(daysMatch[1], 10) : 0,
        hours: hoursMatch ? parseInt(hoursMatch[1], 10) : 0,
        minutes: minutesMatch ? parseInt(minutesMatch[1], 10) : 0,
      };

      setDuration(parsedDuration);
    }
  }, [visible, taskData]);

  const daysOptions = generateOptions(30);
  const hoursOptions = generateOptions(23);
  const minutesOptions = generateOptions(59);

  const content = (
    <div>
      <Row sm={12} style={{ gap: "10px", marginBottom: "10px" , padding: "0px 13px"}}>
        <Col sm={4}>
          <span style={{ fontWeight: "500" }}>Days</span>
          <Creatable
            value={daysOptions.find((option) => option.value === duration.days)}
            onChange={(value) => handleChange("days", value)}
            options={daysOptions}
            placeholder="Days"
            styles={customStyles}
          />
        </Col>
        <Col sm={4}>
          <span style={{ fontWeight: "500" }}>Hours</span>
          <Creatable
            value={hoursOptions.find((option) => option.value === duration.hours)}
            onChange={(value) => handleChange("hours", value)}
            options={hoursOptions}
            placeholder="Hours"
            styles={customStyles}
          />
        </Col>
        <Col sm={4}>
          <span style={{ fontWeight: "500" }}>Minutes</span>
          <Creatable
            value={minutesOptions.find((option) => option.value === duration.minutes)}
            onChange={(value) => handleChange("minutes", value)}
            options={minutesOptions}
            placeholder="Minutes"
            styles={customStyles}
          />
        </Col>
      </Row>
      <Button type="primary" onClick={handleSave}>
        Save
      </Button>
      <Button type="primary" danger onClick={clearDuration} style={{ marginLeft: "10px" }}>
        Clear
      </Button>
    </div>
  );

  return (
    <Popover
      content={content}
      title="Time Estimate"
      trigger="click"
      open={visible}
      onOpenChange={handleVisibleChange}
      rootClassName={rootClassName}
      className={className}
      placement={placement}
    >
      <span style={taskData?.TimeEstimate != "null" && taskData?.TimeEstimate ? {cursor:"pointer", padding: "8px"} : {cursor:"pointer"}}>{taskData?.TimeEstimate != "null" && taskData?.TimeEstimate ? taskData?.TimeEstimate : <HourglassOutlined className="hour-glass" />}</span>
    </Popover>
  );
};

export default CustomTimeSelect;
