import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Popover } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react'
import { DateRangePicker } from 'react-date-range';

export const DateRangePickerNew = ({ranges,onChange}) => {
    const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(), // default to a week-long range
      key: 'selection'
    }
  ]);

  useEffect(e=>{
    if(ranges && ranges.startDate && ranges.endDate){
        setState([ranges]);
    }
  },[ranges])

  useEffect(e=>{
    if(ranges && ranges.startDate && ranges.endDate){
        setState([ranges]);
    }
  },[ranges])

  const handleSelect = (ranges) => {
    console.log(ranges); // log selected range
    onChange(ranges.selection);
    setState([ranges.selection]); // update the state with selected range
  };

    return (
        <Popover
          overlayClassName="create-task-datepicker"
          content={
            <div className="due-date-range">
              <DateRangePicker
                onChange={handleSelect}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={1}
                ranges={Array.isArray(state) ? state : []}
              //   ranges={row.last}
                direction="vertical"
                scroll={{ enabled: true }}
              />
            </div>
          }
          trigger="click"
          placement="bottom"
        >
          <Button
            id="setDueDate"
            className="SupervisorBtn"
            icon={<FontAwesomeIcon icon={faCalendarAlt} />}
            style={{ width: "100%" , color: "#5a6c97"}}
          >
            {`${dayjs(state[0]?.startDate?state[0]?.startDate:new Date()).format("MM/DD/YYYY")} - ${dayjs(state[0]?.endDate?state[0]?.endDate:new Date()).format("MM/DD/YYYY")}`}
          </Button>
        </Popover>
      );
}
