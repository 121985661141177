import { Badge, Modal, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import './HelperComponent.css'
import { Col, Row } from 'reactstrap'

export const NewCustomePopover = ({open,width,disableCloseIcon,onCancel,okButtonProps,cancelButtonProps,onOk,cancelText,okText,title,titleBackGroundColor,children}) => {

    return (
        <div>
            <Modal open={open}
                onCancel={onCancel}
                width={`${width+"vw"}`}
                className='Custome-Backdrop Node-Delete-Conformation-Popover'
                closable={disableCloseIcon?false:true}
                maskClosable={false}
                title={
                    <Badge.Ribbon placement='start' text={<div>{title?title:"Warning!"}</div>} color={`${titleBackGroundColor?titleBackGroundColor:"red"}`} >
                        <Row sm={12}>
                            <Col sm={1}></Col>
                        </Row>
                    </Badge.Ribbon>
                }
                onOk={onOk}
                okText={okText?okText:"OK"}
                cancelText={cancelText?cancelText:"Cancel"}
                cancelButtonProps={cancelButtonProps}
                okButtonProps={okButtonProps}
            > {children}
            </Modal>
        </div>
    )
}
