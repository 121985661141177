import React, { useState, useEffect } from "react";

import { Progress, Space } from 'antd';
import '../loader.css';

const ProgressBarCustom = ({ percent }) => {

    return (
        <div className="loader-contentV1">
            <div style={{ width: 475, marginLeft: -189 }} className="loader">
                {/* <div className="loading"></div> */}
                {/* <h5>Loading...</h5> */}
                <div className="progress">
                    <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{width:`${percent}%`}}>{percent}%</div>
                </div>
            </div>
        </div>

    );
    // return (
    //     <div className="spinner-border text-dark" role="status">
    //         <span className="visually-hidden">Loading...</span>
    //     </div>
    // )
}


export default ProgressBarCustom;