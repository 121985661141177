import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Modal as AntModal,
  Input,
  Popover,
  Tabs,
  Button,
  Badge,
  theme,
  Select,
  Tooltip,
  Checkbox,
  Popconfirm,
} from "antd";
import "./CreateTaskModal.css";
import { Row, Col} from "reactstrap";
import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faUser,
  faClock,
  faFolder,
  faPaperclip,
  faCodeBranch,
  faArrowLeft,
  faTimes,
  faCheck,
  faAngleRight,
  faPlus,
  faMinus,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { DateRangePicker } from "react-date-range";
import { CaretDownFilled, CaretDownOutlined, CaretRightOutlined, CheckCircleOutlined, CloseOutlined, FlagFilled, MinusCircleFilled, MinusCircleOutlined, MinusCircleTwoTone, MinusOutlined, PlusCircleFilled, PlusCircleOutlined, PlusOutlined, PlusSquareTwoTone, } from "@ant-design/icons";
import { faCommentAlt } from "@fortawesome/free-regular-svg-icons";
import { UserPicker } from "../../common/UserPicker/UserPicker";
import { AppDataRequired } from "../../App";
import SubTaskTab from "./AddTaskTabs/SubTaskTab";
import { Checklist } from "./AddTaskTabs/Checklist";
import { ActivitySection } from "./AddTaskTabs/ActivitySection";
import TextArea from "antd/es/input/TextArea";
import { CustomeCollapse } from "./AddTaskTabs/CustomeCollapse";
import AppContext from "../../Context/AppContext";
import AwsServerService from "../../common/util/AwsServerService";
import _ from 'lodash';
import swal from "sweetalert";
import {convertToUSTime,getPriorityValue, invertColor,} from "../../components/HR/TimeClock/Util";
import { Option } from "antd/es/mentions";
import { number } from "mathjs";
import CustomTimeSelect from "../../common/CustomTimeSelect/CustomTimeSelect";
import TimeTracker from "../../common/TimeTracker/TimeTracker";
import {getNextStepOfFlow} from '../../components/ProjectTasks/Util'
import AttachmentModal from "./attachmentModal";
import axios from "axios";
import SettingIconDark from '../../assets/images/svg/SettingIconDark.svg';
import { TaskFlowStatus } from "./TaskStatus/TaskFlowStatus";
import DependencyModal from "./ViewPipelinesTabs/DependencyModal";
import { evaluateEdgeVisibility } from "../../Util/CommonUtil";
import { modifyEdgesAndNodes, processEdgeConnections, updateNodeVisitedStatus } from "./TaskUtil";
import { Flow } from "../../components/TaskBluePrints/Flow";
import Loader from "../../common/loader";
import { InputFields } from "../../components/InputFields/InputFields";
import { InputFieldsTable } from "../../components/InputFields/InputFieldsTable";
import { Socket } from "../../Context/AppActions";
import moment from "moment";

const { Search } = Input;

const CustomeTaskField = ({ name, icon, children ,type}) => {
  return (
    <Row className="Custom-Field-Row" sm={12}>
      <Col style={{ padding: "0px" }} sm={4}>
        <Row sm={12}>
          <Col style={{ padding: "0px" }} sm={2}>
            {icon}
          </Col>
          <Col style={{ padding: "0px 3px", fontWeight: "500", fontSize: "12px" }} sm={10}>
            {name}
          </Col>
        </Row>
      </Col>
      <Col sm={8}>{children}</Col>
    </Row>
  );
};

const CreateTaskModal = ({
  open,
  history,
  setOpen,
  viewTaskData,
  taskModalState,
  viewPipelineData,
  refreshData,
  setPageRefreshData,
  createPiplineModalState,
  selectedTable,
  isSubTaskView,
  setIsSubTaskView,
  allPipelineData,
  viewTaskFunction,
  dropdownTasksDataCache,
  allTasksAndSubtasks,
  allBlueprintsData,
  dependencyRefresh,
  setDependencyRefresh,
  getHeaderForDeal,
  setDropdownTasksDataCache
}) => {
  const backendUrl = "https://catly-795086611.catalystserverless.com/server/apiservice/";
  const { token } = theme.useToken();
  const userInfo = JSON.parse(localStorage.getItem('userinfo'));
  const [loader,setLoader] = useState(false);
  const [accountSelected, setAccountSelected] = useState();
  const [selectedBlueprintData,setSelectedBlueprintData] = useState({
    type: "view flow",
    data: null,
    action: "view"
})
  const [dealSelected, setDealSelected] = useState();
  const [activeTab, setActiveTab] = useState("1");
  const [taskHistory, setTaskHistory] = useState([]); 
  const [statusOptions, setStatusOptions] = useState([]);
  const [mainStatusOptions, setMainStatusOptions] = useState([]);
  const [attachmentModal, setAttachmentModal] = useState(false);
  const [fileNames, setFileNames] = useState([]);
  const [relativePathId, setRelativePathId] = useState(null);
  const [filteredDropdownTasksData,setFilteredDropdownTasksData]=useState([]);
  const [BluePrintDropDownOptions,setBluePrintDropDownOptions] = useState();
  const [selectedButton, setSelectedButton] = useState(null);
  const [popConfirmVisible, setPopConfirmVisible] = useState(false);
    ////// Attachment Functions /////

    const convertToBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
          resolve(fileReader.result);
        };
        fileReader.onerror = (error) => {
          reject(error);
        };
      });
    };

    const handleAttachementRemove = (one) => {
      let tempFileCollection = [...fileNames];
      tempFileCollection = tempFileCollection.filter((file) => file.fileName !== one.fileName);
      setFileNames(tempFileCollection);
    }
      
    const uploadFiles = (arr) => {
      let doArr = []
      let fileUploadArr = []
      let names = []
      let tempFileCollection = [...fileNames];
      tempFileCollection.forEach((obj) => {
        obj.Status = "Pending"
      })
      arr.forEach((one) => {
        names.push(one.fileName);
        // doArr.push(addZohoTaskAttachements(taskData.project_id, taskData.id_string, one))
        fileUploadArr.push(handleUploadFileWorkDrive(one))
      })
  
      // setProgress({ active: true, percent: 1 })
      // let startInterval = setInterval(() => {
      //   setProgress((prev) => {
      //     if (prev && prev.percent < 100) {
      //       prev.percent = prev.percent + 1;
      //       setProgress(prev);
      //     }
      //   })
      // }, 1)
      Promise.all(doArr)
        .then((result) => {
  
          console.log(result);
  
          return Promise.all(fileUploadArr)
        })
        .then((result) => {
          arr.forEach((one) => {
            one.Status = "Success"
          })
  
          // setProgress({ active: true, percent: 100 });
          let filterTemp = tempFileCollection.filter(e => e.Status != "Success")
          // setLoader(false);
          setFileNames(filterTemp);
          // clearInterval(startInterval);
          // setProgress({ active: false, percent: 0 });
          alert("file upload successfully");
          // setAttachmentModal(false)
          console.log(result);
        })
        .catch((err) => {
          console.log(err);
        })
  
      // let text = `You upload ${tempFileCollection.length} files `
      // let text = `You upload one file `
      // let filename = names.join(' , ')
      // swal({
      //   title: "Are you sure?",
      //   text: text + filename,
      //   icon: "warning",
      //   buttons: [
      //     'No',
      //     'Yes'
      //   ],
      //   dangerMode: true,
      // }).then(function (isConfirm) {
      //   if (isConfirm) {
  
      //   }
      // })
  
    }

    const handleUploadFiles = () => {
      // let link = document.createElement('input');
      // link.setAttribute('type', 'file');
      // link.setAttribute('name', 'uploadFiles');
      // link.setAttribute('multiple', "multiple");
      // document.body.appendChild(link);
      // link.click();
      // link.onchange = function (e) {
      //   // let formData = new FormData();
      //   // formData.append('uploaddoc', e.currentTarget.files[0])
      //   // updateZohoTask(formData)
      //   handleFileAttachments(e.currentTarget.files);
      // }
  
      // document.body.removeChild(link);
    }
    const getBreadCrumbs = (task) => {
      if(!viewTaskData){
       return;
      }
     if(task.TaskId && task.PipelineROWID && false){
       let pipeline=viewTaskData?.Pipeline;
       // let pipeline = allPipelineData.find(e=>e.PipelineROWID==task?.PipelineROWID)
       return <>
       <span style={{position:"relative", top:"2px", fontWeight: "700"}}><FontAwesomeIcon icon={faAngleRight} /></span>
         <span style={{
           backgroundColor: "#5b6c97",
           color: "white"
         }} className='Group1-Card' onClick={() => {
           //  let data = state.AllDeals?.find(e => e.Deal_Name == key);
           //  if (data) {
           //    history.push({
           //      pathname: "/d/Accounts/DetailView/" + data?.Client?.id,
           //      state: {
           //        data: data,
           //        action: "DetailView",
           //        module: "Accounts",
           //      },
           //    })
           //  }
         }}>{pipeline.PipelineName}</span>
        </>
     }else if(task.SubTaskId && task.TaskRowId){
         // let t=allTasksAndSubtasks["Tasks"].get(Number(task.TaskRowId));
         let t=viewTaskData?.Task;
         let pipeline=viewTaskData?.Pipeline;
         // if(t.PipelineROWID){
         //   pipeline = allPipelineData.find(e=>e.PipelineROWID==task?.PipelineROWID)
         // }
       if(!pipeline || !t){
         return<></>
       }
       return <>
       {/* <span style={{position:"relative", top:"2px", fontWeight: "700"}}><FontAwesomeIcon icon={faAngleRight} /></span>
         <span style={{
           backgroundColor: "#5b6c97",
           color: "white"
         }} className='Group1-Card' onClick={() => {
           //  let data = state.AllDeals?.find(e => e.Deal_Name == key);
           //  if (data) {
           //    history.push({
           //      pathname: "/d/Accounts/DetailView/" + data?.Client?.id,
           //      state: {
           //        data: data,
           //        action: "DetailView",
           //        module: "Accounts",
           //      },
           //    })
           //  }
         }}>{pipeline.PipelineName}</span> */}
         <span style={{position:"relative", top:"2px", fontWeight: "700"}}><FontAwesomeIcon icon={faAngleRight} /></span>
         <span style={{
           backgroundColor: "#5b6c97",
           color: "white"
         }} className='Group1-Card' onClick={() => {
           //  let data = state.AllDeals?.find(e => e.Deal_Name == key);
           //  if (data) {
           //    history.push({
           //      pathname: "/d/Accounts/DetailView/" + data?.Client?.id,
           //      state: {
           //        data: data,
           //        action: "DetailView",
           //        module: "Accounts",
           //      },
           //    })
           //  }
         }}>{t.TaskName}</span>  
        </>
     }
 }
  const handleUploadFileWorkDrive = (fileObj) => {
    return new Promise((resolve, reject) => {
      if (relativePathId) {
        var config = {
          Type: "Upload",
          data: {
            filename: fileObj.fileName,
            content: fileObj.file,
            parent_id: relativePathId,
          },
          url: "https://www.zohoapis.com/workdrive/api/v1/upload",
          method: "POST",
          headers: {
            'Authorization': localStorage.getItem('token')
          }
        };
        axios.post(backendUrl + "workdrive", { config: config })
          .then(async function (response) {
            // ActivitieService.AddActivityLocal('Workdrive', { DealId: props.dealObj.Project_ID }, { action_type: 'Upload', data_type: 'File', data: { name: fileObj.fileName, path: relativeFolderId, pathId: relativePathId } })
            // resolve(response)
            // console.log("-------------------------files data-----------------------------------")

          })
          .catch(function (error) {
            reject(error);
          });
      }
    })
  }

  const handleFileAttachments = async (fileList) => {
    let tempFileCollection = [...fileNames];
    let v = fileList.length;
    let index = 0;
    while (index < v) {
      let fileName = fileList[index].name;
      let base64 = await convertToBase64(fileList[index]);
      tempFileCollection.push({
        fileName: fileName,
        file: base64,
      });
      index = index + 1;
    }
    setFileNames(tempFileCollection);
    // let doArr = []
    // let fileUploadArr = []
    // let names = []
    // tempFileCollection.forEach((one) => {
    //   names.push(one.fileName);
    //   doArr.push(addZohoTaskAttachements(props.dealObj.Project_ID, taskData.id_string, one))
    //   fileUploadArr.push(handleUploadFileWorkDrive(one))
    // })

    // let text = `You upload ${tempFileCollection.length} files `
    // let filename = names.join(' , ')
    // swal({
    //   title: "Are you sure?",
    //   text: text + filename,
    //   icon: "warning",
    //   buttons: [
    //     'No',
    //     'Yes'
    //   ],
    //   dangerMode: true,
    // }).then(function (isConfirm) {
    //   if (isConfirm) {
    //     setLoader(true);
    //     Promise.all(doArr)
    //       .then((result) => {
    //         console.log(result);
    //         return Promise.all(fileUploadArr)
    //       })
    //       .then((result) => {
    //         setLoader(false);
    //         alert("file upload successfully");
    //         setAttachmentModal(false)
    //         console.log(result);
    //       })
    //       .catch((err) => {
    //         console.log(err);
    //       })
    //   }
    // })


  };
    ////// Attachment Functions /////

  const taskEmptyTemplate = {
    TaskName: "",
    TaskDescription: "",
    Priority: "Not Set",
    Assignee: "",
    Supervisor: "",
    PipelineROWID: "",
    DueDate: new Date(),
    StartDate:new Date(),
    Account: "",
    Project: "",
    TaskTemplateId: "",
    TaskStatus:1,
    // TaskFlowId:'1',
    TimeEstimate: "",
    SubTasks: [],
    CheckList: [],
    InputFields:[],
    LookUpFields:[],
    TaskFlowObject:null,
  };
  
  const [taskData, setTaskData] = useState({ ...taskEmptyTemplate });
  const { AllUsers } = useContext(AppDataRequired);
  const { DealFields } = useContext(AppDataRequired);
  const [userOptions, setUserOptions] = useState([]);
  const [allAccountsData, setAllAccountsData] = useState([]);
  const [allDealsData, setAllDealsData] = useState([]);
  const [allPipelinesData, setAllPipelinesData] = useState([]);
  const [isDependencyModalOpen, setIsDependencyModalOpen] = useState(false);
  const [subTaskData, setSubTaskData] = useState([]);
  const AppData = useContext(AppContext);
  const { state, dispatch } = AppData || {};
  let allDealsAndUsers = state.AllDeals || [];
  let allAccounts = state.AllAccounts || [];
  const  comment = useRef({ROWID:null,flag:false});
  const [dropdowncount,setDropdownCount]=useState({WaitingOn:0,Blocking:0}); 
  let userinfo = JSON.parse(localStorage.getItem("userinfo"));
  const [selectedDependenciesCache,setSelectedDependenciesCache]=useState({WaitingOn:[], Blocking:[]})
  const [selectedDependencies,setSelectedDependencies]=useState({
    WaitingOn:[],
    Blocking:[]
  }); 
  const [lookUpDeal, setLookUpDeal] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState('');
  useEffect(()=>{
    if(open && !viewTaskData){
      let flowObj= allBlueprintsData?.filter(e=>e.DefaultFlow=="true")[0]; 
      if (flowObj) {
        let status = flowObj.Nodes.find(k=>k.NonSelectable);
        setSelectedBlueprintData({...selectedBlueprintData,data:flowObj})
        let ob={ ...taskData ,TaskStatus:status?.id,TaskFlowId:flowObj?.ROWID, TaskFlowObject:flowObj};
        getBlueprintDataOptions(ob, ob.TaskStatus);
        setTaskData(ob);
      }
    }
  },[open])
  useEffect(e=>{
    if(taskData && dealSelected && allDealsAndUsers){
      let deal = allDealsAndUsers.find(d=> d.Deal_Name == dealSelected);
      setLookUpDeal(deal)
    }
  }, [taskData, allDealsAndUsers, DealFields ,dealSelected])

  useEffect(e=>{
    if(DealFields && DealFields?.length > 0){
      setFilteredFields(DealFields)
    }
  }, [DealFields])
  

  async function handleSaveTask(data) {
    let user = JSON.parse(localStorage.getItem("userinfo"));
    const {
      TaskName,
      Priority,
      StartDate,
      DueDate,
      Assignee,
      Supervisor,
      PipelineROWID,
      TaskDescription,
      TimeEstimate,
      TaskStatus,
      TaskFlowId,
      TaskFlowObject,
      LookUpFields,
      InputFields
    } = data;
    const payload = {
      TaskName,
      Priority,
      StartDate,
      DueDate,
      Assignee,
      Supervisor,
      PipelineROWID,
      TaskDescription,
      TimeEstimate,
      TaskStatus,
      TaskFlowId,
      TaskFlowObject,
      LookUpFields,
      InputFields
    };
    if(!payload.TaskFlowId){
      let flowObj= allBlueprintsData?.filter(e=>e.DefaultFlow=="true")[0];
      payload.TaskFlowId =flowObj?.ROWID;
      payload.TaskFlowObject = flowObj;
    }
    payload["PipelineROWID"] = data.PipelineROWID;
    if(!payload?.TaskName){
      swal('Alert','Task Name can not be empty!','warning');
      return
    }
    if (taskModalState.action == "create") {
      payload["CREATEDBY"] = user.email_id;
      if (selectedTable == "tasks") {
        if (!isSubTaskView) {
          await AwsServerService.saveTask(payload)
            .then(async (res) => {
              setOpen(false);
              const promises = Object.keys(selectedDependencies).map(async (e) => { 
                return Promise.all(selectedDependencies[e].map(async (a) => { 
                  const [type,id]=a.value.split('_');
                  const payload={
                   TargetTaskId:id,
                   STaskType:'Tasks',
                   SourceTaskId:res[0]?.insertId,
                   TaskType:type,
                   DependancyType:e,
                   CREATEDBY:userinfo.email_id,
                   TargetDependencyStatus: data.TargetDependencyStatus,
                   SourceDependencyStatus: data.SourceDependencyStatus
                 } 
                 return AwsServerService.createDependency(payload);
               }));
             }); 
             await Promise.all(promises).then(r=>{
              if(selectedDependencies.Blocking.length>0 || selectedDependencies.WaitingOn.length>0){
               let data={...selectedDependencies}
               let message='USER added ';
               data.WaitingOn?.forEach((e,index)=>{
                if(index==0){
                  message+='Waiting dependency of "'
                }
                message+=`${e.label}`;
                if(index==data.WaitingOn.length-1){
                  message+='" '
                }else{
                  message+=', '
                }
               })
               data.Blocking?.forEach((e,index)=>{
                if(index==0){
                  message+='Blocking dependency of "'
                }
                message+=`${e.label}`;
                if(index==data.Blocking.length-1){
                  message+='"'
                }else{
                  message+=', '
                }
               })
               handleCreateActivity('Tasks',message,res[0]?.insertId);
              }
               setSelectedDependenciesCache(data)
               setIsDependencyModalOpen(false); 
             });
             
             setTaskData({ ...taskEmptyTemplate });
              await Promise.all(
                subTaskData.map((item) => {
                  if (item.SubTaskROWID || !item?.TaskName) {
                    return;
                  }
                  delete item["id"];
                  delete item["flag"];
                  delete item["PipelineROWID"];
                  item["TaskROWID"] = res[0]?.insertId;
                  return AwsServerService.createSubtask(item);
                })
              ).then((allRes) => {
                if(subTaskData.length>0){
                let message=`USER added ${subTaskData.length>1?'subtasks':'subtask'} "`;
                subTaskData?.forEach((r,index)=>{
                  message+=`${r.TaskName}`
                  if(index==subTaskData.length-1){
                    message+='"';
                  }else{
                    message+=', ';
                  }
                })
                handleCreateActivity('Tasks',message,res[0]?.insertId) 
                console.log("allRes", allRes);
              }
              });
            })
            .catch((err) => {});
        } else {
          delete payload["PipelineROWID"];
          await AwsServerService.createSubtask(payload)
            .then(async (res) => {
              setOpen(false);
            })
            .catch((err) => {});
        }
      } else {
        if (!isSubTaskView) {
          await AwsServerService.saveTaskInTemplate(payload)
            .then(async (res) => {
              const promises = Object.keys(selectedDependencies).map(async (e) => { 
                return Promise.all(selectedDependencies[e].map(async (a) => { 
                  const [type,id]=a.value.split('_');
                  const payload={
                   TargetTaskId:id,
                   STaskType:'Tasks',
                   SourceTaskId:res[0]?.insertId,
                   TaskType:type,
                   DependancyType:e,
                   CREATEDBY:userinfo.email_id,
                   TargetDependencyStatus: a.TargetDependencyStatus
                 } 
                 return AwsServerService.createDependency(payload);
               }));
             }); 
             await Promise.all(promises).then(r=>{
              if(selectedDependencies.Blocking.length>0 || selectedDependencies.WaitingOn.length>0){
               let data={...selectedDependencies}
               let message='USER added ';
               data.WaitingOn?.forEach((e,index)=>{
                if(index==0){
                  message+='Waiting dependency of "'
                }
                message+=`${e.label}`;
                if(index==data.WaitingOn.length-1){
                  message+='" '
                }else{
                  message+=', '
                }
               })
               data.Blocking?.forEach((e,index)=>{
                if(index==0){
                  message+='Blocking dependency of "'
                }
                message+=`${e.label}`;
                if(index==data.Blocking.length-1){
                  message+='"'
                }else{
                  message+=', '
                }
               })
               handleCreateActivity('Tasks',message,res[0]?.insertId,true);
              }
               setSelectedDependenciesCache(data)
               setIsDependencyModalOpen(false); 
             });
              console.log("res", res);
              setOpen(false);
              await Promise.all(
                subTaskData.map((item) => {
                  if (item.SubTaskROWID || !item?.TaskName) {
                    return;
                  }
                  delete item["id"];
                  delete item["flag"];
                  delete item["PipelineROWID"];
                  delete item["TaskStatus"];
                  item["TaskROWID"] = res[0]?.insertId;
                  return AwsServerService.createSubtaskInTemplate(item);
                })
              ).then((allRes) => {
                if(subTaskData.length>0){
                  let message=`USER added ${subTaskData.length>1?'subtasks':'subtask'} "`;
                  subTaskData?.forEach((r,index)=>{
                    message+=`${r.TaskName}`
                    if(index==subTaskData.length-1){
                      message+='"';
                    }else{
                      message+=', ';
                    }
                  })
                  handleCreateActivity('Tasks',message,res[0]?.insertId,true) 
                  console.log("allRes", allRes);
                }
                console.log("allRes", allRes);
              }); 
              // setPageRefreshData(!refreshData);
              setTaskData({ ...taskEmptyTemplate });
              
            })
            .catch((err) => {});
        } else {
          delete payload["PipelineROWID"];
          delete payload["TaskStatus"];
          await AwsServerService.createSubtaskInTemplate(payload)
            .then(async (res) => {
              console.log("res", res);
              setOpen(false);
            })
            .catch((err) => {});
        }
      }
    } else if (taskModalState.action == "edit") {
      payload["MODIFIEDBY"] = user.email_id;
      payload["ROWID"] = isSubTaskView
        ? taskData?.SubTaskROWID
        : taskData?.TaskROWID;
      if (selectedTable == "tasks") {
        if (!isSubTaskView) {
          await AwsServerService.updateTask(payload)
            .then(async (res) => { 
              setOpen(false);
              await Promise.all(
                subTaskData.map((item) => {
                  if (item.SubTaskROWID || !item?.TaskName) {
                    return;
                  }
                  delete item["id"];
                  delete item["flag"];
                  delete item["PipelineROWID"];
                  item["TaskROWID"] = taskData?.TaskROWID;
                  return AwsServerService.createSubtask(item);
                })
              ).then((allRes) => {
                console.log("allRes", allRes);
              });
            })
            .catch((err) => {});
        } else {
          delete payload["PipelineROWID"];
          await AwsServerService.updateSubtask(payload)
            .then(async (res) => {
              setOpen(false);
            })
            .catch((err) => {});
        }
      } else {
        if (!isSubTaskView) {
          await AwsServerService.updateTaskInTemplate(payload)
            .then(async (res) => {
              setOpen(false);
              console.log("res", res);
              await Promise.all(
                subTaskData.map((item) => {
                  if (item.SubTaskROWID || !item?.TaskName) {
                    return;
                  }
                  delete item["id"];
                  delete item["flag"];
                  delete item["PipelineROWID"];
                  item["TaskROWID"] = taskData?.TaskROWID;
                  return AwsServerService.createSubtaskInTemplate(item);
                })
              ).then((allRes) => {
                console.log("allRes", allRes);
              });
              // setPageRefreshData(!refreshData);
              
            })
            .catch((err) => {});
        } else {
          delete payload["PipelineROWID"];
          await AwsServerService.updateSubtaskInTemplate(payload)
            .then(async (res) => {
              // console.log("res", res);
              // setPageRefreshData(!refreshData);
              setOpen(false);
            })
            .catch((err) => {});
        }
      }
    }
    setPageRefreshData(prev => !prev);
    setDependencyRefresh(prev => !prev);
    setTaskHistory([]);
    // setLookUpArray([])
    setIsSubTaskView(false);
  } 

  useEffect(e=>{
    if(taskModalState?.action == "create"){
      setSubTaskData([])
    }
  }, [taskModalState?.action])
  
  useEffect(
    (e) => {
      if (isSubTaskView) {
        setActiveTab("2");
      } else {
        setActiveTab(activeTab);
      }
    },
    [isSubTaskView]
  );

  useEffect(e=>{
    if(taskData && allDealsAndUsers && allDealsAndUsers.length>0){
      getBlueprintDataOptions(taskData, taskData.TaskStatus);
    }
  },[dealSelected])

  useEffect((e) => {
    if (viewTaskData && allDealsAndUsers && allDealsAndUsers.length>0) {
      let newObject;
      let pipeline = viewTaskData.Pipeline;
      let task = viewTaskData.Task;
      let subtask = viewTaskData.SubTask;
      getAllDependenciesForTask(subtask?.hasOwnProperty('SubTaskROWID'), subtask?.hasOwnProperty('SubTaskROWID') ? subtask?.SubTaskROWID : task?.TaskROWID);

      let id = '';
      let param = '';
      id = subtask && subtask.SubTaskROWID ? subtask?.SubTaskROWID : task?.TaskROWID
      param = subtask && subtask.SubTaskROWID  ? "SubTask" : "Task"
 
      if (subtask && subtask?.hasOwnProperty("SubTaskId")) {
        //subtask logic...
        let account;
        account = allDealsAndUsers?.filter(e => e.Deal_Name == pipeline?.DealName ? e?.Account_Name : '')
        const compareId = task?.TaskRowId || task?.TaskROWID;
       
        newObject = { ...subtask, PipelineROWID: pipeline?.PipelineROWID }
      
        setDealSelected(pipeline?.DealName);
        setAccountSelected(account[0]?.Account_Name?.name)
 
        let array = [];
        array.push({
          value: pipeline?.PipelineROWID,
          label: pipeline?.PipelineName,
          key: pipeline?.PipelineROWID,
        });
 
        setAllPipelinesData(array);
        let blueprint;
        if (!newObject.TaskFlowObject) {
          blueprint = allBlueprintsData?.find(k => k.ROWID == newObject.TaskFlowId);
        } else {
          blueprint = newObject.TaskFlowObject;
        }
        if (blueprint) {
          getBlueprintDataOptions(newObject, newObject.TaskStatus);
        }
        setTaskData({ ...newObject, TaskFlowObject: blueprint });
        setSelectedBlueprintData({ ...selectedBlueprintData, data: blueprint });
        (newObject?.SubTasks && newObject.SubTasks.length > 0) ? setSubTaskData(newObject?.SubTasks) : setSubTaskData([]);
        checkForCommentsForTask(id,param);
      } else if (task && task?.hasOwnProperty("TaskId")) {
         //task logic...
        let account;
        account = allDealsAndUsers?.filter(e => e.Deal_Name == pipeline?.DealName ? e?.Account_Name : '')
        setDealSelected(pipeline?.DealName);
        setAccountSelected(account[0]?.Account_Name?.name)
 
        let array = [];
        array.push({
          value: pipeline?.PipelineROWID,
          label: pipeline?.PipelineName,
          key: pipeline?.PipelineROWID,
        });
 
        setAllPipelinesData(array);
        let blueprint;
        if (!task.TaskFlowObject) {
          blueprint = allBlueprintsData?.find(k => k.ROWID == task.TaskFlowId);
        } else {
          blueprint = task.TaskFlowObject;
        }
        if (blueprint) {
          getBlueprintDataOptions(task, task.TaskStatus);
        }
        setTaskData({ ...task, TaskFlowObject: blueprint });
        setSelectedBlueprintData({ ...selectedBlueprintData, data: blueprint });
        (task?.SubTasks && task.SubTasks.length > 0) ? setSubTaskData(task?.SubTasks) : setSubTaskData([]);
        checkForCommentsForTask(id,param);
      }
    } else {
      setTaskData({ ...taskEmptyTemplate });
      setAllPipelinesData([]);
      setSubTaskData([])
      setDealSelected();
      setAccountSelected();
      setSelectedDependenciesCache({ WaitingOn: [], Blocking: [] })
      setDropdownCount({ WaitingOn: 0, Blocking: 0 })
    }
  },
    [viewTaskData, dependencyRefresh,allDealsAndUsers]
  );

    useEffect(()=>{ 
    if(selectedTable=='pipelines'){ 
     setFilteredDropdownTasksData(dropdownTasksDataCache);
    }
    },[selectedTable,dropdownTasksDataCache])

  useEffect(
    (e) => {
      if (viewPipelineData && viewPipelineData?.length > 0) {
        // console.log("viewPipelineData", viewPipelineData);
        let temp = { ...taskData };
        temp["PipelineROWID"] = viewPipelineData[0]?.PipelineROWID || viewPipelineData[0]?.ROWID;
        setTaskData(temp);
      }
    },
    [viewPipelineData]
  );
  const checkForCommentsForTask=(ROWID,Type)=>{
    AwsServerService.getCommentsByTaskIdAndType(ROWID,Type).then((r)=>{ 
      if(r.data[0][0]?.Message || r.data[0][0]?.ROWID){
        comment.current = {ROWID:r.data[0][0]?.ROWID,flag:true};
      }else{
        comment.current = {ROWID:null,flag:false};
      }
      setTaskData(task=>({...task,'Comment':r.data[0][0]?.Message}));
    })
  }
  const saveCommentForTask= async ()=>{ 
    let sourceTaskType=taskData?.hasOwnProperty('SubTaskROWID')?"SubTask":"Task";
    let sourceId=taskData?.hasOwnProperty('SubTaskROWID')?taskData?.SubTaskROWID:taskData?.TaskROWID;
    if(comment.current.flag){ 
      let commentPayload = {
        "MODIFIEDBY" : userInfo.email_id,
        "Message" : taskData['Comment'],
        "ROWID" : comment.current.ROWID, 
        "TaskType" : sourceTaskType,
      } 
      await AwsServerService.updateComment(commentPayload).then(r=>{ 
        let message=`USER updated comment to " ${taskData["Comment"]} "`
        handleCreateActivity(sourceTaskType=='Task'?'Tasks':'SubTasks',message,sourceId,selectedTable=='pipelines');
      }).catch(e=>{
        console.log("error in comment", e);                  
      })  
  }else{
    if(taskData['Comment']){
     let commentPayload = {
       "Message" : taskData['Comment'],
       "CREATEDBY" : userInfo.email_id,
       "TaskId" : sourceId,
       "TaskType" : sourceTaskType
     } 
     await AwsServerService.addComment(commentPayload).then(r=>{
       comment.current = {ROWID:r.data[0]?.insertId,flag:true}
       console.log('r',r.data[0]?.insertId,'rrrr',r);
       
       let message=`USER added comment " ${taskData["Comment"]} "`
       handleCreateActivity(sourceTaskType=='Task'?'Tasks':'SubTasks',message,sourceId,selectedTable=='pipelines');
     }).catch(e=>{
       console.log("error in comment", e);                  
     }) 
    }
    }
  }
  function getOptionsForDropdown(data,selected){
    let options=[];
    let selectedData={...selected}
    let type=taskData?.hasOwnProperty('SubTaskROWID')?'SubTasks':'Tasks';
    let id=taskData?.hasOwnProperty('SubTaskROWID')?taskData?.SubTaskROWID:taskData?.TaskROWID;
    const valuesToExclude = new Set([
      ...selectedData.Blocking.map(item => item.value),
      ...selectedData.WaitingOn.map(item => item.value),
      `${type}_${id}`
    ]) 
    console.log("data ......>>>>", data)
    data.filter(e=>!valuesToExclude.has(e.value)?options.push(e):'')
    return options;
  }
  // console.log("statusOptions", statusOptions)
  const getBlueprintDataOptions = async (data,status)=>{
    if(data){
    let currentStatus=status; 
    let node =  data?.TaskFlowObject?.Nodes?.filter(e=>e.id==currentStatus)[0];
    let Edges = data?.TaskFlowObject?.Edges?.filter(e=>!e?.data?.visited && e.source==node?.id); 
    let options = [];
    let optionsStatus = [];
      Edges?.map((item) => {
        if (evaluateEdgeVisibility({ 
          Project: allDealsAndUsers?.find(d=>d.Deal_Name==dealSelected), 
          Task: {...taskData},
          "Input Fields": taskData?.TaskFlowObject?.InputFields
        }, item, data.Edges)) {
          if (item.label) {
            let temp = {
              value: item?.label,
              label: item?.label,
              key: item?.id,
            }
            if (item?.data?.color) {
              temp["color"] = item?.data?.color
            }
            options.push(temp);
          }
        }
      });
      data?.TaskFlowObject?.Nodes?.map((item) => {
      if(item.data?.label){
        let temp={
          value: item?.data?.label,
          label: item?.data?.label,
          key: item?.id,
        }
        if(item.data?.color){
          temp["color"]=item.data.color == "grey" ? "#808080" : item.data.color
        }
        optionsStatus.push(temp);
      }
      }) 
      setMainStatusOptions(optionsStatus);
      setStatusOptions(options) 
  } 
    }
    const getAllDependenciesForTask = (subtask,ROWID) => { 
      if(ROWID==undefined||ROWID==null){
        return
      }
      let dropdowndata = [...dropdownTasksDataCache]; 
      let sourceTaskType=subtask?'SubTasks':'Tasks';
      if(selectedTable=='pipelines'){
        AwsServerService.getTempDependecyByTaskId(ROWID).then(r => {
          const result = r.data[0];
          // setResultFromGetDependency(result);
          if (result.length > 0) { 
            let obj = {
              WaitingOn: new Set(),
              Blocking: new Set()
            }; 
            const dropdownMap = new Map(dropdowndata.map(item => [item.value, item]));
            allPipelineData?.forEach(pipeline => {
              pipeline.Tasks?.forEach(task => {
                const tDataValidate = result.find(e => (e.TargetTaskId == ROWID && e.SourceTaskId==task.TaskROWID && e.STaskType == 'Tasks'));
                let tData=undefined;
                if(tDataValidate){
                tData=tDataValidate;
                }else{
                  tData = result.find(e => (e.TargetTaskId == task.TaskROWID && e.TaskType == 'Tasks'));
                }
                let flag=true;
                if(tData?.SourceTaskId==ROWID){
                  flag=tData.STaskType==sourceTaskType
                }
                if (tData && flag) {
                  const type = (tData.TargetTaskId == ROWID && tData.DependancyType == 'WaitingOn') ? 'Blocking' :
                               (tData.TargetTaskId == ROWID && tData.DependancyType == 'Blocking') ? 'WaitingOn' :
                               tData.DependancyType; 
                  if (tData.TargetTaskId == ROWID) {
                    const tmp = dropdownMap.get(`${tData.STaskType}_${tData.SourceTaskId}`);
                    if (tmp && sourceTaskType === tData.TaskType) {
                      const updatedTmp = { ...tmp, TargetDependencyStatus: tData.SourceDependencyStatus , SourceDependencyStatus:tData.TargetDependencyStatus, ROWID:tData.ROWID};
                        obj[type].add(JSON.stringify(updatedTmp));
                    }
                  } else{
                    obj[type].add(JSON.stringify({ label: task.TaskName, value: `Tasks_${task.TaskROWID}`, TargetDependencyStatus: tData.TargetDependencyStatus , SourceDependencyStatus:tData.SourceDependencyStatus, ROWID:tData.ROWID}));
                  }
                } 
                task.SubTasks?.forEach(subtask => {
                  const tDataValidate = result.find(e => (e.TargetTaskId == ROWID && e.SourceTaskId==subtask.SubTaskROWID && e.STaskType == 'SubTasks'));
                let tData2=undefined;
                if(tDataValidate){
                  tData2=tDataValidate;
                }else{
                  tData2 = result.find(e => (e.TargetTaskId == subtask.SubTaskROWID && e.TaskType == 'SubTasks'));
                } 
                  if (tData2) {
                    const type2 = (tData2.TargetTaskId == ROWID && tData2.DependancyType == 'WaitingOn') ? 'Blocking' :
                                  (tData2.TargetTaskId == ROWID && tData2.DependancyType == 'Blocking') ? 'WaitingOn' :
                                  tData2.DependancyType; 
                    if (tData2.TargetTaskId == ROWID) {
                      const tmp2 = dropdownMap.get(`${tData2.STaskType}_${tData2.SourceTaskId}`);
                    if (tmp2 && sourceTaskType === tData2.TaskType) {
                        // Update TargetDependencyStatus key in tmp
                        const updatedTmp = { ...tmp2, TargetDependencyStatus: tData2.SourceDependencyStatus, SourceDependencyStatus:tData2.TargetDependencyStatus, ROWID:tData2.ROWID };                      
                        // Add the updated object to obj[type]
                        obj[type2].add(JSON.stringify(updatedTmp));
                    }
                    } else {
                      obj[type2].add(JSON.stringify({ label: subtask.TaskName, value: `SubTasks_${subtask.SubTaskROWID}`, TargetDependencyStatus: tData2.TargetDependencyStatus, SourceDependencyStatus:tData2.SourceDependencyStatus, ROWID:tData2.ROWID}));
                    }
                  }
                });
              });
            }); 
            setSelectedDependenciesCache({
              WaitingOn: Array.from(obj.WaitingOn).map(item => JSON.parse(item)),
              Blocking: Array.from(obj.Blocking).map(item => JSON.parse(item))
            });
          } else {
            setSelectedDependenciesCache({ WaitingOn: [], Blocking: [] });
          }
        });
      }else{
      AwsServerService.getDependecyByTaskId(ROWID).then(r => {
        const result = r.data[0];
        if (result.length > 0) { 
          let obj = {
            WaitingOn: new Set(),
            Blocking: new Set()
          }; 
          const dropdownMap = new Map(dropdowndata.map(item => [item.value, item]));
          allPipelineData?.forEach(pipeline => {
            pipeline.Tasks?.forEach(task => {
              const tDataValidate = result.find(e => (e.TargetTaskId == ROWID && e.SourceTaskId==task.TaskROWID && e.STaskType == 'Tasks'));
              let tData=undefined;
              if(tDataValidate){
              tData=tDataValidate;
              }else{
                tData = result.find(e => (e.TargetTaskId == task.TaskROWID && e.TaskType == 'Tasks'));
              }
              let flag=true;
              if(tData?.SourceTaskId==ROWID){
                flag=tData.STaskType==sourceTaskType
              }
              if (tData && flag) {
                const type = (tData.TargetTaskId == ROWID && tData.DependancyType == 'WaitingOn') ? 'Blocking' :
                             (tData.TargetTaskId == ROWID && tData.DependancyType == 'Blocking') ? 'WaitingOn' :
                             tData.DependancyType; 
                if (tData.TargetTaskId == ROWID) {
                  const tmp = dropdownMap.get(`${tData.STaskType}_${tData.SourceTaskId}`);
                    if (tmp && sourceTaskType === tData.TaskType) {
                        const updatedTmp = { ...tmp, TargetDependencyStatus: tData.SourceDependencyStatus , SourceDependencyStatus:tData.TargetDependencyStatus, ROWID:tData.ROWID};
                        obj[type].add(JSON.stringify(updatedTmp));
                    }
                } else{
                  obj[type].add(JSON.stringify({ label: task.TaskName, value: `Tasks_${task.TaskROWID}`, TargetDependencyStatus: tData.TargetDependencyStatus , SourceDependencyStatus:tData.SourceDependencyStatus, ROWID:tData.ROWID}));
                }
              } 
              task.SubTasks?.forEach(subtask => {
                const tDataValidate = result.find(e => (e.TargetTaskId == ROWID && e.SourceTaskId==subtask.SubTaskROWID && e.STaskType == 'SubTasks'));
              let tData2=undefined;
              if(tDataValidate){
                tData2=tDataValidate;
              }else{
                tData2 = result.find(e => (e.TargetTaskId == subtask.SubTaskROWID && e.TaskType == 'SubTasks'));
              } 
                if (tData2) {
                  const type2 = (tData2.TargetTaskId == ROWID && tData2.DependancyType == 'WaitingOn') ? 'Blocking' :
                                (tData2.TargetTaskId == ROWID && tData2.DependancyType == 'Blocking') ? 'WaitingOn' :
                                tData2.DependancyType; 
                  if (tData2.TargetTaskId == ROWID) {
                    // const tmp2 = dropdownMap.get(`${tData2.STaskType}_${tData2.SourceTaskId}`);
                    const tmp2 = dropdownMap.get(`${tData2.STaskType}_${tData2.SourceTaskId}`);
                    if (tmp2 && sourceTaskType === tData2.TaskType) {
                        const updatedTmp = { ...tmp2, TargetDependencyStatus: tData2.SourceDependencyStatus, SourceDependencyStatus:tData2.TargetDependencyStatus, ROWID:tData2.ROWID };
                        obj[type2].add(JSON.stringify(updatedTmp));
                    }
                  } else {
                    obj[type2].add(JSON.stringify({ label: subtask.TaskName, value: `SubTasks_${subtask.SubTaskROWID}`, TargetDependencyStatus: tData2.TargetDependencyStatus, SourceDependencyStatus:tData2.SourceDependencyStatus, ROWID:tData2.ROWID}));
                  }
                }
              });
            });
          }); 
          setSelectedDependenciesCache({
            WaitingOn: Array.from(obj.WaitingOn).map(item => JSON.parse(item)),
            Blocking: Array.from(obj.Blocking).map(item => JSON.parse(item))
          });
        } else {
          setSelectedDependenciesCache({ WaitingOn: [], Blocking: [] });
        }
      }); 
    }
  };
  
  useEffect(()=>{
    let data={...selectedDependenciesCache}
    setSelectedDependencies(data);
  },[selectedDependenciesCache])

  useEffect(() => {
    if (allAccounts) {
      let array = [];
      const filteredAccounts = allAccounts.filter((item) => item?.Account_Name !== "All");
      filteredAccounts.forEach((item) => {
        array.push({
          value: item?.Account_Name,
          label: item?.Account_Name,
          key: item?.id,
        });
      });
      array.unshift({
        value: "Miscellaneous",
        label: "Miscellaneous",
        key: "Miscellaneous",
      });
  
      setAllAccountsData(array);
    }
  }, []);
  

  useEffect(
    (e) => {
      let array = [];
      allDealsAndUsers = allDealsAndUsers?.filter(
        (item) =>
          item.hasOwnProperty("Created_Time") &&
          item?.Account_Name?.name == accountSelected
      );
      allDealsAndUsers?.map((item) => {
        array.push({
          value: item?.Deal_Name,
          label: item?.Deal_Name,
          key: item?.id,
        });
      });
      setAllDealsData(array);
    },
    [accountSelected]
  );

  useEffect(() => {
    if (dealSelected) {
      AwsServerService.getPipelineDataByDeal(dealSelected)
        .then((res) => {
          let array = [];
          res[0]?.map((item) => {
            array.push({
              value: item?.PipelineROWID,
              label: item?.PipelineName,
              key: item?.PipelineROWID,
            });
          });
          setAllPipelinesData(array);
          let data=res[0];
          let result =[]; 
          if (data) {
            for (let i = 0; i < data.length; i++) {
              let pipeline = data[i];
              if (pipeline.Tasks) {
                for (let j = 0; j < pipeline.Tasks.length; j++) {
                  let task = pipeline.Tasks[j];
                  let statusColor = "#808080";  
                  if (task.TaskFlowObject) {
                    let filterData = task.TaskFlowObject.Nodes.find(e => e.data.label == task.TaskStatus);
                    statusColor = filterData && filterData?.data?.color ? filterData.data.color : statusColor;
                  } 
                  result.push({
                    label: task.TaskName,
                    value: `Tasks_${task.TaskROWID}`,
                    status: task.TaskFlowObject?.Nodes?.find(k=>k.id==task.TaskStatus)?.data?.label,
                    TaskStatus: task.TaskStatus,
                    statusColor: statusColor == "grey" || !statusColor ? "#808080" : statusColor,
                    taskData: task
                  }); 
                  if (task.SubTasks) {
                    for (let k = 0; k < task.SubTasks.length; k++) {
                      let subtask = task.SubTasks[k];
                      if (subtask.TaskFlowObject) {
                        let filterData = subtask.TaskFlowObject.Nodes.find(e => e.data.label == subtask.TaskStatus);
                        statusColor = filterData && filterData?.data?.color ? filterData.data.color : statusColor;
                      } 
                      result.push({
                        label: subtask.TaskName,
                        value: `SubTasks_${subtask.SubTaskROWID}`,
                        status: subtask.TaskFlowObject?.Nodes?.find(k=>k.id==subtask.TaskStatus)?.data?.label,
                        TaskStatus: subtask.TaskStatus,
                        statusColor: statusColor == "grey" || !statusColor ? "#808080" : statusColor,
                        taskData: subtask
                      });
                    } } } } } } 
                  setFilteredDropdownTasksData(result);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      setAllPipelinesData([]);
    }
  }, [dealSelected]);

  useEffect(() => {
    if (viewPipelineData && viewPipelineData?.length > 0) {
      let array = [];
      array.push({
        value: viewPipelineData[0]?.PipelineROWID,
        label: viewPipelineData[0]?.PipelineName,
        key: viewPipelineData[0]?.PipelineROWID,
      });
      setAllPipelinesData(array);
    } else {
      setAllPipelinesData([]);
    }
  }, [viewPipelineData]);

  const activitySections = [
    {
      key: "Activity",
      open: true,
      icon: <FontAwesomeIcon icon={faCommentAlt} />,
      component: (taskId, flag, flag2) => {
        // let module=flag2? flag ? "SUBTASKTEMPLATE" :"TASKTEMPLATE"
        return (
          <ActivitySection taskId={taskId} module={(flag2 && flag) ? "SUBTASKTEMPLATE" :
            (flag2 && !flag) ? "TASKTEMPLATE" :
            (!flag2 && flag) ? "SUBTASK" : "TASK"} 
            refreshData={refreshData}/>
        );
      },
    },
    {
      key: "Settings",
      open: false,
      icon: <img src={SettingIconDark} alt="SettingIcon" style={{width:"17px"}}/>,
      component: () => <></>,
    },
  ];


  ///// new changes for settings options ////

  const defaultDuplicateObject = {
    TaskName: '',
    Everything: true, 
    // Attachements: true, Comments: true, InputFields: true,LookUpFields: true, Dependencies: true, 
    StartDate: true, DueDate: true, Assignee: true, Supervisor:true,
    TimeEstimate: true, Priority: true, 
    // Status: true, 
    Subtask: true,
  }

  const [popoverVisible, setPopoverVisible] = useState(false); // State for controlling Popover visibility
  const [duplicateValueObj, setDuplicateValueObj] = useState({...defaultDuplicateObject});
  const [duplicatePopUp, setDuplicatePopUp] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  
  const handleOnChangeDuplicateCheckBox = (e) => {
    const { name, checked } = e.target;
    const updatedObj = { ...duplicateValueObj };
    if (name === 'TaskName') {
        updatedObj[name] = e.target.value; // Handle TaskName input change
    } else if (name === 'Everything') {
      // Select/Deselect all checkboxes when 'Everything' is clicked
      const newValue = checked;
      Object.keys(updatedObj).forEach((key) => {
        if (key !== 'TaskName') {
          updatedObj[key] = newValue;
        }
      });
    } else {
      // Handle individual checkbox changes
      updatedObj[name] = checked;
    }
  
    // Check if all checkboxes (except 'TaskName' and 'Everything') are selected
    const allChecked = Object.keys(updatedObj).every(
      (key) => key === 'TaskName' || key === 'Everything' || updatedObj[key]
    );
  
    // Check if at least one checkbox (other than 'TaskName') is selected but not all
    const someChecked = Object.keys(updatedObj).some(
      (key) => key !== 'TaskName' && key !== 'Everything' && updatedObj[key]
    );
  
    // Update the 'Everything' checkbox state
    updatedObj['Everything'] = allChecked;
    setIndeterminate(someChecked && !allChecked); // Set indeterminate if some are checked but not all
  
    setDuplicateValueObj(updatedObj);
  };

  const hanleCreateDuplicateTask = async(duplicateFields, data) =>{
    let duplicateObj = { ...duplicateFields };
    let duplicateTaskObj = { ...data };
    if (!duplicateObj.Priority) {
      duplicateTaskObj.Priority = "Not Set"
    }
    if (!duplicateObj.Assignee) {
      duplicateTaskObj.Assignee = '';
    }
    if (!duplicateObj.DueDate) {
      duplicateTaskObj.DueDate = new Date();
    }
    if (!duplicateObj.Supervisor) {
      duplicateTaskObj.Supervisor = ''
    }
    if (!duplicateObj.StartDate) {
     duplicateTaskObj.StartDate = new Date()
    }
      delete duplicateTaskObj?.TaskROWID
      delete duplicateTaskObj?.SubTasks
      delete duplicateTaskObj?.TaskTemplateId
      delete duplicateTaskObj?.TaskId
      delete duplicateTaskObj?.MilestoneName
      delete duplicateTaskObj?.IsMilestone
      delete duplicateTaskObj?.deps
      duplicateTaskObj.TaskName = duplicateValueObj.TaskName
      await AwsServerService.saveTask(duplicateTaskObj).then(async res=>{
        if(duplicateObj.Subtask && data.SubTasks?.length > 0){
          await Promise.all(
            data.SubTasks.map((item) => {
              delete item["id"];
              delete item["flag"];
              delete item["PipelineROWID"];
              delete item["SubTaskROWID"];
              delete item["TaskRowId"];
              delete item["SubTaskId"];
              delete item["SubTaskTemplateId"];
              item["TaskROWID"] = res[0]?.insertId;
              item["TaskName"] = `${item["TaskName"]} - Copy`;
              return AwsServerService.createSubtask(item);
            })
          ).then((allRes) => {
            console.log("allRes dupl", allRes);
          });
        }
        setDuplicateValueObj({...defaultDuplicateObject})
        setTaskData({...taskEmptyTemplate})
        setPageRefreshData(prev => !prev)
        setDuplicatePopUp(prev => !prev)
        setOpen(false)
      })
  }

  const renderDuplicateModal = () => {
    return (
      <AntModal
        title="Duplicate Task"
        open={duplicatePopUp}
        onCancel={()=>{setDuplicatePopUp(false)}}
        footer={null}
        className="taskComplete-warning duplicate-task-modal"
      >
        <Row>
          <Col sm={12}>
            <h5 className='text-left'>New Task Name</h5>
            <Input
              onChange={(e)=>{
                const trimmedValue = e.target.value.trimStart(); // Remove leading whitespace
                  e.target.value = trimmedValue;

                  if (trimmedValue) {
                    handleOnChangeDuplicateCheckBox(e);
                  }else{
                    e.target.value = '';
                    handleOnChangeDuplicateCheckBox(e);
                  }
              }}
              defaultValue={duplicateValueObj.TaskName}
              value={duplicateValueObj.TaskName}
              name='TaskName'
              placeholder='New task name'
              className='form-control form-control-sm duplicate-taskname'
            />
          </Col>
        </Row>
  
        <div className='duplicate-task-box' style={{ padding: '20px 15px' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <p style={{ fontSize: 16 }} className='small text-left'>
              What do you want to copy?
            </p>

            <Checkbox
              onChange={handleOnChangeDuplicateCheckBox}
              checked={duplicateValueObj.Everything}
              indeterminate={indeterminate} // Add indeterminate state
              name='Everything'
              style={{width:"max-content"}}
            >
              Everything
            </Checkbox>

            <Checkbox
              onChange={handleOnChangeDuplicateCheckBox}
              checked={duplicateValueObj.Supervisor}
              name='Supervisor'
              style={{width:"max-content"}}
            >
              Supervisor
            </Checkbox>

            <Checkbox
              onChange={handleOnChangeDuplicateCheckBox}
              checked={duplicateValueObj.Assignee}
              name='Assignee'
              style={{width:"max-content"}}
            >
              Assignee
            </Checkbox>

            <Checkbox
              onChange={handleOnChangeDuplicateCheckBox}
              checked={duplicateValueObj.Priority}
              name='Priority'
              style={{width:"max-content"}}
            >
              Priority
            </Checkbox>

            <Checkbox
              onChange={handleOnChangeDuplicateCheckBox}
              checked={duplicateValueObj.TimeEstimate}
              name='TimeEstimate'
              style={{width:"max-content"}}
            >
              Time Estimate
            </Checkbox>

            <Checkbox
              onChange={handleOnChangeDuplicateCheckBox}
              checked={duplicateValueObj.StartDate}
              name='StartDate'
              style={{width:"max-content"}}
            >
              Start Date
            </Checkbox>

            <Checkbox
              onChange={handleOnChangeDuplicateCheckBox}
              checked={duplicateValueObj.DueDate}
              name='DueDate'
              style={{width:"max-content"}}
            >
              Due Date
            </Checkbox>
            <Checkbox
              onChange={handleOnChangeDuplicateCheckBox}
              checked={duplicateValueObj.Subtask}
              name='Subtask'
              style={{width:"max-content"}}
            >
              Subtask
            </Checkbox>
          </div>
        </div>
  
        <Row>
          <Col sm={12} style={{ textAlign: 'right' }}>
            <Button
              type='primary'
              onClick={async () => {
                if(duplicateValueObj?.TaskName){
                  hanleCreateDuplicateTask(duplicateValueObj, taskData)
                }else{
                  swal('Alert','Task Name can not be empty!','warning');
                }
              }}
            >
              Duplicate Task
            </Button>
          </Col>
        </Row>
      </AntModal>
    );
  };

  // Function to handle option selection
  const handleOptionSelect = (option) => {
    const parent = { ...taskData };
    if (option && option.value == 'Duplicate') {

      let duplicate = {
        TaskName: '',
        Everything: true, 
        // Attachements: true, Checklist: true, Comments: true, InputFields: true,
        // LookUpFields: true, Dependencies: true, 
        StartDate: true, DueDate: true, Assignee: true,Supervisor:true,
        TimeEstimate: true, Priority: true, 
        // Status: true, 
        Subtask: true
      };
      duplicate.TaskName = parent.TaskName + ' - Copy'
      setDuplicateValueObj(duplicate);
      setDuplicatePopUp(true);

    }
    if (option && option.value == 'Template') {
      // setTemplatePopUp(true)
      // setServiceRequested(serviceReqVariable);
      // duplicate.TaskName = parent.name + ' - Template';
      // setDuplicateValueObj(duplicate);
    }
    if (option && option.value == 'Copy') {
      // getLinkOfTaskModal(parent)
    }
    if (option && option.value == 'Path') {
      // setRelativeModal(true);
    }
    
    console.log(`Selected option: ${option.value}`);

    setPopoverVisible(false); // Close popover after selection
    // Add your handling logic here
  };

  // Options for the settings popover
  const settingsOptions = [
    { label: "Duplicate Task", value: "Duplicate" },
    { label: "Save as Template", value: "Template" },
    { label: "Copy Link", value: "Link" },
    { label: "Add Relative Path", value: "Path" },
  ];

  const renderSettingsPopoverContent = () => (
    <div>
      {taskModalState.action !== "create" && settingsOptions.map((option) => (
        <Button
          key={option.value}
          style={{ width: "100%"}}
          className="Duplicate-modal-btns"
          onClick={(e) => { 
            if (taskModalState.action === "create") {
              e.preventDefault();
              return;
            }
            handleOptionSelect(option)
          }}
        >
          {option.label}
        </Button>
      ))}
    </div>
  );

  //////////// End ////////////

  const [sidebarOptions, setSidebarOptions] = useState([...activitySections]);
  const [activitySection, setActivitySection] = useState({
    key: "Activity",
    open: true,
    icon: <FontAwesomeIcon icon={faCommentAlt} />,
    component: (taskId, flag, flag2) => {
      // let module=flag2? flag ? "SUBTASKTEMPLATE" :"TASKTEMPLATE"
      return (
        <ActivitySection taskId={taskId} module={(flag2 && flag) ? "SUBTASKTEMPLATE" :
          (flag2 && !flag) ? "TASKTEMPLATE" :
          (!flag2 && flag) ? "SUBTASK" : "TASK"} 
          refreshData={refreshData}/>
      );
    },
  });
  const [selectionRange, setSelectionRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);
  useEffect((e) => {
      if (selectionRange[0]?.startDate && selectionRange[0]?.endDate) {
        let temp = { ...taskData };
        temp["StartDate"] = convertToUSTime(selectionRange[0]?.startDate);
        temp["DueDate"] = convertToUSTime(selectionRange[0]?.endDate);
        setTaskData(temp);
      }
    },
    [selectionRange]
  );

  useEffect(() => {
    let temp = { ...taskData };
  
    // Only set dates if they are missing
    if (!temp.StartDate || !temp.DueDate) {
      if (!temp.StartDate) {
        temp["StartDate"] = convertToUSTime(new Date());
      }
      if (!temp.DueDate) {
        temp["DueDate"] = convertToUSTime(new Date());
      }
      setTaskData(temp);
    }
  }, [taskData]);
  

  useEffect(
    (e) => {
      if (AllUsers && AllUsers.length > 0) {
        let arr = AllUsers.map((d) => {
          return {
            label: d.full_name,
            value: d.zuid,
            subvalue: `${d.first_name[0]?.toUpperCase()}${d.last_name[0]?.toUpperCase()}`,
          };
        });
        setUserOptions(arr);
      }
    },
    [AllUsers]
  );
  const changeDateRange = (item) => { 
    // Update the selection range
    setSelectionRange([item.selection]);
  };
  useEffect(e=>{
    if(statusOptions){
      let modifiedEdgesAndNodes=modifyEdgesAndNodes(taskData?.TaskFlowObject?.Edges,taskData?.TaskFlowObject?.Nodes,statusOptions.map(k=>k.key))
      let obj={...taskData};
      if(modifiedEdgesAndNodes && modifiedEdgesAndNodes.updatedNodes && modifiedEdgesAndNodes.updatedEdges){
        obj={
          ...obj,Nodes:modifiedEdgesAndNodes.updatedNodes,Edges:modifiedEdgesAndNodes.updatedEdges
        }
      }
      setTaskData(obj)
    }
  },[statusOptions])
  // console.log('filtered',filteredDropdownTasksData,selectedDependencies.WaitingOn);
  
  const handleTransitionChange =async  (e, data) => {
    let waitingDeps=[...selectedDependenciesCache.WaitingOn];
    let data2=[...filteredDropdownTasksData]; 
    let ar=[];  
    let key = statusOptions?.find(key => key.label == e)
    let edge = data?.Edges?.filter(res => res.id == key.key)[0];
    data = {
      ...data, Edges: processEdgeConnections(edge,data.Edges)
    }
    data = {
      ...data, Nodes: updateNodeVisitedStatus(data.Nodes,data.Edges)
    }

    let nextNode = data?.Nodes?.filter(res => res.id == edge.target)[0];
    // let status = nextNode?.data?.label;
    let status = nextNode?.id;
    let flag=false; 
    let deps= waitingDeps.filter(er=>er.SourceDependencyStatus==status); 
    let depsData=data2.filter(r=>deps.some((a)=>a.value==r.value));
    depsData.forEach(er=>{
        let record=deps.find((a)=>a.TargetDependencyStatus==er.taskData.TaskStatus);
        if(record){
          ar.push(record);
        }
        if(deps.some((a)=>a.TargetDependencyStatus!==er.taskData.TaskStatus)){
          flag=true;
        }
       }) 
    if(flag){
      swal('Warning','Cant move forward','Alert')
      return
    }else if(ar.length>0){
      await Promise.all(
        ar.map((item) => { 
          let payload={ROWID:item.ROWID,IsResolved:1}
          AwsServerService.updateDependency(payload);
        })
      ) 
    }
    let temp={ ...taskData, TaskFlowObject: data, 'TaskStatus': status }
    setTaskData(temp);
    if(taskData.hasOwnProperty('SubTaskROWID')){
      await AwsServerService.updateTask({TaskStatus:status,ROWID:taskData.SubTaskROWID})
    }else{
      await AwsServerService.updateTask({TaskStatus:status,ROWID:taskData.TaskROWID}).then(async (res)=>{
        let blockingDeps=[...selectedDependenciesCache.Blocking];
       //  let data=[...filteredDropdownTasksData]; 
        let array=[] 
        let deps= blockingDeps.filter(er=>er.TargetDependencyStatus==status); 
       //  let depsData=data.filter(r=>deps.some((a)=>a.value==r.value));
        await Promise.all(
         deps.map((item) => {
           let payload={ROWID:item.ROWID,IsResolved:1}
           AwsServerService.updateDependency(payload);
         })
       ).then((resolve) => {
         console.log(resolve); 
       });
       
      })
    }
    setSelectedBlueprintData({...selectedBlueprintData,data:data})
    getBlueprintDataOptions(temp, temp.TaskStatus);
  }
  const handleDropDownChange = (e, data) => {
    if (data == "Account") {
      setAccountSelected(e);
      setDealSelected();
    } else if (data == "Project") {
      setDealSelected(e);
    } else {
      
      let temp = { ...taskData };
      if(data=="TaskFlowId"){
        let bluePrint = allBlueprintsData?.find(d=>d.ROWID==e);
        if(bluePrint){
          temp["TaskFlowObject"] = bluePrint;
          setSelectedBlueprintData({...selectedBlueprintData,data:bluePrint})
          temp["TaskStatus"] = bluePrint?.Nodes?.find(k=>k.NonSelectable)?.id;
        }
        getBlueprintDataOptions(temp, temp.TaskStatus);
      }
      
      temp[data] = e;
      setTaskData(temp);
    }
  };
  const getAntSelectDropDown = (data) => {
    let options = data == "Project" ? allDealsData : allAccountsData;
    return (
      <Select
        disabled={
          (data == "Project" && accountSelected == "Miscellaneous") ||
          ((data == "Project" || data == "Account") && createPiplineModalState)|| taskModalState.action == "edit"
        }
        showSearch
        size={"small"}
        className="form-control"
        popupClassName={
          data == "Priority"
            ? "dropdown-create-task"
            : "dropdown-create-task header-dropdowns"
        }
        placeholder={data == "Account" ? "Select Account" : "Select Project"}
        filterOption={(input, option) =>
          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
        }
        onChange={(e) => {
          handleDropDownChange(e, data);
        }}
        value={
          data == "Account"
            ? accountSelected
            : data == "Project"
            ? dealSelected
            : taskData[data]
        }
        options={options}
        suffixIcon={<CaretDownFilled />}
      />
    );
  };
  useEffect(e=>{
    if(allBlueprintsData){
      let arr = allBlueprintsData?.map((item) => {
        return ({
          value: item?.ROWID,
          label: item?.Name,
          // key: item?.Name,
        });
      })
      setBluePrintDropDownOptions(arr);
    }
  },[allBlueprintsData])

  const getBlueprintsDropDown = (data) => {
    
    return (
      <Select
        showSearch
        size={"small"}
        className="form-control"
        popupClassName={"dropdown-create-task header-dropdowns" }
        placeholder={"Select blueprint"}
        filterOption={(input, option) =>
          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
        }
        onChange={(e) => {
          handleDropDownChange(e, data);
        }}
        value={taskData[data]}
        options={BluePrintDropDownOptions}
        suffixIcon={<CaretDownFilled />}
      />
    );
  };
  const checkWaitingDependencies = () => {
    let flag = false;
    let data = { ...selectedDependenciesCache };

    // Create a map of waitingOnTaskIds with TargetDependencyStatus
    const waitingOnTaskIdsWithStatus = data.WaitingOn.map(item => ({
        id: item.value,
        TargetDependencyStatus: item.TargetDependencyStatus,
        SourceDependencyStatus : item.SourceDependencyStatus
    }));

    let filteredDeps = [];
    for (const el of waitingOnTaskIdsWithStatus) {
        let type = el.id.split('_');
        let taskObject = allTasksAndSubtasks[type[0]].get(Number(type[1]));
        console.log(":Taskadatatatataat", taskObject)
        // Filter Edges based on both visited status and matching TargetDependencyStatus
        let Nodes = taskObject?.TaskFlowObject?.Nodes?.filter(e => 
             !e?.data?.visited
        ).filter(item=> item.data.label == el.TargetDependencyStatus) || [];
        // Proceed only if Edges with matching TargetDependencyStatus are found
        if (taskObject && Nodes && Nodes.length > 0) {
            taskObject.TargetDependencyStatus = el.TargetDependencyStatus; // Add the TargetDependencyStatus key directly
            filteredDeps.push(taskObject);
            flag = true;
        }
    }

    return { flag: flag, data: filteredDeps };
};

  const onChangeTransion= (e) => {
    setSelectedButton(e);
    //     let result=checkWaitingDependencies(); 
    //     if (result.flag) {
    //       const taskItems = result.data.map(task => {
    //         const type = task.hasOwnProperty('SubTaskROWID') ? 'SubTasks' : 'Tasks';
    //         const id = type === 'Tasks' ? task.TaskROWID : task.SubTaskROWID;
    //         const status = task?.TaskFlowObject?.Nodes?.find(k=>k.id==task.TaskStatus?task.TaskStatus:"1")?.data?.label;
    //         let statusColor = "#808080";
    //         if(task.TaskFlowObject){
    //           let filterData = task.TaskFlowObject.Nodes.find(e => e.data.label == task.TaskStatus);
    //           statusColor = filterData ? filterData.data.color : statusColor;
    //         }
            
    //         return (
    //           <Row className="task-item" key={id} id={`task_${id}`}>
    //             <Col sm={6} className="task-name-col">
    //               <span className="task-name Elipsis-Class" id={`${type}_${id}`}>{task.TaskName}</span>
    //             </Col>
    //             <Col sm={2} className="task-status-col" style={{padding: "0px"}}>
    //               <Tooltip color="rgb(54 54 54)" placement="left" title={status}>
    //                 <span
    //                   className="task-status Elipsis-Class"
    //                   style={{
    //                     backgroundColor: statusColor === "grey" || !statusColor ? "#808080" : statusColor,
    //                     color: invertColor(statusColor === "grey" || !statusColor ? "#808080" : statusColor, true)
    //                   }}
    //                 >
    //                   {status}
    //                 </span>
    //               </Tooltip>
    //             </Col>
    //             <Col sm={2} className="task-status-col" style={{padding: "0px"}}>
    //               <Tooltip color="rgb(54 54 54)" placement="left" title={status}>
    //                 <span
    //                   className="task-status Elipsis-Class"
    //                   style={{
    //                     backgroundColor: statusColor === "grey" || !statusColor ? "#808080" : statusColor,
    //                     color: invertColor(statusColor === "grey" || !statusColor ? "#808080" : statusColor, true)
    //                   }}
    //                 >
    //                   {task.TargetDependencyStatus}
    //                 </span>
    //               </Tooltip>
    //             </Col>
    //             <Col sm={2} className="remove-icon-col" style={{textAlign: "center"}}>
    //               <span className="remove-icon" onClick={()=>{handleDeleteDependency({value : `${type}_${id}`})}}>
    //                <FontAwesomeIcon icon={faTrashAlt} style={{color: "red", opacity:0.9}}/>
    //               </span>
    //             </Col>
    //           </Row>
    //         );
    //       });
        
    //       const content = (
    //         <div className="task-list">
    //           <p className="task-message-heading">This task is waiting on the following tasks:</p>
    //           <Row className="task-header-row" style={{ fontWeight: 'bold', paddingBottom: '10px' , color: "#5a6c97"}}>
    //             <Col sm={6} className="task-name-col">
    //                 Task Name
    //             </Col>
    //             <Col sm={2} className="task-status-col Elipsis-Class">
    //                 Status
    //             </Col>
    //             <Col sm={2} className="task-status-col Elipsis-Class">
    //                 Dependency Status
    //             </Col>
    //             <Col sm={2} className="remove-icon-col" style={{ textAlign: "center" }}>
    //                 Actions
    //             </Col>
    //           </Row>
    //           <div className="dep-task-container">
    //             {taskItems}
    //           </div>
    //         </div>
    //       );
            
    //       setModalContent(content);
    //       setIsModalVisible(true);
    // }else{
      handleTransitionChange(e, taskData?.TaskFlowObject);
    // }
    const taskNameElements = document.querySelectorAll('.swalDiv'); 
      taskNameElements.forEach(taskNameElement => {
          taskNameElement.addEventListener('click', (e) => {
              swal.close();  
              // let data2 = result.data.find(task => task === e.currentTarget.dataset.task); 
              let data2 = e.currentTarget.dataset.task;   
              // let tasksData = { ...allTasksAndSubtasks };
              let step=data2.split('_'); 
                  // const newData = tasksData[step[0]].get(Number(step[1]));
                  if(Number(step[1])){
                    setLoader(true);
                    if(step[0]=='Tasks'){
                      AwsServerService.getTaskById(Number(step[1])).then(res=>{
                        let k=res[0]?.taskInfo;
                        if(k){
                          handleHistoryAdd()
                          viewTaskFunction(k,"TASK");
                          setLoader(false);
                          // setIsSubTaskView(true);
                        }
                      }).catch(e=>{
                        setLoader(false);
                      })
                    }else{
                      AwsServerService.getSubTaskBySubTaskId(Number(step[1])).then(res=>{
                        let k=res[0]?.taskInfo;
                        if(k){
                          handleHistoryAdd()
                          viewTaskFunction(k,"SUBTASK");
                          setLoader(false);
                          // setIsSubTaskView(true);
                        }
                      }).catch(e=>{
                        setLoader(false);
                      })
                    }
                  }  
          });
    }) 
    
  }

  const handleConfirm = () => {
    handleTransitionChange(selectedButton, taskData?.TaskFlowObject)
    setPopConfirmVisible(false);
  }
  const handleCancel = () => {
    setPopConfirmVisible(false)
  }

  const showWarningModal = () =>{
    return (
      <AntModal
        title={<span style={{ fontSize: '18px', fontWeight: 'bold', color: '#5a6c97' }}>Waiting on Dependencies</span>}
        open={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        wrapClassName="Warning-Modal-Popup"
        footer={[<>
          <Popconfirm
          className="Do-Not-Close"
          overlayClassName="Do-Not-Close"
          open={popConfirmVisible}
          onConfirm={() => {
            handleConfirm();
            setTimeout(() => setIsModalVisible(false), 300); // Delay modal close to let Popconfirm update
          }}
          onCancel={handleCancel}
          okText="Yes"
          cancelText="No"
          title="Confirmation"
          description="Are you sure you want to move ahead?"
          icon={<></>}
          >
            <Button key="ignore" size="middle" onClick={()=>{
                setPopConfirmVisible(true)
              }}
              >
              Ignore
            </Button>
          </Popconfirm>
          <Button key="ok" size="middle" type="primary" onClick={handleModalOk}>
            OK
          </Button>,
          </>]}
      >
        {modalContent}
      </AntModal>
    )
  }

  const handleModalOk = () => {
    setIsModalVisible(false);
  };
  
  const handleModalCancel = () => {
    setIsModalVisible(false);
  };
  
  const getStyleObj=(status)=>{
    let temp=mainStatusOptions?.find(k=>k.label==status);
    let sty={
      backgroundColor:"white",
      color:"black",
      border:"1px solid white"
    }
    if(temp && temp.color){
      sty["backgroundColor"]=temp.color;
      sty["border"]= `1px solid ${temp.color}`
      sty["color"]= invertColor(temp.color,true)
    }
    return sty;
  }
  const getStatusDropdown=(data,listtype) => {

    // let arr=[];
    // if(listtype=="reject"){
    //   arr=statusOptions?.filter(one=>one.color=="red")
    // }else{
    //   arr=statusOptions?.filter(one=>one.color!="red")
    // }
    const content = (
      <div className="Task-Status-Div">
        {mainStatusOptions?.map((one) => {
          return (
            <p
              style={getStyleObj(one.label)}
              className="Task-Status-P"
              onClick={() => {
                onChangeTransion(one.label)
              }}
            >
              {one.label}
            </p>
          );
        })}
      </div>
    );
    if(mainStatusOptions.length==0){
      return (<Button
          type="primary"
          disabled={true}
          style={{
            opacity:0.5,
            backgroundColor: "#1677ff",
            borderColor: "#1677ff",
            color:"white",
          }}
          // icon={<CheckCircleOutlined size={24} style={{ height: "1rem" }} />}
          size="middle"
        >No Task Flow</Button>)
    }
    return (
      <Popover
        style={{ zIndex: 9999 }}
        // open={true}
        onOpenChange={(e) => {
          // setIsOpen(!isOpen);
        }}
        trigger="click"
        // overlayClassName={"Prject-Status-Container" }
        placement="bottomLeft"
        content={content}
        arrow={false}
      >
        <Button
          type="primary"
          style={getStyleObj(taskData.TaskStatus)}
          // icon={<CheckCircleOutlined size={24} style={{ height: "1rem" }} />}
          size="middle"
        >{taskData.TaskStatus}</Button>
      </Popover>
    )
    
  }
  const getBlueprintsStatuses = (data,listtype) => {

    let arr=[];
    if(listtype=="reject"){
      arr=statusOptions?.filter(one=>one.color=="red")
    }else{
      arr=statusOptions?.filter(one=>one.color!="red")
    }
    const content = (
      <div>
        {arr?.map((one) => {
          return (
            <p
              className="Task-Status-P"
              style={getStyleObj()}
              onClick={() => {
                // onSelect(one);
                onChangeTransion(one.label)
                // setSelected(!selected);
              }}
              // style={{ backgroundColor: one.color, color: one.color ? invertColor(one.color, true) : "#fff", fontWeight:"600" }}
            >
              {one.label}
            </p>
          );
        })}
      </div>
    );
    if(arr.length==0){
      return (
        <Button
          type="primary"
          disabled={true}
          style={{
            opacity:0.5,
            backgroundColor: listtype === "reject" ? "red" : "#1677ff",
            borderColor: listtype === "reject" ? "red" : "#1677ff",
          }}
          icon={ listtype === "reject"?<FontAwesomeIcon color="white" icon={faTimes} />:<FontAwesomeIcon color="white" icon={faCheck} />}
          size="middle"
        ></Button>
      )
    }
    return (
      <Popover
        style={{ zIndex: 9999 }}
        onOpenChange={(e) => {
        }}
        trigger="hover"
        placement="bottomLeft"
        content={content}
        arrow={false}
      >
        <Button
          type="primary"
          style={{
            backgroundColor: listtype === "reject" ? "red" : "#1677ff",
            borderColor: listtype === "reject" ? "red" : "#1677ff",
          }}
          icon={ listtype === "reject"?<FontAwesomeIcon color="white" icon={faTimes} />:<FontAwesomeIcon color="white" icon={faCheck} />}
          size="middle"
        ></Button>
      </Popover>
    )
    
  };

  const DueDateRangePopover = () => {
    return (
      <Popover
        overlayClassName="create-task-datepicker"
        content={
          <div className="due-date-range">
            <DateRangePicker
              onChange={changeDateRange}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={1}
              ranges={selectionRange}
              direction="vertical"
              scroll={{ enabled: true }}
            />
          </div>
        }
        trigger="click"
        placement="bottom"
      >
        <Button
          id="setDueDate"
          className="SupervisorBtn"
          icon={<FontAwesomeIcon icon={faCalendarAlt} />}
          style={{ width: "100%" , color: "#5a6c97"}}
        >
          {`${dayjs(taskData?.StartDate).format("MM/DD/YYYY")} - ${dayjs(taskData?.DueDate).format("MM/DD/YYYY")}`}
        </Button>
      </Popover>
    );
  };
  const handleHistoryAdd=()=>{
    let history=[...taskHistory]
    history.push(taskData)
    setTaskHistory(history);
  }
  const handleBack = () => {
    const previousHistory = [...taskHistory]; 
    const lastTask = previousHistory.pop();
    // let type=lastTask?.hasOwnProperty('SubTaskROWID')?'SUBTASK':'TASK';
    if(lastTask){
      if(selectedTable=='tasks'){
      if(lastTask?.hasOwnProperty('SubTaskROWID')){
        AwsServerService.getSubTaskBySubTaskId(lastTask.SubTaskId).then(res=>{
          let k=res[0]?.taskInfo;
          if(k){ 
            viewTaskFunction(k,"SUBTASK");
            setLoader(false);
            setTaskHistory(previousHistory) 
          }
        }).catch(e=>{
          setLoader(false);
        })
      }else{
        AwsServerService.getTaskById(lastTask.TaskId).then(res=>{
          let k=res[0]?.taskInfo;
          if(k){ 
            viewTaskFunction(k,"TASK");
            setLoader(false);
            setTaskHistory(previousHistory) 
          }
        }).catch(e=>{
          setLoader(false);
        })
      }
    }else{
      if(lastTask?.hasOwnProperty('SubTaskROWID')){
        AwsServerService.getSubTaskBySubTaskIdFromTemplate(lastTask?.SubTaskROWID).then(res=>{
          let k= res[0]?.taskInfo || res[0][0].taskInfo; 
          if(k){ 
            viewTaskFunction(k,"SUBTASK");  
          }
        }) 
      }else{
        AwsServerService.getTaskByIdFromTemplate(lastTask?.TaskROWID).then(res=>{
          let k= res[0]?.taskInfo || res[0][0].taskInfo; 
          if(k){ 
            viewTaskFunction(k,"TASK");  
          }
        }) 
      } 
    } 
    }
  };
  function openTaskFromDependencies(data){ 
    const [type,id]=data?.value?.split('_');
    setLoader(true)
    if(selectedTable=='tasks'){
    if(type=='Tasks'){
      AwsServerService.getTaskById(number(id)).then(res=>{
        let k=res[0]?.taskInfo;
        if(k){
          handleHistoryAdd();
          viewTaskFunction(k,"TASK");
          setLoader(false)
        }
      }).catch(e=>{
        setLoader(false);
      }) 
    }else{
      AwsServerService.getSubTaskBySubTaskId(number(id)).then(res=>{
        let k=res[0]?.taskInfo;
        if(k){
          handleHistoryAdd();
          viewTaskFunction(k,"SUBTASK");
          setLoader(false);
        }
      }).catch(e=>{
        setLoader(false);
      })   
    }
  }else{
      if(type=='Tasks'){
        AwsServerService.getTaskByIdFromTemplate(number(id)).then(res=>{
          let k= res[0]?.taskInfo || res[0][0].taskInfo; 
          if(k){ 
            handleHistoryAdd();
            viewTaskFunction(k,"TASK"); 
            setLoader(false); 
          }
        }) 
      }else{
        AwsServerService.getSubTaskBySubTaskIdFromTemplate(number(id)).then(res=>{
          let k= res[0]?.taskInfo || res[0][0].taskInfo; 
          if(k){ 
            handleHistoryAdd();
            viewTaskFunction(k,"SUBTASK"); 
            setLoader(false) 
          }
        }) 
      } 
    }
    setLoader(false); 
    setIsDependencyModalOpen(false);
  }
  const handleCreateActivity =async (sourceTaskType, message, ROWID, template)=>{
    let type=sourceTaskType=='Tasks'?'TASK':'SUBTASK';
    if(template){
      type=sourceTaskType=='Tasks'?'TASKTEMPLATE':'SUBTASKTEMPLATE';
    }
    let payload={
      SourceId:ROWID,
      ActivityType:type,
      Message:message,
      ActivityOwner:userinfo.email_id
    }  
   await AwsServerService.createActivity(payload).then(res=>{
     dispatch(Socket({trigger:"refresh_activity",data:''}))
   }).catch(errMessage=>{
    console.log('Error saving Activity',errMessage);
   })
}
const handleSaveDependencies = async (data,deptype)=>{ 
  if(!viewTaskData){
  let obj={...selectedDependenciesCache};
  obj[deptype].push(data);
  setSelectedDependenciesCache(obj)
  setIsDependencyModalOpen(false);
  return;
  }
  try { 
  let sourceTaskType=taskData.hasOwnProperty('SubTaskROWID')?'SubTasks':'Tasks';
  let sourceId=taskData.hasOwnProperty('SubTaskROWID')?taskData.SubTaskROWID:taskData.TaskROWID;
  if(selectedTable=='tasks'){ 
          const [type,id]=data.value.split('_');
          const payload={
            TargetTaskId:id,
            STaskType:sourceTaskType,
            SourceTaskId:sourceId,
            TaskType:type,
            DependancyType:deptype,
            CREATEDBY:userinfo.email_id,
            TargetDependencyStatus: data.TargetDependencyStatus,
            SourceDependencyStatus: data.SourceDependencyStatus,
          } 
          return AwsServerService.createDependency(payload).then(async (e)=>{
            let selections = [...selectedDependencies[deptype]];
            selections.push({...data,ROWID:e.insertId});
            setSelectedDependenciesCache({
              ...selectedDependencies,
              [deptype]: selections,
            })
            let dependencytype=deptype=='Blocking'?'Blocking':'Waiting';
            let message=`USER added ${dependencytype} dependency of " ${data.label} "`
            handleCreateActivity(sourceTaskType,message,sourceId);
          }).catch(err=> swal('Failed','Error saving dependency','Alert'));
  }else{
  const [type,id]=data.value.split('_');
        const payload={
         TargetTaskId:id,
         STaskType:sourceTaskType,
         SourceTaskId:sourceId,
         TaskType:type,
         DependancyType:deptype,
         PipelineROWID:taskData.PipelineROWID,
         CREATEDBY:userinfo.email_id,
         TargetDependencyStatus: data.TargetDependencyStatus,
         SourceDependencyStatus: data.SourceDependencyStatus
       } 
       return AwsServerService.createTempDependency(payload).then(e=>{ 
        let selections = [...selectedDependencies[deptype]];
            selections.push({...data,ROWID:e.insertId});
            setSelectedDependenciesCache({
              ...selectedDependencies,
              [deptype]: selections,
            }) 
            let dependencytype=deptype=='Blocking'?'Blocking':'Waiting';
            let message=`USER added ${dependencytype} dependency of " ${data.label} "`
            handleCreateActivity(sourceTaskType,message,sourceId,true);
      }).catch(err=> swal('Failed','Error saving dependency','Alert'));
  }
  }catch{
  
  }
  }
  
  function handleDeleteDependency(e,isWaiting){
    let [type,id]=e.value.split('_');
    if(!viewTaskData){
      if(isWaiting=='waiting'){
        let selections=[...selectedDependenciesCache.WaitingOn];
        let data=selections?.filter(t=>t.label!==e.label);
        if(data.length==0){
          setDropdownCount({...dropdowncount,'WaitingOn':0});
        }
        setSelectedDependenciesCache({...selectedDependenciesCache,'WaitingOn':data})
      }else{
        let selections=[...selectedDependenciesCache.Blocking];
        let data=selections?.filter(t=>t.label!==e.label);
        if(data.length==0){
          setDropdownCount({...dropdowncount,'Blocking':0});
        } 
        setSelectedDependenciesCache({...selectedDependenciesCache,'Blocking':data}) 
      }
      return;
    }
    let sourceType=taskData.hasOwnProperty('SubTaskROWID')?'SubTasks':'Tasks';
    let sourceId=taskData.hasOwnProperty('SubTaskROWID')?taskData.SubTaskROWID:taskData.TaskROWID;
    if(selectedTable=='tasks'){

      AwsServerService.deleteDependency(sourceId,sourceType,id,type).then(r=>{
      if(isWaiting=='waiting'){
        let selections=[...selectedDependenciesCache.WaitingOn];
        let data=selections?.filter(t=>t.label!==e.label);
        if(data.length==0){
          setDropdownCount({...dropdowncount,'WaitingOn':0});
        }
          setSelectedDependenciesCache({...selectedDependenciesCache,'WaitingOn':data}) 
      }else{
        let selections=[...selectedDependenciesCache.Blocking];
        let data=selections?.filter(t=>t.label!==e.label);
        if(data.length==0){
          setDropdownCount({...dropdowncount,'Blocking':0});;
        } 
          setSelectedDependenciesCache({...selectedDependenciesCache,'Blocking':data}) 
      }
      let dependencytype=isWaiting?'Waiting':'Blocking';
      let message=`USER removed ${dependencytype} dependency of " ${e.label} "`
      handleCreateActivity(sourceType,message,sourceId);
      })
    }else{
      AwsServerService.deleteTempDependency(sourceId,sourceType,id,type).then(r=>{
        if(isWaiting=='waiting'){
          let selections=[...selectedDependenciesCache.WaitingOn];
          let data=selections?.filter(t=>t.label!==e.label);
          if(data.length==0){
            setDropdownCount({...dropdowncount,'WaitingOn':0});
          }
            setSelectedDependenciesCache({...selectedDependenciesCache,'WaitingOn':data}) 
        }else{
          let selections=[...selectedDependencies.Blocking];
          let data=selections?.filter(t=>t.label!==e.label);
          if(data.length==0){
            setDropdownCount({...dropdowncount,'Blocking':0});;
          } 
            setSelectedDependenciesCache({...selectedDependenciesCache,'Blocking':data})  
        }
        let dependencytype=isWaiting?'Waiting':'Blocking';
        let message=`USER removed ${dependencytype} dependency of " ${e.label} "`
        handleCreateActivity(sourceType,message,sourceId,true);
        })
    }
  }
  // console.log("DealFields", DealFields)
  // const DependecyModal = () => {
  //   return (
  //     <>
  //       <AntButton
  //         onClick={() => {
  //           setIsDependencyModalOpen(true);
  //         }}
  //         color="primary"
  //         type="primary"
  //       >
  //         {(selectedDependenciesCache.Blocking.length>0 || selectedDependenciesCache.WaitingOn.length>0) ?`Dependencies`:`Add Dependency`}
  //       </AntButton>
  //       <AntModal
  //         open={isDependencyModalOpen}
  //         // open={true}
  //         wrapClassName={"Add-dependecny-Wrapper"}
  //         rootClassName="Add-dependecny-Root-Wrapper"
  //         draggable
  //         title={(selectedDependenciesCache.Blocking.length>0 || selectedDependenciesCache.WaitingOn.length>0)?"Edit Dependency":"Add Dependency"}
  //         maskClosable={false}
  //         width={300}
  //         className=""
  //         style={{ bottom: '2rem',right: '9rem', zIndex: "1000" }}
  //         onCancel={() => {
  //           setIsDependencyModalOpen(false); 
  //           let data={...selectedDependenciesCache};
  //           setSelectedDependencies(data); 
  //           setDropdownCount({WaitingOn:0,Blocking:0})
  //         }}
  //         footer={[<>
  //         <Button type="primary" onClick={()=>{
  //           handleSaveDependencies(selectedDependencies) 
  //         }}>{_.isEmpty(selectedDependenciesCache.Blocking) && _.isEmpty(selectedDependenciesCache.WaitingOn)?'Save':'Update'}</Button>
  //         </>]}
  //       >
  //         <div className="add-dependency-wrapper-div">
  //           <div className="dependency-header"></div>
  //           <div className="dependency-modal-body-wrapper">
  //                 <Row>
  //                 <Col sm={2}>
  //                  <label htmlFor="status"><strong>Waiting on:</strong></label>
  //                 </Col>
  //                   <Col sm={10}>
  //                   <div className="waitingOndiv">
  //                   <div className="renderTasksDiv">
  //                     {
  //                       selectedDependencies.WaitingOn.map((e,index)=>{
  //                       let type=e.value.split('_');
  //                       let taskData=allTasksAndSubtasks[type[0]].get(Number(type[1]));
  //                         return <div key={index} className="innerRendertaskdiv">
  //                       <Row style={{padding: "3px"}}>
  //                         <Col sm={1}>  
  //                           {taskData?.TaskStatus=='Not Started'?<span>
  //                           <FontAwesomeIcon icon={faCircle} style={{opacity:'0.8', width: '1em',color:'darkslategrey',marginLeft:'6px'}} />
  //                           </span>:(taskData?.TaskStatus=='Complete' || taskData?.TaskStatus=='Completed')?
  //                           <span>
  //                           <FontAwesomeIcon icon={faCheckCircle} style={{opacity:'0.8', width: '1em',marginLeft:'6px',color: 'green'}} />
  //                           </span>:<span>
  //                           <FontAwesomeIcon icon={faClock} style={{opacity:'1', width: '1em',marginLeft:'6px',color:'#a65050'}} />
  //                           </span>}
  //                         </Col>
  //                         <Col sm={9}>
  //                           <div style={{cursor: 'pointer'}} onClick={()=>{
  //                             openTaskFromDependencies(e);
  //                           }}>
  //                           {e.label}
  //                         </div>
  //                           </Col>
  //                           <Col sm={2} style={{textAlign:'right'}}> 
  //                           <span style={{cursor:'pointer', marginRight:"10px"}} onClick={()=>{
  //                             let [type,id]=e.value.split('_');
  //                             console.log(type,id);
  //                           }}>
  //                          <FontAwesomeIcon icon={faTrash} style={{opacity:'0.5', color:'#e84445'}}
  //                          onClick={()=>{ 
  //                           // let selections=[...selectedDependencies.WaitingOn];
  //                           // let data=selections?.filter(t=>t.label!==e.label);
  //                           // if(data.length==0){
  //                           //   setDropdownCount({...dropdowncount,'WaitingOn':0});
  //                           // } 
  //                           // setSelectedDependencies({...selectedDependencies,'WaitingOn':data}) 
  //                           handleDeleteDependency(e,'waiting');
  //                          }}
  //                          />
  //                           </span> 
  //                           </Col>
  //                           </Row>
  //                         </div>
  //                         }
  //                        )
  //                     }
  //                    </div>
  //                    {dropdowncount.WaitingOn==0 && <Button type="primary" style={{bottom:'0.5em', border:"none"}} onClick={()=>setDropdownCount({...dropdowncount,'WaitingOn':1})} ghost> Add Task + </Button>}
  //                   { dropdowncount.WaitingOn==1 && 
  //                   <div className="dropdownForWaiting">
  //                   <AntSelect
  //                   showSearch
  //                   size={"small"}
  //                   className="form-control"
  //                   popupClassName={"dependncy-list-dropdowns"}
  //                   placeholder={'Select Waiting On'}
  //                   filterOption={(input, option) =>
  //                     (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
  //                   }
  //                   onSelect={(e)=>{
  //                     let data=[...dropdownTasksDataCache]
  //                     let selections=[...selectedDependencies.WaitingOn];
  //                     selections.push(data?.filter(t=>t.value==e)[0]);
  //                     setSelectedDependencies({...selectedDependencies,'WaitingOn':selections}) 
  //                   }} 
  //                   value={null}
  //                   options={getOptionsForDropdown(filteredDropdownTasksData,selectedDependencies)}
  //                   />
  //                   </div>
  //                 }
  //                 </div>
  //                   </Col>
  //                   </Row>
  //                 <Row style={{ marginTop: '20px' }}>
  //                 <Col sm={2}>
  //                  <label htmlFor="status"><strong>Blocking :</strong></label>
  //                 </Col>
  //                   <Col sm={10}>
  //                   <div className="waitingOndiv">
  //                   <div className="renderTasksDiv">
  //                     {
  //                       selectedDependencies.Blocking.map((e,index)=>{
  //                         let type=e.value.split('_');
  //                         let taskData=allTasksAndSubtasks[type[0]].get(Number(type[1]));
  //                         return <div key={index} className="innerRendertaskdiv">
  //                       <Row style={{padding: "3px"}}>
  //                         <Col sm={1}>
  //                         {taskData?.TaskStatus=='Not Started'?<span>
  //                           <FontAwesomeIcon icon={faCircle} style={{opacity:'0.8', width: '1em',color:'darkslategrey',marginLeft:'6px'}} />
  //                           </span>:(taskData?.TaskStatus=='Complete' || taskData?.TaskStatus=='Completed')?
  //                           <span>
  //                           <FontAwesomeIcon icon={faCheckCircle} style={{opacity:'0.8', width: '1em',marginLeft:'6px',color: 'green'}} />
  //                           </span>:<span>
  //                           <FontAwesomeIcon icon={faClock} style={{opacity:'1', width: '1em',marginLeft:'6px',color:'#a65050'}} />
  //                           </span>}
  //                         </Col>
  //                         <Col sm={9}>
  //                         <div style={{cursor: 'pointer'}} onClick={()=>{
  //                         openTaskFromDependencies(e);
  //                        }}>
  //                           {e.label}
  //                       </div>
  //                           </Col>
  //                           <Col sm={2} style={{textAlign:'right'}}> 
  //                           <span style={{cursor:'pointer', marginRight:"10px"}}>
  //                          <FontAwesomeIcon icon={faTrash} style={{opacity:'0.5', color:'#e84445'}}
  //                          onClick={()=>{ 
  //                           handleDeleteDependency(e); 
  //                          }}
  //                          />
  //                           </span> 
  //                           </Col>
  //                           </Row>
  //                         </div>}
  //                        )
  //                     }
  //                    </div>
  //                    {dropdowncount.Blocking==0 && <Button type="primary" style={{bottom:'0.5em', border:"none"}} onClick={()=>setDropdownCount({...dropdowncount,'Blocking':1})} ghost> Add Task + </Button>}
  //                   { dropdowncount.Blocking==1 && 
  //                   <div className="dropdownForWaiting">
  //                   <AntSelect
  //                   showSearch
  //                   size={"small"}
  //                   className="form-control"
  //                   popupClassName={"dependncy-list-dropdowns"}
  //                   placeholder={'Select Blocking'}
  //                   filterOption={(input, option) =>
  //                     (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
  //                   }
  //                   onSelect={(e)=>{
  //                     let data=[...dropdownTasksDataCache]
  //                     let selections=[...selectedDependencies.Blocking];
  //                     selections.push(data?.filter(t=>t.value==e)[0]);
  //                     setSelectedDependencies({...selectedDependencies,'Blocking':selections})  
  //                     // setDropdownTasksData(data?.filter(t=>t.value != e))
  //                   }} 
  //                   value={null}
  //                   options={getOptionsForDropdown(filteredDropdownTasksData,selectedDependencies)}
  //                   />
  //                   </div>
  //                 }
  //                 </div>
  //                   </Col>
  //                   </Row>
  //           </div>
  //         </div>
  //       </AntModal>
  //     </>
  //   );
  // };
  const [filteredFields, setFilteredFields] = useState(DealFields);

  const getLookUpData = (data, type, filedName) =>{
    if(data && typeof data == "object"){
      return <span className="Elipsis-Class" style={{fontWeight:"600", color: type == "table" ? "#5a6c97" : "#ffffff", fontSize: "12px"}}>{(data.name)?.toUpperCase()}</span>
    }
    if(data && filedName == "Closing_Date"){
      return <span className="Elipsis-Class" style={{fontWeight:"600", color: type == "table" ? "#5a6c97" : "#ffffff", fontSize: "12px"}}>{moment(new Date(data)).format("MM/DD/YYYY")}</span>
    }
    return <span className="Elipsis-Class" style={{fontWeight:"600", color: type == "table" ? "#5a6c97" : "#ffffff", fontSize: "12px"}}>{typeof data == "string" ? data?.toUpperCase() : data}</span>
  }
  const [searchedValue, setSearchedValue] = useState('');
  const handleSearchLookUp = (value) => {
    const lowercasedValue = value.toLowerCase();
    setSearchedValue(lowercasedValue);
    // Filter DealFields based on the field name or its corresponding data in lookUpDeal
    const filtered = DealFields.filter((one) => {  
      if(one.field == "Project_Status"){
        one.field = "Stage"
      }
      const fieldValue = one.field.toLowerCase();
      let lookUpValue = '';
      if (lookUpDeal && lookUpDeal[one.field]) {
        if (typeof lookUpDeal[one.field] === "object") {
            // Handle if the value is an object and get the `name` property
            lookUpValue = (lookUpDeal[one.field].name || "").toLowerCase();
        } else if (typeof lookUpDeal[one.field] === "number") {
            // Convert numbers to string
            lookUpValue = lookUpDeal[one.field].toString();
        } else {
            // Handle as a string
            lookUpValue = lookUpDeal[one.field].toLowerCase();
        }
    }
      return (
        fieldValue.includes(lowercasedValue) ||
        lookUpValue.includes(lowercasedValue)
      );
    });

    setFilteredFields(filtered);
  };

  const AddLookUpFields = (label, value) => {
    let lookUpdata = { ...taskData };
      if(!lookUpdata["LookUpFields"]){
        lookUpdata["LookUpFields"] = [];
      }
      
      // If action is "edit", update API and state
      if (taskModalState.action === "edit") {
        const updatedArray = [...lookUpdata["LookUpFields"]? lookUpdata["LookUpFields"] : [], label];
        const data2 = {
          ROWID: lookUpdata.hasOwnProperty("SubTaskROWID") ? lookUpdata.SubTaskROWID : lookUpdata.TaskROWID,
          LookUpFields: updatedArray,
        };
        
        const updateService = lookUpdata.hasOwnProperty("SubTaskROWID") 
          ? AwsServerService.updateSubtask
          : AwsServerService.updateTask;
  
        updateService(data2)
          .then(() => {
            // setPageRefreshData(prev => !prev);
          })
          .catch(er => console.log("Er", er));
        
        lookUpdata["LookUpFields"] = updatedArray;
        setTaskData(lookUpdata);
  
      } else {
        lookUpdata["LookUpFields"].push(label);
        setTaskData(lookUpdata);
      }
  };
  
  const removeLookUpField = (label) => {
    let data = { ...taskData };
    const updatedArray = data["LookUpFields"].filter(item => item !== label);
  
    if (taskModalState.action === "edit") {
      const data2 = {
        ROWID: data.hasOwnProperty("SubTaskROWID") ? data.SubTaskROWID : data.TaskROWID,
        LookUpFields: updatedArray,
        // MODIFIEDBY: user.email_id,
      };
  
      const updateService = data.hasOwnProperty("SubTaskROWID") 
        ? AwsServerService.updateSubtask 
        : AwsServerService.updateTask;
  
      updateService(data2)
        .then(() => {
          // setPageRefreshData(prev => !prev);
        })
        .catch(er => console.log("Er", er));
        data["LookUpFields"] = updatedArray;
        setTaskData(data);
    }else{
      data["LookUpFields"] = updatedArray;
      setTaskData(data);
    }
  };
  
const getTableForLookupFields = () => {
  // Separate selected and non-selected fields
  let data = {...taskData}
  if(!data["LookUpFields"]){
    data["LookUpFields"] = [];
  }
  const selectedFields = filteredFields?.filter(one => data["LookUpFields"].some(item => item === one.field));
  const nonSelectedFields = filteredFields?.filter(one => !data["LookUpFields"].some(item => item === one.field));

  // Define function to render the list of fields
  const renderFields = (fields, isSelected) => (
      <div>
          {fields.map((one,ind) => {
              // if(one.field === "Project_Status"){
              //     one.field = "Stage";
              // }
              console.log("uppercase", (one.field === "Stage" ? "Project Status" : one.field)?.replaceAll("_", " ")?.toUpperCase());
              
              return (
                  <Row sm={12} className="Look-Up-P" key={ind}>
                      <Tooltip color="rgb(54 54 54)" title={one.field}>
                          <Col sm={5}>
                              <span className="Elipsis-Class" style={{fontWeight:"600", color: "#5a6c97", fontSize: "12px"}}>
                                  {(one.field === "Stage" ? "Project Status" : one.field)?.replaceAll("_", " ")?.toUpperCase()}
                              </span>
                          </Col>
                      </Tooltip>
                      <Tooltip
                          color="rgb(54 54 54)"
                          title={getLookUpData(lookUpDeal && lookUpDeal[one.field == "Project_Status" ? "Stage": one.field], "tooltip",one.field)}
                      >
                          <Col sm={5}>
                              {one.field != "Stage" && getLookUpData(lookUpDeal && lookUpDeal[one.field == "Project_Status" ? "Stage": one.field], "table", one.field)}
                          </Col>
                      </Tooltip>
                      <Col sm={2} style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                          <Button 
                            className="Look-Up-Add-Btn"
                              icon={isSelected ? <FontAwesomeIcon style={{ margin: "0px 7px" }} icon={faMinus} /> : <FontAwesomeIcon style={{ margin: "0px 7px" }} icon={faPlus} />}
                              size="middle" 
                              onClick={(e) => {
                                  e.stopPropagation();
                                  if (isSelected) {
                                      removeLookUpField(one.field);
                                  } else {
                                      AddLookUpFields(one.field);
                                    }
                              }}
                          />
                      </Col>
                  </Row>
              );
          })}
      </div>
  );

  const content = (
      <div className="Look-Up-Div">
          <div className="Look-Up-Header">
              <span className="Look-Up-Title" style={{ color: "#5a6c97" }}>Add Lookup Fields</span>
              <Search
                  placeholder="Search..."
                  size="small"
                  style={{ width: "100%", color: "#5a6c97", marginTop: "6px" }}
                  value={searchedValue}
                  allowClear
                  onChange={(e) => handleSearchLookUp(e.target.value)}
              />
          </div>

          {/* Render selected fields */}
          <div className="Selected-Fields">
              <h6 style={{ color: "#5a6c97", fontWeight: "bold" }}>Selected Fields</h6>
              {renderFields(selectedFields, true)}
          </div>

          {/* Render non-selected fields */}
          <div className="Non-Selected-Fields" style={{ marginTop: "10px" }}>
              <h6 style={{ color: "#5a6c97", fontWeight: "bold" }}>Available Fields</h6>
              {renderFields(nonSelectedFields, false)}
          </div>
      </div>
  );

  return (
      <Popover
          style={{ zIndex: 99990 }}
          onOpenChange={(open) => {
              handleSearchLookUp('')
              console.log("open-->", open)
          }}
          trigger="click"
          overlayClassName={"Look-Up-Container"}
          placement="top"
          content={content}
      >
          <Button 
          className="Add-Lookup-Icon" 
          icon={<FontAwesomeIcon style={{ marginRight: "7px" }} icon={faPlus} />} 
          onClick={(e) => e.stopPropagation()}></Button>
      </Popover>
  );
};


  const getStatusByFlow=async(viewTaskData,accepted)=>{
    let id=viewTaskData?.TaskFlowId; 
    let currentStatus= viewTaskData?.TaskStatus;
    if(id==undefined){
      swal('Alert','Task Not Saved Yet!','info')
      return
    }
    swal({
      title: "Warning!",
      text: `Are you sure you want to ${accepted?'Approve':'Reject'}?`,
      icon: "warning",
      buttons: {
        cancel: "Cancel",
        confirm: "Proceed",
      },
    }).then(async(isTrue)=>{
      if(isTrue){
         await AwsServerService.getTaskFlowById(id).then(r=>{
           let data=r.data[0];
           if(data){
       let status=data[0].Nodes.filter(e=>e.data.label==currentStatus)[0];
       let next=getNextStepOfFlow(data[0].Nodes,data[0].Edges,status.id,accepted);
       let statusToSet=data[0].Nodes.filter(e=>e.id==next)[0];
       if(statusToSet){ 
        let temp = { ...taskData };
        temp["TaskStatus"] = statusToSet.data.label;
        setTaskData(temp);
      }
    }
  }) 
  }
});
}

  return (
    <div>
      {loader && <Loader/>}
      {showWarningModal()}
      {renderDuplicateModal()}
      <AntModal
        open={open}
        // open={true}
        wrapClassName={"Add-task-Wrapper"}
        rootClassName="Add-task-Root-Wrapper"
        draggable
        title=""
        maskClosable={false}
        width={300}
        className=""
        style={{ top: 170, borderRadius: 50, right: 50 }}
        onCancel={() => {
          // history.replace('/project/Tasks/defaultView')
          setOpen(false);
          setIsSubTaskView(false);
          setTaskData({ ...taskEmptyTemplate });
          setStatusOptions([]);
          setAccountSelected('')
          setDealSelected('')
          setTaskHistory([]);
        }}
        footer={[<></>]}
      >
        <div className="Task-Modal-Wrapper">
          <div className="Task-Header">
            <div style={{ display: "flex", gap: "20px", alignItems: "center" }}>
              { taskModalState.action == "edit" && <span
                style={{ display: "flex", gap: "10px", alignItems: "center" }}
              >
                <FontAwesomeIcon
                onClick={() => {
                  handleBack();
                }}
                style={taskHistory.length == 0 ? { cursor: 'not-allowed', color: 'lightgray' } : { cursor: 'pointer' }}
                icon={faArrowLeft}
              />
              </span>}
              {taskModalState.action != "edit"?<><span
                style={{ display: "flex", gap: "10px", alignItems: "center" }}
              >
                <FontAwesomeIcon color="#7a7a7a" icon={faUser} />
                <div>{getAntSelectDropDown("Account")}</div>
              </span>
              <span
                style={{ display: "flex", gap: "10px", alignItems: "center" }}
              >
                <FontAwesomeIcon color="#7a7a7a" icon={faFolder} />
                <div>{getAntSelectDropDown("Project")}</div>
              </span></>
              :
              <span>
                {selectedTable != "pipelines" && getHeaderForDeal(dealSelected)}
                {getBreadCrumbs(taskData)}           
                 </span>
                }
 
              <span
                style={{ display: "flex", gap: "10px", alignItems: "center" }}
              >
                <FontAwesomeIcon color="#7a7a7a" icon={faCodeBranch} />
                <div>{getBlueprintsDropDown("TaskFlowId")}</div>
                {selectedBlueprintData &&
                <Popover
                  overlayClassName="Task-Flow-View-Popup"
                  content={()=>(<Flow
                    showProgress={true}
                    disableMiniMap={true}
                    selectedData={selectedBlueprintData}
                    onSelectedData={()=>{

                    }}
                    onNodeSelect={(node) => {
                    }}
                    onEdgeSelected={(edge) => {
                    }}
                    saveCallback={(res) => {
                    }}
                  />)}
                  title={selectedBlueprintData?.data?.Name?selectedBlueprintData?.data?.Name:"Preview"}
                  trigger="click"
                  overlayStyle={{ width: '60vw', height: '60vh' }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="view-flow-icon"
                    viewBox="0 0 512 512"
                  >
                    <path d="M352 0c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9L370.7 96 201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L416 141.3l41.4 41.4c9.2 9.2 22.9 11.9 34.9 6.9s19.8-16.6 19.8-29.6V32c0-17.7-14.3-32-32-32H352zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z" />
                  </svg>
                </Popover>}
              </span>
            </div>
            <div>
              <div style={{ display: "flex", gap: "10px" }}>
                <Button
                  type="primary"
                  onClick={() => {
                    handleSaveTask(taskData);
                  }}
                  style={{ height: "28px" }}
                >
                  {taskModalState.action == "edit"
                    ? "Update Task"
                    : "Save Task"}
                </Button>
                <Button
                  icon={<CloseOutlined />}
                  onClick={() => {
                    if(history.location.pathname.includes("project/Tasks/defaultView")){
                      history.replace('/project/Tasks/defaultView')
                    }else if(history.location.pathname.includes("setting/pipeline")){
                      history.replace('/setting/pipeline')
                    }
                    setOpen(false);
                    setIsSubTaskView(false);
                    setDependencyRefresh(prev => !prev);
                    setTaskData({ ...taskEmptyTemplate });
                    setStatusOptions([]);
                    setTaskHistory([]);
                    setAccountSelected('');
                    setDealSelected('');
                    comment.current = {ROWID:null,flag:false};
                    dispatch(Socket({trigger:"refresh_task",data:selectedTable}))
                  }}
                  style={{ height: "28px" }}
                ></Button>
              </div>
            </div>
          </div>
          <div className="Task-Header-Two">
            {[
             {
              label: "STATUS",
              component: (
                <>
                  <div className="Status-Wrapper-Class">
                    <TaskFlowStatus
                      type={"Status"}
                      statusOptions={mainStatusOptions}
                      onChangeStatus={(data)=>{onChangeTransion(data.label)}}
                      taskData={taskData}
                      transitions={statusOptions}
                    />
                    </div>
                </>
              ),
            },
            {
                label: "SUPERVISOR",
                component: (
                  <UserPicker
                    values={taskData["Supervisor"] ? taskData["Supervisor"] : ""}
                    onChange={(val) =>
                      setTaskData({ ...taskData, Supervisor: val ? val : ""})
                    }
                    options={userOptions}
                    rootClassName="table-dropdown"
                    onClear={()=> setTaskData({ ...taskData, Supervisor: "" })}
                  />
                ),
              },
              {
                label: "ASSIGNEE",
                component: (
                  <UserPicker
                    mode="multiple"
                    values={taskData["Assignee"] ? taskData["Assignee"] : ""}
                    onChange={(val) =>
                      setTaskData({ ...taskData, Assignee: val ? val : ""})
                    }
                    options={userOptions}
                    rootClassName="table-dropdown"
                    onClear={()=> setTaskData({ ...taskData, Assignee: "" })}
                  />
                ),
              },
              {
                label: "PRIORITY",
                component: (
                  <Tooltip color="rgb(54 54 54)" title={taskData["Priority"]}>
                    <Select
                      className="priority-icon"
                      name="Priority"
                      placeholder="Select an option"
                      style={{ width: 200 }}
                      value={taskData["Priority"] ? taskData["Priority"] : "Not Set"}
                      onChange={(val) =>
                        setTaskData({ ...taskData, Priority: val })
                      }
                      optionLabelProp="icon"
                      rootClassName="table-dropdown"
                    >
                      {["Not Set", "Low", "Medium", "High"].map((priority) => (
                        <Option
                          key={priority}
                          value={priority}
                          icon={
                            <FlagFilled
                              style={{ color: getPriorityValue(priority) }}
                              className="Icon"
                            />
                          }
                        >
                          <FlagFilled
                            style={{ color: getPriorityValue(priority) }}
                            className="Icon"
                          />{" "}
                          {priority}
                        </Option>
                      ))}
                    </Select>
                  </Tooltip>
                  ),
              },
              {
                label: "TIME TRACKED",
                component: (<>
                <TimeTracker 
                  viewTaskData={(taskData?.TaskRowId || taskData?.TaskROWID)?taskData:viewTaskData}
                  open={open} 
                  users={userOptions} 
                  setLoader={setLoader}
                  allTasksAndSubtasks={allTasksAndSubtasks} 
                  viewTaskFunction={viewTaskFunction}
                  handleHistoryAdd={handleHistoryAdd}
                  />                  
                </>),
              },
              {
                label: "TIME ESTIMATE",
                component: (
                  <CustomTimeSelect
                    rootClassName={"Create-Task-TimePicker"}
                    className={"Create-Task-TimePicker-Class"}
                    placement={"top"}
                    taskData={taskData}
                    setTaskData={setTaskData}
                  />
                ),
              },
              {
                label: "START/DUE DATE",
                component: <DueDateRangePopover />,
              },
            ].map(({ label, component }) => (
              <div key={label}>
                <p className="Text-Center" style={label == "TIME ESTIMATE" ? {marginBottom : "2px"} : {}}>{label}</p>
                {component}
              </div>
            ))}
          </div>
          <div className="Task-Modal-Body-Wrapper">
            <div className="Task-Modal-Body-Child1">
              <Row className="Task-Modal-Body" sm={12}>
                <Col
                  className="Task-Info-Wrapper"
                  sm={activitySection && taskModalState.action =="edit" && activitySection.open ? 7 : 12}
                >
                  <Row className="TaskNameWrapper margin-0">
                    <Input
                      className="Task-Name"
                      size="large"
                      placeholder="Task Name"
                      name="TaskName"
                      variant="borderless"
                      value={taskData["TaskName"]}
                      onChange={(e) => {
                        e.currentTarget.value = (e.currentTarget.value).trimStart();
                        let temp = { ...taskData };
                        temp["TaskName"] = e.currentTarget.value;
                        setTaskData(temp);
                      }}
                    />
                    <div className="Status-Wrapper-Class">
                    <TaskFlowStatus
                      statusOptions={mainStatusOptions}
                      onChangeStatus={(data)=>{onChangeTransion(data.label)}}
                      taskData={taskData}
                      transitions={statusOptions}
                    />
                    </div>
                  </Row>
                  <div className="stickyDivWrapper">
                    <Row className="TaskDescriptionWrapper margin-0">
                      <CustomeCollapse
                        defaultOpen={[]}
                        items={[
                          {
                            key: "1",
                            label: <Badge color="#1677ff" style={{right:"-13px",top:"50%"}} count={0}>DESCRIPTION</Badge>,
                            children: (
                              <TextArea
                                name="TaskDescription"
                                style={{ border: "none", color:"#5b6c97" }}
                                placeholder="Write description.."
                                value={taskData["TaskDescription"]}
                                onChange={(e) => {
                                  let temp = { ...taskData };
                                  temp["TaskDescription"] = e.currentTarget.value;
                                  setTaskData(temp);
                                }}
                              />
                            ),
                          },
                        ]}
                        rootClassName={''}
                      />
                    </Row>
                    <Row
                      sm={12}
                      className="margin-0"
                      style={{ borderBottom: "1px solid #e4e4e4" }}
                    >
                      <Col sm={7}>
                        <CustomeTaskField
                          name={"PIPELINE:"}
                          icon={<FontAwesomeIcon icon={faUser} />}
                        >
                          <Select
                            mode={""}
                            style={{ width: 200 }}
                            value={taskData["PipelineROWID"] ? taskData["PipelineROWID"] : null}
                            placeholder="Select Pipeline"
                            onChange={(val) => {
                              let temp = { ...taskData };
                              temp["PipelineROWID"] = val;
                              setTaskData(temp);
                            }}
                            options={allPipelinesData}
                          />
                        </CustomeTaskField>
                        <CustomeTaskField
                          name={"CREATED AT:"}
                          icon={<FontAwesomeIcon icon={faClock} />}
                        >
                          <span
                            style={{
                              fontWeight: "500",
                              opacity: "0.85",
                              padding: "12px",
                              fontSize: "13px"
                            }}
                          >
                            {taskData?.CREATEDTIME
                              ? dayjs(taskData?.CREATEDTIME).format("MM-DD-YYYY")
                              : ""}
                          </span>
                        </CustomeTaskField>
                      </Col>
                    </Row>
                    <Row
                      sm={12}
                      className="margin-0"
                      style={{ borderBottom: "1px solid #e4e4e4" }}
                    >
                      <CustomeCollapse
                        defaultOpen={[]}
                        items={[
                          {
                            key: "1",
                            label: <Badge color="#1677ff" style={{right:"-13px",top:"50%"}} count={(taskData?.InputFields && taskData?.InputFields.length>0?taskData?.InputFields.length:0)
                              +(taskData?.TaskFlowObject?.InputFields && taskData?.TaskFlowObject?.InputFields?.length>0?taskData?.TaskFlowObject?.InputFields?.length:0)}>INPUT FIELDS</Badge>,
                            rightComponent:<>
                              <InputFields
                                Module={"TASK"}
                                cardSize={3}
                                onAdd={(obj) => {
                                  let temp={...taskData};
                                  let arr = [];
                                  if (Array.isArray(temp?.InputFields) && temp?.InputFields?.length > 0) {
                                    arr = [...temp?.InputFields];
                                  }
                                  arr.push(obj);
                                  temp["InputFields"] = arr;
                                  setTaskData(temp);
                                }}
                              >
                                <Button className="Add-Input-Icon" icon={<FontAwesomeIcon style={{ marginRight: "7px" }} icon={faPlus} />} onClick={()=>{}}></Button>
                              </InputFields>
                            </>,
                            children: (
                              <>
                              <InputFieldsTable onDelete={data=>{
                               if(data.Module=="TASK"){
                                 let temp={...taskData}
                                 temp["InputFields"]=temp.InputFields.filter(e=>e.id!=data.id);
                                 setTaskData(temp);
                               }
                                }} onChange={(fields) => {

                                  let temp = { ...taskData }
                                  let taskI = fields.filter(e => e.Module == "TASK")
                                  let flowI = fields.filter(e => e.Module != "TASK")
                                  temp["TaskFlowObject"]["InputFields"] = [...flowI];
                                  temp["InputFields"] = [...taskI];
                                  setTaskData(temp);

                                  getBlueprintDataOptions(temp, temp.TaskStatus);
                                }} readOnly={taskModalState.action!="edit"}  Module={"TASK"} InputFields={taskData}/>
                             </>
                            ),
                          },
                        ]}
                        rootClassName={''}
                      />
                    </Row>
                    <Row
                      sm={12}
                      className="margin-0"
                      style={{ borderBottom: "1px solid #e4e4e4" }}
                    >
                     <CustomeCollapse
                        defaultOpen={[]}
                        items={[
                          {
                            key: "1",
                            label: <Badge color="#1677ff" style={{right:"-13px",top:"50%"}} count={taskData["LookUpFields"] && taskData["LookUpFields"].length>0?taskData["LookUpFields"].length:0}>LOOK UP FIELDS</Badge>,
                            rightComponent:<>
                              {getTableForLookupFields()}
                            </>,
                            children: (
                              <Row className="look-up-tile-container">
                                <div className="Look-Up-Div">
                                  {taskData["LookUpFields"] && taskData["LookUpFields"]?.map((one) => {
                                    return (
                                      <Row sm={12} className="Look-Up-P" style={{padding: "5px"}}>
                                        <Tooltip color="rgb(54 54 54)" title={one}>
                                          <Col sm={5}>
                                            <span className="Elipsis-Class" style={{fontWeight:"600", color: "#5a6c97", fontSize: "12px"}}>{(one == "Project_Status" ? "Stage": one).replaceAll("_", " ")?.toUpperCase()}</span>
                                          </Col>
                                        </Tooltip>
                                        <Tooltip
                                          color="rgb(54 54 54)"
                                          title={one != "Stage" && getLookUpData(lookUpDeal && lookUpDeal[one == "Project_Status" ? "Stage": one], "tooltip", one)}
                                        >
                                          <Col sm={5}>
                                            {one != "Stage" && getLookUpData(lookUpDeal && lookUpDeal[one == "Project_Status" ? "Stage": one], "table", one)}
                                          </Col>
                                        </Tooltip>
                                        <Col sm={2} style={{textAlign:"end", paddingRight:"3px"}}>
                                          <Button 
                                          className="Look-Up-Table-Btn"
                                          icon={<FontAwesomeIcon style={{ margin: "7px"}} icon={faMinus} />}
                                          size="small" 
                                          onClick={(e)=>{
                                              removeLookUpField(one);
                                          }}
                                          ></Button>
                                        </Col>
                                      </Row>
                                    );
                                  })}
                                </div>
                                </Row>
                            ),
                          },
                        ]}
                        rootClassName={''}
                      />
                    </Row>
                    <Row
                      sm={12}
                      className="margin-15"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        borderBottom: "1px solid #e4e4e4",
                      }}
                    >
                      <Col sm={7}>
                        <CustomeTaskField
                          name={"ATTACHMENTS:"}
                          icon={<FontAwesomeIcon icon={faPaperclip} />}
                        >
                          <Button
                            title="Upload attachments"
                            onClick={() => {
                                setAttachmentModal(!attachmentModal)
                            }}
                            // className="compose-btns"
                            style={{ position: "relative" , color:"#5a6c97"}}
                          >
                            Upload files
                          </Button>
                          <AttachmentModal
                            handleAttachementRemove={handleAttachementRemove}
                            setAttachmentModal={setAttachmentModal}
                            attachmentModal={attachmentModal}
                            handleUploadFiles={handleUploadFiles}
                            // data={props.dealObj}
                            fileNames={fileNames}
                            setFileNames={setFileNames}
                            uploadFiles={uploadFiles}
                          />
                        </CustomeTaskField>
                      </Col>
                      <Col sm={6}>
                      </Col>
                    </Row>
                    <div className="sticky-container">
                      <Row className="margin-15">
                        <Tabs
                          activeKey={activeTab}
                          onTabClick={(key) => {
                            setActiveTab(key);
                          }}
                          defaultActiveKey={isSubTaskView ? "2" : "1"}
                          items={[
                            {
                              label: (
                                <Badge
                                  color="blue"
                                  count={taskData?.SubTasks?.length}
                                  offset={[10, 0]}
                                >
                                  <span>Sub Tasks</span>
                                </Badge>
                              ),
                              key: "1",
                              disabled: isSubTaskView,
                              children: (
                                <SubTaskTab
                                  allPipelineData={allPipelineData}
                                  groupFilter={""}
                                  module={"View Task"}
                                  viewTaskFunction={(data,module) => {
                                    setActiveTab("2");
                                    handleHistoryAdd();
                                    viewTaskFunction(data,module);
                                  }}
                                  taskData={taskData}
                                  setOpen={setOpen}
                                  setTaskData={()=>{
                                    setTaskData({...taskEmptyTemplate})
                                  }}
                                  key={"Task Name"}
                                  selectedTable={selectedTable}
                                  allBlueprintsData={allBlueprintsData}
                                  subTaskData={subTaskData}
                                  setSubTaskData={setSubTaskData}
                                  isSubTaskView={isSubTaskView}
                                  setIsSubTaskView={setIsSubTaskView}
                                  setPageRefreshData={setPageRefreshData}
                                  refreshData={refreshData}
                                  handleCreateActivity={handleCreateActivity}
                                />
                              ),
                            },
                            {
                              label: (
                                <Badge color="blue" count={4} offset={[10, 0]}>
                                  <span>Check List</span>
                                </Badge>
                              ),
                              key: "2",
                              children: <Checklist />,
                            },
                            {
                              label: (
                                <Badge color="blue" count={(selectedDependenciesCache?.Blocking?.length + selectedDependenciesCache?.WaitingOn?.length)} offset={[10, 0]}>
                                  <span>Dependency</span>
                                </Badge>
                              ),
                              key: "3",
                              children: <DependencyModal 
                              selectedDependenciesCache={selectedDependenciesCache} 
                              getOptionsForDropdown={getOptionsForDropdown} 
                              handleDeleteDependency={handleDeleteDependency} 
                              allTasksAndSubtasks={allTasksAndSubtasks}
                              selectedDependencies={selectedDependencies}
                              dropdowncount={dropdowncount}
                              setSelectedDependencies={setSelectedDependencies}
                              filteredDropdownTasksData={filteredDropdownTasksData}
                              openTaskFromDependencies={openTaskFromDependencies}
                              setDropdownCount={setDropdownCount}
                              dropdownTasksDataCache={dropdownTasksDataCache}
                              setDropdownTasksDataCache={setDropdownTasksDataCache}
                              handleSaveDependencies={handleSaveDependencies}
                              userOptions={userOptions}
                              statusOptions={mainStatusOptions}
                              openTaskData={taskData}
                              />,
                            },

                          ]}
                        />
                      </Row>
                    </div>
                  </div>
                </Col>
                {activitySection && taskModalState.action=="edit" && activitySection.open  && (
                  <Col className="Task-Activity-Wrapper" sm={5}>
                    <div className="Activity-Header">
                      <span className="header">{activitySection.key}</span>
                    </div>
                    <div className="Activity-Body">
                      {taskModalState.action == "edit" &&
                        activitySection.component(
                          isSubTaskView ? taskData?.SubTaskROWID : taskData?.TaskROWID ,
                          isSubTaskView,selectedTable=='pipelines'
                        )}
                    </div>
                    <div className="Activity-Comment-Body">
                      <CustomeCollapse
                        defaultOpen={[]}
                        items={[
                          {
                            key: "1",
                            label: (
                              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' ,height:'3.5vh'}}>
                                <span>Comments</span>
                                <Button style={selectedTable!='tasks'?{pointerEvents:'none',color:'grey'}:{}} onClick={saveCommentForTask}>
                                  Send
                                </Button>
                              </div>
                            ),
                            children: (
                              <TextArea
                                name="Comment"
                                style={{ border: "none" }}
                                placeholder="Write Comment.."
                                value={taskData["Comment"]}
                                onChange={(e) => {
                                  setTaskData(result=>({...result,Comment:e.currentTarget.value}))
                                }}
                                rootClassName={''}
                              />
                            ),
                          },
                        ]}
                      />
                    </div>
                  </Col>
                )}
              </Row>
            </div>
            <div className="Task-Modal-Body-Child2">
              <div className="Task-Sidebar" sm={2}>
              {sidebarOptions &&
                  sidebarOptions.map((item, i) => {
                    if (item.key === "Settings") {
                      return taskModalState.action === "create" ? (
                        <div
                          className="mb-3 SidebarBtns"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            cursor: "not-allowed",  // Change cursor to indicate non-clickable
                            opacity: 0.5,           // Optional: Make it look disabled
                          }}
                        >
                          {/* <Badge
                            showZero={false}
                            count={i !== 0 ? 0 : 1}
                            color="#ec4242d1"
                            offset={[0, 0]}
                          > */}
                            <Button
                              style={{ border: "none" }}
                              icon={item.icon}
                              disabled  // Disable button click
                            />
                          {/* </Badge> */}
                          <span>{item.key}</span>
                        </div>
                      ) : (
                        <Popover
                          content={renderSettingsPopoverContent()}
                          title=""
                          trigger="click"
                          open={popoverVisible}
                          onOpenChange={setPopoverVisible}
                          rootClassName="Duplicate-Modal"
                        >
                          <div
                            className="mb-3 SidebarBtns"
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            {/* <Badge
                              showZero={false}
                              count={i !== 0 ? 0 : 1}
                              color="#ec4242d1"
                              offset={[0, 0]}
                            > */}
                              <Button
                                style={{ border: "none" }}
                                icon={item.icon}
                              />
                            {/* </Badge> */}
                            <span>{item.key}</span>
                          </div>
                        </Popover>
                      );
                    }
                    return (
                      <div
                        className="mb-3 SidebarBtns"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                        onClick={(e) => {
                          if (taskModalState.action === "create") {
                            e.preventDefault();
                            return;
                          }
                          let arr = [...sidebarOptions];
                          arr = arr.map((a, k) => {
                            if (i !== 0) {
                              e.preventDefault();
                            } else {
                              a.open = !a.open;
                            }
                            return a;
                          });
                          setSidebarOptions(arr);
                          setActivitySection(arr[0]);
                        }}
                      >
                        {/* <Badge
                          showZero={false}
                          count={i !== 0 ? 0 : 1}
                          color="#ec4242d1"
                          offset={[0, 0]}
                        > */}
                          <Button
                            style={{ border: "none" }}
                            disabled={taskModalState.action === "create"}
                            icon={item.icon}
                          />
                        {/* </Badge> */}
                        <span>{item.key}</span>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </AntModal>
    </div>
  );
};

export default CreateTaskModal;
