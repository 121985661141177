import React, { useEffect, useState } from 'react'
import { Col,Row } from 'reactstrap'
import './common.css';
import ActivityStreamTab from '../ViewPipelinesTabs/ActivityStreamTab';

export const ActivitySection = ({taskId,module, refreshData}) => {
	const [id,setId]=useState();
	useEffect(e=>{
		setId(taskId);
	},[taskId])

	return (
		<Col sm={12}>
			<Row style={{height:"90%"}}>
				<ActivityStreamTab pipelineId={id} module={module} refreshData={refreshData}/>
			</Row>
		</Col>
	)
}
