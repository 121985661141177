import React, { useRef, useEffect, useState, useContext } from 'react';
import { getSmoothStepPath, EdgeLabelRenderer } from 'reactflow';
import { invertColor } from '../../HR/TimeClock/Util';
import AppContext from '../../../Context/AppContext';

const CustomEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  data,
  label,
  onClick,
}) => {
  // console.log("color----------", data);

  // Get the step-like path from React Flow utility
  const [edgePath] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const AppData = useContext(AppContext);
  const { dispatch ,state } = AppData || {};
  const pathRef = useRef(null);
  const [labelPos, setLabelPos] = useState({ x: 0, y: 0 });

  useEffect(() => {
    if (pathRef.current) {
      const pathLength = pathRef.current.getTotalLength(); // Total length of the path
      const position = pathRef.current.getPointAtLength(pathLength * 0.3); // 30% of the path length

      setLabelPos({ x: position.x, y: position.y });
    }
  }, [sourceX, sourceY, targetX, targetY, edgePath]);

  const handleLabelClick = (event) => {
    event.stopPropagation(); // Prevent the click event from bubbling to the edge
    onClick(event); // Call the onClick handler passed down as a prop
  };

  return (
    <>
      {/* Step-like edge path */}
      <path
        ref={pathRef} // Attach the ref to access the path for calculations
        id={id}
        className="react-flow__edge-path"
        d={edgePath}
        style={{
          ...style,
          stroke: data?.disable? "rgb(118 118 118)" : data?.color ? data?.color : "rgb(144 144 144)",
          // opacity: data.disable ? "0.3":"1",
          strokeWidth: 2,
          strokeDasharray: 5, // Dotted pattern for the path
          animation: `${state.EnableFlow && !data?.disable ?"dash-animation":"test"} 3s linear infinite`,
        }}
        markerEnd={markerEnd}
      />

      {label && (
        <EdgeLabelRenderer>
          <div
            style={{
              position: 'absolute',
              transform: `translate(-50%, -50%)`,
              left: labelPos.x,
              top: labelPos.y,
              // opacity: data.disable ? "0.3":"1",
              padding: '2px 4px',
              borderRadius: '3px',
              fontSize: 12,
              pointerEvents: 'all',
              boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
              backgroundColor: data?.disable? "rgb(236 236 236)" :data?.color ? data?.color : "#fff5e0",
              color:  data?.disable? "rgb(118 118 118)" : data?.color ? invertColor(data?.color, true) : "black",
            }}
            className="nopan"
            onClick={handleLabelClick}
          >
            {label}
          </div>
        </EdgeLabelRenderer>
      )}
    </>
  );
};

export default CustomEdge;