import {
  faPaperclip,
  faTrashAlt,
  faAngleUp,
  faChevronCircleDown,
  faEllipsisV,
  faCog,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState, useRef, useContext } from "react";
import SendMessgaeIcon from "./SendMessageIcon.png";
import GroupImg from "../../assets/images/groupImg.jpg";
import defaultUser from "../../assets/images/defaultUser.png";
import config from "../../config.json";
import Picker, { SKIN_TONE_NEUTRAL } from "emoji-picker-react";
import _ from "lodash";
import "./chat.css";
import httpService from "../../services/http.service";
import ChatService from "../util/ChatService";
import TaskService from "../util/TaskService";
import { EmojiReactionPicker } from "../EmojiReactionPicker/EmojiReactionPicker";
import { ChatBodyComponent } from "./ChatBodyComponent";
import { HorizontalCollapseOptions } from "./CollapseOptions/HorizontalCollapseOptions";
import { MentionsCollapse } from "./CollapseOptions/MentionsCollapse";
import UserService from "../util/UserService";
import { GlobalSearchComponent } from "./GlobalSearchComponent";
import { GroupInfoCollapse } from "./CollapseOptions/GroupInfoCollapse";
import PdfViewer from "../../components/PdfPreview/PdfViewer";
import {
  Modal as AntdModal,
  Button,
  Popover as Dropdown,
  Collapse,
} from "antd";
import AppContext from "../../Context/AppContext";
import NotificationAPI from "../util/NotificationAPI";
const Panel = Collapse.Panel;

const ChatModule = (props) => {
  const AppData = useContext(AppContext);
  const { state } = AppData || {};
  const allActiveNitifications = state.AllNotificationsForChat || [];
  const [myProfile, setMyProfile] = useState({});
  const [chatMessageInpute, setchatMessageInpute] = useState("");
  const [chatMessageToSubmit, setchatMessageToSubmit] = useState("");
  const [allUsers, setAllUsers] = useState([]);
  const [allAvailableUsers, setallAvailableUsers] = useState([]);
  const [reloadState, setreloadState] = useState(false);
  const [allAppUsers, setAllAppUsers] = useState([]);
  const [chatContainerRef, setChatContainerRef] = useState(true);
  const [loginUserData, setLoginUserData] = useState({});
  const [SearchedItem, setSearchedItem] = useState("");
  const [messageVal, setMessageVal] = useState("");
  const [selectUser, setSelectUser] = useState(null);
  const [typingUser, setTypingUser] = useState("");
  const [tempState, setTempState] = useState(true);
  const [selectedChatsInChats, setSelectedChatsInChats] = useState([]);
  const [chatMembersDetails, setchatMembersDetails] = useState([]);
  const [clearSelections, setclearSelections] = useState(false);
  const [chatMessages, setChatMessages] = useState([]);
  const [showMentions, setshowMentions] = useState(false);
  const [showAttchments, setshowAttchments] = useState(false);
  const [charToCompare, setcharToCompare] = useState("");
  const [scrollMessageIntoView, setScrollMessageIntoView] = useState(false);
  const [pinnedMassageForReply, setpinnedMassageForReply] = useState(null);
  const [allUsersForSearch, setAllUsersForSearch] = useState([]);
  const [isChecked, setIsChecked] = useState(true);
  const [attachments, setAttachments] = useState([]);
  const [searchInchats, setsearchInChats] = useState("");
  const [externalSearchTrigger, setexternalSearchTrigger] = useState(false);
  const [scrollToEnd, setscrollToEnd] = useState(false);
  const [showGroupCollapse, setShowGroupCollapse] = useState(false);
  const [downclicked, setDownclicked] = useState();
  const [upclicked, setUpclicked] = useState();
  const [userActiveStatus, setUserActiveStatus] = useState();
  const [newMessagesBuffer, setnewMessagesBuffer] = useState([]);
  const [loadChatTrigger, setLoadChatTrigger] = useState(false);
  const [showPriview, setShowPriview] = useState(false);
  const [filePrvData, setFilePrvData] = useState({
    FileName: "",
    Data: "",
    type: "",
  });

  const pickerRef = useRef(null);
  const chatRef = useRef(null);
  const divRef = useRef(null);
  const msgInputRef = useRef(null);
  const messageRef = useRef(null);
  const [msgStyleValue, setMsgStyleValue] = useState("calc(100%-165px)");
  const [chosenEmoji, setChosenEmoji] = useState(null);
  const [showEmoticons, setshowEmoticons] = useState(false);

  const msgStyle = {
    width: "100%",
    height: msgStyleValue,
  };

  useState(
    (e) => {
      setTimeout((e) => {
        let lastMessage = document.querySelector(".last-message");
        lastMessage?.scrollIntoView({
          behavior: "smooth",
        });
      }, 2000);
    },
    [scrollMessageIntoView]
  );

  const bultkOpeeations = async (type) => {
    let arrayOfIds = selectedChatsInChats.map((e) => {
      return e.id;
    });
    if (type && type == "PIN") {
    } else {
      arrayOfIds.forEach(async (id) => {
        await ChatService.DeleteChats(selectUser.chat_id, id)
          .then((e) => {})
          .catch((e) => {
            console.log(e);
          });
      });
      setLoadChatTrigger(!loadChatTrigger);
    }
  };

  const modifySingleChat = (chat, UID) => {
    if (chat?.type == "deleted") {
      return;
    }
    let myReactions = [];
    let othersReactions = [];
    chat["my_like"] = null;
    if (chat.reaction) {
      Object.keys(chat.reaction).forEach((key, i) => {
        let isFound = false;
        chat.reaction[key]?.forEach((d) => {
          if (d) {
            if (d.includes(UID)) {
              if (key == "👍") {
                chat["my_like"] = { emoji: key, user: d };
              } else {
                myReactions.push({ emoji: key, user: d });
              }
            } else if (!d.includes(UID)) {
              othersReactions.push({ emoji: key, user: d });
            }
          }
        });
      });
    }
    chat["my_reactions"] = myReactions;
    chat["others_reactions"] = othersReactions;
    return chat;
  };

  const modifyChatData = (oldData) => {
    let UID = localStorage.getItem("ZUID");
    let newData = [];
    if (oldData) {
      oldData.forEach((chat) => {
        let newChat = modifySingleChat(chat, UID);
        if (newChat) {
          if (newChat.replied_to) {
            newChat["parent_id"] = newChat.replied_to?.id;
          }
          newData.push(newChat);
        }
      });
    }
    return newData;
  };

  useEffect(() => {
    getAllUsers();
    getAllAppUsers();
  }, []);

  useEffect(() => {
    if (selectUser) {
      getUserStatus(selectUser.zuid);
      ChatService.GetChatMessage(selectUser.chat_id)
        .then((result) => {
          let newChatData = modifyChatData(result.data.data);
          setChatMessages(newChatData);
          document.querySelectorAll("Selected-Chat")?.forEach((e) => {
            e.classList.remove("Selected-Chat");
          });
          setSelectedChatsInChats([]);
        })
        .catch((err) => {
          console.log(err);
        });

      ChatService.GetMembersInChat(selectUser.chat_id)
        .then((res) => {
          setchatMembersDetails(res?.data?.data?.users);
        })
        .catch((e) => {
          setchatMembersDetails([]);
          console.log(e);
        });
      if (allActiveNitifications) {
        markPendingChatsAsRead(selectUser);
      }
    }
  }, [
    selectUser,
    loadChatTrigger,
    props.refreshTheChat,
    allActiveNitifications,
  ]);

  const getTime = (date) => {
    let Time = new Date(date);
    var hours = Time.getHours();
    var minutes = Time.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;

    /// date
    if (
      new Date().toLocaleDateString() !== new Date(date).toLocaleDateString()
    ) {
      let dateTime = new Date(date);
      let str = "";
      str = str + dateTime.toLocaleString("en-us", { month: "short" });
      str = str + " " + dateTime.getDate();
      /// year
      if (new Date().getFullYear() !== new Date(date).getFullYear()) {
        strTime = str + " , " + dateTime.getFullYear();
      }
    }
    return strTime;
  };

  const getSelectedUserImg = (chatType) => {
    if (chatType == "dm") {
      if (!_.isEmpty(selectUser.zuid)) {
        return (
          <img src={UserService.GetUserPhotoUrl(selectUser.zuid)} alt="" />
        );
      } else {
        return (
          <div className="wrap">
            <div
              className="no-image-profile mt-10"
              style={{ backgroundColor: `${selectUser.color}` }}
            >
              {selectUser.name.charAt(0)}
            </div>
          </div>
        );
      }
    } else {
      return <img src={GroupImg} alt="" />;
    }
  };

  const onEmojiClick = (event, emojiObject) => {
    let updatedMessageVal = messageVal + emojiObject.emoji;
    setMessageVal(updatedMessageVal);
  };

  const getUserStatus = async (zuid) => {
    let key = await ChatService.GetUserActiveStatus(zuid)
      .then((res) => {
        if (res?.data?.data) {
          setUserActiveStatus(res?.data?.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getSelectedUserOrGroup = (chatType) => {
    if (chatType == "dm") {
      return (
        <>
          {getSelectedUserImg(chatType)}
          <div className="user-name-box mt-8">
            <div>{selectUser ? selectUser.name : ""}</div>
            <div className="user-typing-box">
              {chatType == "dm" ? (
                <span
                  className={`status-icon ${
                    userActiveStatus && userActiveStatus.text
                  }`}
                  title={userActiveStatus && userActiveStatus.text}
                ></span>
              ) : (
                <></>
              )}{" "}
              {typingUser}
            </div>
          </div>
        </>
      );
    }
    if (chatType == "chat") {
      return (
        <>
          <img src={GroupImg} alt="" />
          <div className="user-name-box mt-8">
            <div>{selectUser ? selectUser?.name : ""}</div>
            <div
              className="view-participants"
              onClick={(e) => {
                setShowGroupCollapse(!showGroupCollapse);
              }}
            >
              {selectUser?.recipients_summary?.length} participants
            </div>
            <div className="user-typing-box"> {typingUser}</div>
          </div>
        </>
      );
    }
  };

  const doSearch = (e) => {
    let user_id = localStorage.getItem("ZUID");
    let data = [];
    if (e != "") {
      allUsersForSearch.map((one) => {
        if (
          one.name.toLowerCase().includes(e.toLowerCase()) ||
          one.recipients_summary.some(
            (a) =>
              a.name.toLowerCase().includes(e.toLowerCase()) &&
              user_id != a.user_id
          )
        ) {
          data.push(one);
        }
      });
      setAllUsers(data);
    } else {
      setAllUsers(allUsersForSearch);
    }
  };

  const getAllAppUsers = async () => {
    UserService.GetAllUsers()
      .then((result) => {
        setAllAppUsers(result.data.users);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllUsers = async () => {
    props.setLoader(true);
    let config = {
      Type: "Get",
      method: "GET",
      url: "https://cliq.zoho.com/api/v2/chats",
      headers: { Authorization: localStorage.getItem("token") },
    };
    await httpService
      .post("workdrive", { config: config })
      .then((result) => {
        result.data.chats = result.data.chats.sort(
          (a, b) =>
            new Date(b?.last_message_info.time) -
            new Date(a?.last_message_info.time)
        );
        return result;
      })
      .then((result) => {
        let arr = result.data.chats.map((u) => {
          let user = u.recipients_summary?.find((e) => e.name == u.name);
          if (user) {
            u["zuid"] = user.user_id;
          }
          return u;
        });

        setallAvailableUsers(arr);
        setreloadState(!reloadState);
        arr = arr.filter(
          (one) =>
            one.name !== "Notes" &&
            (one.chat_type == "chat" || one.chat_type == "dm")
        );
        setAllUsers(arr);
        setAllUsersForSearch(arr);
        props.setLoader(false);
      })
      .catch((err) => {
        props.setLoader(false);
        console.log(err);
      });
  };

  const EmojiData = (chosenEmoji) => {
    return chosenEmoji.emoji;
  };

  const convertZohoClickToCRM = (message) => {
    const users = [...props.allUsers];
    users.forEach((one) => {
      let fromStr = `<span id="User-${one.zuid}" name="${one.full_name}" class="mymention" data-mention-id="${one.zuid}">@${one.full_name}</span>`;
      let toStr = `<span uid="${one.zuid}" elemtype="contact" contenteditable="false" class="selusr sel" nodetype="@" data-title="@${one.first_name}" title="@Aditya">@${one.first_name}</span>`;
      message = message.toString("html");
      message = message?.replaceAll(fromStr, "{@" + one.zuid + "}");
    });
    return message;
  };

  const handleSendMessage = (message) => {
    props.setLoader(true);
    props.allUsers?.forEach((e, i) => {
      message = message?.replaceAll(`@${e.full_name}`, `{@${e.zuid}}`);
    });
    message = message?.toString("html");
    message = convertZohoClickToCRM(message);
    message = message.replace("<p>", "");
    message = message.replace("</p>", "");
    message = message?.replaceAll("&nbsp;", " ");
    if (attachments && attachments.length > 0) {
      const formData = new FormData();
      attachments.forEach((e) => {
        formData.append("files", e);
        formData.append("comments", message);
      });
      ChatService.SendAttachmentToUser(selectUser.chat_id, formData)
        .then(async (result) => {
          let userInfo = JSON.parse(localStorage.getItem("userinfo"));
          setAttachments([]);
          setchatMessageInpute("");
          setpinnedMassageForReply(null);
          let text = `<a style="color:#40a9ff !important" id="User-${
            userInfo?.email_id
          }">${
            userInfo?.first_name + " " + userInfo?.last_name
          }</a>  <span> sent you a attachment in </span>  <a style="color:#40a9ff !important" id="Chat-${
            selectUser.chat_id
          }-${result.data.message_id}">${
            selectUser.chat_type == "chat"
              ? "Group"
              : selectUser.chat_type == "dm" && "Private"
          } Chat</a> `;
          await TaskService.SendMentionNotification(
            message,
            props.allUsers,
            text,
            "Attachment",
            selectUser?.recipients_summary.filter(
              (e) => e.user_id != localStorage.getItem("ZUID")
            ),
            "Attachment"
          );
          await TaskService.SendMentionNotification(
            message,
            props.allUsers,
            text,
            "Chat"
          );
          return ChatService.GetChatMessage(selectUser.chat_id);
        })
        .then((result) => {
          setChatMessages(result.data.data);
          props.getNotification();
          props.setLoader(false);
        })
        .catch((err) => {
          props.setLoader(false);
          console.log(err);
        });
    } else {
      ChatService.SendMessageToUser(
        selectUser.chat_id,
        message,
        pinnedMassageForReply
      )
        .then(async (result) => {
          let userInfo = JSON.parse(localStorage.getItem("userinfo"));
          setchatMessageInpute("");
          setpinnedMassageForReply(null);
          if (message.includes("@")) {
            let text = `<a style="color:#40a9ff !important" id="User-${
              userInfo?.email_id
            }">${
              userInfo?.first_name + " " + userInfo?.last_name
            }</a>  <span> Mentioned you in a </span>  <a style="color:#40a9ff !important" id="Chat-${
              selectUser.chat_id
            }-${result.data.message_id}">${
              selectUser.chat_type == "chat"
                ? "Group"
                : selectUser.chat_type == "dm" && "Private"
            } Chat</a> `;
            await TaskService.SendMentionNotification(
              message,
              props.allUsers,
              text,
              "Chat"
            );
          } else {
            let text = `<a style="color:#40a9ff !important" id="User-${
              userInfo?.email_id
            }">${
              userInfo?.first_name + " " + userInfo?.last_name
            }</a>  <span> sent you a message in </span>  <a style="color:#40a9ff !important" id="Chat-${
              selectUser.chat_id
            }-${result.data.message_id}">${
              selectUser.chat_type == "chat"
                ? "Group"
                : selectUser.chat_type == "dm" && "Private"
            } Chat</a> `;
            await TaskService.SendMentionNotification(
              message,
              props.allUsers,
              text,
              "Message",
              selectUser?.recipients_summary.filter(
                (e) => e.user_id != localStorage.getItem("ZUID")
              ),
              "Message"
            );
          }
          return ChatService.GetChatMessage(selectUser.chat_id);
        })
        .then((result) => {
          setChatMessages(result.data.data);
          props.getNotification();
          props.setLoader(false);
        })
        .catch((err) => {
          props.setLoader(false);
          console.log(err);
        });
    }
  };

  const convertZohoClickMessageToCRM1 = (message) => {
    if (!message) {
      message = "";
    }
    const users = [...props.allUsers];
    users.forEach((one) => {
      let fromStr = `<span id="User-${one.zuid}" name="${one.full_name}" class="mymention" data-mention-id="${one.zuid}">@${one.full_name}</span>`;
      if (message) {
        message = message.toString("html");
        message = message?.replaceAll("{@" + one.zuid + "}", fromStr);
      } else {
        message = "";
      }
    });
    return `<p>${message}</p>`;
  };

  useEffect((e) => {
    if (localStorage.getItem("radioOptions") === "sentMessage") {
      setIsChecked(false);
    }
    let userString = localStorage.getItem("userinfo");
    if (userString) {
      setMyProfile(JSON.parse(userString));
    }
  }, []);

  const closePreview = (e) => {
    setFilePrvData({
      FileName: "",
      Data: "",
    });
    setShowPriview(false);
  };

  const openImagePreview = async (file) => {
    if (file) {
      let obj = {};
      if (file.type.includes("image") || file.type.includes("png")) {
        obj["type"] = "jpeg";
      } else if (file.type.includes("pdf")) {
        obj["type"] = "pdf";
      }
      props.setLoader(true);
      await ChatService.GetAttachmentById(file.id, obj.type)
        .then((data) => {
          obj["FileName"] = file?.name;
          obj["Data"] = data.data;
          setFilePrvData(obj);
          setShowPriview(true);
          props.setLoader(false);
        })
        .catch((e) => {
          props.setLoader(false);
          console.log(e);
        });
    }
  };

  const getPDFPrevModal = () => {
    return (
      <AntdModal
        wrapClassName="Do-Not-Close"
        open={showPriview}
        title={filePrvData?.FileName}
        width={900}
        style={{ top: 170, borderRadius: 50 }}
        onCancel={closePreview}
        footer={null}
      >
        <PdfViewer
          type={filePrvData?.type}
          location={"chats"}
          fileData={filePrvData?.Data}
        ></PdfViewer>
      </AntdModal>
    );
  };

  const getPendingReadMessagesCount = (user) => {
    if (allActiveNitifications && allActiveNitifications.length > 0) {
      return allActiveNitifications.filter(
        (e) =>
          e?.Notifications?.Text?.includes(user.chat_id) &&
          !e?.Notifications?.IsRead
      )?.length;
    } else {
      return undefined;
    }
  };

  const markPendingChatsAsRead = async (user) => {
    if (allActiveNitifications && allActiveNitifications.length > 0) {
      let thisChatNotification = [...allActiveNitifications];
      let doArr = [];
      if (thisChatNotification) {
        let arr = thisChatNotification.map(async (one) => {
          if (one.Notifications.Text?.includes(user.chat_id)) {
            one.Notifications.IsRead = true;
            doArr.push(one);
            await NotificationAPI.UpdateNotification(one.Notifications)
              .then((res) => {})
              .catch((e) => {
                console.log(e);
              });
          }
          return one;
        });
      }
      setnewMessagesBuffer(doArr);
      props.getNotification();
      setTimeout((e) => {
        setnewMessagesBuffer([]);
      }, 10000);
    }
  };
  return (
    <div ref={chatRef}>
      <div id="frame">
        <div id="sidepanel">
          <div id="profile">
            <div className="wrap">
              <div>
                <img
                  src={localStorage.getItem("ProfilePhoto")}
                  className="online"
                  alt=""
                />
                <p className="m-0 px-2">{localStorage.getItem("UserName")}</p>
              </div>

              <div id="status-options">
                <ul>
                  <li id="status-online" className="active">
                    <span className="status-circle"></span> <p>Online</p>
                  </li>
                  <li id="status-away">
                    <span className="status-circle"></span> <p>Away</p>
                  </li>
                  <li id="status-busy">
                    <span className="status-circle"></span> <p>Busy</p>
                  </li>
                  <li id="status-offline">
                    <span className="status-circle"></span> <p>Offline</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div id="search">
            <label for="">
              <i className="fa fa-search" aria-hidden="true"></i>
            </label>
            <input
              type="text"
              placeholder="Search contacts or groups..."
              value={SearchedItem}
              onChange={(e) => {
                setSearchedItem(e.currentTarget.value);
                doSearch(e.target.value, "allUserSearch");
              }}
            />
          </div>
          <div id="contacts">
            {allUsers.map((e) => {
              return (
                <ul id={"ChatId-" + e.chat_id}>
                  <li
                    id={"Notification" + e.chat_id}
                    className={
                      selectUser && selectUser.chat_id == e.chat_id
                        ? "contact selected-user-color"
                        : "contact"
                    }
                    onClick={() => {
                      setShowGroupCollapse(false);
                      setSelectUser(e);
                      setTempState(!tempState);
                      markPendingChatsAsRead(e);
                    }}
                  >
                    <div className="wrap">
                      {e.chat_type == "dm" ? (
                        !_.isEmpty(e?.zuid) ? (
                          <img
                            key={Date.now()}
                            cache={false}
                            src={
                              e?.zuid
                                ? UserService.GetUserPhotoUrl(e?.zuid)
                                : defaultUser
                            }
                          />
                        ) : (
                          <div
                            className="no-image-profile mr-10"
                            style={{ backgroundColor: `${e.color}` }}
                          >
                            {e.name.charAt(0)}
                          </div>
                        )
                      ) : (
                        <img key={Date.now()} cache={false} src={GroupImg} />
                      )}

                      <div className="meta">
                        <p className="name">{e.name}</p>
                      </div>
                      {getPendingReadMessagesCount(e) > 0 ? (
                        <div className="pending-message-count chat-pending-count">
                          {getPendingReadMessagesCount(e)}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </li>
                </ul>
              );
            })}
          </div>
          <div className="setting-dropdown">
            <Dropdown
              overlayStyle={{ zIndex: 10050, width: "10%" }}
              overlayClassName="Do-Not-Close Setting-In-Chat"
              className="enter-btn-setting"
              placement="bottom"
              arrowPointAtCenter
              trigger={["click"]}
              content={
                <>
                  <Collapse activeKey={["1"]}>
                    <Panel
                      header="Message Behavior"
                      key={"1"}
                      style={{ overflow: "hidden" }}
                    >
                      <ul className="collapseOptionsSettings">
                        <li className="collapseOptionsSettingsLi">
                          <input
                            style={{ marginRight: "5px" }}
                            type="radio"
                            name="settingRadio"
                            value="sentMessage"
                            checked={!isChecked}
                            onClick={(e) => {
                              setIsChecked(false);
                              localStorage.setItem(
                                "radioOptions",
                                e.target.value
                              );
                            }}
                          />
                          Send on Enter.
                        </li>
                        <li className="collapseOptionsSettingsLi">
                          <input
                            style={{ marginRight: "5px" }}
                            type="radio"
                            name="settingRadio"
                            value="paragraph"
                            checked={isChecked}
                            onClick={(e) => {
                              setIsChecked(true);
                              localStorage.setItem("radioOptions", "");
                            }}
                          />
                          Next line on Enter.
                        </li>
                      </ul>
                    </Panel>
                  </Collapse>
                </>
              }
            >
              <Button
                style={{ border: "1px", background: "#018ee0" }}
                onClick={(e) => e.preventDefault()}
              >
                <FontAwesomeIcon
                  style={{ height: "1rem", width: "1rem", color: "black" }}
                  icon={faCog}
                />
              </Button>
            </Dropdown>
          </div>
        </div>
        <div className="content">
          {getPDFPrevModal()}
          <div className="contact-profile">
            {getSelectedUserOrGroup(selectUser?.chat_type)}
            <div className="Actions-For-Selection">
              <GlobalSearchComponent
                setexternalSearchTrigger={setexternalSearchTrigger}
                setDownclicked={setDownclicked}
                setUpclicked={setUpclicked}
                externalSearchTrigger={externalSearchTrigger}
                searchInchats={searchInchats}
                settextToSearch={setsearchInChats}
                action={"enter"}
              />
              <div className="Selected-User-Actions">
                {selectedChatsInChats && selectedChatsInChats.length > 0 ? (
                  <>
                    <FontAwesomeIcon
                      className="Fade-Btn"
                      icon={faTrashAlt}
                      style={{ marginLeft: "7px" }}
                      onClick={(e) => {
                        bultkOpeeations("DELETE");
                      }}
                    />
                    <span
                      className="Fade-Btn"
                      onClick={(e) => {
                        setclearSelections(!clearSelections);
                      }}
                    >
                      Cancel
                    </span>
                  </>
                ) : (
                  <></>
                )}
              </div>
              <FontAwesomeIcon
                id="addGroup"
                className="create-user-group-icon"
                style={{ cursor: "pointer" }}
                icon={faEllipsisV}
                onClick={(e) => {
                  setShowGroupCollapse(!showGroupCollapse);
                }}
              ></FontAwesomeIcon>
            </div>
          </div>
          {showGroupCollapse && selectUser ? (
            <GroupInfoCollapse
              chatMembersDetails={chatMembersDetails}
              setchatMembersDetails={setchatMembersDetails}
              reloadState={reloadState}
              setSelectUser={setSelectUser}
              setLoader={props.setLoader}
              loader={props.loader}
              getAllUsers={getAllUsers}
              showGroupCollapse={showGroupCollapse}
              setShowGroupCollapse={setShowGroupCollapse}
              selectUser={selectUser}
              setChatMessages={setChatMessages}
              allAvailableUsers={allAvailableUsers}
              setallAvailableUsers={setallAvailableUsers}
              chatMessages={chatMessages}
            />
          ) : (
            <div
              id="message-body"
              className="messages"
              ref={messageRef}
              style={msgStyle}
            >
              <ChatBodyComponent
                refreshTheChat={props.refreshTheChat}
                setChatContainerRef={setChatContainerRef}
                newMessagesBuffer={newMessagesBuffer}
                setnewMessagesBuffer={setnewMessagesBuffer}
                setRefreshChat={props.setRefreshChat}
                scrollToEnd={scrollToEnd}
                externalSearchTrigger={externalSearchTrigger}
                upclicked={upclicked}
                downclicked={downclicked}
                openImagePreview={openImagePreview}
                searchInchats={searchInchats}
                clearSelections={clearSelections}
                setSelectedChatsInChats={setSelectedChatsInChats}
                allUsers={allUsers}
                appUser={myProfile}
                pinnedMassageForReply={pinnedMassageForReply}
                setpinnedMassageForReply={setpinnedMassageForReply}
                tempState={tempState}
                setChatMessages={setChatMessages}
                chatMessages={chatMessages}
                convertZohoClickMessageToCRM1={convertZohoClickMessageToCRM1}
                getTime={getTime}
                selectUser={selectUser}
              />
            </div>
          )}
          {selectUser ? (
            <div
              className={
                loginUserData.onEnterChat != "sentMessage"
                  ? "message-input"
                  : "message-input"
              }
              ref={msgInputRef}
            >
              <div
                ref={divRef}
                className="common-chat-module wrap pos-relative"
              >
                <div className="Chat-Input-Wrapper">
                  {pinnedMassageForReply && (
                    <div className="Pinned-Reply-Massage-Wrapper">
                      <p className="massage-body-wrapper">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: convertZohoClickMessageToCRM1(
                              pinnedMassageForReply.content?.text
                            ),
                          }}
                        ></div>
                        {pinnedMassageForReply?.content?.thumbnail?.blur_data &&
                          pinnedMassageForReply?.content?.file?.type.includes(
                            "image/png"
                          ) && (
                            <div className="Attachment-Wrapper-Chats">
                              <img
                                src={`data:image/png;base64,${pinnedMassageForReply?.content?.thumbnail?.blur_data}`}
                              />
                            </div>
                          )}
                        <HorizontalCollapseOptions
                          callBack={(e) => {
                            setpinnedMassageForReply(null);
                          }}
                          closeIcon={true}
                          currentMessage={pinnedMassageForReply}
                        />
                        <span className="m-time">
                          {getTime(new Date(pinnedMassageForReply.time))}
                        </span>
                        <div className="reaction-chat-wrapper">
                          <EmojiReactionPicker
                            type="CHAT"
                            setNotesData={setChatMessages}
                            chatId={selectUser?.chat_id}
                            notesReactions={chatMessages}
                            messageId={pinnedMassageForReply.id}
                          />
                        </div>
                      </p>
                    </div>
                  )}

                  <div className="Input-Box">
                    <MentionsCollapse
                      charToCompare={charToCompare}
                      allUsers={allAppUsers}
                      setchatMessageInpute={setchatMessageInpute}
                      setchatMessageToSubmit={setchatMessageToSubmit}
                      chatMessageToSubmit={chatMessageToSubmit}
                      chatMessageInpute={chatMessageInpute}
                      showMentions={showMentions}
                      setshowMentions={setshowMentions}
                    />
                    <MentionsCollapse
                      charToCompare={"Attachments"}
                      allUsers={allAppUsers}
                      setchatMessageInpute={setchatMessageInpute}
                      setchatMessageToSubmit={setchatMessageToSubmit}
                      chatMessageToSubmit={chatMessageToSubmit}
                      chatMessageInpute={chatMessageInpute}
                      showMentions={showAttchments}
                      attachments={attachments}
                      setAttachments={setAttachments}
                      setshowMentions={setshowAttchments}
                    />

                    <div
                      className="Inpute-Body"
                      style={selectUser?.removed ? { opacity: "0.5" } : {}}
                    >
                      {attachments?.length > 0 && !showAttchments && (
                        <div
                          className="Attachment-Btn"
                          onClick={(e) => {
                            setshowAttchments(!showAttchments);
                          }}
                        >
                          <span>{`Attachments (${attachments?.length})`}</span>
                          <FontAwesomeIcon icon={faAngleUp} />
                        </div>
                      )}
                      <textarea
                        readOnly={selectUser?.removed}
                        id="Input-Text-Area"
                        value={chatMessageInpute}
                        onKeyDown={(e) => {
                          if (
                            e.key == "Enter" &&
                            localStorage.getItem("radioOptions") ===
                              "sentMessage"
                          ) {
                            e.preventDefault();
                            if (chatMessageInpute) {
                              handleSendMessage(chatMessageInpute);
                              console.log(chatMessageInpute);
                            }
                          }
                        }}
                        onChange={(e) => {
                          let text = e.currentTarget.value;
                          let arr = text.split(" ");
                          if (arr[arr.length - 1].includes("@")) {
                            setcharToCompare("@");
                            setshowMentions(true);
                          } else if (arr[arr.length - 1].includes("#")) {
                            setcharToCompare("#");
                            setshowMentions(true);
                          } else {
                            setshowMentions(false);
                          }
                          setchatMessageInpute(text);
                        }}
                        placeholder="Message"
                      />
                      <div className="Buttons-Groups">
                        <FontAwesomeIcon
                          opacity={chatContainerRef ? 0 : 1}
                          color="#018cdc"
                          className="scroll-down-icon"
                          onClick={(e) => {
                            setscrollToEnd(!scrollToEnd);
                          }}
                          icon={faChevronCircleDown}
                        ></FontAwesomeIcon>
                        <input
                          id="Attachment"
                          onChange={(e) => {
                            let selectedFile = e.currentTarget.files[0];
                            if (attachments?.length > 0) {
                              if (
                                !attachments.find(
                                  (a) => a.name == selectedFile.name
                                )
                              ) {
                                setAttachments([...attachments, selectedFile]);
                              } else {
                                alert("File already selected!");
                              }
                            } else {
                              setAttachments([selectedFile]);
                            }
                          }}
                          type="file"
                          hidden
                        />
                        <FontAwesomeIcon
                          className="attachment-icon"
                          onClick={(e) => {
                            if (!selectUser?.removed) {
                              document.getElementById("Attachment").click();
                            }
                          }}
                          icon={faPaperclip}
                        />
                        <img
                          src={SendMessgaeIcon}
                          onClick={(e) => {
                            if (
                              (chatMessageInpute || attachments) &&
                              !selectUser?.removed
                            ) {
                              handleSendMessage(chatMessageInpute);
                              console.log(chatMessageInpute);
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="clear"></div>
              </div>
              {showEmoticons && (
                <div ref={pickerRef} className="emoji-select-box">
                  <Picker
                    className="emoticon-select-box"
                    onEmojiClick={onEmojiClick}
                    disableAutoFocus={true}
                    skinTone={SKIN_TONE_NEUTRAL}
                    groupNames={{ smileys_people: "PEOPLE" }}
                    native={false}
                  />
                  {chosenEmoji ? <span>{EmojiData(chosenEmoji)}</span> : <></>}
                </div>
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};
export default ChatModule;
