import {
  faEdit,
  faStar,
  faTimesCircle,
  faTrash,
  faAngleDown
} from "@fortawesome/free-solid-svg-icons";
import { Dropdown as AntDropDown, Collapse, Select } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import Config from '../../config.json'
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  Row,
} from "reactstrap";
import upstar from "../../assets/images/star1.png";

import "./tabs.css";
import Loader from "../loader";
import { useParams } from "react-router-dom";
import axios from "axios";
import _, { isArray } from "lodash";
import { SyncAlt } from "@material-ui/icons";
import { Popover as AntdPopover } from "antd";

// import { Collapse } from 'antd';
const Panel = Collapse.Panel;

function TabView(props) {
  const location = { ...props.location };
  const params = useParams();
  location.state = props.params ? props.params : params;
  const [allViews, setAllViews] = useState([]);
  const [favViews, setFavViews] = useState([]);
  const [publicViews, setPublicViews] = useState([]);
  const [createByMeViews, setCreatedByMeViews] = useState([]);
  const [key, setKey] = useState("");
  const [saveViewModal, setsaveViewModal] = useState(false);
  const [deleteWarn, setDeleteWarn] = useState(false);
  const [showPopOver, setShowPopOver] = useState(false);
  const [tabrename, settabrename] = useState("");
  const [publicCheck, setPublicCheck] = useState(false);
  const [savingId, setSavingId] = useState("");
  const [editFlag, setViewEditFlag] = useState(false);
  const [closeTabId, setCloseTabId] = useState(null);
  // const [nameEdited, setNameEdited] = useState();
  const [data, setData] = useState([]);
  const [deleteViewId, setDeleteViewId] = useState("");
  const [loader, setLoader] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [tab, setTab] = useState();
  const [viewDropDown, setViewDropDown] = useState(false)
  
  let currntUserInfo = JSON.parse(localStorage.getItem("userinfo"));
  const btnRef = useRef();

  useEffect(() => {
    if (location.state && location.state.module == "Deals") {
      loadTabData(true);
      props.setInitialLoad(true);
    }else if(location.state && location.state.module == "Accounts"){
      loadTabData(true);
    }else if(location.state && location.state.module == "Vendors"){
      loadTabData(true);
    }
  }, []);
  useEffect(() => {
    if(location.state && location.state.module == "Deals"){
      props.setActiveFilterAndGetActiveFilters({}, "SET", []);
      let activeTab = localStorage.getItem("ActiveTab");
      if (activeTab && activeTab != "undefined") {
        setTimeout(e => handleTabSelection(activeTab, null), 2000)
      }
    }
  }, [props.viewType])

  useEffect(e=>{ 
     
    let modifyKey = key?.replace("tabId", "");
    if(modifyKey){
      let filterViewData=allViews.filter(v=>v._id==modifyKey)
      if(filterViewData && modifyKey!="alldeal"){
        let obj = [];
        let thisColumnFilters = {};
        filterViewData[0].columns?.forEach(column => {
          
          if (column.Applied_Filters_On_Column) {
            thisColumnFilters[column.field] = column.Applied_Filters_On_Column;
          }
        })
        // if(location.state.module == "Deals"){
        props.setActiveFilterAndGetActiveFilters(thisColumnFilters, "SET", obj);
        // }
      }else{
        // if(location.state.module == "Deals"){
          props.setActiveFilterAndGetActiveFilters({}, "SET", []);
        // }
      }
    }
  },[props.refreshTab])

  const loadTabData = async (allViewFlag, tabObj) => { 
    // if(nameEdited){
    //   setNameEdited(false);
    //   return;
    // }

    let user = JSON.parse(localStorage.getItem("userinfo"));
    let obj = {
      _id: "alldeal",
      IsPublic: true,
      module: location.state.module,
      ViewName: "All " + location.state.module,
      columns: props.headerData,
    };
    let nycObject = {
      _id: "nycDeal",
      IsPublic: true,
      module: location.state.module,
      ViewName: "NYC " + location.state.module,
      columns: props.headerData,
    };
    let outsideNycObject = {
      _id: "nonNycDeal",
      IsPublic: true,
      module: location.state.module,
      ViewName: "Outside NYC " + location.state.module,
      columns: props.headerData,
    };
    setIsPopoverOpen(false);
    setLoader(true);
    // if (tabObj) {
    //   setLoader(false);
    //   let filtersss = [];
    //   let colFilter = {};
    //   tabObj.columns.filter((q) => {
    //     try {
    //       if (q.Applied_Filters) {
    //         if(q.Applied_Filters.logic){
    //           filtersss.push(q.Applied_Filters);
    //         }else{
    //           filtersss=[q.Applied_Filters,...filtersss]
    //         }
    //       }
    //       if (q.Applied_Filters_On_Column) {
    //         colFilter[q.field] = q.Applied_Filters_On_Column;
    //       }
    //     } catch (error) { }
    //   });
    //   props.setActiveFilterAndGetActiveFilters(
    //     colFilter,
    //     "SET",
    //     filtersss
    //   );
    //   return;
    // }
    try{
      let response = await axios.get(`${Config.backend_url}getViews`)
      .then(async (response) => {  
        if(response && response.data){
          if (response.data == "") {
            response.data = [];
          }
          response.data = response.data.filter(
            (one) => one.module == location.state.module
          );
          response.data = response.data.filter(
            (one) => one.UserId == user.user_id || one.IsPublic
          );
          setData(response.data); 
          let obj1 = response.data;
          if(location.state.module == "Deals"){
            obj1.splice(0, 0, obj, nycObject, outsideNycObject);
          }else{
            obj1.splice(0, 0, obj);
          }
          let open = obj1.filter(
            (one) => one.IsOpen || one.ViewName.includes("All")
          );
          let byMe = obj1.filter((one) => one.IsCreatedByMe === true);
          let publicView = obj1.filter((one) => one.IsPublic === true);
          let favView = obj1.filter((one) => one.IsFavourite === true);
          setCreatedByMeViews(byMe);
          setPublicViews(publicView);
          setFavViews(favView);
          // let filterViewdata = open.filter(
          //   (one) => one._id === response.data._id
          // );
          if (allViewFlag) {
             
            if (open.length == 1) {
              props.getViewColumns("All");
              // if(location.state.module == "Deals"){
                props.setActiveFilterAndGetActiveFilters({}, "SET", []);
              // }
            } else {
              props.getViewColumns(open[open.length - 1].columns);
            }
          }
          let currentTabData=open;
          if(tabObj){
            currentTabData=open?.filter(v=>v._id==tabObj._id)
          }
          if (currentTabData) {
            let filtersss = [];
            let colFilter = {};
            // currentTabData[currentTabData.length - 1].columns.forEach((q) => {
            //   try {
            //     if (q.Applied_Filters) {
            //       if(q.Applied_Filters.logic){
            //         filtersss.push(q.Applied_Filters);
            //       }else{
            //         filtersss=[q.Applied_Filters,...filtersss]
            //       }
            //     }
            //     if (q.Applied_Filters_On_Column) {
            //       colFilter[q.field] = q.Applied_Filters_On_Column;
            //     }
            //   } catch (error) { }
            // });
            props.getViewColumns(currentTabData[currentTabData.length - 1].columns);
            if(currentTabData[currentTabData.length - 1].appliedFilters){
              filtersss=currentTabData[currentTabData.length - 1].appliedFilters;
            }
            if(currentTabData[currentTabData.length - 1].columnFilters){
              colFilter=currentTabData[currentTabData.length - 1].columnFilters;
            }
            // if(location.state.module == "Deals"){
              props.setActiveFilterAndGetActiveFilters(
                  colFilter,
                  "SET",
                  filtersss
                );
              // props.setPreAppliedFiltersForView(filtersss)
            // }
            }
          setAllViews(open);
          setKey("tabId" + currentTabData[currentTabData.length - 1]._id);
          setLoader(false);
        }
        return response;
      })
       
     
    }catch(err){
      console.log(err)
      setLoader(false);
    }
    

   
  };

  // const saveViewFromActionBtn = (flag) => {
  //   if (key) {
  //     let temp = key.replace("tabId", "");
  //     let currentView = allViews.find((v) => v._id == temp);
  //     if (currentView) {
  //       if (flag == "flag1") {
  //         togglesaveViewModal(currentView._id, currentView.ViewName);
  //       } else if (flag == "flag2") {
  //         handleUpdateView(currentView);
  //       }
  //       props.dealListTriggers({ type: "SaveAsView", status: undefined });
  //     }
  //   }
  // };

  // useEffect(() => {
  //   if (props.dealListStatus && props.dealListStatus.SaveAsView == "SAVE") {
  //     saveViewFromActionBtn("flag1");
  //   } else if (
  //     props.dealListStatus &&
  //     props.dealListStatus.SaveAsView == "SAVE VIEW"
  //   ) {
  //     saveViewFromActionBtn("flag2");
  //   }
  // }, [props.dealListStatus]);
  useEffect(
    (e) => {
      try {
        if (key && allViews) {
          let temp = key.replace("tabId", "");
          let currentView = allViews.find((v) => v._id == temp);
          if (currentView) {
            props.dealListTriggers({
              type: "SetActiveTab",
              status: currentView.ViewName,
            });
          }
        }
      } catch (error) { }
    },
    [key]
  );

  const handleCloseView = async (viewId, tab) => {
     
    let viewData = [...allViews];
    let currentColsedView=viewData.filter((one) => one._id == viewId);
    if(currentColsedView){
      let obj = [ ...currentColsedView ];
      obj[0].IsOpen = false;
      if (viewId !== "alldeal") {
        updateViewSimple(obj[0]);
      }
    }
    viewData = viewData.filter((one) => one._id !== viewId);
    let lastOpenView = viewData[viewData.length - 1];
    if (!lastOpenView && viewData.length == 0) {
      let obj1 = {
        _id: "alldeal",
        IsPublic: true,
        module: location.state.module,
        ViewName: "All " + location.state.module,
        Columns: props.headerData,
      };
      viewData.push(obj1);
      setAllViews(viewData);
      setKey("tabId" + obj1._id);
      props.getViewColumns("All");
      // if(location.state.module == "Deals"){
      props.setActiveFilterAndGetActiveFilters({}, "SET", []);
      // }
    } else {
      setAllViews(viewData);
      setKey("tabId" + lastOpenView._id);
      if (lastOpenView._id == "alldeal") {
        if(location.state.module == "Deals"){
        props.setActiveFilterAndGetActiveFilters({}, "SET", []);
        await props.setCheckType({ type: "All_Deals" });
        }
        props.getViewColumns("All");
      } else if (lastOpenView._id == "nycDeal") {
        if(location.state.module == "Deals"){
        props.setActiveFilterAndGetActiveFilters({}, "SET", []);
        await props.setCheckType({ type: "NYC_Deals" });
        }
        props.getViewColumns("All");
      } else if (lastOpenView._id == "nonNycDeal") {
        if(location.state.module == "Deals"){
        props.setActiveFilterAndGetActiveFilters({}, "SET", []);
        await props.setCheckType({ type: "NON_NYC_Deals" });
        }
        props.getViewColumns("All");
      }
      else {
        if(location.state.module == "Deals"){
        await props.setCheckType({ type: "" });
        }
        props.getViewColumns(lastOpenView.columns);
        let object = [];
        let thisColumnFilters = {};
        // lastOpenView.columns?.forEach((column) => {
          
        //   if (column.Applied_Filters_On_Column) {
        //     thisColumnFilters[column.field] = column.Applied_Filters_On_Column;
        //   }
        // });
        if(lastOpenView.appliedFilters){
          object=lastOpenView.appliedFilters;
        }
        if(lastOpenView.columnFilters){
          thisColumnFilters=lastOpenView.columnFilters;
        }
        // if(location.state.module == "Deals"){
        props.setActiveFilterAndGetActiveFilters(
          thisColumnFilters,
          "SET",
          object
        );
        // }
      }
      
    }
  };

  const handleUpdateView = async (tabObj) => {
      
    let newColsForUpdate = props.headerData;
    tabObj = { ...tabObj };
    tabObj["columns"] = newColsForUpdate;
    tabObj["appliedFilters"] = props.appliedFiltersForView;
    tabObj["columnFilters"] =props.activeFiltersOnColumnLevel;
    setLoader(true);
    try {
      await axios.post(Config.backend_url+"updateView",tabObj).then((result) => result)
      setLoader(false);
      await loadTabData(false,tabObj);
      let views=[...allViews]
      views.map((v)=>{
        if(v.ViewName==tabObj.ViewName){
          v["appliedFilters"]=tabObj["appliedFilters"];
          v["columnFilters"]=tabObj["columnFilters"];        
        }
      })
      setAllViews(views);
     
    } catch (err) {
      console.log(err);
      setLoader(false);
    }
  };

  const openView = async (viewId, pushState) => {
      
    let nycObject = {
      _id: "nycDeal",
      IsPublic: true,
      module: location.state.module,
      ViewName: "NYC " + location.state.module,
      columns: props.headerData,
    };
    let outsideNycObject = {
      _id: "nonNycDeal",
      IsPublic: true,
      module: location.state.module,
      ViewName: "Outside NYC " + location.state.module,
      columns: props.headerData,
    };
    if (pushState) {
      let modifyId = key.replace("tabId", "");
      if (modifyId == viewId) {
        return
      }
    }
    if (location.state) {
      setLoader(true)
       await axios.get(Config.backend_url+'getViews')
      .then(async (response) => {
        let filterViewData = response.data.filter(one => one._id === viewId);
        if (pushState) {
          if (viewId == "nycDeal" && location.state.module == "Deals") {
            await props.setCheckType({ type: "NYC_Deals" });
            let viewData = [...allViews];
            if (
              viewData.filter(
                (one) => one.ViewName == "nycDeal"
              ).length == 0
            ) {
              viewData.push(nycObject);
              setAllViews(viewData);
              setKey("tabId" + viewId);
              if(location.state.module == "Deals"){
                props.setActiveFilterAndGetActiveFilters(null, "SET", null);
                }
              setLoader(false);
            }
            return
          } else if (viewId == "nonNycDeal" && location.state.module == "Deals") {
            await props.setCheckType({ type: "NON_NYC_Deals" });
            let viewData = [...allViews];
            if (
              viewData.filter(
                (one) => one.ViewName == "nycDeal"
              ).length == 0
            ) {
              viewData.push(outsideNycObject);
              setAllViews(viewData);
              setKey("tabId" + viewId);
              if(location.state.module == "Deals"){
              props.setActiveFilterAndGetActiveFilters(null, "SET", null);
              }
              setLoader(false);
            }
            return
          }
          if(location.state.module == "Deals"){
            props.setCheckType({ type: "" });
          }
          let obj = [];
          let thisColumnFilters = {};
          // filterViewData[0].columns?.forEach(column => {
          //   if (column.Applied_Filters_On_Column) {
          //     thisColumnFilters[column.field] = column.Applied_Filters_On_Column;
          //   }
          // })
          if(filterViewData[0].appliedFilters){
            obj=filterViewData[0].appliedFilters;
          }
          if(filterViewData[0].columnFilters){
            thisColumnFilters=filterViewData[0].columnFilters;
          }
          // if(location.state.module == "Deals"){
            props.setActiveFilterAndGetActiveFilters(
              thisColumnFilters,
              "SET",
              obj
            );
            // }
          
          let viewData = [...allViews]
          if (viewData.filter((one) => one.ViewName == filterViewData[0].ViewName).length == 0) {
            viewData.push(filterViewData[0]);
            setAllViews(viewData);
            props.getViewColumns(filterViewData[0].columns);
            setKey("tabId" + viewId);
            let obj = { ...filterViewData[0] };
            obj.IsOpen = true;
            updateViewSimple(obj);
          }
        } else {

          if (viewId == "nycDeal" && location.state.module == "Deals") {
            props.setCheckType({ type: "NYC_Deals" });
            props.setActiveFilterAndGetActiveFilters(null, "SET", null);
            setKey("tabId" + viewId);
            return
          } else if (viewId == "nonNycDeal" && location.state.module == "Deals") {
            props.setCheckType({ type: "NON_NYC_Deals" });
            props.setActiveFilterAndGetActiveFilters(null, "SET", null);
            setKey("tabId" + viewId);
            return
          } else {
            if(location.state.module == "Deals"){
            props.setCheckType({ type: "" });
            }
            let obj = [];
            let thisColumnFilters = {};
            // filterViewData[0].columns?.forEach(column => {
            //   if (column.Applied_Filters_On_Column) {
            //     thisColumnFilters[column.field] = column.Applied_Filters_On_Column;
            //   }
            // })
            if (props.columnsEdit) {
              props.getViewColumns(props.headerData);
            } else {
              props.getViewColumns(filterViewData[0].columns);
            }
            if(filterViewData[0].appliedFilters){
              obj=filterViewData[0].appliedFilters;
            }
            if(filterViewData[0].columnFilters){
              thisColumnFilters=filterViewData[0].columnFilters;
            }
            // if(location.state.module == "Deals"){
            props.setActiveFilterAndGetActiveFilters(
              thisColumnFilters,
              "SET",
              obj
            );
            // }
            // props.setActiveFilterAndGetActiveFilters(thisColumnFilters, "SET", obj);
          }
          // props.setPreAppliedFiltersForView(obj);
        }
      })
        .catch((err) => {
          setLoader(false)
          console.log(err)
        });
      setLoader(false)
    }

  }
  

  const getCloseEvent = (e) => {
    let flag = false;
    if (e?.target?.id == "closeView") {
      flag = true;
    }
    if (e?.target?.parentNode && e?.target?.parentNode.id == "closeView") {
      flag = true;
    }
    return flag;
  };

  const getDrodownArrowEvent = (e) => {
    let flag = false;
    if (e) {
      if (e.target.id == "DropdownArrow") {
        flag = true;
      }
      if (e.target && e.target.parentNode && e.target.parentNode.id) {
        let bropdownClikc = "";
        bropdownClikc = e.target.parentNode.id.split("_");
        bropdownClikc = bropdownClikc[1];
        if (bropdownClikc == "DropdownArrow") {
          flag = true;
        }
      }
    }
    return flag;
  };

  useEffect(e => {
    localStorage.setItem("ActiveTab", key);
  }, [key])

  const handleTabSelection = async (newkey, e) => { 
     
    if (newkey !== "addview" && !getCloseEvent(e)) {
      let bropdownClikc = "";
      if (e?.target && e?.target.parentNode && e?.target.parentNode.id) {
        bropdownClikc = e.target.parentNode.id.split("_");
        bropdownClikc = bropdownClikc[1];
      }
      if (!getDrodownArrowEvent(e)) {
        let modifyKey = newkey.replace("tabId", "");
        if (e?.currentTarget?.innerText?.includes("All Deals") || newkey.includes("alldeal")) {
          props.getViewColumns("All");
          if(location.state.module == "Deals"){
          props.setCheckType({ type: "All_Deals" });
          props.setActiveFilterAndGetActiveFilters({}, "SET", []);
          // props.setActiveFiltersOnColumnLevel({});
          }
          setKey("tabId" + modifyKey);
        } else {
          // if(location.state.module == "Deals"){
          props.setActiveFilterAndGetActiveFilters({}, "SET", []);
          // }
          setKey("tabId" + modifyKey);
          await openView(modifyKey, false);
        }
      }
    }
  };

  const togglePopOver = () => {
    setShowPopOver(!showPopOver);
  };

  const toggleDeleteViewModal = () => {
    setDeleteWarn(!deleteWarn);
  };

  const togglesaveViewModal = () => {
    setsaveViewModal(!saveViewModal);
  };

  const onEditView = (tab) => {
    setsaveViewModal(!saveViewModal);
    settabrename(tab.ViewName);
    setSavingId(tab._id);
    // setKey(tab._id)
    // setNameEdited(true);
    setViewEditFlag(true);
    setPublicCheck(tab.IsPublic);
  };

  const updateViewSimple = (obj) => {
    setLoader(true);
    axios
      .post(
        Config.backend_url+"updateView",
        obj
      )
      .then(async (res) => {
        setLoader(false);
        if(res.resobj==="Unsuccessfull"){
        return loadTabData(false);
        }
      })
      .then((result) => {
        // setPublicCheck(false);
        // settabrename("")
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });
  };

  const updateView = (obj) => {
    // const backendUrl = "https://crm.srsolardesign.com:3006/api/v1/";
    setLoader(true);
    // axios.post(backendUrl+"updateview", obj)
    axios
      .post(
        Config.backend_url+"updateView",
        obj
      )
      .then(async (res) => {
        // if(res.resobj==="Unsuccessfull"){
        setLoader(false);
        // setKey("tabId" + obj._id);
        return loadTabData(false);
      })
      .then((result) => {
        setLoader(false);
        setPublicCheck(false);
        settabrename("");
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });
  };

  const saveclientview = async () => {
    let updatedHeaderWithFilters = props.headerData;
    if (editFlag) {
      let obj = [...data];
      let filterObj = obj.filter((one) => one._id === savingId)[0];
      filterObj.ViewName = tabrename;
      filterObj.IsPublic = publicCheck;
      axios
        .post(
          Config.backend_url+"updateView",
          filterObj
        )
        .then(async (res) => {
          // if(res.resobj==="Unsuccessfull"){

          const allView = [...allViews, filterObj];
          allView.forEach((one, index, self) => {
            if (one._id == filterObj._id) {
              self[index] = filterObj;
            }
          });
          setAllViews(allView);
          setKey("tabId" + filterObj._id);
          if (allView.length == 1) {
            setKey("tabId" + 'alldeal');
            return await loadTabData(true, undefined);
          } else {
            setKey("tabId" + filterObj._id);
            return await loadTabData(false, filterObj);
          }
        })
        .then((result) => {
          setPublicCheck(false);
          settabrename("");
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      let user = JSON.parse(localStorage.getItem("userinfo"));
      let obj = {
        IsPublic: publicCheck,
        module: location.state.module,
        UserId: localStorage.getItem("UserId"),
        ViewName: tabrename,
        IsCreatedByMe: true,
        IsOpen: true,
        columns: updatedHeaderWithFilters,
        appliedFilters:props.appliedFiltersForView,
        columnFilters:props.activeFiltersOnColumnLevel,
        UserId: user.user_id,
      };
      // axios.post(backendUrl+"createview", obj).then(async (res) => {
      await axios
        .post(
          Config.backend_url+"createView",
          obj
        )
        .then(async (re) => {
          //    props.setappliedFiltersForView([]);
          await axios
            .get(
              Config.backend_url+"testGetViewById?VID=" +
              re.data.data
            )
            .then((res) => {
              if (res.data.data) {
                const views = [...allViews];
                views.push(res.data.data);
                setAllViews(views);
                setKey("tabId" + re.data.data);
              } else {
                alert(res.data.msg);
              }
            });
            await loadTabData(false);
        })
        .then((result) => {
          setPublicCheck(false);
          settabrename("");

        })
        .catch(function (error) {
          console.log("error");
        });
    }
  };

  const saveView = () => {
    if (tabrename == "") {
      alert("View name can not be empty!");
      return;
    }
    saveclientview();
    setsaveViewModal(!saveViewModal);
    setViewEditFlag(false);
  };

  const handleDeleteView = (viewId) => {
    setDeleteViewId(viewId);
    toggleDeleteViewModal();
  };

  const handleDeleteViewTab = (viewId) => {

    fetch(
      `${Config.backend_url}deleteView?ROWID=${viewId}`,
      {
        method: "DELETE",
      }
    )
      .then(async (result) => {

        let viewData = [...allViews];
        viewData = viewData.filter((one) => one._id !== viewId);
        setDeleteViewId("");
        toggleDeleteViewModal();
        let viewNumber = viewData.length - 1;
        setKey("tabId" + viewData[viewNumber]._id);
        setAllViews(viewData);
        if (viewData.length == 1) {
          return loadTabData(true);
        } else {
          return loadTabData(false);
        }
      })
      .then((result) => {

      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteViewWarning = () => {
    return (
      <Modal
        className="modalwidth-400"
        isOpen={deleteWarn}
        backdrop={"static"}
        toggle={toggleDeleteViewModal}
      >
        <div className="modal-header-class">Delete View</div>
        <ModalBody>
          <FormGroup className="marb-16 padtop-15">
            <Row>
              <Col sm={12}>Are you sure you want to delete this view?</Col>
            </Row>
          </FormGroup>
          <FormGroup className="marb-16 padtop-15">
            <Row>
              <Col sm={1}></Col>
              <Col sm={4}>
                <Button
                  className="continue-button"
                  onClick={toggleDeleteViewModal}
                >
                  Cancel
                </Button>
              </Col>
              <Col sm={4}>
                <Button
                  className="continue-button"
                  onClick={() => handleDeleteViewTab(deleteViewId)}
                >
                  Delete
                </Button>
              </Col>
              <Col sm={3}></Col>
            </Row>
          </FormGroup>
        </ModalBody>
      </Modal>
    );
  };


  function getAntDropdown(tab) {
    if (tab?._id == "alldeal") {
      let items = [{
        label: <a className="cursor-point"
          onClick={(e) => {
            e.stopPropagation();
            setViewDropDown(!viewDropDown);
            togglesaveViewModal(tab?._id, tab?.ViewName)
          }
          }>Create New View</a>,
        key: "0",
      },]
      return (
        <AntDropDown
          // open={viewDropDown}
          // on
          className={key == "tabId" + tab?._id && tab?.id !== "ALLVIEWS"
            ? "pinned-drop-icon"
            : "display-none"}
          menu={{
            items,
          }}
          arrow={{
            pointAtCenter: true,
          }}
          trigger={["hover"]}
        >
          <FontAwesomeIcon
            onClick={(e) => {
              setTab(tab)
              setViewDropDown(!viewDropDown);
              e.preventDefault();
              e.stopPropagation();
            }}
            className="Action-Btn-Ant-Dropdown"
            icon={faAngleDown}
          />

        </AntDropDown>
      )
    } else {
      {
        let items = [{
          label: <a className="cursor-point"
            onClick={async (e) => {
              e.stopPropagation();
              await togglesaveViewModal(tab?._id, tab?.ViewName)
              setViewDropDown(!viewDropDown);
            }
            }>Create New View</a>,
          key: "0",
        },
        {
          label: tab?._id !== "alldeal" ? <a className="cursor-point"
            onClick={async (e) => {
               
              e.stopPropagation();
              await handleUpdateView(tab);
              setViewDropDown(!viewDropDown);
            }}>Update View </a> :
            <div style={{ display: "none" }} onClick={(e) => {
              e.stopPropagation()
            }}></div>,
          key: "1",
        }]
        return (
          <AntDropDown
            // open={viewDropDown}
            // onOpenChange={e=>{
            //   setViewDropDown(e);
            // }}
            className={key == "tabId" + tab?._id && tab?.id !== "ALLVIEWS"
              ? "pinned-drop-icon"
              : "display-none"}
            menu={{
              items,
            }}
            arrow={{
              pointAtCenter: true,
            }}
            trigger={["hover"]}
          >
            <FontAwesomeIcon
              onClick={(e) => {
                setViewDropDown(!viewDropDown);
                setTab(tab)
                e.preventDefault();
                e.stopPropagation();
              }}
              className="Action-Btn-Ant-Dropdown"
              icon={faAngleDown}
            />

          </AntDropDown>
        )
      }
    }
  }

  return (
    <div style={{ borderBottom: "1px solid #dee2e6" }} className="tabs-wrapper">
      {deleteViewWarning()}
      {loader ? <Loader /> : <></>}
      <Tabs
        activeKey={key}
        onSelect={(key, e) => {
          handleTabSelection(key, e);
        }}
        // defaultActiveKey="alldeals1"
        id="controlled-tab-example2"
        className="text-colr alldealtabs"
      >
        <Tab eventKey="blank" title="      " disabled></Tab>
        {allViews
          ? allViews.map((tab, i) => {
            return (
              <Tab
                className="position-relative view_Tabs"
                eventKey={"tabId" + tab._id}
                title={
                  <>

                    {allViews.length > 1 || tab._id !== "alldeal" ? (
                      <span className="close-tab-icon">
                        <FontAwesomeIcon
                          id="closeView"
                          onClick={() => handleCloseView(tab._id, tab)}
                          icon={faTimesCircle}
                        />
                      </span>
                    ) : (
                      <></>
                    )}
                    <div className="pos-relative unpin-tab pl-2 d-flex justify-content-center align-items-center">
                      <span
                        id={"tooltip-tab" + tab._id}
                        className="text-truncate"
                        title={tab.ViewName}
                      >
                        {tab.ViewName}
                      </span>


                      {getAntDropdown(tab)}

                    </div>
                  </>
                }
              >

                {/* <UncontrolledPopover
                    ref={btnRef}
                    
                    className="doropdown-menu-view"
                    // trigger="hover"
                    //isOpen={isPopoverOpen}
                    hidden={!isPopoverOpen}
                    placementPrefix=""
                    trigger="click"
                    placement="bottom"
                    target={"tabId" + tab._id + "_DropdownArrow"}
                    // toggle={() =>{ 
                    //      setIsPopoverOpen(!isPopoverOpen)}}
                  >
                    <PopoverBody>
                      <ul
                        // onClick={() => setDealsModalOpen(!dealsModalOpen)}
                        className="asbes-list "
                        type="none"
                      >
                        <li
                          className="cursor-point"
                          onClick={() =>
                            togglesaveViewModal(tab._id, tab.ViewName)
                          }
                        >
                          Save As View
                        </li>
                        {tab._id !== "alldeal" ? (
                          <li
                            className="cursor-point"
                            onClick={() => {
                              handleUpdateView(tab);
                            }}
                          >
                            Save View
                          </li>
                        ) : (
                          <></>
                        )}
                      </ul>
                    </PopoverBody>
                  </UncontrolledPopover> */}
              </Tab>
            );
          })
          : ""}

        <Tab
          style={{ backgroundColor: "white !important" }}
          eventKey="addview"
          title={
            <div id="clientAddView">
              <AntdPopover
                placement="bottom"
                onOpenChange={togglePopOver}
                arrow={{
                  pointAtCenter: true,
                }}
                open={showPopOver}
                trigger={"click"}
                content={
                  <>
                    <Collapse defaultActiveKey={['1', '2', '3']}>
                      <Panel header="Favourites" key="1">
                        {favViews ? (
                          favViews.map((favtab, i) => {
                            return (
                              <li>
                                <span className="pad-right10 fav-star-icon">
                                  <FontAwesomeIcon
                                    onClick={() => {
                                      let obj = { ...favtab };
                                      obj.IsFavourite = false;
                                      updateView(obj);
                                    }}
                                    icon={faStar}
                                  />
                                </span>
                                <span
                                  onClick={() => {
                                    openView(favtab._id, true);
                                    setShowPopOver(false);
                                  }}
                                  className="fav-tab"
                                >
                                  {favtab.ViewName}
                                </span>
                              </li>
                            );
                          })
                        ) : (
                          <></>
                        )}
                      </Panel>
                      <Panel header="Created By Me" key="2">
                        <ul
                          className="addView-list"
                          style={{ listStyle: "none" }}
                        >
                          {createByMeViews ? (
                            createByMeViews.map((tab, i) => {

                              return (
                                <li className="favtablist">
                                  <span className="pad-right10 unfav-star-icon  star-display">
                                    {tab.IsFavourite ? (
                                      // <img
                                      //   className="star-display2"
                                      //   src={upstar}
                                      // />
                                      <FontAwesomeIcon
                                        icon={faStar}
                                        className="fav-icon"
                                      />
                                    ) : (
                                      <FontAwesomeIcon
                                        icon={faStar}
                                        onClick={() => {
                                          let obj = { ...tab };
                                          obj.IsFavourite = true;
                                          updateView(obj);
                                        }}
                                      />
                                    )}
                                  </span>
                                  <span
                                    className="view-name"
                                    title={tab.ViewName}
                                    onClick={() => {
                                      // if(location.state.module == "Deals"){
                                      props.setActiveFilterAndGetActiveFilters({}, "SET", null);
                                      // }
                                      openView(tab._id, true);
                                      setShowPopOver(false);
                                      // addNewView(tab.name, tab.id, tab.column, false)
                                      // setActionCloseTabFlag(false);
                                    }}
                                  >
                                    {tab.ViewName}
                                  </span>
                                  <span className="unfav-star-icon pl-3  star-display1">
                                    {
                                      <>
                                        {" "}
                                        <FontAwesomeIcon
                                          icon={faEdit}
                                          //onClick={() => handleDeleteView(tab._id)}
                                          onClick={() => {
                                            setShowPopOver(!showPopOver)
                                            onEditView(tab)
                                          }}
                                        />
                                        &nbsp;&nbsp;
                                        <FontAwesomeIcon
                                          icon={faTrash}
                                          onClick={() =>
                                            handleDeleteView(tab._id)
                                          }
                                        // onClick={() =>
                                        //     onEditView(tab.id, tab.name)
                                        // }
                                        />
                                      </>
                                    }
                                  </span>
                                </li>
                              );
                            })
                          ) : (
                            <></>
                          )}
                        </ul>
                      </Panel>
                      <Panel header="Public Views" key="3">
                        <ul
                          className="addView-list"
                          style={{ listStyle: "none" }}
                        >
                          {publicViews.map((tab, i) => {
                            return (
                              <li className="favtablist">
                                <span className="pad-right10 unfav-star-icon  star-display">
                                  {tab._id !== "alldeal" ? (
                                    <>
                                      {" "}
                                      {tab.IsFavourite ? (
                                        // <img
                                        //   className="star-display2"
                                        //   src={upstar}
                                        // />
                                        <FontAwesomeIcon
                                          icon={faStar}
                                          className="fav-icon"
                                        />
                                      ) : (
                                        <FontAwesomeIcon
                                          icon={faStar}
                                          onClick={() => {
                                            let obj = { ...tab };
                                            obj.IsFavourite = true;
                                            updateView(obj);
                                          }}
                                        />
                                      )}
                                    </>
                                  )
                                    : tab._id !== "nycDeal" ? (
                                      <>
                                        {tab.IsFavourite ? (
                                          <img
                                            className="star-display2"
                                            src={upstar}
                                          />
                                        ) : (
                                          <FontAwesomeIcon
                                            icon={faStar}
                                            onClick={() => {
                                              let obj = { ...tab };
                                              obj.IsFavourite = true;
                                              updateView(obj);
                                            }}
                                          />
                                        )}
                                      </>
                                    )
                                      :
                                      tab._id !== "nonNycDeal" ? (
                                        <>
                                          {" "}
                                          {tab.IsFavourite ? (
                                            <img
                                              className="star-display2"
                                              src={upstar}
                                            />
                                          ) : (
                                            <FontAwesomeIcon
                                              icon={faStar}
                                              onClick={() => {
                                                let obj = { ...tab };
                                                obj.IsFavourite = true;
                                                updateView(obj);
                                              }}
                                            />
                                          )}
                                        </>
                                      )
                                        : (
                                          <></>
                                        )}
                                </span>
                                <span
                                  className="view-name"
                                  title={tab.ViewName}
                                  onClick={() => {

                                    openView(tab._id, true);
                                    setShowPopOver(false);
                                    // addNewView(tab.name, tab.id, tab.column, false);
                                    // setActionCloseTabFlag(false);
                                  }}
                                >
                                  {tab.ViewName}
                                </span>
                                <span className="unfav-star-icon pl-3  star-display1">
                                  {
                                    <>
                                      <FontAwesomeIcon
                                        icon={faEdit}
                                        visibility={tab._id == "alldeal" || tab._id == "nycDeal" || tab._id == "nonNycDeal" || currntUserInfo.role_details.role_name != "App Administrator" || currntUserInfo.user_id != tab.UserId ? "hidden" : ""}
                                        onClick={() => {
                                            setShowPopOver(!showPopOver)
                                            onEditView(tab)
                                        }
                                        }
                                      />
                                      &nbsp;&nbsp;
                                      <FontAwesomeIcon
                                        icon={faTrash}
                                        visibility={tab._id == "alldeal" || tab._id == "nycDeal" || tab._id == "nonNycDeal" || currntUserInfo.role_details.role_name != "App Administrator" || currntUserInfo.user_id != tab.UserId ? "hidden" : ""}
                                        onClick={() => handleDeleteView(tab._id)}                                      
                                      />
                                    </>
                                  }
                                </span>
                              </li>
                            );
                          })}
                        </ul>
                      </Panel>
                    </Collapse>
                  </>
                }
              >
                <span id="PopoverLegacy" className="line-height36" style={{ textWrap: 'nowrap' }}>
                  +<span className="leftpad-6" onClick={togglePopOver}>Add View</span>
                </span>
              </AntdPopover>
            </div>
          }
        ></Tab>
      </Tabs>

      <Modal
        className="modalwidth-400"
        isOpen={saveViewModal}
        backdrop={"static"}
        toggle={togglesaveViewModal}
      >
        <div className="modal-header-class">Create New View</div>
        <ModalBody>
          <FormGroup className="marb-16 padtop-15">
            <Row>
              <Col sm={1}></Col>
              <Col sm={4}>
                <Label className="text-bold" for="l2code">
                  View Name<span className="red-text">*</span>
                </Label>
              </Col>
              <Col sm={6} className="select-padding">
                <Input
                  type="text"
                  className="dropdown-text"
                  value={tabrename}
                  onChange={(e) => settabrename(e.target.value)}
                  name="l2code"
                  id="l2code"
                />
              </Col>
              <Col sm={1}></Col>
            </Row>
          </FormGroup>
          <FormGroup className="marb-16 padtop-15">
            <Row>
              <Col sm={1}></Col>
              <Col sm={4}>
                <Label className="text-bold" for="l2code">
                  Make Public View
                </Label>
              </Col>

              <Col sm={6} className="select-padding check-custom-wrapper">
                <Input
                  type="checkbox"
                  className="mar-0"
                  style={{ paddingLeft: '0px' }}
                  checked={publicCheck}
                  value={publicCheck}
                  onClick={() => setPublicCheck(!publicCheck)}
                  name="l2code"
                  id="l2code"
                />
              </Col>
              <Col sm={1}></Col>
            </Row>
            <Row>
              <Col sm={1}></Col>
              {/* <Col sm={4}>
                  <Label className="text-bold" for="l2code">
                    Pipeline View
                 
                  </Label>
                </Col> */}

              {/* <Col sm={6} className="select-padding">
                  <Input
                    type="checkbox"
                    className="mar-0"
                    value={pipelineView}
                    onClick={() => setPipelineView(!pipelineView)}
                    name="l2code"
                    id="l2code"
                  />
                </Col> */}
              <Col sm={1}></Col>
            </Row>
          </FormGroup>
          <FormGroup className="marb-16 padtop-15">
            <Row>
              <Col sm={1}></Col>
              <Col sm={4}>
                <Button
                  className="continue-button"
                  onClick={togglesaveViewModal}
                >
                  Cancel
                </Button>
              </Col>
              <Col sm={4}>
                <Button className="continue-button" onClick={saveView}>
                  Save
                </Button>
              </Col>
              <Col sm={3}></Col>
            </Row>
          </FormGroup>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default TabView;