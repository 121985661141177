import React from 'react'
import { Button } from 'antd';

const AddInlineData = ({ onAdd, name, onSave , isAdding}) => {
  return (
    <div style={{ margin: '5px 0px' , position:"sticky", left:"0px", paddingLeft:"6px"}}>
      <Button type="primary" onClick={(e)=>onAdd(e)} style={{ marginRight: '5px' }} disabled={isAdding}>
        {name}
      </Button>
      {/* <Button type="primary" danger ghost onClick={onDelete} style={{ marginRight: '5px' }}>
        Delete
      </Button> */}
      <Button type="default" onClick={(e)=>onSave(e)} disabled={!isAdding}>
        Save
      </Button>
    </div>
  );
};

export default AddInlineData