import React, { useContext, useEffect, useState } from "react";
import "./ActivityStreamTab.css"; // Import the CSS file
import { Col, Row } from "reactstrap";
import { Timeline } from "antd";
import ActivityTimeLine from "../../../common/ActivityStream/ActivityTimeLine";
import AwsServerService from "../../../common/util/AwsServerService";
import dayjs from "dayjs";
import { AppDataRequired } from "../../../App";
import swal from 'sweetalert';
import { useHistory } from "react-router-dom";
import { convertDateFromString } from "../../../components/ProjectTasks/Util";
import AppContext from "../../../Context/AppContext";
import { refershModule } from "../../../Context/AppActions";

const ActivityStreamTab = ({pipelineId, module, refreshData}) => {
  const [activitiyData, setActivityData] = useState([]);
  const { AllUsers } = useContext(AppDataRequired);
  const AppData = useContext(AppContext);
  const { state, dispatch } = AppData || {};
  const history = useHistory();
  const [refresh,setRefresh]=useState(false);
  
  useEffect(
    (e) => {
      if (pipelineId) {
        AwsServerService.getAllActivityByModule(pipelineId, module)
          .then((res) => {
            res = res.sort(
              (a, b) => new Date(b?.CREATEDTIME) - new Date(a.CREATEDTIME)
            );
            setActivityData(res);
          })
          .catch((err) => {
            // swal("Error!", "Something went wrong!", "error");
          });
      }
    },
    [pipelineId, refreshData, refresh]
  );
  console.log("AllUsers", AllUsers)
  useEffect(e=>{ 
      if(state?.refershModule && state.refershModule.module=='task-activity'){  
        setRefresh(prev=>!prev);
        dispatch(refershModule({module:"",data:""}))
        }  
  },[state.refershModule])

  const getMessage = (text, user, allUsers) => {
    // Convert date placeholders to formatted date strings
    let formattedText = text.replace(/(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}-\d{2}:\d{2})/g, match => convertDateFromString(match));
  
    // Replace each `zuid` in the message without quotes
    allUsers.forEach(otherUser => {
      const zuidRegex = new RegExp(`"${otherUser.zuid}"`, 'g');  // Looks for "zuid" with quotes
      formattedText = formattedText.replace(zuidRegex, `__ZUID_${otherUser.zuid}__`);
    });
  
    // Replace "USER" placeholder with a special marker for later replacement
    if (user) {
      formattedText = formattedText.replace("USER", "__USER__");
    }
  
    // Split the message into parts based on markers (`__ZUID_*__` and `__USER__`)
    const parts = formattedText.split(/(__ZUID_\d+__|__USER__)/g);
  
    return (
      <>
        {parts.map((part, index) => {
          if (part.startsWith("__ZUID_")) {
            // Extract the ZUID from the placeholder and find the corresponding user
            const zuid = part.replace("__ZUID_", "").replace("__", "");
            const foundUser = allUsers.find(u => u.zuid === zuid);
            return foundUser ? (
              <a
                key={index}
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  history.push({
                    pathname: `/setting/users/${foundUser.id}/Users`,
                    state: { tab: "Users" },
                  });
                }}
                className="user-link"
              >
                {foundUser.full_name}
              </a>
            ) : part;
          } else if (part === "__USER__" && user) {
            // Replace `USER` placeholder with a clickable user link for the main user
            return (
              <a
                key={index}
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  history.push({
                    pathname: `/setting/users/${user.user_id}/Users`,
                    state: { tab: "Users" },
                  });
                }}
                className="user-link"
              >
                {user.full_name}
              </a>
            );
          } else {
            // Render plain text for other parts
            return <span key={index}>{part}</span>;
          }
        })}
      </>
    );
  };
  
  
  
  const getUserThumbnailUrl = (zuid) => {
    const loginProfileUrl =
      "https://contacts.zoho.com/file?ID=" + zuid + "&fs=thumb";
    return loginProfileUrl;
  };
  
  const RenderChildren = (data) => {   
    data = data?.data ? data?.data : data;
    let user = AllUsers.find(d => d.zuid == data?.ActivityOwner || d.email == data?.ActivityOwner);
    return (
      data?.Message && <div className="Activity-Data-Wrapper">
        <span style={{minWidth : "3rem"}}>
          <img
            className="User-Image"
            src={getUserThumbnailUrl(user?.zuid)}
          />
        </span>
        <div>
          <p className="activity-message">
            {data?.Message ? getMessage(data?.Message, user, AllUsers) : <></>}
          </p>
          <span className="activity-date">
            {dayjs(data?.CREATEDTIME).format("MMM DD YYYY hh:mm A")}
          </span>
        </div>
      </div>
    );
};


  const items = activitiyData
  .filter(item => item?.Message) // Filter out items where Message is null or undefined
  .map((item, ind) => {
    return {
      children: <RenderChildren data={item}/>,
    };
  });

  return (
    <div className="Actvity-Items-Wrapper">
      <ActivityTimeLine items={items} />
    </div>
  );
};

export default ActivityStreamTab;