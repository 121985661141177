import React, { useState, useEffect } from "react";
import HttpService from "../../services/http.service";
// import "./ProjectTasks.css";
// import Taskcard from "../job/jobbody/tasks/Taskcard";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { faFlag as faFlagSolid, faSearch, faChevronRight, faChevronDown, faEllipsisH, faChartBar, faCaretDown, faCheck, faFilter, faTimes, faArrowDown, faEllipsisV, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import Config from '../../config.json'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import Dashboard from "../job/jobbody/dashboard/dashboardcopy";
import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";
import { subDays } from "date-fns/esm";
import { Tabs, Tab, Nav } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import _ from 'lodash';
import {
    Input,
    PopoverBody,
    UncontrolledCollapse,
    FormGroup,
    UncontrolledPopover,
    UncontrolledDropdown,
    UncontrolledTooltip,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Row, Col,
    Modal,
    ModalBody,
    ModalHeader,
} from "reactstrap";
import filter from "../../../src/assets/images/filter.png";
// import ProjectTasksTab from "./projecttaskstab";
import Tasks from "../../tabs/Task/task";
// import Vector from "../../../assets/images/vector.png";
import swal from "sweetalert";
import CustomTabs from "../../common/custom_tabs/custom_tabs";
import axios from "axios";
import TaskService from "../../common/util/TaskService";
import ModuleService from "../../common/util/ModuleService";
import UserService from "../../common/util/UserService";
import Loader from "../../common/loader";
import dayjs from "dayjs";
import { Popover as AntdPopover, Switch } from 'antd';
import Task_Filters from "../../common/task_filters/task_filter";
import AddColumn from "../../common/AddColumn/add_column";
import { faFlag, faUser } from "@fortawesome/free-regular-svg-icons";
import dateTimeObject from "../../common/util/date";
import TabView from "../../common/tabs/tabs";
import leftexpand from "../../assets/images/svg/left.svg";
import leftexpandactive from "../../assets/images/svg/leftactive.svg";
import leftexpandgray from "../../assets/images/svg/left-gray.svg";
import leftexpandactivegray from "../../assets/images/svg/leftactive-gray.svg";
import httpService from "../../services/http.service";
import ProjectService from "../../common/util/ProjectService";
import FieldService from "../../common/util/FieldService";
import StatusDropdown from "../../common/StatusDropdown/StatusDropdown";
import CustomDropdown from "../../common/custom_dropdown/CustomDropdown";
import validation from "../../common/util/validation";
import TaskModal from "../../tabs/Task/taskModal";

let dealsResult = [];

const TaskListWithDeal = (props) => {
    const location = props.location;
    const history = props.history;
    const params = useParams();
    useEffect(() => {
        if (props.getParams) {
            props.getParams(params);
        }
    }, [location])
    const [dummyDealObj, setDummyDealObj] = useState({ id: "Miscellaneous", Account_Name: { name: props.data.Account_Name, id: props.data.id }, Deal_Name: "Miscellaneous" });
    const [allProjects, setAllProjects] = useState([]);
    const [Deals, setDeals] = useState([]);
    const [DealsdropdownOpen, setDealsdropdownOpen] = useState(false);
    const [dueDateDropDown, setDueDateDropdown] = useState(false);
    const [assineeDropdownOpen, setAssineeDropdownOpen] = useState(false);
    const [supervisorDropdownOpen, setSupervisorDropdownOpen] = useState(false);
    const [priorityDropdownOpen, setPriorityDropdownOpen] = useState(false);
    const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);

    const [openTaskModal, setOpenTaskModel] = useState(false);
    const [taskAction, setTaskAction] = useState("Create");
    const [AllClients, setAllClients] = useState("");
    const [DropDownClient, setDropDownClient] = useState([]);
    const [searchedClient, setSearchedClient] = useState("");
    const [ClientSearchResult, setClientSearchResult] = useState([]);
    const [DropdownStatus, setDropdownStatus] = useState([]);

    const [DropDownDeals, setDropDownDeals] = useState([]);
    const [searchedDeal, setSearchedDeal] = useState("");
    const [searchedPriority, setSearchPriority] = useState("");
    const [DealSearchResult, setDealSearchResult] = useState([]);

    const [taskFilterOpen, setTaskFilterOpen] = useState(false);

    const [assignedMeVisible, setAssignedMeVisible] = useState(false);
    const [firstActive, setFirstActive] = useState(false);
    const [dependencyList, setDependencyList] = useState([]);



    const [startDate, setStartDate] = useState(subDays(new Date(), 7));
    const [endDate, setEndDate] = useState(new Date());

    const [CollapseDeal, setCollapseDeal] = useState([]);
    const [allJobs, setAllJobs] = useState([]);

    const [allTimeLogs, setAllTimeLogs] = useState([]);

    const [loader, setLoader] = useState(false);
    const [taskLayoutDetail, setTaskLayoutDetail] = useState({});

    const [groupExpandFlag, setGroupExpandFlag] = useState([]);
    const [filters, setFilters] = useState([]);
    const [clearFilter, setClearFilter] = useState(false)

    const [taskSearchSetting, setTaskSearchSetting] = useState({ TaskName: true, Description: false, Custom_Fields: false });
    const [dealGroupBy, setDealGroupBy] = useState([])
    const [groupBy, setGroupBy] = useState({ Task: "Pipeline", Deal: 'Deal Name' });
    const [cacheGroupBy, setCacheGroupBy] = useState({ Task: "Pipeline", Deal: 'Deal Name' });
    const [taskFilterSetting, setTaskFilterSetting] = useState({ TaskLocation: false, SubtaskParntNames: false, ExpandDeals: true, ExpandGroups: true, ExpandTasks: false, ViewSeparateTask: false })
    const [taskLocationShow, setTaskLocationShow] = useState(false)
    const [showParentNames, setShowParentNames] = useState(false);

    const [tasklist, setTaskList] = useState([]);
    // useEffect(()=>{
    //     TaskService.GetAllProjects()
    //     .then((result)=>{
    //        if(result.data.projects){
    //         setAllProjects(result.data.projects);
    //        }else{
    //         setAllProjects([]);
    //        }
    //     })
    //     .catch((err)=>{
    //       console.log(err);
    //     })
    //   },[])
    

    const [viewData, setViewData] = useState([]);
    const priorityValues = [
        { name: "None", color: "lightgray" },
        { name: "High", color: "#e50000" },
        { name: "Medium", color: "#f7c700" },
        { name: "Low", color: "blue" },
    ];

    const [taskLayout, setTaskLayout] = useState({
        status_details: [
            // {
            //     is_start: true,
            //     color: "#f56b62",
            //     name: "Pending",
            //     id: "1716273000002029049",
            //     is_default: false,
            //     type: "open",
            // },
            {
                is_start: false,
                color: "#a9b2c0",
                name: "Not Required",
                id: "1716273000002029043",
                is_default: false,
                type: "closed",
            },
            {
                is_start: false,
                color: "#a7bcee",
                name: "Not Started",
                id: "1716273000002029045",
                is_default: false,
                type: "open",
            },
            {
                is_start: false,
                color: "#fbc11e",
                name: "To Do",
                id: "1716273000002029041",
                is_default: false,
                type: "open",
            },
            {
                is_start: false,
                color: "#08aeea",
                name: "In Progress",
                id: "1716273000000031001",
                is_default: true,
                type: "open",
            },
            {
                is_start: false,
                color: "#98ca3c",
                name: "Completed",
                id: "1716273000002029047",
                is_default: false,
                type: "closed",
            }
           
        ]
    })



    const [addTaskColumns, setAddTaskColumns] = useState([
        { name: 'TIME TRACKING', isChecked: false, isVisible: true, isSearch: true, isDragOver: false, id: "TIME_TRACKING", field: "TIME_TRACKING", type: "text" },
        { name: 'SUPERVISER', isChecked: false, isVisible: true, isSearch: true, isDragOver: false, id: "SUPERVISER", field: "SUPERVISER", type: "text" },
        { name: 'ASSIGNEE', isChecked: false, isVisible: true, isSearch: true, isDragOver: false, id: "ASSIGNEE", field: "ASSIGNEE", type: "text" },
        { name: 'DUE DATE', isChecked: false, isVisible: true, isSearch: true, isDragOver: false, id: "DUE_DATE", field: "DUE_DATE", type: "text" },
        { name: 'PRIORITY', isChecked: false, isVisible: true, isSearch: true, isDragOver: false, id: "PRIORITY", field: "PRIORITY", type: "text" },
        { name: 'TIME ESTIMATE', isChecked: false, isVisible: true, isSearch: true, isDragOver: false, id: "TIME_ESTIMATE", field: "TIME_ESTIMATE", type: "text" },
        { name: 'PROGRESS', isChecked: false, isVisible: true, isSearch: true, isDragOver: false, id: "PROGRESS", field: "PROGRESS", type: "text" },
        { name: 'STATUS', isChecked: false, isVisible: true, isSearch: true, isDragOver: false, id: "STATUS", field: "STATUS", type: "text" },
        { name: 'PIPELINE', isChecked: false, isVisible: true, isSearch: true, isDragOver: false, id: "PIPELINE", field: "PIPELINE", type: "text" },
        { name: 'CATEGORY', isChecked: false, isVisible: true, isSearch: true, isDragOver: false, id: "CATEGORY", field: "CATEGORY", type: "text" },
        { name: 'MILESTONE', isChecked: false, isVisible: true, isSearch: true, isDragOver: false, id: "MILESTONE", field: "MILESTONE", type: "text" },

    ])



    const [filterItems, setFilterItems] = useState(
        [{ name: 'Status', type: 'string' },
        { name: 'Task Name', type: 'string' },
        { name: 'Pipeline', type: 'string' },
        { name: 'Category', type: 'string' },
        { name: "Due Date", type: 'date' }, { name: "Priority", 'type': 'string' }, { name: "Milestone", 'type': 'string' },
        { name: "Assignee", type: 'string' }, { name: "Created By", type: 'string' }, { name: 'Start Date', type: 'date' }])

    const [filterItemsDeal, setFilterItemsDeal] = useState(
        [{ name: 'Deal Name', type: 'string' },
        { name: 'Client', type: 'string' },
        // { name: 'Project Address', type: 'string' },
        { name: 'Status', type: 'string' },
        { name: 'Borough', type: 'string' },
        // { name: "Bin Number", type: 'string' },
        // { name: "Lot Number", type: 'string' },
        // { name: "Block Number", type: 'string' },
        { name: "Closing Date", 'type': 'date' },
        { name: "Created By", type: 'string' }])
    const pvSystemTableHeaders = [
        { value: "Interconnection_Number", label: "Interconnection Number" },
        { value: "Invertor_Model_Number", label: "Invertor Model Number" },
        { value: "Invertor_Type", label: "Invertor Type" },
        { value: "Number_Of_Modules", label: "Number Of Modules" },
        { value: "PV_Module_Model", label: "PV Module Model" },
        { value: "System_Number", label: "System Number" },
        { value: "System_Size_KW1", label: "System Size KW" }
    ]
    const wwwViolationHeaders = [
        { value: "Civil_Penalty_Payment_Required", label: "Civil Penalty Payment Required" },
        { value: "Invoice_Number", label: "Invoice Number" },
        { value: "ISN_Number", label: "ISN Number" },
        { value: "L2_Code", label: "L2 Code" },
        { value: "Paid_Under_Job_Number", label: "Paid Under Job Number" },
        { value: "Violation_Number", label: "Violation Number" },
        { value: "Violation_Type", label: "Violation Type" }
    ]

    useEffect(() => {
        const items = [...filterItemsDeal];
        pvSystemTableHeaders.forEach((one) => {
            items.push({ name: one.label, type: 'string', api_name: one.value })
        })
        wwwViolationHeaders.forEach((one) => {
            items.push({ name: one.label, type: 'string', api_name: one.value })
        })
        setFilterItemsDeal(items);
        getTaskDependencyData();
    }, [])


    const getTaskDependencyData = () => {
        ModuleService.GetModuleListData("Task_Dependency")
            .then((result) => {
                setDependencyList(result)
            })
            .catch((err) => {
                console.log(err);
            })
    }






    const [columnsEdit, setColumnsEdit] = useState(false);
    const [dealStatus, setDealStatus] = useState([]);

    const [selectedTable, setSelectedTable] = useState("tasks");
    const [filterState, setFilterState] = useState(true);
    const changeFilterState = () => setFilterState(!filterState);
    useEffect(() => {
        getTaskList();
        getJobsByProjectId('');
        getAllTimeLogs();
        TaskService.GetTaskLayout("1716273000002205035")
            .then((result) => {
                setTaskLayoutDetail(result);
                let status = [...result.status_details]
                status.forEach((one) => {
                    one.isCheck = true;
                })
                status.unshift({ name: 'All', id: 'all', isCheck: true })
                setDropdownStatus(status)
            })
            .catch((err) => {
                console.log(err);
            })
    }, [])



    const status = ["On Hold", 'Proposal', 'Cancelled', 'Completed', 'In Progress']


    const getAllTimeLogs=()=>{
        let userInfo=JSON.parse(localStorage.getItem('userinfo'));
        let startDate = new Date();
        startDate.setDate(1);
        startDate = dayjs(startDate).format('YYYY-MM-DD')
        let endDate = new Date();
        endDate.setDate(31);
        endDate = dayjs(endDate).format('YYYY-MM-DD')
        TaskService.GetAllTimeTrack(userInfo?.email_id,startDate,endDate,'all')
        .then((result)=>{
            setAllTimeLogs(result);
        })
        .catch((err)=>{
            console.log(err);
        })
    }

    useEffect(() => {
        TaskService.GetProjectStatus()
            .then((result) => {
                let arr = []
                result.forEach((one) => {
                    if (arr.length == 0) {
                        arr.push(one)
                    } else {
                        if (arr.filter(obj => obj.status_name == one.status_name).length == 0) {
                            arr.push(one)
                        }
                    }
                })
                arr = arr.filter(one => status.includes(one.status_name))
                setDealStatus(arr);
            })
            .catch((err) => {
                console.log(err);
            })

    }, [])



    useEffect(() => {
        if ((allJobs.length > 0)) {
            getAllDeals();
            getAllUsers();
            getAllTasks();
            getAllClients();
            getFieldHeaders();
            setDummyDealObj({ id: "Miscellaneous", Account_Name: { name: props.data.Account_Name, id: props.data.id }, Deal_Name: "Miscellaneous", LastProjectID: props.data.Project_ID, Project_ID: props.data.Project_ID });
        }
    }, [allJobs, props.data.id,allTimeLogs]);

    const [allPipelines, setAllPipline] = useState([]);
    const [allCategory, setAllCategory] = useState([])

    const getCategoryNames = () => {
        let all = [...allCategory];
        let returnArr = [];
        all.forEach((one) => {
            returnArr.push({ name: one.CategoryName, id: one.CategoryName });
        })
        return returnArr;
    }


    useEffect(() => {
        getAllPipelines();
        getAllCategorys();
    }, [])
    const subformApiName = ["Deal_Name", 'PV_Interconnection'];
    const moduleApiNameForSubform = ['WWP_Violations', 'Previous_Solar_Info'];
    const firstArr = ["Deal_Name", "Closing_Date", "Account_Name", "Project_Address", "Project_Status", "House_Number", "City",
        "Street_Name", "State", "Zip_Code", "Latitude", "Longitude", "Street_Name", " Is_NYC_Project", "Borough", "Block_Number",
        "Lot_Number", "Bin_Number", "Community_Board_Number", "Occupancy_Classification_of_Building", "Construction_Classification_Code", "Multiple_Dwelling_Classification",
        "Number_of_Dwelling_Units", "Building_Height", "Number_of_Stories", "Commercial_Overlay", 
        "Zoning_Map_Number", "Zoning_District", "Tidal_Wetlands",
        "Fresh_Water_Wetland", "Flood_Hazard", "Coastal_Erosion_Hazard", "Special_Fire_District",
        "Landmark_Area", "Environmental_Restriction", "Previous_Solar_Application"
    ]
    const [dealHeaders, setDealHeaders] = useState([])
    const getFieldHeaders = () => {
        // if(headerData.length===0){
        setLoader(true)
        FieldService.GetFields('all', 'Deals')
            .then(async (result) => {
                let arr = [];
                // result = result.filter((one) => one.api_name == 'PV_Interconnection')
                firstArr.forEach(one => {
                    let fieldObj = result.filter(object => object.api_name == one);
                    if (fieldObj.length > 0) {
                        fieldObj = fieldObj[0];
                        fieldObj.field_label = fieldObj.field_label.replace('0', '');
                        let obj = {}
                        obj.field = fieldObj.api_name
                        obj.id = fieldObj.id
                        obj.isChecked = false
                        obj.isDragOver = false
                        obj.isSearch = true
                        obj.name = fieldObj.field_label
                        obj.type = fieldObj.data_type
                        arr.push(obj)
                    }

                })
                result.forEach(one => {
                    if (!firstArr.includes(one.api_name)) {
                        let obj = {}
                        obj.field = one.api_name
                        obj.id = one.id
                        obj.isChecked = false
                        obj.isDragOver = false
                        obj.isSearch = true
                        obj.name = one.field_label.replace('0', '')
                        obj.type = one.data_type == 'formula' ? 'number' : one.data_type;
                        arr.push(obj)
                    }
                })
                const notIncludesFileds = ['latitude', 'longitude']
                arr = arr.filter(one => !notIncludesFileds.includes(one.name))
                moduleApiNameForSubform.forEach((one) => {
                    let obj = {}
                    obj.field = one
                    obj.id = one
                    obj.isChecked = false
                    obj.isDragOver = false
                    obj.isSearch = true
                    obj.name = one.replaceAll('_', ' ')
                    obj.type = 'text';
                    arr.push(obj)
                })
                arr = _.uniqBy(arr, 'name')
                setDealHeaders(arr);
            })
            .catch((err) => {
                console.log(err);
            })
        // }


    }

    useEffect(()=>{
        if(props.refreshEnable){
            getAllTasks()
        }
    },[props.refresEnable])

    const getAllTaskInputFields = (dealId) => {
        const backendUrl = Config.backend_url;
        return new Promise((resolve, reject) => {
            let obj = dealId ? { DealId: dealId } : {}
            // httpService.CreateUpdate('getTaskFields', obj)
            axios.post(backendUrl+'getTaskFields', obj)
                .then((result) => {
                    resolve(result.data.Data)
                })
                .catch((err) => {
                    reject(err)
                })
        })

    }

    // useEffect(() => {
    //     if (dealHeaders.length>0) {
    //         (async () => {
    //             const header = [...dealHeaders];
    //             const data = [...dealHeaders];
    //             try {
    //                 setLoader(true);
    //                 let custom_fields = await getAllTaskInputFields();
    //                 for (let deal = 0; deal < data.length; deal++) {
    //                     if (data[deal].Project_ID) {
    //                         let fields = custom_fields.filter((field) => (field.Assign) && (field.Assign.length > 0) && (field.Assign.filter(one => one.DealId == data[deal].Project_ID).length > 0));
    //                         fields.forEach((field) => {
    //                             data[deal][field.Name] = (field.Value ? field.Value.Value ? field.Value.Value : 'NA' : 'NA')
    //                         })

    //                     }
    //                 }
    //                 custom_fields.forEach((one) => {
    //                     let obj = {}
    //                     obj.field = one.Name
    //                     obj.id = one._id
    //                     obj.isChecked = false
    //                     obj.isDragOver = false
    //                     obj.isSearch = true
    //                     obj.name = one.Name
    //                     obj.type = one.Type
    //                     header.push(obj)
    //                 })

    //                 setLoader(false);
    //                 setDealHeaders(header);
    //             }
    //             catch (ex) {
    //                 setLoader(false);
    //                 console.log(ex);
    //             }
    //         })()
    //     }

    // }, [dealHeaders])


    useEffect(() => {
        if (dealHeaders.length > 0) {
            let arr = []
            const items = [...dealHeaders];
            items.forEach((one) => {
                if (one.type == 'text') {
                    arr.push({ name: one.name, type: 'string', api_name: one.field })
                }
                else if (one.type == 'integer') {
                    arr.push({ name: one.name, type: 'number', api_name: one.field })
                }
                else if (one.type == 'date') {
                    arr.push({ name: one.name, type: 'date', api_name: one.field })
                }
                else if (one.type == 'picklist') {
                    arr.push({ name: one.name, type: 'boolean', api_name: one.field })
                }
                else {
                    arr.push({ name: one.name, type: 'string', api_name: one.field })
                }
            })
            pvSystemTableHeaders.forEach((one) => {
                arr.push({ name: one.label, type: 'string', api_name: one.value })
            })
            wwwViolationHeaders.forEach((one) => {
                arr.push({ name: one.label, type: 'string', api_name: one.value })
            })
            setFilterItemsDeal(arr)
        }

    }, [dealHeaders])

    const getAllCategorys = () => {
        setLoader(true)
        const backendUrl = Config.backend_url;
        axios.get(backendUrl+'getAllCategories')
            .then((result) => {
                setLoader(false)
                setAllCategory(result.data)
            })
            .catch((err) => {
                setLoader(false)
                console.log(err);
            })
    }

    const getAllPipelines = () => {
        setLoader(true)
        HttpService.getByBoj('getAllPipeline')
            .then((result) => {
                setLoader(false)
                setAllPipline(result.data)
            })
            .catch((err) => {
                setLoader(false)
                console.log(err);
            })
    }


    const getTaskList = () => {
        TaskService.GetAllProjects()
            .then(async (result) => {
                let doarr = []
                if (result.data.projects) {
                    // result.data.projects.forEach(one => {
                    //     doarr.push(TaskService.GetTaskList(one.id_string))
                    // })
                    // let tasklist = await Promise.all(doarr);
                    // tasklist = validation.RemoveNestedArray(tasklist);
                    // tasklist = tasklist.filter(tlist => tlist.name !== "General");
                    // console.log("result.data", result.data.projects);
                    setAllProjects(result.data.projects)
                    GetTaskLayout(result.data.projects[0].id_string)
                    setTaskList(tasklist);
                } else {
                    setAllProjects([]);
                    setTaskList([]);
                }

            })
            .catch((err) => {
                console.log(err);
            })


    }

    const GetTaskLayout=(projectID)=>{
        let pid="1716273000002205035"
        TaskService.GetTaskLayout(projectID)
        .then((result) => {
            setTaskLayout(result);
            setTaskLayoutDetail(result);
            let status = [...result.status_details]
            status.forEach((one) => {
                one.isCheck = true;
            })
            status.unshift({ name: 'All', id: 'all', isCheck: true })
            setDropdownStatus(status)
        })
        .catch((err) => {
            console.log(err);
        })
    }

    const getJobsByProjectId = (pid) => {
        setLoader(true)
        TaskService.GetJobsByProjectId(pid)
            .then((result) => {
                if (result) {
                    setAllJobs(result)
                } else {
                    setAllJobs([])
                }
                setLoader(false)

            })
            .catch((err) => {
                setLoader(false)
                console.log(err);
            })
    }

    const [collapseOpen, setCollapseOpen] = useState(false);

    const tabsName = [
        {
            id: "tasks",
            name: "Tasks",
        },
        {
            id: "timechart",
            name: "Time Chart",
        },
    ];

    const collapseToggle = () => {
        setCollapseOpen(!collapseOpen);
    }

    const collapseAllDeals = (dealsId) => {
        let dc = [];
        dealGroupBy.forEach((e, i) => {
            dc.push({
                ID: e,
                isOpen: false,
            });
        });
        setCollapseDeal(dc);
        setCollapseOpen(false);
        // console.log("line 73", +dc);
    }

    const unCollapseAllDeals = () => {
        let dc = [];
        dealGroupBy.forEach((e, i) => {
            dc.push({
                ID: e,
                isOpen: true,
            });
        });
        setCollapseDeal(dc);
        setCollapseOpen(false);
    }


    const getGroupByFields = (deals, groupBy) => {
        var unique = [];
        if (groupBy == "Client") {
            unique = [...new Set(deals.map((deal) => {
                if (deal.Account_Name && deal.Account_Name.name) {
                    return deal.Account_Name.name
                } else {
                    return 'None'
                }

            }))]

        }
        else if (groupBy == "Status") {
            unique = [...new Set(deals.map((deal) => {
                if (deal.Stage) {
                    return deal.Stage
                } else {
                    return 'None'
                }

            }))]

        }
        else {
            let value = groupByDealItem.filter((one) => one.name == groupBy)[0];
            if (value) {
                value = value.value;
                unique = [...new Set(deals.map((deal) => {
                    if (deal[value]) {
                        return deal[value]
                    } else {
                        return 'None'
                    }
                }))]
            }


        }
        let arr = []
        unique.forEach((one) => {
            arr.push({ DealId: one, isOpen: true })
        })
        setGroupExpandFlag(arr);
        setDealGroupBy(unique);
        DealIcons(unique);


    };


    const searchClient = (clientName) => {
        let clientdropdown = [...DropDownClient];
        clientdropdown = clientdropdown.filter((item) =>
            item.Account_Name.toLowerCase().includes(clientName.toLowerCase())
        );
        setSearchedClient(clientName);
        setClientSearchResult(clientdropdown);
    };

    const getAllClients = () => {
        ModuleService.GetModuleList('Accounts')
            .then((result) => {
                if (result) {
                    setAllClients(result.data);
                    result.data.forEach((client, i) => {
                        client.isCheck = true;
                    });
                    if (result.data.length > 0) {
                        result.data.unshift({ Account_Name: 'All', isCheck: true, id: 'allClient' })
                    }
                    setDropDownClient(result.data);
                    setClientSearchResult(result.data);
                }
            })
            .catch((err) => {
                console.log(err);
            })
    };


    const [ClientdropdownOpen, setClientdropdownOpen] = useState(false);
    const toggleClientDropDown = () => {
        setClientdropdownOpen(!ClientdropdownOpen);


    }

    const toggleClientChecks = (e) => {
        //setSearchedClient("");
        let temp = [...DropDownClient];
        let alltask = [...allTasks]

        if ("Check-allClient" == e.target.id) {
            DropDownClient.forEach((client, i) => {
                temp[i].isCheck = e.target.checked;
            });
        } else {
            DropDownClient.forEach((client, i) => {
                if ("Check-" + client.id.toString() == e.target.id) {
                    temp[i].isCheck = !temp[i].isCheck;
                }
                if (client.clientName == 'All') {
                    temp[i].isCheck = false;
                }
            });
        }

        //setDropDownClient(temp);
        setClientSearchResult(temp);
        temp = temp.filter((e) => e.isCheck == true);
        // getDashBoardData(temp, startDate, endDate);
        let dealsdrpdwn = [];
        let taskdrpdwn = [];
        temp.forEach((e1, i) => {
            let d = Deals.filter((deal) => (deal.Account_Name) && (deal.Account_Name.id == e1.id));
            if (d.length != undefined && d.length > 0) {
                d.forEach((deal, i) => {
                    deal.isCheck = true;
                    dealsdrpdwn.push(deal);
                    let t = alltask.filter((task) => task.project && task.project.id_string == deal.Project_ID);
                    if (t.length != undefined && t.length > 0) {
                        t.forEach((task, i) => {
                            task.isCheck = true;
                            taskdrpdwn.push(task);
                        });
                    }
                });
            }
        });
        dealsdrpdwn = dealsdrpdwn.sort((a, b) => new Date(b.Created_Time) - new Date(a.Created_Time))
        setDropDownDeals(dealsdrpdwn);
        setDealSearchResult(dealsdrpdwn);
        setDropDownTasks(taskdrpdwn);
        setTaskSearchResult(taskdrpdwn);
        toggleClientDropDown();
    };
    const getClientsDropdown = () => {
        let filterApply = ClientSearchResult.filter((one) => !one.isCheck).length > 0 ? true : false
        return (
            <Dropdown
                className="timechart-filter-dropdown"
                isOpen={ClientdropdownOpen}
                toggle={toggleClientDropDown}
            >
                <DropdownToggle style={filterApply ? { color: '#0069d9' } : {}} caret>Clients </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem header className="dropdown-search">
                        <FormGroup className="pos-relative">
                            <Input
                                type="text"
                                onChange={(e) => searchClient(e.target.value)}
                                value={searchedClient}
                                placeholder="Search"
                                className="mr-sm-2 pad-fix search-filter-deals"
                            />
                            <FontAwesomeIcon className="mr-sm-2 search-ico" icon={faSearch} />
                        </FormGroup>
                    </DropdownItem>
                    {ClientSearchResult.map((client, i) => (
                        <DropdownItem>
                            <input
                                id={"Check-" + client.id.toString()}
                                type="checkbox"
                                checked={client.isCheck}
                                onChange={(e) => toggleClientChecks(e)}
                            />
                            {client.Account_Name}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </Dropdown>
        );
    };


    const toggleDealsDropDown = () => {
        setDealsdropdownOpen((prevState) => !prevState);
        // setSearchedDeal("");
    }

    const toggleDueDateDropDown = () => {
        setDueDateDropdown((prevState) => !prevState);
        // setSearchedDeal("");
    }
    const togglePriorityDropdownOpen = () => {
        setPriorityDropdownOpen((prevState) => !prevState);
        // setSearchedDeal("");
    }

    const toggleStatusDropdownOpen = () => {
        setStatusDropdownOpen((prevState) => !prevState);
        // setSearchedDeal("");
    }


    const [DropDownPriority, setDropDownPriority] = useState([
        { name: 'All', _id: 'all', isCheck: true },
        { name: 'None', _id: 'none', isCheck: true },
        { name: 'High', _id: 'high', isCheck: true },
        { name: 'Medium', _id: 'medium', isCheck: true },
        { name: 'Low', _id: 'low', isCheck: true }
    ]);
    const [DropDownSearchPriorityResult, setDropDownSearchPriorityResult] = useState();


    const searchDeal = (ProjectNumber) => {
        setSearchedDeal(ProjectNumber);
        let dealdropdown = [...DropDownDeals];
        dealdropdown = dealdropdown.filter((item) =>
            item.Deal_Name.toLowerCase().includes(ProjectNumber.toLowerCase())
        );
        setDealSearchResult(dealdropdown);
    };

    const searchPriority = (string) => {
        setSearchPriority(string);
        let dealdropdown = [...DropDownPriority];
        dealdropdown = dealdropdown.filter((item) =>
            item.name.toLowerCase().includes(string.toLowerCase())
        );
        //setDealSearchResult(dealdropdown);
    };
    const toggleDealChecks = (e) => {
        //  setSearchedDeal("");
        let temp = [...DropDownDeals];

        if ("Check-allDeal" == e.target.id) {
            DropDownDeals.forEach((client, i) => {
                temp[i].isCheck = e.target.checked;
            });
        } else {
            DropDownDeals.forEach((deal, i) => {
                if ("Check-" + deal.id.toString() == e.target.id) {
                    temp[i].isCheck = !temp[i].isCheck;
                }
                if (deal.Deal_Name == 'All') {
                    temp[i].isCheck = false;
                }
            });
        }
        //setDropDownDeals(temp);
        setDealSearchResult(temp);
        temp = temp.filter((e) => e.isCheck == true);
        let taskdrpdwn = [];
        temp.forEach((deal, i) => {
            let t = allTasks.filter((task) => task.project && task.project.id_string == deal.Project_ID);
            if (t.length != undefined && t.length > 0) {
                t.forEach((task, i) => {
                    task.isCheck = true;
                    taskdrpdwn.push(task);
                });
            }
        });
        setDropDownTasks(taskdrpdwn);
        setTaskSearchResult(taskdrpdwn);
        toggleDealsDropDown();
    };
    const getDealsDropdown = () => {
        let filterApply = DealSearchResult.filter((one) => !one.isCheck && (one._id !== 'allDeal')).length > 0 ? true : false
        return (
            <Dropdown
                className="timechart-filter-dropdown"
                isOpen={DealsdropdownOpen}
                toggle={toggleDealsDropDown}
            >
                <DropdownToggle style={filterApply ? { color: '#0069d9' } : {}} caret>Deals{filterApply ? <FontAwesomeIcon color="#0069d9" icon={faCheck} /> : <></>}</DropdownToggle>
                <DropdownMenu>
                    <DropdownItem header className="dropdown-search">
                        <FormGroup className="pos-relative">
                            <Input
                                type="text"
                                onChange={(e) => searchDeal(e.target.value)}
                                value={searchedDeal}
                                placeholder="Search"
                                className="mr-sm-2 pad-fix search-filter-deals"
                            />
                            <FontAwesomeIcon className="mr-sm-2 search-ico" icon={faSearch} />
                        </FormGroup>
                    </DropdownItem>

                    {DealSearchResult.map((deal, i) => (
                        <DropdownItem>
                            <input
                                id={"Check-" + (deal.id ? deal.id.toString() : 'NA')}
                                type="checkbox"
                                checked={deal.isCheck}
                                onChange={(e) => toggleDealChecks(e)}
                            />
                            {deal.Deal_Name}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </Dropdown>
        );
    };

    const getPriorityDropdown = () => {
        let filterApply = DropDownPriority.filter((one) => !one.isCheck).length > 0 ? true : false
        return (
            <Dropdown
                className="timechart-filter-dropdown"
                isOpen={priorityDropdownOpen}
                toggle={togglePriorityDropdownOpen}
            >
                <DropdownToggle style={filterApply ? { color: '#0069d9' } : {}} caret>Priority {filterApply ? <FontAwesomeIcon color="#0069d9" icon={faCheck} /> : <></>}</DropdownToggle>
                <DropdownMenu>
                    {/* <DropdownItem header className="dropdown-search">
            <FormGroup className="pos-relative">
              <Input
                type="text"
                onChange={(e) => searchPriority(e.target.value)}
                value={searchedPriority}
                placeholder="Search"
                className="mr-sm-2 pad-fix search-filter-deals"
              />
              <FontAwesomeIcon className="mr-sm-2 search-ico" icon={faSearch} />
            </FormGroup>
          </DropdownItem> */}

                    {DropDownPriority.map((one, i) => (
                        <DropdownItem>
                            <input
                                id={"Check-" + one._id.toString()}
                                type="checkbox"
                                checked={one.isCheck}
                                onChange={(e) => toggleChecksPriority(e)}
                            />
                            {one.name}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </Dropdown>
        );
    };

    const changeDateRange = (item) => {
        let tempTask = [...DropDownTasks];
        setStartDate(item.selection.startDate);
        setEndDate(item.selection.endDate);
        setSelectionRange([item.selection]);
        filterDueDateToggle();
        setSelectedDateRange(
            GetFormattedDate(item.selection.startDate) +
            " - " +
            GetFormattedDate(item.selection.endDate)
        );
        tempTask.forEach((task, index) => {
            if (task.end_date) {
                if ((task.end_date) && (new Date(task.end_date) >= item.selection.startDate) && (new Date(task.end_date) <= item.selection.endDate)) {
                    tempTask[index].isCheck = true;
                } else {
                    tempTask[index].isCheck = false;
                }
            }

        })

        let tempDeals = [...DropDownDeals];
        tempDeals.forEach((deal, index) => {
            let filterTask = tempTask.filter(task => task.project && (deal.Project_ID == task.project.id_string) && (task.isCheck));
            if (filterTask.length > 0) {
                tempDeals[index].isCheck = true;
            } else {
                tempDeals[index].isCheck = false;
            }
        })
        setDropDownDeals(tempDeals);
        setDropDownTasks(tempTask);
    };




    const [SelectedDateRange, setSelectedDateRange] = useState(
        GetFormattedDate(startDate) + " - " + GetFormattedDate(endDate)
    );
    const [selectionRange, setSelectionRange] = useState([
        {
            startDate: startDate,
            endDate: endDate,
            key: "selection",
        },
    ]);

    function GetFormattedDate(date) {
        let dateObj = new Date(date);
        let month = dateObj.getMonth() + 1; //months from 1-12
        let day = dateObj.getDate();
        let year = dateObj.getFullYear();
        day = day < 10 ? "0" + day : day;
        month = month < 10 ? "0" + month : month;

        return month + "/" + day + "/" + year;
    }


    const getDueDateDropdown = () => {
        let filterApply = ClientSearchResult.filter((one) => !one.isCheck).length > 0 ? true : false
        return (
            <Dropdown
                className="timechart-filter-dropdown"
                isOpen={dueDateDropDown}
                toggle={toggleDueDateDropDown}
                id='dueDate-filter'
            >
                <DropdownToggle style={filterApply ? { color: '#0069d9' } : {}} caret>Due Date </DropdownToggle>
                <UncontrolledPopover
                    isOpen={dueDateDropDown}
                    toggle={toggleDueDateDropDown}
                    trigger="legacy"
                    placement="bottom"
                    target="dueDate-filter"
                >
                    <PopoverBody>
                        <DateRangePicker
                            onChange={(item) => {
                                changeDateRange(item);
                            }}
                            showSelectionPreview={true}
                            moveRangeOnFirstSelection={false}
                            months={1}
                            ranges={selectionRange}
                            direction="vertical"
                            scroll={{ enabled: true }}
                        />
                    </PopoverBody>
                </UncontrolledPopover>
            </Dropdown>

        );
    };
    const [DropDownTasks, setDropDownTasks] = useState([]);

    useEffect(() => {

        if ((viewData.length > 0) && (DropDownTasks.length > 0) && (DropDownDeals.length > 0)) {
            if (viewData == 'All') {
                if (viewData !== 'All') {
                    resetViewFilters()
                }
            } else {
                setViewFilters(viewData)
            }

        }
    }, [DropDownTasks, viewData, DropDownDeals])

    useEffect(() => {

        if ((viewData.length > 0) && (DropDownTasks.length > 0) && (DropDownDeals.length > 0)) {
            // if (viewData == 'All') {
            //     resetViewFilters()
            // } else {
            //     setViewFilters(viewData)
            // }

        }
    }, [DropDownTasks, viewData, DropDownDeals])

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggleDropDown = () => {
        setDropdownOpen((prevState) => !prevState);
        // setSearchedTask("");
    };

    const [searchedTask, setSearchedTask] = useState("");
    const [searchedAssinee, setSearchAssinee] = useState("");
    const [TaskSearchResult, setTaskSearchResult] = useState([]);
    const [assineeSearchResult, setAssineeSearchResult] = useState([]);
    const searchTask = (TaskName) => {
        setSearchedTask(TaskName);
        let taskdropdown = [...DropDownTasks];
        taskdropdown = taskdropdown.filter((item) =>
            item.TaskName.toLowerCase().includes(TaskName.toLowerCase())
        );
        setTaskSearchResult(taskdropdown);
    };

    const searchAssignee = (userstring) => {
        setSearchAssinee(userstring);
        let users = [...allUsers];
        users = users.filter((user) =>
            (user.full_name.toLowerCase().includes(userstring.toLowerCase())) || (user.full_name.toLowerCase().includes(userstring.toLowerCase()))
        );
        setAssineeSearchResult(users);
    };

    const toggleChecks = (e) => {
        //  setSearchedTask("");

        let temp = [...DropDownTasks];
        // DropDownTasks.forEach((task) => {
        //   temp.push({ ...task });
        // });
        if ("Check-allTask" == e.target.id) {
            DropDownTasks.forEach((client, i) => {
                temp[i].isCheck = e.target.checked;
            });
        } else {
            DropDownTasks.forEach((tasks, i) => {
                if ("Check-" + tasks._id.toString() == e.target.id) {
                    temp[i].isCheck = !temp[i].isCheck;
                }
                if (tasks.TaskName == 'All') {
                    temp[i].isCheck = false;
                }
            });
        }

        let tempDeals = [...DropDownDeals];
        tempDeals.forEach((deal, index) => {
            let filterTask = temp.filter(task => (deal._id == task.DealsId) && (task.isCheck));
            if (filterTask.length > 0) {
                tempDeals[index].isCheck = true;
            } else {
                tempDeals[index].isCheck = false;
            }
        })
        setDropDownDeals(tempDeals);


        setTaskSearchResult(temp);
        toggleDropDown();
    };

    const [DropDownAssinee, setDropDownAssinee] = useState([]);


    const toggleChecksPriority = (e) => {
        //  setSearchedTask("");
        let tempPriority = [...DropDownPriority];
        let tempTask = [...DropDownTasks];
        // DropDownTasks.forEach((task) => {
        //   temp.push({ ...task });
        // });
        if ("Check-all" == e.target.id) {
            DropDownPriority.forEach((client, i) => {
                tempPriority[i].isCheck = e.target.checked;
            });
        } else {
            DropDownPriority.forEach((one, i) => {
                if ("Check-" + one._id.toString() == e.target.id) {
                    tempPriority[i].isCheck = e.target.checked;
                }
                if (one.name == 'All') {
                    tempPriority[i].isCheck = false;
                }
            });
        }


        tempTask.forEach((task, index) => {
            if (task.TaskName !== 'All') {
                let assigneeFilter = tempPriority.filter((one) => {
                    if (task.priority && (one.name == task.priority) && one.isCheck) {
                        return one;
                    }
                })

                if (tempPriority.filter((a) => a.name == 'All')[0].isCheck) {
                    tempTask[index].isCheck = true;
                } else {
                    if (assigneeFilter.length > 0) {
                        tempTask[index].isCheck = true;
                    }
                    else {
                        tempTask[index].isCheck = false;
                    }
                }

            } else {
                tempTask[index].isCheck = false;
            }
        })

        let tempDeals = [...DropDownDeals];
        tempDeals.forEach((deal, index) => {
            let filterTask = tempTask.filter(task => task.project && (deal.Project_ID == task.project.id_string) && (task.isCheck));
            if (filterTask.length > 0) {
                tempDeals[index].isCheck = true;
            } else {
                tempDeals[index].isCheck = false;
            }
        })
        setDropDownDeals(tempDeals);
        setDropDownPriority(tempPriority)
        setDropDownTasks(tempTask);
        togglePriorityDropdownOpen();
    }

    const toggleChecksStatus = (e) => {
        //  setSearchedTask("");
        let tempStatus = [...DropdownStatus];
        let tempTask = [...DropDownTasks];
        // DropDownTasks.forEach((task) => {
        //   temp.push({ ...task });
        // });
        if ("Check-all" == e.target.id) {
            DropdownStatus.forEach((client, i) => {
                tempStatus[i].isCheck = e.target.checked;
            });
        } else {
            DropdownStatus.forEach((one, i) => {
                if ("Check-" + one.id.toString() == e.target.id) {
                    tempStatus[i].isCheck = e.target.checked;
                }
                if (one.name == 'All') {
                    tempStatus[i].isCheck = false;
                }
            });
        }


        tempTask.forEach((task, index) => {
            if (task.TaskName !== 'All') {
                let assigneeFilter = tempStatus.filter((one) => {
                    if (task.status.name && (one.name == task.status.name) && one.isCheck) {
                        return one;
                    }
                })

                if (tempStatus.filter((a) => a.name == 'All')[0].isCheck) {
                    tempTask[index].isCheck = true;
                } else {
                    if (assigneeFilter.length > 0) {
                        tempTask[index].isCheck = true;
                    }
                    else {
                        tempTask[index].isCheck = false;
                    }
                }

            } else {
                tempTask[index].isCheck = false;
            }
        })

        let tempDeals = [...DropDownDeals];
        tempDeals.forEach((deal, index) => {
            let filterTask = tempTask.filter(task => task.project && (deal.Project_ID == task.project.id_string) && (task.isCheck));
            if (filterTask.length > 0) {
                tempDeals[index].isCheck = true;
            } else {
                tempDeals[index].isCheck = false;
            }


        })

        // if((tempDeals.filter(one=>one.isCheck).length)==(tempDeals.length-1)){
        //     tempDeals[0].isCheck=true
        // }
        setDropDownDeals(tempDeals);
        setDropdownStatus(tempStatus)
        setDropDownTasks(tempTask);
        toggleStatusDropdownOpen();
    }
    const toggleChecksAssinee = (e) => {
        //  setSearchedTask("");
        let tempAssinee = [...DropDownAssinee];
        let tempTask = [...DropDownTasks];
        // DropDownTasks.forEach((task) => {
        //   temp.push({ ...task });
        // });
        if ("Check-allUser" == e.target.id) {
            DropDownAssinee.forEach((client, i) => {
                tempAssinee[i].isCheck = e.target.checked;
            });
        } else {
            DropDownAssinee.forEach((user, i) => {
                if ("Check-" + user.id.toString() == e.target.id) {
                    tempAssinee[i].isCheck = e.target.checked;
                }
                if (user.full_name == 'All') {
                    tempAssinee[i].isCheck = false;
                }
            });
        }




        tempTask.forEach((task, index) => {
            if (task.TaskName !== 'All') {
                if (("Check-allUser" == e.target.id) || ("Check-noUser" == e.target.id)) {
                    if ((task.details.owners == 0)) {
                        tempTask[index].isCheck = e.target.checked;
                    }
                }
                task.details.owners.forEach((assignee) => {
                    let assigneeFilter = tempAssinee.filter((one) => { if ((one.zuid == assignee.id) && one.isCheck) { return one; } })
                    if (assigneeFilter.length > 0) {
                        tempTask[index].isCheck = true;
                    }
                    else {
                        tempTask[index].isCheck = false;
                    }
                })
            }
        })

        // if("Check-noUser" == e.target.id){
        //   tempTask.forEach((task, index) => {
        //     if (task.TaskName !== 'All') {

        //     }
        //   })
        // }
        let tempDeals = [...DropDownDeals];
        tempDeals.forEach((deal, index) => {
            let filterTask = tempTask.filter(task => task.project && (deal.Project_ID == task.project.id_string) && (task.isCheck));
            if (filterTask.length > 0) {
                tempDeals[index].isCheck = true;
            } else {
                tempDeals[index].isCheck = false;
            }
        })
        setDropDownDeals(tempDeals);


        setAssineeSearchResult(tempAssinee)
        setDropDownTasks(tempTask);
        toggleAssineeDropDown();
    };

    const toggleAssineeDropDown = () => {
        setAssineeDropdownOpen((prevState) => !prevState);
        // setSearchedTask("");
    };

    const toggleSupervsioreDropDown = () => {
        setSupervisorDropdownOpen((prevState) => !prevState);
        // setSearchedTask("");
    };

    const getAssigneDropdown = () => {
        let filterApply = assineeSearchResult.filter((one) => !one.isCheck).length > 0 ? true : false
        return (
            <Dropdown
                className="timechart-filter-dropdown"
                isOpen={assineeDropdownOpen}
                toggle={toggleAssineeDropDown}
            >
                <DropdownToggle style={filterApply ? { color: '#0069d9' } : {}} caret>Assignee {filterApply ? <FontAwesomeIcon color="#0069d9" icon={faCheck} /> : <></>}</DropdownToggle>
                <DropdownMenu className="dropdown-hr">
                    <DropdownItem header className="dropdown-search">
                        <FormGroup className="pos-relative">
                            <Input
                                type="text"
                                onChange={(e) => searchAssignee(e.target.value)}
                                value={searchedAssinee}
                                placeholder="Search"
                                className="mr-sm-2 pad-fix search-filter-deals"
                            />
                            <FontAwesomeIcon className="mr-sm-2 search-ico" icon={faSearch} />
                        </FormGroup>
                    </DropdownItem>

                    {assineeSearchResult.map((user, i) => (
                        <DropdownItem>
                            <input
                                id={"Check-" + user.id.toString()}
                                type="checkbox"
                                checked={user.isCheck}
                                onChange={(e) => toggleChecksAssinee(e)}
                            />
                            {user.full_name}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </Dropdown>
        );
    };
    const getTasksStatusDropdown = () => {
        let filterApply = DropdownStatus.filter((one) => !one.isCheck).length > 0 ? true : false
        return (
            <Dropdown
                className="timechart-filter-dropdown"
                isOpen={statusDropdownOpen}
                toggle={toggleStatusDropdownOpen}
            >
                <DropdownToggle style={filterApply ? { color: '#0069d9' } : {}} caret>Status {filterApply ? <FontAwesomeIcon color="#0069d9" icon={faCheck} /> : <></>}</DropdownToggle>
                <DropdownMenu>
                    {/* <DropdownItem header className="dropdown-search">
            <FormGroup className="pos-relative">
              <Input
                type="text"
                onChange={(e) => searchPriority(e.target.value)}
                value={searchedPriority}
                placeholder="Search"
                className="mr-sm-2 pad-fix search-filter-deals"
              />
              <FontAwesomeIcon className="mr-sm-2 search-ico" icon={faSearch} />
            </FormGroup>
          </DropdownItem> */}

                    {DropdownStatus.map((one, i) => (
                        <DropdownItem>
                            <input
                                id={"Check-" + one.id.toString()}
                                type="checkbox"
                                checked={one.isCheck}
                                onChange={(e) => toggleChecksStatus(e)}
                            />
                            {one.name}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </Dropdown>
        );
    };

    const getTaskSupervisorDropdown = () => {
        let filterApply = assineeSearchResult.filter((one) => !one.isCheck).length > 0 ? true : false
        return (
            <Dropdown
                className="timechart-filter-dropdown"
                isOpen={supervisorDropdownOpen}
                toggle={toggleSupervsioreDropDown}
            >
                <DropdownToggle style={filterApply ? { color: '#0069d9' } : {}} caret>Supervisor {filterApply ? <FontAwesomeIcon color="#0069d9" icon={faCheck} /> : <></>}</DropdownToggle>
                <DropdownMenu className="dropdown-hr">
                    <DropdownItem header className="dropdown-search">
                        <FormGroup className="pos-relative">
                            <Input
                                type="text"
                                onChange={(e) => searchAssignee(e.target.value)}
                                value={searchedAssinee}
                                placeholder="Search"
                                className="mr-sm-2 pad-fix search-filter-deals"
                            />
                            <FontAwesomeIcon className="mr-sm-2 search-ico" icon={faSearch} />
                        </FormGroup>
                    </DropdownItem>

                    {assineeSearchResult.map((user, i) => (
                        <DropdownItem>
                            <input
                                id={"Check-" + user.id.toString()}
                                type="checkbox"
                                checked={user.isCheck}
                                onChange={(e) => toggleChecksAssinee(e)}
                            />
                            {user.full_name}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </Dropdown>
        );
    };
    const getTasksDropdown = () => {
        let filterApply = TaskSearchResult.filter((one) => !one.isCheck && (one._id !== 'allTask')).length > 0 ? true : false
        return (
            <Dropdown
                className="timechart-filter-dropdown"
                isOpen={dropdownOpen}
                toggle={toggleDropDown}
            >
                <DropdownToggle style={filterApply ? { color: '#0069d9' } : {}} caret>Tasks{filterApply ? <FontAwesomeIcon color="#0069d9" icon={faCheck} /> : <></>}</DropdownToggle>
                <DropdownMenu>
                    <DropdownItem header className="dropdown-search">
                        <FormGroup className="pos-relative">
                            <Input
                                type="text"
                                onChange={(e) => searchTask(e.target.value)}
                                value={searchedTask}
                                placeholder="Search"
                                className="mr-sm-2 pad-fix search-filter-deals"
                            />
                            <FontAwesomeIcon className="mr-sm-2 search-ico" icon={faSearch} />
                        </FormGroup>
                    </DropdownItem>

                    {TaskSearchResult.map((tasks, i) => (
                        <DropdownItem>
                            <input
                                id={"Check-" + tasks._id.toString()}
                                type="checkbox"
                                checked={tasks.isCheck}
                                onChange={(e) => toggleChecks(e)}
                            />
                            {tasks.TaskName}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </Dropdown>
        );
    };
    const getAllDeals = async () => {

         
        const backendUrl = Config.backend_url+"getAllDealPages";
        var config = {
            'token': localStorage.getItem('token')
        };
        setLoader(true);
        axios.post(backendUrl, {...config})
        //axios.post(backendUrl, { config: config })
            .then(async function (res) {
                res.data.data = res.data.data.filter((one) => one.Account_Name && (one.Account_Name.id == props.data.id));
                res.data.data.push({ Deal_Name: "Miscellaneous", Project_ID: props.data.Project_ID });

                for (let i = 0; i < subformApiName.length; i++) {
                    if (subformApiName[i] !== 'Deal_Name') {
                        let res1 = await ProjectService.GetSubformData(subformApiName[i])
                        res1.forEach((subform) => {
                            res.data.data.forEach((one, dealIdx) => {
                                if (one.id == subform.Parent_Id?.id) {
                                    if (res.data.data[dealIdx][subformApiName[i]]) {
                                        res.data.data[dealIdx][subformApiName[i]].push(subform)
                                    } else {
                                        res.data.data[dealIdx][subformApiName[i]] = [subform]
                                    }
                                }
                            })
                        })
                    }

                }
                for (let i = 0; i < moduleApiNameForSubform.length; i++) {
                    let res1 = await ModuleService.GetModuleListData(moduleApiNameForSubform[i]);
                    res.data.data.forEach((deal, dealIdx) => {
                        res1 = res1.filter(one => one.Deal_Name && (one.Deal_Name.name == deal.Deal_Name));
                        res.data.data[dealIdx][moduleApiNameForSubform[i]] = res1;
                    })
                }
                setDeals(res.data.data);
                // res.data.data = res.data.data.map((one) => {
                //     one.Created_By = one.Created_By.name;
                //     one.Owner = one.Owner.name;
                //     one.Account_Name = one.Account_Name ? one.Account_Name.name : "";
                //     return one;
                // })
                // DealIcons(res.data.data);

                let arr = []
                let originalData = [...res.data.data];
                res.data.data.forEach((deal, i) => {
                    deal.isCheck = true;
                    let obj = { DealId: deal.id, isOpen: true };
                    arr.push(obj);
                    // if (deal.Project_ID) {
                    //     arr.push({ _id: deal.Project_ID, id: deal.id, isCheck: true, ProjectNumber: deal.Deal_Name })

                    // } else {
                    //     arr.push({ _id: "NA", id: deal.id, isCheck: true, ProjectNumber: deal.Deal_Name })

                    // }

                });
                // if (res.data.data.length > 0) {
                //     res.data.data.unshift({ _id: 'allDeal', id: 'allDeal', Deal_Name: 'All', isCheck: true })
                // }
                // setGroupExpandFlag(arr);
                setDropDownDeals(res.data.data);
                setDealSearchResult(res.data.data);
                getGroupByFields(originalData, groupBy.Deal)
                setLoader(false)

            })
            .catch(function (error) {
                setLoader(false)
                console.log(error);
            });

    };

    const doSearch = (name) => {
        let searchItemstoPushinState = [];
        setSearchedItem(name);

        Deals.forEach((deal, i) => {
            if (deal.ProjectNumber.toLowerCase().includes(name.toLowerCase())) {
                searchItemstoPushinState.push(deal);
            }
        });
        setSearchResult(searchItemstoPushinState);
    };

    const DealIcons = (Deals) => {
        let dc = [];
        Deals.forEach((e, i) => {
            dc.push({
                ID: e,
                isOpen: true,
            });
        });
        setCollapseDeal(dc);
    };

    const toggleDealCollapse = (dealsId) => {
        let dc = [...CollapseDeal];
        CollapseDeal.forEach((e, i) => {
            if (e.ID == dealsId) {
                dc[i] = {
                    ID: dealsId,
                    isOpen: !e.isOpen,
                };
            }
        });
        setCollapseDeal(dc);
    };
    const getDealCollapse = (dealsId) => {
        let obj = CollapseDeal.find((e) => e.ID == dealsId);
        if (obj != undefined) {
            return obj.isOpen;
        } else {
            return true;
        }
    };
    const getTimeChart = () => {
        return <></>
        //
        // if (SelectedDeal == null) {
        //     return <Dashboard dealsId={undefined} />;
        // } else {
        //     return <Dashboard dealsId={SelectedDeal._id} />;
        // }
    };

    const [SearchResult, setSearchResult] = useState([]); ///
    const [SelectedDeal, setSelectedDeal] = useState(null); // <--  Search Deal Const
    const [SearchedItem, setSearchedItem] = useState(""); ///

    const [filterTaskPopup, setFilterTaskPopup] = useState(false);

    const filtertasktoggle = () => {
        setFilterTaskPopup(!filterTaskPopup)
    };


    const [filterDueDatePopup, setFilterDueDatePopup] = useState(false);
    const filterDueDateToggle = () => setFilterDueDatePopup(!filterDueDatePopup);


    const changeGroupBy = (e, name) => {
        const group = { ...cacheGroupBy };
        const deals = [...DropDownDeals];
        group[name] = e
        setCacheGroupBy(group);


    };
    const [SearchThisTask, setSearchThisTask] = useState("");
    const onSearchThisTask = (taskName) => {
        try {
            let searchSetting = { ...taskSearchSetting };
            let tasks = [...TaskSearchResult]
            if (taskName) {
                tasks = tasks.filter(one => {
                    let result
                    if (searchSetting.TaskName && searchSetting.Description) {
                        result = (one.name && one.name.toLowerCase().includes(taskName.toLowerCase())) || (one.description && one.description.toLowerCase().includes(taskName.toLowerCase()))
                    }
                    else if (searchSetting.TaskName) {
                        result = one.name && one.name.toLowerCase().includes(taskName.toLowerCase())
                    }
                    else if (searchSetting.Description) {
                        result = one.description && one.description.toLowerCase().includes(taskName.toLowerCase())
                    } else {
                        result = false;
                    }
                    if (result) {
                        return one;
                    }

                })
            }
            setSearchThisTask(taskName)
            setDropDownTasks(tasks)
        }
        catch (ex) {
            console.log(ex)
        }

        //setAllTasks(tasks);

    };
    const [selectedTab, setSelectedTab] = useState("tasks");
    const [allUsers, setAllUsers] = useState([]);
    const [users, setUsers] = useState([]);
    const getAllUsers = async () => {
        setLoader(true)
        UserService.GetAllUsers()
            .then((result) => {
                // console.log(result.data.users)
                setUsers(result.data.users)
                let users = [...result.data.users];

                users.forEach((user, i) => {
                    user.isCheck = true;
                });
                users.unshift({ id: 'allUser', full_name: 'All', isCheck: true })
                users.push({ id: 'noUser', full_name: 'No Assignee', isCheck: true })

                setDropDownAssinee(users);
                setAssineeSearchResult(users);
                setAllUsers(users);
                setLoader(false)
            })
            .catch((err) => {
                setLoader(false)
                console.log(err);
            })

    };
    const [allTasks, setAllTasks] = useState([]);

    const getTimeTrackLogs = (taskId) => {
        let result=[...allTimeLogs];
        result=result.filter((one)=>one.jobId==taskId)
        // console.log(result)
        let totalTime = 0;
        result.forEach((one) => {
            totalTime = totalTime + one.totaltime;
        })
        let obj = { TotalTime: totalTime, TimeTracks: result }
        return obj;
    }
    const getTaskMilestones = (projectId, milestoneId) => {
        return new Promise((resolve, reject) => {
            TaskService.GetMilestone(projectId, milestoneId)
                .then((result) => {
                    resolve(result);
                })
                .catch((err) => {
                    reject(err);
                })
        })
    }
    const getJobId = (taskId) => {
        let jobs = [...allJobs];
        let filterJob = jobs.filter(one => one.zProjectsJobRefId == taskId);
        if (filterJob.length > 0) {
            return filterJob[0].jobId
        } else {
            return '';
        }
    }
    const getAllTasks = async () => {
        setLoader(true)
        TaskService.GetMyTasks()
            .then(async (result) => {
                // result=result.filter((one)=>one.name=="test testtadgsd")
                // console.log("resulttttttttttttttttttttt", result);
                for (let i = 0; i < result.length; i++) {
                    let timeTracksObj = getTimeTrackLogs(getJobId(result[i].id_string));
                    let milestone = {}//await getTaskMilestones(result[i].milestone_id);
                    result[i].Milestone = milestone;
                    result[i].TimeTrack = timeTracksObj;
                    result[i].JobId = getJobId(result[i].id_string);
                }
                setAllTasks(result);
                result.forEach((task, i) => {
                    task.TaskName = task.name;
                    task._id = task.id_string;
                    task.isCheck = true;
                });
                if (result.length > 0) {
                    result.unshift({ TaskName: 'All', _id: 'allTask', isCheck: true })
                }
                if(props.setRefreshEnable){
                    props.setRefreshEnable(false);
                }
                setDropDownTasks(result);
                setTaskSearchResult(result);
                setLoader(false)
            })
            .catch((ex) => {
                setLoader(false)
                console.log(ex);
            })
    };
    const [addColumns, setAddColumns] = useState([
        { headerName: 'ASSIGNEE', isVisible: true },
        { headerName: 'DUE DATE', isVisible: true },
        { headerName: 'PRIORITY', isVisible: true },
        { headerName: 'TIME ESTIMATE', isVisible: true },
        { headerName: 'PROGRESS', isVisible: true },
        { headerName: 'STATUS', isVisible: true },
        { headerName: 'CATEGORY', isVisible: true },
    ])

    const handleOnChangeAddColumn = (name, checked) => {
        const columns = [...addColumns];
        columns.filter(one => one.headerName == name)[0].isVisible = checked;
        setAddColumns(columns);
    }

    // useEffect(() => {
    //   if (location.state.detail.action === "taskModalOpen") {
    //     setOpenModalDefault(location.state.detail.TaskUniqueId);
    //   }
    // }, [location])

    // const setOpenModalDefault = (taskId) => {
    //   let intervalTask = setInterval(() => {
    //     let element = document.getElementById('OpenUrlModal' + taskId);
    //     if (element) {
    //       element.click();
    //       clearInterval(intervalTask);
    //     }
    //   }, 100)

    // }

    const handleFilterLogic = (one, tasks, result) => {

    }

    const compareDateFilterResult = (date, obj) => {
        if (date) {
            date = new Date(date);
            date = dayjs(new Date(date)).format('MM-DD-YYYY');
        }
        let retsult = false;
        if (obj.value == 'Before Date' || obj.value == 'After Date') {
            if (obj.value == 'Before Date') {
                if (date && (new Date(date) < new Date(obj.date))) {
                    retsult = true;
                }
            } else if (obj.value == 'After Date') {
                if (date && (new Date(date) > new Date(obj.date))) {
                    retsult = true;
                }
            }
        } else if (obj.value == 'Date Range') {
            if (obj.date && obj.date.includes(date)) {
                retsult = true;
            }
        } else {
            let datesArr = dateTimeObject.GetDateArr(new Date(date), new Date(date), obj.value)
            if ((new Date(date) >= datesArr[0]) && (new Date(date) <= datesArr[1])) {
                retsult = true;
            }
        }
        return retsult;


    }

    const getDealFilterResult = (one, tasks, deals, logic_result) => {
        let arr = [];
        if (one.value && (one.column_name !== 'Closing Date') && (one.column_name !== 'Due Date') && (one.column_name !== 'Start Date')) {
            arr = one.value && one.value.map((one) => one.name);
        }
        if (one.Group) {
            let groupFilters = one.Group.filter((grp) => grp.phase == 'completed');
            if (one.logic == 'OR') {
                let res = getFilterResult(groupFilters, tasks, deals)
                if (logic_result || res.logic_result) {
                    // deals = res.deals;
                    // tasks = res.tasks;
                    logic_result = res.logic_result;
                }

            }
            if (one.logic == 'AND' || (!one.logic)) {
                let res = getFilterResult(groupFilters, tasks, deals)
                if (logic_result && res.logic_result) {
                    // deals = res.deals;
                    // tasks = res.tasks;
                    logic_result = res.logic_result;
                }
            }
        }
        if (one.column_name == 'Deal Name') {
            if (one.logic == 'OR') {
                if (one.condition == 'is') {
                    deals = deals.filter(deal => logic_result || (one.value.some((val) => val.name == deal.Deal_Name)));
                }
                if (one.condition == 'is not') {
                    deals = deals.filter(deal => logic_result || (!one.value.some((val) => val.name == deal.Deal_Name)));
                }
            }
            if (one.logic == 'AND' || (!one.logic)) {
                if (one.condition == 'is') {
                    deals = deals.filter(deal => logic_result && (one.value.some((val) => val.name == deal.Deal_Name)));
                }
                if (one.condition == 'is not') {
                    deals = deals.filter(deal => logic_result && (!one.value.some((val) => val.name == deal.Deal_Name)));
                }
            }
        }
        if (one.column_name == 'Status') {
            if (one.logic == 'OR') {
                if (one.condition == 'is') {
                    deals = deals.filter(deal => logic_result || (one.value.some((val) => val.name == deal.Project_Status)));
                }
                if (one.condition == 'is not') {
                    deals = deals.filter(deal => logic_result || (!one.value.some((val) => val.name == deal.Project_Status)));
                }
            }
            if (one.logic == 'AND' || (!one.logic)) {
                if (one.condition == 'is') {
                    deals = deals.filter(deal => logic_result && (one.value.some((val) => val.name == deal.Project_Status)));
                }
                if (one.condition == 'is not') {
                    deals = deals.filter(deal => logic_result && (!one.value.some((val) => val.name == deal.Project_Status)));
                }
            }
        }
        if (one.column_name == 'Client') {
            if (one.logic == 'OR') {
                if (one.condition == 'is') {
                    deals = deals.filter(deal => logic_result || (one.value.some((val) => deal.Account_Name && (val.name == deal.Account_Name.name))));
                }
                if (one.condition == 'is not') {
                    deals = deals.filter(deal => logic_result || (!one.value.some((val) => deal.Account_Name && (val.name == deal.Account_Name.name))));
                }
            }
            if (one.logic == 'AND' || (!one.logic)) {
                if (one.condition == 'is') {
                    deals = deals.filter(deal => logic_result && (one.value.some((val) => deal.Account_Name && (val.name == deal.Account_Name.name))));
                }
                if (one.condition == 'is not') {
                    deals = deals.filter(deal => logic_result && (!one.value.some((val) => deal.Account_Name && (val.name == deal.Account_Name.name))));
                }
            }
        }
        if (one.column_name == 'Created By') {
            if (one.logic == 'OR') {
                if (one.condition == 'is') {
                    deals = deals.filter(deal => logic_result || (one.value.some((val) => deal.Created_By && (val.name == deal.Created_By.name))));
                }
                if (one.condition == 'is not') {
                    deals = deals.filter(deal => logic_result || (!one.value.some((val) => deal.Created_By && (val.name == deal.Created_By.name))));
                }
            }
            if (one.logic == 'AND' || (!one.logic)) {
                if (one.condition == 'is') {
                    deals = deals.filter(deal => logic_result && (one.value.some((val) => deal.Created_By && (val.name == deal.Created_By.name))));
                }
                if (one.condition == 'is not') {
                    deals = deals.filter(deal => logic_result && (!one.value.some((val) => deal.Created_By && (val.name == deal.Created_By.name))));
                }
            }
        }
        if (one.column_name == 'Closing Date') {
            if (one.logic == 'OR') {
                if (one.condition == 'is') {
                    deals = deals.filter(deal => logic_result || compareDateFilterResult(deal.Closing_Date, one));
                }
                if (one.condition == 'is not') {
                    deals = deals.filter(deal => logic_result || compareDateFilterResult(deal.Closing_Date, one));
                }
            }
            if (one.logic == 'AND' || (!one.logic)) {
                if (one.condition == 'is') {
                    deals = deals.filter(deal => logic_result && compareDateFilterResult(deal.Closing_Date, one));
                }
                if (one.condition == 'is not') {
                    deals = deals.filter(deal => logic_result && compareDateFilterResult(deal.Closing_Date, one));
                }
            }
        }
        if (one.column_name == 'Borough') {
            if (one.logic == 'OR') {
                if (one.condition == 'is') {
                    deals = deals.filter(deal => logic_result || (one.value.some((val) => deal.Borough && (val.name == deal.Borough))));
                }
                if (one.condition == 'is not') {
                    deals = deals.filter(deal => logic_result || (!one.value.some((val) => deal.Borough && (val.name == deal.Borough))));
                }
            }
            if (one.logic == 'AND' || (!one.logic)) {
                if (one.condition == 'is') {
                    deals = deals.filter(deal => logic_result && (one.value.some((val) => deal.Borough && (val.name == deal.Borough))));
                }
                if (one.condition == 'is not') {
                    deals = deals.filter(deal => logic_result && (!one.value.some((val) => deal.Borough && (val.name == deal.Borough))));
                }
            }
        }
        // if (one.column_name == 'Category') {
        //     if (one.logic == 'OR') {
        //         if (one.condition == 'is' || one.condition == 'is set') {
        //             tasks = tasks.filter(task => logic_result || (task.custom_fields && task.custom_fields.length > 0) && (task.custom_fields.filter(ow => {
        //                 if ((ow.label_name == 'Category') && arr.includes(ow.value)) {
        //                     return ow
        //                 }


        //             }).length > 0))
        //         }
        //         if (one.condition == 'is not' || one.condition == 'is not set') {
        //             tasks = tasks.filter(task => logic_result || (task.custom_fields && task.custom_fields.length > 0) && (task.custom_fields.filter(ow => {
        //                 if ((ow.label_name == 'Category') && (!arr.includes(ow.value))) {
        //                     return ow
        //                 }


        //             }).length > 0))
        //         }
        //     }
        //     if (one.logic == 'AND' || (!one.logic)) {
        //         if (one.condition == 'is' || one.condition == 'is set') {
        //             tasks = tasks.filter(task => logic_result && (task.custom_fields && task.custom_fields.length > 0) && (task.custom_fields.filter(ow => {
        //                 if ((ow.label_name == 'Category') && arr.includes(ow.value)) {
        //                     return ow
        //                 }


        //             }).length > 0))
        //         }
        //         if (one.condition == 'is not' || one.condition == 'is not set') {
        //             tasks = tasks.filter(task => logic_result && (task.custom_fields && task.custom_fields.length > 0) && (task.custom_fields.filter(ow => {
        //                 if ((ow.label_name == 'Category') && (!arr.includes(ow.value))) {
        //                     return ow
        //                 }


        //             }).length > 0))
        //         }
        //     }
        // }
        // if (one.column_name == 'Task Name') {
        //     if (one.logic == 'OR') {
        //         if (one.logic) {
        //             if (one.condition == 'is' || one.condition == 'is set') {
        //                 tasks = tasks.filter(task => logic_result || (task.status && arr.includes(task.name)))
        //             }
        //             if (one.condition == 'is not' || one.condition == 'is not set') {
        //                 tasks = tasks.filter(task => logic_result || (task.status && !arr.includes(task.name)))
        //             }
        //         }

        //     }
        //     if (one.logic == 'AND' || (!one.logic)) {
        //         if (one.condition == 'is' || one.condition == 'is set') {
        //             tasks = tasks.filter(task => logic_result && task.status && arr.includes(task.name))
        //         }
        //         if (one.condition == 'is not' || one.condition == 'is not set') {
        //             tasks = tasks.filter(task => logic_result && (task.status && !arr.includes(task.name)))
        //         }
        //     }
        // }

        // if (one.column_name == 'Priority') {
        //     if (one.logic == 'OR') {
        //         if (one.condition == 'is' || one.condition == 'is set') {
        //             tasks = tasks.filter(task => logic_result || (task.priority && arr.includes(task.priority)))
        //         }
        //         if (one.condition == 'is not' || one.condition == 'is not set') {
        //             tasks = tasks.filter(task => logic_result || (task.priority && !arr.includes(task.priority)))
        //         }
        //     }
        //     if (one.logic == 'AND' || (!one.logic)) {
        //         if (one.condition == 'is' || one.condition == 'is set') {
        //             tasks = tasks.filter(task => logic_result && task.priority && arr.includes(task.priority))
        //         }
        //         if (one.condition == 'is not' || one.condition == 'is not set') {
        //             tasks = tasks.filter(task => logic_result && (task.priority && !arr.includes(task.priority)))
        //         }
        //     }
        //     // tasks = tasks.filter(task => task.priority && one.value.includes(task.priority))
        // }
        // if (one.column_name == 'Assignee') {
        //     if (one.logic == 'OR') {
        //         if (one.condition == 'is' || one.condition == 'is set') {
        //             tasks = tasks.filter(task => logic_result || (task.details.owners.length > 0) && (task.details.owners.filter(ow => {
        //                 if (arr.includes(ow.first_name + " " + ow.last_name)) {
        //                     return ow
        //                 }


        //             }).length > 0))
        //         }
        //         if (one.condition == 'is not' || one.condition == 'is not set') {
        //             tasks = tasks.filter(task => logic_result || (task.details.owners.length > 0) && (task.details.owners.filter(ow => {
        //                 if (arr.includes(ow.first_name + " " + ow.last_name)) {
        //                     return ow
        //                 }


        //             }).length > 0))
        //         }
        //     }
        //     if (one.logic == 'AND' || (!one.logic)) {
        //         if (one.condition == 'is' || one.condition == 'is set') {
        //             tasks = tasks.filter(task => logic_result && (task.details.owners.length > 0) && (task.details.owners.filter(ow => {
        //                 if (arr.includes(ow.first_name + " " + ow.last_name)) {
        //                     return ow
        //                 }


        //             }).length > 0))
        //         }
        //         if (one.condition == 'is not' || one.condition == 'is not set') {
        //             tasks = tasks.filter(task => logic_result && (task.details.owners.length > 0) && (task.details.owners.filter(ow => {
        //                 if (arr.includes(ow.first_name + " " + ow.last_name)) {
        //                     return ow
        //                 }


        //             }).length > 0))
        //         }
        //     }

        // }
        // if (one.column_name == 'Created By') {
        //     if (one.logic == 'OR') {
        //         if (one.condition == 'is' || one.condition == 'is set') {
        //             tasks = tasks.filter(task => logic_result || arr.includes(task.created_person))
        //         }
        //         if (one.condition == 'is not' || one.condition == 'is not set') {
        //             tasks = tasks.filter(task => logic_result || arr.includes(task.created_person))
        //         }
        //     }
        //     if (one.logic == 'AND' || (!one.logic)) {
        //         if (one.condition == 'is' || one.condition == 'is set') {
        //             tasks = tasks.filter(task => logic_result && arr.includes(task.created_person))
        //         }
        //         if (one.condition == 'is not' || one.condition == 'is not set') {
        //             tasks = tasks.filter(task => logic_result && arr.includes(task.created_person))
        //         }
        //     }

        // }
        // if (one.column_name == 'Due Date') {
        //     if (one.logic == 'OR') {

        //         if (one.condition == 'is' || one.condition == 'is set') {
        //             tasks = tasks.filter(task => logic_result || (compareDateFilterResult(task.end_date, one)))
        //         }
        //         if (one.condition == 'isnt' || one.condition == 'isnt') {
        //             tasks = tasks.filter(task => logic_result || (compareDateFilterResult(task.end_date, one)))
        //         }
        //     }
        //     if (one.logic == 'AND' || (!one.logic)) {
        //         if (one.condition == 'is' || one.condition == 'is set') {
        //             tasks = tasks.filter(task => logic_result && (compareDateFilterResult(task.end_date, one)))
        //         }
        //         if (one.condition == 'isnt' || one.condition == 'isnt') {
        //             tasks = tasks.filter(task => logic_result && (compareDateFilterResult(task.end_date, one)))
        //         }
        //     }

        // }
        // if (one.column_name == 'Start Date') {
        //     if (one.logic == 'OR') {
        //         if (one.condition == 'is' || one.condition == 'is set') {
        //             tasks = tasks.filter(task => logic_result || (compareDateFilterResult(task.start_date, one)))
        //         }
        //         if (one.condition == 'isnt' || one.condition == 'is not set') {
        //             tasks = tasks.filter(task => logic_result || (compareDateFilterResult(task.start_date, one)))
        //         }
        //     }
        //     if (one.logic == 'AND' || (!one.logic)) {
        //         if (one.condition == 'is' || one.condition == 'is set') {
        //             tasks = tasks.filter(task => logic_result && (compareDateFilterResult(task.start_date, one)))
        //         }
        //         if (one.condition == 'isnt' || one.condition == 'is not set') {
        //             tasks = tasks.filter(task => logic_result && (compareDateFilterResult(task.start_date, one)))
        //         }
        //     }

        // }
        if (one.column_name) {
            logic_result = (deals.length > 0 ? true : false);
        }

        return { tasks: tasks, deals: deals, logic_result: logic_result };

    }

    const getTaskFilterResult = (one, tasks, deals, logic_result) => {
        let arr = [];
        if (one.value && (one.column_name !== 'Due Date') && (one.column_name !== 'Start Date')) {
            arr = one.value && one.value.map((one) => one.name);
        }
        if (one.Group) {
            let groupFilters = one.Group.filter((grp) => grp.phase == 'completed');
            if (one.logic == 'OR') {
                let res = getFilterResult(groupFilters)
                if (logic_result || res.logic_result) {
                    // filterTasks = [...filterTasks,...res.tasks];
                    // filterDeals = [...filterDeals,...res.deals];
                    // tasks = res.tasks;
                    // deals = res.deals;
                    logic_result = res.logic_result;
                }

            }
            if (one.logic == 'AND' || (!one.logic)) {
                let res = getFilterResult(groupFilters)
                if (logic_result && res.logic_result) {
                    // filterTasks = [...filterTasks,...res.tasks];
                    // filterDeals = [...filterDeals,...res.tasks];
                    // tasks = res.tasks;
                    // deals = res.deals;
                    logic_result = res.logic_result;
                }
            }
        }
        if (one.column_name == 'Pipeline') {
            if (one.logic == 'OR') {
                if (one.condition == 'is' || one.condition == 'is set') {
                    tasks = tasks.filter(task => logic_result || (task.custom_fields && task.custom_fields.length > 0) && (task.custom_fields.filter(ow => {
                        if ((ow.label_name == 'Pipeline') && arr.includes(ow.value)) {
                            return ow
                        }


                    }).length > 0))
                }
                if (one.condition == 'is not' || one.condition == 'is not set') {
                    tasks = tasks.filter(task => logic_result || (task.custom_fields && task.custom_fields.length > 0) && (task.custom_fields.filter(ow => {
                        if ((ow.label_name == 'Pipeline') && (!arr.includes(ow.value))) {
                            return ow
                        }


                    }).length > 0))
                }
            }
            if (one.logic == 'AND' || (!one.logic)) {
                if (one.condition == 'is' || one.condition == 'is set') {
                    tasks = tasks.filter(task => logic_result && (task.custom_fields && task.custom_fields.length > 0) && (task.custom_fields.filter(ow => {
                        if ((ow.label_name == 'Pipeline') && arr.includes(ow.value)) {
                            return ow
                        }


                    }).length > 0))
                }
                if (one.condition == 'is not' || one.condition == 'is not set') {
                    tasks = tasks.filter(task => logic_result && (task.custom_fields && task.custom_fields.length > 0) && (task.custom_fields.filter(ow => {
                        if ((ow.label_name == 'Pipeline') && (!arr.includes(ow.value))) {
                            return ow
                        }


                    }).length > 0))
                }
            }
        }
        if (one.column_name == 'Category') {
            if (one.logic == 'OR') {
                if (one.condition == 'is' || one.condition == 'is set') {
                    tasks = tasks.filter(task => logic_result || (task.custom_fields && task.custom_fields.length > 0) && (task.custom_fields.filter(ow => {
                        if ((ow.label_name == 'Category') && arr.includes(ow.value)) {
                            return ow
                        }


                    }).length > 0))
                }
                if (one.condition == 'is not' || one.condition == 'is not set') {
                    tasks = tasks.filter(task => logic_result || (task.custom_fields && task.custom_fields.length > 0) && (task.custom_fields.filter(ow => {
                        if ((ow.label_name == 'Category') && (!arr.includes(ow.value))) {
                            return ow
                        }


                    }).length > 0))
                }
            }
            if (one.logic == 'AND' || (!one.logic)) {
                if (one.condition == 'is' || one.condition == 'is set') {
                    tasks = tasks.filter(task => logic_result && (task.custom_fields && task.custom_fields.length > 0) && (task.custom_fields.filter(ow => {
                        if ((ow.label_name == 'Category') && arr.includes(ow.value)) {
                            return ow
                        }


                    }).length > 0))
                }
                if (one.condition == 'is not' || one.condition == 'is not set') {
                    tasks = tasks.filter(task => logic_result && (task.custom_fields && task.custom_fields.length > 0) && (task.custom_fields.filter(ow => {
                        if ((ow.label_name == 'Category') && (!arr.includes(ow.value))) {
                            return ow
                        }


                    }).length > 0))
                }
            }
        }
        if (one.column_name == 'Task Name') {
            if (one.logic == 'OR') {
                if (one.logic) {
                    if (one.condition == 'is' || one.condition == 'is set') {
                        tasks = tasks.filter(task => logic_result || (task.status && arr.includes(task.name)))
                    }
                    if (one.condition == 'is not' || one.condition == 'is not set') {
                        tasks = tasks.filter(task => logic_result || (task.status && !arr.includes(task.name)))
                    }
                }

            }
            if (one.logic == 'AND' || (!one.logic)) {
                if (one.condition == 'is' || one.condition == 'is set') {
                    tasks = tasks.filter(task => logic_result && task.status && arr.includes(task.name))
                }
                if (one.condition == 'is not' || one.condition == 'is not set') {
                    tasks = tasks.filter(task => logic_result && (task.status && !arr.includes(task.name)))
                }
            }
        }
        if (one.column_name == 'Status') {
            if (one.logic == 'OR') {
                if (one.logic) {
                    if (one.condition == 'is' || one.condition == 'is set') {
                        tasks = tasks.filter(task => logic_result || (task.status && arr.includes(task.status.name)))
                    }
                    if (one.condition == 'is not' || one.condition == 'is not set') {
                        tasks = tasks.filter(task => logic_result || (task.status && !arr.includes(task.status.name)))
                    }
                }

            }
            if (one.logic == 'AND' || (!one.logic)) {
                if (one.condition == 'is' || one.condition == 'is set') {
                    tasks = tasks.filter(task => logic_result && task.status && arr.includes(task.status.name))
                }
                if (one.condition == 'is not' || one.condition == 'is not set') {
                    tasks = tasks.filter(task => logic_result && (task.status && !arr.includes(task.status.name)))
                }
            }
        }
        if (one.column_name == 'Priority') {
            if (one.logic == 'OR') {
                if (one.condition == 'is' || one.condition == 'is set') {
                    tasks = tasks.filter(task => logic_result || (task.priority && arr.includes(task.priority)))
                }
                if (one.condition == 'is not' || one.condition == 'is not set') {
                    tasks = tasks.filter(task => logic_result || (task.priority && !arr.includes(task.priority)))
                }
            }
            if (one.logic == 'AND' || (!one.logic)) {
                if (one.condition == 'is' || one.condition == 'is set') {
                    tasks = tasks.filter(task => logic_result && task.priority && arr.includes(task.priority))
                }
                if (one.condition == 'is not' || one.condition == 'is not set') {
                    tasks = tasks.filter(task => logic_result && (task.priority && !arr.includes(task.priority)))
                }
            }
            // tasks = tasks.filter(task => task.priority && one.value.includes(task.priority))
        }
        if (one.column_name == 'Assignee') {
            if (one.logic == 'OR') {
                if (one.condition == 'is' || one.condition == 'is set') {
                    tasks = tasks.filter(task => logic_result || (task.details && task.details.owners) && (task.details.owners.length > 0) && (task.details.owners.filter(ow => {
                        if (arr.some(name => name.startsWith(ow.name))) {
                            return ow
                        }


                    }).length > 0))
                }
                if (one.condition == 'is not' || one.condition == 'is not set') {
                    tasks = tasks.filter(task => logic_result || (task.details && task.details.owners) && (task.details.owners.length > 0) && (task.details.owners.filter(ow => {
                        if (arr.some(name => name.startsWith(ow.name))) {
                            return ow
                        }


                    }).length > 0))
                }
            }
            if (one.logic == 'AND' || (!one.logic)) {
                if (one.condition == 'is' || one.condition == 'is set') {
                    tasks = tasks.filter(task => logic_result && (task.details && task.details.owners) && (task.details.owners.length > 0) && (task.details.owners.filter(ow => {
                        if (arr.some(name => name.startsWith(ow.name))) {
                            return ow
                        }


                    }).length > 0))
                }
                if (one.condition == 'is not' || one.condition == 'is not set') {
                    tasks = tasks.filter(task => logic_result && (task.details && task.details.owners) && (task.details.owners.length > 0) && (task.details.owners.filter(ow => {
                        if (arr.some(name => name.startsWith(ow.name))) {
                            return ow
                        }


                    }).length > 0))
                }
            }

        }
        if (one.column_name == 'Created By') {
            if (one.logic == 'OR') {
                if (one.condition == 'is' || one.condition == 'is set') {
                    tasks = tasks.filter(task => logic_result || arr.includes(task.created_person))
                }
                if (one.condition == 'is not' || one.condition == 'is not set') {
                    tasks = tasks.filter(task => logic_result || arr.includes(task.created_person))
                }
            }
            if (one.logic == 'AND' || (!one.logic)) {
                if (one.condition == 'is' || one.condition == 'is set') {
                    tasks = tasks.filter(task => logic_result && arr.includes(task.created_person))
                }
                if (one.condition == 'is not' || one.condition == 'is not set') {
                    tasks = tasks.filter(task => logic_result && arr.includes(task.created_person))
                }
            }

        }
        if (one.column_name == 'Due Date') {
            if (one.logic == 'OR') {

                if (one.condition == 'is' || one.condition == 'is set') {
                    tasks = tasks.filter(task => logic_result || (compareDateFilterResult(task.end_date, one)))
                }
                if (one.condition == 'isnt' || one.condition == 'isnt') {
                    tasks = tasks.filter(task => logic_result || (compareDateFilterResult(task.end_date, one)))
                }
            }
            if (one.logic == 'AND' || (!one.logic)) {
                if (one.condition == 'is' || one.condition == 'is set') {
                    tasks = tasks.filter(task => logic_result && (compareDateFilterResult(task.end_date, one)))
                }
                if (one.condition == 'isnt' || one.condition == 'isnt') {
                    tasks = tasks.filter(task => logic_result && (compareDateFilterResult(task.end_date, one)))
                }
            }

        }
        if (one.column_name == 'Start Date') {
            if (one.logic == 'OR') {
                if (one.condition == 'is' || one.condition == 'is set') {
                    tasks = tasks.filter(task => logic_result || (compareDateFilterResult(task.start_date, one)))
                }
                if (one.condition == 'isnt' || one.condition == 'is not set') {
                    tasks = tasks.filter(task => logic_result || (compareDateFilterResult(task.start_date, one)))
                }
            }
            if (one.logic == 'AND' || (!one.logic)) {
                if (one.condition == 'is' || one.condition == 'is set') {
                    tasks = tasks.filter(task => logic_result && (compareDateFilterResult(task.start_date, one)))
                }
                if (one.condition == 'isnt' || one.condition == 'is not set') {
                    tasks = tasks.filter(task => logic_result && (compareDateFilterResult(task.start_date, one)))
                }
            }

        }
        if (one.column_name) {
            logic_result = (tasks.length > 0 ? true : false);
        }

        return { tasks: tasks, logic_result: logic_result, deals: deals };
    }
    const conditions = {
        '<': (x, y) => x < y ? true : false,
        '<=': (x, y) => x <= y ? true : false,
        '>': (x, y) => x > y ? true : false,
        '>=': (x, y) => x >= y ? true : false,
        '=': (x, y) => x == y ? true : false
    };

    const getLogicResultDeal = (data, one) => {
        let arr = [];
        if (one.value && (one.column_name !== 'Closing Date') && (one.column_name !== 'Due Date') && (one.column_name !== 'Start Date')) {
            arr = one.value && one.value.map((one) => one.name);
        }
        if (one.Group && (one.Group.filter((cond) => cond.phase == 'completed').length > 0)) {
            let conditionalArray = one.Group;
            conditionalArray = one.Group.filter((cond) => cond.phase == 'completed');
            var i = 0;
            for (const el of conditionalArray) {
                let filterDeal = Deals.filter((one) => data.project && (one.Project_ID == data.project.id_string))
                if (filterDeal.length > 0) {
                    filterDeal = filterDeal[0]
                } else {
                    filterDeal = {}
                }

                if (conditionalArray.length == 1) {
                    if (conditionalArray[0].module == 'Deal') {
                        return getLogicResultDeal(filterDeal, conditionalArray[0])
                    } else {
                        return getLogicResult(data, conditionalArray[0])
                    }
                }

                if (i == 0) {

                    if (conditionalArray[i + 1].logic == "OR") {
                        let res1
                        let res2
                        if (conditionalArray[i + 1].module == 'Deal') {
                            res1 = getLogicResult(data, el)
                            res2 = getLogicResultDeal(filterDeal, conditionalArray[i + 1])
                        }
                        else if (el.module == 'Deal') {
                            res1 = getLogicResultDeal(filterDeal, el)
                            res2 = getLogicResult(data, conditionalArray[i + 1])
                        } else {
                            res1 = getLogicResult(data, el)
                            res2 = getLogicResult(data, conditionalArray[i + 1])
                        }
                        if (!(res1 || res2)) {
                            return false;
                        }
                    }
                    else {
                        let res1
                        let res2
                        if (conditionalArray[i + 1].module == 'Deal') {
                            res1 = getLogicResult(data, el)
                            res2 = getLogicResultDeal(filterDeal, conditionalArray[i + 1])
                        }
                        else if (el.module == 'Deal') {
                            res1 = getLogicResultDeal(filterDeal, el)
                            res2 = getLogicResult(data, conditionalArray[i + 1])
                        } else {
                            res1 = getLogicResult(data, el)
                            res2 = getLogicResult(data, conditionalArray[i + 1])
                        }

                        if (!(res1 && res2)) {
                            return false;
                        }
                    }
                }
                else {
                    if (el.logic == "OR") {
                        let res1
                        let res2
                        if (conditionalArray[i - 1].module == 'Deal') {
                            res1 = getLogicResult(data, el)
                            res2 = getLogicResultDeal(filterDeal, conditionalArray[i - 1])
                        }
                        else if (el.module == 'Deal') {
                            res1 = getLogicResultDeal(filterDeal, el)
                            res2 = getLogicResult(data, conditionalArray[i - 1])
                        } else {
                            res1 = getLogicResult(data, el)
                            res2 = getLogicResult(data, conditionalArray[i - 1])
                        }
                        if (!(res1 || res2)) {
                            return false;
                        }

                    }
                    else {
                        let res1
                        let res2
                        if (conditionalArray[i - 1].module == 'Deal') {
                            res1 = getLogicResult(data, el)
                            res2 = getLogicResultDeal(filterDeal, conditionalArray[i - 1])
                        }
                        else if (el.module == 'Deal') {
                            res1 = getLogicResultDeal(filterDeal, el)
                            res2 = getLogicResult(data, conditionalArray[i - 1])
                        } else {
                            res1 = getLogicResult(data, el)
                            res2 = getLogicResult(data, conditionalArray[i - 1])
                        }

                        if (!(res1 && res2)) {
                            return false;
                        }

                    }
                }
                i++;
            }
            let final = Deals.filter((one) => data.project && (one.Project_ID == data.project.id_string));
            dealsResult = [...final, ...dealsResult];
            dealsResult = _.uniqBy(dealsResult, function (e) {
                return e.id;
            });
            return true;
        }
        if (one.column_name == 'Deal Name') {
            if (one.condition == 'is') {
                return ((one.value && one.value.some((val) => val.name == data.Deal_Name))) ? true : false;
            }
            if (one.condition == 'is not') {
                return (!one.value.some((val) => val.name == data.Deal_Name)) ? true : false;
            }
        }
        if (one.column_name == 'Status') {
            if (one.condition == 'is') {
                return (one.value.some((val) => val.name == data.Project_Status)) ? true : false

            }
            if (one.condition == 'is not') {
                return (!one.value.some((val) => val.name == data.Project_Status)) ? true : false
            }
        }
        if (one.column_name == 'Client') {
            if (one.condition == 'is') {
                return one.value && one.value.some((val) => data.Account_Name && (val.name == data.Account_Name.name)) ? true : false;
            }
            if (one.condition == 'is not') {
                return (!(one.value && one.value.some((val) => data.Account_Name && (val.name == data.Account_Name.name))) ? true : false);
            }
        }
        if (one.column_name == 'Created By') {
            if (one.condition == 'is') {
                return one.value.some((val) => data.Created_By && (val.name == data.Created_By.name));
            }
            if (one.condition == 'is not') {
                return one.value.some((val) => data.Created_By && (val.name !== data.Created_By.name));
            }
        }
        if (one.column_name == 'Closing Date') {
            if (one.condition == 'is') {
                return compareDateFilterResult(data.Closing_Date, one);
            }
            if (one.condition == 'is not') {
                return !compareDateFilterResult(data.Closing_Date, one);
            }
        }
        if (one.column_name == 'Borough') {
            if (one.condition == 'is') {
                return one.value.some((val) => data.Borough && (val.name == data.Borough))
            }
            if (one.condition == 'is not') {
                return !one.value.some((val) => data.Borough && (val.name == data.Borough))
            }
        }

        // return { tasks: tasks, deals: deals, logic_result: logic_result };

    }

    const getLogicResult = (data, one) => {

        // if ((one.module == 'Deal')&&(data.module=='Deal')) {
        //     return getLogicResultDeal(data, one)
        // }else if((one.module == 'Task')&&(data.module=='Task')){
        let arr = [];
        if (one.value && (one.column_name !== 'Due Date') && (one.column_name !== 'Start Date')) {
            arr = one.value && one.value.map((one) => one.name);
        }
        if (one.Group && (one.Group.filter((cond) => cond.phase == 'completed').length > 0)) {
            let conditionalArray = one.Group;
            conditionalArray = one.Group.filter((cond) => cond.phase == 'completed');

            var i = 0;
            for (const el of conditionalArray) {
                let filterDeal = Deals.filter((one) => data.project && (one.Project_ID == data.project.id_string))
                if (filterDeal.length > 0) {
                    filterDeal = filterDeal[0]
                } else {
                    filterDeal = {}
                }

                if (conditionalArray.length == 1) {
                    if (conditionalArray[0].module == 'Deal') {
                        return getLogicResultDeal(filterDeal, conditionalArray[0])
                    } else {
                        return getLogicResult(data, conditionalArray[0])
                    }
                }

                if (i == 0) {

                    if (conditionalArray[i + 1].logic == "OR") {
                        let res1
                        let res2
                        if (conditionalArray[i + 1].module == 'Deal') {
                            res1 = getLogicResult(data, el)
                            res2 = getLogicResultDeal(filterDeal, conditionalArray[i + 1])
                        }
                        else if (el.module == 'Deal') {
                            res1 = getLogicResultDeal(filterDeal, el)
                            res2 = getLogicResult(data, conditionalArray[i + 1])
                        } else {
                            res1 = getLogicResult(data, el)
                            res2 = getLogicResult(data, conditionalArray[i + 1])
                        }
                        if (!(res1 || res2)) {
                            return false;
                        }
                    }
                    else {
                        let res1
                        let res2
                        if (conditionalArray[i + 1].module == 'Deal') {
                            res1 = getLogicResult(data, el)
                            res2 = getLogicResultDeal(filterDeal, conditionalArray[i + 1])
                        }
                        else if (el.module == 'Deal') {
                            res1 = getLogicResultDeal(filterDeal, el)
                            res2 = getLogicResult(data, conditionalArray[i + 1])
                        } else {
                            res1 = getLogicResult(data, el)
                            res2 = getLogicResult(data, conditionalArray[i + 1])
                        }

                        if (!(res1 && res2)) {
                            return false;
                        }
                    }
                }
                else {
                    if (el.logic == "OR") {
                        let res1
                        let res2
                        if (conditionalArray[i - 1].module == 'Deal') {
                            res1 = getLogicResult(data, el)
                            res2 = getLogicResultDeal(filterDeal, conditionalArray[i - 1])
                        }
                        else if (el.module == 'Deal') {
                            res1 = getLogicResultDeal(filterDeal, el)
                            res2 = getLogicResult(data, conditionalArray[i - 1])
                        } else {
                            res1 = getLogicResult(data, el)
                            res2 = getLogicResult(data, conditionalArray[i - 1])
                        }
                        if (!(res1 || res2)) {
                            return false;
                        }

                    }
                    else {
                        let res1
                        let res2
                        if (conditionalArray[i - 1].module == 'Deal') {
                            res1 = getLogicResult(data, el)
                            res2 = getLogicResultDeal(filterDeal, conditionalArray[i - 1])
                        }
                        else if (el.module == 'Deal') {
                            res1 = getLogicResultDeal(filterDeal, el)
                            res2 = getLogicResult(data, conditionalArray[i - 1])
                        } else {
                            res1 = getLogicResult(data, el)
                            res2 = getLogicResult(data, conditionalArray[i - 1])
                        }

                        if (!(res1 && res2)) {
                            return false;
                        }

                    }
                }
                i++;
            }
            let final = Deals.filter((one) => data.project && (one.Project_ID == data.project.id_string));
            dealsResult = [...final, ...dealsResult];
            dealsResult = _.uniqBy(dealsResult, function (e) {
                return e.id;
            });
            return true;


        }

        if (one.column_name == 'Pipeline') {
            if (one.condition == 'is' || one.condition == 'is set') {
                return (data.custom_fields && data.custom_fields.length > 0) && (data.custom_fields.filter(ow => {
                    if ((ow.label_name == 'Pipeline') && (arr.includes(ow.value))) {
                        return ow
                    }


                }).length > 0) ? true : false
            }
            if (one.condition == 'is not' || one.condition == 'is not set') {
                return (data.custom_fields && data.custom_fields.length > 0) && (data.custom_fields.filter(ow => {
                    if ((ow.label_name == 'Pipeline') && (!arr.includes(ow.value))) {
                        return ow
                    }


                }).length > 0) ? true : false
            }
        }
        if (one.column_name == 'Category') {
            if (one.condition == 'is' || one.condition == 'is set') {
                return (data.custom_fields && data.custom_fields.length > 0) && (data.custom_fields.filter(ow => {
                    if ((ow.label_name == 'Category') && (arr.includes(ow.value))) {
                        return ow
                    }


                }).length > 0) ? true : false
            }
            if (one.condition == 'is not' || one.condition == 'is not set') {
                return (data.custom_fields && data.custom_fields.length > 0) && (data.custom_fields.filter(ow => {
                    if ((ow.label_name == 'Category') && (!arr.includes(ow.value))) {
                        return ow
                    }


                }).length > 0) ? true : false
            }
        }
        if (one.column_name == 'Task Name') {
            if (one.condition == 'is' || one.condition == 'is set') {
                return data.name && arr.includes(data.name) ? true : false
            }
            if (one.condition == 'is not' || one.condition == 'is not set') {
                return data.name && (!arr.includes(data.name)) ? true : false
            }
        }
        if (one.column_name == 'Status') {
            if (one.condition == 'is' || one.condition == 'is set') {
                let res = (data.status && arr.includes(data.status.name)) ? true : false
                return res;
            }
            if (one.condition == 'is not' || one.condition == 'is not set') {
                let res = ((!arr.includes(data.status.name))) ? true : false
                return res;
            }

        }
        if (one.column_name == 'Priority') {
            if (one.condition == 'is' || one.condition == 'is set') {
                return data.priority && arr.includes(data.priority) ? true : false
            }
            if (one.condition == 'is not' || one.condition == 'is not set') {
                return (!arr.includes(data.priority)) ? true : false
            }
            // tasks = tasks.filter(task => task.priority && one.value.includes(task.priority))
        }
        if (one.column_name == 'Assignee') {
            if (one.condition == 'is' || one.condition == 'is set') {
                return (data.details && data.details.owners) && (data.details.owners.length > 0) && (data.details.owners.filter(ow => {
                    if (arr.some(name => name.startsWith(ow.name))) {
                        return ow
                    }


                }).length > 0) ? true : false
            }
            if (one.condition == 'is not' || one.condition == 'is not set') {
                return (data.details && data.details.owners) && (data.details.owners.length > 0) && (data.details.owners.filter(ow => {
                    if (!arr.some(name => name.startsWith(ow.name))) {
                        return ow
                    }


                }).length > 0) ? true : false
            }

        }
        if (one.column_name == 'Created By') {
            if (one.condition == 'is' || one.condition == 'is set') {
                return arr.includes(data.created_person) ? true : false;
            }
            if (one.condition == 'is not' || one.condition == 'is not set') {
                return !arr.includes(data.created_person) ? true : false;
            }

        }
        if (one.column_name == 'Due Date') {
            if (one.condition == 'is' || one.condition == 'is set') {
                return compareDateFilterResult(data.end_date, one)
            }
            if (one.condition == 'isnt' || one.condition == 'isnt') {
                return !compareDateFilterResult(data.end_date, one)
            }

        }
        if (one.column_name == 'Start Date') {
            if (one.condition == 'is' || one.condition == 'is set') {
                return compareDateFilterResult(data.start_date, one)
            }
            if (one.condition == 'isnt' || one.condition == 'is not set') {
                return !compareDateFilterResult(data.start_date, one)
            }

        }

        // }
        // else{
        //     return true;
        // }

    }
    const getFilterResult = (taskFilters) => {
        let conditionalArray = [...taskFilters];
        let tasks = [...TaskSearchResult];
        let deals = [...Deals]
        // tasks.forEach((one) => one.module = 'Task');
        // deals.forEach((one) => one.module = 'Deal')
        let dataToFilter = tasks
        // tasks = [...tasks];
        // deals = [...deals];
        let returnDeals = []
        let result = []
        conditionalArray = conditionalArray.filter((one) => (one.phase == 'completed') || one.Group);
        if (conditionalArray.length == 1) {
            if (conditionalArray[0].module == 'Deal') {
                dealsResult = deals.filter((one) => getLogicResultDeal(one, conditionalArray[0]))
            } else {
                dealsResult = deals;
                result = tasks.filter((one) => getLogicResult(one, conditionalArray[0]))
            }
        } else {
            result = dataToFilter.filter(data => {
                var i = 0;

                for (const el of conditionalArray) {
                    let filterDeal = deals.filter((one) => data.project && (one.Project_ID == data.project.id_string))
                    if (filterDeal.length > 0) {
                        filterDeal = filterDeal[0]
                    } else {
                        filterDeal = {}
                    }

                    if (i == 0) {

                        if (conditionalArray[i + 1].logic == "OR") {
                            let res1
                            let res2
                            if (conditionalArray[i + 1].module == 'Deal') {
                                res1 = getLogicResult(data, el)
                                res2 = getLogicResultDeal(filterDeal, conditionalArray[i + 1])
                            }
                            else if (el.module == 'Deal') {
                                res1 = getLogicResultDeal(filterDeal, el)
                                res2 = getLogicResult(data, conditionalArray[i + 1])
                            } else {
                                res1 = getLogicResult(data, el)
                                res2 = getLogicResult(data, conditionalArray[i + 1])
                            }
                            if (!(res1 || res2)) {
                                return false;
                            }
                        }
                        else {
                            let res1
                            let res2
                            if (conditionalArray[i + 1].module == 'Deal') {
                                res1 = getLogicResult(data, el)
                                res2 = getLogicResultDeal(filterDeal, conditionalArray[i + 1])
                            }
                            else if (el.module == 'Deal') {
                                res1 = getLogicResultDeal(filterDeal, el)
                                res2 = getLogicResult(data, conditionalArray[i + 1])
                            } else {
                                res1 = getLogicResult(data, el)
                                res2 = getLogicResult(data, conditionalArray[i + 1])
                            }

                            if (!(res1 && res2)) {
                                return false;
                            }
                        }
                    }
                    else {
                        if (el.logic == "OR") {
                            let res1
                            let res2
                            if (conditionalArray[i - 1].module == 'Deal') {
                                res1 = getLogicResult(data, el)
                                res2 = getLogicResultDeal(filterDeal, conditionalArray[i - 1])
                            }
                            else if (el.module == 'Deal') {
                                res1 = getLogicResultDeal(filterDeal, el)
                                res2 = getLogicResult(data, conditionalArray[i - 1])
                            } else {
                                res1 = getLogicResult(data, el)
                                res2 = getLogicResult(data, conditionalArray[i - 1])
                            }
                            if (!(res1 || res2)) {
                                return false;
                            }

                        }
                        else {
                            let res1
                            let res2
                            if (conditionalArray[i - 1].module == 'Deal') {
                                res1 = getLogicResult(data, el)
                                res2 = getLogicResultDeal(filterDeal, conditionalArray[i - 1])
                            }
                            else if (el.module == 'Deal') {
                                res1 = getLogicResultDeal(filterDeal, el)
                                res2 = getLogicResult(data, conditionalArray[i - 1])
                            } else {
                                res1 = getLogicResult(data, el)
                                res2 = getLogicResult(data, conditionalArray[i - 1])
                            }

                            if (!(res1 && res2)) {
                                return false;
                            }

                        }
                    }
                    i++;
                }
                let final = deals.filter((one) => data.project && (one.Project_ID == data.project.id_string));
                dealsResult = [...final, ...dealsResult];
                dealsResult = _.uniqBy(dealsResult, function (e) {
                    return e.id;
                });
                return true;

            });
        }

        return { tasks: result };
    }

    const getTaskFilterSetting = () => {
        return <>
            {/* <UncontrolledTooltip
                placement="top"
                target="search-setting"
            >
                Search setting
            </UncontrolledTooltip> */}


            <AntdPopover
                placement="bottom"
                trigger="click"
                // isOpen={employeeNotePopup}
                content={<div>
                    <div className="column-show-hide">
                        <AddColumn onChangeColumn={(columns) => { setAddTaskColumns(columns) }} columns={addTaskColumns} />
                    </div>

                    <hr className="hrcls"></hr>
                    <div className="row task-filter-hovr">
                        <div className="col-sm-9">
                            <a
                                onClick={() => {

                                }}
                            >Task Location
                            </a>
                        </div>
                        <div className="col-sm-3">
                            <Switch
                                onChange={(checked, event) => {
                                    const obj = { ...taskFilterSetting };
                                    obj.TaskLocation = checked;
                                    setTaskFilterSetting(obj)
                                }}

                                size="small"
                                checked={taskFilterSetting.TaskLocation}
                            />
                        </div>
                    </div>

                    <hr className="hrcls"></hr>
                    <div className="row task-filter-hovr">
                        <div className="col-sm-9">
                            <a onClick={() => {
                            }}
                            >Subtask parent names
                            </a>
                        </div>
                        <div className="col-sm-3">
                            <Switch
                                onChange={(checked, event) => {
                                    const obj = { ...taskFilterSetting };
                                    obj.SubtaskParntNames = checked;
                                    setTaskFilterSetting(obj)

                                }}
                                size="small"
                                checked={taskFilterSetting.SubtaskParntNames}
                            />
                        </div>
                    </div>

                    <hr className="hrcls"></hr>
                    <div className="row task-filter-hovr">
                        <div className="col-sm-9">
                            <a onClick={() => {
                            }}
                            >Expand All Deals
                            </a>
                        </div>
                        <div className="col-sm-3">
                            <Switch
                                onChange={(checked, event) => {
                                    const obj = { ...taskFilterSetting };
                                    obj.ExpandDeals = checked;
                                    if (!checked) {
                                        obj.ExpandGroups = false;
                                        obj.ExpandTasks = false;
                                    }
                                    if (obj.ExpandDeals == true) {
                                        unCollapseAllDeals()
                                    }
                                    else {
                                        collapseAllDeals()
                                    }
                                    setTaskFilterSetting(obj)

                                }}
                                size="small"
                                checked={taskFilterSetting.ExpandDeals}
                            />
                        </div>
                    </div>

                    <hr className="hrcls"></hr>
                    <div className="row task-filter-hovr">
                        <div className="col-sm-9">
                            <a >Expand All Groups</a>
                        </div>
                        <div className="col-sm-3">
                            <Switch
                                onChange={(checked, event) => {
                                    const obj = { ...taskFilterSetting };
                                    let arr = [...groupExpandFlag]
                                    arr.forEach(one => {
                                        one.isOpen = checked
                                    })
                                    obj.ExpandGroups = checked;
                                    if (checked) {
                                        obj.ExpandDeals = true;
                                        unCollapseAllDeals()

                                    }
                                    if (!checked) {
                                        obj.ExpandTasks = false;
                                    }
                                    setTaskFilterSetting(obj)
                                    setGroupExpandFlag(arr)

                                }}
                                size="small"
                                checked={taskFilterSetting.ExpandGroups}
                            />
                        </div>
                    </div>

                    <hr className="hrcls"></hr>
                    <div className="row task-filter-hovr">
                        <div className="col-sm-9">
                            <a onClick={() => {
                            }}
                            >Expand All Tasks
                            </a>
                        </div>
                        <div className="col-sm-3">
                            <Switch
                                onChange={(checked, event) => {
                                    const obj = { ...taskFilterSetting };
                                    let arr = [...groupExpandFlag]

                                    obj.ExpandTasks = checked;
                                    if (checked) {
                                        unCollapseAllDeals()
                                        obj.ExpandGroups = true;
                                        obj.ExpandDeals = true;
                                        arr.forEach(one => {
                                            one.isOpen = true
                                        })
                                    }
                                    setTaskFilterSetting(obj)

                                }}
                                size="small"
                                checked={taskFilterSetting.ExpandTasks}
                            />
                        </div>
                    </div>

                    <hr className="hrcls"></hr>
                    <div className="row task-filter-hovr">
                        <div className="col-sm-9">
                            <a onClick={() => {
                            }}
                            >View List Separate Task
                            </a>
                        </div>
                        <div className="col-sm-3">
                            <Switch
                                size="small"
                                onChange={(checked, event) => {
                                    const obj = { ...taskFilterSetting };
                                    obj.ViewSeparateTask = checked;
                                    setTaskFilterSetting(obj);
                                }}
                                checked={taskFilterSetting.ViewSeparateTask ? taskFilterSetting.ViewSeparateTask : false}
                            />
                        </div>
                    </div>

                    {allTasks.filter(ta => ta.selected).length > 0 ?
                        <>
                            <hr className="hrcls"></hr>
                            <div className="row task-filter-hovr">
                                <div className="col-sm-10">
                                    <AntdPopover
                                        placement="bottom"
                                        trigger="click"
                                        content={getSelectedTaskAction()}
                                    >
                                        <div className="col-sm-12 pl-0">
                                            Action
                                        </div>

                                    </AntdPopover>
                                </div>
                                <div className="col-sm-2">
                                    <FontAwesomeIcon style={{ fontSize: '14px' }} className="float-right" icon={faCaretDown} />
                                </div>
                            </div>

                        </> : <></>}


                </div>}
            >
                <span className="ml-3"><FontAwesomeIcon icon={faEllipsisV} id='filter-setting' />
                </span>
            </AntdPopover>
        </>
    }





    // useEffect(() => {
    //     if (filters.filter(one => one.phase == 'completed').length > 0) {
    //         // let deals = [...Deals]
    //         // let tasks = [...TaskSearchResult]
    //         // filterTasks = []
    //         // filterDeals = []
    //         dealsResult = []
    //         let obj = getFilterResult(filters);
    //         getGroupByFields(dealsResult, groupBy.Deal)
    //         // deals = filterResult.deals;
    //         // tasks = filterResult.tasks;
    //         setDropDownDeals(dealsResult);
    //         setDropDownTasks(obj.tasks);
    //     } else {
    //         let deals = [...Deals]
    //         let tasks = [...TaskSearchResult]
    //         getGroupByFields(deals, groupBy.Deal);
    //         setDropDownDeals(deals);
    //         setDropDownTasks(tasks);

    //     }

    // }, [filters])

    const onChangeRadio = (task) => {
        const all = [...allTasks];
        let idx = all.findIndex(one => one.id_string == task.id_string);
        if (idx > -1) {
            all[idx].selected = !all[idx].selected;
            setAllTasks(all)
        }

    }
    const [dueDateAction, setDueDateAction] = useState(false);
    const [assigneeAction, setAssigneAction] = useState(false);

    const updateMultipleTask = (tasks, key, data) => {
        let doArr = [];
        if (tasks) {
            tasks.forEach((one) => {
                doArr.push(TaskService.UpdateTask(one.project_id, one.id_string, key, data))
            })
            Promise.all(doArr)
                .then((result) => {
                    // console.log(result);
                    getAllTasks()
                })
                .catch((err) => {
                    console.log(err);
                })
        }

    }

    const [taskDeleteWarningToggle, setTaskDeleteWarningToggle] = useState(false);
    const taskDeleteWarningModal = () => {
        return (
            <Modal
                isOpen={taskDeleteWarningToggle}
                backdrop={'static'}
                size='md'
                className="taskComplete-warning"
            >

                <ModalBody>
                    <Row >
                        <Col sm={12}>
                            <FontAwesomeIcon icon={faExclamationTriangle} size='3x' className="text-warning" />
                            <h5 className="my-2">Warning</h5>
                            <p className="taskComplete-warning-subtitle">Are you sure? you want to delete tasks</p>
                        </Col>
                    </Row>
                    <Row >
                        <Col sm={12}>
                            <button
                                className='btn btn-primary w-100'
                                onClick={async () => {
                                    let all = [...allTasks];
                                    all = all.filter(one => one.selected);
                                    if (all.length > 0) {
                                        let doArr = []
                                        try{
                                            for(let i=0;i<all.length;i++){
                                                await TaskService.DeleteTask(all[i].project_id, all[i].id_string);
                                               
                                            }
                                            setTaskDeleteWarningToggle(false)
                                            getAllTasks();
                                        }
                                        catch(err){
                                            console.log(err);
                                        }
                                        // all.forEach((one) => {
                                        //     doArr.push(TaskService.DeleteTask(one.project_id, one.id_string))
                                        // })
                                        // Promise.all(doArr)
                                        //     .then((result) => {
                                        //         setTaskDeleteWarningToggle(false)
                                        //         getAllTasks();
                                        //         console.log(result);
                                        //     })
                                        //     .catch((err) => {
                                        //         console.log(err);
                                        //     })
                                    }
                                }}

                            >Yes</button>


                            <button onClick={() => {
                                setTaskDeleteWarningToggle(false)
                            }} className='btn btn-secondary w-100 mt-2'>Cancel</button>
                        </Col>

                    </Row>
                </ModalBody>
            </Modal>
        )
    }

    const getSelectedTaskAction = () => {
        return <>
            <hr className="hrcls"></hr>
            <div className="row task-filter-hovr">

                <div className="col-sm-9">
                    <a onClick={() => {

                        setTaskDeleteWarningToggle(true)
                    }}
                    >Delete
                    </a>
                </div>
            </div>
            <hr className="hrcls"></hr>
            <div className="row task-filter-hovr">

                <StatusDropdown toggelButton={<>
                    <div className="col-sm-9">
                        <a onClick={() => {
                        }}
                        >Set Status
                        </a>
                    </div>

                </>}
                invertColor={props.invertColor}
                 items={taskLayout.status_details} 
                 onSelect={(value) => {
                    let all = [...allTasks];
                    all = all.filter((one) => one.selected);
                    updateMultipleTask(all, "custom_status", value.id)
                }} 
                />

            </div>

            <hr className="hrcls"></hr>
            <div className="row task-filter-hovr">

                <UncontrolledDropdown >
                    <div className="col-sm-9 three-dots-priority">
                        <a onClick={() => {
                        }}
                        ><DropdownToggle>
                                Set Priority
                            </DropdownToggle>
                        </a>
                    </div>
                    <DropdownMenu style={props.id == 'tasklist' ? { maxHeight: '100px', overflowY: 'auto' } : {}}>
                        {priorityValues.map((value, i) => {
                            return (
                                <DropdownItem
                                    id={'priority-click' + value.id + value.name}
                                    onClick={(e) => {
                                        let all = [...allTasks];
                                        all = all.filter((one) => one.selected);
                                        updateMultipleTask(all, "priority", value.name)
                                    }

                                        // updateTask(data, 'priority', value.name)
                                    }
                                >
                                    <FontAwesomeIcon
                                        style={{
                                            color: value.color,
                                            marginRight: "5px",
                                        }}
                                        icon={faFlagSolid}
                                    />{" "}
                                    {value.name}
                                </DropdownItem>
                            );
                        })}
                        <DropdownItem
                            onClick={(e) => {
                                // updateTask(data.id_string, 'priority', 'None')
                            }}
                        >
                            <FontAwesomeIcon
                                icon={faTimes}
                                style={{
                                    color: "red",
                                    marginRight: "10px",
                                }}
                            />
                            CLEAR
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>



            </div>


            <hr className="hrcls"></hr>
            <div className="row task-filter-hovr ">

                <div className="col-sm-9 datepicker-action">
                    <a onClick={() => setDueDateAction(!dueDateAction)}>Set Due Date
                    </a>
                    <DatePicker
                        onChange={(evnt) => {
                            let all = [...allTasks];
                            all = all.filter((one) => one.selected);
                            let formattedDate = dayjs(new Date(evnt)).format("MM-DD-YYYY");
                            let empthStartDateTask=all.filter((one)=>!one.start_date)
                            all=all.filter((one)=>one.start_date);
                            updateMultipleTask(all, "end_date", formattedDate);

                            if(empthStartDateTask.length>0){
                                empthStartDateTask=empthStartDateTask.map(task=>task.name);
                                empthStartDateTask=empthStartDateTask.join(' , ');
                                swal(`${empthStartDateTask} these tasks not set due date becuase start date not preset.`);
                            }
                        }
                        }
                        open={dueDateAction}
                        popperPlacement="bottom"
                        popperModifiers={{
                            flip: {
                                behavior: ['bottom'] // don't allow it to flip to be above
                            },
                            preventOverflow: {
                                enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                            },
                            hide: {
                                enabled: false // turn off since needs preventOverflow to be enabled
                            }
                        }}
                    />
                </div>
            </div>
            {/* <hr className="hrcls"></hr>
            <div className="row task-filter-hovr">

                <CustomDropdown isUser={true} toggelButton={<>
                    <div className="col-sm-9">
                        <a onClick={() => {
                        }}
                        >Set Assignee
                        </a>
                    </div>

                </>} items={allUsers} onSelect={(value) => {
                    //  let all = [...allTasks];
                    //  all = all.filter((one) => one.selected);
                    //  let formattedDate = dayjs(new Date(evnt)).format("MM-DD-YYYY");
                    //  updateMultipleTask(all, "end_date", formattedDate);
                    // updateTask(data, "custom_status", value.id)
                    // updateTask(data, 'custom_fields', JSON.stringify({ UDF_CHAR6: value.CategoryName }))
                }} />
            </div> */}

            <hr className="hrcls"></hr>
            <div className="row task-filter-hovr">
                <CustomDropdown toggelButton={<>
                    <div className="col-sm-9">
                        <a onClick={() => {
                        }}
                        >Set Category
                        </a>
                    </div>

                </>} items={getCategoryNames()} onSelect={(value) => {
                    let all = [...allTasks];
                    all = all.filter((one) => one.selected);
                    updateMultipleTask(all, "custom_fields", JSON.stringify({ UDF_CHAR6: value.name }));
                    // updateTask(data, "custom_status", value.id)
                    // updateTask(data, 'custom_fields', JSON.stringify({ UDF_CHAR6: value.CategoryName }))
                }} />
            </div>
            {/* <hr className="hrcls"></hr>
            <div className="row task-filter-hovr">

                <CustomDropdown toggelButton={<>
                    <div className="col-sm-9">
                        <a onClick={() => {
                        }}
                        >Set Milestone
                        </a>
                    </div>

                </>} items={tasklist} onSelect={(value) => {
                    let all = [...allTasks];
                    all = all.filter((one) => one.selected);
                    updateMultipleTask(all, "tasklist_id", value.id);
                }} />
            </div> */}
            {/* <hr className="hrcls"></hr>
            <div className="row task-filter-hovr">

                <div id="task_action_time_estimate" className="col-sm-9">
                    <a>Set Time Estimate
                    </a>
                    <UncontrolledPopover
                        className="time_est_popover"
                        trigger="legacy"
                        placement="bottom"
                        target="task_action_time_estimate"
                    >
                        <PopoverBody className="m-2">
                            <b className="taskstatus-heading-right">
                                Time Estimate :
                            </b>
                            <div className="time_est_fields">
                                <div className="time_fields">
                                    <Input
                                        type="text"
                                        placeholder="00"
                                        value={'0'}
                                        onChange={(e) => {
                                            //   if (e.target.value.length >= 2) {
                                            //     let str1 = e.target.value.replace(/^0+/, '');
                                            //     setTimeEstimateValue(str1);
                                            //   }
                                            //   else {
                                            //     setTimeEstimateValue(e.target.value)
                                            //   }

                                        }}
                                        onKeyDown={(e) => {

                                        }

                                        }
                                    />
                                    <span className="time_unit">
                                        hrs
                                    </span>
                                </div>
                                <div className="time_fields ml-2">
                                    <Input
                                        type="text"
                                        placeholder="00"
                                        // value={timeMinuteValue.length == 1 ? '0' + timeMinuteValue : timeMinuteValue}
                                        onChange={(e) => {

                                        }
                                        }
                                        onKeyDown={(e) => { }
                                        }
                                    />
                                    <span className="time_unit">
                                        min
                                    </span>
                                </div>
                            </div>
                            <div className="text-right mt-2">
                            </div>
                        </PopoverBody>
                    </UncontrolledPopover>
                </div>
            </div> */}

        </>
    }


    const getDealByGroupName = (group, groupIdx) => {
        let deals = [...DropDownDeals]
        let tasks = [...DropDownTasks]
        // let filterResult = getFilterResult(filters, tasks, deals);
        // // getGroupByFields(filterResult.deals,groupBy.Deal)
        // deals = filterResult.deals;
        // tasks = filterResult.tasks;
        let value = groupByDealItem.filter((one) => one.name == groupBy.Deal)[0];
        value = value.value
        if (groupBy.Deal == 'Client') {
            deals = deals.filter((deal) => {
                if (deal.Account_Name && deal.Account_Name.name == group) {
                    return deal;
                } else {
                    if (group == 'None') {
                        return deal;
                    }
                }
            })
        } else if (groupBy.Deal == 'Status') {
            deals = deals.filter((deal) => {
                if (deal.Project_Status && (deal.Project_Status == group)) {
                    return deal;
                } else {
                    if (group == 'None') {
                        return deal;
                    }
                }
            })
        }
        else if (groupBy.Deal == 'Closing Date') {
            deals = deals.filter((deal) => {
                if (deal[value] && deal[value] == group) {
                    return deal;
                } else {
                    if (group == 'None') {
                        return deal;
                    }
                }
            })
        }
        else if (groupBy.Deal == 'Block Number') {
            // let value=groupByDealItem.filter((one)=>one.name==groupBy.Deal)[0];
            // value=value.value
            deals = deals.filter((deal) => {
                if (deal[value] && deal[value] == group) {
                    return deal;
                } else {
                    if (group == 'None') {
                        return deal;
                    }
                }
            })
        } else if (groupBy.Deal == 'Lot Number') {
            deals = deals.filter((deal) => {
                if (deal[value] && deal[value] == group) {
                    return deal;
                } else {
                    if (group == 'None') {
                        return deal;
                    }
                }
            })
        } else if (groupBy.Deal == 'Bin Number') {
            deals = deals.filter((deal) => {
                if (deal[value] && deal[value] == group) {
                    return deal;
                } else {
                    if (group == 'None') {
                        return deal;
                    }
                }
            })

        } else if (groupBy.Deal == 'Deal Name') {
            deals = deals.filter((deal) => {
                if (deal[value] && deal[value] == group) {
                    return deal;
                } else {
                    if (group == 'None') {
                        return deal;
                    }
                }
            })

        } else {

            deals = deals.filter((deal) => {
                if (deal[groupBy.Deal] && deal[groupBy.Deal] == group) {
                    return deal;
                } else {
                    if (group == 'None') {
                        return deal;
                    }
                }
            })
        }
        let myTask = [];
        let taskToPass = []
        deals.forEach((deal) => {
            if (deal.Project_ID) {
                let ids = deal.Project_ID;
                ids = ids.split(',');
                ids = ids.filter(id => id);
                let task = tasks.filter((task) => task.project && (ids.includes(task.project.id_string)));
                let tasksPass = tasks.filter((task) => !task.parent_task_id && task.project && (ids.includes(task.project.id_string)));
                task.forEach((one) => {
                    one.Deal_Name = deal.Deal_Name;
                    one.DealId = deal.id;
                })
                tasksPass.forEach((one) => {
                    one.Deal_Name = deal.Deal_Name;
                    one.DealId = deal.id;
                })
                myTask = [...task, ...myTask];
                taskToPass = [...taskToPass, ...tasksPass];
            }
        })
        if (assignedMeVisible) {
            let info = JSON.parse(localStorage.getItem('userinfo'))
            let name = info.first_name + " " + info.last_name;
            myTask = myTask.filter(task => (task.details.owners.length > 0) && (task.details.owners.filter(ow => {
                if (name == (ow.first_name + " " + ow.last_name)) {
                    return ow
                }


            }).length > 0))
            taskToPass = taskToPass.filter(task => (task.details.owners.length > 0) && (task.details.owners.filter(ow => {
                if (name == (ow.first_name + " " + ow.last_name)) {
                    return ow
                }


            }).length > 0))
        }
        if (taskToPass.length > 0) {
            let obj = { Deal_Name: "", DealId: '', ProjectId: [] }
            taskToPass.forEach(one => {
                obj.Deal_Name = one.Deal_Name;
                obj.DealId = one.DealId;
                if (!obj.ProjectId.includes(one.project_id))
                    obj.ProjectId.push(one.project_id);
            })
            let dealObj = deals.filter(one => one.id == obj.DealId);
            dealObj = dealObj.length > 0 ? dealObj[0] : {}
            return React.cloneElement(<Tasks />, {
                history:history,
                taskLayout:taskLayout,
                setTimerPrevTimeTrack:props.setTimerPrevTimeTrack,
                startstopClockTimeTrack: props.startstopClockTimeTrack,
                setTimerTimeTrack: props.setTimerTimeTrack,
                timerTimeTrack: props.timerTimeTrack,
                timerObj: props.timerObj,
                timeTrackFlag:props.timeTrackFlag,
                initializeTimeTrack:props.initializeTimeTrack,
                getAllTasks:getAllTasks,
                taskLocation: props.taskLoaction,
                dependencyList: dependencyList,
                onChangeRadio: onChangeRadio,
                AllClients: AllClients,
                dealStatus: dealStatus,
                history: history,
                dealGroupExpand: groupExpandFlag,
                Deals: Deals,
                dealGroup: group,
                taskLocationShow: taskLocationShow,
                showParentNames: showParentNames,
                columns: addTaskColumns,
                taskFilterSetting: taskFilterSetting,
                taskSearchSetting: taskSearchSetting,
                setOpenTaskModel: setOpenTaskModel,

                groupIdx: groupIdx,
                isView: props.isVisible,
                isAccess: props.isAccess,
                selectedRole: props.selectedRole,
                handleOnChangeAddColumn: handleOnChangeAddColumn,
                dealObj: dealObj,
                dealInfo: obj,
                addColumns: addColumns,
                DropDownDeals: DropDownDeals,
                allUsers: allUsers,
                allJobs: allJobs,
                groupBy: groupBy.Task,
                allTasks: taskToPass,
                myTasks: myTask,
                setAllTasks: setAllTasks,
                id: 'tasklist',
                dealsId: "none",
                groupExpandFlag: groupExpandFlag
            }, null)
        }


    }

    const getTaskCardbyId = (deal, tasksToPass) => {
        // let dealObj = Deals.filter(obj => obj.id == deal.id);
        let dealObj = deal
        let myTasks = tasksToPass.filter((task) => task.project && (task.project.id_string == deal.Project_ID));
        tasksToPass = tasksToPass.filter((task) => !task.parent_task_id && task.project && (task.project.id_string == deal.Project_ID));


        if ((tasksToPass.length > 0) && (allUsers.length > 0)) {
            tasksToPass = getFilterResult(filters, tasksToPass).tasks;
            return (
                // <></>
                React.cloneElement(<Tasks />, {
                    history:history,
                    taskLayout:taskLayout,
                    setTimerPrevTimeTrack:props.setTimerPrevTimeTrack,
                    startstopClockTimeTrack: props.startstopClockTimeTrack,
                    setTimerTimeTrack: props.setTimerTimeTrack,
                    timerTimeTrack: props.timerTimeTrack,
                    timerObj: props.timerObj,
                    timeTrackFlag:props.timeTrackFlag,
                    initializeTimeTrack:props.initializeTimeTrack,
                    getAllTasks:getAllTasks,
                    taskLocation: props.taskLoaction,
                    dependencyList: dependencyList,
                    AllClients: AllClients,
                    dealStatus: dealStatus,
                    Deals: Deals,
                    history: history,
                    taskLocationShow: taskLocationShow,
                    showParentNames: showParentNames,
                    columns: addTaskColumns,
                    taskFilterSetting: taskFilterSetting,
                    taskSearchSetting: taskSearchSetting,
                    setOpenTaskModel: setOpenTaskModel,
                    isView: props.isVisible,
                    isAccess: props.isAccess,
                    selectedRole: props.selectedRole,
                    handleOnChangeAddColumn: handleOnChangeAddColumn,
                    dealObj: dealObj,
                    addColumns: addColumns,
                    DropDownDeals: DropDownDeals,
                    allUsers: allUsers,
                    allJobs: allJobs,
                    groupBy: groupBy.Task,
                    allTasks: tasksToPass,
                    myTasks: myTasks,
                    setAllTasks: setAllTasks,
                    id: 'tasklist',
                    dealsId: deal._id,
                    groupExpandFlag: groupExpandFlag
                }, null)

                // <Tasks
                //     setOpenTaskModel={setOpenTaskModel}
                //     openTaskModal={openTaskModal}
                //     isView={props.isVisible}
                //     isAccess={props.isAccess}
                //     selectedRole={props.selectedRole}
                //     handleOnChangeAddColumn={handleOnChangeAddColumn}
                //     dealObj={dealObj}
                //     addColumns={addColumns}
                //     // dealsId={deal._id}
                //     DropDownDeals={DropDownDeals}
                //     allUsers={allUsers}
                //     allJobs={allJobs}
                //     groupBy={groupBy}
                //     allTasks={tasksToPass}
                //     setAllTasks={setAllTasks}
                //     id='tasklist'
                //     dealsId={deal._id}
                // />
                // card
                //     isTemplate={false}
                //     addColumns={addColumns}
                //     handleOnChangeAddColumn={handleOnChangeAddColumn}
                //     dealsId={deal._id}
                //     groupBy={groupBy}
                //     searchTask={SearchThisTask}
                //     allTasks={tasksToPass}
                //     DropDownDeals={DropDownDeals}
                //     allUsers={allUsers}
                //   //searchTask={SearchThisTask}
                // />
                // <Tasks
                //   addColumns={addColumns}
                //   handleOnChangeAddColumn={handleOnChangeAddColumn}
                //   dealsId={id}
                //   groupBy={groupBy}
                //   searchTask={SearchThisTask}
                //   allTasks={tasksToPass}
                //   allUsers={allUsers}
                // //searchTask={SearchThisTask}
                // ></Tasks>
            );
        } else {
            return <></>;
        }
    };


    const getTaskSearchSetting = () => {
        return <>
            <UncontrolledTooltip
                placement="top"
                target="search-setting"
            >
                Search setting
            </UncontrolledTooltip>


            <AntdPopover
                placement="bottom"
                trigger="click"
                // isOpen={employeeNotePopup}
                content={<div>
                    <a onClick={() => {

                    }}>Task Name        <Switch onChange={(checked, event) => { const obj = { ...taskSearchSetting }; obj.TaskName = checked; setTaskSearchSetting(obj) }} style={{ marginLeft: 35 }} size="small" checked={taskSearchSetting.TaskName} /></a><br></br>
                    <hr></hr>
                    <a onClick={() => {

                    }}>Task Description  <Switch onChange={(checked, event) => { const obj = { ...taskSearchSetting }; obj.Description = checked; setTaskSearchSetting(obj) }} size="small" checked={taskSearchSetting.Description} /></a>
                    {/* <hr></hr> */}
                    {/* <a onClick={() => {

                    }}>Custom Fields     <Switch onChange={(checked,event)=>{const obj={...taskSearchSetting};obj.Custom_Fields=checked;setTaskSearchSetting(obj)}} style={{ marginLeft: 15 }} size="small" checked={taskSearchSetting.Custom_Fields} /></a> */}
                </div>}
            >
                <span className="ml-3"><FontAwesomeIcon icon={faEllipsisH} id='search-setting' /></span>
            </AntdPopover>
        </>
    }

    const groupByTaskItem = [
        { name: "None", value: 'None' },
        { name: "Pipeline", value: 'Pipeline' },
        { name: "Status", value: 'Status' },
        { name: "Assignee", value: 'Assignee' },
        { name: "Priority", value: 'Priority' },
        { name: "Category", value: 'Category' },
        { name: "Milestone", value: 'Milestone' },
        { name: "Superviser", value: 'Superviser' },
        { name: "Due Date", value: 'Due Date' }

    ]
    const groupByDealItem = [
        { name: "None", value: 'None' },
        { name: "Status", value: 'Status' },
        { name: "Deal Name", value: 'Deal_Name' },
        // { name: "Client", value: 'Client' },
        { name: "Closing Date", value: 'Closing Date' },
        { name: "NYC Project", value: ' Is_NYC_Project' },
        { name: "Borough", value: 'Borough' },
        // { name: "Block Number", value: 'Block_Number0' },
        // { name: "Bin Number", value: 'Bin_Number' },
        // { name: "Lot Number", value: 'Lot_Number0' }

    ]

    const [itemsTaskGroup, setItemTaskGroup] = useState(groupByTaskItem);
    const [itemDealGroup, setItemDealGroup] = useState(groupByDealItem)


    const getSearchBox = (module) => {
        return <FormGroup className="group-by-search" style={{ position: 'relative' }} >
            <Input

                type="text"
                placeholder="Search"
                className="mr-sm-2 pad-fix search-filter"
                onChange={(e) => {
                    if (module == 'Task') {
                        let items = [...groupByTaskItem]
                        if (e.currentTarget.value !== '') {
                            items = items.filter((one) => one.name.toLowerCase().includes(e.currentTarget.value.toLowerCase()))
                        }
                        setItemTaskGroup(items)
                    } else {
                        let items = [...groupByDealItem]
                        if (e.currentTarget.value !== '') {
                            items = items.filter((one) => one.name.toLowerCase().includes(e.currentTarget.value.toLowerCase()))
                        }
                        setItemDealGroup(items)
                    }

                }}
            // value={searchValue}
            // onChange={(e) => onSearchColumns(e.target.value)}
            />
            <FontAwesomeIcon
                className="mr-sm-2 search-ico"
                icon={faSearch}
            />
        </FormGroup>
    }


    const getElementGroupBy = () => {
        return <> <UncontrolledTooltip
            placement="top"
            target="taskfilter-popup"
        >
            Group By  Deal : {groupBy.Deal}<br></br>
            Group By  Task : {groupBy.Task}
        </UncontrolledTooltip>
            <AntdPopover
                style={{ height: 300 }}
                placement="bottom"
                trigger="click"

                isOpen={filterTaskPopup}

                content={<><div className="row group-by-tasklist" style={{ width: 400 }}>
                    <div style={{ borderRight: '1px solid lightgray' }} className="col deal-group-by">
                        <div className="list-group-item-header">
                            <span> GROUP BY DEAL</span>

                            {getSearchBox('Deal')}
                        </div>
                        <ul className="list-group">

                            {itemDealGroup.map((one) => {
                                return <li onClick={(e) => changeGroupBy(one.name, 'Deal')} className={one.name == cacheGroupBy.Deal ? "list-group-item active" : "list-group-item"}>{one.name}</li>
                            })}
                        </ul>
                    </div>
                    <div className="col task-group-by">
                        <div className="list-group-item-header">
                            <span> GROUP BY TASK</span>

                            {getSearchBox('Task')}
                        </div>
                        <ul className="list-group">
                            {/* <li className="list-group-item">
                                <span>GROUP BY TASK</span>
                                {getSearchBox('Task')}
                            </li> */}
                            {/* <li className="list-group-item ">
                          
                            </li> */}
                            {itemsTaskGroup.map((one) => {
                                return <li onClick={(e) => changeGroupBy(one.value, 'Task')} className={one.value == cacheGroupBy.Task ? "list-group-item active" : "list-group-item"}>{one.name}</li>
                            })}
                        </ul>
                    </div>

                </div><div className="row">
                        <div className="col ">
                            <span onClick={() => {
                                const obj = { ...cacheGroupBy };
                                let deals = [...DropDownDeals];
                                // deals=deals.filter((one=>))
                                setGroupBy(obj);
                                getGroupByFields(deals, obj.Deal);
                                filtertasktoggle();
                            }} className="float-right btn btn-sm btn-primary">Apply</span>
                        </div>
                    </div></>}
            >
                <span id="taskfilter-popup" className="text-truncate" onClick={() => filtertasktoggle()} style={{ display: 'flex', cursor: 'pointer', padding: '0px 8px' }} >Group By
                    <p style={{ color: '#0194f7', marginLeft: 3, marginBottom: '0px' }}>{groupBy.Deal},{groupBy.Task} <FontAwesomeIcon icon={faChevronDown} /></p></span>

            </AntdPopover>
        </>
    }


    const getFIlterTask = () => {
        let tasks = [...DropDownTasks];
        let deals = [...DropDownDeals];
        let myTask = tasks.filter((task) => {
            if (deals.filter((one) => {
                if (task.project && (task.project.id_string == one.Project_ID)) {
                    return one;
                }
            }).length > 0) {
                return task
            }
        })
        let taskToPass = tasks.filter((task) => {
            if (deals.filter((one) => {
                if (!task.parent_task_id && task.project && (task.project.id_string == one.Project_ID)) {
                    return one;
                }
            }).length > 0) {
                return task;
            }
        })
        if (assignedMeVisible) {
            let info = JSON.parse(localStorage.getItem('userinfo'))
            let name = info.first_name + " " + info.last_name;
            myTask = myTask.filter(task => (task.details.owners.length > 0) && (task.details.owners.filter(ow => {
                if (name == (ow.first_name + " " + ow.last_name)) {
                    return ow
                }


            }).length > 0))
            taskToPass = taskToPass.filter(task => (task.details.owners.length > 0) && (task.details.owners.filter(ow => {
                if (name == (ow.first_name + " " + ow.last_name)) {
                    return ow
                }


            }).length > 0))
        }

        return { my_task: myTask, task_to_pass: taskToPass }

    }


    const getDealNameWithClientName = (group) => {

        if (groupBy.Deal == 'Deal Name') {
            let deals = [...DropDownDeals];
            deals = deals.filter((one) => one.Deal_Name == group);
            let colorObj = { background: "#5b6c97", color: '#fff' }
            let clientColorObj = { background: "#5b6c97", color: '#fff' }
            let filterClient = {};
            if (deals.length > 0) {
                deals = deals[0];
                if (deals.Project_Status_ID) {
                    let color_code = dealStatus.filter(one => one.status_id == deals.Project_Status_ID)
                    if (color_code.length > 0) {
                        color_code = color_code[0];
                        colorObj = { background: color_code.status_color_hexcode, color: '#fff' }
                    }

                }

                // if (AllClients.length > 0) {
                //     filterClient = AllClients.filter((one) => deals.Account_Name && (one.id == deals.Account_Name.id));
                //     if (filterClient.length > 0) {
                //         filterClient = filterClient[0];
                //         if (filterClient.Color) {
                //             clientColorObj.background = filterClient.Color;
                //         }
                //     }
                // }
            }





            /* background: #5b6c97;
 color: #fff; */
            return <> <a style={colorObj} onClick={() => {
                deals.Created_By = deals.Created_By.name;
                deals.Owner = deals.Owner.name;
                deals.Account_Name = deals.Account_Name ? deals.Account_Name.name : "";
                history.push({
                    pathname: '/d/Deals/DetailView/' + deals.id,
                    state: { data: deals, action: 'DetailView', module: 'Deals' }
                });
            }}>{group}</a></>
        } else {

            let defaultColor = { background: "#5b6c97", color: '#fff' }
            if (groupBy.Deal == 'Status') {
                let filterResult = dealStatus.filter((one) => one.status_name == group);
                if (filterResult.length > 0) {
                    filterResult = filterResult[0];
                    defaultColor = { background: filterResult.status_color_hexcode, color: '#fff' }
                }

            }

            return <><a style={defaultColor}>{group}</a></>
        }

    }


    const resetViewFilters = () => {
        let allheaderData = [...addTaskColumns];
        const deals = [...DropDownDeals]
        let searchObj = { ...taskFilterSetting }
        allheaderData.forEach((one) => {
            one.isChecked = false;
            one.isVisible = true;
        })
        setAddTaskColumns(allheaderData);
        getGroupByFields(deals, 'Deal Name')
        setGroupBy({ Task: "Pipeline", Deal: 'Deal Name' });
        setCacheGroupBy({ Task: "Pipeline", Deal: 'Deal Name' });
        setAssignedMeVisible(false);
        searchObj.ViewSeparateTask = false;
        searchObj.TaskLocation = false;
        searchObj.SubtaskParntNames = false;
        setTaskFilterSetting(searchObj);
    }

    const setViewFilters = (viewData) => {
        if (viewData.length > 0) {
            const deals = [...DropDownDeals]
            let searchObj = { ...taskFilterSetting }
            viewData = viewData[0]

            setCacheGroupBy(viewData.group_by);
            setAssignedMeVisible(viewData.is_me_selected);
            setAddTaskColumns(viewData.columns);
            setGroupBy(viewData.group_by);
            getGroupByFields(deals, viewData.group_by.Deal)
            searchObj.TaskLocation = viewData.is_task_location;
            searchObj.SubtaskParntNames = viewData.is_parent_name;
            searchObj.ViewSeparateTask = viewData.is_seprate_task;
            searchObj.ExpandDeals = viewData.is_expand_deal;
            searchObj.ExpandGroups = viewData.is_expand_group;
            searchObj.ExpandTasks = viewData.is_expand_task;
            setTaskFilterSetting(searchObj);

        }
    }

    const getViewColumns = (viewData) => {
        // let viewNames =  viewData.map(obj=> {
        //     return obj.fields
        // });
        if (viewData == 'All') {
            let columnsData = [...addTaskColumns];
            columnsData.forEach(e => e.isChecked = false)
            // setheaderData(columnsData);
            setViewData(viewData);
            // resetViewFilters()
        } else {
            let viewApiNames = viewData[0].columns.map(one => {
                return one.id
            })
            let filterViewData = [];
            let columnsData = [...viewData[0].columns]
            for (let i = 0; i < columnsData.length; i++) {
                if (viewApiNames.includes(columnsData[i].id)) {
                    // filterViewData.push(columnsData[i]);
                    columnsData[i].isChecked = columnsData[i].isChecked
                }
                else {
                    columnsData[i].isChecked = true;
                }

            }
            // viewData[0].columns = columnsData
            setViewData(viewData);
            // setViewFilters(viewData);
        }
        setColumnsEdit(false);
    }

    const getHeaderArr = () => {
        let columns = [...addTaskColumns];
        let arr = []
        let obj = {
            is_expand_deal: taskFilterSetting.ExpandDeals, is_expand_task: taskFilterSetting.ExpandTasks, is_expand_group: taskFilterSetting.ExpandGroups,
            columns: columns, group_by: groupBy, filters: filters, is_me_selected:
                assignedMeVisible, is_task_location: taskFilterSetting.TaskLocation, is_parent_name: taskFilterSetting.SubtaskParntNames, is_seprate_task: taskFilterSetting.ViewSeparateTask
        };
        arr.push(obj);
        return arr;

    }

    const handleCloseTaskModel = async (taskType, task) => {
        setOpenTaskModel(false);
        // if (props.setOpenTaskModel) {
        //   props.setOpenTaskModel(false);
        // }
        // if (taskType == 'task') {
        //   setNestedModal(false);
        //   setParentObject(null);
        // }
        // if (taskType == 'subtask') {
        //   setOpenModalType('task');
        //   setTaskAction('Edit')
        //   setSubtaskNestedModal(false);
        // }
        // if (taskType == 'metatask') {
        //   setMetaTaskNestedModal(false);
        //   setOpenModalType('subtask');
        //   setTaskAction('Edit')
        // }


    }

    const handleCreateTaskWithProject = (ProjectID, taskData) => {
        if (ProjectID) {
            TaskService.CreateTask(ProjectID, taskData)
                .then((result) => {
                    // console.log(result);
                    handleCloseTaskModel('task', "")
                })
                .catch(err => {
                    console.log(err);
                })
        } else {
            ProjectService.CreateProject({ name: props.data.Account_Name + '-' + "Miscellaneous" })
                .then(async (project) => {
                    if (project && project.id_string) {
                        await ModuleService.CreateModuleData('Accounts', { id: props.data.id, Project_ID: project.id_string })
                        return TaskService.CreateTask(project.id_string, taskData);
                    } else {
                        return project;
                    }
                })
                .then((result) => {
                    // console.log(result);
                    handleCloseTaskModel('task', "")
                })
                .catch(err => {
                    console.log(err);
                })
        }

    }


    const handleOnChangeDeal = (e) => {
        let obj = { ...dummyDealObj };
        obj[e.currentTarget.name] = e.currentTarget.value;
        obj["id"] = e.currentTarget.id;
        obj['Project_ID'] = e.currentTarget.Project_ID;
        obj['LastProjectID'] = e.currentTarget.LastProjectID;
        setDummyDealObj(obj);
    }


    const renderTaskModal = () => {
        return (

            <TaskModal
            // handleOpenTaskModel={handleOpenTaskModel}
            // parentTaskObj={parentTaskObj}
            initializeTimeTrack={props.initializeTimeTrack}
            timeTrackFlag={props.timeTrackFlag}
                getTaskDependencyData={getTaskDependencyData}
                dependencyList={dependencyList}
                deals={Deals}
                taskCreateLocation="Account"
                handleCreateTaskWithProject={handleCreateTaskWithProject}
                handleOnChangeDeal={handleOnChangeDeal}
                // handleClickSubtaskEdit={handleClickSubtaskEdit}
                // DependencyDropdownData={DependencyDropdownData}
                // tasklist={tasklist}
                // setMainTaskId={setMainTaskId}
                id={""}
                allProject={allProjects}
                // allMilestones={props.allMilestones}
                style='taskdetail-modal-subtask'
                // setTaskAction={setTaskAction}
                // setParentObject={setParentObject}
                // getallTasks={getAllMainTask}
                taskDocs={allTasks}
                // handleOpenTaskModel={handleOpenTaskModel}
                // task={parentObj}
                // taskType={openmodalType}
                taskAction={taskAction}
                nestedModal={openTaskModal}
                handleCloseTaskModel={handleCloseTaskModel}
                dealObj={dummyDealObj}
            // pipeline={groupByField}
            // handleCreateDuplicateTask={handleCreateDuplicateTask}
            />
        );


    };
    return (<></>
        // <div className="project-tasks-wrapper">
        //     {loader ? <Loader /> : <></>}
        //     <div className="row m-0">
        //         <div className={"col-sm-12"}>
        //             <div style={!filterState ? { marginTop: '3px' } : { marginTop: '15px' }} className="project-task-card p-0">
        //                 <div className="task-tab pr-0 row">
        //                     <div className="col-sm-7">
        //                         <div className="d-flex align-items-center mb-3">
        //                             <div className="project-search-filter">
        //                                 <FormGroup className="pos-relative m-0">
        //                                     <Input //////  Search Box
        //                                         type="text"
        //                                         placeholder="Search"
        //                                         className="search-filter-deals"
        //                                         value={SearchThisTask}
        //                                         onChange={(e) => onSearchThisTask(e.target.value)}
        //                                     />
        //                                     <FontAwesomeIcon className="search-ico" icon={faSearch} />
        //                                 </FormGroup>
        //                                 {getTaskSearchSetting()}

        //                                 <div className="task-filter-btn">
        //                                     {getElementGroupBy()}
        //                                 </div>
        //                                 <div className="task_filter_box">

        //                                     <Task_Filters
        //                                         dealData={Deals}
        //                                         taskData={TaskSearchResult}
        //                                         mode='Both'
        //                                         getReturnData={(obj) => {
        //                                             getGroupByFields(obj.deals, groupBy.Deal)
        //                                             setDropDownDeals(obj.deals);
        //                                             setDropDownTasks(obj.tasks);
        //                                         }}
        //                                         dealColumns={filterItemsDeal}
        //                                         taskColumns={filterItems}
        //                                         allClient={ClientSearchResult}
        //                                         dealStatus={dealStatus}
        //                                         Deals={Deals}
        //                                         categorys={allCategory}
        //                                         pipelines={allPipelines}
        //                                         myTasks={getFIlterTask().my_task}
        //                                         clearFilter={clearFilter}
        //                                         users={users} status={taskLayoutDetail.status_details} open={taskFilterOpen} handleClose={() => setTaskFilterOpen(false)} />

        //                                 </div>

        //                             </div>




        //                         </div>
        //                     </div>
        //                     <div className="col-sm-5 ">
        //                         <div className="d-flex float-right mb-3 mr-4">
        //                             {getTaskFilterSetting()}
        //                         </div>
        //                         <div className="d-flex float-right mb-3 mr-3">


        //                             <div
        //                                 className="add-task-btn cursor-pointer ml-2"

        //                             >
        //                                 <span onClick={() => {
        //                                     setOpenTaskModel(true);
        //                                     setTaskAction('Create');
        //                                 }}
        //                                     className="nycText">Add Task</span>
        //                                 <FontAwesomeIcon
        //                                     id="addtask-popup"
        //                                     className=""
        //                                     icon={faCaretDown}
        //                                 />
        //                             </div>
        //                         </div>
        //                         <UncontrolledTooltip
        //                             placement="top"
        //                             target="assigned-to-me"
        //                         >
        //                             only show task assigned to me
        //                         </UncontrolledTooltip>

        //                         <div style={{ height: '24px', marginTop: 4 }} className="d-flex float-right mb-3 mr-3">
        //                             <span id="assigned-to-me" style={assignedMeVisible ? { border: '0.5px solid gray', padding: '0px 3px', color: '#0194f7' } : { border: '0.5px solid gray', padding: '0px 3px' }} className="btn btn-sm" onClick={() => {
        //                                 setAssignedMeVisible(!assignedMeVisible)
        //                             }}
        //                             ><FontAwesomeIcon icon={faUser} /> Me</span>
        //                         </div>

        //                     </div>

        //                     {!taskFilterSetting.ViewSeparateTask ?
        //                         <div style={{ maxHeight: "calc(100vh - 250px)" }} className="project-deals-height">
        //                             {dealGroupBy.map((group, groupIdx) => {
        //                                 return <div className="mb-2">
        //                                     {SelectedDeal == null ? (
        //                                         (
        //                                             <>
        //                                                 <h5 className="deal-num d-flex align-items-center mb-2">
        //                                                     <span
        //                                                         id={"toggle-collapse" + group.replace(/[- )(]/g, '')}
        //                                                         // onMouseLeave={() => toggleDealCollapse(deal._id)}
        //                                                         // onMouseEnter={() => toggleDealCollapse(deal._id)}
        //                                                         onClick={() => toggleDealCollapse(group)}
        //                                                     >
        //                                                         <i className="deal-toggle-down">
        //                                                             <FontAwesomeIcon
        //                                                                 style={{ marginBottom: '2px' }}
        //                                                                 id={"collapse-" + group.replace(/[- )(]/g, '')}
        //                                                                 className="task-toggle-down-alt"
        //                                                                 icon={getDealCollapse(group) ? faChevronDown : faChevronRight}
        //                                                             />
        //                                                         </i>
        //                                                     </span>

        //                                                     <span
        //                                                         id={"toggle-collapse" + group.replace(/[- )(]/g, '')}
        //                                                     // onMouseLeave={() => toggleDealCollapse(deal._id)}
        //                                                     // onMouseEnter={() => toggleDealCollapse(deal._id)}
        //                                                     // onClick={() => toggleDealCollapse(group)}
        //                                                     >
        //                                                         {getDealNameWithClientName(group)}
        //                                                     </span>
        //                                                     <AntdPopover
        //                                                         placement="right"
        //                                                         trigger="click"
        //                                                         // isOpen={employeeNotePopup}
        //                                                         content={<div>
        //                                                             <div className="row task-filter-hovr">
        //                                                                 <div className="col-sm-9">
        //                                                                     <a
        //                                                                         onClick={() => {

        //                                                                         }}
        //                                                                     >Expand All Groups
        //                                                                     </a>
        //                                                                 </div>
        //                                                                 <div className="col-sm-3">
        //                                                                     <Switch
        //                                                                     checked={groupExpandFlag.filter(one=>group == one.DealId).length==groupExpandFlag.filter(one=>one.isOpen&&(group == one.DealId)).length}
        //                                                                         onChange={(checked, event) => {
        //                                                                             let arr = [...groupExpandFlag]
        //                                                                             arr.forEach(one => {
        //                                                                                 if (group == one.DealId) {
        //                                                                                     one.isOpen = checked
        //                                                                                 }
        //                                                                             })
        //                                                                             setGroupExpandFlag(arr)
        //                                                                         }}

        //                                                                         size="small"
        //                                                                         // checked={taskFilterSetting.TaskLocation}
        //                                                                     />
        //                                                                 </div>
        //                                                             </div>
        //                                                             <hr className="hrcls"></hr>
        //                                                                 <div className="row task-filter-hovr">
        //                                                                     <div className="col-sm-9">
        //                                                                         <a
        //                                                                             onClick={() => {

        //                                                                             }}
        //                                                                         >Expand All Tasks
        //                                                                         </a>
        //                                                                     </div>
        //                                                                     <div className="col-sm-3">
        //                                                                         <Switch
        //                                                                         // checked={groupExpandFlag.filter(one=>group == one.DealId).length==groupExpandFlag.filter(one=>one.isOpen&&(group == one.DealId)).length}
        //                                                                             onChange={(checked, event) => {
        //                                                                                 const obj={...taskFilterSetting};
        //                                                                                 if(checked){
        //                                                                                     obj.ExpandDealTask=group;
        //                                                                                 }else{
        //                                                                                     obj.ExpandDealTask="";
        //                                                                                 }
                                                                                       
        //                                                                                 setTaskFilterSetting(obj);
        //                                                                             }}

        //                                                                             size="small"
        //                                                                             // checked={taskFilterSetting.TaskLocation}
        //                                                                         />
        //                                                                     </div>
        //                                                                 </div>
        //                                                             {/* <a onClick={() => {
        //                                                                 let arr = [...groupExpandFlag]
        //                                                                 arr.forEach(one => {
        //                                                                     if (group == one.DealId) {
        //                                                                         one.isOpen = true
        //                                                                     }
        //                                                                 })
        //                                                                 setGroupExpandFlag(arr)
        //                                                             }}>Expand All Groups</a><br></br>
        //                                                             <hr></hr>
        //                                                             <a onClick={() => {
        //                                                                 let arr = [...groupExpandFlag]
        //                                                                 arr.forEach(one => {
        //                                                                     if (group == one.DealId) {
        //                                                                         one.isOpen = false
        //                                                                     }
        //                                                                 })
        //                                                                 setGroupExpandFlag(arr)
        //                                                             }}>Collapse All Groups</a> */}
        //                                                         </div>}
        //                                                     >
        //                                                         <FontAwesomeIcon
        //                                                             id="tasksetting_popup"
        //                                                             className="deal_setting_icon"
        //                                                             // className="task-settings-icon rotate-ellipses"
        //                                                             icon={faEllipsisH}
        //                                                         />
        //                                                     </AntdPopover>
        //                                                 </h5>
        //                                                 <UncontrolledCollapse
        //                                                     toggler={"toggle-collapse" + group.replace(/[- )(]/g, '')}
        //                                                     isOpen={getDealCollapse(group)}
        //                                                 >
        //                                                     {getDealByGroupName(
        //                                                         group,
        //                                                         groupIdx
        //                                                     )}



        //                                                 </UncontrolledCollapse>
        //                                             </>
        //                                         )

        //                                     ) : (
        //                                         <>
        //                                             <h5 className="deal-num mb-2">
        //                                                 <span>{SelectedDeal.ProjectNumber}</span>
        //                                             </h5>
        //                                             {getTaskCardbyId(
        //                                                 SelectedDeal._id,
        //                                                 DropDownTasks.filter((task) => task.isCheck == true)
        //                                             )}

        //                                         </>
        //                                     )}
        //                                 </div>
        //                             })}

        //                         </div>





        //                         :
        //                         // <></>
        //                         React.cloneElement(<Tasks />, {
        //                             history:history,
        //                             taskLayout:taskLayout,
        //                             setTimerPrevTimeTrack:props.setTimerPrevTimeTrack,
        //                             startstopClockTimeTrack: props.startstopClockTimeTrack,
        //                             setTimerTimeTrack: props.setTimerTimeTrack,
        //                             timerTimeTrack: props.timerTimeTrack,
        //                             timerObj: props.timerObj,
        //                             timeTrackFlag:props.timeTrackFlag,
        //                             initializeTimeTrack:props.initializeTimeTrack,
        //                             getAllTasks:getAllTasks,
        //                             taskLocation: props.taskLoaction,
        //                             dependencyList: dependencyList,
        //                             AllClients: AllClients,
        //                             dealStatus: dealStatus,
        //                             history: history,
        //                             dealGroupExpand: groupExpandFlag,
        //                             Deals: Deals,
        //                             taskFilterSetting: taskFilterSetting,
        //                             taskLocationShow: taskLocationShow,
        //                             showParentNames: showParentNames,
        //                             columns: addTaskColumns,
        //                             taskSearchSetting: taskSearchSetting,
        //                             setOpenTaskModel: setOpenTaskModel,

        //                             isView: props.isVisible,
        //                             isAccess: props.isAccess,
        //                             selectedRole: props.selectedRole,
        //                             handleOnChangeAddColumn: handleOnChangeAddColumn,
        //                             dealObj: {},
        //                             DropDownDeals: DropDownDeals,
        //                             allUsers: allUsers,
        //                             allJobs: allJobs,
        //                             groupBy: groupBy.Task,
        //                             allTasks: getFIlterTask().task_to_pass,
        //                             myTasks: getFIlterTask().my_task,
        //                             setAllTasks: setAllTasks,
        //                             id: 'tasklist',
        //                             dealsId: "none",
        //                             groupExpandFlag: groupExpandFlag
        //                         }, null)



        //                     }
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        //     {renderTaskModal()}
        //     {taskDeleteWarningModal()}
        // </div>
    );
};

export default TaskListWithDeal;
