import { resolve } from "mathjs";
import TaskService from "../../common/util/TaskService";
import { reject } from "lodash";

export function modifyEdgesAndNodes(edges, nodes, filteredEdgeIds) {
    if (edges && nodes && filteredEdgeIds) {
        // Step 1: Create a Set of node IDs and edge keys (source-target pairs) from filtered edges
        const unvisitedNodeIds = new Set();
        const unvisitedEdgeKeys = new Set();
        let filteredEdges = edges.filter(ed => filteredEdgeIds.includes(ed.id));
        // Add the nodes and edges from filteredEdges to the sets
        filteredEdges.forEach(edge => {
            unvisitedNodeIds.add(edge.source);
            unvisitedNodeIds.add(edge.target);
            unvisitedEdgeKeys.add(`${edge.source}-${edge.target}`);
        });

        // Step 2: Modify the nodes
        nodes.forEach(node => {
            if (unvisitedNodeIds.has(node.id)) {
                node.data["disable"] = false; // Mark as not disabled if in the filtered edges
            } else {
                node.data["disable"] = true; // Otherwise, mark as disabled
            }
        });

        // Step 3: Modify the edges
        edges.forEach(edge => {
            const edgeKey = `${edge.source}-${edge.target}`;
            if (edge.data) {
                if (unvisitedEdgeKeys.has(edgeKey)) {
                    edge.data["disable"] = false; // Mark as not disabled if in the filtered edges
                } else {
                    edge.data["disable"] = true; // Otherwise, mark as disabled
                }
            }
        });

        return { updatedNodes: nodes, updatedEdges: edges };
    } else {
        return;
    }

}
//     // Step 1: Create an adjacency list and an edge map from the edges array

//     const adjacencyList = {};
//     const edgeMap = {};

//     edgesArray.forEach((e) => {
//         const { source, target } = e;
//         if (!adjacencyList[source]) {
//             adjacencyList[source] = [];
//         }
//         adjacencyList[source].push(target);
//         edgeMap[`${source}-${target}`] = e; // Map source-target pair to the edge object
//     });

//     // Step 2: Define variables to track visited nodes, the path, and edges in a cycle
//     const visited = new Set();
//     const path = [];
//     let cycleEdges = [];

//     // Step 3: Helper function to perform DFS for cycle detection
//     const dfs = (currentNode, targetNode) => {
//         if (currentNode === targetNode) {
//             // We've found a cycle, add edges to cycleEdges array
//             path.forEach((edge) => cycleEdges.push(edge));
//             return true;
//         }

//         visited.add(currentNode);

//         if (adjacencyList[currentNode]) {
//             for (let neighbor of adjacencyList[currentNode]) {
//                 if (!visited.has(neighbor)) {
//                     // Add the edge to the path
//                     const edgeKey = `${currentNode}-${neighbor}`;
//                     path.push(edgeMap[edgeKey]);

//                     // Perform DFS on the neighbor
//                     const result = dfs(neighbor, targetNode);
//                     if (result) {
//                         return true; // Stop searching if a cycle is found
//                     }

//                     // Backtrack if no cycle found
//                     path.pop();
//                 }
//             }
//         }

//         return false;
//     };

//     // Step 4: Check if the current edge is a "reject" edge
//     if (currentEdge.sourceHandle === "reject") {
//         // Perform DFS to find if the edge is part of a cycle
//         visited.clear(); // Clear previous visited nodes
//         dfs(currentEdge.target, currentEdge.source);

//         // Step 5: If a cycle is detected, remove the "visited" flag from edges in the cycle
//         if (cycleEdges.length > 0) {
//             edgesArray = edgesArray.map((e) => {
//                 if (cycleEdges.includes(e)) {
//                     let temp=e.data
//                     const { visited, ...rest } = temp;
//                     temp.data={ ...rest };
//                     return temp;
//                 }
//                 return e;
//             });
//         }
//     } else {
//         // Step 6: If the edge is not rejected, mark it as visited
//         let obj;
//         edgesArray = edgesArray.map((e) => {
//             if (e.id === currentEdge.id) {
//                 let temp=e.data
//                 const { visited, ...rest } = temp;
//               //   updateEdgeVisitedStatus()
//                 obj={ ...e,data:{...rest,visited:true}}
//                 return obj;
//             }
//             return e;
//         });
//         if(obj){
//             edgesArray = updateEdgeVisitedStatus(obj,edgesArray)
//         }
//     }

//     // Step 7: Return the modified edges array
//     return edgesArray;
//   };
export const processEdgeConnections = (currentEdge, edgesArray) => {
    // Step 1: Create an adjacency list and an edge map from the edges array

    const adjacencyList = {};
    const edgeMap = {};

    edgesArray.forEach((e) => {
        const { source, target } = e;
        if (!adjacencyList[source]) {
            adjacencyList[source] = [];
        }
        adjacencyList[source].push(target);
        edgeMap[`${source}-${target}`] = e; // Map source-target pair to the edge object
    });

    // Step 2: Define variables to track visited nodes, the path, and edges in a cycle
    const visited = new Set();
    const path = [];
    let cycleEdges = [];

    // Step 3: Helper function to perform DFS for cycle detection
    const dfs = (currentNode, targetNode) => {
        if (currentNode === targetNode) {
            // We've found a cycle, add edges to cycleEdges array
            path.forEach((edge) => cycleEdges.push(edge));
            return true;
        }

        visited.add(currentNode);

        if (adjacencyList[currentNode]) {
            for (let neighbor of adjacencyList[currentNode]) {
                if (!visited.has(neighbor)) {
                    // Add the edge to the path
                    const edgeKey = `${currentNode}-${neighbor}`;
                    path.push(edgeMap[edgeKey]);

                    // Perform DFS on the neighbor
                    const result = dfs(neighbor, targetNode);
                    if (result) {
                        return true; // Stop searching if a cycle is found
                    }

                    // Backtrack if no cycle found
                    path.pop();
                }
            }
        }

        return false;
    };

    // Step 4: Check if the current edge is a "reject" edge
    if (currentEdge.sourceHandle === "reject") {
        // Perform DFS to find if the edge is part of a cycle
        visited.clear(); // Clear previous visited nodes
        dfs(currentEdge.target, currentEdge.source);

        // Step 5: If a cycle is detected, remove the "visited" flag from edges in the cycle
        if (cycleEdges.length > 0) {
            edgesArray = edgesArray.map((e) => {
                if (cycleEdges.includes(e)) {
                    let temp = e.data
                    delete temp["visited"];
                    // const { visited, ...rest } = temp;
                    e.data = { ...temp };
                    return e;
                }
                return e;
            });
        }
    } else {
        // Step 6: If the edge is not rejected, mark it as visited
        let obj;
        edgesArray = edgesArray.map((e) => {
            if (e.id === currentEdge.id) {
                let temp = e.data
                // const { visited, ...rest } = temp;
                //   updateEdgeVisitedStatus()
                obj = { ...e, data: { ...temp, visited: true } }
                return obj;
            }
            return e;
        });
        //   if(obj){
        //       edgesArray = updateEdgeVisitedStatus(obj,edgesArray)
        //   }
    }

    // Step 7: Return the modified edges array
    return edgesArray;
};
export function updateNodeVisitedStatus(nodes, edges) {
    // Create a map to track visited status for each node based on its edges
    const visitedStatusMap = new Map();

    // Initialize all nodes with visited = true (default assumption if no incoming edges)
    nodes.forEach(node => {
        visitedStatusMap.set(node.id, true);
    });

    // Iterate over the edges to track visited status for each target node
    edges.forEach(edge => {
        const { target, data: { visited: edgeVisited } = {} } = edge;

        // If any edge has visited = false, mark the target node as false in the map
        if (!edgeVisited) {
            visitedStatusMap.set(target, false);
        }
    });

    // Iterate through the nodes and modify their visited status based on the map
    return nodes.map(node => {
        const { id, data } = node;
        const nodeVisited = visitedStatusMap.get(id);

        // If all incoming edges have visited=true (or no edges), add visited=true to the node
        if (nodeVisited) {
            return {
                ...node,
                data: {
                    ...data,
                    visited: true
                }
            };
        } else {
            // If not all incoming edges have visited=true, remove the visited key from the node
            const { visited, ...remainingData } = data;
            return {
                ...node,
                data: remainingData
            };
        }
    });
}
export const getDefaultValues = (type) => {
    switch (type) {
        case "DATE":
            return new Date();
            break;
        case "NUMBER":
            return 0;
            break;
        case "TEXT":
            return "";
            break;
        default:
            break;
    }
}
function mapKeysInArrayAsync(arr, keysFrom, keysTo) {
      if (keysFrom.length !== keysTo.length) {
        reject(new Error("keysFrom and keysTo arrays must have the same length"));
        return;
      }
  
      const result = arr.map((obj) => {
        let newObj = {};
        keysFrom.forEach((key, index) => {
          if (key in obj) {
            newObj[keysTo[index]] = obj[key];
            delete newObj[key];
          }
        });
  
        return newObj;
      });
  
      return result;
  }
export const getStatusOptions = () => {
    return new Promise((resolve,reject)=>{
        TaskService.GetDealStatus()
        .then((res) => {
            let arr = mapKeysInArrayAsync(res,["id","actual_value","display_value","colour_code"],["id","value","name","color"])
            resolve(arr);
        }).catch((err) => {
            console.log(err);
        });
    })
}
export const getPickListOptions = (key,state) => {
    switch (key) {
        case "Project_Status": 
            return state.DealStatusOptions;
            break;
        default:
            break;
    }
}