export const secretCode = (payload) => {
    return {
        type : 'SECRET_CODE',
        payload : payload,
    }
}
//// context ///
export const accessData = (payload) => {
    return {
        type : 'ACCESS_DATA',
        payload : payload,
    }
}

export const tokenData = (payload) => {
    return {
        type : 'TOKEN',
        payload : payload,
    }
}

export const EditDealData = (payload) => {
    return {
        type : 'EDIT_DEAL_DATA',
        payload : payload,
    }
}
export const CreateDealData = (payload) => {
    return {
        type : 'CREATE_DEAL_DATA',
        payload : payload,
    }
}
export const NycApiData = (payload) => {
    return {
        type : 'NYC_API_DATA',
        payload : payload,
    }
}
export const AllDeals = (payload) => {
    return {
        type : 'ALL_DEALS',
        payload : payload,
    }
}
export const AllAccounts = (payload) => {
    return {
        type : 'ALL_ACCOUNTS',
        payload : payload,
    }
}
export const AllNotificationsForChat = (payload) => {
    return {
        type : 'ALL_CHAT_NOTIFICATIONS',
        payload : payload,
    }
}
export const ClientServices = (payload) => {
    return {
        type : 'CLIENT_SERVICES',
        payload : payload,
    }
}
export const ClientFullServices = (payload) => {
    return {
        type : 'CLIENT_FULL_SERVICES',
        payload : payload,
    }
}
export const SaveClickedForClient = (payload) => {
    return {
        type : 'SAVE_CLIENT_CLICK',
        payload : payload,
    }
}
export const ClientLayoutChanged = (payload) => {
    return {
        type : 'CLIENT_LAYOUT_CLICK',
        payload : payload,
    }
}
export const ServicesForClient = (payload) => {
    return {
        type : 'SERVICES_FOR_CLIENT',
        payload : payload,
    }
}
export const SelectedIdsForTimeClock = (payload) => {
    return {
        type : 'SELECTED_IDS_FOR_TIMECLOCK',
        payload : payload,
    }
}
export const CacheDataOfTimesheet = (payload) => {
    return {
        type : 'CASH_DATA_OF_TIMESHEET',
        payload : payload,
    }
}
export const timesheetEntryObject = (payload) => {
    return {
        type : 'TIMESHEET_ENTRY_OBJECT',
        payload : payload,
    }
}
export const ActionsOfTimeClock = (payload) => {
    return {
        type : 'ACTIONS_OF_TIMECLOCK',
        payload : payload,
    }
}
export const CustomeRefreshButton = (payload) => {
    return {
        type : 'CUSTOME_REFRESH_BTN',
        payload : payload,
    }
}
export const addTimeSheetClicked = (payload) => {
    return {
        type : 'ADD_TIMESHEET',
        payload : payload,
    }
}
export const hrActiveKey = (payload) => {
    return {
        type : 'HR_ACTIVE_KEY',
        payload : payload,
    }
}
export const isDeleteEnable = (payload) => {
    return {
        type : 'IS_DELETE_ENABLE',
        payload : payload,
    }
}
export const billingTerms = (payload) => {
    return {
        type : 'BILLING_TERMS',
        payload : payload,
    }
}

export const isServiceAddersClickable = (payload) => {
    return {
        type : 'SERVICE_ADDERS_CLICKABLE',
        payload : payload,
    }
}
export const GlobalRefresh = (payload) => {
    return {
        type : 'GlobalRefresh',
        payload : payload,
    }
}
export const Socket = (payload) => {
    return {
        type : 'Socket',
        payload : payload,
    }
}
export const CacheReminderData = (payload) => {
    return {
        type : 'CacheReminderData',
        payload : payload,
    }
}
export const setIsReminderPopupOpen = (payload) => {
    return {
        type : 'setIsReminderPopupOpen',
        payload : payload,
    }
}
export const setReminderData = (payload) => {
    return {
        type : 'setReminderData',
        payload : payload,
    }
}
export const forwardObject = (payload) => {
    return {
        type : 'forwardObject',
        payload : payload,
    }
}
export const refershModule = (payload) => {
    return {
        type : 'refershModule',
        payload : payload,
    }
}
export const taskActiveKey = (payload) => {
    return {
        type : 'TASK_ACTIVE_KEY',
        payload : payload,
    }
}
export const isTaskModalClosed = (payload) => {
    return {
        type : 'IS_TASK_MODAL_CLOSED',
        payload : payload,
    }
}
export const DealStatusOptions = (payload) => {
    return {
        type : 'DealStatusOptions',
        payload : payload,
    }
}
export const isPipelineModalClosed = (payload) => {
    return {
        type : 'IS_PIPELINE_MODAL_CLOSED',
        payload : payload,
    }
}
export const AllPipelines = (payload) => {
    return {
        type : 'ALL_PIPELINES',
        payload : payload,
    }
}
export const EnableFlow = (payload) => {
    return {
        type : 'EnableFlow',
        payload : payload,
    }
}