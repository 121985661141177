import { faBell, faClock } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDropzone } from "react-dropzone";
import {
    Col, Container, Row, Form, UncontrolledDropdown, Input,
    Popover,
    PopoverBody,
    PopoverHeader,
    UncontrolledPopover,
    UncontrolledTooltip,
    UncontrolledCollapse,
    Tooltip,
    Card, CardTitle, CardText,
    Collapse, FormGroup
} from "reactstrap";
import { HexColorPicker } from "react-colorful";
import { Popover as AntdPopover, DatePicker as AntdDatePicker, Button, Modal, Card as AntdCard } from 'antd';
import { Dropdown } from 'react-bootstrap';
import React, { Component, useEffect, useState } from 'react';
import { faCalendarAlt, faChevronDown, faChevronLeft, faChevronRight, faPaperclip, faTimes } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/js/bootstrap';
import HttpService from '../../services/http.service';
import './Reminder.css';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import 'antd/dist/reset.css';
import remIcoCutom from '../../assets/images/reminder_icon_custom.svg'
import remIcoClickup from '../../assets/images/reminder_icon_clickup.svg'

import swal from 'sweetalert';
import UserService from '../../common/util/UserService';
import NotificationAPI from '../../common/util/NotificationAPI';
import TaskService from '../../common/util/TaskService';

import CalendarAPI from '../../common/util/CalendarAPI';
import { toIsoString } from '../../common/DateTime';
import Reccuring from '../Reccuring/reccuring';


const Reminder = ({ show, onClose, users, id, task }) => {
    const userinfo = JSON.parse(localStorage.getItem('userinfo'))
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
    const [selectedUser, setSelectedUser] = useState([{ role: localStorage.getItem('Role'), photo: localStorage.getItem('ProfilePhoto'), email: userinfo?.email_id, name: userinfo?.first_name + " " + userinfo?.last_name, zuid: localStorage.getItem('ZUID') }])
    const [allUsers, setAllUsers] = useState([]);
    const [action, setAction] = useState('create');
    const [cacheAllUsers, setAllCacheUser] = useState([]);
    const [editOccurence, setEditOccurence] = useState("");
    // const [eventData, setEventData] = useState({ color: '#8cbf40' });
    const [fromDate, setFromDate] = useState(null);
    const [tillDate, setTillDate] = useState(null);
    const [fromTime, setFromTime] = useState(null);
    const [tillTime, setTillTime] = useState(null);
    const [errors, setErrors] = useState({});
    const [isRepeatEvent, setIsRepeatEvent] = useState(false);
    const [loading, setLoading] = useState(false);
    const [eventData, setEventData] = useState({ color: '#F26F56', action: ['notification'] })
    const [remindData, setRemindData] = useState({ color: '#8cbf40', Reminder: "No Title", ReminderRadio: 'SpecificDate', Time: "00:00", NumberOfDays: 0, action: ['notification'] });
    const [notifyOption, setNotifyOptions] = useState([
        { value: 'duedate', 'label': 'On Due Date' },
        { value: 10, 'label': '10 minute before' },
        { value: 60, 'label': '1 hour before' },
        { value: 'custom', 'label': 'Custom..' }
    ]);

    useEffect(() => {
        if (users.length > 0) {
            setAllUsers(users);
            setAllCacheUser(users);

        }

    }, [users])
    useEffect(() => {
        if ((selectedUser.length > 0) && (users.length > 0)) {
            let usersArr = users.filter((user) => {
                if (selectedUser.filter(one => one.userId == user.UserId).length == 0) {
                    return user;
                }
            })
            setAllUsers(usersArr);
        } else {
            setAllUsers(users);
        }

    }, [selectedUser, users])
    useEffect(() => {
        //getServiceRequestedData(props.dealObj);
        //  if(acceptedFiles!==null){
        if (acceptedFiles.length > 0) {
            handleFileAttachments(acceptedFiles);
        }

        //  }
    }, [acceptedFiles]);

    useEffect(() => {
        if (show) {


            setErrors({})

            setEventData({ color: '#F26F56', action: ['notification'] })
            setIsRepeatEvent(false);
            setFromDate(dayjs().format('YYYY-MM-DD'));
            setFromTime(dayjs().format('HH:mm'));
            setTillDate(dayjs().format('YYYY-MM-DD'));
            let date = new Date(Date.now());
            date.setMinutes(date.getMinutes() + 15);
            setTillTime(dayjs(date).format('HH:mm'))
            setDueDateTime(new Date());
            setRemindData({ Reminder: "No Title", ReminderRadio: 'SpecificDate', Time: "00:00", NumberOfDays: 0, action: ['notification'] });
        }
    }, [show])

    const [attachements, setAttachements] = useState([])
    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };
    const handleFileAttachments = async (fileList) => {
        let tempFileCollection = [];
        let v = fileList.length;
        let index = 0;
        while (index < v) {
            let fileName = fileList[index].name;
            let base64 = await convertToBase64(fileList[index]);
            tempFileCollection.push({
                fileName: fileName,
                file: base64,
            });
            index = index + 1;
        }
        setAttachements(tempFileCollection);
        // attachFilesData.AttachedFiles = tempFileCollection;
        // addUpdateTaskApi(attachFilesData);
    };

    const [closeAssigneePopup, setCloseAssigneePopup] = useState(false);

    const removeAssignee = (user) => {
        let selected = [...selectedUser]
        selected = selected.filter(obj => obj.zuid !== user.zuid);
        setSelectedUser(selected);
    }

    const handleConvertRule = (rrule) => {
        let rruleStr = ''
        if (rrule.Repeat == "Daily") {
            rruleStr = `FREQ=DAILY;INTERVAL=${rrule.DailyIntervalCount};`
            if (rrule.DailyInterval == 'WeekDay') {
                rruleStr = rruleStr + `BYDAY=MO,TU,WE,TH,FR;`;
            }
            if (rrule.EndBy == 'ByDate') {
                rruleStr = rruleStr + `UNTIL=${dayjs(rrule.EndByValue).format('YYYYMMDD')};`
            }
            if (rrule.EndBy == 'ByCount') {
                rruleStr = rruleStr + `COUNT=${rrule.EndByValue};`
            }
        }
        if (rrule.Repeat == "Weekly") {
            rruleStr = `FREQ=WEEKLY;INTERVAL=${rrule.DailyIntervalCount};`
            rruleStr = rruleStr + `BYDAY=${convertWeeklyDayTOShortDay(rrule.WeeklyDay)};`;
            if (rrule.EndBy == 'ByDate') {
                rruleStr = rruleStr + `UNTIL=${dayjs(rrule.EndByValue).format('YYYYMMDD')};`
            }
            if (rrule.EndBy == 'ByCount') {
                rruleStr = rruleStr + `COUNT=${rrule.EndByValue};`
            }
        }
        if (rrule.Repeat == "Monthly") {
            rruleStr = `FREQ=MONTHLY;INTERVAL=${rrule.DailyIntervalCount};`
            // rruleStr = rruleStr + `BYDAY=${convertWeeklyDayTOShortDay(rrule.WeeklyDay)}`;
            if (rrule.OnMonth == 'OnDay') {
                rruleStr = rruleStr + `BYMONTHDAY=${rrule.OnDay};`
            }
            if (rrule.OnMonth == 'OnThe') {
                rruleStr = rruleStr + `BYDAY=${convertWeeklyDayTOShortDay(rrule.MonthDay)};BYSETPOS=${rrule.MonthStep};`;
            }

            if (rrule.EndBy == 'ByDate') {
                rruleStr = rruleStr + `UNTIL=${dayjs(rrule.EndByValue).format('YYYYMMDD')};`
            }
            if (rrule.EndBy == 'ByCount') {
                rruleStr = rruleStr + `COUNT=${rrule.EndByValue};`
            }
        }
        if (rrule.Repeat == "Yearly") {
            rruleStr = `FREQ=YEARLY;INTERVAL=1;BYMONTH=${rrule.WhichMonth};`

            if (rrule.OnMonth == 'OnDay') {
                rruleStr = rruleStr + `BYMONTHDAY=${rrule.OnDay};`
            }
            if (rrule.OnMonth == 'OnThe') {
                rruleStr = rruleStr + `BYDAY=${convertWeeklyDayTOShortDay(rrule.MonthDay)};BYSETPOS=${rrule.MonthStep};`;
            }

            if (rrule.EndBy == 'ByDate') {
                rruleStr = rruleStr + `UNTIL=${dayjs(rrule.EndByValue).format('YYYYMMDD')};`
            }
            if (rrule.EndBy == 'ByCount') {
                rruleStr = rruleStr + `COUNT=${rrule.EndByValue};`
            }
        }
        return rruleStr;
    }

    const fetchPhotoFromLocalstorage = (photo, name, user) => {
        if (photo === null || photo === "") {
            return (
                <div className='user-img-icon common_reminder_box'>
                    {selectedUser.length > 1 ? <FontAwesomeIcon icon={faTimes} onClick={() => removeAssignee(user)} className='remove-user' /> : <></>}
                    <div className='no-image-user'
                        type="button" id="reminder-pop-user" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                        onClick={() => {
                            setDueDatePopUp(false);
                            setNotifyMePopUp(false);
                            setCloseAssigneePopup(!closeAssigneePopup);
                        }}
                    >{name ? name.charAt(0) : ''}</div>
                </div>
            )
        }
        else {
            return (
                <div className='user-img-icon common_reminder_box'>
                    {selectedUser.length > 1 ? <FontAwesomeIcon icon={faTimes} onClick={() => removeAssignee(user)} className='remove-user' /> : <></>}
                    <img src={photo} className="user-img btn btn-secondary dropdown-toggle" type="button" id="reminder-pop-user" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                        onClick={() => {
                            setDueDatePopUp(false);
                            setNotifyMePopUp(false);
                            setCloseAssigneePopup(!closeAssigneePopup);
                        }}
                    >
                    </img>
                </div>


            )
        }
    }
    const [assignDrop, setAssignDrop] = useState(false);
    const assignDropdownToggle = () => setAssignDrop(!assignDrop);
    const showPhotoOfUser = (user) => {

        if (user.zuid === null || user.zuid === "") {
            return (
                <div
                    className='no-image-user'
                >{user.first_name ? user.first_name.charAt(0) : ''}</div>)
        }
        else {
            return (<img src={UserService.GetUserPhotoUrl(user.zuid)} className="user-ico" />)
        }
    }


    const [dueDateTime, setDueDateTime] = useState(new Date(Date.now()));
    const onSaveReminder = async (rrule) => {
        let errros = { ...errors };
        if (!eventData.title) {
            errros['title'] = 'please enter title'
            setErrors(errros);
            return

        } else {
            setErrors({});
        }
        let userInfo = JSON.parse(localStorage.getItem('userinfo'));
        let dateFromArr = fromDate.split('-');
        let from = new Date(dateFromArr[0], parseInt(dateFromArr[1]) - 1, dateFromArr[2], 0, 0, 0)
        if (fromTime) {
            let fromTime1 = fromTime.split(':');
            from.setHours(parseInt(fromTime1[0]), parseInt(fromTime1[1]))
        }
        let dateTillArr = tillDate.split('-');
        let till = new Date(dateTillArr[0], parseInt(dateTillArr[1]) - 1, dateTillArr[2], 0, 0, 0)
        if (tillTime) {
            let tillTime1 = tillTime.split(':');
            till.setHours(parseInt(tillTime1[0]), parseInt(tillTime1[1]))
        }

        let typeTitle = "#$srsolarreminder$#"
        if (eventData.action) {
            if (eventData.action.length == 0) {
                typeTitle = `#$type:email$#`;
            } else {
                eventData.action.forEach((one) => {
                    typeTitle = typeTitle + `#$type:${one}$#`;
                })
            }
        } else {
            typeTitle = `#$type:email$#`;
        }
        let obj = { title: eventData.title, description: typeTitle, color: eventData.color }
        if (id == "Task") {
            obj = { title: obj.title, description: typeTitle + `#$taskid:${task.id_string}$#` }
        }

        if (rrule && isRepeatEvent) {
            rrule = handleConvertRule(rrule)
            obj.rrule = rrule;
        }
        from.setMinutes(from.getMinutes() + 5);
        if (from >= new Date()) {
            obj.dateandtime = { timezone: localStorage.getItem('TIMEZONE'), start: toIsoString(from), end: toIsoString(till) };
            obj.isrep = isRepeatEvent;
            obj.isallday = eventData.isallday ? true : false;
            obj.sendnotification = true
            obj.reminders = [{ action: 'email', minutes: '-5' }]
            let calender = await CalendarAPI.GetAllCalendars();
            CalendarAPI.CreateEvent(calender.uid, { eventdata: obj })
                .then((result) => {
                    console.log(result);
                    onClose()

                    CreateReminder()

                })
                .catch((err) => {
                    console.log(err);
                })
        } else {
            swal('Reminder can not be set in past time', { dangerMode: true });
        }

    }


    const onSaveRRRuleReminder = async (rrule, eventDate) => {
        let errros = { ...errors };
        if (!eventData.title) {
            errros['title'] = 'please enter title'
            setErrors(errros);
            return
        } else {
            setErrors({});
        }
        let userInfo = JSON.parse(localStorage.getItem('userinfo'));
        let dateFromArr = fromDate.split('-');
        let from = new Date(dateFromArr[0], parseInt(dateFromArr[1]) - 1, dateFromArr[2], 0, 0, 0)
        if (fromTime) {
            let fromTime1 = fromTime.split(':');
            from.setHours(parseInt(fromTime1[0]), parseInt(fromTime1[1]))
        }
        let dateTillArr = tillDate.split('-');
        let till = new Date(dateTillArr[0], parseInt(dateTillArr[1]) - 1, dateTillArr[2], 0, 0, 0)
        if (tillTime) {
            let tillTime1 = tillTime.split(':');
            till.setHours(parseInt(tillTime1[0]), parseInt(tillTime1[1]))
        }
        let typeTitle = "#$reminder$#"
        if (remindData.action) {
            if (remindData.action.length == 0) {
                typeTitle = typeTitle + `#$type:email$#`;
            } else {
                remindData.action.forEach((one) => {
                    typeTitle = typeTitle + `#$type:${one}$#`;
                })
            }
        } else {
            typeTitle = `#$type:email$#`;
        }
        let obj = { rrule: rrule, title: eventData.title, description: typeTitle }
        if (id == "Task") {
            obj = { rrule: rrule, title: obj.title, description: typeTitle + `#$taskid:${task.id_string}$#` }
        }
        from.setMinutes(from.getMinutes() + 5);
        if (from >= new Date()) {
            obj.dateandtime = { timezone: localStorage.getItem('TIMEZONE'), start: toIsoString(from), end: toIsoString(till) };
            obj.isrep = isRepeatEvent;
            obj.isallday = eventData.isallday ? true : false;
            obj.sendnotification = true
            obj.reminders = [{ action: 'email', minutes: '-5' }]
            let calender = await CalendarAPI.GetAllCalendars();
            CalendarAPI.CreateEvent(calender.uid, { eventdata: obj })
                .then((result) => {
                    console.log(result);
                    onClose()
                    setDueDateTime(new Date())
                    CreateReminder()

                })
                .catch((err) => {
                    console.log(err);
                })
        } else {
            swal('Reminder can not be set in past time', { dangerMode: true });
        }

    }

    const CreateReminder = () => {
        let userinfo = JSON.parse(localStorage.getItem('userinfo'));
        let notifyDate = new Date(dueDateTime);
        let obj = { DateTime: new Date(dueDateTime), Is_Notify_Me: false, Is_Sent: false }
        if (notifyObj) {
            obj.Is_Notify_Me = true;
            if (notifyObj.value == 'custom') {
                if (notifyType == 'minute') {
                    notifyDate.setMinutes(notifyDate.getMinutes() - parseInt(notifyMinutes));
                }
                else if (notifyType == 'hour') {
                    notifyDate.setHours(notifyDate.getHours() - parseInt(notifyMinutes));
                }
                else {
                    notifyDate.getDate(notifyDate.getDate() - parseInt(notifyMinutes));
                }
            }
            else if (notifyObj.value == 'duedate') {

            } else {
                notifyDate.setMinutes(notifyDate.getMinutes() - parseInt(notifyObj.value));
            }
        }
        obj.Is_Notify_Me_DateTime = new Date(notifyDate);
        obj.Creator_Id = localStorage.getItem('ZUID');
        obj.Creator_Email = userinfo?.email_id;
        obj.Text = remindData ? remindData.Reminder : '';
        obj.Creator_Name = userinfo?.first_name + " " + userinfo?.last_name;
        let doArr = []
        selectedUser.forEach((one) => {
            let oneObj = { ...obj };
            oneObj.Delegate_Email = one.email;
            oneObj.Delegate_Name = one.name;
            doArr.push(NotificationAPI.CreateReminder(oneObj))
        })
        Promise.all(doArr)
            .then((result) => {
                console.log(result)
                let userinfo = JSON.parse(localStorage.getItem('userinfo'));
                onClose()
                setDueDateTime(new Date())
                setRemindData({ ReminderRadio: 'SpecificDate', Time: "00:00", NumberOfDays: 0 })
                setSelectedUser([{ email: userinfo?.email_id, role: localStorage.getItem('Role'), photo: localStorage.getItem('ProfilePhoto'), name: userinfo?.first_name + " " + userinfo?.last_name, zuid: localStorage.getItem('ZUID') }])
            })
            .catch((err) => {
                swal(`${err}`);
            })

    }
    // const onSaveReminder = () => {
    //     let userinfo = JSON.parse(localStorage.getItem('userinfo'));
    //     let notifyDate = new Date(dueDateTime);
    //     let obj = { DateTime: new Date(dueDateTime), Is_Notify_Me: false, Is_Sent: false }
    //     if (notifyObj) {
    //         obj.Is_Notify_Me = true;
    //         if (notifyObj.value == 'custom') {
    //             if (notifyType == 'minute') {
    //                 notifyDate.setMinutes(notifyDate.getMinutes() - parseInt(notifyMinutes));
    //             }
    //             else if (notifyType == 'hour') {
    //                 notifyDate.setHours(notifyDate.getHours() - parseInt(notifyMinutes));
    //             }
    //             else {
    //                 notifyDate.getDate(notifyDate.getDate() - parseInt(notifyMinutes));
    //             }
    //         }
    //         else if (notifyObj.value == 'duedate') {

    //         } else {
    //             notifyDate.setMinutes(notifyDate.getMinutes() - parseInt(notifyObj.value));
    //         }
    //     }
    //     obj.Is_Notify_Me_DateTime = new Date(notifyDate);
    //     obj.Creator_Id = localStorage.getItem('ZUID');
    //     obj.Creator_Email = userinfo?.email_id;
    //     obj.Text = remindData ? remindData.Reminder : '';
    //     obj.Creator_Name = userinfo?.first_name + " " + userinfo?.last_name;
    //     let doArr = []
    //     selectedUser.forEach((one) => {
    //         let oneObj = { ...obj };
    //         oneObj.Delegate_Email = one.email;
    //         oneObj.Delegate_Name = one.name;
    //         doArr.push(NotificationAPI.CreateReminder(oneObj))
    //     })
    //     Promise.all(doArr)
    //         .then((result) => {
    //             console.log(result)
    //             let userinfo = JSON.parse(localStorage.getItem('userinfo'));
    //             onClose()
    //             setRemindData(null)
    //             setSelectedUser([{ email: userinfo?.email_id, role: localStorage.getItem('Role'), photo: localStorage.getItem('ProfilePhoto'), name: userinfo?.first_name + " " + userinfo?.last_name, zuid: localStorage.getItem('ZUID') }])
    //         })
    //         .catch((err) => {
    //             swal(`${err}`);
    //         })

    // }

    const handleSearchUser = (e) => {
        let user = [...cacheAllUsers];
        if (e.target.value !== '') {
            user = user.filter((obj) => {
                let name = obj.FirstName + " " + obj.LastName;
                if (name.toLowerCase().startsWith(e.target.value.toLowerCase())) {
                    return obj
                }
            })
        }
        setAllUsers(user)

    }

    // const handleOnChange = (e) => {
    //     const data = { ...remindData };
    //     if (e.target.name == "NotificationType") {
    //         if (data.action) {
    //             if (e.target.checked) {
    //                 data.action.push(e.target.value);
    //             } else {
    //                 data.action = data.action.filter(one => one !== e.target.value);
    //             }

    //         } else {
    //             if (e.target.checked) {
    //                 data.action = [e.target.value];
    //             }
    //         }
    //     } else {
    //         data[e.target.name] = e.target.value;
    //     }

    //     // if(data.ReminderType=="on same day"){
    //     //   if(e.target.name=='Time'){
    //     //     let date=new Date(dueDateTime);
    //     //     let time=e.target.value;
    //     //     time=time.split(':');
    //     //     date.setHours(parseInt(time[0]),(parseInt(time[1])+5));
    //     //   }
    //     // }
    //     setRemindData(data);

    // }


    const handleOnChange = (e) => {
        const obj = { ...eventData };
        const error = { ...errors };
        if (e.currentTarget.name == 'isallday') {
            obj[e.currentTarget.name] = e.currentTarget.value == 'true' ? true : false;
        } else {
            obj[e.currentTarget.name] = e.currentTarget.value;
        }


        if (e.currentTarget.name == 'title') {
            if (!obj.title) {
                error['title'] = 'please enter title'
                setErrors(error);

            } else {
                setErrors({});
            }
        }

        if (e.target.name == "NotificationType") {
            if (obj.action) {
                if (e.currentTarget.checked) {
                    obj.action.push(e.currentTarget.value);
                } else {
                    obj.action = obj.action.filter(one => one !== e.currentTarget.value);
                }

            } else {
                if (e.currentTarget.checked) {
                    obj.action = [e.currentTarget.value];
                }
            }
        }
        setEventData(obj)
    }



    function range(start, end) {
        const result = [];
        for (let i = start; i < end; i++) {
            result.push(i);
        }
        return result;
    }

    function disabledDate(current) {
        // Can not select days before today and today
        return current && current < dayjs().add(-1, 'day').endOf('day');
    }

    function disabledDateTime(current) {
        return {
            disabledHours: () => range(0, 24).splice(4, 20),
            disabledMinutes: () => range(30, 60),
            disabledSeconds: () => [55, 56],
        };
    }
    const [dueDatePopUp, setDueDatePopUp] = useState(false);
    const dueDatePopupToggle = () => setDueDatePopUp(!dueDatePopUp);

    const [notifyMePopUp, setNotifyMePopUp] = useState(false);
    const notifyMeToggle = () => setNotifyMePopUp(!notifyMePopUp);

    const [customPopup, setCustomPopup] = useState(false);
    const customPopUpToggle = () => setNotifyMePopUp(!notifyMePopUp);
    const [notifyObj, setNotifyObj] = useState(null);
    const handleClickNotify = (value) => {
        if (value.value == 'custom') {
            setNotifyMePopUp(false);
            setCustomPopup(true);
            setNotifyObj(value);
        } else {
            setNotifyMePopUp(false);
            setCustomPopup(false);
            setNotifyObj(value);
        }
    }

    const [notifyMinutes, setNotifyMinutes] = useState(30);
    const [notifyType, setNotifyType] = useState('minute');

    function sumOfTotalHrs(preTimeTotalTime, newTime) {
        if (newTime == "N/A") {
            newTime = "00:00";
        }
        let hour = 0;
        let minute = 0;
        let splitTime1 = preTimeTotalTime.split(":");
        let splitTime2 = newTime.split(":");
        hour = parseInt(splitTime1[0]) + parseInt(splitTime2[0]);
        minute = parseInt(splitTime1[1]) + parseInt(splitTime2[1]);
        hour = hour + minute / 60;
        minute = minute % 60;
        hour = Math.trunc(hour);
        minute = Math.trunc(minute);
        hour = (hour < 10 ? "0" : "") + hour;
        minute = (minute < 10 ? "0" : "") + minute;
        let total = hour + ":" + minute; //+ ":" + second;
        console.log("total : " + total);
        return total;
    }

    const eventCreateCalender = () => {
        return <>
            <div className="form-group">
                <div className="Event_color_picker">
                    <div className="color-picker"><span style={eventData.color ? { backgroundColor: eventData.color } : { backgroundColor: '#8cbf40' }} className="circle"></span></div>
                    <input name="title" value={eventData.title ? eventData.title : ''} onChange={handleOnChange} placeholder="Reminder Title" type="text" className="form-control form-control-sm" />

                </div>
                {errors.title ? <p style={{ paddingLeft: '55px', paddingTop: '5px' }} className="small text-danger">{errors.title}</p> : <></>}
            </div>
            <div className="row">
                <div className="col-1">
                    <FontAwesomeIcon icon={faCalendarAlt} />
                </div>
                <div className="col-2">
                    <input name="FromDate" value={fromDate ? fromDate : ''} onChange={(e) => {
                        setFromDate(e.currentTarget.value)
                        setTillDate(e.currentTarget.value);
                    }} type="date" className="form-control form-control-sm" />
                </div>
                {!eventData.isallday ? <div className="col-2">
                    <input name="FromTime" value={fromTime ? fromTime : ''} onChange={(e) => {
                        let till = sumOfTotalHrs(e.currentTarget.value, '00:15');
                        setTillTime(till)
                        setFromTime(e.currentTarget.value)
                    }} type="time" className="form-control form-control-sm" />
                </div> : <></>}
                <div className="col-sm-1">

                </div>
                <div className="col-2">
                    <input style={{ display: 'none' }} name="TillDate" value={tillDate ? tillDate : ''} onChange={(e) => setTillDate(e.currentTarget.value)} type="date" className="form-control form-control-sm" />
                </div>
                {!eventData.isallday ? <div className="col-2">
                    <input style={{ display: 'none' }} disabled name="TillTime" value={tillTime ? tillTime : ''} onChange={(e) => setTillTime(e.currentTarget.value)} type="time" className="form-control form-control-sm" />
                </div> : <></>}
                {/* <div className="col-2">
                    <input name="isallday" checked={eventData.isallday || (eventData.isallday == 'true') ? true : false} value={eventData.isallday ? false : true} onChange={handleOnChange} type="checkbox" /> All Day
                </div> */}

            </div>
            <div className="row mt-2">
                <div className="col-1">
                    {/* <FontAwesomeIcon icon={faCalendarAlt} /> */}
                </div>
                <div className="col-2">
                    <input name="isallday" checked={eventData.isallday || (eventData.isallday == 'true') ? true : false} value={eventData.isallday ? false : true} onChange={handleOnChange} type="checkbox" /> All Day
                </div>
                {(editOccurence == 'all_occurence') || (action == 'create') ? <div className="col-2">
                    <input onChange={(e) => setIsRepeatEvent(e.currentTarget.checked)} checked={isRepeatEvent} type="checkbox" /> Repeat
                </div> : <></>}
            </div>
            <div className='row mt-2'>
                <div className='col-1'>
                    <FontAwesomeIcon icon={faBell} />
                </div>
                <div className='col-7'>
                    <div className='d-flex reminder-notify-type'>
                        <div><input checked={eventData.action && eventData.action.filter(one => one == "notification").length > 0 ? true : false} onChange={handleOnChange} value={"notification"} name="NotificationType" type='checkbox' /> <span>Notification</span></div>
                        <div className='ml-2'><input checked={eventData.action && eventData.action.filter(one => one == "email").length > 0 ? true : false} onChange={handleOnChange} value={"email"} name="NotificationType" type='checkbox' /> <span>Email</span></div>
                        <div className='ml-2'><input checked={eventData.action && eventData.action.filter(one => one == "popup").length > 0 ? true : false} onChange={handleOnChange} value={"popup"} name="NotificationType" type='checkbox' /> <span>Popup</span></div>
                    </div>
                </div>

            </div>

            {isRepeatEvent ? <div className="row mt-2">
                <div className="col-1">
                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M0 224c0 17.7 14.3 32 32 32s32-14.3 32-32c0-53 43-96 96-96H320v32c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l64-64c12.5-12.5 12.5-32.8 0-45.3l-64-64c-9.2-9.2-22.9-11.9-34.9-6.9S320 19.1 320 32V64H160C71.6 64 0 135.6 0 224zm512 64c0-17.7-14.3-32-32-32s-32 14.3-32 32c0 53-43 96-96 96H192V352c0-12.9-7.8-24.6-19.8-29.6s-25.7-2.2-34.9 6.9l-64 64c-12.5 12.5-12.5 32.8 0 45.3l64 64c9.2 9.2 22.9 11.9 34.9 6.9s19.8-16.6 19.8-29.6V448H352c88.4 0 160-71.6 160-160z" /></svg>
                </div>
                <div className="col-10 reminderModal-footer">
                    <Reccuring action={action} obj={eventData} onSave={(rule) => {
                        onSaveReminder(rule)
                    }} onClose={() => onClose()} />
                </div>
            </div> : <></>}

        </>
    }




    const eventEntryPopUp = () => {
        let userInfo = JSON.parse(localStorage.getItem('userinfo'));
        return <Modal
            closable={false}
            className="event_ctm_mod"
            style={{ width: '780px' }}
            open={show}
            title={<>
                <div className="d-flex justify-content-between pr-2" style={{ paddingTop: '2px' }}>
                    <div className='d-flex justify-content-between w-100'>
                        Reminder Event
                        <FontAwesomeIcon style={{ fontSize: '16px' }} className="rem-event-close-icon" onClick={() => { setAction('create'); onClose() }} size="2x" icon={faTimes} />
                    </div>
                </div>
            </>}
            onOk={() => { setAction('create'); onClose() }}
            // onCancel={() => { setAction('create'); onClose() }}

            footer={(action == 'edit' || action == 'create') && (!isRepeatEvent) ? [

                <Button key="submit" type="primary" loading={loading} onClick={() => {
                    if (fromDate && tillDate && tillTime && fromTime) {
                        onSaveReminder()
                    } else {
                        swal('please select from and till date time', { dangerMode: true });
                    }
                }}>
                    Save
                </Button>,
                <Button


                    onClick={() => { setAction("create"); onClose() }}
                >
                    Cancel
                </Button>,
            ] : null}
        >
            <>

                {action == "edit" || action == 'create' ? eventCreateCalender() : <></>}

            </>
        </Modal>

    }


    // const reminderModal = () => {
    //     return <Modal
    //         isOpen={show}
    //         backdrop={'static'}
    //         size='md'
    //         className=" reminder-modal"
    //     >

    //         <ModalBody className='p-0'>
    //             <div className='reminderModalContent p-4'>
    //                 <div>
    //                     <img  className='reminder-icon-cust' style={{backgroundColor:'#ffc107',color:'blue',borderRadius:'25%',alignItems:'center'}} src={remIcoCutom}/>
    //                     <h5 className="mb-4">Reminder</h5>
    //                 </div>
    //                 <FormGroup className='m-0'>
    //                     <input type='text' className='form-control form-control-sm ' name='Reminder' onChange={handleOnChange} placeholder='Type some text here....' />
    //                 </FormGroup>
    //             </div>
    //             <div className='d-flex reminder-date'>

    //                 <div className='ml-2'><input checked={remindData && (remindData.ReminderRadio == "SpecificDate")} onChange={handleOnChange} value={"SpecificDate"} name="ReminderRadio" type='radio' /> <span>On Specific Date</span></div>
    //                 <div className='ml-2'><input checked={remindData && (remindData.ReminderRadio == "Reccuring")} onChange={handleOnChange} value={"Reccuring"} name="ReminderRadio" type='radio' /> <span>Set Reccuring</span></div>
    //                 {id == 'Task' ? <div className='ml-2'><input checked={remindData && (remindData.ReminderRadio == "DueDate")} onChange={handleOnChange} value={"DueDate"} name="ReminderRadio" type='radio' /> <span>Based on Due Date</span></div> : <></>}
    //             </div>
    //             <div className='d-flex reminder-notify-type'>
    //                 <div className='ml-2'> <span style={{ fontWeight: 600 }}>Notification Type : </span></div>
    //                 <div className='ml-2'><input checked={remindData.action && remindData.action.filter(one => one == "notification").length > 0 ? true : false} onChange={handleOnChange} value={"notification"} name="NotificationType" type='checkbox' /> <span>Notification</span></div>
    //                 <div className='ml-2'><input checked={remindData.action && remindData.action.filter(one => one == "email").length > 0 ? true : false} onChange={handleOnChange} value={"email"} name="NotificationType" type='checkbox' /> <span>Email</span></div>
    //                 <div className='ml-2'><input checked={remindData.action && remindData.action.filter(one => one == "popup").length > 0 ? true : false} onChange={handleOnChange} value={"popup"} name="NotificationType" type='checkbox' /> <span>Popup</span></div>

    //             </div>

    //             <div className='reminderModal-footer m-3 rounded border-0'>
    //                 {remindData.ReminderRadio == "Reccuring" ? Recurring() :

    //                     remindData.ReminderRadio == "DueDate" ? basedOnDueDate() : onSpecificDate()

    //                 }
    //                 {/* <div><input name="ReminderRadio" type='radio' /> <span>On Specific Date</span></div>
    //                 <div><input name="ReminderRadio" type='radio' /> <span>Reccuring</span></div>
    //                 <div><input name="ReminderRadio" type='radio' /> <span>Based on Due Date</span></div> */}
    //             </div>
    //         </ModalBody>
    //     </Modal>
    // }

    const basedOnDueDate = () => {
        return <Row>

            <Col sm={12}>
                <div className="form-group form-group-sm text-left">
                    <label>Reminder Type</label>
                    <select value={remindData ? remindData.ReminderType : ""} onChange={handleOnChange} name='ReminderType' className="form-control form-control-sm" >
                        <option value={'daily'}>Daily</option>
                        <option value={'on same day'}>On Due Date</option>
                        <option value={"before due date"}>Day before due date</option>
                    </select>
                </div>
            </Col>
            <Col sm={12}>
                {remindData.ReminderType == "before due date" ? <>
                    <div className="form-group form-group-sm text-left">
                        <label>Time</label>
                        <input onChange={handleOnChange} value={remindData.Time} name='Time' type="time" className="form-control form-control-sm" />
                    </div>
                    <div className="form-group form-group-sm text-left">
                        <label>Number Of Days</label>
                        <input onChange={handleOnChange} value={remindData.NumberOfDays} name='NumberOfDays' type="number" className="form-control form-control-sm" />
                    </div></> : <div className="form-group form-group-sm text-left">
                    <label>Time</label>
                    <input onChange={handleOnChange} value={remindData.Time} name='Time' type="time" className="form-control form-control-sm" />
                </div>}

            </Col>
            <Col sm={12}>
                <div className='row'>
                    <div className='col'>
                        <div className="form-group form-group-sm text-left">
                            <label>Notify Users</label>
                            <div className='reminder-footerIcon reminder-assignees'>
                                {selectedUser.map((user) => {
                                    return fetchPhotoFromLocalstorage(user.photo, user.name, user)
                                })}
                                <UncontrolledPopover
                                    isOpen={closeAssigneePopup}
                                    // toggle={closeAssigneePopup}
                                    trigger="legacy"
                                    placement="bottom"
                                    target="reminder-pop-user"
                                    className='reminderUserDropdown'
                                >
                                    <PopoverHeader>
                                        <input type='text' name='SearchActivityUser' onChange={handleSearchUser} placeholder='search user...' className='form-control form-control-sm' />
                                    </PopoverHeader>
                                    <PopoverBody
                                        className='activity-filters'
                                    >
                                        {
                                            users.map((user) => {
                                                return <div onClick={() => {
                                                    const selected = [...selectedUser];
                                                    if (selected.filter(one => one.zuid == user.zuid).length == 0) {
                                                        let obj = {}
                                                        obj.photo = user.ProfilePhoto;
                                                        obj.name = user.full_name;
                                                        obj.zuid = user.zuid;
                                                        obj.role = "";
                                                        obj.email = user.email;
                                                        selected.push(obj);
                                                        setSelectedUser(selected);
                                                    }

                                                }} className='row m-0 cursor-pointer'>
                                                    <div
                                                        className='row align-items-center my-1'
                                                        onClick={() => setCloseAssigneePopup(!closeAssigneePopup)}
                                                    >
                                                        <div className='col-sm-4'>
                                                            {showPhotoOfUser(user)}
                                                        </div>
                                                        <div className='col-sm-8 filterName call-log-contacts-text'>
                                                            {user.first_name + " " + user.last_name}
                                                        </div>
                                                    </div>
                                                    {/* {user._id == 'all' ? <hr style={{ width: '88%' }}></hr> : <></>} */}
                                                </div>
                                            })
                                        }

                                    </PopoverBody>
                                </UncontrolledPopover>
                            </div>
                        </div>
                    </div>
                    <div className='col'>
                        <div className='reminder-duedate-btn'>
                            <button onClick={onSaveReminder} className='btn btn-sm btn-primary mr-2'>Save</button>
                            <button onClick={() => {
                                let userinfo = JSON.parse(localStorage.getItem('userinfo'));
                                onClose()
                                setDueDateTime(new Date())
                                setSelectedUser([{ role: localStorage.getItem('Role'), photo: localStorage.getItem('ProfilePhoto'), name: userinfo?.first_name + " " + userinfo?.last_name, email: userinfo?.email_id, zuid: localStorage.getItem('ZUID') }])

                            }} className='btn btn-sm btn-secondary'>Cancel</button>
                        </div>
                    </div>

                </div>


            </Col>

        </Row>
    }

    const convertWeeklyDayTOShortDay = (days) => {
        if (days) {
            days = days.split(',');
            days = days.filter(day => day);
            days.forEach((day, idx) => {
                days[idx] = day.slice(0, 2).toUpperCase();
            })
            days = days.join(',');
        }
        return days;
    }

    const handleSetRepeatRule = (rrule) => {
        let rruleStr = ''
        if (rrule.Repeat == "Daily") {
            rruleStr = `FREQ=DAILY;INTERVAL=${rrule.DailyIntervalCount};`
            if (rrule.DailyInterval == 'WeekDay') {
                rruleStr = rruleStr + `BYDAY=MO,TU,WE,TH,FR;`;
            } else {
                if (rrule.EndBy == 'ByDate') {
                    rruleStr = rruleStr + `UNTIL=${dayjs(new Date(rrule.EndByValue)).format('YYYYMMDDTHHMMSS')};`
                }
                if (rrule.EndBy == 'ByCount') {
                    rruleStr = rruleStr + `COUNT=${rrule.EndByValue};`
                }
            }


        }
        if (rrule.Repeat == "Weekly") {
            rruleStr = `FREQ=WEEKLY;INTERVAL=${rrule.DailyIntervalCount};`
            rruleStr = rruleStr + `BYDAY=${convertWeeklyDayTOShortDay(rrule.WeeklyDay)};`;
            if (rrule.EndBy == 'ByDate') {
                rruleStr = rruleStr + `UNTIL=${dayjs(new Date(rrule.EndByValue)).format('YYYYMMDDTHHMMSS')};`
            }
            if (rrule.EndBy == 'ByCount') {
                rruleStr = rruleStr + `COUNT=${rrule.EndByValue};`
            }
        }
        if (rrule.Repeat == "Monthly") {
            rruleStr = `FREQ=MONTHLY;INTERVAL=${rrule.DailyIntervalCount};`
            // rruleStr = rruleStr + `BYDAY=${convertWeeklyDayTOShortDay(rrule.WeeklyDay)}`;
            if (rrule.OnMonth == 'OnDay') {
                rruleStr = rruleStr + `BYMONTHDAY=${rrule.OnDay};`
            }
            if (rrule.OnMonth == 'OnThe') {
                rruleStr = rruleStr + `BYDAY=${convertWeeklyDayTOShortDay(rrule.MonthDay)};BYSETPOS=${rrule.MonthStep};`;
            }

            if (rrule.EndBy == 'ByDate') {
                rruleStr = rruleStr + `UNTIL=${dayjs(new Date(rrule.EndByValue)).format('YYYYMMDDTHHMMSS')};`
            }
            if (rrule.EndBy == 'ByCount') {
                rruleStr = rruleStr + `COUNT=${rrule.EndByValue};`
            }
        }
        if (rrule.Repeat == "Yearly") {
            rruleStr = `FREQ=YEARLY;INTERVAL=1;BYMONTH=${rrule.WhichMonth};`

            if (rrule.OnMonth == 'OnDay') {
                rruleStr = rruleStr + `BYMONTHDAY=${rrule.OnDay};`
            }
            if (rrule.OnMonth == 'OnThe') {
                rruleStr = rruleStr + `BYDAY=${convertWeeklyDayTOShortDay(rrule.MonthDay)};BYSETPOS=${rrule.MonthStep};`;
            }

            if (rrule.EndBy == 'ByDate') {
                rruleStr = rruleStr + `UNTIL=${dayjs(new Date(rrule.EndByValue)).format('YYYYMMDDTHHMMSS')};`
            }
            if (rrule.EndBy == 'ByCount') {
                rruleStr = rruleStr + `COUNT=${rrule.EndByValue};`
            }
        }
        onSaveRRRuleReminder(rruleStr, rrule.EventDate)
            .then((result) => {
                console.log(result);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const Recurring = () => {
        // return <></>
        return <Reccuring users={users} id='Reminder' onSave={handleSetRepeatRule} onClose={() => {
            onClose()
            setDueDateTime(new Date())
            setRemindData({ Reminder: "No Title", ReminderRadio: 'SpecificDate', Time: "00:00", NumberOfDays: 0, action: ['notification'] });
        }} />
    }

    const onSpecificDate = () => {
        return <>
            <div className='d-flex align-items-center'>
                <div className='reminder-footerIcon reminder-assignees'>
                    {selectedUser.map((user) => {
                        return fetchPhotoFromLocalstorage(user.photo, user.name, user)
                    })}
                    <UncontrolledPopover
                        isOpen={closeAssigneePopup}
                        // toggle={closeAssigneePopup}
                        trigger="legacy"
                        placement="bottom"
                        target="reminder-pop-user"
                        className='reminderUserDropdown'
                    >
                        <PopoverHeader>
                            <input type='text' name='SearchActivityUser' onChange={handleSearchUser} placeholder='search user...' className='form-control form-control-sm' />
                        </PopoverHeader>
                        <PopoverBody
                            className='activity-filters'
                        >
                            {
                                users && users.map((user) => {
                                    return <div onClick={() => {
                                        const selected = [...selectedUser];
                                        if (selectedUser.filter(usr => usr.zuid == user.zuid).length == 0) {
                                            let obj = {}
                                            obj.photo = UserService.GetUserPhotoUrl(user.zuid);
                                            obj.name = user.full_name;
                                            obj.zuid = user.zuid;
                                            obj.role = user.Role;
                                            obj.email = user.email;
                                            selected.push(obj);
                                            setSelectedUser(selected);
                                        }

                                    }} className='row m-0 cursor-pointer'>
                                        <div
                                            className='row align-items-center my-1'
                                            onClick={() => setCloseAssigneePopup(!closeAssigneePopup)}
                                        >
                                            <div className='col-sm-4'>
                                                {showPhotoOfUser(user)}
                                            </div>
                                            <div className='col-sm-8 filterName call-log-contacts-text'>
                                                {user.full_name}
                                            </div>
                                        </div>
                                        {/* {user._id == 'all' ? <hr style={{ width: '88%' }}></hr> : <></>} */}
                                    </div>
                                })
                            }

                        </PopoverBody>
                    </UncontrolledPopover>
                </div>
                <div className='reminder-footerIcon d-flex align-items-center'>

                    {/* <FontAwesomeIcon id="reminder-due-date" onClick={() => {
                        setNotifyMePopUp(false);
                        setDueDatePopUp(true);
                        setCloseAssigneePopup(false);
                    }} icon={faClock} /> */}
                    <UncontrolledTooltip target={'datePicker-reminder-due-date'} placeholder='top'>
                        Due Date
                    </UncontrolledTooltip>
                    <div id='datePicker-reminder-due-date'>
                        <DatePicker
                            dis
                            // renderExtraFooter={() => <><button onClick={() => setDueDatePopUp(false)} className='mt-2 btn btn-sm btn-primary float-right'>Close</button> </>}
                            placeholder='Due Date'
                            format="YYYY-MM-DD HH:mm"
                            onChange={(date, dateString) => { setDueDateTime(date.$d) }}
                            value={dayjs(dueDateTime)}
                            disabledDate={disabledDate}

                            // disabledTime={true}
                            showTime={{ defaultValue: dayjs('00:00:00', 'HH:mm'), use12Hours: true }}
                        />
                    </div>
                    {/* <UncontrolledPopover
                        isOpen={dueDatePopUp}
                        // toggle={dueDatePopupToggle}
                        trigger="legacy"
                        placement="bottom"
                        target="reminder-due-date"
                        className="due-date-range"
                    >
                        <PopoverBody>

                        </PopoverBody>
                    </UncontrolledPopover> */}
                </div>
            </div>
            <div>
                <button onClick={onSaveReminder} className='btn btn-sm btn-primary mr-2'>Save</button>
                <button onClick={() => {
                    let userinfo = JSON.parse(localStorage.getItem('userinfo'));
                    onClose()
                    setSelectedUser([{ role: localStorage.getItem('Role'), photo: localStorage.getItem('ProfilePhoto'), name: userinfo?.first_name + " " + userinfo?.last_name, email: userinfo?.email_id, zuid: localStorage.getItem('ZUID') }])

                }} className='btn btn-sm btn-secondary'>Cancel</button>
            </div>
        </>
    }
    return (
        <>
            {eventEntryPopUp()}

        </>


    );
}

export default Reminder;