export function getNextStepOfFlow(nodes, edges, activeNodeId, isCompleted) {

    const edgesMap = edges.reduce((acc, edge) => {
        if (!acc[edge.source]) acc[edge.source] = [];
        acc[edge.source].push(edge);
        return acc;
    }, {});

    const activeNodeEdges = edgesMap[activeNodeId] || [];
    
    for (const edge of activeNodeEdges) {
        if (isCompleted && edge.sourceHandle === 'destination') {
            return edge.target;
        } else if (!isCompleted && edge.sourceHandle === 'reject') {
            return edge.target;
        }
    }
    return null;
}

export function convertDateFromString(dateStr) {
    // Parse the date string
    const date = new Date(dateStr);
    
    // Format the date and time
    const options = { 
        year: 'numeric', 
        month: '2-digit', 
        day: '2-digit', 
        hour: '2-digit', 
        minute: '2-digit', 
        hour12: true 
    };

    // Convert to MM-DD-YYYY HH:MM A format
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);

    return formattedDate;
}