import React, { useEffect, useState } from 'react'
import "./task_filter.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faTimes, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Button, Modal, Select, Popover, Switch, Space } from "antd";
import { DatePicker as AntdDatepicker } from "antd";
import AntdRangePicker from "../DateRangePicker";
import dayjs from "dayjs";
import CustumSelect from '../SelectWithSearch';
import CustomMultiSelect from '../custom_multiselect/custom_multiselect';
import _, { deburr, indexOf, isArray } from "lodash";
import dateTimeObject from "../../common/util/date";
import Draggable from 'react-draggable';
import ModuleService from '../util/ModuleService';
import TaskService from '../util/TaskService';
let filterDataResult = [];
export default function GlobalTaskFilter({
    setActiveFilterAndGetActiveFilters,
    setActiveFiltersOnColumnLevel,
    setappliedFiltersForView,
    preAppliedFiltersForView,
    setIsColFilDeleted,
    activeFiltersOnColumnLevel,
    module,
    cacheData,
    setRowData,
    Columns,
    rowData,
    dropdownFields,
    setFilterTrigger,
    FilterTrigger,
    setFilterData,
    setHeadersForFilter
}) {
    const types = {
        phone: "string",
        text: "string",
        website: "string",
        ownerlookup:"string",
        picklist:"string",
        lookup:"string",
        textarea:"string",
        integer:"number",
        bigint:"number",
        currency:"number",
        double:"number",
        datetime:"date",
        boolean:"boolean",
    }
    const [filters, setFilters] = useState([{ phase: "initial", logic: "AND", module: module }]);
    const [rangeCalCloseFlag, setRangeCalCloseFlag] = useState(false);
    const [open, setOpen] = useState(false);
    const [opendDropdowns, setOpendDropDowns] = useState("");
    const [errorMsg, setErrorMsg] = useState();
    const [selectedCond, setSelectedCond] = useState([]);
    const [addGroupVisibility, setAddGroupVisibility] = useState("none");
    const [date, setDate] = useState([]);
    const [dateRange, setDateRange] = useState([]);
    const [isInitialStateOfFilter, setIsInitialStateOfFilter] = useState(true);
    const [allClients, setAllClients] = useState([]);
    const [dealStatus, setDealStatus] = useState([]);
    const [modalPosition, setModalPosition] = useState({ x: 0, y: 35 });
    const handleDrag = (e, ui) => {
        if(e.target && e.target.closest(".ant-picker-dropdown")){
          e.stopPropagation();
          return
          }
        const { x, y } = ui;
        setModalPosition({ x, y });
      };
      useEffect(e=>{
        setModalPosition({ x: 0, y: 35 });
        getAllClients();
        TaskService.GetDealStatus()
        .then((res) => {
          setDealStatus(res);
        })
        .catch((err) => {
          console.log(err);
        });
      },[])
      
    const [filterItems, setFilterItems] = useState([
        { name: "Status", type: "string" },
        { name: "Task Name", type: "string" },
        { name: "Pipeline", type: "string" },
        { name: "Category", type: "string" },
        { name: "Due Date", type: "date" },
        { name: "Priority", type: "string" },
        { name: "Assignee", type: "string" },
        { name: "Supervisor", type: "string" },
        { name: "Milestone", type: "string" },
        { name: "Created By", type: "string" },
        { name: "Start Date", type: "date" },
    ]);
    // util methods
    const dateFilterArr = [
        { label: "Before Date", value: "isbefore" },
        { label: "Select A Date", value: "selectadate" },
        { label: "After Date", value: "isafter" },
        { label: "Date Range", value: "daterange" },
    ];
    const conditions = {
        "<": (x, y) => (parseFloat(y) < parseFloat(x) ? true : false),
        "<=": (x, y) => (parseFloat(y) <= parseFloat(x) ? true : false),
        ">": (x, y) => (parseFloat(y) > parseFloat(x) ? true : false),
        ">=": (x, y) => (parseFloat(y) >= parseFloat(x) ? true : false),
        "=": (x, y) => (parseFloat(y) == parseFloat(x) ? true : false),
        "!=": (x, y) => (parseFloat(y) !== parseFloat(x) ? true : false),
        between: (x, y) =>
            parseFloat(y?.from) < parseFloat(x) && parseFloat(y?.to) > parseFloat(x)
                ? true
                : false,
        "not between": (x, y) =>
            parseFloat(y?.from) > parseFloat(x) || parseFloat(y?.to) < parseFloat(x)
                ? true
                : false,
        "is empty": (x, y) =>
            y === "" || y === undefined || y === null ? true : false,
        "is not empty": (x, y) =>
            y !== "" && y !== undefined && y !== null ? true : false,
        is: (arrX, y) => (arrX?.includes(y) ? true : false),
        "isn't": (arrX, y) => (!arrX?.includes(y) ? true : false),
        isnt: (arrX, y) => (!arrX.includes(y) ? true : false),
        contains: (arrX, y) =>
            y && arrX && y?.toLowerCase().includes(arrX.toLowerCase()) ? true : false,
        "doesn't contains": (arrX, y) =>
            !y.toLowerCase()?.includes(arrX.toLowerCase()) ? true : false,
        "starts with": (arrX, y) =>
            y.toLowerCase().startsWith(arrX.toLowerCase()) ? true : false,
        "ends with": (arrX, y) =>
            y.toLowerCase().endsWith(arrX.toLowerCase()) ? true : false,
    };
    const setDatePickerValue = (list) => {
        setDate([...list]);
    };
    const getDatePickerValueOfGroup = (index, groupIdx) => {
        let d = date.find((t, i) => i == index);
        if (d) {
          let group = d?.Group[groupIdx];
          if (group && types[group.type] == "date" && group?.date && group?.date[0]) {
            return dayjs(new Date(group?.date[0]));
          } else {
            return null;
          }
        }
        return null;
      };
    const getDatePickerValue = (index) => {
        try {
            let d = date.find((t, i) => i == index);
            if (d && d?.date[0] && types[d.type] == "date") {
                return dayjs(new Date(d?.date[0]));
            } else {
                return null;
            }
        } catch (error) { }
    };
    const getDateRangePickerValue = (index, k, groupIdx, isGroup) => {
        let d = dateRange?.find((t, i) => i == index);
        if (isGroup) {
            let dg = d?.Group?.find((t, i) => i == groupIdx);
            if (dg && types[dg.type] == "date" && dg?.date) {
                let temp = dg.date?.slice(k);
                if (k == 0) {
                    temp = dg.date[0];
                } else {
                    temp = dg.date[dg.date.length - 1];
                }
                return new Date(temp);
            } else {
                return new Date();
            }
        } else {
            if (d && types[d.type] == "date" && d?.date) {
                let temp = d.date?.slice(k);
                if (k == 0) {
                    temp = d.date[0];
                } else {
                    temp = d.date[d.date.length - 1];
                }
                return new Date(temp);
            } else {
                return new Date();
            }
        }
    };
    const getAllClients = () => {
        ModuleService.GetModuleList("Accounts")
          .then((result) => {
            if (result) {
              setAllClients(result.data);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      };
    function invertColor(hex, bw) {
        if (hex.indexOf("#") === 0) {
            hex = hex.slice(1);
        }
        // convert 3-digit hex to 6-digits.
        if (hex.length === 3) {
            hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
        }
        if (hex.length !== 6) {
            throw new Error("Invalid HEX color.");
        }
        var r = parseInt(hex.slice(0, 2), 16),
            g = parseInt(hex.slice(2, 4), 16),
            b = parseInt(hex.slice(4, 6), 16);
        if (bw) {
            return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? "#000000" : "#FFFFFF";
        }
        // invert color components
        r = (255 - r).toString(16);
        g = (255 - g).toString(16);
        b = (255 - b).toString(16);
        // pad each with zeros and return
        return "#" + padZero(r) + padZero(g) + padZero(b);
    }
    function padZero(str, len) {
        len = len || 2;
        var zeros = new Array(len).join("0");
        return (zeros + str).slice(-len);
    }
    const removeTile = (id, filterIndex, groupIdx, e) => {
        e.stopPropagation();
        let list = [...filters];
        if (list[filterIndex] && list[filterIndex].Group) {
            list[filterIndex].Group[groupIdx].value = list[filterIndex].Group[
                groupIdx
            ].value?.filter((one) => one.id !== id);
            list[filterIndex].Group[groupIdx].phase = "completed";
            setFilters(list);
        } else {
            list[filterIndex].value = list[filterIndex].value?.filter(
                (one) => (one.id !== id && one.id != 0)
            );
            list[filterIndex].phase = "completed";
            setFilters(list);
        }
    };
    const compareDateFilterResult = (date, obj) => {
        if (date) {
            date = new Date(date);
            date = dayjs(new Date(date)).format("MM-DD-YYYY");
        }
        let retsult = false;
        if (
            obj.value == "Before Date" ||
            obj.value == "After Date" ||
            obj.value == "Select A Date" ||
            obj.value == "No Date"
        ) {
            if (obj.value == "Before Date") {
                if (date && new Date(date) < new Date(obj.date)) {
                    retsult = true;
                }
            } else if (obj.value == "After Date") {
                if (date && new Date(date) > new Date(obj.date)) {
                    retsult = true;
                }
            } else if (obj.value == "Select A Date") {
                let tempObjDate = dayjs(new Date(obj.date)).format("MM-DD-YYYY");
                if (date && date == tempObjDate) {
                    retsult = true;
                }
            } else if (obj.value == "No Date") {
                if (!date) {
                    return true;
                }
            }
        } else if (obj.value == "Date Range") {
            if (obj.date && obj.date.includes(date)) {
                retsult = true;
            }
        } else if (obj.value == "Today") {
            let today = dayjs(new Date()).format("MM-DD-YYYY");
            return today == date;
        } else if (obj.value == "Yestarday") {
            let today = dayjs(new Date()).format("MM-DD-YYYY");
            let temp = new Date(today);
            temp.setDate(temp.getDate() - 1);
            let yesterday = dayjs(temp).format("MM-DD-YYYY");
            return yesterday == date;
        } else if (obj.value == "Tomorrow") {
            let today = dayjs(new Date()).format("MM-DD-YYYY");
            let temp = new Date(today);
            temp.setDate(temp.getDate() + 1);
            let tomorrow = dayjs(temp).format("MM-DD-YYYY");
            return tomorrow == date;
        } else {
            let datesArr = dateTimeObject.GetDateArr(
                new Date(),
                new Date(),
                obj.value
            );
            if (new Date(date) >= datesArr[0] && new Date(date) <= datesArr[1]) {
                retsult = true;
            }
        }
        return retsult;
    };
    const skippMsgForInitial = (allFilters, clickFlag) => {
        if (clickFlag) {
          setIsInitialStateOfFilter(false);
          return;
        }
        if (allFilters.slice(-1).column_name) {
          if (allFilters.slice(-1).Group) {
            if (allFilters.slice(-1).Group.slice(-1).column_name) {
              setIsInitialStateOfFilter(false);
            } else {
              setIsInitialStateOfFilter(true);
            }
          } else {
            setIsInitialStateOfFilter(false);
          }
        } else {
          if (allFilters.slice(-1).Group) {
            if (allFilters.slice(-1).Group.slice(-1).column_name) {
              setIsInitialStateOfFilter(false);
            } else {
              setIsInitialStateOfFilter(true);
            }
          } else {
            setIsInitialStateOfFilter(true);
          }
        }
      };
    const shouldWeAddFilters = (allFilters, clickFlag) => {
        if (allFilters && allFilters.length > 0) {
          skippMsgForInitial(allFilters, clickFlag);
          let isIncompleteFilter = allFilters.find((m) => {
            if (m?.phase != "completed") {
              if (m?.Group?.length > 0) {
                let temp = m?.Group?.find((g) => g?.phase != "completed");
                if (temp) {
                  return true;
                } else {
                  return false;
                }
              } else {
                return true;
              }
            } else if (m?.phase == "completed") {
              if (m?.Group?.length > 0) {
                let temp = m?.Group?.find((g) => g?.phase != "completed");
                if (temp) {
                  return true;
                } else {
                  return false;
                }
              } else {
                return false;
              }
            }
          });
          if (isIncompleteFilter) {
            return false;
          } else {
            return true;
          }
        } else {
          return false;
        }
      };
    const getGroupConditionValidation = (conditionResult) => {
        let result = conditionResult[0]?.status;
        conditionResult.forEach((c, i) => {
            if (i != 0) {
                if (c?.condition == "OR") {
                    result = result || c?.status;
                } else if (c?.condition == "AND") {
                    result = result && c?.status;
                }
            }
        });
        return result;
    };
    const getListOfNumbersForTypeNumber = (one) => {
        const data = [...cacheData];
        let all = data.map((data,key) => {
            if (_.isArray(data[one.api_name])) {
                return { name: data[one.api_name], id: data[one.api_name] };
            }
            else if (_.isObject(data[one.api_name])) {
                if (data[one.api_name]) {
                    return { name: data[one.api_name].name, id: data[one.api_name].name };
                }
            } else if (data[one.api_name]) {
                return { name: data[one.api_name], id: data[one.api_name] };
            }
        });
        all = all?.filter((one) => one != undefined);
        return all;
    };

    const getLogicResultDealForDealModule = (data, one) => {
        try {
            let arr = [];
            if(one?.api_name=="Project_Status"){
                one.api_name='Stage'
            }
            if (one.value && one.column_name && types[one.type] !== "date") {
                if (one.value && _.isArray(one.value)) {
                    arr = one.value && one.value.map((one) => one.name);
                } else {
                    arr = one.value;
                }
            } else if (!one?.Group && one.condition != "is empty" && one.condition != "is not empty") {
                return compareDateFilterResult(data[one.api_name], one);
            }

            if (one?.Group && one?.Group?.filter((cond) => cond?.phase == "completed").length > 0) {
                let conditionalArray = one?.Group;
                conditionalArray = one?.Group?.filter(
                    (cond) => cond?.phase == "completed"
                );
                let groupValidationArray = [];
                conditionalArray?.forEach((el, i) => {
                    let conditionResult = {
                        status: false,
                        condition: "",
                    };
                    conditionResult.status = getLogicResultDealForDealModule(data, el);
                    conditionResult.condition = el?.logic;
                    groupValidationArray.push(conditionResult);
                });
                let resultOfGroup = getGroupConditionValidation(groupValidationArray);
                return resultOfGroup;
            }
            if (_.isArray(data[one.api_name])) {
                return conditions[one.condition](arr, data[one.api_name])
                    ? true
                    : false;
            } else if (_.isObject(data[one.api_name])) {
                return data[one.api_name] &&
                    conditions[one.condition](arr, data[one.api_name].name)
                    ? true
                    : false;
            } else if (
                data[one.api_name] ||
                data[one.api_name] == 0 ||
                one.condition == "is empty" ||
                one.condition == "is not empty"
            ) {
                if (types[one.type] == "date") {
                    return compareDateFilterResult(data[one.api_name], one);
                } else {
                    return conditions[one.condition](arr, data[one.api_name]?.includes("_")?data[one.api_name]?.split("_")[0]:data[one.api_name])
                        ? true
                        : false;
                }
            }
            if (
                (arr.length == 0 ||
                    _.isEmpty(one.condition) ||
                    _.isNull(one.condition)) &&
                !one?.Group
            ) {
                return true;
            }

        } catch (err) { }
    };
    const getFilterResult = (thisFilter) => {
        let conditionalArray = [...thisFilter];
        let tasks = [];
        let deals = [...cacheData];
        let dataToFilter = deals;
        let result = [];
        conditionalArray = conditionalArray?.filter(
            (one) => one?.phase == "completed" || one?.Group
        );
        if (conditionalArray.length == 1) {
            result = deals?.filter((one) =>
                getLogicResultDealForDealModule(one, conditionalArray[0])
            );
        } else {
            result = dataToFilter?.filter((data) => {
                let groupValidationArray = [];
                conditionalArray?.forEach((el, i) => {
                    let conditionResult = {
                        status: false,
                        condition: "",
                    };
                    conditionResult.status = getLogicResultDealForDealModule(data, el);
                    conditionResult.condition = el?.logic;
                    groupValidationArray.push(conditionResult);
                });
                let resultOfGroup = false;
                resultOfGroup = getGroupConditionValidation(groupValidationArray);
                return resultOfGroup;
            });
        }
        // getReturnData({ deals: result, tasks: [] });
        return result;
    };
    //util methods

    //filter conditions --
    const filterCondition = [
        {
            type: "number",
            conditions: [
                { label: "is", value: "is" },
                { label: "=", value: "=" },
                { label: "!=", value: "!=" },
                { label: "<", value: "<" },
                { label: "<=", value: "<=" },
                { label: ">", value: ">" },
                { label: ">=", value: ">=" },
                { label: "between", value: "between" },
                { label: "not between", value: "not between" },
                { label: "is empty", value: "is empty" },
                { label: "is not empty", value: "is not empty" },
            ],
        },

        {
            type: "string",
            conditions: [
                { label: "is", value: "is" },
                { label: "isn't", value: "isn't" },
                { label: "contains", value: "contains" },
                { label: "doesn't contains", value: "doesn't contains" },
                { label: "starts with", value: "starts with" },
                { label: "ends with", value: "ends with" },
                { label: "is empty", value: "is empty" },
                { label: "is not empty", value: "is not empty" },
                // { label: 'is set', value: 'is set' }, { label: "is not set", value: 'is not set' },
            ],
        },

        {
            type: "date",
            conditions: [
                { label: "is", value: "is" },
                { label: "isn't", value: "isnt" },
            ],
        },

        {
            type: "boolean",
            conditions: [
                { label: "is", value: "is" },
                { label: "isn't", value: "isnt" },
            ],
        },
    ];
    //filter conditions --
    //useEffects start --
    useEffect(e=>{
        setFilters([{ phase: "initial", logic: "AND", module: module }]);
      },[module])
    useEffect(() => {
        setFilterItems(Columns);
        if(filters?.length==0){
            setFilters([{ phase: "initial", module: module }]);
        }
    }, [Columns]);
    useEffect(
        (e) => {
          if (preAppliedFiltersForView != undefined) {
            // setfiltersmode(preAppliedFiltersForView);
            setFilters(preAppliedFiltersForView);
            // setDate(preAppliedFiltersForView);
          }
          //  getFilterResultDeal(preAppliedFiltersForView);
        },
        [preAppliedFiltersForView]
      );
    useEffect(() => {
        setappliedFiltersForView(filters)
        if (filters?.filter((one) => one?.phase == "completed" || one?.Group).length > 0) {
            let Result = [];
               
            Result = getFilterResult(filters);
            setHeadersForFilter(Columns)
            setRowData(Result)
            if(module == "Request"){
                setFilterData(Result)
            }
        } else {
            if (_.isEmpty([])) {
                setRowData(cacheData)
            }
        }
    }, [filters,cacheData]);
    //useEffects end --
    //switch section -- end


    // console.log("activeFiltersOnColumnLevel", activeFiltersOnColumnLevel);
    // console.log("filters", filters);

    const getWitch = (index, obj, type) => {
        return (
            <Space direction="vertical">
                <Switch
                    checked={obj.logic == "AND"}
                    checkedChildren={"AND"}
                    unCheckedChildren={"OR"}
                    onChange={(checked, event) => {
                        let list = [...filters];
                        list[index].logic = checked ? "AND" : "OR";
                        setFilters(list);
                    }}
                />
            </Space>
        );
    };
    const getGroupSwitch = (objIndex, index, obj, type) => {
        return (
            <Space direction="vertical">
                <Switch
                    style={{ backgroundColor: "rgb(1, 148, 247)" }}
                    checked={obj.module == "Task"}
                    checkedChildren={"Task"}
                    unCheckedChildren={"Deal"}
                    onChange={(checked, event) => {
                        let list = [...filters];
                        if (list[objIndex].Group) {
                            list[objIndex].Group[index].module = checked ? "Task" : "Deal";
                            setFilters(list);
                        }
                    }}
                />
            </Space>
        );
    };
    //switch section -- end
    //get fields section -- start
    const getModuleItems = (obj) => {
        let arr = [];
           
        filterItems.forEach((one) =>
            arr.push({
                label: one.name,
                value: one.name,
                api_name: one.field,
                type: one.type,
            })
        );
        // console.log("fields----",arr);
        return arr;
    };

    const addFilterInGroup = (obj, index) => {
        const filterList = [...filters];
        if (filterList[index].Group) {
            filterList[index].Group.push({
                phase: "initial",
                module: "Deal",
                logic: "AND",
            });
            setFilters(filterList);
        }
    };
    const getModuleItems1 = (obj) => {
        
        let arr = [];
        filterItems.forEach((one) => {
           arr.push(one.name)
        });
        return arr;
    };
    const getMiddleLastInputForDeal = (obj) => {
           
        if (cacheData) {
            let arr = [];
            let unique = [];
            if(obj.api_name=="Project_Status"){
                obj.api_name="Stage"
            }
                cacheData.forEach((one, index) =>{
                    if(obj.api_name=="Stage"){
                        let status=dealStatus?.find(e=>e.display_value==one.Stage);
                        one={...one,color:status.colour_code}
                    }
                    let flag=false;
                    if((module==0 &&obj.api_name=='Account_Name'||_.isObject(one[obj.api_name]))){
                        if(!one[obj.api_name] || !one[obj.api_name].name){
                            return
                        }
                       flag=!unique.includes(one[obj.api_name].name?.split("_")[0]) 
                       let client=allClients?.find(e=>e.Account_Name==one[obj.api_name].name);
                       one={...one,color:client?.Color?client.Color:'#5b6c97'}
                    }else{
                       flag=!unique.includes(one[obj.api_name]?.split("_")[0]);
                    }
                    if(flag){
                        if(_.isObject( one[obj.api_name])){
                            if(types[obj.type]=="string" && (one[obj.api_name] && one[obj.api_name].name?.trim()!="")){
                                arr.push({ name: one[obj.api_name].name?.trim(), color: one?.color, id: one?.ROWID ? one?.ROWID : one?.id  });
                                unique.push(one[obj.api_name].name);
                            }else if(types[obj.type]!="string"){
                                arr.push({ name: one[obj.api_name].name, color: one?.color, id: one?.ROWID ? one?.ROWID : one?.id  });
                                unique.push(one[obj.api_name].name);
                            }
                        }else{
                            if(types[obj.type]=="string" && (one[obj.api_name] && one[obj.api_name]?.trim()!="")){
                                arr.push({ name: one[obj.api_name]?.includes("_")?one[obj.api_name]?.split("_")[0]:one[obj.api_name], color: one?.color?one.color:one.Color, id: one?.ROWID ? one?.ROWID : one?.id ? one?.id : index });
                                unique.push(one[obj.api_name]?.split("_")[0]);
                            }else if(types[obj.type]!="string"){
                                arr.push({ name: one[obj.api_name]?.includes("_")?one[obj.api_name]?.split("_")[0]:one[obj.api_name], color: one?.color, id: one?.ROWID ? one?.ROWID : one?.id  });
                                unique.push(one[obj.api_name]?.includes("_")?one[obj.api_name]?.split("_")[0]:one[obj.api_name]);
                            }
                        }
                    }
                }
            );
            return arr;
        }
    }
    const updatedgetMiddleLastInputForDeal = (obj) => {
        let newdata = getMiddleLastInputForDeal(obj);
        newdata.unshift({ name: "Select All", color: "#fff", id: "0" })
        return newdata;
    }

    function getDisplayName(k) {
        return k=="ptoCode"?"Absense":k=="TimesheetDate"?"Timesheet Date":
        k=="requestType"?"Request Type":k=="requestDate"?"Request Date":
        k=="checkOut"?"Check Out":k=="checkIn"?"Check In":k=="userName"?"User Name":k;
    }
    function DeleteColumnFilters(key) {
        let newFilter = { ...activeFiltersOnColumnLevel };
        delete newFilter[key];
        if (Object.keys(newFilter)?.length == 0) {
          setActiveFiltersOnColumnLevel({});
        } else {
          setActiveFiltersOnColumnLevel(newFilter);
        }
        setIsColFilDeleted(true)
        // console.log("newFilter", newFilter);
      }
    function getColumnFilterIfExist(filters) {
        if (filters && Object.keys(filters).length > 0) {
          let keys = Object.keys(filters);
          return (
            <div className="col Column-Filter-Wrapper">
              <div className="row w-100" style={{ fontWeight: 500 }}>
                Column Filter Applied :
              </div>
              <div className="row w-100">
                {keys.map((k, i) => {
                let displayName = getDisplayName(k);
                  return (
                    // <div className="row w-100 column-filter-row">
                    //    <div className="col-3">{k.replaceAll("_", " ")}</div>
                    //   <div className="col-8 Filter-Value-Wrapper">
                    //     {renderInnerFilterContent(filters[k], k)}
                    //   </div>
                    //   <div className="col-1">
                    //     <FontAwesomeIcon
                    //       style={{ marginLeft: "4px" }}
                    //       onClick={() => {
                    //         DeleteColumnFilters(k);
                    //       }}
                    //       icon={faTrashAlt}
                    //     />
                    //   </div>
                    // </div>
                    <span className="filter-span" key={i}>
                          {displayName.replaceAll("_", " ")}
                          <FontAwesomeIcon
                           icon={faTrashAlt}
                            className="Delete-Icon-For-ColFilters"
                            style={{
                              marginLeft: "3px",
                              marginRight: "3px",
                              color: "#ff4545",
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              DeleteColumnFilters(k);
                              //deletePerticularValueOfColumnFilter(key,v, i,d?.filterType);
                            }}
                          />
                      </span>
                  );
                })}
              </div>
            </div>
          );
        }
      }

    const getLastInputTypeForGroup = (obj1, index, obj, groupIdx) => {
        if (types[obj.type] == "string") {
            if (dropdownFields.includes(obj.column_name)) {
              if (obj.condition == "is" || obj.condition == "isn't") {
                return (
                  <CustomMultiSelect
                    setOpendDropDowns={setOpendDropDowns}
                    invertColor={invertColor}
                    opendDropdowns={opendDropdowns}
                    id={`checkboxes-group${groupIdx}${index}`}
                    values={obj.value}
                    filterIndex={index}
                    GroupIndex={groupIdx}
                    removeTile={removeTile}
                    onChange={(checked, event, optionIdx) => {
                      if (obj.value) {
                        if (checked) {
                          let list = [...filters];
                          let data= updatedgetMiddleLastInputForDeal(obj)
                          if(event.name=="Select All"){
                            list[index].Group[groupIdx].value=[];
                            data.map((abc)=>{
                              list[index].Group[groupIdx].value.push(abc);
                              list[index].Group[groupIdx].phase = "completed";
                            })
                           }else{
                            list[index].Group[groupIdx].value.push(event);
                          list[index].Group[groupIdx].phase = "completed";
                          if(list[index].Group[groupIdx].value.length==data.length-1){
                            list[index].Group[groupIdx].value.push({ name: "Select All", color: "#fff", id: "0" })
                          }
                        }
                          // list[index].Group[groupIdx].value.push(event);
                          // list[index].Group[groupIdx].phase = "completed";
                          setFilters(list);
                        } else {
                          let list = [...filters];
                          if(event.name=="Select All"){        
                            list[index].Group[groupIdx].value=[];
                            list[index].Group[groupIdx].phase = "middle";                      
                           }else{
                             list[index].Group[groupIdx].value = list[index].Group[groupIdx].value?.filter(
                               (one) => one.name !== event.name && one.name!=="Select All"
                             );
                             list[index].Group[groupIdx].phase = "completed";
                             if (list[index].Group[groupIdx].value.length == 0) {
                              list[index].Group[groupIdx].phase = "middle";
                          }
                        }
                          setFilters(list);
                        }
                      } else {
                        if (checked) {
                          let list = [...filters];
                          if(event.name=="Select All"){
                            list[index].Group[groupIdx].value=[];
                            let data= updatedgetMiddleLastInputForDeal(obj)
                            data.map((abc)=>{
                              list[index].Group[groupIdx].value.push(abc);
                              list[index].Group[groupIdx].phase = "completed";
                            })
                           }else{
                          list[index].Group[groupIdx].value = [event];
                          list[index].Group[groupIdx].phase = "completed";
                          }
                          // list[index].Group[groupIdx].value = [event];
                          // list[index].Group[groupIdx].phase = "completed";
                          setFilters(list);
                        } else {
                          let list = [...filters];
                          list[index].Group[groupIdx].value = null;
                          list[index].Group[groupIdx].phase = "middle";
                          setFilters(list);
                        }
                      }
                    }}
                    icon={obj.column_name}
                    options={updatedgetMiddleLastInputForDeal(obj)}
                  />
                );
              } else {
                return (
                  <input
                    style={{ height: "35px" }}
                    className="form-control form-control-sm"
                    type={
                      obj.condition == "is empty" || obj.condition == "is not empty"
                        ? "hidden"
                        : "text"
                    }
                    id={"checkboxes-group" + groupIdx}
                    value={obj.value}
                    onChange={(e) => {
                      if (e.currentTarget.value) {
                        let list = [...filters];
                        list[index].Group[groupIdx].value = e.currentTarget.value;
                        list[index].Group[groupIdx].phase = "completed";
                        setFilters(list);
                      } else {
                        let list = [...filters];
                        list[index].Group[groupIdx].phase = "middle";
                        list[index].Group[groupIdx].value = e.currentTarget.value;
                        setFilters(list);
                      }
                    }}
                  />
                );
              }
            } else {
              return (
                <input
                  style={{ height: "35px" }}
                  className="form-control form-control-sm"
                  type={
                    obj.condition == "is empty" || obj.condition == "is not empty"
                      ? "hidden"
                      : "text"
                  }
                  id={"checkboxes-group" + groupIdx}
                  value={obj.value}
                  onChange={(e) => {
                    if (e.currentTarget.value) {
                      let list = [...filters];
                      list[index].Group[groupIdx].value = e.currentTarget.value;
                      list[index].Group[groupIdx].phase = "completed";
                      setFilters(list);
                    } else {
                      let list = [...filters];
                      list[index].Group[groupIdx].phase = "middle";
                      list[index].Group[groupIdx].value = e.currentTarget.value;
                      setFilters(list);
                    }
                  }}
                />
              );
            }
          }
        else if (types[obj.type] == "date") {  
            if (
              filters[index] &&
              filters[index].Group &&
              filters[index].Group[groupIdx] &&
              (types[filters[index].Group[groupIdx].type] == "date" && filters[index].Group[groupIdx].value != "Date Range")
            ) {
              return (
                <>
                  <div className="row">
                    <div className="col-sm-5">
                      <CustumSelect
                        addFlag={false}
                        placeholder={"Select Option"}
                        value={obj.value ? obj.value : ""}
                        onChange={(value) => {
                          let list = [...filters];
                          let newList = [];
                          list?.forEach((t) => {
                            if (!newList.includes(t)) {
                              newList.push(t);
                            }
                          });
                          list = newList;
                          list[index].Group[groupIdx].value = value;
                          // list[index].phase = "completed";
                          setFilters(list);
                        }}
                        items={(() => {
                          let arr = [];
                          if (dateFilterArr) {
                            dateFilterArr.forEach((one) => arr.push(one.label));
                          }
                          return arr;
                        })()}
                      />
                    </div>
                    <div className="col-sm-7">
                      <AntdDatepicker
                        portalId="root"
                        allowClear
                        format="MM/DD/YYYY"
                        popperClassName="ag-custom-component-popup"
                        className="form-control form-control-sm"
                        value={getDatePickerValueOfGroup(index, groupIdx)}
                        onChange={(selected) => { 
                          //setDate([...date,{date:selected,index:index}])
                          if (selected) {
                            let list = [...filters];
                            list[index].Group[groupIdx].date = [
                              dayjs(selected).format("MM/DD/YYYY"),
                            ];
                            list[index].Group[groupIdx].phase = "completed";
                            setDatePickerValue(list);
                            setFilters(list);
                          } else {
                            let list = [...filters];
                            list[index].Group[groupIdx].date = [selected];
                            list[index].Group[groupIdx].phase = "middle";
                            setDatePickerValue(list);
                            setFilters(list);
                          }
                        }}
                      />
                    </div>
                  </div>
                </>
              );
            }
            if (
              filters[index] &&
              filters[index].Group &&
              filters[index].Group[groupIdx].value == "Date Range"
            ) {
              return (
                <>
                  <div className="row">
                    <div className="col-sm-5">
                      <CustumSelect
                        addFlag={false}
                        placeholder={"Select Option"}
                        value={obj.value ? obj.value : ""}
                        onChange={(value) => {
                          let list = [...filters];
                          let newList = [];
                          list?.forEach((t) => {
                            if (!newList.includes(t)) {
                              newList.push(t);
                            }
                          });
                          list = newList;
                          list[index].Group[groupIdx].value = value;
                          // list[index].phase = "completed";
                          setFilters(list);
                        }}
                        items={(() => {
                          let arr = [];
                          if (dateFilterArr) {
                            dateFilterArr.forEach((one) => arr.push(one.label));
                          }
                          return arr;
                        })()}
                      />
                    </div>
                    <div className="col-sm-7">
                      <AntdRangePicker
                        onChangeDateRange={(dates, datesString) => { 

                          let start = new Date(new Date(datesString[0]));
                          let end = new Date(new Date(datesString[1]));
                          let arr = [];
                          let date = start;
                          while (date <= end) {
                            let temp = dayjs(new Date(date)).format("MM-DD-YYYY");
                            arr.push(temp);
                            date.setDate(date.getDate() + 1);
                          }
                          let list = [...filters];
                          list[index].Group[groupIdx].date = arr;
                          list[index].Group[groupIdx].phase = "completed";
                          setDateRange(list);
                          setFilters(list);
                        }}
                        defaultStartDate={getDateRangePickerValue(
                          index,
                          0,
                          groupIdx,
                          true
                        )}
                        defaultEndDate={getDateRangePickerValue(
                          index,
                          -1,
                          groupIdx,
                          true
                        )}
                        allEnd={getDateRangePickerValue(index, -1, groupIdx, true)}
                        allStart={getDateRangePickerValue(index, 0, groupIdx, true)}
                      />
                    </div>
                  </div>
                </>
              );
            } else {
              return (
                <CustumSelect
                  addFlag={false}
                  placeholder={"Select Option"}
                  value={obj.value ? obj.value : ""}
                  onChange={(value) => {
                    let list = [...filters];
                    let newList = [];
                    list?.forEach((t) => {
                      if (!newList.includes(t)) {
                        newList.push(t);
                      }
                    });
                    list = newList;
                    list[index].value = value;
                    list[index].Group[groupIdx].value = value;
                    if (
                      list[index] &&
                      list[index].Group &&
                      list[index].Group[groupIdx].value !== "Before Date" &&
                      list[index].Group[groupIdx].value !== "After Date" &&
                      list[index].Group[groupIdx].value !== "Date Range"
                    ) {
                      list[index].Group[groupIdx].phase = "completed";
                    }

                    setFilters(list);
                  }}
                  items={(() => {
                    let arr = [];
                    if (dateFilterArr) {
                      dateFilterArr.forEach((one) => arr.push(one.label));
                    }
                    return arr;
                  })()}
                />
              );
            }
          } 
        else if (types[obj.type] == "number") {
            if (obj.condition == "between" || obj.condition == "not between") {
              return (
                <div className="row">
                  <div className="col">
                    <input
                      placeholder="from..."
                      style={{ height: "35px" }}
                      className="form-control form-control-sm"
                      type="number"
                      id={"checkboxes-group" + groupIdx}
                      value={obj.from}
                      onChange={(e) => {
                        if (e.currentTarget.value) {
                          let list = [...filters];
                          list[index].Group[groupIdx].from = e.currentTarget.value;
                          list[index].Group[groupIdx].phase = "completed";
                          setFilters(list);
                        } else {
                          let list = [...filters];
                          list[index].Group[groupIdx].phase = "middle";
                          list[index].Group[groupIdx].to = e.currentTarget.value;
                          list[index].Group[groupIdx].from = e.currentTarget.value;
                          setFilters(list);
                        }
                      }}
                    />
                  </div>
                  <div className="col">
                    <input
                      placeholder="to.."
                      style={{ height: "35px" }}
                      className="form-control form-control-sm"
                      type="number"
                      id={"checkboxes-group" + groupIdx}
                      value={obj.value}
                      onChange={(e) => {
                        if (e.currentTarget.value) {
                          let list = [...filters];
                          list[index].Group[groupIdx].value = e.currentTarget.value;
                          list[index].Group[groupIdx].phase = "completed";
                          setFilters(list);
                        } else {
                          let list = [...filters];
                          list[index].Group[groupIdx].phase = "middle";
                          list[index].Group[groupIdx].value = e.currentTarget.value;
                          setFilters(list);
                        }
                      }}
                    />
                  </div>
                </div>
              );
            }
            if (obj.condition == "is") {
              return (
                <div className="row">
                  <div className="col">
                    <CustomMultiSelect
                      setOpendDropDowns={setOpendDropDowns}
                      invertColor={invertColor}
                      opendDropdowns={opendDropdowns}
                      id={`checkboxes-group${groupIdx}${index}`}
                      values={obj.value}
                      filterIndex={index}
                      GroupIndex={groupIdx}
                      removeTile={removeTile}
                      onChange={(checked, event, optionIdx) => {
                        if (obj.value) {
                          if (checked) {
                            let list = [...filters];
                            list[index].Group[groupIdx].value.push(event);
                            list[index].Group[groupIdx].phase = "completed";
                            setFilters(list);
                          } else {
                            let list = [...filters];
                            list[index].Group[groupIdx].value = list[index].Group[
                              groupIdx
                            ].value?.filter((one) => one.name !== event.name);
                            list[index].Group[groupIdx].phase = "completed";
                            if (list[index].Group[groupIdx].value.length == 0) {
                              list[index].Group[groupIdx].phase = "middle";
                            }
                            setFilters(list);
                          }
                        } else {
                          if (checked) {
                            let list = [...filters];
                            list[index].Group[groupIdx].value = [event];
                            list[index].Group[groupIdx].phase = "completed";
                            setFilters(list);
                          } else {
                            let list = [...filters];
                            list[index].Group[groupIdx].value = null;
                            list[index].Group[groupIdx].phase = "middle";
                            setFilters(list);
                          }
                        }
                      }}
                      icon={obj.column_name}
                      options={getListOfNumbersForTypeNumber(obj)}
                    />
                  </div>
                </div>
              );
            } else {
              return (
                <input
                  placeholder="number.."
                  style={{ height: "35px" }}
                  className="form-control form-control-sm"
                  type={
                    obj.condition == "is empty" || obj.condition == "is not empty"
                      ? "hidden"
                      : "number"
                  }
                  id={"checkboxes-group" + groupIdx}
                  value={obj.value}
                  onChange={(e) => {
                    if (e.currentTarget.value) {
                      let list = [...filters];
                      list[index].Group[groupIdx].value = e.currentTarget.value;
                      list[index].Group[groupIdx].phase = "completed";
                      setFilters(list);
                    } else {
                      let list = [...filters];
                      list[index].Group[groupIdx].phase = "middle";
                      list[index].Group[groupIdx].value = e.currentTarget.value;
                      setFilters(list);
                    }
                  }}
                />
              );
            }
          } 
        //   else if (obj.type == "boolean") {
        //     if (obj.condition == "is") {
        //       return (
        //         <CustomMultiSelect
        //           setOpendDropDowns={setOpendDropDowns}
        //           opendDropdowns={opendDropdowns}
        //           id={`checkboxes-group${groupIdx}${index}`}
        //           values={obj.value}
        //           filterIndex={index}
        //           GroupIndex={groupIdx}
        //           removeTile={removeTile}
        //           onChange={(checked, event, optionIdx) => {
        //             if (obj.value) {
        //               if (checked) {
        //                 let list = [...filters];
        //                 list[index].Group[groupIdx].value.push(event);
        //                 list[index].Group[groupIdx].phase = "completed";
        //                 setFilters(list);
        //               } else {
        //                 let list = [...filters];
        //                 list[index].Group[groupIdx].value = list[index].Group[
        //                   groupIdx
        //                 ].value?.filter((one) => one.name !== event.name);
        //                 list[index].Group[groupIdx].phase = "completed";
        //                 if (list[index].Group[groupIdx].value.length == 0) {
        //                   list[index].Group[groupIdx].phase = "middle";
        //                 }
        //                 setFilters(list);
        //               }
        //             } else {
        //               if (checked) {
        //                 let list = [...filters];
        //                 list[index].Group[groupIdx].value = [event];
        //                 list[index].Group[groupIdx].phase = "completed";
        //                 setFilters(list);
        //               } else {
        //                 let list = [...filters];
        //                 list[index].Group[groupIdx].value = null;
        //                 list[index].Group[groupIdx].phase = "middle";
        //                 setFilters(list);
        //               }
        //             }
        //           }}
        //           icon={obj.column_name}
        //           options={
        //             obj.api_name == "Stop_Work_Order_Exists_on_Property"
        //               ? [
        //                   { name: "No", id: "no" },
        //                   { name: "Partial SWO Exists", id: "Partial SWO Exists" },
        //                   { name: "Full SWO Exists", id: "Full SWO Exists" },
        //                   { name: "N/A", id: "N/A" },
        //                 ]
        //               : obj.api_name == "Conjunction"
        //               ? [
        //                   { name: "(Blank)", id: "blank" },
        //                   { name: "N/A", id: "N/A" },
        //                   { name: "No", id: "no" },
        //                   ...ConjunctionApplicationNumbers,
        //                 ]
        //               : obj.api_name == "Construction_Classification_Code"
        //               ? [{ name: "N/A", id: "N/A" }, ...ConstructionClassfCode]
        //               : [
        //                   { name: "N/A", id: "N/A" },
        //                   { name: "Yes", id: "yes" },
        //                   { name: "No", id: "no" },
        //                 ]
        //           }
        //         />
        //       );
        //     } else {
        //       return (
        //         <input
        //           style={{ height: "35px" }}
        //           className="form-control form-control-sm"
        //           type="text"
        //           id={"checkboxes-group" + groupIdx}
        //           value={obj.value}
        //           onChange={(e) => {
        //             if (e.currentTarget.value) {
        //               let list = [...filters];
        //               list[index].Group[groupIdx].value = e.currentTarget.value;
        //               list[index].Group[groupIdx].phase = "completed";
        //               setFilters(list);
        //             } else {
        //               let list = [...filters];
        //               list[index].Group[groupIdx].phase = "middle";
        //               list[index].Group[groupIdx].value = e.currentTarget.value;
        //               setFilters(list);
        //             }
        //           }}
        //         />
        //       );
        //     }
        //   } 
        else {
            return <></>;
        }
    };
    //get fields section -- end

    const getGroupsForInitital = (one, index) => {
        if (one?.Group) {
            return (
                <>
                    <div
                        className="col-sm-6 ml-3 group-wrapper"
                    //style={{ borderLeft: "1px solid lightgray" }}
                    >
                        {one?.Group.map((group, groupIdx) => {
                            return (
                                <div className="row mt-1">
                                    <div className="col-sm-2" style={{ lineHeight: "30px" }}>
                                        {groupIdx == 0
                                            ? "Where"
                                            : getGroupSwitch(index, groupIdx, group, "condition")}
                                    </div>
                                    {module == "Both" ? (
                                        <div className="col-sm-2">
                                            {getGroupSwitch(index, groupIdx, group, "module")}
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                    <div className="col-sm-3">
                                        <Select
                                            showSearch
                                            style={{ width: 190 }}
                                            placeholder="Search to Select"
                                            onSelect={(value, option) => {
                                                let list = [...filters];
                                                list[index].Group[groupIdx].phase = "middle";
                                                list[index].Group[groupIdx].column_name = value;
                                                setFilters(list);
                                                let items = [...filterItems];
                                                let conditions = [...filterCondition];
                                                items = items?.filter((one) => one.name == value);
                                                conditions = conditions?.filter(
                                                    (cond) => cond.type == types[items[0].type]
                                                );
                                                list[index].Group[groupIdx].column_name = value;
                                                list[index].Group[groupIdx].api_name = option.api_name;
                                                list[index].Group[groupIdx].type = option.type;
                                                list[index].Group[groupIdx].value = undefined;
                                                list[index].Group[groupIdx].condition = undefined;
                                                setSelectedCond(conditions[0].conditions);

                                            }}
                                            options={getModuleItems(group)}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                        <p
                            onClick={() => addFilterInGroup(one, index)}
                            style={{ cursor: "pointer", width: "90px" }}
                            className="small mt-2 mb-0"
                        >
                            Add Filter +
                        </p>
                    </div>
                </>
            );
        }
    };
    const initialPhaseInput = (index, one) => {
        return (
            <div className="row mt-2 filter-div-wrapper">
                <div
                    className="col-sm-1"
                    style={{ lineHeight: "30px", paddingLeft: "0px" }}
                >
                    {index == 0 ? "Where" : getWitch(index, one, "condition")}
                </div>

                {one?.Group && one?.Group.length > 0 ? (
                    getGroupsForInitital(one, index)
                ) : (
                    <>
                        <div className="col-sm-3">
                            <Select
                                showSearch
                                style={{ width: 190 }}
                                placeholder="Search to Select"
                                onSelect={(value, option) => {
                                    
                                    let list = [...filters];
                                    list[index].phase = "middle";
                                    list[index].column_name = value;
                                    list[index].api_name = option.api_name;
                                    list[index].type = option.type;
                                    list[index].value = undefined;
                                    list[index].condition = undefined;
                                    let items = [...filterItems]
                                    let conditions = [...filterCondition];
                                    items = items?.filter((one) => one.name == value);
                                    conditions = conditions?.filter(
                                        (cond) => cond.type == types[items[0].type]
                                    );
                                    // console.log("option ---- ",option)
                                    setFilters(list);
                                    setSelectedCond(conditions[0].conditions);

                                }}
                                options={getModuleItems(one)}
                            />
                        </div>
                    </>
                )}
            </div>
        );
    };
    const middlePhaseGroupInput = (obj, index) => {
        
        if (obj.Group) {
            return (
                <>
                    <div
                        className="col-sm-11 group-wrapper"
                    >
                        {obj.Group.map((group, groupIdx) => {
                            return (
                                <div className="row mt-1">
                                    <div className="col-sm-1 insideSwitch">
                                        {groupIdx == 0
                                            ? "Where"
                                            : getGroupSwitch(index, groupIdx, group, "condition")}
                                    </div>
                                    <div className="col-sm-3">
                                        <CustumSelect
                                            addFlag={false}
                                            // placeholder={'Select Applicant'}
                                            value={group.column_name ? group.column_name : ""}
                                            onChange={(value) => {
                                                let list = [...filters];
                                                let newList = [];
                                                list?.forEach((t) => {
                                                    if (!newList.includes(t)) {
                                                        newList.push(t);
                                                    }
                                                });
                                                list = newList;
                                                let items =[...filterItems];
                                                let conditions = [...filterCondition];
                                                items = items?.filter((one) => one.name == value);
                                                conditions = conditions?.filter(
                                                    (cond) => cond.type == types[items[0].type]
                                                );
                                                list[index].Group[groupIdx].column_name = value;
                                                list[index].Group[groupIdx].value = undefined;
                                                list[index].Group[groupIdx].condition = undefined;
                                                list[index].Group[groupIdx].type = items[0].type;
                                                list[index].Group[groupIdx].api_name =items[0].field;
                                                list[index].Group[groupIdx].phase = "middle";

                                                setSelectedCond(conditions[0].conditions);
                                                setFilters(list);
                                            }}
                                            items={getModuleItems1(group)}
                                        />
                                    </div>
                                    {types[obj.Group[groupIdx].type] !== "date" ? (
                                        <div className="col-sm-2">
                                            <CustumSelect
                                                addFlag={false}
                                                placeholder={"Condition"}
                                                value={group.condition ? group.condition : ""}
                                                onChange={(value) => {
                                                    let list = [...filters];
                                                    let newList = [];
                                                    list?.forEach((t) => {
                                                        if (!newList.includes(t)) {
                                                            newList.push(t);
                                                        }
                                                    });
                                                    list = newList;
                                                    list[index].Group[groupIdx].condition = value;
                                                    list[index].Group[groupIdx].value = undefined;
                                                    setFilters(list);
                                                }}
                                                items={(() => {
                                                    let arr = [];
                                                    selectedCond.forEach((one) => arr.push(one.value));
                                                    return arr;
                                                })()}
                                            />
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                    <div
                                        className={types[obj.Group[groupIdx].type] == "date"
                                            ? "col-sm-7"
                                            : "col-sm-5"
                                        }
                                    >
                                        {getLastInputTypeForGroup(obj, index, group, groupIdx)}
                                    </div>
                                    <div
                                        className="col-sm-1"
                                        style={{ lineHeight: "33px", paddingRight: "20px", textAlign: "right" }}
                                    >
                                        <FontAwesomeIcon
                                            onClick={() => {
                                                let list = [...filters];
                                                if (list[index].Group) {
                                                    list[index].Group.splice(groupIdx, 1);
                                                    if (list[index].Group?.length == 0) {
                                                        list.splice(index, 1);
                                                    }
                                                    if (list?.length == 0) {
                                                        setFilters([{ phase: "initial", module: "Deal" }]);
                                                    } else {
                                                        setFilters(list);
                                                    }
                                                }

                                                // setfiltersmode([]);

                                            }}
                                            className="cursor-pointer trashIcon"
                                            icon={faTrashAlt}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                        <p
                            onClick={() => addFilterInGroup(obj, index)}
                            style={{ cursor: "pointer", width: "90px" }}
                            className="small mt-2 mb-0"
                        >
                            Add Filter +
                        </p>
                    </div>
                </>
            );
        }
    };
    const middlePhaseInput = (index, obj) => {

        return (
            <div className="row mt-2 filter-div-wrapper">
                <div className="col-sm-1 groupFilter">
                    {index == 0 ? "Where" : getWitch(index, obj, "condition")}
                </div>
                {obj.Group && obj.Group.length > 0 ? (
                    middlePhaseGroupInput(obj, index)
                ) : (
                    <>
                        <div className="col-sm-3">
                            <CustumSelect
                                addFlag={false}
                                // placeholder={'Select Applicant'}
                                value={obj.column_name ? obj.column_name : ""}
                                onChange={(value) => {
                                    
                                    let list = [...filters];
                                    let newList = [];
                                    list?.forEach((t) => {
                                        if (!newList.includes(t)) {
                                            newList.push(t);
                                        }
                                    });
                                    list = newList;
                                    let items = [...filterItems];
                                    let conditions = [...filterCondition];
                                    items = items?.filter((one) => one.name == value);
                                    conditions = conditions?.filter(
                                        (cond) => cond.type == types[items[0].type]
                                    );
                                    list[index].column_name = value;
                                    list[index].api_name = items[0].field;
                                    list[index].type = items[0].type;
                                    list[index].value = undefined;
                                    list[index].condition = undefined;
                                    list[index].phase = "middle";
                                    setSelectedCond(conditions[0].conditions);
                                    setFilters(list);
                                }}
                                items={getModuleItems1(obj)}
                            />
                        </div>
                        {types[obj.type] !== "date" ? (
                            <div className="col-sm-2">
                                <CustumSelect
                                    addFlag={false}
                                    placeholder={"Condition"}
                                    value={obj.condition ? obj.condition : ""}
                                    onChange={(value) => {
                                        let list = [...filters];
                                        let newList = [];
                                        list?.forEach((t) => {
                                            if (!newList.includes(t)) {
                                                newList.push(t);
                                            }
                                        });
                                        list = newList;
                                        list[index].condition = value;
                                        if (value == "is empty" || value == "is not empty") {
                                            list[index].value = value;
                                            list[index].phase = "completed";
                                        } else {
                                            list[index].value = undefined;
                                        }
                                        list[index].value = undefined;
                                        setFilters(list);
                                    }}
                                    items={(() => {
                                        let arr = [];
                                        selectedCond.forEach((one) => arr.push(one.value));
                                        return arr;
                                    })()}
                                />
                            </div>
                        ) : (
                            <></>
                        )}
                        <div
                            className={types[obj.type] == "date"
                                ? "col-sm-7"
                                : "col-sm-5"
                            }
                        >
                            {getLastInputType(obj, index)}
                        </div>

                        <div className="col-sm-1" style={{ lineHeight: "33px" }}>
                            <FontAwesomeIcon
                                onClick={() => {
                                    let list = [...filters];
                                    list = list?.filter((one, idx) => idx !== index);
                                    if (list?.length == 0) {
                                        setFilters([{ phase: "initial", module: "Deal" }]);
                                    } else {
                                        setFilters(list);
                                    }

                                    //   removeDatePickerValue(list);
                                    //   setfiltersmode([]);

                                    //setFilters([{ phase: "initial", module: "Deal" }]);
                                }}
                                className="cursor-pointer trashIcon"
                                icon={faTrashAlt}
                            />
                        </div>
                    </>
                )}
            </div>
        );
    };
    const getLastInputType = (obj, index) => {
        if (types[obj.type] == "string") {
            if (dropdownFields.includes(obj.column_name)) {
                if (obj.condition == "is" || obj.condition == "isn't") {
                    return (
                        <CustomMultiSelect
                            setOpendDropDowns={setOpendDropDowns}
                            invertColor={invertColor}
                            opendDropdowns={opendDropdowns}
                            id={"checkboxes" + index}
                            filterIndex={index}
                            removeTile={removeTile}
                            values={obj.value}
                            onChange={(checked, event, optionIdx) => {
                                if (obj.value) {
                                    if (checked) {
                                        let list = [...filters];
                                        let data = updatedgetMiddleLastInputForDeal(obj)
                                        if (event.name == "Select All") {
                                            list[index].value = [];
                                            data.map((abc) => {
                                                list[index].value.push(abc);
                                                list[index].phase = "completed";
                                            })
                                        } else {
                                            list[index].value.push(event);
                                            list[index].phase = "completed";
                                            if (list[index].value.length == data.length - 1) {
                                                list[index].value.push({ name: "Select All", color: "#fff", id: "0" })
                                            }
                                        }
                                        setFilters(list);
                                    } else {
                                        let list = [...filters];
                                        if (event.name == "Select All") {
                                            list[index].value = [];
                                            list[index].phase = "middle";
                                        } else {
                                            list[index].value = list[index].value?.filter(
                                                (one) => one.name !== event.name && one.name !== "Select All"
                                            );
                                            list[index].phase = "completed";
                                            if (list[index].value.length == 0) {
                                                list[index].phase = "middle";
                                            }
                                        }
                                        setFilters(list);
                                    }
                                } else {
                                    if (checked) {
                                        let list = [...filters];
                                        if (event.name == "Select All") {
                                            list[index].value = [];
                                            let data = updatedgetMiddleLastInputForDeal(obj)
                                            data.map((abc) => {
                                                list[index].value.push(abc);
                                                list[index].phase = "completed";
                                            })
                                        } else {
                                            list[index].value = [event];
                                            list[index].phase = "completed";
                                        }
                                        setFilters(list);
                                    } else {
                                        let list = [...filters];
                                        list[index].value = null;
                                        list[index].phase = "middle";
                                        setFilters(list);
                                    }
                                }
                            }}
                            icon={obj.column_name}
                            options={updatedgetMiddleLastInputForDeal(obj)}
                        />
                    );
                } else {
                    return (
                        <input
                            style={{ height: "35px" }}
                            className="form-control form-control-sm"
                            type={
                                obj.condition == "is empty" || obj.condition == "is not empty"
                                    ? "hidden"
                                    : "text"
                            }
                            id={"checkboxes" + index}
                            value={obj.value}
                            onChange={(e) => {
                                if (e.currentTarget.value) {
                                    let list = [...filters];
                                    list[index].value = e.currentTarget.value;
                                    list[index].phase = "completed";
                                    setFilters(list);
                                } else {
                                    let list = [...filters];
                                    list[index].phase = "middle";
                                    list[index].value = e.currentTarget.value;
                                    setFilters(list);
                                }
                            }}
                        />
                    );
                }
            } else {
                let listx = [...filters];
                return (
                    <input
                        style={{ height: "35px" }}
                        className="form-control form-control-sm"
                        type={
                            obj.condition == "is empty" || obj.condition == "is not empty"
                                ? "hidden"
                                : "text"
                        }
                        id={"checkboxes" + index}
                        value={listx[index].value}
                        onChange={(e) => {
                            if (e.currentTarget.value) {
                                let list = [...filters];
                                list[index].value = e.currentTarget.value;
                                list[index].phase = "completed";
                                setFilters(list);
                            } else {
                                let list = [...filters];
                                list[index].phase = "middle";
                                list[index].value = e.currentTarget.value;
                                setFilters(list);
                            }
                        }}
                    />
                );
            }
        } else if (types[obj.type] == "date") {
            if (
                filters[index] && types[filters[index].type] == "date" && filters[index].value != "Date Range"
            ) {
                return (
                    <>
                        <div className="row">
                            <div className="col-sm-5">
                                <CustumSelect
                                    addFlag={false}
                                    placeholder={"Select Option"}
                                    value={obj.value ? obj.value : ""}
                                    onChange={(value) => {
                                        let list = [...filters];
                                        let newList = [];
                                        list?.forEach((t) => {
                                            if (!newList.includes(t)) {
                                                newList.push(t);
                                            }
                                        });
                                        list = newList;
                                        list[index].value = value;
                                        // list[index].phase = "completed";
                                        setFilters(list);
                                    }}
                                    items={(() => {
                                        let arr = [];
                                        if (dateFilterArr) {
                                            dateFilterArr.forEach((one) => arr.push(one.label));
                                        }
                                        return arr;
                                    })()}
                                />
                            </div>
                            <div className="col-sm-7">
                                <AntdDatepicker
                                    allowClear
                                    portalId="root"
                                    format="MM/DD/YYYY"
                                    placeholderText="Select a date"
                                    popperClassName="ag-custom-component-popup"
                                    className="form-control form-control-sm"
                                    value={getDatePickerValue(index)}
                                    onChange={(selected) => { 
                                        try {
                                            if (
                                                selected &&
                                                selected.$d instanceof Date &&
                                                isFinite(selected.$d.getTime())
                                            ) {
                                                let list = [...filters];
                                                list[index].date = [
                                                    dayjs(selected.$d).format("MM/DD/YYYY"),
                                                ];
                                                list[index].phase = "completed";
                                                setDatePickerValue(list);
                                                setFilters(list);
                                                // date is valid
                                            } else {
                                                let list = [...filters];
                                                list[index].date = [selected];
                                                list[index].phase = "middle";
                                                setDatePickerValue(list);
                                                setFilters(list);
                                            }
                                        } catch (error) { }
                                    }}
                                />
                                {/* <label className="Date-Picker-Calender">
                        <img src={calIcon} alt="cal" />
                      </label> */}
                            </div>
                        </div>
                    </>
                );
            }
            if (filters[index] && filters[index].value == "Date Range") {
                return (
                    <>
                        <div className="row">
                            <div className="col-sm-5">
                                <CustumSelect
                                    addFlag={false}
                                    placeholder={"Select Option"}
                                    value={obj.value ? obj.value : ""}
                                    onChange={(value) => {
                                        let list = [...filters];
                                        let newList = [];
                                        list?.forEach((t) => {
                                            if (!newList.includes(t)) {
                                                newList.push(t);
                                            }
                                        });
                                        list = newList;
                                        list[index].value = value;
                                        // list[index].phase = "completed";
                                        setFilters(list);
                                    }}
                                    items={(() => {
                                        let arr = [];
                                        if (dateFilterArr) {
                                            dateFilterArr.forEach((one) => arr.push(one.label));
                                        }
                                        return arr;
                                    })()}
                                />
                            </div>
                            <div className="col-sm-7">
                                <AntdRangePicker
                                    inputReadOnly={false}
                                    madalOpenState={() => { return open }}
                                    onChangeDateRange={(datesFlag, datesString) => { 
                                        try {
                                            let start = new Date(new Date(datesString[0]));
                                            let end = new Date(new Date(datesString[1]));
                                            let arr = [];
                                            let date = start;
                                            while (date <= end) {
                                                let temp = dayjs(new Date(date)).format("MM-DD-YYYY");
                                                arr.push(temp);
                                                date.setDate(date.getDate() + 1);
                                            }
                                            let list = [...filters];
                                            list[index].date = arr;
                                            list[index].phase = datesFlag && datesFlag == "Clear" ? "middle" : "completed";
                                            setDateRange(list);
                                            setFilters(list);
                                        } catch (error) { }
                                    }}
                                    defaultStartDate={getDateRangePickerValue(index, 0)}
                                    defaultEndDate={getDateRangePickerValue(index, -1)}
                                    allEnd={getDateRangePickerValue(index, -1)}
                                    allStart={getDateRangePickerValue(index, 0)}
                                />

                                {/* <input style={{ height: '24px' }} type='date' onChange={(e) => {
                                  let list = [...filters];
                                  list[index].date = dayjs(new Date(e.currentTarget.value)).format('MM-DD-YYYY');
                                  list[index].phase = "completed";
                                  setFilters(list);
                              }} className='form-control form-control-sm' /> */}
                            </div>
                        </div>
                    </>
                );
            } else {
                return (
                    <CustumSelect
                        addFlag={false}
                        placeholder={"Select Option"}
                        value={obj.value ? obj.value : ""}
                        onChange={(value) => {
                            let list = [...filters];
                            let newList = [];
                            list?.forEach((t) => {
                                if (!newList.includes(t)) {
                                    newList.push(t);
                                }
                            });
                            list = newList;
                            list[index].value = value;
                            if (
                                list[index] &&
                                list[index].value !== "Before Date" &&
                                list[index].value !== "After Date" &&
                                list[index].value !== "Date Range"
                            ) {
                                list[index].phase = "completed";
                            }

                            setFilters(list);
                        }}
                        items={(() => {
                            let arr = [];
                            if (dateFilterArr) {
                                dateFilterArr.forEach((one) => arr.push(one.label));
                            }
                            return arr;
                        })()}
                    />
                );
            }
        } else if (types[obj.type] == "number") {
            if (obj.condition == "between" || obj.condition == "not between") {
                return (
                    <div className="row">
                        <div className="col">
                            <input
                                placeholder="from..."
                                style={{ height: "35px" }}
                                className="form-control form-control-sm"
                                type="number"
                                id={"checkboxes" + index}
                                value={obj.from}
                                onChange={(e) => {
                                    if (e.currentTarget.value) {
                                        let list = [...filters];
                                        list[index].from = e.currentTarget.value;
                                        list[index].phase = "completed";
                                        setFilters(list);
                                    } else {
                                        let list = [...filters];
                                        list[index].phase = "middle";
                                        list[index].to = e.currentTarget.value;
                                        list[index].from = e.currentTarget.value;
                                        setFilters(list);
                                    }
                                }}
                            />
                        </div>
                        <div className="col">
                            <input
                                placeholder="to.."
                                style={{ height: "35px" }}
                                className="form-control form-control-sm"
                                type="number"
                                id={"checkboxes" + index}
                                value={obj.value}
                                onChange={(e) => {
                                    if (e.currentTarget.value) {
                                        let list = [...filters];
                                        list[index].value = e.currentTarget.value;
                                        list[index].phase = "completed";
                                        setFilters(list);
                                    } else {
                                        let list = [...filters];
                                        list[index].phase = "middle";
                                        list[index].value = e.currentTarget.value;
                                        setFilters(list);
                                    }
                                }}
                            />
                        </div>
                    </div>
                );
            }
            if (obj.condition == "is") {
                return (
                    <div className="row">
                        <div className="col">
                            <CustomMultiSelect
                                setOpendDropDowns={setOpendDropDowns}
                                invertColor={invertColor}
                                filterIndex={index}
                                removeTile={removeTile}
                                opendDropdowns={opendDropdowns}
                                id={"checkboxesForNumbers" + index}
                                values={obj.value}
                                onChange={(checked, event, optionIdx) => {
                                    if (obj.value) {
                                        if (checked) {
                                            let list = [...filters];
                                            list[index].value.push(event);
                                            list[index].phase = "completed";
                                            setFilters(list);
                                        } else {
                                            let list = [...filters];
                                            list[index].value = list[index].value?.filter(
                                                (one) => one.name !== event.name
                                            );
                                            list[index].phase = "completed";
                                            if (list[index].value.length == 0) {
                                                list[index].phase = "middle";
                                            }
                                            setFilters(list);
                                        }
                                    } else {
                                        if (checked) {
                                            let list = [...filters];
                                            list[index].value = [event];
                                            list[index].phase = "completed";
                                            setFilters(list);
                                        } else {
                                            let list = [...filters];
                                            list[index].value = null;
                                            list[index].phase = "middle";
                                            setFilters(list);
                                        }
                                    }
                                }}
                                icon={obj.column_name}
                                options={getListOfNumbersForTypeNumber(obj)}
                            />
                        </div>
                    </div>
                );
            } else {
                return (
                    <input
                        placeholder="number.."
                        style={{ height: "35px" }}
                        className="form-control form-control-sm"
                        type={
                            obj.condition == "is empty" || obj.condition == "is not empty"
                                ? "hidden"
                                : "number"
                        }
                        id={"checkboxes" + index}
                        value={obj.value}
                        onChange={(e) => {
                            if (e.currentTarget.value) {
                                let list = [...filters];
                                list[index].value = e.currentTarget.value;
                                list[index].phase = "completed";
                                setFilters(list);
                            } else {
                                let list = [...filters];
                                list[index].phase = "middle";
                                list[index].value = e.currentTarget.value;
                                setFilters(list);
                            }
                        }}
                    />
                );
            }
        }
        //   else if (obj.type == "boolean") {
        //     if (obj.condition == "is" || obj.condition == "isnt") {
        //       return (
        //         <CustomMultiSelect
        //           setOpendDropDowns={setOpendDropDowns}
        //           invertColor={invertColor}
        //           opendDropdowns={opendDropdowns}
        //           filterIndex={index}
        //           removeTile={removeTile}
        //           id={"checkboxes" + index}
        //           values={obj.value}
        //           onChange={(checked, event, optionIdx) => {
        //             if (obj.value) {
        //               if (checked) {
        //                 let list = [...filters];
        //                 list[index].value.push(event);
        //                 list[index].phase = "completed";
        //                 setFilters(list);
        //               } else {
        //                 let list = [...filters];
        //                 list[index].value = list[index].value?.filter(
        //                   (one) => one.name !== event.name
        //                 );
        //                 list[index].phase = "completed";
        //                 if (list[index].value.length == 0) {
        //                   list[index].phase = "middle";
        //                 }
        //                 setFilters(list);
        //               }
        //             } else {
        //               if (checked) {
        //                 let list = [...filters];
        //                 list[index].value = [event];
        //                 list[index].phase = "completed";
        //                 setFilters(list);
        //               } else {
        //                 let list = [...filters];
        //                 list[index].value = null;
        //                 list[index].phase = "middle";
        //                 setFilters(list);
        //               }
        //             }
        //           }}
        //           icon={obj.column_name}
        //           options={
        //             obj.api_name == "Stop_Work_Order_Exists_on_Property"
        //               ? [
        //                   { name: "No", id: "no" },
        //                   { name: "Partial SWO Exists", id: "Partial SWO Exists" },
        //                   { name: "Full SWO Exists", id: "Full SWO Exists" },
        //                   { name: "N/A", id: "N/A" },
        //                 ]
        //               : obj.api_name == "Conjunction"
        //               ? [
        //                   { name: "(Blank)", id: "blank" },
        //                   { name: "N/A", id: "N/A" },
        //                   { name: "No", id: "no" },
        //                   ...ConjunctionApplicationNumbers,
        //                 ]
        //               : obj.api_name == "Construction_Classification_Code"
        //               ? [{ name: "N/A", id: "N/A" }, ...ConstructionClassfCode]
        //               : [
        //                   { name: "N/A", id: "N/A" },
        //                   { name: "Yes", id: "yes" },
        //                   { name: "No", id: "no" },
        //                 ]
        //           }
        //         />
        //       );
        //     } else {
        //       return (
        //         <input
        //           style={{ height: "35px" }}
        //           className="form-control form-control-sm"
        //           type={
        //             obj.condition == "is empty" || obj.condition == "is not empty"
        //               ? "hidden"
        //               : "text"
        //           }
        //           id={"checkboxes" + index}
        //           value={obj.value}
        //           onChange={(e) => {
        //             if (e.currentTarget.value) {
        //               let list = [...filters];
        //               list[index].value = e.currentTarget.value;
        //               list[index].phase = "completed";
        //               setFilters(list);
        //             } else {
        //               let list = [...filters];
        //               list[index].phase = "middle";
        //               list[index].value = e.currentTarget.value;
        //               setFilters(list);
        //             }
        //           }}
        //         />
        //       );
        //     }
        //   } else {
        //     return <></>;
        //   }
    }
    //main render
    return (
        <>
            <span style={{ backgroundColor: "#0194f7", cursor: "pointer" }}>
                <FontAwesomeIcon
                    onClick={() => {
                        if (filters.length == 0) {
                            setFilters([{ phase: "initial", module: "Deal" }]);
                        }
                        setRangeCalCloseFlag(true)
                        setOpen(true);
                    }}
                    color="white"
                    icon={faFilter}
                />
                {filters?.filter(
                    (one) =>
                        one?.phase == "completed" ||
                        one?.Group?.filter((g) => g?.phase == "completed").length > 0
                ).length > 0 ||
                    (activeFiltersOnColumnLevel
                        ? Object.keys(activeFiltersOnColumnLevel).length > 0
                        : false) ? (
                    <>
                        <span
                            style={{ backgroundColor: "white" }}
                            className="badge badge-light"
                        >
                            {" "}
                            {(() => {
                                let mainFilters = filters?.filter(
                                    (one) =>
                                        one?.phase == "completed" ||
                                        one?.Group?.filter((g) => g?.phase == "completed").length >
                                        0
                                ).length;
                                return (
                                    mainFilters +
                                    (activeFiltersOnColumnLevel
                                        ? Object.keys(activeFiltersOnColumnLevel).length
                                        : 0)
                                );
                            })()}
                        </span>
                        <FontAwesomeIcon
                            onClick={() => {
                                setActiveFilterAndGetActiveFilters({}, "SET", []);
                                setFilters([{ phase: "initial" }]);
                                setFilterTrigger(!FilterTrigger)
                            }}
                            color="white"
                            icon={faTimes}
                        />
                    </>
                ) : (
                    <></>
                )}
            </span>
            <Modal
                open={open}
                draggable
                title="Filters"
                maskClosable={false}
                width={900}
                className="antModalBody taskFilterModal"
                style={{ top: 170, borderRadius: 50 }}
                onCancel={() => {
                    setOpendDropDowns("");
                    setRangeCalCloseFlag(!open)
                    setOpen(false);
                    setModalPosition({ x: 0, y: 35 })
                }}
                modalRender={(modal) => (
                    <Draggable
                      defaultPosition={modalPosition}
                      position={{x:modalPosition.x, y: modalPosition.y}}
                      onStop={handleDrag}
                    >
                      <div>{modal}</div>
                    </Draggable>
                  )}
                footer={[
                    <div
                        onMouseLeave={() => setAddGroupVisibility("none")}
                        onMouseEnter={() => setAddGroupVisibility("inline")}
                        className="col-sm-12 task-filter-buttons"
                    >
                        <span className="Error-Msg-For-Filter">
                            {errorMsg && !isInitialStateOfFilter ? errorMsg : <></>}
                        </span>
                        <Button
                            style={{ display: "inline" }}
                              onClick={() => {
                                let shouldAdd = shouldWeAddFilters(filters, true);
                                if (!shouldAdd) {
                                  setErrorMsg("Please complete above filter.");
                                  return;
                                } else {
                                  setErrorMsg(null);
                                }
                                const filterList = [...filters];
                                filterList.push({
                                  phase: "middle",
                                  logic: "AND",
                                  module: "Deal",
                                  Group: [{ phase: "initial", logic: "AND", module: module }],
                                });
                                
                                setFilters(filterList);
                              }}
                            key="submit"
                            type="primary"
                        >
                            Add Group +
                        </Button>
                        <Button
                            onClick={() => {
                                let shouldAdd = shouldWeAddFilters(filters, true);
                                if (!shouldAdd) {
                                  setErrorMsg("Please complete above filter.");
                                  return;
                                } else {
                                  setErrorMsg(null);
                                }
                                const filterList = [...filters];

                                filterList.push({
                                    phase: "initial",
                                    logic: "AND",
                                    module: "Task",
                                });

                                setFilters(filterList);
                            }}
                            key="submit"
                            type="primary"
                        >
                            Add Filter +
                        </Button>
                    </div>,
                ]}
            >
                {filters.map((one, index) => {
                    if (one?.phase == "initial") {
                        return initialPhaseInput(index, one);
                    }
                    if (one?.phase == "middle" || one?.phase == "completed") {
                        return middlePhaseInput(index, one);
                    }
                })}
                {getColumnFilterIfExist(activeFiltersOnColumnLevel)}
            </Modal>
        </>
    );
}
