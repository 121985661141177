import { faCircle, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const OptionCards = ({setInputField,inputField}) => {
  const [options, setOptions] = useState(inputField.ExtraData?inputField.ExtraData:[]);
  const [newOption, setNewOption] = useState('');
  const [option,setOption]= useState({ id: "", text: "" })
  const handleAddOption = () => {
    if (newOption.trim()) {
      const newId = `option-${options.length + 1}`;
      setOptions([...options, { id: newId, text: newOption }]);
      setNewOption(''); // Clear input after adding
    }
  };
  useEffect(e=>{
    setInputField({...inputField,"ExtraData":options})
  },[options])
  const handleOnDragEnd = (result) => {
    if (!result.destination) return; // Dropped outside

    const newOptions = Array.from(options);
    const [movedItem] = newOptions.splice(result.source.index, 1);
    newOptions.splice(result.destination.index, 0, movedItem);

    setOptions(newOptions);
  };
  function onDeleteCard(option) {
    setOptions(options.filter(o=>o.id!=option.id));
  }
  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <div
            className='Option-Wrapper'
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {options?.map((option, index) => (
              <Draggable key={option.id} draggableId={option.id} index={index}>
                {(provided) => (
                  <div
                    className='Card'
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <FontAwesomeIcon style={{ marginRight: '7px' }} icon={faCircle} />
                    {option.text}
                    <FontAwesomeIcon className={"Close-Icon"} onClick={(e)=>{
                      onDeleteCard(option)
                    }} color={"red"} style={{ marginRight: '7px' }} icon={faTimes} />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder} {/* Placeholder for the drag effect */}
            <div style={{ minWidth: 'max-content' }} className='Card'>
              <input
                type="text"
                value={newOption}
                onChange={(e) => setNewOption(e.target.value)}
                placeholder="Type option name"
              />
              <Button icon={<FontAwesomeIcon icon={faPlus}/>} onClick={handleAddOption}>
              </Button>
            </div>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default OptionCards;
