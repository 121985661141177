import { CaretRightOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Collapse, theme } from 'antd';
import React, { useEffect, useState } from 'react'

export const CustomeCollapse = ({ items, defaultOpen, rootClassName }) => {
  const { token } = theme.useToken();
  const [tabItems, setTabItems] = useState([]);
  const [activeKey,setActiveKey] = useState();
  const panelStyle = {
    marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: 'none',
  };
  const [buttonClicked, setButtonClicked] = useState(false);

  const handleButtonClick = (e) => {
    setButtonClicked(true); // Track that the button was clicked
    // Logic to open your popover goes here
  };

  const handlePanelChange = (key) => {
    //  
    if (buttonClicked) {
      setButtonClicked(false); // Reset button click state
      return; // Prevent toggling due to button click
    }
    setActiveKey(key)
    // Logic for panel change can go here if needed
  };
  useEffect(e => {
    if (items && items.length > 0) {
      let arr = items.map((d,i) => {
        d["style"] = panelStyle;
        d["key"] = i;
        if (d.rightComponent) {
          d["extra"] = <div onClick={handleButtonClick}>{d.rightComponent}</div>;
        }
        // extra: <Button type="primary" onClick={handleButtonClick}>Action</Button>
        return d;
      })
      setTabItems(arr);
    }
  }, [items])
  return (
    <Collapse
      bordered={false}
      defaultActiveKey={defaultOpen}

      activeKey={activeKey}
      expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
      style={{
        background: token.colorBgContainer,
      }}
      items={tabItems}
      rootClassName={rootClassName}
      onChange={handlePanelChange}
    />
  );
}
