import React, { useState, useEffect, useRef, useContext, createContext } from "react";
import { Switch, Route } from "react-router-dom";
import {
  Navbar,
  Nav,
  NavDropdown,
  Form,
  FormControl,
  Dropdown,
  FormGroup,
  Row,
  Col,
  Tabs,
  Tab,
} from "react-bootstrap";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  UncontrolledDropdown,
} from "reactstrap";
import logo from "./assets/images/logo.png";
import { useHistory, useLocation } from "react-router-dom";
import { Popover as AntdPopover, Collapse } from "antd";
import TimePicker from "react-time-picker/dist/entry.nostyle";
import "./common/header/header.css";
import { getWorkingHoursAndMinutes ,formateNoInDecimalString , getDateFormate, getDeviceId, convertToUSTime, modifyTimezoneString, checkDeviceIdCookie, checkEqualDatesAndTime, AllPaths, convertToUSDate} from "./components/HR/TimeClock/Util";
import Config from './config.json'
import "./common/style.css";
import {
  faBell,
  faCheck,
  faChevronCircleDown,
  faCircle,
  faClock,
  faCog,
  faComment,
  faExclamationTriangle,
  faEye,
  faFilter,
  faMinus,
  faPauseCircle,
  faPlayCircle,
  faPlusCircle,
  faSearch,
  faSignOutAlt,
  faSlidersH,
  faStopCircle,
  faStopwatch,
  faTrashAlt,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SubHeader from "./common/subheader/SubHeader";
import DealList from "./components/Deal/DealList";
import axios from "axios";
import Createdeal from "./components/Deal/createdeal/createdeal";
import ClientList from "./components/Accounts/ClientList";
import CreateClient from "./components/Accounts/CreateClient";
import attendance from "./common/util/AttendenceService";
import moment from "moment";
import 'moment-timezone';
import swal from "sweetalert";
import { UncontrolledCollapse, UncontrolledTooltip } from "reactstrap";
import ChatModule from "./common/chat/chat";
import Body from "./common/body/body";
import HRTimeClock from "./components/HR/TimeClock/HR";
import DocumentTree from "./components/Documents/DocumentTree";
import "bootstrap/dist/js/bootstrap";
import Setting from "./components/Setting/Setting";
import Users from "./components/Users/Users";
import VendorList from "./components/Vendors/vendorList";
import CreateVendor from "./components/Vendors/createVendor/createVendor";
import "bootstrap/dist/css/bootstrap.min.css";
import UserService from "./common/util/UserService";
import "bootstrap/js/src/dropdown";
import ProjectTasks from "./components/ProjectTasks/ProjectTasks";
import ClientBody from "./components/Accounts/ClientBody";
import TaskService from "./common/util/TaskService";
import "./App.css";
import CustumSelect from "./common/SelectWithSearch";
import ModuleService from "./common/util/ModuleService";
import TaskModal from "./tabs/Task/taskModal";
import _ from "lodash";
import TaskGlobalSearch from "./common/TaskGlobalSearch/TaskGlobalSearch";
import NotificationAPI from "./common/util/NotificationAPI";
import { useSelector } from "react-redux";
import { getTimerSelector } from "./redux/slices/timerSlice";
import queryString from "query-string";
import config from "./config.json";
import GetRecordById from "./common/util/getRecordById";
import Reminder from "./components/Reminder/Reminder";
import FullCalendar from "./components/Calendar/Calendar";
import Email from "./components/EmailSection/email";
import AddColumn from "./common/AddColumn/add_column";

import remIcoCutom from "./assets/images/reminder_icon_custom.svg";
import { Button as AntdButton, Modal as AntdModal,Menu,Dropdown as AntDropDown,Switch as AntSwitch} from "antd";
import CalendarAPI from "./common/util/CalendarAPI";
import Loader from "./common/loader";
import { AllAccounts, AllDeals ,AllNotificationsForChat, AllPipelines, CacheReminderData, CustomeRefreshButton, DealStatusOptions, GlobalRefresh, Socket, forwardObject, refershModule } from "./Context/AppActions";
import AppContext from "./Context/AppContext";
import { OtsideClickClosingWrapper } from "./common/EmojiReactionPicker/OtsideClickClosingWrapper";
import ClockInTime from "./ClockInTime";
import { getSocket } from "./Socket";
import AwsServerService from "./common/util/AwsServerService";
import dayjs from "dayjs";
import { ReminderListPopUp } from "./components/ReminderPopUp/ReminderListPopUp";
import { log } from "mathjs";
import ReminderSvg from '../src/assets/images/svg/AlarmReminder.svg';
import EmailReminder from "./components/EmailSection/EmailReminder";
import { Reminders } from "./components/ReminderPopUp/Reminders";
import SettingIcon from '../src/assets/images/svg/SettingIcon.svg';
import { CheckOutlined, CloseOutlined, LeftOutlined } from "@ant-design/icons";
import TaskBlueprints from "./components/TaskBluePrints/TaskBlueprints";
import FieldService from "./common/util/FieldService";
import { getCriteriaFieldsAndObjects } from "./Util/CommonUtil";
import { getStatusOptions } from "./tabs/Task/TaskUtil";

String.prototype.toHHMMSS = function () {
  var sec_num = parseInt(this, 10); // don't forget the second param
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - hours * 3600) / 60);
  var seconds = sec_num - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  return hours + ":" + minutes + ":" + seconds;
};
let timeTrackInitialTime = new Date(Date.now());
const { Panel } = Collapse;
const { SubMenu } = Menu;

export const AppDataRequired = createContext();

function App() {
  const [appDataContext, setAppDataContext] = useState({
    AllUsers:[],
    DealStatus:[],
    AllDeals:[],
    DealFields:[],
    AllTasks:[],
    CriteriaData:[],
    TaskFlows:[]
  });
  const [cacheAllDeals,setCacheAllDeals]=useState([]);
  const AppData = useContext(AppContext);
  const { dispatch ,state} = AppData || {};
  const socket = getSocket();
  const entry=useRef(null);
  const backendUrl = config.backend_url;
  const [openChatModal, setOpenChatModal] = useState(false);
  // const [isReminderPopupOpen, setIsReminderPopupOpen] = useState({status:false,email:null});
  // const [reminderData, setReminderData] = useState({});
  const [openReminderListModal, setOpenReminderListModal] = useState({
    status:false,data:""
  });
  const [reminderFlag, setReminderFlag] = useState(false);
  const [token, setToken] = useState(null);
  const [tokenTime, setTokenTime] = useState(new Date(Date.now()));
  const [open,setOpen]=useState(false)
  // const [timer, setTimer] = useState(0);
  const [refreshTimeclock, setRefreshTimeclock] = useState(false);
  const [appRefreshed, setAppRefreshed] = useState(false);
  const [reminderNotify, setReminderNotify] = useState(false);
  const [reminderNotifyMsg, setReminderNotifyMsg] = useState("");
  const [notificationEvent, setNotificationEvent] = useState("");
  const [helperOpsData,setHelperOpsData]=useState([]);
  const [notificationEventDateTime, setNotificationEventDateTime] = useState(
    new Date()
  );
  const [allClients, setAllClients] = useState([]);
  const [filterTaskList, setFilterTaskList] = useState([]);
  const [cacheDeals, setCacheDeals] = useState([]);
  const [tasklist, setTaskList] = useState([]);
  const [cachePipeline, setCachePipeline] = useState([]);
  const [filterDeal, setFilterDeals] = useState([]);
  const [filterPipeline, setFilterPipeline] = useState([]);
  const [clockisActive, setClockIsActive] = useState(false);
  const [timeTrackKey, setTimeTrackKey] = useState("timer");
  const [timerTimeTrack, setTimerTimeTrack] = useState(0);
  const [timerPrevTimeTrack, setTimerPrevTimeTrack] = useState(0);
  const [timeTrackInterv, setTimeTrackInterv] = useState(0);
  const [timeTrackClock, setTimeTrackClock] = useState(false);
  const [searchInputData, setSearchInputData] = useState({});
  const [taskData, setTaskData] = useState({});
  const [openTaskModal, setOpenTaskModal] = useState(false);
  const [dealObj, setDealObj] = useState({});
  const [timerObj, setTimerObj] = useState(null);
  const [runningTaskLabel, setRunningTaskLabel] = useState();
  const clockInterval = useRef(null);
  const [currentUser, setCurrentUser] = useState(null);
  const userInfo = JSON.parse(localStorage.getItem("userinfo"));
  const [FromRange, setFromRange] = useState(null);
  const [ToRange, setToRange] = useState(null);
  const [timeLogDate, setTimeLogDate] = useState(null);
  const [timeLogDeleteWarn, setTimeDeleteWarn] = useState(false);
  const [deleteTimeLogObj, setDeleteTimeLogObj] = useState({});
  const [notificationList, setNotificationList] = useState([]);
  const [chatnotificationList, setchatnotificationList] = useState([]);
  const [notifyTabKey, setNotifyTabKey] = useState("Unarchive");
  const [notificationCacheList, setNotificationCacheList] = useState([]);
  const [messageData, setMessageData] = useState({});
  const [loader, setLoader] = useState(false);
  const [warningFlag, setWarningFlag] = useState(false);
  const [showExitPrompt, setShowExitPrompt] = useState(false);
  const [notificationFilter, setNotifcationFilter] = useState([
    { name: "TASK", isVisible: true, isSearch: true , settingObj:{active:true,lastDisableTime:null,lastActiveTime:null} },
    { name: "MENTION", isVisible: true, isSearch: true, settingObj:{active:true,lastDisableTime:null,lastActiveTime:null} },
    { name: "NOTES", isVisible: true, isSearch: true ,settingObj:{active:true,lastDisableTime:null,lastActiveTime:null}},
    { name: "ASSIGNEE", isVisible: true, isSearch: true ,settingObj:{active:true,lastDisableTime:null,lastActiveTime:null}},
    { name: "REMINDER", isVisible: true, isSearch: true ,settingObj:{active:true,lastDisableTime:null,lastActiveTime:null}},
    { name: "MESSAGE", isVisible: true, isSearch: true ,settingObj:{active:true,lastDisableTime:null,lastActiveTime:null}},
    { name: "ATTACHMENT", isVisible: true, isSearch: true,settingObj:{active:true,lastDisableTime:null,lastActiveTime:null} },
  ]);
  const [checkType, setCheckType] = useState({ type: "" });
  const [tokenObj, setTokenObj] = useState(null);
  const [selectedIds, setSelectedIds] = useState([]);
  const [deleteDealFlag, setDeleteDealFlag] = useState();
  const [refreshEnable, setRefreshEnable] = useState(false);
  const [filterObj, setFilterObj] = useState({
    All: true,
    Assignee: true,
    Mention: true,
    Task: true,
    Notes: true,
    Reminder: true,
  });
  const [taskAction, setTaskAction] = useState("");
  const [allUsers, setAllUsers] = useState([]);
  const [rendering, setRendering] = useState(true);
  const chatButtonRef = useRef();
  const [refreshTheChat,setRefreshChat] = useState(false);
  const entrytwo=useRef(null);
  const [allReminderData,setAllReminderData] = useState([]);
  const [triggeredReminders,setTriggeredReminders] = useState([]);
  const reminderDataTemplate={
    "At specific time":{
      active:[],
      inactive:[],
    },
    "On every reply":{
      active:[],
      inactive:[],
    },
    "If no replies until":{
      active:[],
      inactive:[],
    },
  }
  const moduleApiNameForSubform = ["WWP_Violations", "Previous_Solar_Info"];
  const customLinkFields = [
    {
      "field": "bis_link",
      "id": "101",
      "isChecked": false,
      "isDragOver": false,
      "isSearch": true,
      "name": "Bis Link",
      "type": 'link',
    },
    {
      "field": "zola_link",
      "id": "102",
      "isChecked": false,
      "isDragOver": false,
      "isSearch": true,
      "name": "Zola Link",
      "type": 'link',
    },
    {
      "field": "taxmap_link",
      "id": "103",
      "isChecked": false,
      "isDragOver": false,
      "isSearch": true,
      "name": "Tax Map Link",
      "type": 'link',
    },
    {
      "field": "acris_link",
      "id": "104",
      "isChecked": false,
      "isDragOver": false,
      "isSearch": true,
      "name": "Acris Link",
      "type": 'link',
    }
  ]
  const firstArr = [
    "Deal_Name",
    "Closing_Date",
    "Account_Name",
    "Project_Address",
    "Project_Status",
    "House_Number",
    "Street_Name",
    "City",
    "State",
    "Zip_Code",
    "Latitude",
    "Longitude",
    "Is_NYC_Project",
    "Borough",
    "Service_Requested_Name",
    "Block_Number",
    "Lot_Number",
    "Bin_Number",
    "Community_Board_Number",
    "Occupancy_Classification_Building_2014_2008_Code",
    "Occupancy_Classification_of_Building",
    "Construction_Classification_Code_2014_2008_Code",
    "Construction_Classification_Code",
    "Multiple_Dwelling_Classification",
    "Number_of_Dwelling_Units",
    "Building_Height",
    "Number_of_Stories",
    "Zoning_District",
    "Commercial_Overlay",
    "Specials_Districts",
    "Zoning_Map_Number",
    "Tidal_Wetlands",
    "Fresh_Water_Wetland",
    "Flood_Hazard",
    "Coastal_Erosion_Hazard",
    "Special_Fire_District",
    "Landmark_Area",
    "Environmental_Restriction",
    "Conjunction",
    "Conjunction_Application_Number",
    "Stop_Work_Order_Exists_on_Property",
    "Active_Work_Without_Permit_Violations",
    "Asbestos_Required",
    "ACP5_Completed",
    "Asbestos_Inspector_Certificate_Number",
    "ACP5_Control_Number",
    "Certificate_of_Occupancy_Available",
    "NB_Application_Number",
    "Owner_Type",
    "Property_Owner_s_Name",
    "Owner_s_First_Name",
    "Owner_s_Middle_Initial",
    "Owner_s_Last_Name",
    "Owner_Phone_Number",
    "Owner_s_Email_Address",
    "Owner_s_Mailing_Address_Diff_Than_Property_Address",
    "Owner_s_Mailing_Address",
    "Owner_s_Street_Name",
    "Owner_s_House_Number",
    "Owner_s_City",
    "Owner_s_State",
    "Owner_s_Zip_Code",
    "Non_Profit",
    "DHCR",
    "Other_Owner_Company_Name",
    "Other_Owner_Mailing_Address",
    "Other_Owner_Street_Name",
    "Other_Owner_House_Number",
    "Other_Owner_City",
    "Other_Owner_State",
    "Other_Owner_Zip_Code",
    "Other_Owner_Officer_First_Name",
    "Other_Owner_Officer_Middle_Initial",
    "Other_Owner_Officer_Last_Name",
    "Other_Owner_Officer_Title",
    "Other_Owner_Phone_Number",
    "Other_Owner_Email_Address",
    "Condo_Company_Name",
    "Condo_Mailing_Address",
    "Condo_Street_Name",
    "Condo_House_Number",
    "Condo_City",
    "Condo_State_Name",
    "Condo_Zip_Code",
    "Condo_Officer_First_Name",
    "Condo_Officer_Middle_Initial",
    "Condo_Officer_Last_Name",
    "Condo_Officer_Title",
    "Condo_Phone_Number",
    "Condo_Email_Address",
    "Applicant",
    "General_Contractor",
    "Electrician",
    "Number_of_Interconnection",
    "Total_System_Size",
    "Total_Modules",
    "PV_Interconnection",
    "Project_Cost",
    "Owner",
    "Created_By",
    "Created_Time",
    "Modified_By",
    "Modified_Time",
  ];
  const [allReminderDataObject,setAllReminderDataObject] = useState({});
  const UpdateReminder=(arr,isMultiple)=>{
    AwsServerService.UpdateReminders(arr,isMultiple).then(res=>{
       
      console.log(res);
    }).catch(e=>{
        console.log(e);
    })
  }
  function reminderSchedular(flag,data){
    // if(!flag){
    //   if(allReminderData){
    //     let reminderFound=allReminderDataObject["At specific time"]?.active?.find(data=>checkEqualDatesAndTime(data.reminderTime,dayjs().toISOString()) && data.status=="false" && data.reminderType!="On every reply");
    //     let reminderForReplyTill=allReminderDataObject["If no replies until"]?.active?.find(data=>checkEqualDatesAndTime(data.reminderTime,dayjs().toISOString()) && data.status=="false" && data.reminderType!="On every reply");
    //     let updateArr=[]
    //     if(reminderFound){
    //      // setTriggeredReminders([reminderFound])
    //       updateArr.push({ROWID:reminderFound.ROWID,triggered:"true"})
    //       // swal({
    //       //   title: 'Reminder!',
    //       //   text: reminderFound.reminderNote?reminderFound.reminderNote:"You have reminder on a email!",
    //       //   icon: 'info',
    //       //   buttons: {
    //       //     complete: {
    //       //       text: 'Complete',
    //       //       value: 'complete',
    //       //     },
    //       //     snooze: {
    //       //       text: 'Snooze',
    //       //       value: 'snooze',
    //       //     },
    //       //   },
    //       // }).then((value) => {
    //       //   switch (value) {
    //       //     case 'complete':
    //       //       //
    //       //       break;
    //       //     case 'snooze':
    //       //       //
    //       //       break;
    //       //     default:
    //       //       // Handle default case or do nothing
    //       //       break;
    //       //   }
    //       // });
    //      // swal("Reminder!",reminderFound.reminderNote?reminderFound.reminderNote:"You have reminder on a email!","info");
    //     }
    //     if(reminderForReplyTill){
    //       updateArr.push({ROWID:reminderForReplyTill.ROWID,triggered:"true"})
    //     }
    //     if(updateArr.length>0){
    //       UpdateReminder(updateArr,true);
    //       setOpenReminderListModal({status:true,data:null})
    //     }
    //   }
    // }else 
     
    if(flag=="On every reply"){
      // UpdateReminder([{ROWID:data.ROWID,triggered:"true"}],true)
      fetchReminders();
      setOpenReminderListModal({status:true,data:null})
    }else if(flag=="Trigger From Server"){
      let userinfo = JSON.parse(localStorage.getItem("userinfo"));
      fetchReminders();
      if(data && data?.find(r=>r.attendees.includes(userinfo.email_id))){
        setOpenReminderListModal({status:true,data:null});
      }
    }
  }
  function modifyReminders(data){
    let arr={...reminderDataTemplate}
    let triggerArray=[]
    data.map(d=>{
      if(d.triggered=="true" && d.status!="true" && d.attendees?.includes(userInfo?.email_id)){
        triggerArray.push(d);
      }
      let obj=arr[d.reminderType];
      if(obj){
        if(d.status!="true"){
          obj.active?.push(d);
        }else{
          obj.inactive?.push(d);
        }
      }
    })
    triggerArray=triggerArray?.sort((a,b)=>new Date(b)-new Date(a));
    setTriggeredReminders(triggerArray);
    setAllReminderDataObject(arr);
  }
  async function fetchReminders(){
    let user=JSON.parse(localStorage.getItem("userinfo"));
    await AwsServerService.GetReminders().then(res=>{
      // if(res?.data && res.data.length>0){
        setAllReminderData(res.data);
        dispatch(CacheReminderData(res.data));
        res.data = res.data?.filter(d=>d?.attendees?.includes(user?.email_id));
        modifyReminders(res.data);
        res.data = res.data?.sort((a, b) => new Date(b.reminderTime) - new Date(a.reminderTime));
     // }
    }).catch(e=>{
      console.log(e);
    })
  }
  async function fetchHelperOpsData(){
     
    await AwsServerService.GetHelperOps().then(res=>{
      setHelperOpsData(res.data);
      let tempNotificationFilters=res?.data?.find(d=>d.module=="NotificationFilter" && d.userId==userInfo.email_id);
      if(tempNotificationFilters && tempNotificationFilters.jsonData){
        // let arr=[...notificationFilter,...tempNotificationFilters.jsonData];
        setNotifcationFilter(tempNotificationFilters.jsonData);
      }
    }).catch(e=>{
      console.log(e);
    })
  }
  
  useEffect(e=>{
    fetchReminders();
    getStatusOptions().then(res=>{
      dispatch(DealStatusOptions(res));
    })
    setAppRefreshed(true);
    fetchHelperOpsData()
  },[])
  // useEffect(() => {
  //   if(allReminderData && allReminderData.length>0){
  //     const interval = setInterval(() => {
  //       const currentDate = new Date();
  //       const currentMinute = currentDate.getSeconds();
  //       if (currentMinute === 0) {
  //         reminderSchedular()
  //       }
  //     }, 1000);
  //     return () => clearInterval(interval);
  //   }
  // }, [allReminderData]);

  useEffect(e=>{
    if(refreshEnable){
      dispatch(CustomeRefreshButton(true));
    }else{
      dispatch(CustomeRefreshButton(false));
    }
  },[refreshEnable])
  const [clientListStatus, setClientListStatus] = useState({
    EditColumns: undefined,
    SaveAsView: undefined,
    ActiveTab: undefined,
    ExcelDownload: undefined,
    ShouldSave: false,
  });
  const clientListTriggers = (data) => {
    if (data.type == "EditColumns") {
      setClientListStatus({
        ...clientListStatus,
        EditColumns: data.status ? data.status : !clientListStatus.EditColumns,
      });
    } else if (data.type == "SaveAsView") {
      setClientListStatus({
        ...clientListStatus,
        SaveAsView: data.status,
      });
    } else if (data.type == "SetActiveTab") {
      setClientListStatus({
        ...clientListStatus,
        ActiveTab: data.status,
      });
    } else if (data.type == "downloadExcel") {
      setClientListStatus({
        ...clientListStatus,
        ExcelDownload: data.status,
      });
    } else if (data.type == "ShouldSave") {
      setClientListStatus({
        ...clientListStatus,
        ShouldSave: data.status,
      });
    }
  };


  //Hrushikesh shelke new code starts
  const [dealListStatus, setDealListStatus] = useState({
    EditColumns: undefined,
    SaveAsView: undefined,
    ActiveTab: undefined,
    ExcelDownload: undefined,
  });
  const dealListTriggers = (data) => {
    if (data.type == "EditColumns") {
      setDealListStatus({
        ...dealListStatus,
        EditColumns: data.status ? data.status : !dealListStatus.EditColumns,
      });
    } else if (data.type == "SaveAsView") {
      setDealListStatus({
        ...dealListStatus,
        SaveAsView: data.status,
      });
    } else if (data.type == "SetActiveTab") {
      setDealListStatus({
        ...dealListStatus,
        ActiveTab: data.status,
      });
    } else if (data.type == "downloadExcel") {
      setDealListStatus({
        ...dealListStatus,
        ExcelDownload: data.status,
      });
    }
  };
  const getTasksAndSubtasks = async (data,task) => {
    const result = []; 
    const taskMap = new Map();
    const subtaskMap = new Map(); 
    data?.forEach(pipeline => {
      pipeline.Tasks?.forEach(task => { 
        taskMap.set(task.TaskROWID, task);
        result.push({
          label: task.TaskName,
          value: `Tasks_${task.TaskROWID}`
        }); 
        if (task.SubTasks) {
          task.SubTasks?.forEach(subtask => {
            subtaskMap.set(subtask.SubTaskROWID,subtask)
            result.push({
              label: subtask.TaskName,
              value: `SubTasks_${subtask.SubTaskROWID}`
            });
          });
        }
      });
    }); 
    if(taskMap){
      await getCriteriaFieldsAndObjects(state.AllDeals,Array.from(taskMap).map(a=>a[1])).then(res=>{
        let contextState={...appDataContext,AllTasks:Array.from(taskMap).map(a=>a[1]),CriteriaData:res};
        // contextState["CriteriaData"]=res;
        console.log("App js---",contextState);
        setAppDataContext(contextState);
        
      }).catch(e=>{
        console.log(e);
      })
    }   
    return result; 
  };
  useEffect(e=>{
    if(state.AllPipelines && state.AllDeals){
      getTasksAndSubtasks(state.AllPipelines,"tasks")
    }
  },[state.AllPipelines,state.AllDeals])
  useEffect(e=>{
    // let ob={...appDataContext,AllDeals:cacheAllDeals};
    // setAppDataContext(ob);
    dispatch(AllDeals(cacheAllDeals))
  },[cacheAllDeals])
  useEffect(e=>{
    const backendUrl = Config.backend_url+"getAllDealPages";
        var config = {
            'token': localStorage.getItem('token')
    };
    axios.post(backendUrl, {...config})
    .then((res)=> {
       
      if(res.data.data){
        // console.log("all deals----------",res.data.data);
        
        setCacheAllDeals(res.data.data);
      }
    }).catch(e=>{

    })
    AwsServerService.getAllPipeline().then(res => {
      // console.log("------p", res);
      if (res) {
        dispatch(AllPipelines(res))
      }
    })
    ModuleService.GetModuleList("Accounts")
      .then((result) => {
        if (result) {
          dispatch(AllAccounts(result.data))
        }
      })
      .catch((err) => {
        console.log(err);
      });
      AwsServerService.getAllTaskFlows().then(res=>{
        const object = res?.reduce((acc, currentItem) => {
          acc[currentItem.ROWID] = currentItem;
          return acc;
        }, {});
        setAppDataContext({...appDataContext,TaskFlows:object})
      }).catch(err=>{
        console.log(err);
      })
  },[tokenObj])
  const [sidebarCardsStatus, setSidebarCardsStatus] = useState({
    Preliminary: false,
    NycProject: false,
    Homeowner: false,
    Permitting: false,
    PVSystem: false,
  });
  const saveCancelButtonRef = useRef();
  const clickActionOfSaveAndCancelButton = (d) => {
    saveCancelButtonRef.current.saveButtonClick();
  };
  const toggelActiveBar = (d) => {
    saveCancelButtonRef.current.toggelActiveBar(d);
  };
  //Hrushikesh shelke new code ends

  const [allTask, setAllTasks] = useState([]);
  const [allJobs, setAllJobs] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);
  const [lastEntry, setLastEntry] = useState(null);
  const [timeTrackLogs, setTimeTrackLogs] = useState([]);

  const [routesParams, setRoutesParams] = useState({});
  let history = useHistory();
  let location = useLocation();
  location.state = routesParams;
  // new code start
  // const setTimeclockOnRefresh=async ()=>{
  //   await attendance.GetAttendenceAllUser(0, 0, 0)
  //    .then((result) => {
  //      result?.data?.data?.forEach(obj=>{
  //        if((obj?.Attendance?.checkIn!="-"||obj?.Attendance?.checkIn!="")
  //        && (obj?.Attendance?.checkOut=="-"||obj?.Attendance?.checkOut=="") && obj?.Attendance?.email == userInfo?.email_id  ){
  //          let object = {
  //            in: obj.Attendance.checkIn,
  //            ROWID: obj.Attendance.ROWID
  //          };
  //          entry.current="timeClockRefreshed";
  //          setLastEntry(object);
  //          const lastEntryIn = new Date(obj.Attendance.checkIn);
  //          const now = new Date();
  //          const startingTime = moment(lastEntryIn, "HH:mm:ss");
  //          const timeDifferenceInSeconds = Math.round((now - startingTime) / 1000);
  //          setTimer((timer)=>timer + timeDifferenceInSeconds);
  //          setClockIsActive(true);
  //          clockInterval.current = setInterval(() => {
  //          setTimer((timer) => timer + 1);
  //        }, 1000);
  //        }
  //      })
  //      })
  //      .catch((err)=>{
  //        console.log(err);
  //      })
  //    }
  //    useEffect(()=>{
  //     if(entrytwo.current!="loaded" && userInfo!=null){
  //       setTimeclockOnRefresh();
  //       entrytwo.current="loaded";
  //     }
  //    },[userInfo])
   useEffect(e=>{
    socket.on("Refresh_Reminder_List",(data)=>{
      pullNotifications();
      fetchReminders();
    })
    socket.on("refresh_activity",(data)=>{
      dispatch(refershModule({module:"task-activity",data:data}))
    })
 
    socket.on("refresh_task",(data)=>{
      dispatch(refershModule({module:"View-Task",data:data}))
    })
    socket.on("Refresh_Module",(data)=>{
      if(data?.module=="NotificationFilter" && data?.userId==userInfo.email_id){
        fetchHelperOpsData();
      }
    })
    socket.on("Check_Reply_Reminder",(data)=>{
      pullNotifications();
      reminderSchedular("On every reply",data)
    })
    socket.on("Reminders_Notification",(data)=>{
       
      pullNotifications();
      reminderSchedular("Trigger From Server",data)
    })
    socket.on("Refresh_Calendar",(data)=>{
      // pullNotifications();
      // reminderSchedular("Trigger From Server",data)
      if(data && data?.attendees?.find(a=>a.email==userInfo.email_id)){
        dispatch(refershModule({module:"Calendar",data:data}))
      }
    })
    socket.on("Refresh_Module",(data)=>{
      if(data){
        dispatch(refershModule({module:data}))
      }
    })
   },[])
   useEffect(e=>{
     
    try{
      if(state?.socket?.trigger && !state?.socket?.data){
        socket.emit(state.socket.trigger,"")
      }else if(state?.socket?.trigger && state?.socket?.data){
        if(state?.socket?.trigger=="Check_Reply_Reminder"){
          let activData=[...allReminderData];
          if(activData && activData.length>0){
            let isReply = activData.find(d=>d.messageId==state?.socket?.data && d.reminderType=="On every reply" && d.status=="false");
            if(isReply){
              socket.emit(state?.socket?.trigger,isReply);
            }
          }
        }else{
          socket.emit(state?.socket?.trigger,state?.socket?.data);
        }
      }
    }catch(e){
      console.log(e);
    }
    dispatch(Socket(null))
  },[state?.socket])
  useEffect(
    (e) => {
      if (history && history.replace) {
        var currentURL = window.location.href;

        // Check if the previous entry in the history is the same as the current URL
        if (history.state && history.location.pathname === currentURL) {
          history.replace(null, "", currentURL);
        }
      }
    },
    [history.location]
  );

  // reused function for file prev.
  const handleClickDownloadViolationFromWorkdrive = async (fileData) => {
    let baseUrl = `${config.backend_url}getPriviewFileById`;
    let payLoad = {
      fileId: fileData,
      accessToken: localStorage.getItem("token"),
    };
    let str = "";
    await axios.post(baseUrl, payLoad).then((r) => {
      str = r.data;
    });
    return str;
  };

  const fetchAuthenticatedUrl = async ({ info, type, fileId }) => {
    let uri = "";
    let returnObject = {
      DataUrl: "",
      ShowPrevModal: false,
      FileName: "",
    };
    //setLoader(true);
    try {
      let data;
      if (fileId != "" && fileId != undefined) {
        data = await handleClickDownloadViolationFromWorkdrive(fileId);
      }
      returnObject["DataUrl"] = data;
      //setLoader(false);
    } catch (e) {
      //setLoader(false);
    }
    //setAuthenticatedUrl("https://firstprojectbook.herokuapp.com/static/pdf/Mediatek_2016_CSRreport.pdf")
    returnObject["ShowPrevModal"] = true;
    return returnObject;
  };
  // new code end

  //Nagesh Altekar code start//
  const content = (
    <div
      className="dropdown-menu dropdown-menu-right accountexpansion"
      aria-labelledby="dropdownMenuButton"
    >
      <div>
        <img src={localStorage.getItem("ProfilePhoto")} className="user-ico" />
        <div className="user-details-box">
          <span className="username">
            {currentUser
              ? currentUser.first_name + " " + currentUser.last_name
              : "NA"}
          </span>
          <span className="useremail">
            {currentUser ? currentUser.email_id : "NA"}
          </span>
          <span className="userrole">
            {currentUser ? currentUser.role_details.role_name : "NA"}
          </span>
        </div>
      </div>
      <div className="navAccountMenu-bottom ">
        <a
          className="logout"
          style={{ cursor: "pointer" }}
          onClick={() => {
            if (window.confirm("Are you sure want to logout?")) {
              window.catalyst.auth.signOut("/");
              localStorage.clear();
            }
          }}
        >
          <FontAwesomeIcon icon={faSignOutAlt} />  Logout
        </a>

        <a
          className="setting"
          style={{ cursor: "pointer" }}
          onClick={() => history.push({ pathname: "/setting/index" })}
        >
          <FontAwesomeIcon icon={faCog} /> Settings
        </a>
      </div>
    </div>
  );

  const getParams = (params) => {
    location.state = params;
    setRoutesParams(params);
  };

  const getUserThumbnailUrl = (zuid) => {
    const loginProfileUrl =
      "https://contacts.zoho.com/file?ID=" + zuid + "&fs=thumb";
    return loginProfileUrl;
  };

  // useEffect(() => {
  //   window.addEventListener("beforeunload", alertUser1);
  //   return () => {
  //     window.removeEventListener("beforeunload", alertUser1);
  //     dispatch(EditDealData({}));
  //     dispatch(CreateDealData({}));
  //   };
  // }, []);
  // const alertUser1 = (e) => {
  //   e.preventDefault();
  //   e.returnValue = "";
  // };

  let timeTokenInterval;
  useEffect(() => {
    getAllResourse("Accounts", setAllClients);
    getAllResourse("Deals", setCacheDeals);
    getAllPipeline();
    // getNotification();
    getAllTask();
    getJobsByProjectId("");
  }, []);

  const handleOnChangeFilterNotification = (e) => {
    const obj = { ...filterObj };
    if (e.currentTarget.name == "All") {
      let keys = Object.keys(obj);
      keys.forEach((key) => {
        obj[key] = e.currentTarget.checked;
      });
    } else {
      obj["All"] = false;
      obj[e.currentTarget.name] = e.currentTarget.checked;
    }
    setFilterObj(obj);
  };

  const getText = (text, taskid) => {
    let type = ["email", "popup", "notification"];
    text = text.replaceAll(`#$taskid:${taskid}$#`, "");
    text = text.replaceAll(`<span>`, "");
    text = text.replaceAll(`</span>`, "");
    type.forEach((one) => {
      text = text.replaceAll(`#$type:${one}$#`, "");
    });
    return text.trim();
  };
  const getNotificationWithReminderTaskTiles = async (list) => {
    list = [...list];
    const deals = [...cacheDeals];
    const tasks = [...allTask];
    try {
      // list.forEach((one) => {
      //   if ((one.Notifications.Type == "Reminder") && (one.Notifications.Text.includes("#$taskid"))) {
      //     tasks.forEach(async (task) => {
      //       if (one.Notifications.Text.includes(`taskid:${task.id_string}`)) {
      //         let deal = deals.filter(d => d.Project_ID && d.Project_ID.includes(tasks[t].project_id))
      //         if (deal.length > 0) {
      //           deal = deal[0];
      //           let clientColor = await TaskService.GetClientColor(deal.Account_Name.id);
      //           let dealColor = await TaskService.GetDealColor(deal.Stage);
      //           one.Notifications.Text = `${getText(one.Notifications.Text)}<a style="color:#40a9ff" id="Task-${task.id_string}">${task.name}</a>  <a style="background-color:${clientColor}" id="Client-${deal.Account_Name ? deal.Account_Name.id : ''}">${deal.Account_Name ? deal.Account_Name.name : ''}</a>  <a style="background-color:${dealColor}" id="Deal-${deal.id}">${deal.Deal_Name}</a> `

      //         }
      //       }
      //     })
      //   }
      // })

      for (let n = 0; n < list.length; n++) {
        list[n].Notifications.Text = getText(list[n].Notifications.Text, "");
        if (
          list[n].Notifications.Type == "Reminder" &&
          list[n].Notifications.Text.includes("#$taskid")
        ) {
          for (let t = 0; t < tasks.length; t++) {
            if (
              list[n].Notifications.Text.includes(
                `taskid:${tasks[t].id_string}`
              )
            ) {
              let deal = deals.filter(
                (d) =>
                  d.Project_ID && d.Project_ID.includes(tasks[t].project_id)
              );
              if (deal.length > 0) {
                deal = deal[0];
                let clientColor = await TaskService.GetClientColor(
                  deal.Account_Name.id
                );
                let dealColor = await TaskService.GetDealColor(deal.Stage);
                list[n].Notifications.Text = `${getText(
                  list[n].Notifications.Text,
                  tasks[t].id_string
                )} <br/><a style="color:#40a9ff" id="Task-${
                  tasks[t].id_string
                }">${
                  tasks[t].name
                }</a>  <a style="background-color:${clientColor}" id="Client-${
                  deal.Account_Name ? deal.Account_Name.id : ""
                }">${
                  deal.Account_Name ? deal.Account_Name.name : ""
                }</a>  <a style="background-color:${dealColor}" id="Deal-${
                  deal.id
                }">${deal.Deal_Name}</a>  `;
              }
            }
          }
        }
      }
    } catch (err) {
      console.log(err);
    }

    return list;
  };

  const getNotification = () => {
    let userInfo = JSON.parse(localStorage.getItem("userinfo"));
    localStorage.setItem("notificationcount",0);
    pullNotifications()
  };

  const getJobId = (taskId) => {
    let jobs = [...allJobs];
    let filterJob = jobs.filter((one) => one.zProjectsJobRefId == taskId);
    if (filterJob.length > 0) {
      return filterJob[0].jobId;
    } else {
      return "";
    }
  };

  const getDate = (date) => {
    let date1 = new Date(date);
    return (
      (date1.getMonth() + 1 < 10 ? "0" : "") +
      (date1.getMonth() + 1) +
      "/" +
      (date1.getDate() < 10 ? "0" : "") +
      date1.getDate() +
      "/" +
      date1.getFullYear()
    );
  };

  const getDate1 = (date1) => {
    const targetTimeZone = localStorage.getItem("TIMEZONE"); // Timezone of the datetime value
    const DateTime = moment.tz(date1, targetTimeZone).format();
    date1 = new Date(DateTime);
    return (
      (date1.getMonth() + 1 < 10 ? "0" : "") +
      (date1.getMonth() + 1) +
      "/" +
      (date1.getDate() < 10 ? "0" : "") +
      date1.getDate() +
      "/" +
      date1.getFullYear()
    );
  };

  const adjustForTimezone = (date) => {
    date = new Date(date);
    var timeOffsetInMS = Math.abs(new Date().getTimezoneOffset() * 60000);
    date.setTime(date.getTime() + timeOffsetInMS);
    return date;
  };

  const getTime1 = (Time) => {
    const targetTimeZone = localStorage.getItem("TIMEZONE"); // Timezone of the datetime value
    const DateTime = moment.tz(Time, targetTimeZone).format();
    Time = new Date(DateTime);
    var hours = Time.getHours();
    var minutes = Time.getMinutes();
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  };

  const getTime = (date) => {
    let Time = new Date(date);
    var hours = Time.getHours();
    var minutes = Time.getMinutes();
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  };

  const updateNotification = (data) => {
    NotificationAPI.UpdateNotification(data)
      .then((result) => {
        getNotification();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClickNotes = (e, replaceText, noteIdPrefix) => {
    let id = e.target.id.replaceAll(replaceText, "");
    id = id.split("%");
    let firstId = id[0];
    let secondId = id[1];
    secondId = secondId.replace("20", "");
    id = firstId + secondId;
    let lastElementId = e.currentTarget.lastElementChild.id;
    lastElementId = lastElementId.replaceAll("Deal-", "");
    history.push({
      pathname: "/d/Deals/DetailView/" + lastElementId,
      search: `note=${id}`,
      state: { data: {}, action: "Update", module: "Deals" },
    });
    let startInterval = setInterval(() => {
      let highlightElement = document.getElementById(`${noteIdPrefix}${id}`);
      if (highlightElement) {
        let highlightBody = document.getElementById(`notes-body${id}`);
        if (replaceText !== "Note-") {
          highlightElement.click();
        }
        highlightBody.style.background = "#d7e7ed";
        highlightElement.scrollIntoView();
        clearInterval(startInterval);
      }
    }, 500);
  };

  const convertChatMessageId = (id) => {
    id = id.split("%");
    let firstId = id[0];
    let secondId = id[1];
    secondId = secondId.replace("20", "");
    id = firstId + secondId;
    return id;
  };

  const handleClickNotesReply = (e, replaceText, noteIdPrefix) => {
    let repId = e.target.id.replaceAll(replaceText, "");
    repId = repId.split("-");
    let replyId = convertChatMessageId(repId[1]);
    let commentId = convertChatMessageId(repId[0]);

    let lastElementId = e.currentTarget.lastElementChild.id;
    lastElementId = lastElementId.replaceAll("Deal-", "");
    history.push({
      pathname: "/d/Deals/DetailView/" + lastElementId,
      search: `note=${replyId}`,
      state: { data: {}, action: "Update", module: "Deals" },
    });
    let startInterval = setInterval(() => {
      let highlightElement = document.getElementById(
        `${noteIdPrefix}${replyId}`
      );
      if (highlightElement) {
        let highlightCommentBody = document.getElementById(
          `UniqueComment${commentId}`
        );
        let highlightBody = document.getElementById(`notes-body${replyId}`);
        if (replaceText !== "Note-") {
          highlightElement.click();
        }
        highlightCommentBody.click();
        highlightBody.style.background = "#d7e7ed";
        highlightElement.scrollIntoView();
        clearInterval(startInterval);
      }
    }, 500);
  };

  const handleNotificationHyperlink = async (e) => {
     
    let a  = e.currentTarget.querySelector('.notifications-hyperlinks');
    let isReminder= e.currentTarget.querySelector('.Custome-Notifications-link')
    if(isReminder?.name){
       
      
      if(isReminder?.name.includes("Calendar")){
        dispatch(forwardObject({module:isReminder?.name?.split("-")[0],data:{messageId:isReminder?.name?.split("-")[1]},state:"view"}))
      }else{
        dispatch(forwardObject({module:isReminder?.name,data:"",state:"view"}))
      }
      history.push(AllPaths[isReminder?.name?.split("-")[0]]);
      return;
    }
    let commonId = a?.lastElementChild?.id;
    
    if (e.target.id.startsWith("User")) {
      let id = e.target.id.replaceAll("User-", "");
      let all = [...allUsers];
      all = all.filter((one) => one.email == id);
      if (all.length > 0) {
        all = all[0];
        history.push({
          pathname: `/setting/users/${all.id}/Users`,
          state: { tab: "Users" },
        });
      }

      // history.push({
      //   pathname: "/d/Deals/DetailView/" + lastElementId,
      //   search: `note=${id}`,
      //   state: { data: {}, action: 'Update', module: 'Deals' }
      // });
    }
    if (e.target.id.startsWith("Note")) {
      handleClickNotes(e, "Note-", "comment");
    }
    if (e.target.id.startsWith("Reply")) {
      handleClickNotesReply(e, "Reply-", "UniqueReply");
    }
    if (e.target.id.startsWith("Comment")) {
      handleClickNotes(e, "Comment-", "UniqueComment");
    }
    if (e.target.id.startsWith("Client")) {
      let id = e.target.id.replaceAll("Client-", "");
      let filterClient = allClients.filter((one) => one.id == id);
      if (filterClient.length > 0) {
        filterClient = filterClient[0];
        history.push({
          pathname: "/c/Accounts/DetailView/" + filterClient.id,
          state: { data: filterClient, action: "Update", module: "Accounts" },
        });
      }
    }
    if (e.target.id.startsWith("Deal")) {
      let id = e.target.id.replaceAll("Deal-", "");
      let filterDeal = cacheDeals.filter((one) => one.id == id);
      if (filterDeal.length > 0) {
        filterDeal = filterDeal[0];
        history.push({
          pathname: "/d/Deals/DetailView/" + filterDeal.id,
          state: { data: filterDeal, action: "DetailView", module: "Deals" },
        });
      }
    }
    if (e.target.id.startsWith("Task")) {
      let lastElementId = e.currentTarget.lastElementChild.id;
      lastElementId = lastElementId.replaceAll("Deal-", "");
      GetRecordById("Deals", lastElementId)
        .then((data) => {
          data = { ...data[0] };
          let id = e.target.id.replaceAll("Task-", "");
          let tasks = [...allTask];
          tasks = tasks.filter((one1) => one1.id_string == id);
          if (tasks.length > 0) {
            setDealObj(data);
            setTaskData(tasks[0]);
            setOpenTaskModal(true);
            setTaskAction("Edit");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (e.target.id.startsWith("TaskComment")) {
      let lastElementId = e.currentTarget.lastElementChild.id;
      lastElementId = lastElementId.replaceAll("Deal-", "");
      GetRecordById("Deals", lastElementId)
        .then((data) => {
          data = { ...data[0] };
          let ids = e.target.id.replaceAll("TaskComment-", "");
          ids = ids.split("-");
          let tasks = [...allTask];
          tasks = tasks.filter((one1) => one1.id_string == ids[0]);
          if (tasks.length > 0) {
            setDealObj(data);
            setTaskData(tasks[0]);
            setOpenTaskModal(true);
            setTaskAction("Edit");
            let startInterval = setInterval(() => {
              let highlightElement = document.getElementById(
                `Comment${ids[1]}`
              );
              if (highlightElement) {
                highlightElement.classList.add("highlight-color");
                highlightElement.scrollIntoView();
                clearInterval(startInterval);
              }
            }, 500);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (e.target.id.startsWith("Chat") || (commonId && commonId?.startsWith("Chat"))) { 
      setOpenChatModal(true);
      let lastElementId = e.currentTarget.lastElementChild.id || commonId;
      lastElementId = lastElementId.replaceAll("Chat-", "");
      lastElementId = lastElementId.split("-");
      let chatId = lastElementId[0];
      let messageId = lastElementId[1];
      messageId = messageId.split("%");
      let firstId = messageId[0];
      let secondId = messageId[1];
      secondId = secondId.replace("20", "");
      messageId = firstId + secondId;
      let startInterval = setInterval(() => {
        let userElem = document.getElementById(`Notification${chatId}`);
        let messageEleme = document.getElementById(`Highlight${messageId}`);
        if (userElem || messageEleme) {
          userElem.click();
          if (messageEleme) {
            messageEleme.style.background = "rgb(202 212 215)";
            messageEleme.scrollIntoView();
          }
          if (userElem && messageEleme) {
            clearInterval(startInterval);
          }
        }
      }, 500);
    }
  };

  function invertColor(hex, bw) {
    if (hex.indexOf("#") === 0) {
      hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
      throw new Error("Invalid HEX color.");
    }
    var r = parseInt(hex.slice(0, 2), 16),
      g = parseInt(hex.slice(2, 4), 16),
      b = parseInt(hex.slice(4, 6), 16);
    if (bw) {
      return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? "#000000" : "#FFFFFF";
    }
    // invert color components
    r = (255 - r).toString(16);
    g = (255 - g).toString(16);
    b = (255 - b).toString(16);
    // pad each with zeros and return
    return "#" + padZero(r) + padZero(g) + padZero(b);
  }
  function padZero(str, len) {
    len = len || 2;
    var zeros = new Array(len).join("0");
    return (zeros + str).slice(-len);
  }

  const replaceReminderTaskWithHtml = (message) => {};

  const displayNotification = (message) => {
    // if (message.DataType == 'Deal') {
    //   return message.RequestId.map((jobNum, i) => {
    //     return <li className={message.isClick ? "" : "unreadNotification"}>
    //       <div className="notificationIcon">
    //         <span>
    //           <FontAwesomeIcon icon={faComment} />
    //         </span>
    //       </div>
    //       {message.NotificationType == "deleted" ? (
    //         <div className="notificationText">Admin deleted Job Number : {jobNum}</div>
    //       ) : message.NotificationType == "delete" ? (
    //         <div className="notificationText">
    //           {" "}
    //           <a
    //             className="underline-text"
    //             onClick={() => gotoUserInfo(message.SenderId)}
    //           >
    //             {message.SenderName}
    //           </a>{" "}
    //           sent request to archieve Job Number
    //           <a
    //             className="underline-text"
    //             onClick={() => {
    //               UpdateNotificationIsCheck(message, true);
    //               handleComponentChange(
    //                 "/projects/job/" + jobNum
    //               )
    //             }
    //             }
    //           >
    //             {" "}
    //             {jobNum}
    //           </a>
    //         </div>
    //       ) : (
    //         <></>
    //       )}

    //       {/* {/ {getNotifoicatoionMsg(e)} /} */}
    //       <div className="notificationTime">
    //         <div>
    //           {message.isClick ? <></>
    //             :
    //             <FontAwesomeIcon
    //               icon={faCircle}
    //             />
    //           }
    //           <span>
    //             {getDate(message.createdAt)}</span> at <span>{getTime(message.createdAt)}
    //           </span>
    //         </div>
    //         <div className="notification-unread-button">
    //           {message.isClick ? <span onClick={() => UpdateNotificationIsCheck(message, false)}>Mark as unread</span>
    //             :
    //             <></>
    //           }
    //         </div>
    //       </div>

    //     </li>
    //   })
    // }
    // if (message.DataType == 'Task') {

    //   if (message.NotificationType == 'Assign') {
    //     return <li className={message.isClick ? "" : "unreadNotification"}>

    //       <div className="notificationIcon">
    //         <span
    //           className={message.isCheck || (message._id == messageData._id) ? "checkBox-bg" : ""}
    //         >
    //           {message.isCheck || (message._id == messageData._id)
    //             ?
    //             <input
    //               type="checkbox"
    //               onMouseLeave={() => {
    //                 if (!message.isCheck) {
    //                   setMessageData({});
    //                 }
    //               }}
    //               checked={message.isCheck}
    //               onChange={(e) => {
    //                 const msg = [...messageList];
    //                 msg.filter((m) => m._id == message._id)[0].isCheck = e.target.checked;
    //                 setMessageList(msg)
    //                 if (e.target.checked == false) {
    //                   if (msg.filter(m => m.isCheck).length == 0) {
    //                     setOpenPopupOnIconChange(e.target.checked);
    //                   }
    //                 } else {
    //                   setOpenPopupOnIconChange(e.target.checked);
    //                 }

    //               }}
    //             >
    //             </input>
    //             : <FontAwesomeIcon
    //               icon={faAddressBook}
    //               onMouseLeave={() => {
    //                 setMessageData({});
    //               }}
    //               onMouseEnter={() => {
    //                 setMessageData(message);

    //               }}
    //             />
    //           }
    //         </span>
    //       </div>
    //       <div>
    //         <h6 className="notifType">{message.NotificationType}</h6>
    //         <div className="notificationText">
    //           {" "}
    //           <a
    //             className="underline-text"
    //             onClick={() => gotoUserInfo(message.SenderId)}
    //           >
    //             {message.SenderName}
    //           </a>{" "}
    //           Assigned you task <a
    //             className="underline-text"
    //             onClick={() => {
    //               // handleComponentChange(
    //               //   "/projects/job/" + message.RequestId[0].TaskId
    //               // )
    //               UpdateNotificationIsCheck(message, true)
    //               history.push({
    //                 pathname: "/projects/job/" + message.RequestId[0].DealId,
    //                 state: { detail: { action: 'taskModalOpen', TaskName: message.RequestId[0].TaskName, TaskUniqueId: message.RequestId[0].TaskUniqueId, TaskId: message.RequestId[0].TaskId, dataType: 'task' } }
    //               })
    //             }
    //             }
    //           >

    //             {" "}
    //             {message.RequestId[0].TaskName}
    //           </a>
    //           {"  "}
    //           from
    //           {"  "}
    //           <a
    //             className="underline-text"
    //             onClick={() => {

    //               UpdateNotificationIsCheck(message, true)
    //               history.push({
    //                 pathname: "/projects/job/" + message.RequestId[0].DealId,
    //                 state: { detail: { action: 'taskTabOpen', dataType: 'task' } }
    //               })
    //             }
    //             }
    //           > {message.RequestId[0].DealName}
    //           </a>
    //         </div>
    //       </div>

    //       <div className="notificationTime">
    //         <div>
    //           {message.isClick ? <></>
    //             :
    //             <FontAwesomeIcon
    //               icon={faCircle}
    //             />
    //           }
    //           <span>
    //             {getDate(message.createdAt)}</span> at <span>{getTime(message.createdAt)}
    //           </span>
    //         </div>
    //         <div className="notification-unread-button">
    //           {message.isClick ? <span onClick={() => UpdateNotificationIsCheck(message, false)}>Mark as unread</span>
    //             :
    //             <></>
    //           }
    //         </div>
    //       </div>

    //     </li>
    //   }

    // }
    if (
      message.Type == "Mention" ||
      message.Type == "Assignee" ||
      message.Type == "Message" ||
      message.Type == "Attachment" ||
      message.Type == "Reminder"
    ) {
      // console.log("message        : ityegdugeqdvcgavdcgdvc", message);
      return (
        <li
          style={{ padding: "15px 0px" }}
          className={message.IsRead ? "row" : "unreadNotification row pb-2"}
            >
          <div className="notificationIcon col-1">
            <span
            // className={message.IsRead || (message.ROWID == messageData.ROWID) ? "checkBox-bg" : ""}
            >
              {message.IsRead || message.ROWID == messageData.ROWID ? (
                message.Type == "Reminder" ? (
                  <img
                    className="reminder-icon-cust"
                    style={{ backgroundColor: "#5b6c97", borderRadius: "50%" }}
                    src={remIcoCutom}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={
                      message.Type == "Reminder"
                        ? faBell
                        : message.Type == "Assignee"
                        ? faUser
                        : faComment
                    }
                    onMouseLeave={() => {
                      // setMessageData({});
                    }}
                    onMouseEnter={() => {
                      // setMessageData(message);
                    }}
                  />
                )
              ) : // <input
              //   type="checkbox"
              //   onMouseLeave={() => {
              //     if (!message.IsRead) {
              //       // setMessageData({});
              //     }
              //   }}
              //   checked={false}
              //   onChange={(e) => {
              //     // const msg = [...messageList];
              //     // msg.filter((m) => m._id == message._id)[0].isCheck = e.target.checked;
              //     // setMessageList(msg)
              //     // setOpenPopupOnIconChange(e.target.checked);
              //   }}
              // >
              // </input>
              message.Type == "Reminder" ? (
                <img
                  className="reminder-icon-cust"
                  style={{ backgroundColor: "#5b6c97", borderRadius: "50%" }}
                  src={remIcoCutom}
                />
              ) : (
                <FontAwesomeIcon
                  icon={
                    message.Type == "Reminder"
                      ? faBell
                      : message.Type == "Assignee"
                      ? faUser
                      : faComment
                  }
                />
              )}
            </span>
          </div>
          <div className="col">
            <div className="row">
              <div
                className="col-4 d-flex justify-content-end"
                style={{ flexDirection: "column" }}
              >
                {message.IsRead ? (
                  <h6 className="notifType">{message.Type}</h6>
                ) : (
                  <strong>
                    <h6 className="notifType">{message.Type}</h6>
                  </strong>
                )}
              </div>
              <div className="col-8 d-flex  justify-content-end">
                <div>
                  {message.IsRead ? (
                    <></>
                  ) : (
                    <FontAwesomeIcon
                      className="notify-isRead custom-dot-icon"
                      icon={faCircle}
                    />
                  )}
                </div>
                {message.IsRead ? (
                  <>
                    <span>{getDate1(message.CREATEDTIME)}</span>&nbsp;at&nbsp;
                    <span>{getTime1(message.CREATEDTIME)}</span>
                  </>
                ) : (
                  <strong>
                    <span>{getDate1(message.CREATEDTIME)}</span> at{" "}
                    <span>{getTime1(message.CREATEDTIME)}</span>
                  </strong>
                )}
              </div>
            </div>
          </div>
          <div className="row" style={{ paddingLeft: "15px" }} onClick={(e) => handleNotificationHyperlink(e)}>
            <div className="col-1"></div>

            <div className="notificationText pl-4 col d-flex pr-0">
              {/* {" "}
            <a
              className="underline-text"
              onClick={() => {
                let all = [...allUsers]
                all = all.filter(one => one.email == message.Sender_Email);
                if (all.length > 0) {
                  all = all[0];
                  history.push({ pathname: `/setting/users/${all.id}/Users`, state: { tab: "Users" } })
                }

              }}
            >
              {message.Sender_Name}
            </a>{" "} */}
              {message.IsRead ? (
                <div
                  className="notifications-hyperlinks my-1"
                  onClick={(e) => handleNotificationHyperlink(e)}
                  dangerouslySetInnerHTML={{ __html: message.Text }}
                ></div>
              ) : (
                <strong>
                  <div
                    className="notifications-hyperlinks my-1"
                    onClick={(e) => handleNotificationHyperlink(e)}
                    dangerouslySetInnerHTML={{ __html: message.Text }}
                  ></div>
                </strong>
              )}
            </div>
          </div>
          <div className="notificationTime">
            <div className="notification-unread-button">
              {/* <AntdPopover trigger={'click'} content={<div>

              <div onClick={() => {
                let data = { ...message };
                data.IsRead = !data.IsRead;
                updateNotification(data)
              }} className="row task-filter-hovr cursor-pointer">
                <div className="col-sm-12">
                  {message.IsRead ? "Unmark As read" : "Mark as read"}
                </div>
              </div>
              <hr className="hrcls"></hr>
              <div onClick={() => {
                let data = { ...message };
                data.IsArchive = !data.IsArchive;
                updateNotification(data)
              }} className="row task-filter-hovr cursor-pointer">
                <div className="col-sm-12">
                  {message.IsArchive ? "Restore" : "Archive"}
                </div>
              </div>
            </div>}>
              <FontAwesomeIcon className="more-icon" icon={faEllipsisH} />
            </AntdPopover> */}

              {message.IsRead ? (
                <span
                  onClick={() => {
                    let data = { ...message };
                    data.IsRead = false;
                    updateNotification(data);
                  }}
                >
                  Mark as unread
                </span>
              ) : (
                <span
                  onClick={() => {
                    let data = { ...message };
                    data.IsRead = true;
                    updateNotification(data);
                  }}
                >
                  Mark as read
                </span>
              )}
            </div>
            <div className="notification-unread-button ml-2">
              {/* <AntdPopover trigger={'click'} content={<div>

              <div onClick={() => {
                let data = { ...message };
                data.IsRead = !data.IsRead;
                updateNotification(data)
              }} className="row task-filter-hovr cursor-pointer">
                <div className="col-sm-12">
                  {message.IsRead ? "Unmark As read" : "Mark as read"}
                </div>
              </div>
              <hr className="hrcls"></hr>
              <div onClick={() => {
                let data = { ...message };
                data.IsArchive = !data.IsArchive;
                updateNotification(data)
              }} className="row task-filter-hovr cursor-pointer">
                <div className="col-sm-12">
                  {message.IsArchive ? "Restore" : "Archive"}
                </div>
              </div>
            </div>}>
              <FontAwesomeIcon className="more-icon" icon={faEllipsisH} />
            </AntdPopover> */}

              {message.IsArchive ? (
                <span
                  onClick={() => {
                    let data = { ...message };
                    data.IsArchive = false;
                    updateNotification(data);
                  }}
                >
                  Activate
                </span>
              ) : (
                <span
                  onClick={() => {
                    let data = { ...message };
                    data.IsArchive = true;
                    updateNotification(data);
                  }}
                >
                  Archive
                </span>
              )}
            </div>
          </div>
        </li>
      );
    }
    // if (message.DataType == 'Notes') {
    //   if (message.NotificationType == 'Mention') {
    //     return <li className={message.isCheck ? "" : "unreadNotification"}>
    //       <div className="notificationIcon">
    //         <span
    //           className={message.isCheck || (message._id == messageData._id) ? "checkBox-bg" : ""}
    //         >
    //           {message.isCheck || (message._id == messageData._id)
    //             ?
    //             <input
    //               type="checkbox"
    //               onMouseLeave={() => {
    //                 if (!message.isCheck) {
    //                   setMessageData({});
    //                 }

    //               }}
    //               checked={message.isCheck}
    //               onChange={(e) => {
    //                 const msg = [...messageList];
    //                 msg.filter((m) => m._id == message._id)[0].isCheck = e.target.checked;
    //                 setMessageList(msg)
    //                 setOpenPopupOnIconChange(e.target.checked);

    //               }}
    //             >
    //             </input>
    //             : <FontAwesomeIcon
    //               icon={faComment}
    //               onMouseLeave={() => {
    //                 setMessageData({});
    //               }}
    //               onMouseEnter={() => {
    //                 setMessageData(message);

    //               }}
    //             />
    //           }
    //         </span>
    //       </div>
    //       <div>
    //         <h6 className="notifType">{message.NotificationType}</h6>
    //         <div className="notificationText">
    //           {" "}
    //           <a
    //             className="underline-text"
    //             onClick={() => gotoUserInfo(message.SenderId)}
    //           >
    //             {message.SenderName}
    //           </a>{" "}
    //           mentioned you in {" "}
    //           <a
    //             className="underline-text"
    //             onClick={() => {

    //               UpdateNotificationIsCheck(message, true);
    //               history.push({
    //                 pathname: "/projects/job/" + message.RequestId[0].DealId,
    //                 state: { detail: { action: 'noteTabOpen', dataType: 'notes' } }
    //               })
    //             }
    //             }
    //           >notes</a>
    //           {" "}
    //           from
    //           {" "}
    //           <a
    //             className="underline-text"
    //             onClick={() => {
    //               UpdateNotificationIsCheck(message, true)
    //               history.push({
    //                 pathname: "/projects/job/" + message.RequestId[0].DealId,
    //                 state: { detail: { action: 'taskTabOpen', dataType: 'notes' } }
    //               })
    //             }
    //             }
    //           > {message.RequestId[0].DealName}
    //           </a>

    //         </div>
    //       </div>
    //       <div className="notificationTime">
    //         <div>
    //           {message.isClick ? <></>
    //             :
    //             <FontAwesomeIcon
    //               icon={faCircle}
    //             />
    //           }
    //           <span>
    //             {getDate(message.createdAt)}</span> at <span>{getTime(message.createdAt)}
    //           </span>
    //         </div>
    //         <div className="notification-unread-button">
    //           {message.isClick ? <span onClick={() => UpdateNotificationIsCheck(message, false)}>Mark as unread</span>
    //             :
    //             <></>
    //           }
    //         </div>
    //       </div>

    //     </li>
    //   }
    // }
    // if (message.DataType == "CallLog" || message.DataType == "EventLog" || message.DataType == "MeetingLog" || message.DataType == "EmailLog") {
    //   if (message.NotificationType == 'Mention') {
    //     return <li className={message.isCheck ? "" : "unreadNotification"}>
    //       <div className="notificationIcon">
    //         <span
    //           className={message.isCheck || (message._id == messageData._id) ? "checkBox-bg" : ""}
    //         >
    //           {message.isCheck || (message._id == messageData._id)
    //             ?
    //             <input
    //               type="checkbox"
    //               onMouseLeave={() => {
    //                 if (!message.isCheck) {
    //                   setMessageData({});
    //                 }

    //               }}
    //               checked={message.isCheck}
    //               onChange={(e) => {
    //                 const msg = [...messageList];
    //                 msg.filter((m) => m._id == message._id)[0].isCheck = e.target.checked;
    //                 setMessageList(msg)
    //                 setOpenPopupOnIconChange(e.target.checked);

    //               }}
    //             >
    //             </input>
    //             : <FontAwesomeIcon
    //               icon={faComment}
    //               onMouseLeave={() => {
    //                 setMessageData({});
    //               }}
    //               onMouseEnter={() => {
    //                 setMessageData(message);

    //               }}
    //             />
    //           }
    //         </span>
    //       </div>
    //       <div>
    //         <h6 className="notifType">{message.NotificationType}</h6>
    //         <div className="notificationText">
    //           {" "}
    //           <a
    //             className="underline-text"
    //             onClick={() => gotoUserInfo(message.SenderId)}
    //           >
    //             {message.SenderName}
    //           </a>{" "}
    //           mentioned you in {" "}
    //           <a
    //             className="underline-text"
    //             onClick={() => {
    //               UpdateNotificationIsCheck(message, true)
    //               history.push({
    //                 pathname: "/projects/job/" + message.RequestId[0].DealId,
    //                 state: { detail: { action: 'logTabOpen', dataType: 'Logs' } }
    //               })
    //             }
    //             }
    //           >{message.DataType}</a>
    //           {" "}
    //           from
    //           {" "}
    //           <a
    //             className="underline-text"
    //             onClick={() => {
    //               UpdateNotificationIsCheck(message, true)
    //               history.push({
    //                 pathname: "/projects/job/" + message.RequestId[0].DealId,
    //                 state: { detail: { action: 'taskTabOpen', dataType: 'logs' } }
    //               })
    //             }
    //             }
    //           > {message.RequestId[0].DealName}
    //           </a>
    //         </div>
    //       </div>
    //       <div className="notificationTime">
    //         <div>
    //           {message.isClick ? <></>
    //             :
    //             <FontAwesomeIcon
    //               icon={faCircle}
    //             />
    //           }
    //           <span>
    //             {getDate(message.createdAt)}</span> at <span>{getTime(message.createdAt)}
    //           </span>
    //         </div>
    //         <div className="notification-unread-button">
    //           {message.isClick ? <span onClick={() => UpdateNotificationIsCheck(message, false)}>Mark as unread</span>
    //             :
    //             <></>
    //           }
    //         </div>
    //       </div>

    //     </li>
    //   }
    // }
  };
  const displayNotificationForChat = (messages) => {
    let items=[];
    messages?.forEach((message,i)=>{
      message=message?.Notifications
      if (
        message?.Type == "Mention" ||
        message?.Type == "Message" || message?.Type=="Attachment"
      ) {
        items.push (message);
      }
    })
    
    // return (<AntDropDown
    //   menu={{
    //     items,
    //   }}
    //   overlayClassName="Chat-Notification-Overlay"
    //   arrow={{
    //     pointAtCenter: true,
    //   }}
    //   trigger={["hover"]}
    // >
    //   <div
    //           ref={chatButtonRef}
    //           id="ChatOpenFromServiceWorker"
    //           onClick={() => setOpenChatModal(!openChatModal)}
    //           className="chat-box"
    //         >
    //           <i className="fa fa-comment"></i> <span>Chat</span>
    //           {items?.length > 0 ? (
    //             <span className="badge badge-light">
    //               {items?.length}
    //             </span>
    //           ) : (
    //             <></>
    //           )}
    //   </div>
    // </AntDropDown>)
    return (<div
            ref={chatButtonRef}
            id="ChatOpenFromServiceWorker"
            onClick={() => setOpenChatModal(!openChatModal)}
            className="chat-box"
          >
            <i className="fa fa-comment"></i> <span>Chat</span>
            {items?.length > 0 ? (
              <span className="badge badge-light">
                {items?.length}
              </span>
            ) : (
              <></>
            )}
          </div>)
    
  };
  const IsEnableFilter = (type) => {
    let obj = [...notificationFilter];
    obj = obj.filter((one) => one.name == type);
    if (obj.length > 0) {
      obj = obj[0];
      return obj.isVisible;
    } else {
      return false;
    }
  };

  const getNotificationRender = (list) => {
    let obj = [...notificationFilter];
    list = [...list];
    if (!obj.All) {
      list = list.filter((one) => {
        if (
          one.Notifications.Type == "Reminder" &&
          IsEnableFilter("REMINDER")
        ) {
          return one;
        }
        if (one.Notifications.Type == "Mention" && IsEnableFilter("MENTION")) {
          return one;
        }else  if (one.Notifications.Type == "Message" && IsEnableFilter("MESSAGE")) {
          return one;
        }
        else  if (one.Notifications.Type == "Attachment" && IsEnableFilter("ATTACHMENT")) {
          return one;
        }
        else if (
          one.Notifications.Type == "Assignee" &&
          IsEnableFilter("ASSIGNEE")
        ) {
          return one;
        } else if (
          one.Notifications.Data_For == "Task" &&
          IsEnableFilter("TASK")
        ) {
          return one;
        } else if (
          one.Notifications.Data_For == "Notes" &&
          IsEnableFilter("NOTES")
        ) {
          return one;
        }
      });
    }
    return list;
  };

  // useEffect(() => {
  //   if (allJobs.length > 0) {
  //     getAllTask();
  //   }
  // }, [allJobs]);

  useEffect(() => {
    if (cacheDeals.length > 0 && allTask.length > 0) {
      getNotification();
    }
  }, [cacheDeals, allTask]);
  function pullNotifications(){
    // let count=localStorage.getItem("notificationcount")
    let count=0;
    NotificationAPI.LiveNotificationLongPollApi(count).then(async result=>{
      localStorage.setItem("notificationcount",result.length);
      result = result?.filter(
        (one) => one.Notifications.Reciever_Email == userInfo?.email_id
      );
      result.sort(
        (a, b) =>
          new Date(b.Notifications.CREATEDTIME) -
          new Date(a.Notifications.CREATEDTIME)
      );
      let chatNotifications=result.filter(chat=>{
        if((chat.Notifications.Type=="Message" || chat.Notifications.Type=="Chat" || chat.Notifications.Type=="Attachment") && !chat.Notifications.IsRead){
          return chat
        }
      })
      if(chatNotifications?.length!=chatnotificationList?.length){
        // setRefreshChat(!refreshTheChat);
        setchatnotificationList(chatNotifications);
        dispatch(AllNotificationsForChat(chatNotifications))
      }else if(chatNotifications.length == 0){
        setchatnotificationList([]);
        dispatch(AllNotificationsForChat([]))
      }
      result = await getNotificationWithReminderTaskTiles(result);
      setNotificationList(result);
      setNotificationCacheList(result);
      setLoader(false);
    }).catch((err) => {
      setLoader(false);
      console.log(err);
    }).finally(e=>{
      // pullNotifications();
    })
 }
  useEffect(() => {
    localStorage.setItem("notificationcount",0);
    pullNotifications();
  }, []);

  useEffect(() => {
    if (
      cacheDeals.length > 0 &&
      allClients.length > 0 &&
      cachePipeline.length > 0 &&
      allJobs.length > 0
    ) {
      getAllTimeTrackLogs("all");
    }
  }, [cacheDeals, allClients, cachePipeline, allJobs]);

  const getAllResourse = (module, callback) => {
    ModuleService.GetModuleList(module)
      .then((result) => {
        if (result.data) {
          if (module == "Deals") {
            setFilterDeals(result.data);
          }
          callback(result.data);
        } else {
          callback([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllPipeline = () => {
    TaskService.GetAllProjects()
      .then(async (result) => {
        if (result.data.projects) {
          setCachePipeline(result.data.projects);
        } else {
          setCachePipeline([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getJobsByProjectId = (pid) => {
    TaskService.GetJobsByProjectId(pid)
      .then((result) => {
        if (result) {
          for (let i = 0; i < result.length; i++) {
            result[i].name = result[i].projectName + "-" + result[i].jobName;
          }
          setAllJobs(result);
        } else {
          setAllJobs([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (allJobs && timeTrackLogs && allJobs.length > 0 && timeTrackLogs.length > 0) {
      initializeTimeTrack();
    }
  }, [allJobs, timeTrackLogs]);

  const initializeTimeTrack = () => {
    TaskService.GetCurrentlyRunningTimer()
      .then((res) => {
        if (res) {
          if (!timeTrackClock) {
            setTimeTrackInterv(
              setInterval(() => {
                let nowTime = new Date(Date.now());
                let diff = nowTime.getTime() - timeTrackInitialTime.getTime();
                diff = Math.abs(diff / 1000);
                timeTrackInitialTime = new Date(nowTime);
                setTimerTimeTrack((prev) => prev + diff);
              }, 1000)
            );
            let totalTime = 0;
            let filterTimeLog = timeTrackLogs.filter(
              (one) => one.jobId == res.jobId
            );
            if (filterTimeLog.length > 0) {
              totalTime = totalTime + filterTimeLog[0].totaltime;
              setTimerPrevTimeTrack(totalTime);
            }
            let startTime = new Date(Date.now());
            // startTime.setTime(startTime.getTime()-(res.diff*1000))

            // res.diff=res.diff+totalTime;
            // startTime.setTime(startTime.getTime() + ((res.diff) * 1000));
            timeTrackInitialTime = new Date(startTime);

            setTimerTimeTrack((prev) => prev + res.diff + totalTime);
            setTimeTrackClock(true);
            localStorage.setItem("IsTimeTrackRunning", true);
            setTimerObj(res);
            // setRunningTaskLabel(getTaskToolTipLabel(res))
            getAllTimeTrackLogs("all");
          }
        } else {
          if (timeTrackClock) {
            localStorage.setItem("IsTimeTrackRunning", false);
            setTimeTrackClock(false);
            timeTrackInitialTime = new Date(Date.now());
            clearInterval(timeTrackInterv);
            setTimerTimeTrack(0);
            setTimerObj(null);
            setRunningTaskLabel(null);
            getAllTimeTrackLogs("all");
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllTask = () => {
    TaskService.GetMyTasks()
      .then((result) => {
        for (let i = 0; i < result.length; i++) {
          result[i].JobId = getJobId(result[i].id_string);
        }
        setAllTasks(result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const timeLogDeleteWarning = () => {
    return (
      <Modal
        isOpen={timeLogDeleteWarn}
        backdrop={"static"}
        size="md"
        className="taskComplete-warning"
      >
        <ModalBody>
          <Row>
            <Col sm={12}>
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                size="3x"
                className="text-warning"
              />
              <h5 className="my-2">Time Track Delete Warning</h5>
              <p className="taskComplete-warning-subtitle">
                Are you sure? you want to delete this log
              </p>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <button
                onClick={() => {
                  if (deleteTimeLogObj.timelogId) {
                    TaskService.DeleteTimeTrack(deleteTimeLogObj.timelogId)
                      .then((result) => {
                        getAllTimeTrackLogs("all");
                        setTimeDeleteWarn(false);
                      })
                      .catch((err) => {
                        console.log(err);
                      });
                  }
                }}
                className="btn btn-primary w-100 mt-2"
              >
                OK
              </button>
            </Col>
            <Col sm={6}>
              <button
                onClick={() => {
                  setTimeDeleteWarn(false);
                }}
                className="btn btn-secondary w-100 mt-2"
              >
                Cancel
              </button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  };

  function GetFormattedDate(date) {
    let dateObj = new Date(date);
    let month = dateObj.getMonth() + 1; //months from 1-12
    let day = dateObj.getDate();
    let year = dateObj.getFullYear();
    day = day < 10 ? "0" + day : day;
    month = month < 10 ? "0" + month : month;
    return day + "/" + month + "/" + year;
  }

  const getDateMonthOnly = (dateSplit) => {
    dateSplit = GetFormattedDate(dateSplit);
    dateSplit = dateSplit.split("/");
    let monthShortNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    //
    let Month = parseInt(dateSplit[1]);
    return (
      monthShortNames[Month - 1] + " " + dateSplit[0] + ", " + dateSplit[2]
    );
  };

  const getAllTimeTrackLogs = (taskId) => {
    let userInfo = JSON.parse(localStorage.getItem("userinfo"));
    let startDate = new Date();
    startDate.setDate(1);
    startDate = moment(startDate).format("YYYY-MM-DD");
    let endDate = new Date();
    endDate.setDate(31);
    endDate = moment(endDate).format("YYYY-MM-DD");
    //userinfo.email_id
    TaskService.GetAllTimeTrack(
      "david@srsolardesign.com",
      startDate,
      endDate,
      taskId
    )
      .then((result) => {
        // if(timeTrackClock&&timerObj){
        //   let firstObj=result.filter(one=>one.jobId==timerObj.jobId)
        //   result=result.filter(one=>one.jobId!==timerObj.jobId);
        //   result
        // }
        if(result){
          result = getCalculateTimeTrackLogs(result);
        }
        setTimeTrackLogs(result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCalculateTimeTrackLogs = (result) => {
    result = _.cloneDeep(result);
    if (allJobs.length > 0 && cacheDeals.length > 0) {
      try {
        result.forEach((one) => {
          let jobs = [...allJobs];
          let alldeals = [...cacheDeals];
          jobs = jobs.filter((job) => job.projectId == one.projectId);
          if (jobs.length > 0) {
            jobs = jobs[0];
            alldeals = alldeals.filter((one1) => {
              let ids = one1.Project_ID;
              if (ids) {
                ids = ids.split(",");
                ids = ids.filter((id) => id);
                if (ids.includes(jobs.zProjectsProjectRefId)) {
                  return one1;
                }
              }
            });

            if (alldeals.length > 0) {
              alldeals = alldeals[0];
              if (alldeals.Account_Name) {
                one.name =
                  alldeals.Account_Name.name +
                  "_" +
                  one.projectName +
                  "_" +
                  one.jobName;
              } else {
                one.name = "" + "_" + one.projectName + "_" + one.jobName;
              }
            }
          }
        });
      } catch (err) {
        console.log(err);
      }
    }

    try {
      // result=result.filter(one=>one.jobName=="Upload PW2")
      result?.forEach((obj) => {
        if (obj.timearr) {
          obj.timearr.forEach((oneObj) => {
            oneObj.workDate = obj.workDate;
            oneObj.timerLog = obj.timerLog;
          });
        }

        if (!obj.timerLog) {
          //obj.totaltime=(obj.totaltime*60);

          obj.toTime = obj.fromTime + obj.totaltime;

          if (obj.timearr) {
            obj.timearr.push(obj);
          } else {
            obj.timearr = [obj];
          }
        }
      });
      for (let i = 0; i < result.length; i++) {
        for (let j = i + 1; j < result.length; j++) {
          if (result[i].jobId == result[j].jobId) {
            result[i].hoursInMins =
              result[i].hoursInMins + result[j].hoursInMins;
            result[i].totaltime = result[i].totaltime + result[j].totaltime;
            if (result[j].timearr) {
              result[i].timearr = [...result[i].timearr, ...result[j].timearr];
            } else {
              result[i].timearr = [result[j]];
            }
            result.splice(j, 1);
            j--;
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
    result.forEach((one) => {
      let timeArr = aggregateTimeArr(_.cloneDeep(one.timearr));
      timeArr = timeArr.sort(
        (a, b) => new Date(b.workDate) - new Date(a.workDate)
      );
      if (timeArr.length > 0) {
        one.workDate = timeArr[0].workDate;
      }
    });
    result.forEach((one) => {
      one.timearr = aggregateTimeArr(_.cloneDeep(one.timearr));
    });

    result = result.sort((a, b) => new Date(b.workDate) - new Date(a.workDate));
    // if(timeTrackClock&&timerObj){
    //   let idx=result.findIndex(one=>one.jobId==timerObj.jobId);
    //   if(idx!==-1){
    //     let firstObj=_.cloneDeep(result[idx]);
    //     result[idx]=_.cloneDeep(result[0]);
    //     result[0]=_.cloneDeep(firstObj);

    //   }
    // }
    return result;
  };

  const getTopPositionTimeTrack = (result) => {
    result = _.cloneDeep(result);
    if (timeTrackClock && timerObj) {
      let idx = result.findIndex((one) => one.jobId == timerObj.jobId);
      if (idx !== -1) {
        let firstObj = _.cloneDeep(result[idx]);
        result[idx] = _.cloneDeep(result[0]);
        result[0] = _.cloneDeep(firstObj);
      }
    }
    return result;
  };

  // const [elem,setElem]=useState(null)

  // useEffect(()=>{
  //   if(timeTrackClock&&timerObj&&(allJobs.length>0)&&(cacheDeals.length>0)){
  //     let elememtn=getTaskToolTipLabel(timerObj);
  //     setElem(elememtn)
  //   }
  // },[timerObj,allJobs,cacheDeals])

  const getTaskToolTipLabel = (obj) => {
    if (obj) {
      let jobs = [...allJobs];
      let alldeals = [...cacheDeals];
      jobs = jobs.filter((job) => job.jobId == obj.jobId);
      if (jobs.length > 0) {
        jobs = jobs[0];
        alldeals = alldeals.filter((one1) => {
          let ids = one1.Project_ID;
          if (ids) {
            ids = ids.split(",");
            ids = ids.filter((id) => id);
            if (ids.includes(jobs.zProjectsProjectRefId)) {
              return one1;
            }
          }
        });

        if (alldeals.length > 0) {
          alldeals = alldeals[0];
          if (alldeals.Account_Name) {
            return (
              <div>
                Client:<p>{alldeals.Account_Name.name}</p>
                Deal:<p>{alldeals.Deal_Name}</p>
                Pipeline:<p>{getGroupVal(obj.projectName)}</p>
                Task:<p>{obj.jobName}</p>
              </div>
            );
          } else {
            return (
              <div>
                Client:<p>N/A</p>
                Deal:<p>{alldeals.Deal_Name}</p>
                Pipeline:<p>{getGroupVal(obj.projectName)}</p>
                Task:<p>{obj.jobName}</p>
              </div>
            );
          }
        }
      }
    } else {
      return <>ghjgfjgfj</>;
    }

    // if (name) {
    //   name = name.split('_');
    //   name = name.filter(str => str);
    //   return <div>
    //     Client:<p>{name[0]}</p>
    //     Pipeline:<p>{name[1]}</p>
    //     Task:<p>{name[2]}</p>
    //   </div>
    // } else {
    //   return <></>
    // }
  };


  function toIsoString(date) {
    var tzo = -date.getTimezoneOffset(),
      dif = tzo >= 0 ? "+" : "-",
      pad = function (num) {
        return (num < 10 ? "0" : "") + num;
      };

    return (
      date.getFullYear() +
      "" +
      pad(date.getMonth() + 1) +
      "" +
      pad(date.getDate()) +
      "T" +
      pad(date.getHours()) +
      "" +
      pad(date.getMinutes()) +
      "" +
      pad(date.getSeconds()) +
      dif +
      pad(Math.floor(Math.abs(tzo) / 60)) +
      "" +
      pad(Math.abs(tzo) % 60)
    );

    // return date.getFullYear() +
    //     '-' + pad(date.getMonth() + 1) +
    //     '-' + pad(date.getDate()) +
    //     'T' + pad(date.getHours()) +
    //     ':' + pad(date.getMinutes()) +
    //     ':' + pad(date.getSeconds()) +
    //     dif + pad(Math.floor(Math.abs(tzo) / 60)) +
    //     ':' + pad(Math.abs(tzo) % 60);
  }

  const handleSnoozeButton = async (ids, minutes) => {
    ids = ids.split("_"); //notificationEvent.split('_');
    let evid = ids[1].replace("EID", "");
    evid = evid + "@zoho.com";

    CalendarAPI.GetEventDetail(ids[0], evid)
      .then((result) => {
        result = result[0];
        let start = new Date(moment(result.dateandtime.start).$d);
        start.setMinutes(start.getMinutes() + minutes);
        result.dateandtime.start = toIsoString(start);
        // result.dateandtime.start=
        return CalendarAPI.UpdateEvent(ids[0], evid, {
          dateandtime: result.dateandtime,
          etag: result.etag,
        });
      })
      .then((result) => {
        setReminderNotify(false);
        setReminderNotifyMsg("");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const reminderNotification = () => {
    return (
      <>
        <AntdModal
          footer={null}
          mask={false}
          className="popup-notification"
          title={reminderNotifyMsg}
          open={reminderNotify}
          onOk={() => setReminderNotify(false)}
          onCancel={() => setReminderNotify(false)}
        >
          <div className="d-flex mb-3">
            <p>
              <FontAwesomeIcon color="yellow" className="mr-2" icon={faBell} />
            </p>
            <div>
              <span className="font-weight-bold mr-1">Time:</span>
              <span>
                {moment(notificationEventDateTime).format(
                  "ddd, MMM DD, YYYY, hh:mm a"
                )}
              </span>
            </div>
          </div>
          <button
            onClick={() => setReminderNotify(false)}
            className="btn btn-sm btn-outline-primary"
          >
            Cancel
          </button>
          <div className="btn-group">
            <button
              style={{
                borderTopLeftRadius: "45px",
                borderBottomLeftRadius: "45px",
              }}
              onClick={async () => {
                let ids =
                  "d8140b3f7b9a49d39872dc98ba68b1af_EID0f0d9e3f0dbb4ae8b9ef71190f266c0e";
                handleSnoozeButton(notificationEvent, 5);
              }}
              type="button"
              className="btn btn-sm btn-primary"
            >
              Snooze For 5 Min
            </button>
            <button
              style={{
                borderTopRightRadius: "45px",
                borderBottomRightRadius: "45px",
              }}
              type="button"
              className="btn btn-sm btn-primary  dropdown-toggle-split"
              data-toggle="dropdown"
            >
              <span className="caret">
                <FontAwesomeIcon icon={faChevronCircleDown} />
              </span>
            </button>
            <div className="dropdown-menu">
              <a
                onClick={() => handleSnoozeButton(notificationEvent, 10)}
                className="dropdown-item"
                href="#"
              >
                For 10 Min
              </a>
              <a
                onClick={() => handleSnoozeButton(notificationEvent, 15)}
                className="dropdown-item"
                href="#"
              >
                For 15 Min
              </a>
              <a
                onClick={() => handleSnoozeButton(notificationEvent, 20)}
                className="dropdown-item"
                href="#"
              >
                For 20 Min
              </a>
            </div>
          </div>
          {/* <button onClick={()=>setReminderNotify(false)} className="btn btn-sm btn-outline-secondary">Snooze</button> */}
        </AntdModal>
      </>
    );
  };

  const aggregateTimeArr = (result) => {
    result = [...result];
    result.forEach((obj) => {
      if (obj.toTime) {
        obj.totaltime = obj.toTime - obj.fromTime;
      }
    });
    for (let i = 0; i < result.length; i++) {
      for (let j = i + 1; j < result.length; j++) {
        if (
          result[i].workDate == result[j].workDate &&
          result[i].timerLog == result[j].timerLog
        ) {
          if (result[i].timers) {
            result[i].timers.push(result[j]);
          } else {
            result[i].timers = [result[j]];
          }
          result[i].totaltime = result[i].totaltime + result[j].totaltime;
          result.splice(j, 1);
          j--;
        }
      }

      if (!result[i].timers) {
        result[i].timers = [result[i]];
      }
    }
    result = result.sort((a, b) => new Date(b.workDate) - new Date(a.workDate));
    return result;
  };
  useEffect(() => {
    if (token) {
      try {
        // handleAskNotification()
        var userManagement = window.catalyst.userManagement;
        window.catalyst.notification.enableNotification().then((resp) => {
          window.catalyst.notification.messageHandler = async (msg) => {
            let allId = msg.split("-");
            allId = allId.filter((id) => id && id.trim());
            // let arr=allId[1];
            setReminderNotifyMsg(allId[0]);
            setReminderNotify(true);
            setNotificationEvent(allId[1]);
            let ids = allId[1].split("_"); //notificationEvent.split('_');
            let evid = ids[1].replace("EID", "");
            evid = evid + "@zoho.com";

            CalendarAPI.GetEventDetail(ids[0], evid)
              .then((result) => {
                if (result && result.length > 0) {
                  setNotificationEventDateTime(
                    moment(result[0].dateandtime.start).$d
                  );
                }
              })
              .catch((err) => {
                console.log(err);
              });
          };
        });
        var currentUserPromise = userManagement.getCurrentProjectUser();
        currentUserPromise
          .then((response) => {
            if (response.status !== "failure") {
              localStorage.setItem(
                "userinfo",
                JSON.stringify(response.content)
              );
              setCurrentUser(response.content);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (err) {
        setCurrentUser(null);
      }
    }
  }, [token]);

  const handleAskNotification = () => {
    Notification.requestPermission(function (result) {
      if (result === "granted") {
        navigator.serviceWorker.ready.then(async function (registration) {
          registration.showNotification("Permission Granted");
        });
      }
    });

    if (
      Notification.permission !== "denied" ||
      Notification.permission === "default"
    ) {
      Notification.requestPermission(function (permission) {
      });
    }
  };
  
  async function fetchAccounts(){
    let contextState={...appDataContext};
      await UserService.GetAllUsers()
        .then((result) => {
          setAllUsers(result.data.users);
          contextState={...contextState,AllUsers:result.data.users};
          let filterUser = result.data.users.filter(
            (one) => one.email == userInfo.email_id
          );
          if (filterUser.length > 0) {
            localStorage.setItem(
              "ProfilePhoto",
              getUserThumbnailUrl(filterUser[0].zuid)
            );
            localStorage.setItem("ZUID", filterUser[0].zuid);
            localStorage.setItem("TIMEZONE", filterUser[0].time_zone);
          } else {
            localStorage.removeItem("ProfilePhoto");
          }
        })
        .catch((err) => {
          console.log(err);
        });
      await TaskService.GetDealStatus()
      .then((res) => {
        //  
        // console.log("-----deal status-----------",res);
        contextState={...contextState,DealStatus:res};
      })
      .catch((err) => {
        console.log(err);
      });
      await FieldService.GetFields("all", "Deals")
      .then(async (result) => {
        let arr = [];
        firstArr.forEach((one) => {
          let fieldObj = result.filter((object) => object.api_name == one);
          if (fieldObj.length > 0) {
            fieldObj = fieldObj[0];
            fieldObj.field_label = fieldObj.field_label.replace("0", "");
            let obj = {};
            obj.field = fieldObj.api_name;
            obj.id = fieldObj.id;
            obj.isChecked = false;
            obj.isDragOver = false;
            obj.isSearch = true;
            fieldObj.field_label == "Owner's Street Name"
              ? (obj.name = "Owner's Street Address")
              : (obj.name = fieldObj.field_label);
            obj.type = fieldObj.data_type;
            arr.push(obj);
          }
        });
        result.forEach((one) => {
          if (!firstArr.includes(one.api_name)) {
            let obj = {};
            obj.field = one.api_name;
            obj.id = one.id;
            obj.isChecked = false;
            obj.isDragOver = false;
            obj.isSearch = true;
            obj.name = one.field_label.replace("0", "");
            obj.type = one.data_type == "formula" ? "number" : one.data_type;
            arr.push(obj);
          }
        });
        const notIncludesFileds = ["latitude", "longitude"];
        arr = arr.filter((one) => !notIncludesFileds.includes(one.name));
        moduleApiNameForSubform.forEach((one) => {
          let obj = {};
          obj.field = one;
          obj.id = one;
          obj.isChecked = false;
          obj.isDragOver = false;
          obj.isSearch = true;
          obj.name = one.replaceAll("_", " ");
          obj.type = "text";
          if (one == "Previous_Solar_Info") {
            arr.splice(38, 0, obj);
          }
          // if(one == "WWP_Violations"){
          //     arr.splice(42, 0, obj)
          // }
        });
        arr = _.uniqBy(arr, "name");
        arr = [...arr, ...customLinkFields]
        contextState={...contextState,DealFields:arr};
      })
      .catch((err) => {
        console.log(err);
      });
      setAppDataContext(contextState);
  }
  useEffect(() => {
    if (token) {
      fetchAccounts();
    }
  }, [token]);
  useEffect(() => {
    let user_token = localStorage.getItem("user_token");

    setTokenObj(user_token);
  }, []);

  useEffect(() => {
    if (tokenObj) {
      getToken();
    } else {
      if (!localStorage.getItem("user_token")) {
        getAuthCodeURL();
      }
    }
  }, [tokenObj]);

  useEffect(() => {
    if (token) {
      getLastAttendece();
    }
  }, [token]);

  // useEffect(() => {
  //   if (lastEntry ) {
  //     if(entry.current=="timeClockRefreshed"){
  //       return
  //     }
  //     let seconds = 1;
  //     // new Date(Date.now()).getTime() - new Date(moment(lastEntry.in, 'DD/MM/YYYY HH:mm:ss')).getTime();
  //     seconds = Math.round(seconds / 1000);
  //     setTimer((timer) => timer + seconds);
  //     setClockIsActive(true);
  //     clockInterval.current = setInterval(() => {
  //       setTimer((timer) => timer + 1);
  //     }, 1000);
  //   } else {
  //     clearInterval(clockInterval.current);
  //     setTimer(0);
  //     setClockIsActive(false);
  //   }
  // }, [lastEntry]);

  const [notifyUser, setNotifyUser] = useState(null);
  const handleClickNotification = (notifyUser) => {
    setNotifyUser(notifyUser);
    setOpenChatModal(true);
  };

  const handleOutsideClick = () => {
    setOpenChatModal(false);
  };

  const getTokenEndTime = (start) => {
    let end = new Date(start);
    end.setTime(end.getTime() + 3600 * 1000);
    return new Date(end);
  };

  const showPhotoOfUser = () => {
    let photo = localStorage.getItem("ProfilePhoto");
    let name = localStorage.getItem("UserName");
    if (photo === null || photo === "") {
      return <div className="no-image-user">{name ? name.charAt(0) : ""}</div>;
    } else {
      return (
        <img src={localStorage.getItem("ProfilePhoto")} className="user-ico" />
      );
    }
  };
  const checkAndValidateEntry = (data) => { 
    let obj = { ...data };
    let chacheData=state.CacheDataOfTimesheet;
    let zohoData = [...chacheData];
    let thisUserMail = userInfo.email_id;
    let checkEntryExist = zohoData.filter(one => {
     // if(one.email==thisUserMail && one.TimesheetDate==data.TimesheetDate){ 
      //  return one;
      // } 
      let tempdate=moment(new Date(data.date)).format("MM/DD/YYYY");
      if(one.email==thisUserMail &&  one.TimesheetDate==tempdate){
        let dt = moment( one.checkIn, ["h:mm A"]).format("HH:mm:ss");
        let dateTimeInVal = new Date(one.TimesheetDate);
        let dateStr = dateTimeInVal.toString();
        let dObj = dateStr.replace("00:00:00", dt)
  
        let odt = moment(one.checkOut, ["h:mm A"]).format("HH:mm:ss");
        let dateTimeOutVal = new Date(one.TimesheetDate);
        let dateOutStr = dateTimeOutVal.toString();
        let dOutObj = dateOutStr.replace("00:00:00", odt);
  
        data?.in?.setSeconds(0);
        data.out?.setSeconds(0);
        let cIn=data.in;
        let cOut=data.out;
  
        if (
          !((cIn < new Date(dObj) && (cOut < new Date(dObj) || getDateFormate(cOut) === getDateFormate(dObj)) ) 
        || ((cIn > new Date(dOutObj) || getDateFormate(cIn)  === getDateFormate(dOutObj)) && cOut > new Date(dOutObj) ))
        ){
          return one
        }
      }
    })
    if (checkEntryExist.length > 0) {
      return true
    }else {
      return false
    }
  };
  const GetAuth2URL = (props) => {
    const parsedHash = queryString.parse(location.hash);
  };

  const helperFnGetTokenFromCode = (code) => {
    return new Promise((resolve, reject) => {
      axios
        .post(backendUrl + "token_code", {
          code: code,
          redirect_url: config.auth_prod_redirect_url
        })
        .then((result) => {
          localStorage.setItem("user_token", JSON.stringify(result.data));
          localStorage.setItem("token", "Bearer " + result.data.access_token);
          setTokenObj(result.data);
          resolve(result.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const getAuthCodeURL = () => {
    const parsedHash = queryString.parse(window.location.search);
    if (parsedHash && parsedHash.code) {
      helperFnGetTokenFromCode(parsedHash.code)
        .then((result) => {
          console.log(result);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      window.location.href = config.production_auth_url;
    }
  };

  const getToken = async () => {
    let user_token = localStorage.getItem("user_token");
    let obj = JSON.parse(user_token);
    if (obj) {
      await axios
        .get(backendUrl + "token/" + obj.refresh_token)
        .then((result) => {
          setToken("Bearer " + result.data.access_token);
          localStorage.setItem("token", "Bearer " + result.data.access_token);
          let start = new Date(result.data.tokenTime);
          setTokenTime(start);
          clearInterval(timeTokenInterval);
          // timeTokenInterval = setInterval(() => {
          //   let time = new Date(tokenTime);
          //   var minutes = ((getTokenEndTime(start).getTime() - tokenTime.getTime()) / 1000) / 60;
          //   if (minutes <= 15) {
          //     getToken()
          //   }
          //   time.setMinutes(time.getMinutes() + 1);
          //   setTokenTime(time);
          // }, (60 * 1000))
          if(localStorage.getItem("ProfilePhoto") === undefined || localStorage.getItem("ProfilePhoto") === null || localStorage.getItem("ProfilePhoto") == ""){
            getToken();
          }
        })
        .catch((err) => {
          getToken();
          //alert(JSON.stringify(err));
        });
    }
  };
  const convert24to12 = (date) => {
    // let Time =  new Date(date);
    // Time.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });

    if (date == "N/A" || date == "") {
      return date;
    } else {
      try {
        var hours = date.getHours();
        var minutes = date.getMinutes();

        // if(seconds>=30){
        //   minutes=minutes+1;
        // }
        var ampm = hours >= 12 ? "PM" : "AM";
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? "0" + minutes : minutes;
        var strTime = hours + ":" + minutes + " " + ampm;
        return strTime;
      } catch (ex) {}
    }
  };

  const onSaveTimeRange = (start, stop, date) => {
    if (!start || !stop || !date) {
      swal("Please select date and time", { dangerMode: true });
      return;
    }
    if (!timerObj || !timerObj.jobId) {
      swal("Please select task", { dangerMode: true });
      return;
    }
    const userinfo = JSON.parse(localStorage.getItem("userinfo"));
    const data = { ...taskData };
    start = start.split(":");
    stop = stop.split(":");
    let from = new Date(Date.now());
    from.setHours(parseInt(start[0]));
    from.setMinutes(parseInt(start[1]));
    let till = new Date(Date.now());
    till.setHours(parseInt(stop[0]));
    till.setMinutes(parseInt(stop[1]));
    start = convert24to12(from);
    stop = convert24to12(till);
    date = moment(new Date(date)).format("YYYY-MM-DD");
    TaskService.addTimeTrack(
      timerObj.jobId,
      timerObj.jobName,
      date,
      "david@srsolardesign.com",
      start,
      stop
    )
      .then((result) => {
        if (result.errors) {
          alert(result.errors.message);
        } else {
          alert(result.message);
          setTimeLogDate(null);
          setFromRange(null);
          setToRange(null);
          setTimerObj({});
        }
        return getAllTimeTrackLogs("all");
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  };

  const startstopClockTimeTrack = async (timerObj) => {
    const userinfo = JSON.parse(localStorage.getItem("userinfo"));
    try {
      if (timeTrackClock) {
        let res = await TaskService.ResumeAndPauseTimeTrack(
          timerObj.timelogId,
          "stop"
        );
        setTimeTrackClock(false);
        timeTrackInitialTime = new Date(Date.now());
        clearInterval(timeTrackInterv);
        setTimerTimeTrack(0);

        setTimerObj(null);
        getAllTimeTrackLogs("all");
        localStorage.setItem("IsTimeTrackRunning", false);
      } else {
        let date = new Date();
        // date.setDate(date.getDate() - 1)
        date = moment(new Date(date)).format("YYYY-MM-DD");
        let result = await TaskService.StartTimeTrack(
          userinfo.email_id,
          date,
          timerObj.jobId
        );
        if (result.response.errors) {
          alert(result.response.errors.message);
        } else {
          let res = await TaskService.GetCurrentlyRunningTimer();
          if (res) {
            setTimeTrackInterv(
              setInterval(() => {
                let nowTime = new Date(Date.now());
                let diff = nowTime.getTime() - timeTrackInitialTime.getTime();
                diff = diff / 1000;
                timeTrackInitialTime = new Date(nowTime);
                setTimerTimeTrack((prev) => prev + diff);
              }, 1000)
            );
            let startTime = new Date(Date.now());
            startTime.setTime(startTime.getTime() + res.diff * 1000);
            timeTrackInitialTime = new Date(startTime);
            setTimerTimeTrack((prev) => prev + res.diff);
            setTimeTrackClock(true);
            setTimerObj(res);
            localStorage.setItem("IsTimeTrackRunning", true);
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getJobsNames = () => {
    let list = [];
    allJobs.forEach((one) => list.push(one.name));
    return list;
  };

  // const startStopClock = async () => { 
  //   entry.current=null;
  //   if (clockisActive) {
  //     let checkOut =  convertToUSTime(new Date()) 
  //     let TimesheetDate=convertToUSTime(new Date(lastEntry.in));
  //     // checkOut=TimesheetDate+", "+moment(checkOut, ["h:mm A"]).format("HH:mm:ss");
  //     let checkIn= convertToUSTime(new Date(lastEntry.in));
  //     let timeObj=getWorkingHoursAndMinutes(new Date(checkIn), new Date(checkOut));
  //     let TotalHours = formateNoInDecimalString(timeObj.hours)+":"+formateNoInDecimalString(timeObj.minutes);
  //     let payload={ 
  //       checkOut:checkOut,
  //       TotalHours:TotalHours,
  //       ROWID:lastEntry.ROWID
  //     }
  //     await getDeviceId(navigator).then(add=>{
  //       payload.CheckOutLocation = add;
  //       payload.DeviceId = checkDeviceIdCookie();
  //       // attendanceObj.CheckOutLocation = add;
  //     }).catch(e=>{
  //       console.log(e);
  //     })
  //     await attendance
  //       .UpdateAttendence(lastEntry.ROWID, payload )
  //       .then((result) => {
  //         console.log(result);
  //         // getLastAttendece();
  //         setLastEntry(null);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
       
  //   } else {
  //     let checkIn =  convertToUSTime(new Date());
  //     let TimesheetDate= checkIn;
  //     let UserName =userInfo.first_name+" "+userInfo.last_name;
  //     let payload={
  //       UserName:UserName,
  //       TimesheetDate:TimesheetDate,
  //       checkIn:checkIn,
  //       checkOut:'-',
  //       email:userInfo.email_id
  //     }
  //     await getDeviceId(navigator).then(add=>{
  //       payload.CheckInLocation = add;
  //       payload.DeviceId = checkDeviceIdCookie();
  //     }).catch(e=>{
  //       console.log(e);
  //     })


  //     await attendance
  //       .CreateAttendence(checkIn, "", userInfo.email_id,payload)
  //       .then((result) => { 
  //         if(result.data.data.length>0){
  //           let obj = {
  //             in:  modifyTimezoneString(result.data.data[0].Attendance.checkIn,"MM/DD/YYYY HH:mm:ss"),
  //             out:  modifyTimezoneString(result.data.data[0].Attendance.checkOut,"MM/DD/YYYY HH:mm:ss"),
  //             ROWID: result.data.data[0].Attendance.ROWID
  //           };
  //           setLastEntry(obj);
  //         } else {
  //           setLastEntry(null);
  //         }
  //       // getLastAttendece();
  //       console.log(result);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }
  // };

  const formatTime = (timer) => {
    const getSeconds = `0${timer % 60}`.slice(-2);
    const minutes = `${Math.floor(timer / 60)}`;
    const getMinutes = `0${minutes % 60}`.slice(-2);
    const getHours = `0${Math.floor(timer / 3600)}`.slice(-2);

    return `${getHours}:${getMinutes}:${getSeconds} Hrs`;
  };

  const showMinutesInHoursAndMins = (totalMinutes) => {
    var sec_num = parseInt(totalMinutes, 10); // don't forget the second param
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - hours * 3600) / 60);
    var seconds = sec_num - hours * 3600 - minutes * 60;

    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    return hours + ":" + minutes;
  };

  const getGroupVal = (groupValue) => {
    if (groupValue) {
      groupValue = groupValue.split("-");
      groupValue = groupValue[groupValue.length - 1];
      return groupValue;
    }
  };

  const getContentForSearchTask = () => {
    return (
      <div className="row task-filter-hovr">
        <div className="col-sm-12 ">
          <CustumSelect
            addFlag={false}
            placeholder={"Select Client"}
            value={searchInputData.Client ? searchInputData.Client : []}
            onChange={(value) => {
              let allDeals = [...cacheDeals];
              const data = { ...searchInputData };
              allDeals = allDeals.filter((one) => {
                if (one.Account_Name && one.Account_Name.name == value) {
                  let filterClient = allClients.filter(
                    (client) => client.id == one.Account_Name.id
                  );
                  if (filterClient.length > 0) {
                    filterClient = filterClient[0];
                    if (filterClient.Project_ID) {
                      one.Project_ID =
                        one.Project_ID + "," + filterClient.Project_ID;
                    }
                  }
                  return one;
                }
              });
              data["Client"] = value;
              setSearchInputData(data);
              setFilterDeals(allDeals);
            }}
            items={allClients.map((one) => one.Account_Name)}
          />
        </div>
        <div className="col-sm-12 mt-2">
          <CustumSelect
            addFlag={false}
            placeholder={"Select Deal"}
            value={searchInputData.Deal ? searchInputData.Deal : []}
            onChange={(value) => {
              let pipelines = [...cachePipeline];
              let data = { ...searchInputData };
              let filterDeals1 = filterDeal.filter(
                (one) => one.Deal_Name == value
              );
              if (filterDeals1) {
                filterDeals1 = filterDeals1[0];
                let ids = filterDeals1.Project_ID.split(",");
                ids = ids.filter((id) => id);
                pipelines = pipelines.filter((one) => {
                  if (ids.includes(one.id_string)) {
                    return one;
                  }
                });
              }
              data["Deal"] = value;
              setSearchInputData(data);
              setFilterPipeline(pipelines);
            }}
            items={filterDeal.map((one) => one.Deal_Name)}
          />
        </div>
        <div className="col-sm-12 mt-2">
          <CustumSelect
            addFlag={false}
            placeholder={"Select Pipeline"}
            value={searchInputData.Pipeline ? searchInputData.Pipeline : []}
            onChange={(value) => {
              let data = { ...searchInputData };
              data["Pipeline"] = value;
              setSearchInputData(data);
              let selectedPipeline = filterPipeline.filter((one) =>
                one.name.includes(value)
              );
              if (selectedPipeline.length > 0) {
                selectedPipeline = selectedPipeline[0];
                TaskService.GetTaskList(selectedPipeline.id_string)
                  .then((result) => {
                    setTaskList(result);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }
            }}
            items={filterPipeline.map((one) => getGroupVal(one.name))}
          />
        </div>
        <div className="col-sm-12 mt-2">
          <CustumSelect
            addFlag={false}
            placeholder={"Select Milestone"}
            value={searchInputData.Milestone ? searchInputData.Milestone : []}
            onChange={(value) => {
              let data = { ...searchInputData };
              let allTasks = [...allTask];
              data["Milestone"] = value;
              let filterTaskList = tasklist.filter((one) => one.name == value);
              if (filterTaskList.length > 0) {
                filterTaskList = filterTaskList[0];
                allTasks = allTasks.filter(
                  (one) =>
                    one.tasklist &&
                    one.tasklist.id_string == filterTaskList.id_string
                );
                setFilterTaskList(allTasks);
              }
              setSearchInputData(data);
            }}
            items={tasklist.map((one) => one.name)}
          />
        </div>

        <div className="col-sm-12 mt-2">
          <CustumSelect
            addFlag={false}
            placeholder={"Select Task"}
            value={searchInputData.Task ? searchInputData.Task : []}
            onChange={(value) => {
              let all = [...filterTaskList];
              let list = [...allJobs];
              all = all.filter((one) => one.name == value);
              let data = { ...searchInputData };
              data["Task"] = value;
              if (all.length > 0) {
                all = all[0];
                list = list.filter(
                  (one) => one.zProjectsJobRefId == all.id_string
                );
                if (list.length > 0) {
                  setTimerObj({
                    jobId: list[0].jobId,
                    jobName: list[0].jobName,
                    selectedTask: true,
                  });
                  // setSelectedTask(list[0]);
                }
              }

              setSearchInputData(data);
            }}
            items={filterTaskList.map((one) => one.name)}
          />
        </div>
      </div>
    );
  };

  const getLastAttendece = () => {
    attendance
      .GetLastAttendence(60 * 10)
      .then((result) => {
        result.data.response.result = result.data.response.result.filter(
          (one) => one.emailId == userInfo.email_id
        );
        let todayDate = moment().format("YYYY-MM-DD"); //new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getDate();
        if (result.data.response.result.length > 0) {
          let entry = result.data.response.result[0].entries;
          entry =
            entry.filter((one) => one[todayDate]).length > 0
              ? entry.filter((one) => one[todayDate])[0]
              : [];
          entry = entry[todayDate] ? entry[todayDate].attEntries : [];
          if (entry.length > 0) {
            entry = entry.sort(
              (a, b) =>
                new Date(moment(a.checkInTime, "DD/MM/YYYY HH:mm:ss")) -
                new Date(moment(b.checkInTime, "DD/MM/YYYY HH:mm:ss"))
            );
            let lastEntry = entry[entry.length - 1];
            let obj = {
              in: lastEntry.checkInTime,
              out: lastEntry.checkOutTime,
            };
            setLastEntry(obj);
          } else {
            setLastEntry(null);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const spacebetweenAMPM = (time) => {
    if (time && time.endsWith("AM")) {
      time = time.replace("AM", " AM");
    }
    if (time && time.endsWith("PM")) {
      time = time.replace("PM", " PM");
    }
    return time;
  };

  const handleOnOpenTask = (obj) => {
    let tasks = [...allTask];
    let jobs = [...allJobs];
    let alldeals = [...cacheDeals];
    let projectJobs = jobs.filter((job) => job.projectId == obj.projectId);
    let taskJobs = jobs.filter((job) => job.jobId == obj.jobId);
    if (projectJobs.length > 0) {
      projectJobs = projectJobs[0];
      alldeals = alldeals.filter((one1) => {
        let ids = one1.Project_ID;
        if (ids) {
          ids = ids.split(",");
          ids = ids.filter((id) => id);
          if (ids.includes(projectJobs.zProjectsProjectRefId)) {
            return one1;
          }
        }
      });
      if (alldeals.length > 0) {
        setDealObj(alldeals[0]);
      }
    }
    if (taskJobs.length > 0) {
      taskJobs = taskJobs[0];
      tasks = tasks.filter(
        (one1) => one1.id_string == taskJobs.zProjectsJobRefId
      );
      if (tasks.length > 0) {
        setTaskData(tasks[0]);
        setOpenTaskModal(true);
        setTaskAction("Edit");
      }
    }
  };

  const renderTaskModal = () => {
    return (
      <TaskModal
        dealObj={dealObj}
        invertColor={invertColor}
        // taskDocs={allTask}
        setTimerPrevTimeTrack={setTimerPrevTimeTrack}
        setTimerTimeTrack={setTimerTimeTrack}
        timerTimeTrack={timerTimeTrack}
        timerObj={timerObj}
        startstopClockTimeTrack={startstopClockTimeTrack}
        timeTrackFlag={timeTrackClock}
        initializeTimeTrack={initializeTimeTrack}
        // handleClickSubtaskEdit={handleClickSubtaskEdit}
        // DependencyDropdownData={DependencyDropdownData}
        // taskList={taskList}
        // setMainTaskId={setMainTaskId}
        // clients={allCli}
        // taskLocation={props.taskLocation}
        // getAllTasks={props.getAllTasks}
        // evaluateDepedncyAndSuccession={evaluateDepedncyAndSuccession}
        // handleOpenTaskModel={handleOpenTaskModel}
        parentTaskObj={taskData}
        // dependencyList={dependencyList}
        // getTaskDependencyData={getTaskDependencyData}
        taskCreateLocation="Deals"
        // id={props.id}
        allProject={cachePipeline}
        style="taskdetail-modal-subtask"
        // setTaskAction={setTaskAction}
        // setParentObject={setParentObject}
        getallTasks={getAllTask}
        taskDocs={allTask}
        task={taskData}
        taskType={"task"}
        taskAction={taskAction}
        nestedModal={openTaskModal}
        handleCloseTaskModel={() => setOpenTaskModal(false)}

        // pipeline={groupByField}
        // handleCreateDuplicateTask={handleCreateDuplicateTask}
      />
    );
  };

  const getTotalNotifyCount = () => {
    let filterCount = notificationList.filter(
      (one) => !one.Notifications.IsRead
    );
    filterCount = filterCount.filter((one) => !one.Notifications.IsArchive);
    if (filterCount.length > 0) {
      return filterCount.length;
    } else {
      return "";
    }
  };
  async function handleSettingChange(e,module,index){
    let temp=[...notificationFilter];
    // setLoader(true);
     
    if(e){
      temp[index].settingObj.active=true;
      temp[index].settingObj.lastActiveTime=convertToUSTime(new Date());
    }else{
      temp[index].settingObj.active=false;
      temp[index].settingObj.lastDisableTime=convertToUSTime(new Date());
    }
    let userinfo = JSON.parse(localStorage.getItem("userinfo"));
    setLoader(true);
    if(helperOpsData.find(d=>d.module=="NotificationFilter" && d.userId==userinfo.email_id)){
      await AwsServerService.DeleteHelperOps([userinfo.email_id],{module:"NotificationFilter"}).then(async res=>{
        await AwsServerService.SaveHelperOps(notificationFilter,"NotificationFilter",userinfo.email_id);
        setLoader(false)
      }).catch(e=>{
        console.log(e);
        setLoader(false)
      })
    }else{
      await AwsServerService.SaveHelperOps(notificationFilter,"NotificationFilter",userinfo.email_id).then(res=>{
        setLoader(false)
      }).catch(e=>{
        setLoader(false)
        console.log(e);
      })
    }
    // setNotifcationFilter(temp);
  }
  function getCollapseOptions(){
    return <div className="Module-Selection-Wrapper Do-Not-Close" onClick={e=>{
      e.stopPropagation();
      e.preventDefault();
    }}>
      {/* <div className="Module-Selection-Header">Notification Control</div> */}
      {notificationFilter && notificationFilter.map((m,i)=>{
        return (<div className="Module-Wrapper">
        <span style={{marginRight: "5px"}}>{m.name}</span>
        <div>
        <AntSwitch size="default" 
         checkedChildren={<CheckOutlined />}
         unCheckedChildren={<CloseOutlined />}
        defaultChecked checked={m.settingObj?.active?m.settingObj?.active:false} 
        onClick={e=>handleSettingChange(e,"NotificationFilter",i)} />
          {/* <input checked={m.settingObj?.active?m.settingObj?.active:false} type="checkbox" 
          onClick={e=>handleSettingChange(e,"NotificationFilter",i)} /> */}
        </div>
      </div>)
      })}
    </div>
  }
  
  
  function getSettingPopOver(){
    const menu = (
      <Menu onClick={e=>{
        // e.preventDefault();
        // e.stopPropagation();
      }}>
        <Menu.Item key="0" icon={<> </>}
          onClick={async (e) => {
            // e.preventDefault()
            // e.stopPropagation();
            setLoader(true);
            let list = [...notificationCacheList];
            // list = list.filter(one => !one.Notifications.IsArchive);
            let doArr = [];
            list.forEach((one) => {
              if (notifyTabKey == "Unarchive") {
                one.Notifications.IsArchive = true;
              }
              if (notifyTabKey == "Archive") {
                one.Notifications.IsArchive = false;
              }
              doArr.push(
                NotificationAPI.UpdateNotification(
                  one.Notifications
                )
              );
            });
            await Promise.all(doArr)
              .then((result) => {
                getNotification();
                setLoader(false);
              })
              .catch((err) => {
                console.log(err);
                setLoader(false);
              });
          }}
        >{notifyTabKey == "Unarchive"? " Archive All": "Active All"}</Menu.Item>
        <Menu.Item key="1" icon={<> </>} onClick={(e) => {
          // e.preventDefault()
          // e.stopPropagation();
          setLoader(true);
          let list = [...notificationCacheList];
          list = list.filter(
            (one) => !one.Notifications.IsRead
          );
          let doArr = [];
          list.forEach((one) => {
            one.Notifications.IsRead = true;
            doArr.push(
              NotificationAPI.UpdateNotification(
                one.Notifications
              )
            );
          });
          Promise.all(doArr)
            .then((result) => {
              getNotification();
              setLoader(false);
            })
            .catch((err) => {
              console.log(err);
              setLoader(false);
            });
        }}>Mark all as read</Menu.Item>
        <SubMenu key="sub1" icon={<LeftOutlined className="submenu-arrow" />} title={"Notification Control"}>
          {getCollapseOptions()}
        </SubMenu>
      </Menu>
    );
    return <>
      <AntDropDown
        style={{ color: "none" }}
        className="Notification-Setting-Icon"
        overlayClassName="Notification-Setting-Popover Do-Not-Close"
        // arrow={true}
        overlay={menu}
        // menu={{
        //   items,
        // }}
        trigger={["click"]}
      >
        <img style={{ height: "1rem" }} src={SettingIcon} />
      </AntDropDown>
    </>
  }
  if (currentUser) {
    return (
      <AppDataRequired.Provider value={appDataContext}>
        <div className="container-box">
          {renderTaskModal()}
          <EmailReminder
            // isReminderPopupOpen={isReminderPopupOpen}
            // setIsReminderPopupOpen={setIsReminderPopupOpen}
            // setReminderData={setReminderData}
            // reminderData={reminderData}
          />
          <ReminderListPopUp history={history} triggeredReminders={triggeredReminders} setTriggeredReminders={setTriggeredReminders} allReminderData={allReminderData} userReminderObj={allReminderDataObject} open={openReminderListModal} onClose={e=>{
            setOpenReminderListModal({status:false,data:null})
          }} />
          {timeLogDeleteWarning()}
          {loader ? <Loader /> : <></>}

          <div>
            <Navbar style={{ background: "#018EE0" }} expand="lg">
              <img className="logo-img" src={logo} />
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse className="pad-left-40" id="basic-navbar-nav">
                <Nav className="mr-auto header-navbar">
                  <Nav.Link onClick={() => history.push({ pathname: "/", state: {} })} className="nav-single-item">
                    Home
                  </Nav.Link>

                  <NavDropdown
                    className="height-48 nav-dropdown-item"
                    title="Contacts"
                    id="basic-nav-dropdown"
                  >
                    <ul
                      style={{ backgroundColor: "#0180C7" }}
                      type="none"
                      className="header-ul"
                    >
                      <li>
                        <NavDropdown.Item
                          onClick={() =>
                            history.push({
                              pathname: "/clientlist/Accounts/listview",
                              state: { module: "Accounts", action: "listview" },
                            })
                          }
                        >
                          Clients
                        </NavDropdown.Item>
                      </li>
                      <li>
                        <NavDropdown.Item
                          onClick={() =>
                            history.push({
                              pathname: "/vendorlist/Vendors/listview",
                              state: { module: "Vendors", action: "listview" },
                            })
                          }
                        >
                          {" "}
                          Vendor
                        </NavDropdown.Item>
                      </li>
                      <li>
                        <NavDropdown.Item>
                          Leads
                        </NavDropdown.Item>
                      </li>
                      <NavDropdown.Divider />

                      <li>
                        <NavDropdown.Item>
                          Contacts
                        </NavDropdown.Item>
                      </li>
                    </ul>
                  </NavDropdown>

                  <NavDropdown
                    className="height-48 nav-dropdown-item"
                    title="Projects"
                    id="basic-nav-dropdown"
                  >
                    <ul
                      style={{ backgroundColor: "#0180C7" }}
                      type="none"
                      className="header-ul"
                    >
                      <li>
                        <NavDropdown.Item
                          onClick={() =>
                            history.push({
                              pathname: "/deallist/Deals/listview",
                              state: { module: "Deals", action: "listview" },
                            })
                          }
                        >
                          Deals
                        </NavDropdown.Item>
                      </li>
                      <li>
                        <NavDropdown.Item
                          onClick={() =>
                            history.push({
                              pathname: "/project/Tasks/defaultView",
                              state: { module: "Tasks", action: "defaultView" },
                            })
                          }
                        >
                          Tasks
                        </NavDropdown.Item>
                      </li>

                      <NavDropdown.Divider />
                      <li>
                        <NavDropdown.Item> Quotes</NavDropdown.Item>
                      </li>
                    </ul>
                  </NavDropdown>
                  <Nav.Link className="nav-single-item">Accounting</Nav.Link>
                  <NavDropdown
                    className="height-48 nav-dropdown-item"
                    title="HR"
                    id="basic-nav-dropdown"
                  >
                    <ul
                      style={{ backgroundColor: "#0180C7" }}
                      type="none"
                      className="header-ul"
                    >
                      <li>
                        <NavDropdown.Item
                          onClick={() =>
                            history.push({
                              pathname: "/hr/timeclock",
                              state: { module: "TimeClock", action: "listview" },
                            })
                          }
                        >
                          Time Clock
                        </NavDropdown.Item>
                      </li>
                      <li>
                        {/* <NavDropdown.Item

                        >
                          Employees Calender
                        </NavDropdown.Item> */}
                      </li>
                    </ul>
                  </NavDropdown>
                  <Nav.Link
                    onClick={() =>
                      history.push({
                        pathname: "/access/d/Document/listview",
                        state: { action: "listview", module: "Documents" },
                      })
                    }
                    className="nav-single-item"
                  >
                    Documents
                  </Nav.Link>
                  <Nav.Link
                    onClick={() =>
                      history.push({
                        pathname: "/access/Calendar/listview",
                        state: { action: "listview", module: "Calendar" },
                      })
                    }
                    className="nav-single-item"
                  >
                    Calendar
                  </Nav.Link>
                  <Nav.Link
                    onClick={() =>
                      history.push({
                        pathname: "/access/e/Email/listview",
                        state: { action: "listview", module: "Email" },
                      })
                    }
                    className="nav-single-item"
                  >
                    Emails
                  </Nav.Link>
                </Nav>

                <Form inline>
                  <FormGroup
                    className="display-none"
                  >
                    <FormControl
                      type="text"
                      placeholder="Search"
                      className="mr-sm-2 pad-fix search-header"
                    />
                    <FontAwesomeIcon
                      className="fontawsome-text mar-right-20 header-search-icon"
                      icon={faSearch}
                    />
                  </FormGroup>

                  <FontAwesomeIcon
                    className="fontawsome-text mar-right-20"
                    icon={faSearch}
                  />

                  <div
                    className={
                      clockisActive
                        ? "clock-button clock-red"
                        : "clock-button clock-green"
                    }
                    onClick={()=>{
                      setAppRefreshed(false)
                      setClockIsActive(!clockisActive)
                    }}
                  >
                    <span>Check-{clockisActive ? "out" : "in"}</span>
                    <FontAwesomeIcon
                      className={
                        clockisActive
                          ? "clockicon-align-red"
                          : "clockicon-align-green"
                      }
                      icon={faClock}
                    />
                    <div> {clockisActive ? <ClockInTime refreshTimeclock={refreshTimeclock} 
                    setRefreshTimeclock={setRefreshTimeclock}
                    appRefreshed={appRefreshed} userInfo={userInfo} 
                    clockisActive={clockisActive} setClockIsActive={setClockIsActive}/> 
                    :<ClockInTime refreshTimeclock={refreshTimeclock} 
                    setRefreshTimeclock={setRefreshTimeclock} appRefreshed={appRefreshed}userInfo={userInfo}
                    clockisActive={clockisActive} setClockIsActive={setClockIsActive}/>}</div>
                  </div>
                  <div style={{display:"flex",position:"relative"}}>
                    <Reminders
                      history={history}
                      title={"Reminders"}
                      data={allReminderData}
                    >
                      <div className={`Reminder-Icon-Header ${allReminderData?.filter(e=>e.status!="true" && e.triggered=="true" && e.userEmail==userInfo.email_id )?.length>0 ? 'has-alarm' : ''}`}
                        // onClick={e=>{
                        //   setOpenReminderListModal({status:true,data:""});
                        // }}
                      >
                        <i className={`alarm-icon`}></i>
                        <img src={ReminderSvg}></img>
                        <span className="notification-count">
                            {allReminderData?.filter(e=>e.status!="true" && e.userEmail==userInfo.email_id)?.length>0?allReminderData.filter(e=>e.status!="true" && e.userEmail==userInfo.email_id)?.length:''}
                        </span>
                      </div>
                    </Reminders>
                  </div>
                  <Dropdown className="notificaton"
                    show={open}
                    onToggle={e => {
                        setOpen(true);
                    }}
                  >
                    <Dropdown.Toggle
                      id="dropdown-editcolumn"
                      className="notification-drop"
                    >
                      <FontAwesomeIcon
                        className="fontawsome-text "
                        icon={faBell}
                        // onClick={() =>
                        //   setNotifcationFilter([
                        //     { name: "TASK", isVisible: true, isSearch: true },
                        //     { name: "MENTION", isVisible: true, isSearch: true },
                        //     { name: "NOTES", isVisible: true, isSearch: true },
                        //     { name: "ASSIGNEE", isVisible: true, isSearch: true },
                        //     { name: "REMINDER", isVisible: true, isSearch: true },
                        //     { name: "MESSAGE", isVisible: true, isSearch: true },
                        //     { name: "ATTACHMENT", isVisible: true, isSearch: true },
                        //   ])
                        // }
                      />
                      <span className="notification-count">
                        {getTotalNotifyCount()}
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="drop-menu-notificatn">
                      <OtsideClickClosingWrapper
                        close={e => {
                            if (open) {
                                setOpen(!open);
                            }
                        }}
                    >
                      <div className="content-div">
                          Notifications
                          {getSettingPopOver()}
                        </div>

                        <div className="notificationArea">
                          <div className="notification-topMenu">
                            {notificationList.filter((one) => one.isCheck).length >
                            0 ? (
                              <div className="checkedList-actions">
                                <div className="d-flex">
                                  <span
                                    onClick={() => {
                                      // let msgs = messageList.filter((e) => e.isCheck);
                                      // changeReadStatusOfMessage(msgs, true)
                                      // setMessageData({});
                                      // setOpenPopupOnIconChange(false)
                                    }}
                                    className="text-blue"
                                  >
                                    <FontAwesomeIcon icon={faCheck} />
                                    Mark as Read
                                  </span>

                                  <span
                                    onClick={() => {
                                      // let msgs = messageList.filter((e) => e.isCheck);
                                      // changeReadStatusOfMessage(msgs, false)
                                      // setMessageData({});
                                      // setOpenPopupOnIconChange(false)
                                    }}
                                    className="text-blue"
                                    style={{ marginLeft: "20px" }}
                                  >
                                    Mark as Unread
                                  </span>
                                </div>

                                <span
                                  onClick={() => {
                                    // const list = [...messageList];
                                    // list.forEach((e) => {
                                    //   if (e.isCheck) {
                                    //     delete e.isCheck;
                                    //   }
                                    // })
                                    // setMessageList(list);
                                    // setMessageData({});
                                    // setOpenPopupOnIconChange(false)
                                  }}
                                  className="text-blue"
                                >
                                  {" "}
                                  Cancel
                                </span>
                              </div>
                            ) : (
                              <div>
                                <div className="notificationFilters">
                                  {/* <UncontrolledDropdown>
                                    <DropdownToggle className='notification-link filterBtn' caret>
                                      Filter
                                    </DropdownToggle>
                                    <DropdownMenu className='p-0'>
                                      <DropdownItem>
                                        <input
                                          type='checkbox'
                                          onChange={handleOnChangeFilterNotification}
                                          checked={filterObj['All']} name='All'
                                        />
                                        Select All
                                      </DropdownItem>
                                      <DropdownItem>
                                        <input
                                          type='checkbox'
                                          onChange={handleOnChangeFilterNotification}
                                          checked={filterObj['Notes']} name='Notes'
                                        />
                                        Notes
                                      </DropdownItem>
                                      <DropdownItem>
                                        <input
                                          type='checkbox'
                                          onChange={handleOnChangeFilterNotification}
                                          checked={filterObj['Mention']} name='Mention'
                                        />
                                        Mention
                                      </DropdownItem>
                                      <DropdownItem>
                                        <input
                                          type='checkbox'
                                          onChange={handleOnChangeFilterNotification}
                                          checked={filterObj['Task']} name='Task'
                                        />
                                        Tasks
                                      </DropdownItem>
                                      <DropdownItem>
                                        <input
                                          type='checkbox'

                                          onChange={handleOnChangeFilterNotification}
                                          checked={filterObj['Assignee']} name='Assignee'
                                        />
                                        Assignee
                                      </DropdownItem>


                                    </DropdownMenu>
                                  </UncontrolledDropdown> */}
                                  <AddColumn
                                    Label={<FontAwesomeIcon icon={faFilter} />}
                                    columns={notificationFilter}
                                    onChangeColumn={(columns) => {
                                      setNotifcationFilter(columns);
                                    }}
                                  />

                                  {/* <AntdPopover
                                    placement="bottom"
                                    trigger="click"
                                    // isOpen={employeeNotePopup}
                                    content={<div>
                                    

                                      <div className="row task-filter-hovr">
                                        <div onClick={() => {
                                          // setActivityFilterBy(activity)
                                        }} className="col-sm-9">
                                          <input type="checkbox" />
                                          <a
                                          >Select All
                                          </a>
                                        </div>
                                      </div>
                                      <hr className="hrcls"></hr>
                                      <div className="row task-filter-hovr">
                                        <div onClick={() => {
                                          // setActivityFilterBy(activity)
                                        }} className="col-sm-9">
                                          <a
                                          >Notes
                                          </a>
                                        </div>
                                      </div>
                                      <hr className="hrcls"></hr>
                                      <div className="row task-filter-hovr">
                                        <div onClick={() => {
                                          // setActivityFilterBy(activity)
                                        }} className="col-sm-9">
                                          <a
                                          >Mention
                                          </a>
                                        </div>
                                      </div>
                                      <hr className="hrcls"></hr>
                                      <div className="row task-filter-hovr">
                                        <div onClick={() => {
                                          // setActivityFilterBy(activity)
                                        }} className="col-sm-9">
                                          <a
                                          >Task
                                          </a>
                                        </div>
                                      </div>
                                      <hr className="hrcls"></hr>
                                      <div className="row task-filter-hovr">
                                        <div onClick={() => {
                                          // setActivityFilterBy(activity)
                                        }} className="col-sm-9">
                                          <a
                                          >Assignee
                                          </a>
                                        </div>
                                      </div>
                                      <hr className="hrcls"></hr>
                                      <div className="row task-filter-hovr">
                                        <div onClick={() => {
                                          // setActivityFilterBy(activity)
                                        }} className="col-sm-9">
                                          <a
                                          >Reminder
                                          </a>
                                        </div>
                                      </div>
                                      <hr className="hrcls"></hr>
                                      <div className="row task-filter-hovr">
                                        <div onClick={() => {
                                          // setActivityFilterBy(activity)
                                        }} className="col-sm-12 ">
                                          <a className="btn btn-sm btn-primar float-right"
                                          >Apply
                                          </a>
                                        </div>
                                      </div>

                                    </div>}
                                  >
                                    <DropdownToggle className='notification-link filterBtn' caret>
                                      Filter
                                    </DropdownToggle>
                                  </AntdPopover> */}

                                  {/* <UncontrolledDropdown>
                                    <DropdownToggle
                                      className="notification-link"
                                      caret
                                    >
                                      Actions
                                    </DropdownToggle>
                                    <DropdownMenu className="p-0 notification-action">
                                      <DropdownItem
                                        onClick={() => {
                                          let list = [...notificationCacheList];
                                          // list = list.filter(one => !one.Notifications.IsArchive);
                                          let doArr = [];
                                          list.forEach((one) => {
                                            if (notifyTabKey == "Unarchive") {
                                              one.Notifications.IsArchive = true;
                                            }
                                            if (notifyTabKey == "Archive") {
                                              one.Notifications.IsArchive = false;
                                            }
                                            doArr.push(
                                              NotificationAPI.UpdateNotification(
                                                one.Notifications
                                              )
                                            );
                                          });
                                          Promise.all(doArr)
                                            .then((result) => {
                                              getNotification();
                                            })
                                            .catch((err) => {
                                              console.log(err);
                                            });
                                        }}
                                        className="cursor-pointer"
                                      >
                                        {notifyTabKey == "Unarchive"
                                          ? " Archive All"
                                          : "Active All"}
                                      </DropdownItem>
                                      <DropdownItem
                                        onClick={() => {
                                          let list = [...notificationCacheList];
                                          list = list.filter(
                                            (one) => !one.Notifications.IsRead
                                          );
                                          let doArr = [];
                                          list.forEach((one) => {
                                            one.Notifications.IsRead = true;
                                            doArr.push(
                                              NotificationAPI.UpdateNotification(
                                                one.Notifications
                                              )
                                            );
                                          });
                                          Promise.all(doArr)
                                            .then((result) => {
                                              getNotification();
                                            })
                                            .catch((err) => {
                                              console.log(err);
                                            });
                                        }}
                                        className="cursor-pointer"
                                      >
                                        Mark all as read
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </UncontrolledDropdown> */}
                                  <div className="position-relative about-deal-search notification-search-icon">
                                  <input
                                    type="text"
                                    placeholder="Search"
                                    className="pad-fix search-filter-deals"
                                    // value={SearchedItem}
                                    onChange={(e) => {
                                      let list = [...notificationCacheList];
                                      if (e.currentTarget.value) {
                                        let text =
                                          e.currentTarget.value.toLowerCase();
                                        list = list.filter((one) => {
                                          if (
                                            one.Notifications.Sender_Name.toLowerCase().includes(
                                              text
                                            ) ||
                                            one.Notifications.Reciever_Name.toLowerCase().includes(
                                              text
                                            ) ||
                                            one.Notifications.Text.toLowerCase().includes(
                                              text
                                            ) ||
                                            one.Notifications.Type.toLowerCase().includes(
                                              text
                                            ) ||
                                            one.Notifications.Title.toLowerCase().includes(
                                              text
                                            )
                                          ) {
                                            return one;
                                          }
                                        });
                                      }
                                      setNotificationList(list);
                                    }}
                                  />
                                  <FontAwesomeIcon
                                    className="search-ico"
                                    icon={faSearch}
                                  />
                                </div>
                                </div>

                                {/* <div className="position-relative about-deal-search notification-search-icon">
                                  <input
                                    type="text"
                                    placeholder="Search"
                                    className="pad-fix search-filter-deals"
                                    // value={SearchedItem}
                                    onChange={(e) => {
                                      let list = [...notificationCacheList];
                                      if (e.currentTarget.value) {
                                        let text =
                                          e.currentTarget.value.toLowerCase();
                                        list = list.filter((one) => {
                                          if (
                                            one.Notifications.Sender_Name.toLowerCase().includes(
                                              text
                                            ) ||
                                            one.Notifications.Reciever_Name.toLowerCase().includes(
                                              text
                                            ) ||
                                            one.Notifications.Text.toLowerCase().includes(
                                              text
                                            ) ||
                                            one.Notifications.Type.toLowerCase().includes(
                                              text
                                            ) ||
                                            one.Notifications.Title.toLowerCase().includes(
                                              text
                                            )
                                          ) {
                                            return one;
                                          }
                                        });
                                      }
                                      setNotificationList(list);
                                    }}
                                  />
                                  <FontAwesomeIcon
                                    className="search-ico"
                                    icon={faSearch}
                                  />
                                </div> */}
                              </div>
                            )}
                          </div>
                          <Tabs
                            activeKey={notifyTabKey}
                            onSelect={(k) => setNotifyTabKey(k)}
                            className="job-middle-tab job-middle-tabs progress-inner-tabs"
                          >
                            <Tab eventKey="Unarchive" title="Active">
                              <ul
                                className="list-unstyled notificationList col"
                                style={{ overflowX: "hidden" }}
                              >
                                {getNotificationRender(
                                  notificationList.filter(
                                    (one) => {
                                      
                                      let module=notificationFilter.find(f=>f.name?.toUpperCase()==one.Notifications?.Type?.toUpperCase());
                                      if(module?.settingObj?.active && module.settingObj?.lastDisableTime && module.settingObj?.lastDisableTime){
                                        let notificationTime=getDate1(one.Notifications?.CREATEDTIME)+" "+getTime1(one.Notifications?.CREATEDTIME);
                                        let lastDis=modifyTimezoneString(module.settingObj?.lastDisableTime,"MM-DD-YYYY hh:mm A");
                                        let lastActive=modifyTimezoneString(module.settingObj?.lastActiveTime,"MM-DD-YYYY hh:mm A");
                                        if(!one.Notifications.IsArchive 
                                          && ! moment(notificationTime).isBetween(moment(lastDis),moment(lastActive))
                                        ){
                                          return one;
                                        }
                                      }else{
                                        return one;
                                      }
                                    }
                                  )
                                ).map((e) => {
                                  return displayNotification(e.Notifications);
                                })}
                              </ul>
                            </Tab>
                            <Tab eventKey="Archive" title="Archive">
                              <ul
                                className="list-unstyled notificationList col"
                                style={{ overflowX: "hidden" }}
                              >
                                {getNotificationRender(
                                  notificationList.filter(
                                    // (one) => one.Notifications.IsArchive
                                    (one) => {
                                      
                                      let module=notificationFilter.find(f=>f.name?.toUpperCase()==one.Notifications?.Type?.toUpperCase());
                                      if(module?.settingObj?.active && module.settingObj?.lastDisableTime && module.settingObj?.lastDisableTime){
                                        let notificationTime=getDate1(one.Notifications?.CREATEDTIME)+" "+getTime1(one.Notifications?.CREATEDTIME);
                                        let lastDis=modifyTimezoneString(module.settingObj?.lastDisableTime,"MM-DD-YYYY hh:mm A");
                                        let lastActive=modifyTimezoneString(module.settingObj?.lastActiveTime,"MM-DD-YYYY hh:mm A");
                                        if(one.Notifications.IsArchive 
                                          && ! moment(notificationTime).isBetween(moment(lastDis),moment(lastActive))
                                        ){
                                          return one;
                                        }
                                      }
                                    }
                                  )
                                ).map((e) => {
                                  return displayNotification(e.Notifications);
                                })}
                              </ul>
                            </Tab>
                          </Tabs>
                        </div>
                    </OtsideClickClosingWrapper>
                    </Dropdown.Menu>
                  </Dropdown>

                  <Dropdown className="createnew create-actions">
                    <Dropdown.Toggle
                      id="dropdown-createnew"
                      className="createnew-drop"
                    >
                      <FontAwesomeIcon
                        className="fontawsome-text "
                        icon={faPlusCircle}
                      />
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="drop-menu-createnew">
                      <Dropdown.Item
                        // onClick={() => history.push({ pathname: '/app/project/create', state: { module: 'Deals', action: 'create' } })}
                        onClick={() => {
                          setWarningFlag(false);
                          history.push({
                            pathname: "/dealcreate/Deals/create",
                            state: { module: "Deals", action: "create" },
                          });
                        }}
                      >
                        Add Deal
                      </Dropdown.Item>
                      <Dropdown.Item
                        // onClick={() => history.push({ pathname: '/app/contact/createclient', state: { module: 'Accounts', action: 'create' } })}
                        onClick={() => {
                          history.push({
                            pathname: "/createclient/Accounts/create",
                            state: { module: "Accounts", action: "create" },
                          });
                        }}
                      >
                        Add Client
                      </Dropdown.Item>
                      <Dropdown.Item
                        // onClick={() => history.push({ pathname: '/app/contacts/createVendor', state: { module: 'Vendors', action: 'create' } })}
                        onClick={() => {
                          history.push({
                            pathname: "/vendorcreate/Vendors/create",
                            state: { module: "Vendors", action: "create" },
                          });
                        }}
                      >
                        Add Vendor
                      </Dropdown.Item>
                      <Dropdown.Item
                      //    onClick={() => {
                      //     history.push(<PVSystemInformation />)
                      // }}
                      >
                        Add PV Module
                      </Dropdown.Item>
                      <Dropdown.Item>Add PV Manufacturer</Dropdown.Item>
                      <Dropdown.Item>Add String Inverters</Dropdown.Item>
                      <Dropdown.Item>Add Micro Inverters</Dropdown.Item>
                      <Dropdown.Item>Add General Contractors</Dropdown.Item>
                      <Dropdown.Item>Add Licensed Electricians</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                  <AntdPopover
                    style={{ zIndex: 9999 }}
                    trigger="click"
                    placement="bottomLeft"
                    content={content}
                    arrow={false}
                  >
                    {localStorage.getItem("ProfilePhoto") ? (
                      <img
                        type="button"
                        id="dropdownMenuButton"
                        src={localStorage.getItem("ProfilePhoto")}
                        className="user-ico"
                      />
                    ) : (
                      <div
                        className="no-image-user"
                        type="button"
                        id="dropdownMenuButton"
                      >
                        {currentUser ? currentUser.first_name.charAt(0) : "NA"}
                      </div>
                    )}
                  </AntdPopover>
                </Form>
              </Navbar.Collapse>
            </Navbar>
            <div className="chat-box-area">
              <div id="reminder-icon">
                <UncontrolledTooltip placement="top" target="reminder-icon">
                  Reminder
                </UncontrolledTooltip>
                <img
                  onClick={() => setReminderFlag(true)}
                  className="reminder-icon-cust"
                  style={{ backgroundColor: "rgb(242, 111, 86)" }}
                  src={remIcoCutom}
                />
                {/* <FontAwesomeIcon
                  onClick={() => setReminderFlag(true)}
                  // style={{ color: "rgb(45, 204, 110)" }}
                  size="2x" icon={faBell}
                /> */}
                <Reminder
                  id="General"
                  users={allUsers}
                  show={reminderFlag}
                  onClose={() => setReminderFlag(false)}
                />
              </div>
              <UncontrolledTooltip placement="top" target="time-track-icon">
                Time Track{" "}
                {timeTrackClock && timerObj ? "/" + timerObj.jobName : ""}
              </UncontrolledTooltip>
              <AntdPopover
                trigger={"click"}
                content={
                  <div className="common-time-tracker">
                    <div id="timetracklist">
                      <div>
                        <img src={localStorage.getItem("ProfilePhoto")} />
                        {localStorage.getItem("UserName")}
                      </div>
                      {timeTrackClock
                        ? `${timerTimeTrack - timerPrevTimeTrack}`.toHHMMSS()
                        : `${timerTimeTrack}`.toHHMMSS()}

                      <FontAwesomeIcon
                        className="cancel-icon"
                        color="darkgray"
                        icon={faMinus}
                      />
                    </div>
                    <UncontrolledCollapse toggler="timetracklist">
                      <div className="time_track_scroll">
                        <table className="w-100 ">
                          <tbody>
                            {timeTrackLogs?.length > 0 &&
                              getTopPositionTimeTrack(timeTrackLogs).map(
                                (e, i) => {
                                  return (
                                    <>
                                      <UncontrolledTooltip
                                        placement="left"
                                        target={"jobname" + e.jobId}
                                      >
                                        {getTaskToolTipLabel(e)}
                                      </UncontrolledTooltip>
                                      <Row className="no-gutters user-time-track">
                                        <Col>
                                          <tr>
                                            {" "}
                                            <td>
                                              <FontAwesomeIcon
                                                style={
                                                  timerObj &&
                                                  timeTrackClock &&
                                                  e.jobId == timerObj.jobId
                                                    ? {
                                                        color: "rgb(204, 45, 45)",
                                                      }
                                                    : {
                                                        color:
                                                          "rgb(45, 204, 110)",
                                                      }
                                                }
                                                icon={
                                                  timerObj &&
                                                  timeTrackClock &&
                                                  e.jobId == timerObj.jobId
                                                    ? faStopCircle
                                                    : faPlayCircle
                                                }
                                                onClick={() => {
                                                  setTimerTimeTrack(e.totaltime);
                                                  setTimerPrevTimeTrack(
                                                    e.totaltime
                                                  );
                                                  // dispatch(addTimer(e.totaltime))
                                                  if (
                                                    timeTrackClock &&
                                                    timerObj &&
                                                    e.jobId == timerObj.jobId
                                                  ) {
                                                    startstopClockTimeTrack(
                                                      timerObj
                                                    );
                                                  } else {
                                                    if (
                                                      timeTrackClock &&
                                                      timerObj
                                                    ) {
                                                      alert(
                                                        "Another timer is already running"
                                                      );
                                                    } else {
                                                      startstopClockTimeTrack(e);
                                                    }
                                                  }
                                                }}
                                              />
                                            </td>
                                            <td
                                              onClick={() => handleOnOpenTask(e)}
                                              style={{
                                                maxWidth: "75px",
                                                cursor: "pointer",
                                                color: "rgb(24, 144, 255)",
                                              }}
                                              className="text-truncate"
                                              id={"jobname" + e.jobId}
                                            >
                                              {e.jobName}
                                            </td>
                                            <td className="text-center">
                                              {showMinutesInHoursAndMins(
                                                e.totaltime
                                              )}
                                            </td>
                                            {/* <td>
                                          {getDateMonthOnly(new Date(e.workDate))}
                                        </td> */}
                                          </tr>
                                          {/* <ul className="list-unstyled " style={{ display: 'flex' }}>

                                    <li>
                                      {e.timerLog ? (
                                        <FontAwesomeIcon
                                          style={timerObj && timeTrackClock && (e.jobId == timerObj.jobId) ? { color: "rgb(204, 45, 45)" } : { color: "rgb(45, 204, 110)" }}
                                          icon={
                                            timerObj && timeTrackClock && (e.jobId == timerObj.jobId)
                                              ? faStopCircle
                                              : faPlayCircle
                                          }
                                          onClick={() => startstopClockTimeTrack(e)}

                                        />
                                      ) : (
                                        <>
                                          <FontAwesomeIcon
                                            icon={faSlidersH}
                                          />
                                        </>
                                      )}
                                    </li>

                                    <li onClick={() => handleOnOpenTask(e)} style={{ maxWidth: '90px' }} className="text-truncate" id={"jobname" + e.jobId}>
                                      <UncontrolledTooltip target={"jobname" + e.jobId}>
                                        {e.name}
                                      </UncontrolledTooltip>
                                      {e.jobName}
                                    </li>
                                    <li>
                                      {showMinutesInHoursAndMins(e.totaltime)}
                                    </li>
                                    <li>
                                      {getDateMonthOnly(new Date(e.workDate))}
                                    </li>
                                  </ul> */}
                                        </Col>
                                        <Col sm={3} className="text-right">
                                          <ul className="list-unstyled">
                                            <li id={"View" + e.jobId}>
                                              <FontAwesomeIcon
                                                icon={faEye}
                                                className="view"
                                              />
                                            </li>

                                            <li>
                                              {/* <FontAwesomeIcon
                                            id={"Del" + e.timelogId}
                                            icon={faTrashAlt}
                                            className="delete"
                                            onClick={() => {
                                              setDeleteTimeLogObj(e);
                                              setTimeDeleteWarn(true)


                                            }}
                                          /> */}
                                            </li>
                                          </ul>
                                        </Col>
                                      </Row>
                                      <UncontrolledCollapse
                                        toggler={"View" + e.jobId}
                                      >
                                        <>
                                          {e.timearr &&
                                            e.timearr.map((one) => {
                                              return (
                                                <div className="time-tracked-duration time-track-duration-common-app">
                                                  <Row className="no-gutters user-time-track">
                                                    <Col>
                                                      <tr>
                                                        {" "}
                                                        <td>
                                                          {one.timerLog ? (
                                                            <FontAwesomeIcon
                                                              icon={faPlayCircle}
                                                            />
                                                          ) : (
                                                            <>
                                                              <FontAwesomeIcon
                                                                icon={faSlidersH}
                                                              />
                                                            </>
                                                          )}
                                                        </td>
                                                        <td
                                                          style={{
                                                            paddingLeft: "15px",
                                                          }}
                                                          className="text-center"
                                                        >
                                                          {showMinutesInHoursAndMins(
                                                            one.totaltime
                                                          )}
                                                        </td>
                                                        <td
                                                          style={{
                                                            display: "block",
                                                            width: "100%",
                                                          }}
                                                        >
                                                          {getDateMonthOnly(
                                                            new Date(one.workDate)
                                                          )}
                                                        </td>
                                                        <td>
                                                          <FontAwesomeIcon
                                                            id={
                                                              "view" + one.timerId
                                                            }
                                                            icon={faEye}
                                                          />
                                                        </td>
                                                        <td>
                                                          <FontAwesomeIcon
                                                            id={"Del" + e.timerId}
                                                            icon={faTrashAlt}
                                                            className="delete"
                                                            onClick={() => {
                                                              setDeleteTimeLogObj(
                                                                one
                                                              );
                                                              setTimeDeleteWarn(
                                                                true
                                                              );
                                                            }}
                                                          />
                                                        </td>
                                                      </tr>
                                                    </Col>
                                                    {/* <Col sm={3} className="text-right">
                                              <ul className="list-unstyled">
                                              

                                                <li id={"View" + e.timelogId}>
                                                  <FontAwesomeIcon
                                                    icon={faEye}
                                                    className="view"
                                                  />
                                                </li>

                                                <li>
                                                  <FontAwesomeIcon
                                                    id={"Del" + e.timelogId}
                                                    icon={faTrashAlt}
                                                    className="delete"
                                                    onClick={() => {
                                                      setDeleteTimeLogObj(e);
                                                      setTimeDeleteWarn(true)


                                                    }}
                                                  />
                                                </li>
                                              </ul>
                                            </Col> */}
                                                  </Row>
                                                  <UncontrolledCollapse
                                                    toggler={"view" + one.timerId}
                                                  >
                                                    {one.timers &&
                                                      one.timers.map((obj) => {
                                                        return (
                                                          <>
                                                            <div className="time-tracked-duration">
                                                              <p>
                                                                <span>
                                                                  From :
                                                                </span>{" "}
                                                                {spacebetweenAMPM(
                                                                  obj.fromTimeInTimeFormat
                                                                )}
                                                              </p>
                                                              <p>
                                                                <span>To :</span>{" "}
                                                                {spacebetweenAMPM(
                                                                  obj.toTimeInTimeFormat
                                                                )}
                                                              </p>
                                                            </div>
                                                          </>
                                                        );
                                                      })}
                                                  </UncontrolledCollapse>
                                                </div>
                                              );
                                            })}
                                        </>
                                      </UncontrolledCollapse>
                                    </>
                                  );
                                }
                              )}
                          </tbody>
                        </table>
                      </div>
                    </UncontrolledCollapse>
                    <div className="p-2 border-top">
                      <Tabs
                        className="mb-2 time_tracked_tabs"
                        id="controlled-tab-example"
                        activeKey={timeTrackKey}
                        onSelect={(k) => setTimeTrackKey(k)}
                      >
                        <Tab eventKey="timer" title="Timer">
                          <Row style={{ marginTop: "20px" }}>
                            <Col sm={12}>
                              {/* <AntdPopover placement="top" content={getContentForSearchTask()} trigger={'click'}> */}
                              <div className="form-group form-group-sm">
                                <TaskGlobalSearch
                                  onSelect={(task) => {
                                    setTimerObj({
                                      jobId: task.JobId,
                                      jobName: task.name,
                                      selectedTask: true,
                                    });
                                  }}
                                  nodeElement={
                                    <input
                                      value={
                                        timerObj && timerObj.selectedTask
                                          ? timerObj.jobName
                                          : ""
                                      }
                                      style={{ cursor: "pointer" }}
                                      type="text"
                                      className="form-control form-control-sm"
                                      placeholder="Search Task"
                                      name=""
                                    />
                                  }
                                />
                              </div>

                              {/* </AntdPopover> */}
                              {/* <CustumSelect
                              addItemAction={() => { }}
                              addItemLabel={"Create New Task"}
                              addFlag={true}
                              placeholder={'Select Task'}
                              value={timerObj ? [timerObj.jobName] : []}
                              onChange={(value) => {
                                let list = [...allJobs];
                                list = list.filter(one => one.name == value)
                                if (list.length > 0) {
                                  setTimerObj({ jobId: list[0].jobId, jobName: list[0].jobName, selectedTask: true })
                                  // setSelectedTask(list[0]);
                                }
                              }}
                              items={getJobsNames()} /> */}
                            </Col>
                          </Row>
                          <Row style={{ marginTop: "20px" }}>
                            <Col sm={6}>
                              <div className="d-flex align-items-center">
                                <FontAwesomeIcon
                                  className={
                                    timerObj && timeTrackClock
                                      ? "mr-2 timeTrackedIconPause"
                                      : "mr-2 timeTrackedIconPlay"
                                  }
                                  icon={
                                    timerObj && timeTrackClock
                                      ? faStopCircle
                                      : faPlayCircle
                                  }
                                  onClick={() => {
                                    if (timerObj && timerObj.jobId) {
                                      let filterTimeLog = timeTrackLogs.filter(
                                        (one) => one.jobId == timerObj.jobId
                                      );
                                      if (filterTimeLog.length > 0) {
                                        setTimerPrevTimeTrack(
                                          filterTimeLog[0].totaltime
                                        );
                                        setTimerTimeTrack(
                                          filterTimeLog[0].totaltime
                                        );
                                        startstopClockTimeTrack(timerObj);
                                      } else {
                                        setTimerTimeTrack(0);
                                        setTimerPrevTimeTrack(0);
                                        startstopClockTimeTrack(timerObj);
                                      }
                                    } else {
                                      swal("Please select task", {
                                        dangerMode: true,
                                      });
                                    }
                                  }}
                                />
                                <div>
                                  {timerObj && timeTrackClock
                                    ? `${timerTimeTrack}`.toHHMMSS()
                                    : `${timerTimeTrack}`.toHHMMSS()}
                                </div>
                              </div>
                            </Col>

                            <UncontrolledTooltip placement="top" target="running">
                              {getTaskToolTipLabel(timerObj)}
                            </UncontrolledTooltip>
                            <Col
                              id="running"
                              onClick={() => handleOnOpenTask(timerObj)}
                              style={{ color: "#40a9ff", cursor: "pointer" }}
                              sm={6}
                            >
                              <span>
                                {timeTrackClock && timerObj
                                  ? timerObj.jobName
                                  : ""}
                              </span>
                            </Col>
                          </Row>
                        </Tab>
                        <Tab eventKey="range" title="Range">
                          <div className="form-group form-group-sm">
                            <TaskGlobalSearch
                              onSelect={(task) => {
                                setTimerObj({
                                  jobId: task.JobId,
                                  jobName: task.name,
                                  selectedTask: true,
                                });
                              }}
                              nodeElement={
                                <input
                                  value={
                                    timerObj && timerObj.selectedTask
                                      ? timerObj.jobName
                                      : ""
                                  }
                                  style={{ cursor: "pointer" }}
                                  type="text"
                                  className="form-control form-control-sm"
                                  placeholder="Search Task"
                                  name=""
                                />
                              }
                            />
                          </div>

                          <div className="time_tracked_fields mb-2">
                            <input
                              onChange={(e) => {
                                setTimeLogDate(e.currentTarget.value);
                              }}
                              value={timeLogDate ? timeLogDate : ""}
                              type="date"
                              className="form-control form-control-sm"
                            />
                          </div>
                          <div className="time_tracked_fields mb-2">
                            <TimePicker
                              format="hh:mm a"
                              clearIcon={null}
                              disableClock={true}
                              onChange={(e) => {
                                setFromRange(e);
                              }}
                              value={FromRange}
                            />
                            -
                            <TimePicker
                              format="hh:mm a"
                              clearIcon={null}
                              disableClock={true}
                              onChange={(e) => {
                                setToRange(e);
                              }}
                              value={ToRange}
                            />
                          </div>
                          <Row>
                            <Col sm={7}>
                              <p style={{ marginLeft: "14px" }}>When: now</p>
                            </Col>
                            <Col sm={5}>
                              <span className="float-right">
                                <button
                                  className="btn btn-primary btn-small ml-2"
                                  onClick={() => {
                                    if (FromRange && ToRange && timeLogDate) {
                                      onSaveTimeRange(
                                        FromRange,
                                        ToRange,
                                        timeLogDate
                                      );
                                    } else {
                                      swal("Please Set Valid Time", {
                                        dangerMode: true,
                                      });
                                    }
                                  }}
                                >
                                  Save
                                </button>
                              </span>
                            </Col>
                          </Row>
                        </Tab>
                      </Tabs>
                    </div>
                  </div>
                }
              >
                <div
                  id="time-track-icon"
                  style={{ background: "rgb(45, 204, 110)" }}
                  className="reminder-box"
                >
                  <FontAwesomeIcon size="2x" icon={faStopwatch} />
                  {timerObj && timeTrackClock ? (
                    <span>
                      {timerObj && timeTrackClock
                        ? `${timerTimeTrack}`.toHHMMSS()
                        : ""}
                    </span>
                  ) : (
                    <></>
                  )}

                  {timerObj && timeTrackClock ? (
                    <FontAwesomeIcon
                      onClick={() => startstopClockTimeTrack(timerObj)}
                      style={
                        timerObj && timeTrackClock
                          ? { color: "rgb(204, 45, 45)" }
                          : { color: "rgb(45, 204, 110)" }
                      }
                      size="2x"
                      icon={faPauseCircle}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </AntdPopover>

              {/* <div className="d-flex">
                {GetNotifcationForUser().map((user) => {
                  if (user.Count > 0) {
                    return <>
                      <UncontrolledTooltip placement='top' target={'notify' + user.UserId}>
                        {user.Name}
                      </UncontrolledTooltip>
                      <div onClick={() => handleClickNotification(user)} id={'notify' + user.UserId} style={{ cursor: 'pointer' }} className="chatUserAlert">
                        {
                          user.ProfilePhoto !== '' ? <img src={user.ProfilePhoto} />
                            : <div
                              className='no-image-user'
                            >{user.Name ? user.Name.charAt(0) : ""}</div>
                        }
                        <span className="badge badge-light">{user.Count}</span>
                      </div>
                    </>
                  }
                })}
              </div> */}
              {chatnotificationList && displayNotificationForChat(chatnotificationList)}
              
            </div>
            {openChatModal ? (
              <OtsideClickClosingWrapper
                className="Chat-Module-Wrapper"
                close={e=>{
                  setOpenChatModal(false);
                }}
              >
                  <ChatModule
                  loader={loader}
                  refreshTheChat={refreshTheChat}
                  setRefreshChat={setRefreshChat}
                  setLoader={setLoader}
                  getNotification={getNotification}
                  allUsers={allUsers}
                  chatButtonRef={chatButtonRef}
                  notifyUser={notifyUser}
                  handleOutsideClick={handleOutsideClick}
                />
              </OtsideClickClosingWrapper>
              
            ) : (
              <></>
            )}
          </div>
          <SubHeader

            clickActionOfSaveAndCancelButton={clickActionOfSaveAndCancelButton}
            dealListTriggers={dealListTriggers}
            dealListStatus={dealListStatus}
            clientListTriggers={clientListTriggers}
            clientListStatus={clientListStatus}
            getToken={getToken}
            helperFnGetTokenFromCode={helperFnGetTokenFromCode}
            sidebarCardsStatus={sidebarCardsStatus}
            warningFlag={warningFlag}
            setWarningFlag={setWarningFlag}
            params={routesParams}
            refreshEnable={refreshEnable}
            setRefreshEnable={setRefreshEnable}
            location={location}
            history={history}
            showExitPrompt={showExitPrompt}
            setShowExitPrompt={setShowExitPrompt}
            checkType={checkType}
            setCheckType={setCheckType}
            selectedIds={selectedIds}
            setSelectedIds={setSelectedIds}
            setDeleteDealFlag={setDeleteDealFlag}
          />
          <Switch>
            <Route path="/" exact render={(props) => <></>} />
            <Route
              path="/access/e/:module/:action"
              exact
              render={(props) => (
                <Email
                  warningFlag={warningFlag}
                  clickedFromBody={false}
                  setWarningFlag={setWarningFlag}
                  {...props}
                  handleNotificationHyperlink={handleNotificationHyperlink}
                  allTask={allTask}
                  allUsers={allUsers}
                  cacheDeals={cacheDeals}
                  refreshEnable={refreshEnable}
                  setRefreshEnable={setRefreshEnable}
                  getParams={getParams}
                />
              )}
            />
            <Route
              path="/access/:module/:action"
              exact
              render={(props) => (
                <FullCalendar
                  warningFlag={warningFlag}
                  invertColor={invertColor}
                  setWarningFlag={setWarningFlag}
                  {...props}
                  history={history}
                  refreshEnable={refreshEnable}
                  setRefreshEnable={setRefreshEnable}
                  getParams={getParams}
                />
              )}
            />
            <Route
              path="/c/:module/:action/:id"
              exact
              render={(props) => (
                <ClientBody
                  setCheckType={setCheckType}
                  checkType={checkType}
                  invertColor={invertColor}
                  warningFlag={warningFlag}
                  deleteDealFlag={deleteDealFlag}
                  setDeleteDealFlag={setDeleteDealFlag}
                  setWarningFlag={setWarningFlag}
                  selectedIds={selectedIds}
                  setSelectedIds={setSelectedIds}
                  {...props}
                  getNotification={getNotification}
                  refreshEnable={refreshEnable}
                  setRefreshEnable={setRefreshEnable}
                  startstopClockTimeTrack={startstopClockTimeTrack}
                  setTimerPrevTimeTrack={setTimerPrevTimeTrack}
                  setTimerTimeTrack={setTimerTimeTrack}
                  timerObj={timerObj}
                  timerTimeTrack={timerTimeTrack}
                  timeTrackFlag={timeTrackClock}
                  initializeTimeTrack={initializeTimeTrack}
                  getParams={getParams}
                  location={location}
                  history={history}
                />
              )}
            />
            <Route
              path="/deallist/:module/:action"
              exact
              render={() => (
                <DealList
                  warningFlag={warningFlag}
                  fetchAuthenticatedUrl={fetchAuthenticatedUrl}
                  invertColor={invertColor}
                  dealListTriggers={dealListTriggers}
                  dealListStatus={dealListStatus}
                  deleteDealFlag={deleteDealFlag}
                  setDeleteDealFlag={setDeleteDealFlag}
                  setRendering={setRendering}
                  rendering={rendering}
                  setWarningFlag={setWarningFlag}
                  getParams={getParams}
                  selectedIds={selectedIds}
                  setSelectedIds={setSelectedIds}
                  location={location}
                  checkType={checkType}
                  setCheckType={setCheckType}
                  refreshEnable={refreshEnable}
                  setRefreshEnable={setRefreshEnable}
                />
              )}
            />
            <Route
              path="/dealcreate/:module/:action"
              exact
              render={(props) => (
                <Createdeal
                  setLoader={setLoader}
                  getToken={getToken}
                  toggelActiveBar={toggelActiveBar}
                  warningFlag={warningFlag}
                  setWarningFlag={setWarningFlag}
                  ref={saveCancelButtonRef}
                  fetchAuthenticatedUrl={fetchAuthenticatedUrl}
                  setSidebarCardsStatus={setSidebarCardsStatus}
                  {...props}
                  getParams={getParams}
                  location={location}
                  history={history}
                  showExitPrompt={showExitPrompt}
                  setShowExitPrompt={setShowExitPrompt}
                />
              )}
            />
            <Route
              path="/dealupdate/:module/:action/:id"
              exact
              render={(props) => (
                <Createdeal
                  setLoader={setLoader}
                  getToken={getToken}
                  toggelActiveBar={toggelActiveBar}
                  warningFlag={warningFlag}
                  setWarningFlag={setWarningFlag}
                  ref={saveCancelButtonRef}
                  fetchAuthenticatedUrl={fetchAuthenticatedUrl}
                  setSidebarCardsStatus={setSidebarCardsStatus}
                  {...props}
                  getParams={getParams}
                  location={location}
                  history={history}
                  showExitPrompt={showExitPrompt}
                  setShowExitPrompt={setShowExitPrompt}
                />
              )}
            />
            <Route
              path="/d/:module/:action/:id"
              exact
              render={(props) => (
                <Body
                  loader={loader}
                  setLoader={setLoader}
                  deleteDealFlag={deleteDealFlag}
                  setDeleteDealFlag={setDeleteDealFlag}
                  selectedIds={selectedIds}
                  setSelectedIds={setSelectedIds}
                  warningFlag={warningFlag}
                  invertColor={invertColor}
                  setWarningFlag={setWarningFlag}
                  {...props}
                  getNotification={getNotification}
                  startstopClockTimeTrack={startstopClockTimeTrack}
                  setTimerPrevTimeTrack={setTimerPrevTimeTrack}
                  setTimerTimeTrack={setTimerTimeTrack}
                  timerObj={timerObj}
                  setRendering={setRendering}
                  rendering={rendering}
                  timerTimeTrack={timerTimeTrack}
                  timeTrackFlag={timeTrackClock}
                  initializeTimeTrack={initializeTimeTrack}
                  getParams={getParams}
                  location={location}
                  history={history}
                  refreshEnable={refreshEnable}
                  setRefreshEnable={setRefreshEnable}
                  handleNotificationHyperlink={handleNotificationHyperlink}
                  allTask={allTask}
                  allUsers={allUsers}
                  cacheDeals={cacheDeals} 
                />
              )}
            />
            <Route
              path="/project/:module/:action/:taskId?/:subTaskId?"
              exact
              render={(props) => (
                <ProjectTasks
                  warningFlag={warningFlag}
                  setWarningFlag={setWarningFlag}
                  {...props}
                  refreshEnable={refreshEnable}
                  setRefreshEnable={setRefreshEnable}
                  startstopClockTimeTrack={startstopClockTimeTrack}
                  setTimerPrevTimeTrack={setTimerPrevTimeTrack}
                  setTimerTimeTrack={setTimerTimeTrack}
                  timerObj={timerObj}
                  timerTimeTrack={timerTimeTrack}
                  timeTrackFlag={timeTrackClock}
                  initializeTimeTrack={initializeTimeTrack}
                  getParams={getParams}
                  location={location}
                  history={history}
                />
              )}
            />
            <Route
              path="/clientlist/:module/:action"
              exact
              render={(props) => (
                <ClientList
                clientListTriggers={clientListTriggers}
                deleteDealFlag={deleteDealFlag}
                setDeleteDealFlag={setDeleteDealFlag}
                invertColor={invertColor}
                clientListStatus={clientListStatus}
                  warningFlag={warningFlag}
                  setWarningFlag={setWarningFlag}
                  {...props}
                  refreshEnable={refreshEnable}
                  setRefreshEnable={setRefreshEnable}
                  getParams={getParams}
                  location={location}
                  history={history}
                  selectedIds={selectedIds}
                  setSelectedIds={setSelectedIds}
                />
              )}
            />
            <Route
              path="/createclient/:module/:action"
              exact
              render={(props) => (
                <CreateClient
                  clientListStatus={clientListStatus}
                  setClientListStatus={setClientListStatus}
                  warningFlag={warningFlag}
                  setWarningFlag={setWarningFlag}
                  {...props}
                  getParams={getParams}
                  location={location}
                  history={history}
                />
              )}
            />
            <Route
              path="/updateclient/:module/:action/:id"
              exact
              render={(props) => (
                <CreateClient
                  clientListStatus={clientListStatus}
                  setClientListStatus={setClientListStatus}
                  warningFlag={warningFlag}
                  setWarningFlag={setWarningFlag}
                  {...props}
                  getParams={getParams}
                  location={location}
                  history={history}
                />
              )}
            />
            <Route
              path="/hr/timeclock"
              exact
              render={(props) => (
                <HRTimeClock
                  warningFlag={warningFlag}
                  setWarningFlag={setWarningFlag}
                  {...props}
                  getParams={getParams}
                  location={location}
                  history={history}
                  clockisActive={refreshTimeclock}
                  refreshEnable={refreshEnable}
                  setRefreshEnable={setRefreshEnable}
                />
              )}
            />
            <Route
              path="/setting/taskflows"
              exact
              render={(props) => (
                <TaskBlueprints 
                  location={location}
                  history={history}
                />
              )}
            />
            <Route
              path="/setting/pipeline/:taskId?/:subTaskId?"
              exact
              render={(props) => (
                <ProjectTasks
                  warningFlag={warningFlag}
                  setWarningFlag={setWarningFlag}
                  {...props}
                  refreshEnable={refreshEnable}
                  setRefreshEnable={setRefreshEnable}
                  startstopClockTimeTrack={startstopClockTimeTrack}
                  setTimerPrevTimeTrack={setTimerPrevTimeTrack}
                  setTimerTimeTrack={setTimerTimeTrack}
                  timerObj={timerObj}
                  timerTimeTrack={timerTimeTrack}
                  timeTrackFlag={timeTrackClock}
                  initializeTimeTrack={initializeTimeTrack}
                  getParams={getParams}
                  location={location}
                  history={history}
                />
              )}
            />
            <Route
              path="/access/d/:module/:action"
              exact
              render={(props) => (
                <DocumentTree
                  warningFlag={warningFlag}
                  setWarningFlag={setWarningFlag}
                  {...props}
                  location={location}
                  history={history}
                  refreshEnable={refreshEnable}
                  setRefreshEnable={setRefreshEnable}
                  getParams={getParams}
                />
              )}
            />
            <Route
              path="/setting/index"
              exact
              render={() => (
                <Setting
                  location={location}
                  history={history}
                  warningFlag={warningFlag}
                  setWarningFlag={setWarningFlag}
                />
              )}
            />
            <Route
              path="/setting/users/:id/:tab"
              exact
              render={(props) => (
                <Users
                  warningFlag={warningFlag}
                  setWarningFlag={setWarningFlag}
                  {...props}
                  getParams={getParams}
                  location={location}
                  history={history}
                />
              )}
            />
            <Route
              path="/vendorlist/:module/:action"
              exact
              render={(props) => (
                <VendorList
                  warningFlag={warningFlag}
                  setWarningFlag={setWarningFlag}
                  {...props}
                  getParams={getParams}
                  location={location}
                  history={history}
                />
              )}
            />
            <Route
              path="/vendorcreate/:module/:action"
              exact
              render={(props) => (
                <CreateVendor
                  warningFlag={warningFlag}
                  setWarningFlag={setWarningFlag}
                  {...props}
                  getParams={getParams}
                  location={location}
                  history={history}
                />
              )}
            />
            <Route
              path="/vendorupdate/:module/:action/:id"
              exact
              render={(props) => (
                <CreateVendor
                  warningFlag={warningFlag}
                  setWarningFlag={setWarningFlag}
                  {...props}
                  getParams={getParams}
                  location={location}
                  history={history}
                />
              )}
            />

            {/*         
            {/*
            <Route path="/app/pvmodule/list" exact render={() => <PVModuleList />} />
            <Route path="/app/pvmanufacturer/list" exact render={() => <PVManufacturerList />} />
            <Route path="/app/pipeline/list" exact render={() => <PipelineList />} />
            <Route path="/app/pipelines/create" exact render={() => <PipelineForm />} />
            <Route path="/app/pipeline/update/:id" exact render={() => <PipelineForm />} />
            <Route path="/app/holidays/list" component={HolidaysList} /> */}
          </Switch>
          {reminderNotification()}

        </div>
      </AppDataRequired.Provider>
    );
  } else {
    //window.catalyst.auth.signIn("root");
  }
}

export default App;
